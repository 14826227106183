import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, mergeMap } from 'rxjs/operators';

import { IListItem } from '../../classes/IListItem';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import {
  getReportTypeName,
  IReportTemplateInfo,
  ReportTemplatesService
} from '../../services/report-templates.service';
import { ModalResult } from '../modal/modal.component';
import { ReportTemplateComponent } from '../report-template/report-template.component';

/**
 * Информация по элементу списка для использования в компоненте
 */
export interface IClientReportTemplateInfo extends IReportTemplateInfo {
  /** Название типа шаблона отчета */
  typeName: string;
}

/**
 * Компонент для отображения списка шаблонов отчетов
 */
@Component({
  selector: 'report-templates',
  templateUrl: './report-templates.component.html'
})
export class ReportTemplatesComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClientReportTemplateInfo[];
  /**
   * Выбранный элемент
   */
  public selected: IClientReportTemplateInfo;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;

  /**
   * Конструктор
   * @param reportTemplatesService Сервис работы с ДИУП
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис работы с диалоговыми окнами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param translator Сервис для перевода
   */
  constructor(
    private reportTemplatesService: ReportTemplatesService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'account', name: 'ui.account' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление шаблона
   */
  public addItem() {
    this.dialogService.addDialog(ReportTemplateComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранного шаблона
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { templateId: id };
    this.dialogService.addDialog(ReportTemplateComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Создание копии выбранного шаблона
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = { templateId: id, copy: true };
    this.dialogService.addDialog(ReportTemplateComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранного шаблона
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant(
      'component.report-templates.confirm',
      { val: name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        mergeMap(() => this.reportTemplatesService.remove(id)))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    this.error = null;

    this.reportTemplatesService.getList().subscribe((items) => {
      this.items = items?.map((item) => ({
        ...item,
        typeName: this.translator.instant(getReportTypeName(item.type))
      }));
    });
  }
}
