import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { reduce, tap } from 'rxjs/operators';

import { ISimpleGroup, ISimpleObject } from '../../shared/ISimpleObject';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { ReportType } from './report-templates.service';
import { ObjectOrGroup, ObjectType, sortLex } from './reports.service';

/**
 * Report Objects Service
 */
@Injectable()
export class ReportObjectsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/report-objects`

  /**
   * Конструктор
   * @param configService Сервис конфигурации
   * @param http HTTP клиент
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    public configService: ConfigService,
    private http: HttpClient,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Загрузка списка объектов и (или) групп по типу отчёта
   * @param rt Тип отчёта
   * @param ot Тип объектов
   */
  public loadObjects(rt: ReportType, ot: ObjectType): Observable<ObjectOrGroup[]> {
    const fs: Observable<ObjectOrGroup[]>[] = [];
    switch (rt) {
      case ReportType.UNIT:
      case ReportType.UNIT_GROUP:
        if (ot !== ObjectType.Groups) {
          fs.push(this.getUnits());
        }
        if (ot !== ObjectType.Objects) {
          fs.push(this.getUnitGroups());
        }
        break;

      case ReportType.DRIVER:
      case ReportType.DRIVER_GROUP:
        if (ot !== ObjectType.Groups) {
          fs.push(this.getDrivers());
        }
        if (ot !== ObjectType.Objects) {
          fs.push(this.getDriverGroups());
        }
        break;

      case ReportType.TRAILER:
      case ReportType.TRAILER_GROUP:
        if (ot !== ObjectType.Groups) {
          fs.push(this.getTrailers());
        }
        if (ot !== ObjectType.Objects) {
          fs.push(this.getTrailerGroups());
        }
        break;

      case ReportType.USER:
        fs.push(this.getUsers());
    }

    return concat(...fs).pipe(reduce((acc: ObjectOrGroup[], list) => acc.concat(list), []));
  }

  /**
   * Получение списка ТС, доступных для формирования отчета
   */
  public getUnits(): Observable<ISimpleObject[]> {
    const url = `${this.baseUrl}/units`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleObject[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleObject>(x)));
  }

  /**
   * Получение списка групп ТС, доступных для формирования отчета
   */
  public getUnitGroups(): Observable<ISimpleGroup[]> {
    const url = `${this.baseUrl}/unit-groups`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleGroup[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleGroup>(x)));
  }

  /**
   * Получение списка водителей, доступных для формирования отчета
   */
  public getDrivers(): Observable<ISimpleObject[]> {
    const url = `${this.baseUrl}/drivers`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleObject[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleObject>(x)));
  }

  /**
   * Получение списка групп водителей, доступных для формирования отчета
   */
  public getDriverGroups(): Observable<ISimpleGroup[]> {
    const url = `${this.baseUrl}/driver-groups`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleGroup[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleGroup>(x)));
  }

  /**
   * Получение списка прицепов, доступных для формирования отчета
   */
  public getTrailers(): Observable<ISimpleObject[]> {
    const url = `${this.baseUrl}/trailers`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleObject[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleObject>(x)));
  }

  /**
   * Получение списка групп прицепов, доступных для формирования отчета
   */
  public getTrailerGroups(): Observable<ISimpleGroup[]> {
    const url = `${this.baseUrl}/trailer-groups`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleGroup[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleGroup>(x)));
  }

  /**
   * Получение списка пользователей, доступных для формирования отчета
   */
  public getUsers(): Observable<ISimpleObject[]> {
    const url = `${this.baseUrl}/users`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<ISimpleObject[]>(url, { headers })
      .pipe(tap((x) => sortLex<ISimpleObject>(x)));
  }
}
