import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { parse } from 'url';

import { ConfigService } from '../services/config.service';

/**
 * Перехватчик запроса
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  /**
   * Конструктор
   * @param translator Сервис для перевода
   * @param configService Сервис конфигурации
   * @param jwtHelperService Сервис для работы с JWT
   */
  constructor(
    private translator: TranslateService,
    private configService: ConfigService,
    private jwtHelperService: JwtHelperService
  ) {}

  /**
   * Перехватчик
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Очищаем от незаполненных параметров
    // (почему-то angular сам этого не делает)
    let params = req.params;
    if (params) {
      let cleaned = new HttpParams();

      req.params.keys().forEach((key) => {
        if (req.params.get(key) != null) {
          cleaned = cleaned.append(key, req.params.get(key));
        }
      });

      params = cleaned;
    }

    let headers = req.headers;
    if (!headers) {
      headers = new HttpHeaders();
    }

    const requestUrl = parse(req.url, false, true);
    if (window.location.host === requestUrl.host) {
      const token = this.jwtHelperService.tokenGetter();
      if (!this.jwtHelperService.isTokenExpired(token)) {
        headers = headers.set('Authorization', `Bearer ${token}`)
      }
    }

    const observable = localStorage.getItem('observable');
    if (observable) {
      headers = headers.set('X-Observable', observable);
    }

    const lang = this.translator.currentLang;
    if (lang) {
      headers = headers = headers.set('Accept-Language', lang);
    }

    if (req.body) {
      headers = headers = headers.set('Content-Type', 'application/json');
    }

    let responseType = req.responseType;
    if (!responseType) {
      responseType = 'json';
    }

    const request = req.clone({headers, responseType, params});
    return next.handle(request);
  }
}
