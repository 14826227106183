import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap, tap } from 'rxjs/operators';

import { getAllPromotions, getPromotionName, Promotion } from '../../../../../shared/crm/Promotion';
import { ITariff, TariffPeriodType } from '../../../../../shared/crm/tariffs';
import { DetailComponent } from '../../../../classes/DetailComponent';
import { DetailTab } from '../../../../classes/DetailTab';
import { IListItem } from '../../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { LoadingService } from '../../../../services/loading.service';
import { SelectItemsComponent } from '../../../select-items/select-items.component';

/**
 * Данные компонента редактирования тарифа
 */
interface IData {
  /** Идентификатор тарифа */
  tariffId?: string;
  /** Признак режима копирования */
  copy?: boolean;
}

/**
 * Компонент для редактирования тарифа
 */
@Component({
  selector: 'tariff-edit',
  templateUrl: './tariffs.edit.component.html',
  styleUrls: ['./tariffs.edit.component.scss']
})
export class TariffEditComponent
  extends DetailComponent<IData>
  implements IData {

  /** Идентификатор тарифа */
  public tariffId?: string;

  /** Признак режима копирования */
  public copy?: boolean;

  /** Редактируемый тариф */
  public tariff: ITariff;

  /** Список дней месяца */
  public days: number[];

  /** Список акций по тарифу */
  public promotions: IListItem<Promotion>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис работы с CRUD
   * @param loadingService Сервис отображения процесса загрузки
   * @param translator Сервис перевода
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private translator: TranslateService
  ) {
    super(dialogService);

    this.tabs[Tabs.Main] = new DetailTab('component.crm.tariffs.edit.main');
    this.tabs[Tabs.More] = new DetailTab('component.crm.tariffs.edit.more');
    this.selectedTab = this.tabs[Tabs.Main];

    this.tariff = {
      debitPeriod: TariffPeriodType.DAY,
      pricePeriod: TariffPeriodType.MONTH,
      pointStorageDays: 182
    } as ITariff;

    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }
  }

  /**
   * Получение признака возможности сохранения изменений
   */
  public get isCanSave(): boolean {
    if (!this.tariff || !this.tariff.name || this.tariff.name === '') {
      return false;
    }

    if (!this.tariff.pointStorageDays || this.tariff.pointStorageDays < 90) {
      return false;
    }

    if (!this.tariff.debitPeriod || !this.tariff.price || this.tariff.price < 0) {
      return false;
    }

    if (!this.tariff.paymentPeriod || this.tariff.paymentPeriod < 0) {
      return false;
    }

    return this.tariff.debitPeriod !== TariffPeriodType.DAY || this.tariff.pricePeriod && this.tariff.pricePeriod >= 0;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IData) {

    const action = data.tariffId ? data.copy ? 'copy' : 'edit' : 'add';
    this.title = `component.crm.tariffs.edit.tariff-${action}-title`;

    if (data.tariffId) {
      this.loadingService
        .wrap(this.crudService.get(data.tariffId, CRUDEntityType.TARIFF), true)
        .subscribe((tariff: ITariff) => {
          tariff.price /= 100;

          this.tariff = tariff;

          if (data.copy) {
            delete this.tariff.id;
          }

          if (this.tariff.promotions) {
            this.promotions = this.tariff.promotions.map((id) => ({ id, name: getPromotionName(id) }));
          }
        });
    }

    return super.fillData(data);
  }

  /**
   * Обработка изменения периода начислени
   * @param debitPeriod Новое значение периода начисления
   */
  public debitPeriodChange(debitPeriod: TariffPeriodType) {
    if (debitPeriod === TariffPeriodType.MONTH) {
      delete this.tariff.pricePeriod;
    } else {
      this.tariff.pricePeriod = TariffPeriodType.MONTH;
    }
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    const tariff = {
      ...this.tariff,
      price: Math.round(this.tariff.price * 100)
    };

    this.loadingService
      .wrap(this.crudService.addUpdate(tariff, CRUDEntityType.TARIFF), true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Обработки при изменении списка выбранных акций
   */
  public onChangePromotions() {
    this.tariff.promotions = this.promotions.map((p) => p.id);
  }

  /**
   * Выбор акций для тарифа
   */
  public selectPromotions() {
    const items = getAllPromotions().map((id) => ({ id, name: getPromotionName(id) }));

    const data = {
      items,
      selected: this.promotions,
      title: 'component.crm.tariffs.edit.select-events',
      withSearch: true
    };

    this.translator
      .get(items.map((item) => item.name))
      .pipe(
        tap((names) => items.forEach((item) => item.name = names[item.name])),
        flatMap(() => this.dialogService.addDialog(SelectItemsComponent, data)),
        filter((result) => !!result))
      .subscribe((result) => {
        this.promotions = result;
        this.onChangePromotions();
      });
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  More
}
