/** Тип запроса по документу */
export enum DocRequestType {
  /** Получение */
  GET = 'get',
  /** Удаление */
  DELETE = 'delete',
  /** Генерация */
  GENERATE = 'generate',
  /** Изменение */
  CHANGE = 'change'
}
