import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IScavenger } from '../../shared/crm/clients/IClient';
import { IJournal } from '../../shared/garbage/IJournal';
import { IJournalRequest } from '../../shared/garbage/IJournalRequest';
import { IMonthList } from '../../shared/garbage/IMonthList';
import { IMonthListRequest } from '../../shared/garbage/IMonthListRequest';
import { ISummary } from '../../shared/garbage/ISummary';
import { ISummaryRequest } from '../../shared/garbage/ISummaryRequest';
import { IVisits } from '../../shared/garbage/IVisits';
import { IVisitsRequest } from '../../shared/garbage/IVisitsRequest';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { ToastService } from './toast.service';

/**
 * Сервис для работы с мусором
 */
@Injectable()
export class GarbageService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/garbage`;

  /**
   * Адрес сервиса аналитических отчётов
   */
  private readonly logisticsUrl = `${this.configService.url}/logistics`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService Сервис для работы с конфигами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param toastService Сервис всплывающих сообщений
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) {
  }

  /**
   * Получение сводной информации по перевозке мусора за период
   * @param body Тело запроса
   */
  public getAcceptanceCertificate(body: ISummaryRequest): Observable<ISummary> {
    const url = `${this.logisticsUrl}/acceptance-certificate`;
    const headers = this.loadingService.waiterHeader;
    return this.http.post<ISummary>(url, body, { headers })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла XLSX со сводной информацией по перевозке мусора за период
   * @param body Тело запроса
   */
  public getAcceptanceCertificateXLSX(body: ISummaryRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/xlsx/acceptance-certificate`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла PDF со сводной информацией по перевозке мусора за период
   * @param body Тело запроса
   */
  public getAcceptanceCertificatePDF(body: ISummaryRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/pdf/acceptance-certificate`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение маршрутного журнала
   * @param body Тело запроса
   */
  public getJournal(body: IJournalRequest): Observable<IJournal> {
    const url = `${this.logisticsUrl}/journal`;
    const headers = this.loadingService.waiterHeader;
    return this.http.post<IJournal>(url, body, { headers })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла XLSX c маршрутным журналом
   * @param body Тело запроса
   */
  public getJournalXLSX(body: IJournalRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/xlsx/journal`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла PDF c маршрутным журналом
   * @param body Тело запроса
   */
  public getJournalPDF(body: IJournalRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/pdf/journal`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение данных по посещению мест выгрузки ТКО
   * @param body Тело запроса
   */
  public getDetailReport(body: IVisitsRequest): Observable<IVisits> {
    const url = `${this.logisticsUrl}/detail-report`;
    const headers = this.loadingService.waiterHeader;
    return this.http.post<IVisits>(url, body, { headers })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла XLSX с данными по посещению мест выгрузки ТКО
   * @param body Тело запроса
   */
  public getDetailReportXLSX(body: IVisitsRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/xlsx/detail-report`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла PDF с данными по посещению мест выгрузки ТКО
   * @param body Тело запроса
   */
  public getDetailReportPDF(body: IVisitsRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/pdf/detail-report`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение данных заборной ведомости на вывоз ТКО
   * @param body Тело запроса
   */
  public getLoadingsStatement(body: IMonthListRequest): Observable<IMonthList> {
    const url = `${this.logisticsUrl}/loadings-statement`;
    const headers = this.loadingService.waiterHeader;
    return this.http.post<IMonthList>(url, body, { headers })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла XLSX с данными заборной ведомости на вывоз ТКО
   * @param body Тело запроса
   */
  public getLoadingsStatementXLSX(body: IMonthListRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/xlsx/loadings-statement`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение файла XLSX с данными заборной ведомости на вывоз ТКО
   * @param body Тело запроса
   */
  public getLoadingsStatementPDF(body: IMonthListRequest): Observable<Blob> {
    const url = `${this.logisticsUrl}/pdf/loadings-statement`;
    const headers = this.loadingService.waiterHeader;
    const responseType = 'blob';
    return this.http.post(url, body, { headers, responseType })
      .pipe(catchError(() => {
        this.toastService.error('services.garbage.get-report-error');
        return EMPTY;
      }));
  }

  /**
   * Получение списка мусоровозов
   */
  public getTrucks(): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/trucks`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IListItem<string>[]>(url, { headers });
  }

  /**
   * Получение данных перевозчика мусора
   */
  public getScavenger(): Observable<IScavenger> {
    const url = `${this.baseUrl}`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IScavenger>(url, { headers });
  }

  /**
   * Сохранение данных перевозчика мусора
   * @param body Данные перевозчика мусора
   */
  public saveScavenger(body: IScavenger): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.post<string>(url, body);
  }
}

/**
 * Округление периода отчёта до суток
 * @param from Начало периода
 * @param to Конец периода
 */
export function roundGarbagePeriod(from: number, to: number): IGarbagePeriod {
  const fd = new Date(from);
  const td = new Date(to);
  from = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate(), 0, 0, 0, 0).getTime();
  to = new Date(td.getFullYear(), td.getMonth(), td.getDate() + 1, 0, 0, 0, -1).getTime();

  return { from, to };
}

/**
 * Период для отчёта по мусору
 */
export interface IGarbagePeriod {

  /**
   * Начало периода
   */
  from: number;

  /**
   * Конец периода
   */
  to: number;
}
