import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RightType, UserRightType } from '../../shared/rights/RightType';
import { IActUser } from '../../shared/users';
import { IListItem } from '../classes/IListItem';
import { addDay, delDay } from '../utils/intervals';

import { ConfigService } from './config.service';
import { StoreService } from './store.service';

/**
 * Сервис для работы с пользователями
 */
@Injectable()
export class UsersService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/users`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param store
   * @param configService
   */
  constructor(
      private http: HttpClient,
      private store: StoreService,
      private configService: ConfigService
  ) {}

  /**
   * Получение списка пользователей, от имени которых имеет право действовать текущий пользователь
   */
  public getActUsers(): Observable<IActUser[]> {
    const url = `${this.baseUrl}/act`;
    return this.http.get<IActUser[]>(url);
  }

  /**
   * Получение списка пользователей, на которых у текущего пользователя есть указанное право доступа
   * @param right Право доступа
   */
  public getWithRight(right: RightType | UserRightType): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/right/${right}`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получаем период разрешения истории аккаунта
   */
  public getAccountHistoryPeriod(periodLimit: number): Date {
    const now = new Date()
    return new Date(now.setDate(now.getDate() - periodLimit))
  }

  /**
   * Получаем начало разрешения истории по юзеру
   * from от
   */
  public get getUserHistoryPeriodFrom(): Date {
    const userFrom: Date = new Date(this.store.user.settings?.userPointsPeriodFrom)
    const accFrom: Date = this.getAccountHistoryPeriod(this.store.user.account?.periodLimit)
    // если ограничение аккаунта меньше чем ограничение юзера
    return userFrom && userFrom > accFrom ? delDay(userFrom) : accFrom;
  }

  /**
   * Получаем конец разрешения истории по юзеру
   * from до
   */
  public get getUserHistoryPeriodTo(): Date {
    return this.store.user.settings?.userPointsPeriodTo ? addDay(new Date(this.store.user.settings?.userPointsPeriodTo)) : null;
  }
}
