/**
 * Data structure of Yandex Transport repeater
 */
export interface IYandexTransportRepeater {
  clid: string
  uuid: string
  route: string
  category: Category
  vehicleType: VehicleType
}

/**
 * GPS signal level categories
 */
export enum Category {
  // noinspection JSUnusedGlobalSymbols
  NORMAL = 'n',
  WEAK = 's'
}

/**
 * Types of vehicles
 */
export enum VehicleType {
  // noinspection JSUnusedGlobalSymbols
  BUS = 'bus',
  MINIBUS = 'minibus',
  TROLLEYBUS = 'trolleybus',
  TRAMWAY = 'tramway'
}
