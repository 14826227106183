/**
 * Права доступа
 */
export enum RightType {
  /** Просмотр элемента и его основных свойств */
  VIEW_BASIC = 0x1,

  /** Просмотр подробных свойств */
  VIEW_ADVANCED = 0x2,

  /** Управление доступом */
  CHANGE_ACCESS = 0x4,

  /** Удаление */
  DELETE = 0x8,

  /** Переименование */
  RENAME = 0x10,

  /** Просмотр произвольных свойств */
  VIEW_ARBITRARY_FIELDS = 0x20,

  /** Редактировние произвольных свойств */
  CHANGE_ARBITRARY_FIELDS = 0x40,

  /** Редактирование не упомянутых свойств */
  CHANGE_OTHER = 0x80,

  /** Изменение иконки */
  CHANGE_ICON = 0x100,

  /** Запрос сообщений и отчетов */
  GET_REPORTS = 0x200,

  /** Управление содержимым группы */
  CHANGE_GROUP_UNITS = 0x400,

  /** Управление журналом */
  JOURNAL = 0x800,

  /** Просмотр административных полей */
  VIEW_ADMIN_FIELDS = 0x1000,

  /** Управление административными полями */
  CHANGE_ADMIN_FIELDS = 0x2000,

  /** Просмотр и скачивание файлов */
  VIEW_FILES = 0x4000,

  /** Загрузка и удаление файлов */
  CHANGE_FILES = 0x8000
}

/**
 * Особые права доступа для объектов мониторинга
 */
export enum UnitRightType {
  /** Просмотр настроек подключения */
  VIEW_CONNECTION_SETTING = 0x10000,

  /** Редактирование настроек подключения */
  CHANGE_CONNECTION_SETTING = 0x20000,

  /** Создание, редактирование и удаление датчиков */
  CHANGE_SENSORS = 0x40000,

  /** Редактирование счетчиков */
  CHANGE_COUNTERS = 0x80000,

  /** Удаление сообщений */
  DELETE_MESSAGES = 0x100000,

  /** Выполнение команд */
  EXECUTE_COMMANDS = 0x200000,

  /** Управление событиями */
  CHANGE_EVENTS = 0x400000,

  /** Просмотр интервалов техобслуживания */
  VIEW_TO = 0x800000,

  /** Создание, редактирование и удаление интервалов техобслуживания */
  CHANGE_TO = 0x1000000,

  /** Импорт сообщений */
  IMPORT_MESSAGES = 0x2000000,

  /** Экспорт сообщений */
  EXPORT_MESSAGES = 0x4000000,

  /** Просмотр команд */
  VIEW_COMMANDS = 0x8000000,

  /** Создание, редактирование и удаление команд */
  CHANGE_COMMANDS = 0x10000000,

  /** Изменение детектора поездок и расхода топлива */
  CHANGE_MOVE_AND_FUEL_SETTING = 0x20000000,

  /** Использование объекта в уведомлениях, заданиях, маршрутах, ретрансляторах */
  USE_IN_NOTIF_TASK_ROUTE_REPEATER = 0x40000000
}

/**
 * Особые права доступа для пользователей
 */
export enum UserRightType {
  /** Управлять правами доступа пользователя */
  CHANGE_USER_RIGHTS = 0x10000,

  /** Действовать от имени этого пользователя */
  ACT = 0x20000,

  /** Изменять флаги пользователя */
  CHANGE_FLAGS = 0x40000
}

/**
 * Особые права доступа для учетных записей
 */
export enum AccountRightType {
  /** Просмотр геозон */
  VIEW_GEOZONES = 0x10000,
  /** Изменение геозон */
  CHANGE_GEOZONES = 0x20000,
  /** Просмотр заданий */
  VIEW_TASKS = 0x40000,
  /** Изменение заданий */
  CHANGE_TASKS = 0x80000,
  /** Просмотр уведомлений */
  VIEW_NOTIFICATIONS = 0x100000,
  /** Изменение уведомлений */
  CHANGE_NOTIFICATIONS = 0x200000,
  /** Просмотр водителей */
  VIEW_DRIVERS = 0x400000,
  /** Изменение водителей */
  CHANGE_DRIVERS = 0x800000,
  /** Просмотр пассажиров */
  VIEW_PASSENGERS = 0x1000000,
  /** Изменение пассажиров */
  CHANGE_PASSENGERS = 0x2000000,
  /** Просмотр прицепов */
  VIEW_TRAILERS = 0x4000000,
  /** Изменение прицепов */
  CHANGE_TRAILERS = 0x8000000,
  /** Просмотр шаблонов отчетов */
  VIEW_REPORT_TEMPLATES = 0x10000000,
  /** Изменение шаблонов отчетов */
  CHANGE_REPORT_TEMPLATES = 0x20000000,
  /** Изменение учетной записи */
  CHANGE_ACCOUNT = 0x40000000
}

/** Любой из типов прав */
export type AnyRightType = RightType | AccountRightType | UnitRightType | UserRightType;

/**
 * Получение списка всех основных прав доступа
 */
export function getAllRights(): RightType[] {
  const result: RightType[] = [];
  for (let i = 0; i < 16; i++) {
    // tslint:disable-next-line:no-bitwise
    const value = 1 << i;
    result.push(value as RightType);
  }
  return result;
}

/**
 * Получение наименования основного права доступа
 * @param type Право доступа
 */
export function getRightTypeName(type: RightType): string {
  switch (type) {
    case RightType.VIEW_BASIC: return 'enums.rights.type.view';
    case RightType.VIEW_ADVANCED: return 'enums.rights.type.view-details';
    case RightType.CHANGE_ACCESS: return 'enums.rights.type.access';
    case RightType.DELETE: return 'enums.rights.type.delete';
    case RightType.RENAME: return 'enums.rights.type.rename';
    case RightType.VIEW_ARBITRARY_FIELDS: return 'enums.rights.type.view-arbitrary';
    case RightType.CHANGE_ARBITRARY_FIELDS: return 'enums.rights.type.edit-arbitrary';
    case RightType.CHANGE_OTHER: return 'enums.rights.type.edit-other';
    case RightType.CHANGE_ICON: return 'enums.rights.type.icon';
    case RightType.GET_REPORTS: return 'enums.rights.type.reports';
    case RightType.CHANGE_GROUP_UNITS: return 'enums.rights.type.group';
    case RightType.JOURNAL: return 'enums.rights.type.journal';
    case RightType.VIEW_ADMIN_FIELDS: return 'enums.rights.type.view-admin';
    case RightType.CHANGE_ADMIN_FIELDS: return 'enums.rights.type.edit-admin';
    case RightType.VIEW_FILES: return 'enums.rights.type.view-files';
    case RightType.CHANGE_FILES: return 'enums.rights.type.edit-files';
    default: return 'enums.rights.type.unknown';
  }
}

/**
 * Получение списка всех особых прав доступа для объектов мониторинга
 */
export function getAllUnitRights(): UnitRightType[] {
  const result: UnitRightType[] = [];
  for (let i = 0; i < 15; i++) {
    // tslint:disable-next-line:no-bitwise
    const value = 0x10000 << i;
    result.push(value as UnitRightType);
  }
  return result;
}

/**
 * Получение наименования особого права доступа для объектов мониторинга
 * @param type Право доступа
 */
export function getUnitRightTypeName(type: UnitRightType): string {
  switch (type) {
    case UnitRightType.VIEW_CONNECTION_SETTING: return 'enums.rights.unit-right-type.view-connect';
    case UnitRightType.CHANGE_CONNECTION_SETTING: return 'enums.rights.unit-right-type.edit-connect';
    case UnitRightType.CHANGE_SENSORS: return 'enums.rights.unit-right-type.sensors';
    case UnitRightType.CHANGE_COUNTERS: return 'enums.rights.unit-right-type.counters';
    case UnitRightType.DELETE_MESSAGES: return 'enums.rights.unit-right-type.messages';
    case UnitRightType.EXECUTE_COMMANDS: return 'enums.rights.unit-right-type.execute';
    case UnitRightType.CHANGE_EVENTS: return 'enums.rights.unit-right-type.events';
    case UnitRightType.VIEW_TO: return 'enums.rights.unit-right-type.view-service';
    case UnitRightType.CHANGE_TO: return 'enums.rights.unit-right-type.edit-service';
    case UnitRightType.IMPORT_MESSAGES: return 'enums.rights.unit-right-type.import-messages';
    case UnitRightType.EXPORT_MESSAGES: return 'enums.rights.unit-right-type.export-messages';
    case UnitRightType.VIEW_COMMANDS: return 'enums.rights.unit-right-type.view-commands';
    case UnitRightType.CHANGE_COMMANDS: return 'enums.rights.unit-right-type.edit-commands';
    case UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING: return 'enums.rights.unit-right-type.detector';
    case UnitRightType.USE_IN_NOTIF_TASK_ROUTE_REPEATER: return 'enums.rights.unit-right-type.use';
    default: return 'enums.rights.unit-right-type.unknown';
  }
}

/**
 * Получение списка всех особых прав доступа для пользователей
 */
export function getAllUserRights(): UserRightType[] {
  const result: UserRightType[] = [];
  for (let i = 0; i < 3; i++) {
    // tslint:disable-next-line:no-bitwise
    const value = 0x10000 << i;
    result.push(value as UserRightType);
  }
  return result;
}

/**
 * Получение наименования особого права доступа для пользователей
 * @param type Право доступа
 */
export function getUserRightTypeName(type: UserRightType): string {
  switch (type) {
    case UserRightType.CHANGE_USER_RIGHTS: return 'enums.rights.user-right-type.access';
    case UserRightType.ACT: return 'enums.rights.user-right-type.act';
    case UserRightType.CHANGE_FLAGS: return 'enums.rights.user-right-type.flags';
    default: return 'enums.rights.user-right-type.unknown';
  }
}

/**
 * Получение списка всех особых прав доступа для учетных записей
 */
export function getAllAccountRights(): AccountRightType[] {
  const result: AccountRightType[] = [];
  for (let i = 0; i < 15; i++) {
    // tslint:disable-next-line:no-bitwise
    const value = 0x10000 << i;
    result.push(value as AccountRightType);
  }
  return result;
}

/**
 * Получение наименования особого права доступа для учетных записей
 * @param type Право доступа
 */
export function getAccountRightTypeName(type: AccountRightType): string {
  switch (type) {
    case AccountRightType.VIEW_GEOZONES: return 'enums.rights.account-right-type.view-geo';
    case AccountRightType.CHANGE_GEOZONES: return 'enums.rights.account-right-type.edit-geo';
    case AccountRightType.VIEW_TASKS: return 'enums.rights.account-right-type.view-task';
    case AccountRightType.CHANGE_TASKS: return 'enums.rights.account-right-type.edit-task';
    case AccountRightType.VIEW_NOTIFICATIONS: return 'enums.rights.account-right-type.view-notifications';
    case AccountRightType.CHANGE_NOTIFICATIONS: return 'enums.rights.account-right-type.edit-notifications';
    case AccountRightType.VIEW_DRIVERS: return 'enums.rights.account-right-type.view-drivers';
    case AccountRightType.CHANGE_DRIVERS: return 'enums.rights.account-right-type.edit-drivers';
    case AccountRightType.VIEW_PASSENGERS: return 'enums.rights.account-right-type.view-passengers';
    case AccountRightType.CHANGE_PASSENGERS: return 'enums.rights.account-right-type.edit-passengers';
    case AccountRightType.VIEW_TRAILERS: return 'enums.rights.account-right-type.view-trails';
    case AccountRightType.CHANGE_TRAILERS: return 'enums.rights.account-right-type.edit-trails';
    case AccountRightType.VIEW_REPORT_TEMPLATES: return 'enums.rights.account-right-type.view-reports';
    case AccountRightType.CHANGE_REPORT_TEMPLATES: return 'enums.rights.account-right-type.edit-reports';
    case AccountRightType.CHANGE_ACCOUNT: return 'enums.rights.account-right-type.view-account';
    default: return 'enums.rights.account-right-type.unknown';
  }
}
