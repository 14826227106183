import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BgAlertComponent } from './components/bg-alert/bg-alert.component';
import { BgInputComponent } from './components/bg-input/bg-input.component';
import { BgSelectorComponent } from './components/bg-selector/bg-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ScrollingModule
  ],
  declarations: [
    BgInputComponent,
    BgSelectorComponent,
    BgAlertComponent
  ],
  entryComponents: [],
  exports: [
    BgInputComponent,
    BgSelectorComponent,
    BgAlertComponent
  ]
})
export class SharedModule {
}
