import { TimeSpan } from '../../shared/classes/TimeSpan';
import { tranny } from '../app.component';

/**
 * Преобразование интервала в строку
 * @param int интервал
 */
export function intervalToString(int: TimeSpan): string {
  if (int.totalMilliseconds <= 0) {
    return tranny.instant('ui.right-now');
  }
  if (int.days || int.hours || int.minutes || int.seconds) {
    return `${getString(int)} ${tranny.instant('ui.ago')}`;
  }
  return tranny.instant('ui.right-now');
}

export function getString(int: TimeSpan): string {
  const result: string[] = [];
  if (int.days > 0) {
    result.push(tranny.instant('classes.time-span.days', {val: int.days}));
  }
  if (int.hours > 0 || result) {
    result.push(tranny.instant('classes.time-span.hours', {val: int.hours}));
  }
  if (int.minutes > 0 || result) {
    result.push(tranny.instant('classes.time-span.minutes', {val: int.minutes}));
  }
  if (int.seconds > 0) {
    result.push(tranny.instant('classes.time-span.seconds', {val: int.seconds}));
  }
  return result.join(' ');
}


/**
 * Проверяем что бы все значения в календаре вошли в ограничения (для двойного календаря)
 * @param from Дата от
 * @param to Дата до
 * @param disableUntil Ограничение периода от
 * @param disableSince Ограничение периода до
 */
export function correctDisableDatepicker(from: Date, to: Date, disableUntil?: Date, disableSince?: Date): Date[] {
  const fromDate = disableUntil && (disableUntil >= from) ? startOfDay(addDay(disableUntil)) : from;
  const toDate = disableSince && (disableSince < to) ? endOfDay(delDay(disableSince)) : to;
  return [ fromDate, toDate ]
}

/**
 * Проверяем что бы все значения в календаре вошли в ограничения (для одинарного календаря)
 * @param value Дата
 * @param disableUntil Ограничение периода от
 * @param disableSince Ограничение периода до
 */
export function correctDisableDatepickerSingle(value: Date, disableUntil?: Date, disableSince?: Date): Date {
  // Если выходим за нижнюю границу (не кукожить, для наглядности)
  if (value < disableUntil) {
    return startOfDay(addDay(disableUntil))
  }
   // Если выходим за верхнюю границу (не кукожить, для наглядности)
  if (value > disableSince) {
    return endOfDay(delDay(disableSince))
  }
  return value
}

/**
 * Добавление одного дня к дате
 * @param date Обрабатываемая дата
 */
export function addDay(date: Date): Date {
  return new Date(date.getTime() + 86400000);
}

/**
 * Удаление одного дня от даты
 * @param date Обрабатываемая дата
 */
export function delDay(date: Date): Date {
  return new Date(date.getTime() - 86400000);
}

/**
 * Обрезка времени у даты (итогом будет начало дня)
 * @param date Обрабатываемая дата
 */
export function startOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

/**
 * Обрезка времени у даты (итогом будет конец дня)
 * @param date Обрабатываемая дата
 */
export function endOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

/**
 * Добавляем месяцы к дате
 * @param date
 * @param month
 */
export function addMonth(date: Date, month: number): Date {
  return new Date(date.setMonth(date.getMonth() + month))
}
