import { Injectable } from '@angular/core';

import { ReportsService } from './reports.service';

/**
 * Сервис для работы с геозонами
 */
@Injectable()
export class CleanerService {

  /**
   * Constructor
   */
  constructor(
    private reportsService: ReportsService
  ) {
  }

  /**
   * Delete entity
   * @param cn
   */
  public delete(cn: ICleanerNotification) {
    switch (cn.type) {
      case CleanEntityType.Report:
        this.reportsService.deleteFromLists(cn.entityId);
        break;

      case CleanEntityType.Reports:
        this.reportsService.clearQueue();
        break
    }
  }
}

/**
 * Type of cleaning entity
 */
export enum CleanEntityType {
  File,
  Report,
  Reports
}

/**
 * Cleaner message to front
 */
export interface ICleanerNotification {
  /**
   * Entity identifier
   */
  entityId: string;
  /**
   * Entity type
   */
  type: CleanEntityType;
}