/** Тип длительности */
export enum DurationType {
  /** Миллисекунда */
  MILLISECOND = 1,
  /** Секунда */
  SECOND,
  /** Минута */
  MINUTE,
  /** Час */
  HOUR,
  /** День */
  DAY,
  /** Месяц */
  MONTH,
  /** Год */
  YEAR
}

/**
 * Получение списка всех типов длительности
 */
export function getAllDurationTypes(): DurationType[] {
  const result: DurationType[] = [];
  for (let i = 1; i <= 7; i++) {
    result.push(i as DurationType);
  }
  return result;
}

/**
 * Получение наименование типа длительности
 * @param type Тип длительности
 */
export function getDurationTypeName(type: DurationType): string {
  switch (type) {
    case DurationType.MILLISECOND: return 'enums.time.duration-type.milli';
    case DurationType.SECOND: return 'enums.time.duration-type.second';
    case DurationType.MINUTE: return 'enums.time.duration-type.minute';
    case DurationType.HOUR: return 'enums.time.duration-type.hour';
    case DurationType.DAY: return 'enums.time.duration-type.day';
    case DurationType.MONTH: return 'enums.time.duration-type.month';
    case DurationType.YEAR: return 'enums.time.duration-type.year';
    default: return 'enums.time.duration-type.unknown';
  }
}

/**
 * Преобразование длительности ко значению времени
 * @param value Значение
 * @param type Тип длительности
 */
export function durationToTime(value: number, type: DurationType): number {
  switch (type) {
    case DurationType.MILLISECOND: return value;
    case DurationType.SECOND: return value * 1000;
    case DurationType.MINUTE: return value * 60000;
    case DurationType.HOUR: return value * 3600000;
    case DurationType.DAY: return value * 86400000;
    case DurationType.MONTH: return value * 2592000000;
    case DurationType.YEAR: return value * 946080000000;
    default: return value;
  }
}
