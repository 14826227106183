import { Component, EventEmitter, forwardRef, Output, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IListItem } from '../../classes/IListItem';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ListEditComponent),
  multi: true
};

/**
 * Компонент для управления списком записей
 */
@Component({
  selector: 'list-edit',
  templateUrl: './list-edit.component.html',
  styleUrls: ['./list-edit.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class ListEditComponent
implements ControlValueAccessor {

  /**
   * Генератор события клика по кнопке
   * отображения детальной информации
   */
  @Output() public detailClick = new EventEmitter<IListItem<any>>();

  /**
   * Генератор события клика по кнопке
   * выбора из списка
   */
  @Output() public listClick = new EventEmitter<any>();

  /**
   * Генератор события клика по кнопке
   * добавления новой записи
   */
  @Output() public addClick = new EventEmitter<any>();

  /** Признак блокировки компонента */
  public isDisabled = false;

  /**
   * Значение, отображаемое пользователю
   * (если в списке всего один элемент)
   */
  public get displayValue(): string {
    return this.value && this.value.length === 1
      ? this.value[0].name
      : '';
  }

  /** Значение, привязанное к компоненту */
  public value: IListItem<any>[];

  /**
   * Установка нового значения компонента
   * @param value Новое значение компонента
   */
  public writeValue(value: IListItem<any>[]) {
    this.value = value;
  }

  /**
   * Установка признака заблокированности компонента
   * @param disabled Признак заблокированности
   */
  public setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  /**
   * Регистрация функции, вызываемой при измении значения компонента
   * @param fn Функция, вызываемая при изменении
   */
  public registerOnChange(fn: any) {
    this.onChangeCb = fn;
  }

  /**
   * ХЗ что это, пусть будет
   */
  public registerOnTouched(fn: any) {
    this.onTouchedCb = fn;
  }

  /**
   * Обработка клика на кнопку отображения детальной информации
   * @param item Элемент, подробную информацию о котором необходимо отобразить
   */
  public onDetailClick(item?: IListItem<any>) {
    if (!item) {
      item = this.value && this.value.length
        ? this.value[0]
        : null;
    }
    this.detailClick.emit(item);
  }

  /**
   * Обработка клика на кнопку выбора из списка
   */
  public onListClick() {
    if (this.isDisabled) { return; }

    this.listClick.emit();
  }

  /**
   * Обработка клика на кнопку добавления записи
   */
  public onAddClick() {
    if (this.isDisabled) { return; }

    this.addClick.emit();
  }

  /**
   * Получение признака необходимости отображения
   * кнопки вызова детальной информации
   */
  public get isDetailClickVisible() {
    return this.detailClick.observers.length > 0;
  }

  /**
   * Получение признака необходимости отображения
   * кнопки добавления новой записи
   */
  public get isAddClickVisible() {
    return this.addClick.observers.length > 0;
  }

  /**
   * Получение признака того, что компонент
   * должен вести себя так, будто в списке всего одна запись
   */
  public get isSingleItem() {
    return !this.value || this.value.length < 2;
  }

  /**
   * Получение стиля для блока с кнопками действий
   */
  public actionsStyle() {
    let actionsWidth = 50;
    if (this.isDetailClickVisible) { actionsWidth += 25; }
    if (this.isAddClickVisible) { actionsWidth += 25; }

    return {
      width: `${actionsWidth}px`
    };
  }

  /**
   * Обработка клика по кнопке удаления элемента компонента
   * @param item Элемент, который необходимо удалить из списка
   */
  public clean(item?: IListItem<any>) {
    if (this.isDisabled || !this.value || !this.value.length) { return; }

    const index = item
      ? this.value.indexOf(item)
      : 0;
    this.value.splice(index, 1);
    this.onChangeCb(this.value);
    this.onTouchedCb();
  }

  // tslint:disable-next-line:no-empty
  private onChangeCb: (_: any) => void = () => { };
  // tslint:disable-next-line:no-empty
  private onTouchedCb: () => void = () => { };
}
