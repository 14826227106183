import { Component } from '@angular/core';

import { IActivityDetails } from '../../../../shared/crm/clients/IActivityDetails';
import { UnitActivityType } from '../../../../shared/crm/stats/UnitActivityType';
import { IListItem } from '../../../classes/IListItem';
import { ClientService } from '../../../services/client.service';
import { LoadingService } from '../../../services/loading.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';

/**
 * Компонент для отображения детализации активности ТС
 */
@Component({
  selector: 'units-activity',
  templateUrl: './units-activity.component.html',
  styleUrls: ['./units-activity.component.scss']
})
export class UnitsActivityComponent {

  /** Список записей с активностью */
  public activity: IActivityDetails;

  /** Выбранный год */
  public year: number;

  /** Выбранный месяц */
  public month: number;

  /** Список доступных для выбора годов */
  public years: number[];

  /** Список доступных для выбора месяцев */
  public months: IListItem<number>[] = [];

  /** Список дней месяца, по которому необходимо отобразить детализацию */
  public days: number[];

  /**
   * Идентификатор учетной записи,
   * информация по которой отображается в компоненте
   */
  public accountId: string;

  /**
   * Список учетных записей, по которым текущий
   * пользователь может видеть информацию
   */
  public accounts: IListItem<string>[] = [];

  /**
   * Конструктор
   * @param clientService Сервис работы с текущим клиентом
   * @param loadingService Сервис отображения процесса загрузки
   * @param store Сервис для хранения данных
   */
  constructor(
    private clientService: ClientService,
    private loadingService: LoadingService,
    store: StoreService
  ) {
    const date = new Date();
    this.years = [date.getFullYear(), date.getFullYear() - 1, date.getFullYear() - 2];
    this.months = [
      { id: 0, name: 'classes.time.month-1' },
      { id: 1, name: 'classes.time.month-2' },
      { id: 2, name: 'classes.time.month-3' },
      { id: 3, name: 'classes.time.month-4' },
      { id: 4, name: 'classes.time.month-5' },
      { id: 5, name: 'classes.time.month-6' },
      { id: 6, name: 'classes.time.month-7' },
      { id: 7, name: 'classes.time.month-8' },
      { id: 8, name: 'classes.time.month-9' },
      { id: 9, name: 'classes.time.month-10' },
      { id: 10, name: 'classes.time.month-11' },
      { id: 11, name: 'classes.time.month-12' }
    ];

    this.year = date.getFullYear();
    this.month = date.getMonth();

    // Получаем список учетных записей, по которым можно получать детализацию
    this.loadingService.withLoading(
      this.clientService.getAccounts(),
      (accounts) => {
        this.accounts = accounts?.sort(localeSort);

        const user = store.user;
        if (user && this.accounts.some((a) => a.id === user.account.id)) {
          this.accountId = user.account.id;
        } else if (this.accounts.length) {
          this.accountId = this.accounts[0].id;
        }
      }
    );
  }

  /**
   * Получение активности ТС за выбранный месяц
   */
  public getActivity() {
    // Заполняем дни
    this.days = [];
    const lastDay = new Date(this.year, this.month + 1, 0, 0, 0, 0, 0).getDate();
    for (let i = 1; i <= lastDay; i++) {
      this.days.push(i);
    }

    this.loadingService.withLoading(
      this.clientService.getUnitsActivity(this.accountId, this.year, this.month),
      (activity) => {
        this.activity = activity;
        this.loadingService.stopLoading();
      }
    );
  }

  /**
   * Получение класса ячейки для статуса активности ТС в день
   * @param type Тип активности ТС
   */
  public getDayClass(type: UnitActivityType) {
    switch (type) {
      case UnitActivityType.ACTIVE: return 'active';
      case UnitActivityType.UNIT_BLOCKED: return 'unit-blocked';
      case UnitActivityType.CLIENT_BLOCKED: return 'client-blocked';
      case UnitActivityType.NO_DATA: return 'no-data';
      default: return null;
    }
  }
}
