import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Metrika } from 'ng-yandex-metrika';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';

import { Locale } from '../shared/users';

import { HostConfigService } from './services/host-config.service';
import { LoadingService } from './services/loading.service';
import { existsEnumValue } from './utils/enums';

/**
 * Корневой компонент сайта
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /**
   * Конструктор
   * @param primeNGConfig
   * @param loadingService Сервис для отображения процесса загрузки
   * @param translator Сервис для перевода
   * @param hostConfigService Сервис настроек хоста
   * @param router
   * @param metrika
   */
  constructor(
    public loadingService: LoadingService,
    private primeNGConfig: PrimeNGConfig,
    translator: TranslateService,
    hostConfigService: HostConfigService,
    private router: Router,
    private metrika: Metrika
  ) {
    const lang = translator.getBrowserLang();

    translator.setDefaultLang(Locale.EN);
    translator.use(existsEnumValue(Locale, lang) ? lang : Locale.EN);

    translator.get('primeng').subscribe((res) => this.primeNGConfig.setTranslation(res));

    tranny = translator;

    hostConfigService.load();

    this.router.events
      .pipe(filter(event => (event instanceof NavigationEnd)))
      .subscribe((navigationEnd: NavigationEnd) => {
        this.metrika.hit(navigationEnd.urlAfterRedirects, {
          referer: navigationEnd.url
        });
      });
  }

  /**
   * Возвращает класс иконки для тоста
   * @param severity Тип сообщения
   */
  public getToastIconClass(severity: string): string {
    switch (severity) {
      case 'success':
        return 'pi-check-circle';

      case 'info':
        return 'pi-info-circle';

      case 'warn':
        return 'pi-exclamation-circle';

      case  'error':
        return 'pi-ban';

      default:
        return '';
    }
  }
}

/**
 * Глобальная переменная для хранения {TranslateService}
 * Нужна для доступа к сервису извне компонентов
 * TODO: Перенести и переименовать
 */
export let tranny: TranslateService;
