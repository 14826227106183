/**
 * Вкладка с детальной информацией
 */
export class DetailTab {
  /**
   * Конструктор
   * @param name Наименование вкладки
   * @param hidden Признак скрытой вкладки
   */
  constructor(public name: string, public hidden: boolean = false) { }
}
