import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { ConfigService } from '../../services/config.service';
import { HelpService } from '../../services/help.service';

@Component({
  selector: 'app-navigation-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent extends DialogComponent<{}, boolean> {

  /** Заголовок окна */
  public title: string;

  /** Контейнер */
  @ViewChild('changelog', {static: false}) private changelog: ElementRef;

  /**
   * Конструктор
   * @param dialogService
   * @param router
   * @param helpService
   * @param configService
   */
  constructor(
    dialogService: DialogService,
    private router: Router,
    private helpService: HelpService,
    public configService: ConfigService
  ) {
    super(dialogService);

    this.title = 'component.navigation.changelog';
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: any) {
    return super.fillData(data);
  }

  /**
   * Closes dialog
   */
  close():void {
    this.dialogService.removeDialog(this);
  }
}