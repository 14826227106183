/**
 * Данные по району для запроса
 */
export interface IDistrictFilter {

  /**
   * Идентификатор
   */
  districtIds: string[];

  /**
   * Тип фильтрации
   */
  filterType: DistrictFilterType;
}

/**
 * Тип фильтрации по району
 */
export enum DistrictFilterType {

  /**
   * По всем
   */
  All,

  /**
   * По не указанным
   */
  Undefined,

  /**
   * По идентификатору
   */
  ById
}
