import { Component } from '@angular/core';

/**
 * Хаб для дилера
 */
@Component({
  selector: 'diller',
  templateUrl: './diller.component.html',
  styleUrls: ['./diller.component.scss']
})
export class DillerComponent {

}
