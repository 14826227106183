import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAccountExport, IAccountExportSettings } from '../../shared/accounts/IAccountExportSettings';
import { IAccountImportSettings } from '../../shared/accounts/IAccountImportSettings';
import { IUnitExport, IUnitExportSettings } from '../../shared/units/IUnitExportSettings';
import { IUnitImportSettings } from '../../shared/units/IUnitImportSettings';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с импортом/экспортом
 */
@Injectable()
export class ExportImportService {

  /**
   * Базовая часть адреса сервиса для экспорта
   */
  private readonly baseExportUrl = `${this.configService.url}/export`;

  /**
   * Базовая часть адреса сервиса для импорта
   */
  private readonly baseImportUrl = `${this.configService.url}/import`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Экспорт объектов мониторинга
   * @param body Настройки экспорта
   */
  public exportUnits(body: IUnitExportSettings): Observable<IUnitExport> {
    const url = `${this.baseExportUrl}/units`;
    return this.http.post<IUnitExport>(url, body);
  }

  /**
   * Экспорт объектов мониторинга в файл
   * @param body Настройки экспорта
   */
  public exportUnitsFile(body: IUnitExportSettings): Observable<Blob> {
    const url = `${this.baseExportUrl}/units/files`;
    const options: object = {responseType: 'blob'};
    return this.http.post<Blob>(url, body, options);
  }

  /**
   * Импорт в объекты мониторинга
   * @param body Настройки импорта
   */
  public importUnits(body: IUnitImportSettings): Observable<string[]> {
    const url = `${this.baseImportUrl}/units`;
    return this.http.post<string[]>(url, body);
  }

  /**
   * Экспорт учетных записей
   * @param body Настройки экспорта
   */
  public exportAccounts(body: IAccountExportSettings): Observable<IAccountExport> {
    const url = `${this.baseExportUrl}/accounts`;
    return this.http.post<IAccountExport>(url, body);
  }

  /**
   * Экспорт учетных записей в файл
   * @param body Настройки экспорта
   */
  public exportAccountsFile(body: IAccountExportSettings): Observable<Blob> {
    const url = `${this.baseExportUrl}/accounts/files`;
    const options: object = {responseType: 'blob'};
    return this.http.post<Blob>(url, body, options);
  }

  /**
   * Импорт учетных записей
   * @param body Настройки импорта
   */
  public importAccounts(body: IAccountImportSettings): Observable<string[]> {
    const url = `${this.baseImportUrl}/accounts`;
    return this.http.post<string[]>(url, body);
  }
}
