import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as dateformat from 'dateformat';
import { DialogService } from 'ng2-bootstrap-modal';
import { throwError, timer } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { TrackColoringType } from '../../../shared/units/TrackColoringType';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { MapService } from '../../services/map.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { IClientRace, IRaceEvent, IRaceUnit, RaceService } from '../../services/race.service';
import { StoreService } from '../../services/store.service';
import { UsersService } from '../../services/users.service';
import { localeSort } from '../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';
import { SelectPeriodComponent } from '../select-period/select-period.component';

/**
 * Компонент для работы с рейсами
 */
@Component({
  selector: 'app-race',
  templateUrl: './race.component.html',
  styleUrls: ['./race.component.scss']
})
export class RaceComponent implements OnInit {

  /** Доступная толщина линий трека */
  public lineWidths: IListItem<number>[];

  /** Список объектов */
  public units: IListItem<string>[];

  /** Выбранный объект мониторинга */
  public selected: IRaceUnit;

  /** Список всех доступных для построения рейса объектов мониторинга */
  private allUnits: IListItem<string>[];

  /**
   * Конструктор
   * @param store Сервис для хранения данных мониторинга
   * @param raceService Сервис работы с рейсом
   * @param mapService Сервис для работы с картой
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param crudService Сервис работы с ДИУП
   * @param monitoringService Сервис мониторинга
   * @param loadingService Сервис для отображения процесса загрузки
   * @param usersService Сервис для работы с пользователями
   * @param translator Сервис перевода
   * @param route
   * @param router
   */
  constructor(
    public store: StoreService,
    public raceService: RaceService,
    public mapService: MapService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private crudService: CRUDService,
    private monitoringService: MonitoringService,
    private loadingService: LoadingService,
    private usersService: UsersService,
    private translator: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.lineWidths = this.getLineWidths();
  }

  /**
   * Получение признака того, что все рейсы выделены
   */
  get allChecked() {
    return this.raceService.races.every((race) => race.checked);
  }

  /**
   * Получение признака блокировки кнопки получения рейса
   */
  get isGetRaceButtonDisabled() {
    return !this.raceService.unitId || (this.raceService.customColoring &&
      ((this.raceService.coloringType === TrackColoringType.BY_SENSOR && !this.raceService.selectedSensor) ||
        (this.raceService.coloringType === TrackColoringType.BY_SPEED && !this.raceService.speedColors.length)));
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Получение рейса
   */
  public getRace() {
    if (!this.selected) {
      return;
    }

    this.loadingService.wrap(this.raceService.loadRace(this.selected), true).subscribe();
  }

  /**
   * Переключение выбранности события рейса
   * @param e Событие
   */
  public toggleEventSelected(e: IRaceEvent) {
    e.selected = !e.selected;
  }

  /**
   * Обработка изменения выбранного объекта мониторинга
   * @param notResetSelectedSensor Признак того, что не нужно сбрасывать
   * выбранный датчик при успешном получении объекта. (Нужно, когда восстанавливаем
   * значение при переоткрытии вкладки)
   */
  public onSelectedUnitChanged(notResetSelectedSensor?: boolean) {
    if (!this.raceService.unitId) {
      this.selected = null;
      this.raceService.selectedSensor = null;
      return;
    }

    const unit$ = this.raceService.getUnit(this.raceService.unitId);
    const onError = (err) => {
      this.selected = null;
      this.raceService.unitId = null;
      this.raceService.selectedSensor = null;
      return throwError(err);
    };

    this.loadingService.wrap(unit$, true)
      .pipe(catchError(onError))
      .subscribe((unit) => {
        this.selected = unit;

        if (!notResetSelectedSensor) {
          this.raceService.selectedSensor = null;
        }

        if (!this.units.some((u) => u.id === unit.id)) {
          this.units.push({ id: unit.id, name: unit.name });
        }
      });
  }

  /**
   * Удаление всех рейсов
   */
  public removeAllRaces() {
    this.raceService.deleteAllRaces();
  }

  /**
   * Удаление рейса
   * @param race Рейс
   */
  public removeRace(race: IClientRace) {
    this.raceService.deleteRace(race);
  }

  /**
   * Изменение выбранности рейса
   * @param race Рейс
   */
  public toggleRaceChecked(race: IClientRace) {
    this.raceService.toggleRaceChecked(race);
  }

  /**
   * Изменение выбранности всех рейсов
   */
  public toggleAllChecked() {
    const isAllChecked = this.allChecked;
    this.raceService.setAllRacesChecked(!isAllChecked);
  }

  /**
   * Перемещение на начало рейса
   * @param race Рейс
   */
  public moveToStartRace(race: IClientRace) {
    this.raceService.moveToStartRace(race);
  }

  /**
   * Перемещение в конец рейса
   * @param race Рейс
   */
  public moveToEndRace(race: IClientRace) {
    this.raceService.moveToEndRace(race);
  }

  /**
   * Выбор периода
   */
  public selectPeriod() {
    const data = {
      from: this.raceService.from,
      to: this.raceService.to,
      disableUntil: this.usersService.getUserHistoryPeriodFrom,
      disableSince: this.usersService.getUserHistoryPeriodTo
    };

    this.dialogService.addDialog(SelectPeriodComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        // Костыль: Режем секунды и миллисекунды, для корректного запроса точек с сервера
        // result.to.setSeconds(0);
        // result.to.setMilliseconds(0);

        this.raceService.from = result.from;
        this.raceService.to = result.to;
      });
  }

  /**
   * Переключение воспроизведения рейса
   * @param race Рейс
   */
  public toggleRacePlaying(race: IClientRace) {
    race.isPlaying = !race.isPlaying;
    if (race.isPlaying && !race.checked) {
      this.toggleRaceChecked(race);
    }
  }

  /**
   * Получение признака пустого рейса
   * @param race Рейс
   */
  public isEmptyRace(race: IClientRace) {
    return !race.p || !race.p.length;
  }

  /**
   * Добавление геозоны на основе точек рейса
   * @param race Рейс
   */
  public addGeozone(race: IClientRace) {
    if (!race.p || !race.p.length) {
      this.modalService.showInfo('component.race.no-points');
      return;
    }

    this.raceService.raceForGeozone = race;
    this.router.navigate([`./geozone-create`], { relativeTo: this.route }).then();
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.store?.units?.length) {
      const units = this.store.units
        ?.map((unit) => ({ id: unit.id, name: unit.name }))
        ?.sort(localeSort);
      this.onGetUnits(units);

    } else {
      this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
        .subscribe((units) => {
          this.allUnits = units?.sort(localeSort);
          this.onGetUnits(this.allUnits);
        });
    }
  }

  /**
   * Печать рейсов на карте
   */
  public print() {
    const mask = this.translator.instant('formats.date-short-time');
    const distance = this.translator.instant('ui.km');
    const print = document.getElementById('print');
    print.innerText = '';

    this.raceService.races.forEach((race) => {
      const header = document.createElement('p');
      header.style.textAlign = 'center';
      header.innerHTML = `<b style="color: ${race.color};">${race.unitName}</b>: ${dateformat(
        race.f,
        mask)} - ${dateformat(race.t, mask)} (${(Math.round(race.mi * 1000) / 1000)} ${distance})`;
      print.appendChild(header);
    });

    this.mapService.getMapImage().subscribe((data) => {
      const img = document.createElement('img');
      img.src = data;
      img.style.position = 'relative';
      print.appendChild(img);

      // Задержка нужна чтобы DOM успел обновится
      timer(100).subscribe(() => window.print());
      // Обновление слоя карт (костыль для печати яндекс карт)
      timer(500).subscribe(() => this.mapService.refreshMapSubject.next());
    });
  }

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.raceService.unitId
      ? this.allUnits.filter((u) => u.id === this.raceService.unitId)
      : [];

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.race.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {

        const unit = result?.shift();

        // Ничего не поменялось
        if (this.selected?.id === unit?.id) {
          return;
        }

        this.raceService.unitId = unit?.id;
        this.onSelectedUnitChanged();
      });
  }

  /**
   * Получение доступной толщины линии трека
   */
  private getLineWidths(): IListItem<number>[] {
    const result: IListItem<number>[] = [];
    for (let i = 1; i < 16; i++) {
      result.push({ id: i, name: `${i}px` });
    }
    return result;
  }

  /**
   * Обработка загрузки списка объектов, доступных для построения рейса
   * @param units Список загруженных объектов
   */
  private onGetUnits = (units: IListItem<string>[]) => {
    this.units = units;
    this.onSelectedUnitChanged(true);
  };
}
