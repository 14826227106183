import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('waiter')) {
      return next.handle(req);
    }

    this.loadingService.startLoading();
    const headers = req.headers.delete('waiter');
    req = req.clone({ headers });
    return next.handle(req)
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }
}
