import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IListItem } from '../../classes/IListItem';
import { ConfigService } from '../../services/config.service';
import { LoadingService } from '../../services/loading.service';

/**
 * Сервис для работы с ретрансляторами
 */
@Injectable()
export class BgRepeatersService {
  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${ this.configService.url }/repeaters`;

  /**
   * Подписка на открытие окна добавления ретранслятора
   */
  public visibleRepeater$: Subject<{ isOpen: boolean; id?: string }> = new Subject<{ isOpen: boolean; id?: string }>()

  /**
   * Подписка на загрузку списка
   */
  public loadList$: Subject<void> = new Subject<void>();

  /**
   * Консруктор
   * @param http
   * @param configService
   * @param loadingService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Список ретрансляторов
   */
  public getList(): Observable<Array<IRepeater>> {
    const options = { headers: this.loadingService.waiterHeader };
    return this.http.get<IRepeater[]>(this.baseUrl, options);
  }

  /**
   * Один ретранслятор
   * @param id идентификатор
   */
  public getOne(id: string): Observable<IRepeater> {
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.get<IRepeater>(`${ this.baseUrl }/${ id }`, options);
  }

  /**
   * Добавление ретранслятора
   * @param r
   * @param units
   */
  public add(r: IRepeater, units: Array<IListItem<string>>): Observable<Array<IRepeater>> {
    const options = { headers: this.loadingService.waiterHeader }
    const body = {
      repeater: {
        ...r,
        periods: r.periods.map((p) => ({
          isNew: p.isNew,
          begin: (p.begin as Date)?.setSeconds(0, 0) || null,
          end: (p.end as Date)?.setSeconds(59, 0) || null
        } as IRepeaterPeriod))
      }, units
    };
    return this.http.post<Array<IRepeater>>(this.baseUrl, body, options)
  }

  /**
   * Обновление ретранслятора
   * @param repeater
   */
  public update(repeater: IRepeater) {
    const options = { headers: this.loadingService.waiterHeader }
    const body = {
      ...repeater,
      periods: repeater.periods.map((p) => ({
        isNew: p.isNew,
        begin: (p.begin as Date)?.setSeconds(0, 0) || null,
        end: (p.end as Date)?.setSeconds(59, 0) || null
      } as IRepeaterPeriod))
    };
    return this.http.put(this.baseUrl, body, options)
  }

  /**
   * Удаление ретранслятора
   * @param id
   */
  public remove(id: string): Observable<any> {
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.delete<any>(`${ this.baseUrl }/${ id }`, options)
  }

  /**
   * Список аккаунтов
   */
  public getAccounts(): Observable<IListItem<string>[]> {
    const url = `${ this.baseUrl }/accounts`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получение списка объектов мониторинга
   */
  public getUnitsList(accountId: string): Observable<IRepeaterUnit[]> {
    const params = new HttpParams().append('accountId', accountId)
    const headers = this.loadingService.waiterHeader
    const url = `${ this.baseUrl }/units`;
    return this.http.get<IRepeaterUnit[]>(url, { headers, params });
  }

  /**
   * Получение истории
   * @param id
   */
  public getHistory(id: string): Observable<IRepeaterHistory[]> {
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.get<IRepeaterHistory[]>(`${this.baseUrl}/${id}/history`, options)
  }

  /**
   * Получение наименования протокола ретрансляции
   * @param protocol Протокол
   */
  public getRepeaterProtocolName(protocol: RepeaterProtocols): string {
    switch (protocol) {
      case RepeaterProtocols.WIALON_IPS:
        return 'enums.retranslation.protocol.wips';
      case RepeaterProtocols.EGTS:
        return 'enums.retranslation.protocol.egts';
      default:
        return 'enums.retranslation.protocol.unknown';
    }
  }
}

/**
 * Запись об объекте для отображения в списке объектов
 */
interface IRepeaterUnit {
  /** Идентификатор объекта */
  id: string;
  /** Наименование объекта */
  name: string;
  /** Идентификатор устройства */
  uid: string;
}

/**
 * Ретранслятор
 */
export interface IRepeater {
  id: string;
  accountId: string;
  name: string;
  protocol: string;
  address: string;
  auth: string;
  uid: string;
  periods: Array<IRepeaterPeriod>;
  error?: string;
  account?: IListItem<string>;
  unit?: IListItem<string>;
  deletedAt: number;
}

/**
 * Период ретрансляции
 */
export interface IRepeaterPeriod {
  isNew?: boolean;
  type?: RepeaterPeriodType;
  begin: Date | number;
  end: Date | number;
}

/**
 * Тип периода
 */
export enum RepeaterPeriodType {
  /** Будущее */
  FUTURE,
  /** Прошлое */
  OLD
}

/**
 * Протокол ретрансляции
 */
export enum RepeaterProtocols {
  /** Wialon IPS (ex BG) */
  WIALON_IPS = 'wialon-ips',
  /** EGTS */
  EGTS = 'egts'
}

/**
 * История по ретранслятору
 */
export interface IRepeaterHistory {
  userId?: string;
  user?: IListItem<string>;
  repeaterId?: string;
  repeater?: string;
  type: RepeaterHistoryType;
  date: number;
  diff?: Array<IDiff>;
}

/**
 * Дифка
 */
export interface IDiff {
  field: string;
  o?: string | number;
  n?: string | number;
}

/**
 * Типы истории
 */
export enum RepeaterHistoryType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}