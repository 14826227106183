import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { IGeozoneGroup } from '../../../../shared/geozone-group/IGeozoneGroup';
import { GeozoneGroupService } from '../../../services/geozone-group.service';
import { GeozonesService } from '../../../services/geozones.service';
import { ToastService } from '../../../services/toast.service';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/** Компонент для редактирования групп геозон */
@Component({
  selector: 'geozones-edit-group',
  templateUrl: './geozones.edit-group.component.html',
  styleUrls: ['./geozones.edit-group.component.scss']
})
export class GeozonesEditGroupComponent implements OnInit {

  /** Строка поиска */
  public search: string;

  /** Редактируемая группа */
  public group: IGeozoneGroup;

  /** Форма с валидацией */
  public editForm: FormGroup = new FormGroup({});

  /**
   * Конструктор
   */
  constructor(
    private geozoneGroupService: GeozoneGroupService,
    private geozonesService: GeozonesService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  /**
   * При инициализации компонента
   */
  ngOnInit(): void {
    // Получаем группу и список геозон
    const createOrLoad = (params) => params.id == null
      ? of({ name: '', descr: '', geozones: [] })
      : this.geozoneGroupService.getOne(params.id);

    this.route.params.pipe(switchMap(createOrLoad), tap((g) => g?.geozones?.sort(localeSort))).subscribe((group) => {
      this.group = group

      // Инициализация валидации формы
      this.editForm = this.formBuilder.group({
        name: [this.group?.name, [Validators.required]],
        descr: [this.group?.descr, []]
      }, { updateOn: 'change' })

    }, () => this.router.navigate(['../'], { relativeTo: this.route }))
  }

  /**
   * Открывает окно выбора геозон
   */
  public selectGeozones(): void {
    const dialog = (items) => this.dialogService.addDialog(SelectItemsComponent, {
      items,
      selected: items.filter((g) => this.group?.geozones.some((gr) => g.id === gr.id)),
      title: 'component.geo.edit-group.select-geo-title',
      withSearch: true
    })

    this.geozonesService.getAllForList()
      .pipe(
        switchMap(dialog),
        filter((result) => !!result))
      .subscribe((result) => this.group.geozones = result)
  }

  /**
   * Удаляем геозону из группы
   * @param id Идентификатор геозоны
   */
  public deleteGeozone(id: string): void {
    this.group.geozones = this.group?.geozones.filter((g) => g.id !== id)
  }

  /**
   * Сохраняем группу
   */
  public save() {
    if (!this.editForm.valid) return;
    this.geozoneGroupService.updateGroup(this.group)
      .subscribe(() => {
        this.toastService.success('component.geo.edit-group.success')
        this.router.navigate(['/monitoring/geozones']).finally()
      })
  }

  /**
   * Действие при отмене (переход назад)
   */
  public cancel() {
    this.router.navigate(['monitoring/geozones']).then();
  }
}

