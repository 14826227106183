import { Component, OnChanges, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap, map } from 'rxjs/operators';

import { IMessage } from '../../../../shared/messages/IMessage';
import { IMessagesRequest } from '../../../../shared/messages/IMessagesRequest';
import { BGMessagesService } from '../../../services/b-g-messages.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { PointsService } from '../../../services/points.service';
import { StoreService } from '../../../services/store.service';
import { ModalResult } from '../../modal/modal.component';
import { PointEditComponent } from '../../point/edit/point.edit.component';
import { AbstractMessagesTableComponent } from '../table/AbstractMessagesTableComponent';

/**
 * Компонент для просмотра таблицы с точками по объекту мониторинга
 */
@Component({
  selector: 'messages-points',
  templateUrl: './messages.points.component.html',
  styleUrls: ['./messages.points.component.scss', '../table/messages-table.scss']
})
export class MessagesPointsComponent
  extends AbstractMessagesTableComponent<IClientMessage>
  implements OnInit, OnChanges {

  /** Признак диллера */
  public isDealer: boolean;

  /**
   * Конструктор
   * @param loadingService Сервис отображения процесса загрузки
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис модальных окон
   * @param messagesService Сервис работы с сообщениями
   * @param dialogService Сервис диалоговых окон
   * @param pointsService Сервис для работы с точками
   * @param store Сервис для хранения данных
   */
  constructor(
    loadingService: LoadingService,
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private messagesService: BGMessagesService,
    private dialogService: DialogService,
    private pointsService: PointsService,
    store: StoreService
  ) {
    super(loadingService, (req: IMessagesRequest) =>
      this.messagesService.getList(req)
        .pipe(map(({ rows, count }) => ({
          rows: rows.map((row) => ({
            ...row,
            checked: false,
            valid: !!row.d[7],
            tValid: !!row.d[8]
          })),
          count
        }))));

    this.isDealer = store.user && store.user.isDealer;
  }

  /**
   * Получение состояния выбора сообщений
   */
  public get checked(): boolean {
    return this.rows.some((row) => row.checked);
  }

  /**
   * Получение ключа текста о количестве записей
   */
  public get records() {
    let records = 'component.messages.record-';
    if (this.sel % 100 > 4 && this.sel % 100 <= 20) {
      records += '3';

    } else if (this.sel % 10 === 1) {
      records += '1';

    } else if (this.sel % 10 > 1 && this.sel % 10 < 5) {
      records += '2';

    } else {
      records += '3';
    }

    return records;
  }

  /**
   * Получение количестве выбранных строк
   */
  public get sel() {
    return this.rows.filter((x) => x.checked).length;
  }

  /**
   * Выбор строки таблицы
   * @param row Строка таблицы
   */
  public selectRow(row: IClientMessage) {
    super.selectRow(row);

    if (row && row.d && (!row.deleted || row.valid)) {
      this.monitoringService.showMessagePoint(row, this.messagesService.selectedUnit.id);
    }
  }

  /**
   * Установка валидности выбранных сообщений
   * @param valid Валидность
   */
  public setValid(valid: boolean) {
    const messages = this.rows
      .filter((x) => x.checked && x.valid !== valid)
      .map((x) => x.i);

    this.modalService.showConfirm('component.messages.confirm-' + (valid ? '1' : '2'))
      .pipe(
        filter((result) => result === ModalResult.OK),
        flatMap(() => this.loadingService
          .wrap(this.pointsService.setValid(messages, valid), true)))
      .subscribe(this.updatePage);
  }

  /**
   * Изменение выбора всех сообщений
   */
  public toggleChecked() {
    const checked = !this.checked;
    this.rows.forEach((x) => x.checked = checked);
  }

  /**
   * Проверка валидности выбранных сообщений
   * @param valid Валидность
   */
  public checkValid(valid: boolean) {
    return this.rows.some((x) => x.checked && x.valid === valid);
  }

  /**
   * Редактирование строки таблицы
   * @param row Строка таблицы
   */
  public editRow(row: IClientMessage) {
    if (!row) {
      return;
    }

    const data = { id: row.i };
    this.dialogService.addDialog(PointEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.updatePage);
  }

  /**
   * Очистка данных по сообщениям
   */
  public clear() {
    this.monitoringService.changeMessagesRequest(null);
    this.monitoringService.hideMessagePoint();
  }

  /**
   * Установка ширины таблицы по ширине колонок
   */
  protected setTableWidth() {
    const box = this.tableContainer.nativeElement;
    const boxWidth = box.offsetWidth + box.offsetLeft - 17;
    this.tableWidth = this.columns.reduce((a, b) => a + b.width, 0);
    this.tableWidth += this.isDealer ? 60 : 0;
    const dif = boxWidth - this.tableWidth;
    this.lastColumnWidth = dif < 0 ? 0 : dif;
  }
}

/**
 * Сообщение для использования на стороне клиента
 */
interface IClientMessage extends IMessage {
  /** Признак удаленного сообщения */
  deleted?: boolean;
  /** Признак выбранной записи */
  checked?: boolean;
  /** Признак валидности записи */
  valid?: boolean;
}
