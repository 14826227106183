import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { ReportDocumentType, ReportsService } from './reports.service';
import { ToastService } from './toast.service';

/**
 * Сервис для работы с геозонами
 */
@Injectable()
export class FileService {

  /**
   * Событие на загрузку файла
   */
  public onLoadFileSubject = new Subject<ReportDocumentType>()

  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private reportService: ReportsService,
    public toastService: ToastService,
    private loadingService: LoadingService,
    private configService: ConfigService
  ) {
  }

  /**
   * Обработка файла
   * @param fi
   */
  public processFile = (fi: IFileInfo) => {
    if (fi.uniqueId !== this.configService?.uniqueId) return

    const headers = this.loadingService.waiterHeader;
    const options: object = { responseType: 'blob', headers };

    this.http.get<Blob>(fi.link, options).subscribe((blob) => {
      this.onLoadFileSubject.next(fi.format)
      if (fi.format !== ReportDocumentType.HTML) {
        FileSaver.saveAs(blob, fi.name);
        return;
      }
      const reader = new FileReader();
      reader.onload = this.show;
      reader.readAsText(blob);
    });
  }

  /**
   * Отображение превью
   * @param e Событие, данные прочитаны
   */
  private show = (e: ProgressEvent) => {
    const print = document.getElementById('print');
    print.innerText = '';
    print.innerHTML = (e.target as any).result;
    window.print();
  }
}


/**
 * File element interface
 */
export interface IFileInfo {
  /**
   * File name
   */
  name: string;
  /**
   * Ссылка на скачивание
   */
  link: string;
  /**
   * Type of doc
   */
  format: ReportDocumentType;
  /**
   * Unique client id
   */
  uniqueId: string;
  /**
   * Error of file building
   */
  error: string;
  /**
   * User identifier
   */
  userId: string;
  /**
   * Account identifier
   */
  accountId: string;
}
