/** Типы истории */
export enum HistoryType {
  /** Оборудование - Состояние */
  EQUIPMENT_STATE = 1,
  /** Оборудование - ТС */
  EQUIPMENT_UNIT,
  /** Оборудование - SIM-карта */
  EQUIPMENT_SIM,
  /** Оборудование - Учетная запись */
  EQUIPMENT_ACCOUNT,
  /** ТС - Учетная запись */
  UNIT_ACCOUNT,
  /** SIM-карта - Учетная запись */
  SIM_ACCOUNT,
  /** Блокировка ТС */
  UNIT_BLOCK,
  /** Блокировка учетной записи */
  ACCOUNT_BLOCK,
  /** Тариф - Учетная запись */
  TARIFF_ACCOUNT,
  /**
   * Опытная эксплуатация ТС
   * !!! ОБЯЗАТЕЛЬНО ДОЛЖЕН БЫТЬ 10 (type: 10), ИНАЧЕ БГПС НЕ ВИДИТ ЭТУ ИСТОРИЮ !!!
   */
  TRIAL_OPERATION,
  /** История изменения пароля */
  CHANGE_PASSWORD,
  /** История изменения ретранслятора */
  UNIT_RETRANSLATOR,
  /** Включение */
  RETRANSLATOR_ACTIVATE,
  /** Выключение */
  RETRANSLATOR_DEACTIVATE,
  /** Создание ретранслятора */
  RETRANSLATOR_CREATE,
  /** Удаление ретранслятора */
  RETRANSLATOR_DELETE,
  /** Изменение расписания ретранслятора */
  RETRANSLATOR_SCHEDULE_UPDATE,
  /** Изменение ретранслятора */
  RETRANSLATOR_CHANGE
}

export function getHistoryTypeName(type: HistoryType) {
  switch (type) {
    case HistoryType.EQUIPMENT_STATE: return 'component.history.account-block';
    case HistoryType.EQUIPMENT_UNIT: return 'component.history.change-password';
    case HistoryType.EQUIPMENT_SIM: return 'component.history.equipment-account';
    case HistoryType.EQUIPMENT_ACCOUNT: return 'component.history.equipment-sim';
    case HistoryType.UNIT_ACCOUNT: return 'component.history.equipment-state';
    case HistoryType.SIM_ACCOUNT: return 'component.history.equipment-unit';
    case HistoryType.UNIT_BLOCK: return 'component.history.retranslator-activate';
    case HistoryType.ACCOUNT_BLOCK: return 'component.history.retranslator-create';
    case HistoryType.TARIFF_ACCOUNT: return 'component.history.retranslator-schedule-update';
    case HistoryType.TRIAL_OPERATION: return 'component.history.sim-account';
    case HistoryType.CHANGE_PASSWORD: return 'component.history.tariff-account';
    case HistoryType.UNIT_RETRANSLATOR: return 'component.history.trial-operation';
    case HistoryType.RETRANSLATOR_ACTIVATE: return 'component.history.retranslator-activation';
    case HistoryType.RETRANSLATOR_CREATE: return 'component.history.unit-block';
    case HistoryType.RETRANSLATOR_SCHEDULE_UPDATE: return 'component.history.retranslator-schedule';
    case HistoryType.RETRANSLATOR_DEACTIVATE: return 'component.history.retranslator-shutdown';
    case HistoryType.RETRANSLATOR_DELETE: return 'component.history.retranslator-delete';
    case HistoryType.RETRANSLATOR_CHANGE: return 'component.history.retranslator-change';
  }
}
