import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { IOverlayPanelData, OverlayPanelService } from '../../services/overlay-panel.service';

/**
 * Компонент обёртка для PrimeNG - OverlayPanel
 * @template D данные на вход компонента
 * @template R данные на выходе компонента
 */
@Component({
  selector: 'overlay-panel-component',
  templateUrl: './overlay-panel.component.html'
})
export class OverlayPanelComponent implements OnDestroy {

  @ViewChild('op', {static: false}) op;

  /**
   * Data for overlay panel
   */
  public data: IOverlayPanelData;

  /**
   * Подписка
   */
  private openSubscribe: Subscription;

  /**
   * Constructor of component
   * @param overlayPanelService service for working with overlay panels
   */
  constructor(
    private overlayPanelService: OverlayPanelService
  ) {
    this.openSubscribe = this.overlayPanelService.openSubject.subscribe(this.onOpen)
  }

  /**
   * Обработки при уничтожении компонента
   */
  ngOnDestroy(): void {
    this.op.hide();
    this.openSubscribe.unsubscribe();
  }


  /**
   * Handle of open subscription
   * @param data
   */
  public onOpen = (data) => {
    this.data = data;
    this.op.toggle(data.event)
  }
}
