import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dateFormat from 'dateformat';
import { DialogService } from 'ng2-bootstrap-modal';
import { Observable } from 'rxjs';
import { filter, flatMap, tap } from 'rxjs/operators';

import {
  DocRequest,
  DocRequestType,
  DocResponse,
  DocType,
  IDocItem,
  isGeneratedDoc,
  isInvoiceRequest
} from '../../../../../shared/crm/docs';
import { DocsService } from '../../../../services/docs.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';
import { prepareDocForDisplay } from '../../../../utils/docs';
import { ModalResult } from '../../../modal/modal.component';
import { DocsPrintDetailComponent } from '../print-detail/docs.print-detail.component';
import { DocsPrintPreviewComponent, IPreviewDoc } from '../print-preview/docs.print-preview.component';

import { DocAddComponent } from './add/doc.add.component';
import { DocChangeComponent } from './change/doc.change.component';

/**
 * Компонент для просмотра списка документов (счета, акты)
 */
@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss']
})
export class DocComponent implements OnChanges {

  /** Идентификатор учетной записи */
  @Input() public accountId: string;

  /** Тип документов, обрабатываемых в компоненте */
  @Input() public type: DocType;

  /** Список документов */
  public items: IDocItem[] = [];

  /**
   * Конструктор
   * @param docsService Севрис для работы с документами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    private docsService: DocsService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
  }

  /**
   * Признак того, что в компоненте обрабатываются счета
   */
  public get isInvoice() {
    return this.type === 'invoice';
  }

  /**
   * Удаление документа
   * @param item Документ
   */
  public delete(item: IDocItem) {
    this.translator.get('formats.date')
      .pipe(
        flatMap((format) => {
          const key = `component.crm.docs.doc.error-${this.isInvoice ? '1' : '2'}`;
          const params = { no: item.docNo, date: dateFormat(item.date, format) };
          return this.translator.get(key, params);
        }),
        flatMap((message) => this.modalService.showQuestion(message)),
        filter((result) => result === ModalResult.YES),
        flatMap(() => {
          const request: DocRequest = this.isInvoice
            ? { type: DocRequestType.DELETE, accountId: this.accountId, invoiceId: item.id }
            : { type: DocRequestType.DELETE, accountId: this.accountId, reportId: item.id };

          return this.loadingService.wrap(this.docRequest(request), true);
        }))
      .subscribe(() => this.loadAccountDocs(this.accountId));
  }

  /**
   * Получение документа (и отображение превью)
   * @param item Документ
   */
  public get(item: IDocItem) {
    if (item.archive) {
      return;
    }

    const request: DocRequest = this.isInvoice
      ? { type: DocRequestType.GET, accountId: this.accountId, invoiceId: item.id }
      : { type: DocRequestType.GET, accountId: this.accountId, reportId: item.id };

    this.loadingService
      .wrap(this.docRequest(request), true)
      .pipe(
        tap((doc) => {
          if (isGeneratedDoc(doc)) {
            prepareDocForDisplay(doc);
          }
        }),
        flatMap((doc) => {
          const data = { doc, type: this.type } as IPreviewDoc;
          return this.dialogService.addDialog(DocsPrintPreviewComponent, data);
        }))
      .subscribe();
  }

  /**
   * Добавление документа
   */
  public add() {
    const data = { accountId: this.accountId, type: this.type };
    this.dialogService.addDialog(DocAddComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.loadAccountDocs(this.accountId));
  }

  /**
   * Изменение документа
   * @param item Документ
   */
  public change(item: IDocItem) {
    const data = { accountId: this.accountId, type: this.type, docId: item.id };
    this.dialogService.addDialog(DocChangeComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.loadAccountDocs(this.accountId));
  }

  /**
   * Обработка изменений полей документа
   */
  public ngOnChanges() {
    if (this.type) {
      this.loadAccountDocs(this.accountId);
    }
  }

  /**
   * Получение детальной информации по акту
   * @param item
   */
  public getDetailInfo(item: IDocItem) {
    if (item.archive) {
      return;
    }

    const body = { reportId: item.id, accountId: this.accountId };
    this.loadingService
      .wrap(this.docsService.waReportDetail(body), true)
      .pipe(
        flatMap((doc) => {
          const data = { doc };
          return this.dialogService.addDialog(DocsPrintDetailComponent, data);
        }))
      .subscribe();
  }

  /**
   * Загрузка списка существующих документов по учетной записи
   * @param accountId Идентификатор учетной записи
   */
  private loadAccountDocs(accountId: string) {
    const observable: Observable<IDocItem[]> = this.isInvoice
      ? this.docsService.getInvoices(accountId)
      : this.docsService.getWAReports(accountId);

    this.loadingService.wrap(observable, true)
      .subscribe((items) => {
        items.forEach((item) => {
          item.sum /= 100;
        });

        this.items = items?.sort((a, b) => a.date - b.date);
      });
  }

  /**
   * Получение запроса на CRUD документа
   * @param request Параметры запроса
   */
  private docRequest(request: DocRequest): Observable<DocResponse> {
    return isInvoiceRequest(request, this.type)
      ? this.docsService.invoice(request)
      : this.docsService.waReport(request);
  }
}
