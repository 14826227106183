import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { IUserListItem } from '../../../shared/users';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { StoreService } from '../../services/store.service';
import { ModalResult } from '../modal/modal.component';
import { UserComponent } from '../user/user.component';

/**
 * Компонент для отображения списка пользователей
 */
@Component({
  selector: 'users',
  templateUrl: './users.component.html'
})
export class UsersComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IUserListItem[];
  /**
   * Выбранный элемент
   */
  public selected: IUserListItem;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;

  /**
   * Конструктор
   * @param crudService Сервис работы с ДИУП
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис работы с диалоговыми окнами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param translator Сервис для перевода
   * @param store
   * @param router
   */
  constructor(
    private crudService: CRUDService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private translator: TranslateService,
    private store: StoreService,
    private router: Router
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'creator', name: 'ui.creator' },
      { id: 'account', name: 'ui.account' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Переход в режим наблюдения
   */
  public observe(id: string) {
    localStorage.setItem('observer', this.store.user.name);
    localStorage.setItem('observable', id);
    this.router.navigate(['login'], { skipLocationChange: true, replaceUrl: true }).finally();
  }

  /**
   * Добавление пользователя
   */
  public addItem() {
    this.dialogService.addDialog(UserComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранного пользователя
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { userId: id };
    this.dialogService.addDialog(UserComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранного пользователя
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const err = this.translator.instant('component.users.error', { val: name });

    this.modalService.showQuestion(err)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.USER), true)))
      .subscribe(() => {
        this.error = null;
        this.loadData();
      });
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    this.error = null;
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.USER))
      .subscribe(
        (items) => {
          this.items = items;
        },
        (error) => this.error = error);
  }
}
