import { Component, Input, OnInit } from '@angular/core';

import { UnitRightType } from '../../../../shared/rights/RightType';
import { ISensorBase } from '../../../../shared/sensors/ISensorBase';
import { IExtras, ISpeedColor } from '../../../../shared/units/IExtras';
import {
  getAllTrackColoringTypes,
  getTrackColoringTypeName,
  TrackColoringType
} from '../../../../shared/units/TrackColoringType';
import { IListItem } from '../../../classes/IListItem';
import { StoreService } from '../../../services/store.service';

/**
 * Компонент с дополнительной информацией о объекте мониторинга
 */
@Component({
  selector: 'unit-extras',
  templateUrl: './unit.extras.component.html',
  styleUrls: []
})
export class UnitExtrasComponent implements OnInit {

  /** Дополнительная информация о объекте мониторинга */
  @Input() public extras: IExtras<string>;

  /** Список датчиков объекта мониторинга */
  @Input() public sensors: ISensorBase[];

  /** Права доступа на объект мониторинга */
  @Input() public rights: number;

  /** Признак режима добавления */
  @Input() public isAddRegime: boolean;

  /** Признак только для чтения */
  public isReadOnly: boolean;

  /** Признак что юзер является диллером */
  public isDealer: boolean;

  /** Список типов окрашивания трека */
  public trackColoringTypes: IListItem<TrackColoringType>[] = [];

  /**
   * Конструктор
   */
  constructor(
    private storeService: StoreService
  ) {
    this.isReadOnly = false;
    this.trackColoringTypes = getAllTrackColoringTypes()
      .map((id) => ({ id, name: getTrackColoringTypeName(id) }));
  }

  /**
   * Обработка изменения выбранного типа окрашивания трека
   */
  public onTrackColoringTypeChanged() {
    switch (this.extras.trackColoringType) {
      case TrackColoringType.MONO:
        this.extras.trackColorBySpeed = [];
        this.extras.trackColorBySensor = null;
        break;
      case TrackColoringType.BY_SPEED:
        this.extras.trackColorMono = null;
        this.extras.trackColorBySensor = null;
        break;
      case TrackColoringType.BY_SENSOR:
        this.extras.trackColorMono = null;
        this.extras.trackColorBySpeed = [];
        break;
    }
  }

  /**
   * Добавление цвета по скорости
   */
  public addTrackColorBySpeed() {
    if (!this.extras.trackColorBySpeed) {
      this.extras.trackColorBySpeed = [];
    }

    this.extras.trackColorBySpeed.push({
      speed: 0,
      color: '#000'
    });
  }

  /**
   * Удаление цвета по скорости
   * @param speedColor Удаляемый цвет по скорости
   */
  public deleteTrackColorBySpeed(speedColor: ISpeedColor) {
    if (this.extras.trackColorBySpeed) {
      const index = this.extras.trackColorBySpeed.indexOf(speedColor);
      if (index >= 0) {
        this.extras.trackColorBySpeed.splice(index, 1);
      }
    }
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if(this.storeService.user && this.storeService.user.isDealer) {
      this.isDealer = true;
    }
    // tslint:disable-next-line:no-bitwise
    if (!this.isAddRegime && !(this.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)) {
      this.isReadOnly = true;
    }

    if (!this.extras.trackColoringType) {
      this.extras.trackColoringType = TrackColoringType.MONO;
    }

    if (!this.extras.trackColorBySpeed) {
      this.extras.trackColorBySpeed = [];
    }
  }

  /**
   * Обработка события изменения норм расхода топлива по километражу
   */
  public onNormLP100KChanged() {
    this.extras.normsLPH = null;
  }

  /**
   * Обработка события изменения норм расхода топлива по моточасам
   */
  public onNormLPHChanged() {
    this.extras.normsLP100K = null;
  }
}
