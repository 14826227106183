import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { IEquipment } from '../../../../shared/crm/equipment/IEquipmentGeneric';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { AccountComponent } from '../../account/account.component';
import { ModalResult } from '../../modal/modal.component';
import { UnitComponent } from '../../unit/unit.component';
import { SIMCardsEditComponent } from '../sim-cards/edit/sim-cards.edit.component';

import { Table } from 'primeng/table';
import { EquipmentsEditComponent } from './edit/equipments.edit.component';

/**
 * Компонент для отображения списка оборудования
 * (доп. оборудование и терминалы)
 */
@Component({
  selector: 'equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IEquipment[];
  /**
   * Выбранный элемент
   */
  public selected: IEquipment;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Текст ошибки, если есть
   */
  public error: string;

  /**
   * Конструктор
   * @param crudService Сервис для работы с ДИУП
   * @param loadingService Сервис для отображения процесса загрузки
   * @param route Рекущий маршрут
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param modalService Сервис модальных окон
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translator: TranslateService,
    private modalService: ModalService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'uid', name: 'ui.number' },
      { id: 'state.name', name: 'ui.state' },
      { id: 'type.name', name: 'ui.type' },
      { id: 'unit.name', name: 'component.crm.equipments.car' },
      { id: 'client.name', name: 'ui.client' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Признак того, что на странице отображаются терминалы
   */
  public get isTerminals() {
    return this.route.routeConfig.path.endsWith('terminals');
  }

  /**
   * Добавление новой записи
   */
  public addItem() {
    const data = { isTerminal: this.isTerminals };
    this.dialogService.addDialog(EquipmentsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной записи
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = {
      equipmentId: id,
      isTerminal: this.isTerminals
    };

    this.dialogService.addDialog(EquipmentsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранной записи
   */
  public copySelected(id) {
    if (!id) {
      return;
    }

    const data = {
      equipmentId: id,
      isTerminal: this.isTerminals,
      copy: true
    };

    this.dialogService.addDialog(EquipmentsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /** Удаление выбранной записи */
  public deleteSelected(id: string) {
    if (!id) {
      return;
    }

    const key = `component.crm.equipments.confirm.${this.isTerminals ? 'terminal' : 'option'}`;
    const confirm = this.translator.instant(key);

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.EQUIPMENT), true)))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о ТС,
   * на котором установлено оборудование
   * @param unitId Идентификатор ТС
   */
  public showUnit(unitId: string) {
    const data = { unitId };
    this.dialogService.addDialog(UnitComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о клиенте,
   * у которого установлено оборудование
   * @param clientId Идентификатор клиента
   */
  public showClient(clientId: string) {
    const data = { accountId: clientId };
    this.dialogService.addDialog(AccountComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о SIM-карте,
   * которая установлена в оборудовании
   * @param simCardId Идентификатор SIM-карты
   */
  public showSimCard(simCardId: string) {
    const data = { simCardId };
    this.dialogService.addDialog(SIMCardsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка списка оборудования
   */
  protected loadData = () => {
    const query = { terminals: `${this.isTerminals}` };
    this.loadingService
      .wrap(this.crudService.getList(CRUDEntityType.EQUIPMENT, query))
      .subscribe(
        (items) => this.items = items,
        (error) => this.error = error);
  };
}
