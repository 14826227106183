import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { toLonLat, transform } from 'ol/proj';

import { GeozoneType } from '../../../../shared/geozones/GeozoneType';
import { IAddressChosenEvent } from '../../../classes/IAddressChosenEvent';
import { IClientGeozone } from '../../../classes/IClientGeozone';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';

/**
 * Компонент для отображения детальной информации по адресу
 */
@Component({
  selector: 'app-address-detail',
  templateUrl: './map.address-detail.component.html',
  styleUrls: ['./map.address-detail.component.scss']
})
export class AddressDetailComponent {

  /** Данные события выбора адреса */
  @Input() public addressChosenEvent: IAddressChosenEvent;

  /**
   * Конструктор
   * @param monitoringService Сервис мониторинга
   * @param router Маршрутизатор
   * @param store Сервис для хранения данных мониторинга
   */
  public constructor(
    private monitoringService: MonitoringService,
    private router: Router,
    private store: StoreService
  ) { }

  /** Получение признака режима редактирования геозоны */
  public get isEditRegime(): boolean {
    return !!this.store.editGeozone;
  }

  /** Получение признака редактирования геозоны типа "Круг" */
  public get isEditCircleGeozone(): boolean {
    return this.isEditRegime && this.store.editGeozone.type === GeozoneType.CIRCLE;
  }

  /**
   * Перенесение редактируемой геозоны на место отображения детальной информации
   */
  public moveGeozone() {
    if (!this.isEditCircleGeozone) {
      return;
    }

    this.monitoringService.removeAddressDetailSubject.next();

    const [ln, lt] = transform(this.addressChosenEvent.coordinate, 'EPSG:3857', 'EPSG:4326');
    this.store.editGeozone.points = [{ lt, ln }];
    this.store.editGeozone.descr = this.addressChosenEvent.address.details.name;

    this.monitoringService.cancelDrawGeozone();
    this.monitoringService.startDrawGeozone(this.store.editGeozone);
  }

  /**
   * Клик по кнопке добавления геозоны
   */
  public addGeozone() {
    this.monitoringService.removeAddressDetailSubject.next();

    const coords = toLonLat(this.addressChosenEvent.coordinate);
    const cgzr = localStorage.getItem('circle_geozone_radius');
    const newGeozone = {
      name: this.addressChosenEvent.address.details.name,
      checked: true,
      type: GeozoneType.CIRCLE,
      color: '#ff0000',
      labelColor: '#0000ff',
      r: cgzr ? +cgzr : 30,
      points: [{ ln: coords[0], lt: coords[1] }]
    } as IClientGeozone;
    newGeozone.square = this.monitoringService.calcGeozoneSquare(newGeozone);
    this.monitoringService.startEditGeozone(newGeozone);
    this.router.navigate(['monitoring', 'geozones', 'add']);
  }

  /**
   * Клик по кнопке закрытия детальной информации
   */
  public close() {
    this.monitoringService.removeAddressDetailSubject.next();
  }
}
