import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IDocItem,
  IDocsPackets,
  IDocsPacketsRequest,
  IDocTariffsInfo,
  InvoiceResponse,
  ISearchDocItem,
  WAReportResponse
} from '../../shared/crm/docs';
import { IGeneratedWADetail } from '../../shared/crm/docs/IGeneratedWADetail';
import { InvoiceRequest } from '../../shared/crm/docs/InvoiceRequest';
import { IGetDetailRequest, WAReportRequest } from '../../shared/crm/docs/WAReportRequest';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с документами (счета, акты)
 */
@Injectable()
export class DocsService {

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/crm/docs`;

  /**
   * Сохраняем поиск компонента docs.packets
   */
  public docsPacketsSearch: string;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Формирование пакетов документов по списку учетных записей
   * @param body Данные запроса на формирование пакетов документов
   */
  public packets(body: IDocsPacketsRequest): Observable<IDocsPackets> {
    const url = `${this.baseUrl}/packets`;
    return this.http.post<IDocsPackets>(url, body);
  }

  /**
   * Запрос по счету
   * @param body Данные запроса по счету
   */
  public invoice(body: InvoiceRequest): Observable<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices`;
    return this.http.post<InvoiceResponse>(url, body);
  }

  /**
   * Запрос по акту
   * @param body Данные запроса по акту
   */
  public waReport(body: WAReportRequest): Observable<WAReportResponse> {
    const url = `${this.baseUrl}/reports`;
    return this.http.post<WAReportResponse>(url, body);
  }

  /**
   * Запрос по акту
   * @param body Данные запроса по акту
   */
  public waReportDetail(body: IGetDetailRequest): Observable<IGeneratedWADetail> {
    const url = `${this.baseUrl}/reports-detail`;
    return this.http.post<IGeneratedWADetail>(url, body);
  }

  /**
   * Получение информации по тарифу для формирования документов
   * @param accountId Идентификатор учетной записи
   */
  public tariffsInfo(accountId: string): Observable<IDocTariffsInfo> {
    const url = `${this.baseUrl}/tariffs-info/${accountId}`;
    return this.http.get<IDocTariffsInfo>(url);
  }

  /**
   * Получение списка счетов учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getInvoices(accountId: string): Observable<IDocItem[]> {
    const url = `${this.baseUrl}/invoices/${accountId}`;
    return this.http.get<IDocItem[]>(url);
  }

  /**
   * Получение списка актов учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getWAReports(accountId: string): Observable<IDocItem[]> {
    const url = `${this.baseUrl}/reports/${accountId}`;
    return this.http.get<IDocItem[]>(url);
  }

  /**
   * Поиск документов по номеру
   * @param docNo Номер документа
   * @param fullMatch Признак поиска только с полным совпадением номера
   */
  public search(docNo: string, fullMatch: boolean): Observable<ISearchDocItem[]> {
    const url = `${this.baseUrl}/search`;
    const body = {docNo, fullMatch};
    return this.http.post<ISearchDocItem[]>(url, body);
  }

  /**
   * Чистим сервис при логауте
   */
  public clean() {
    this.docsPacketsSearch = null
  }
}
