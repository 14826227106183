import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';

import { BgLanguageSelectorComponent } from './components/bg-language-selector/bg-language-selector.component';
import { BgLoginComponent } from './components/bg-login/bg-login.component';
import { BgAuthService } from './services/bg-auth.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    BgLoginComponent,
    BgLanguageSelectorComponent
  ],
  entryComponents: [
    BgLanguageSelectorComponent
  ],
  exports: [
    BgLoginComponent,
    BgLanguageSelectorComponent
  ],
  providers: [
    BgAuthService
  ]
})
export class BgAuthModule { }
