/**
 * Типы графиков в отчетах
 */
export enum ReportChartType {
  /** По времени */
  BY_TIME = 1,
  /** По пробегу */
  BY_MILEAGE
}

/**
 * Получение списка всех типов графиков, доступных в отчетах
 */
export function getAllReportChartTypes(): ReportChartType[] {
  const result: ReportChartType[] = [];
  for (let i = 1; i <= 2; i++) {
    result.push(i as ReportChartType);
  }
  return result;
}

/**
 * Получение наименования типа графика в отчетах
 * @param type Тип графика в отчетах
 */
export function getReportChartTypeName(type: ReportChartType): string {
  switch (type) {
    case ReportChartType.BY_TIME: return 'enums.reports.charts.type.time';
    case ReportChartType.BY_MILEAGE: return 'enums.reports.charts.type.mileage';
    default: return 'enums.reports.charts.type.unknown';
  }
}
