import { Injectable } from '@angular/core';

import { IDriverGroup } from '../../shared/drivers/IDriverGroup';
import { IGeozoneGroup } from '../../shared/geozones/IGeozoneGroup';
import { IHostConfig } from '../../shared/IHostConfig';
import { IMessagesRequest } from '../../shared/messages/IMessagesRequest';
import { ITrackingSettings } from '../../shared/tracking/ITrackingSettings';
import { ITrailerGroup } from '../../shared/trailers/ITrailerGroup';
import { ICurrentUser } from '../../shared/users';
import { IClientDriver } from '../classes/IClientDriver';
import { IClientGeozone } from '../classes/IClientGeozone';
import { IClientRoute } from '../classes/IClientRoute';
import { IClientTrailer } from '../classes/IClientTrailer';
import { IMapMessagePointInfo } from '../classes/IMapMessagePointInfo';
import { TrackingDriverGroup } from '../classes/TrackingDriverGroup';
import { TrackingGeozoneGroup } from '../classes/TrackingGeozoneGroup';
import { TrackingGroup } from '../classes/TrackingGroup';
import { TrackingTrailerGroup } from '../classes/TrackingTrailerGroup';
import { TrackingUnit } from '../classes/TrackingUnit';

/**
 * Сервис для хранения данных мониторинга
 */
@Injectable()
export class StoreService {
  /**
   * Идентификатор сессии
   */
  public sessionId: string;

  /**
   * Время последнего получения обновлений по сессии
   */
  public lastGetTrackingInfo: number;

  /**
   * Список геозон
   */
  public geozones: IClientGeozone[] = [];

  /**
   * Список групп геозон
   */
  public geozoneGroups: TrackingGeozoneGroup[] = [];

  /**
   * Редактируемая группа геозон
   */
  public editGeozoneGroup: IGeozoneGroup;

  /**
   * Список групп объектов
   */
  public groups: TrackingGroup[] = [];

  /**
   * Редактируемая геозона
   */
  public editGeozone: IClientGeozone;

  /**
   * Список объектов для слежения
   */
  public units: TrackingUnit[] = [];

  /**
   * Объекты вне геозон
   */
  public outsideGeozones: TrackingUnit[] = [];

  /**
   * Текущее сообщение
   */
  public currentMessage: IMapMessagePointInfo = null;

  /**
   * Настройки слежения
   */
  public trackingSettings: ITrackingSettings;

  /**
   * Данные текущего пользователя
   */
  public user: ICurrentUser;

  /**
   * Идентификатор объекта мониторинга, для которого добавляется событие
   */
  public addEventUnitId: string = null;

  /**
   * Параметры запроса сообщений
   */
  public messagesRequest: IMessagesRequest;

  /**
   * Список наблюдаемых водителей
   */
  public drivers: IClientDriver[] = [];

  /**
   * Список групп водителей
   */
  public driverGroups: TrackingDriverGroup[] = [];

  /**
   * Редактируемая группа водителей
   */
  public editDriverGroup: IDriverGroup;

  /**
   * Список наблюдаемых прицепов
   */
  public trailers: IClientTrailer[] = [];

  /**
   * Список групп прицепов
   */
  public trailerGroups: TrackingTrailerGroup[] = [];

  /**
   * Редактируемая группа прицепов
   */
  public editTrailerGroup: ITrailerGroup;

  /**
   * Список маршрутов
   */
  public routes: IClientRoute[] = [];

  /**
   * Редактируемый маршрут
   */
  public editRoute: IClientRoute;

  /**
   * Настройки хоста
   */
  public hostConfig :IHostConfig;

  public cleanTrackingLists() {
    // Очистить список ТС
    this.units.splice(0, this.units.length);
    // Очистить список геозон
    this.geozones.splice(0, this.geozones.length);
    // Очистить список ТС вне геозон
    this.outsideGeozones.splice(0, this.outsideGeozones.length);
    // Очистить список водителей
    this.drivers.splice(0, this.drivers.length);
    // Очистить список прицепов
    this.trailers.splice(0, this.trailers.length);
    // Очистить список маршрутов
    this.routes.splice(0, this.routes.length);
  }

  /**
   * Очистка данных хранилища
   */
  public clean() {
    localStorage.removeItem('sessionId');
    this.cleanTrackingLists();
    this.sessionId = null;
    this.trackingSettings = null;
    this.lastGetTrackingInfo = null;
    this.user = null;
  }
}
