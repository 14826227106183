/**
 * Status of report handling
 */
export enum ReportStatus {
  PROGRESS,
  ERROR,
  DONE
}

/**
 * IReportListItem store report item
 */
export interface IReportListItem {
  /** Identifier */
  id: string;
  /** Name of report  */
  templateName: string;
  /** Index in queue */
  queueNumber: number;
  /** Status of handling report */
  status: ReportStatus;
  /** Ошибка при построении отчёта */
  error: string;
  /** Названия объекта отчёта */
  objectName: string;
  /** Date of crating */
  created: number;
  /** Date from which data recording starts */
  from: number;
  /** Date until which data recording starts */
  to: number;
  /** Date when report was requested */
  requested: number;
}
