import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { IListItem } from '../../../classes/IListItem';
import { ModalService } from '../../../services/modal.service';
import {
  getNotificationTypeName,
  INotificationSettingInfo,
  NotificationsService
} from '../../../services/notifications.service';
import { ToastService } from '../../../services/toast.service';
import { ModalResult } from '../../modal/modal.component';
import { NotificationSettingComponent } from '../notification-setting/notification-setting.component';

/**
 * Компонент для отображения списка настроек уведомлений
 */
@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.html'
})
export class NotificationSettingsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: INotificationSettingInfo[];
  /**
   * Выбранный элемент
   */
  public selected: INotificationSettingInfo;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;

  /**
   * Конструктор
   * @param notificationsService Сервис для работы с уведомлениями
   * @param dialogService Сервис диалоговых окон
   * @param toastService Сервис модальных окон
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'account', name: 'ui.account' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление элемента
   */
  public addItem() {
    this.dialogService.addDialog(NotificationSettingComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранного элемента
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { nsId: id };
    this.dialogService.addDialog(NotificationSettingComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранного элемента
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = { nsId: id, copy: true };
    this.dialogService.addDialog(NotificationSettingComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранного элемента
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant('component.notifications.notification-settings.confirm', { val: name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.notificationsService.remove(id)))
      .subscribe(
        () => this.loadData(),
        () => this.toastService.error('component.notifications.notification-settings.delete-error'));
  }

  /**
   * Загрузка данных компонента
   */
  protected loadData = () => {
    this.notificationsService.getList().subscribe(
      (items) => {
        this.items = items.map((i) => ({ ...i, typeName: getNotificationTypeName(i.type) }));
      },
      () => this.toastService.error('component.notifications.notification-settings.get-list-error'));
  };
}
