import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

import { IReportListItem, ReportStatus } from '../../../shared/reports/IReportListItem';
import { FileService } from '../../services/file.service';
import { LoadingService } from '../../services/loading.service';
import { IReportElement, ReportDocumentType, ReportsService } from '../../services/reports.service';
import { ToastService } from '../../services/toast.service';

/**
 * Элемент отчёта с выделением
 */
interface IClientReportElement extends IReportElement {
  /**
   * Пометка о выделении
   */
  checked?: boolean;
}

/**
 * Report queue component
 */
@Component({
  selector: 'report-queue',
  templateUrl: './report-queue.component.html',
  styleUrls: [`report-queue.component.scss`]
})
export class ReportQueueComponent implements OnInit {

  /**
   * Selected item output from component
   */
  @Output() public selectedOutput = new EventEmitter<IReportListItem>();

  /***
   * Container with html to print
   */
  @ViewChild('container', { static: false })
  public container: ElementRef;

  /** Selected item output from component */
  public selected: IReportListItem;

  /** Elements of report */
  public selectedElement: IClientReportElement[] = [];

  /** Types of report status */
  public statusType = ReportStatus;

  /** Index of accordion selected element */
  public activeIndex: number = -1;

  /** Признак загрузки html файла */
  public isHtmlLoading: boolean = false;

  /** Признак загрузки pdf файла */
  public isPdfLoading: boolean = false;

  /** Признак загрузки xlsx файла */
  public isXlsxLoading: boolean = false;

  /**
   * Alias for {@link ReportDocumentType}
   */
  public dType = ReportDocumentType;

  /**
   * Constructor
   * @param reportsService
   * @param fileService
   * @param toastService
   * @param loadingService
   * @param confirmationService
   * @param translator
   */
  constructor(
    public reportsService: ReportsService,
    public fileService: FileService,
    public toastService: ToastService,
    public loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private translator: TranslateService
  ) {
    this.reportsService.createReportSubject.subscribe(() => {
      this.closeAllTabs()
    });
    this.fileService.onLoadFileSubject.subscribe(this.onFileLoad)
  }

  public ngOnInit(): void {
    this.loadData();
  }

  /**
   * Обработка события при загрузке файла
   * @param type
   */
  private onFileLoad = (type: ReportDocumentType) => {
    switch (type) {
      case ReportDocumentType.HTML:
        this.isHtmlLoading = false
        break
      case ReportDocumentType.PDF:
        this.isPdfLoading = false
        break
      case ReportDocumentType.XLSX:
        this.isXlsxLoading = false
    }
  }

  /**
   * Load report list data
   */
  public loadData() {
    this.closeAllTabs()
    this.reportsService.loadQueueList();
  }

  /**
   * Remove report from storage
   */
  public removeReport(item: IReportListItem, e: MouseEvent) {
    e.stopImmediatePropagation()
    this.closeAllTabs()
    this.reportsService.deleteReportById(item.id).subscribe(() => {
      this.reportsService.fullClearReport();
      this.selected = null;
      this.selectedOutput.emit(null);
      this.loadData();
    })
  }

  /**
   * Clear list of reports
   */
  public removeReports() {
    this.confirmationService.confirm({
      message: this.translator.instant('component.report.report-delete-confirm'),
      header: this.translator.instant('component.report.report-delete-header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.closeAllTabs()
        this.reportsService.queueItems = null;
        this.reportsService.deleteReportList().subscribe(() => {
          this.toastService.success(this.translator.instant('component.report.reports-deleted'))
        })
      },
      reject: null
    });
  }

  /**
   * Экспорт в файл или вывод на печать отчёта
   * @param type Тип документа
   */
  public export(type: ReportDocumentType) {
    if (!this.selectedElement?.length) {
      this.toastService.warn('component.report.no-report-elements-selected');
      return
    }

    switch (type) {
      case ReportDocumentType.HTML:
        this.isHtmlLoading = true;
        break;
      case ReportDocumentType.PDF:
        this.isPdfLoading = true;
        break;
      case ReportDocumentType.XLSX:
        this.isXlsxLoading = true;
    }

    const ids = this.selectedElement.map((x) => x.id);

    this.reportsService.getFile(type, ids).subscribe();
  }

  /**
   * Выбор элемента отчета
   * @param element Элемент отчета
   */
  public selectElement(element: IReportElement) {
    this.reportsService.selectElement(element);
  }

  /**
   * Очистка текущего отчета
   */
  public clear() {
    this.closeAllTabs()
    this.onClose()
  }

  /**
   * Kostyl for prime accordion closing
   */
  closeAllTabs() {
    this.onClose()
    this.activeIndex = -1;
  }

  /**
   * Handle event on opening tab
   */
  public onOpen(event) {
    this.selectedElement = null;
    this.selected = this.reportsService.queueItems[event.index]
    this.selectedOutput.emit(this.selected)
  }

  /**
   * Handle event on closing tab
   */
  public onClose() {
    this.selectedElement = null;
    this.selected = null;
    this.isHtmlLoading = false;
    this.isPdfLoading = false;
    this.isXlsxLoading = false;
    this.reportsService.clearReport();
  }
}
