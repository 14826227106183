import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';

import { PipesModule } from '../../../pipes.module';

import { UiDistrictsComponent } from './ui-districts.component';

@NgModule({
  imports: [
    BootstrapModalModule.forRoot({ container: document.body }),
    TranslateModule,
    PipesModule,
    CommonModule,
    FormsModule
  ],
  declarations: [
    UiDistrictsComponent
  ],
  entryComponents: [
  ],
  exports: [
    UiDistrictsComponent
  ]
})
export class UIDistricsModule { }
