import { Component, Input } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IAssignmentRequest } from '../../../../shared/assignments/IAssignmentRequest';
import { LinkObjectType } from '../../../../shared/LinkObjectType';
import { IListItem } from '../../../classes/IListItem';
import { AssignmentsService } from '../../../services/assignments.service';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для работы с назначениями
 */
@Component({
  selector: 'assignments-assign',
  templateUrl: './assignments.assign.component.html',
  styleUrls: ['./assignments.assign.component.scss']
})
export class AssignComponent {

  /** Идентификатор объекта, для которого производится назначение */
  @Input() public objectId: string;

  /** Тип назначаемого объекта */
  @Input() public objectType: LinkObjectType;

  /** Признак регистрации рабочей смены (интервала) */
  @Input() public isShiftRegistration: boolean;

  /** Объекты мониторинга */
  public units: IListItem<string>[];

  /** Выбранный объект мониторинга */
  public selectedUnit: IListItem<string> = null;

  /** Список всех доступных объектов мониторинга */
  private allUnits: IListItem<string>[];

  /**
   * Конструктор
   * @param assignmentsService Сервис работы с назначениями
   * @param crudService Сервис работы с CRUD
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   * @param store Сервис для хранения данных мониторинга
   * @param monitoringService Сервис мониторинга
   */
  constructor(
    public assignmentsService: AssignmentsService,
    private crudService: CRUDService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private store: StoreService,
    private monitoringService: MonitoringService
  ) {
    this.units = this.store.units
      ?.map((unit) => ({ id: unit.id, name: unit.name }))
      ?.sort(localeSort);
  }

  /**
   * Получение признака возможности назначения
   */
  public get isCanAssign() {
    return this.selectedUnit && (!this.isShiftRegistration || this.isShiftRegistration
      && this.assignmentsService.from || this.assignmentsService.to);
  }

  /**
   * Назначение объекта
   */
  public assign() {
    if (!this.selectedUnit || !this.objectId) {
      return;
    }

    const sessionRequest = {
      oid: this.objectId,
      uid: this.selectedUnit.id
    } as IAssignmentRequest;

    if (this.isShiftRegistration) {
      if (this.assignmentsService.from) {
        sessionRequest.f = this.assignmentsService.from.getTime();
      }

      if (this.assignmentsService.to) {
        sessionRequest.t = this.assignmentsService.to.getTime();
      }
    }

    this.loadingService.wrap(this.assignmentsService.assign(sessionRequest, this.objectType), true)
      .subscribe(() => {
        this.assignmentsService.objectId = null;

        if (this.objectType === LinkObjectType.DRIVER) {
          this.monitoringService.getDrivers();
          return;
        }

        if (this.objectType === LinkObjectType.TRAILER) {
          this.monitoringService.getTrailers();
        }
      });
  }

  /**
   * Обработка отмены назначения
   */
  public cancel() {
    this.assignmentsService.objectId = null;
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.selectedUnit
      ? this.allUnits.filter((u) => u.id === this.selectedUnit.id)
      : [];

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.assignments.assign.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.selectedUnit = result?.shift();
        if (!this.selectedUnit) {
          return;
        }

        const existsUnit = this.units.find((u) => u.id === this.selectedUnit.id);
        if (existsUnit) {
          this.selectedUnit = existsUnit;
        } else {
          this.units.push(this.selectedUnit);
        }
      });
  }
}
