import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CommandParamType, ICommandTypeParam } from './command-types.service';
import { ConfigService } from './config.service';

/**
 * Сервис для работы с командами
 */
@Injectable()
export class CommandsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/commands`;

  /**
   * Сабж с идентификатором объекта мониторинга, используется при отправке команд
   */
  public oidSubject = new Subject<string>();

  /**
   * Сабж для уведомления об отправке команды
   */
  public commandSentSubject = new Subject<void>();

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Выполнить команду
   * @param body отправляемая команда
   */
  public add(body: ICommand): Observable<string> {
    return this.http.post<string>(this.baseUrl, body);
  }

  /**
   * Получение списка отправленных команд
   * @param oid Идентификатор объекта мониторинга
   */
  public list(oid: string): Observable<IListCommand[]> {
    const params = { oid };
    return this.http.get<IListCommand[]>(this.baseUrl, { params });
  }

  /**
   * Отмена команды
   * @param id Идентификатор команды
   */
  public remove(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<string>(url);
  }
}

/**
 * Интерфейс команды в списке
 */
export interface IListCommand {

  /**
   * Идентификатор
   */
  id?: string;

  /**
   * Название
   */
  name: string;

  /**
   * Состояние
   */
  state: CommandState;

  /**
   * Дата отправки
   */
  date: number;

  /**
   * Отправитель
   */
  sender: string;

  /**
   * Ответ от терминала или сервера TCP
   */
  response: string;

  /**
   * Параметры
   */
  params: IListCommandParam[];
}

export interface IListCommandParam {

  /**
   * Название
   */
  name: string;

  /**
   * Тип
   */
  type: CommandParamType;

  /**
   * Значение
   */
  value: CommandParamValue;
}

/**
 * Интерфейс команды
 */
export interface ICommand {

  /**
   * Идентификатор типа
   */
  typeId: string;

  /**
   * Идентификатор объекта мониторинга
   */
  unitId: string;

  /**
   * Параметры
   */
  params?: ICommandParam[];
}

/**
 * Интерфейс параметра команды
 */
export interface ICommandParam extends ICommandTypeParam {

  /**
   * Значение
   */
  value?: CommandParamValue;

  /**
   * Ошибка при валидации значения
   */
  error?: string;
}

/**
 * Тип значения команды
 */
export type CommandParamValue = string | number | boolean;

/**
 * Состояние команды
 */
export enum CommandState {

  // noinspection JSUnusedGlobalSymbols
  /**
   * В очереди
   */
  QUEUED,

  /**
   * Обрабатывается
   */
  PROCESSED,

  /**
   * Отправлена
   */
  SENT,

  /**
   * Отклонена (ошибка отправки)
   */
  REJECTED,

  /**
   * Отменена
   */
  CANCELLED
}

/**
 * Получение текстового описания статуса отправки команды
 * @param status Статус отправки команды
 */
export function getState(status: CommandState): string {
  switch (status) {
    case CommandState.QUEUED:
      return 'enums.command-status.queued';

    case CommandState.PROCESSED:
      return 'enums.command-status.processed';

    case CommandState.SENT:
      return 'enums.command-status.sent';

    case CommandState.REJECTED:
      return 'enums.command-status.rejected';

    case CommandState.CANCELLED:
      return 'enums.command-status.canceled';

    default:
      return 'enums.command-status.unknown';
  }
}
