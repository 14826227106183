/**
 * Изменение объекта мониторинга в сессии
 */
export interface ITrackingUnitChange {
  /** Идентификатор объекта мониторинга */
  unitId: string;

  /** Тип изменения */
  type: TrackingUnitChangeType;
}

/**
 * Типы изменений объекта мониторинга в сессии
 */
export enum TrackingUnitChangeType {
  /** Добавление в сессию */
  ADD,

  /** Удаление из сессии */
  DELETE,

  /** Отображение детальной информации */
  SHOW_DETAIL,

  /** Скрытие детальной информации */
  HIDE_DETAIL,

  /** Выбор объекта */
  CHECKED,

  /** Снятие выбора с объекта */
  UNCHECKED,

  /** Включить слежение */
  EYE_ON,

  /** Отключить слежение */
  EYE_OFF
}
