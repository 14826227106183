/**
 * Промежуток времени
 */
export class TimeSpan {
  /**
   * Милисекунды
   */
  private readonly ms: number;

  /**
   * Получение промежутка времени на основе периода
   * @param dateStart Дата начала периода
   * @param dateEnd Дата окончания периода
   */
  public static fromPeriod(dateStart: Date, dateEnd: Date): TimeSpan {
    return new TimeSpan(dateEnd.getTime() - dateStart.getTime());
  }

  /**
   * Конструктор
   * @param ms Количество миллисеунд
   */
  constructor(ms: number) {
    this.ms = ms || 0;
  }

  /**
   * Количество дней
   */
  get days() {
    return Math.floor(this.totalHours / 24);
  }

  /**
   * Количество часов в неполном дне
   */
  get hours() {
    const diff = this.ms / 3600 / 1000 - 24 * this.days;
    return Math.floor(diff);
  }

  /**
   * Количество минут в неполном часе
   */
  get minutes() {
    const diff = this.ms / 60 / 1000 - (this.hours + 24 * this.days) * 60;
    return Math.floor(diff);
  }

  /**
   * Количество секунд в неполной минуте
   */
  get seconds() {
    const diff = this.ms / 1000 - (60 * 24 * this.days + 60 * this.hours + this.minutes) * 60;
    return Math.floor(diff);
  }

  /**
   * Количество миллисекунд в неполной секунде
   */
  get milliseconds() {
    return this.ms - (3600 * 24 * this.days + 3600 * this.hours + 60 * this.minutes + this.seconds) * 1000;
  }

  /**
   * Полное количество дней
   */
  get totalDays() {
    return this.totalHours / 24;
  }

  /**
   * Полное количество часов
   */
  get totalHours() {
    return this.totalMinutes / 60;
  }

  /**
   * Полное количество минут
   */
  get totalMinutes() {
    return this.totalSeconds / 60;
  }

  /**
   * Полное количество секунд
   */
  get totalSeconds() {
    return this.ms / 1000;
  }

  /**
   * Полное количество милисекунд
   */
  get totalMilliseconds() {
    return this.ms;
  }

  /**
   * Преобразование к короткой строке
   */
  public toShortString(): string {
    const hours = this.hours + this.days * 24;
    return `${hours}:${this.pad(this.minutes, 2)}:${this.pad(this.seconds, 2)}`;
  }

  /**
   * Преобразование к длинной строке
   */
  public toDuration(): IDuration {
    return {
      d: this.days,
      h: this.hours,
      m: this.minutes,
      s: this.seconds
    };
  }

  /**
   * Преобразование к строке с часами и минутами
   */
  public toHMString(): string {
    const hours = this.hours + this.days * 24;
    return `${hours}:${this.pad(this.minutes, 2)}`;
  }

  /**
   * Выравнивание числа под нужное количество знаков
   * @param num Число
   * @param size Количество знаков
   */
  private pad(num: number, size: number): string {
    // TODO: Это потом нужно перенести куда нить в другое место
    const s = `000000000${num}`;
    return s.substr(s.length - size);
  }
}

/**
 * Длительность времени с разбивкой
 */
export interface IDuration {
  /**
   * Дни
   */
  d: number;
  /**
   * Часы
   */
  h: number;
  /**
   * Минуты
   */
  m: number;
  /**
   * Секунды
   */
  s: number;
}
