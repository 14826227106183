import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IEvent } from '../../shared/events/IEvent';
import { IEventDescription } from '../../shared/events/IEventDescription';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с событиями
 */
@Injectable()
export class EventsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/events`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Добавление события
   * @param body Событие
   */
  public add(body: IEvent): Observable<string> {
    return this.http.post<string>(this.baseUrl, body);
  }

  /**
   * Удаление события
   * @param eventId Идентификатор события
   */
  public del(eventId: string): Observable<string> {
    const url = `${this.baseUrl}/${eventId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Добавление описания события
   * @param body Описание события
   */
  public addDescription(body: IEventDescription): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/descriptions`, body);
  }

  /**
   * Удаление описания события
   * @param eventDescriptionId Идентификатор описания события
   */
  public deleteDescription(eventDescriptionId: string): Observable<string> {
    const url = `${this.baseUrl}/descriptions/${eventDescriptionId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Получение списка сохраненных событий
   */
  public getDescriptions(): Observable<IEventDescription[]> {
    return this.http.get<IEventDescription[]>(`${this.baseUrl}/descriptions`);
  }
}
