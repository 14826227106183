import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IGeneratedWADetail } from '../../../../../shared/crm/docs/IGeneratedWADetail';

/**
 * Компонент для отображения превью документа и отправки его на печать
 */
@Component({
  selector: 'docs-print-detail',
  templateUrl: './docs.print-detail.component.html',
  styleUrls: ['./docs.print-detail.component.scss']
})
export class DocsPrintDetailComponent extends DialogComponent<{ doc: IGeneratedWADetail }, void> {

  /** Ссылка на элемент, в котором содержится превью для печати */
  @ViewChild('printContainer', {static: false}) public printContainer: ElementRef;

  /** Документ */
  public doc: IGeneratedWADetail;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  /**
   * Вывод на печать
   */
  public print() {
    document.getElementById('print').innerHTML = this.printContainer.nativeElement.innerHTML;
    window.print()
  }
}
