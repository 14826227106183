import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { ITrailerGroup } from '../../../../shared/trailers/ITrailerGroup';
import { IClientTrailer } from '../../../classes/IClientTrailer';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для редактирования группы прицепов
 */
@Component({
  selector: 'trailers-edit-group',
  templateUrl: './trailers.edit-group.component.html',
  styleUrls: ['./trailers.edit-group.component.scss']
})
export class TrailersGroupEditComponent implements OnInit {
  /**
   * Редактируемая группа
   */
  @Input() public group: ITrailerGroup;

  /**
   * Список прицепов редактируемой группы
   */
  public trailers: IClientTrailer[] = [];

  /** Строка поиска */
  public search: string;

  /**
   * Конструктор
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param store Сервис для хранения данных мониторинга
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private store: StoreService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Получение признака возможности сохранения группы прицепов
   */
  public get isCanSave() {
    return this.group?.name && this.group?.trailers?.length;
  }

  /** Выбор прицепа в группу из списка */
  public selectTrailers() {
    const items = this.store?.trailers
      ?.map((item) => ({ id: item.id, name: item.name }))
      ?.sort(localeSort);

    const selected = items?.filter((t) => this.group?.trailers?.includes(t.id));

    const data = {
      items,
      selected,
      title: 'component.trailers.edit-group.select-trials-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.group.trailers = result?.map((d) => d.id);
        this.refreshTrailers();
      });
  }

  /**
   * Удаление прицепа из группы
   * @param trailer Прицеп
   */
  public deleteTrailer(trailer: IClientTrailer) {
    let index = this.trailers.indexOf(trailer);
    if (index !== -1) {
      this.trailers.splice(index, 1);
    }

    index = this.group.trailers.indexOf(trailer.id);
    if (index !== -1) {
      this.group.trailers.splice(index, 1);
    }
  }

  /**
   * Сохранение группы прицепов
   */
  public save() {
    if (!this.isCanSave) {
      return;
    }

    this.loadingService.wrap(this.monitoringService.saveTrailerGroup(this.group), true).subscribe();
  }

  /**
   * Отмена редактирования группы прицепов
   */
  public cancel() {
    this.store.editTrailerGroup = null;
  }

  /**
   * Обработка после инициализации компонента
   */
  public ngOnInit() {
    this.refreshTrailers();
  }

  /**
   * Обновление списка прицепов группы
   */
  private refreshTrailers() {
    this.trailers = this.store?.trailers
      ?.filter((d) => this.group?.trailers?.includes(d.id))
      ?.sort(localeSort);
  }
}
