import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IMaintenanceInterval } from '../../../../shared/maintenance/IMaintenanceInterval';
import { UnitRightType } from '../../../../shared/rights/RightType';
import { IUnit } from '../../../../shared/units/IUnit';

/** Интерфейс компонента с подробной информацией об интервале техобслуживания */
interface IUnitTOIntervalComponent {
  /** Оригинальный интервал */
  originalTO: IMaintenanceInterval;
  /** Объект мониторинга */
  unit: IUnit;
  /** Признак режима добавления объекта мониторинга */
  isAddRegime: boolean;
}

/** Компонент с подробной информацией об интервале техобслуживания */
@Component({
  selector: 'unit-to-interval',
  templateUrl: './unit.to-interval.component.html'
})
export class UnitTOIntervalComponent
extends DialogComponent<IUnitTOIntervalComponent, IMaintenanceInterval>
implements IUnitTOIntervalComponent {

  /** Оригинальный интервал */
  public originalTO: IMaintenanceInterval;
  /** Объект мониторинга */
  public unit: IUnit;
  /** Признак режима добавления объекта мониторинга */
  public isAddRegime: boolean;

  /** Заголовок окна */
  public title: string;
  /** Редактируемый интервал */
  public to: IMaintenanceInterval;
  /** Дата последнего выполнения техобслуживания с интервалом по дням */
  public byDaysLast: Date;

  /**
   * Конструтор
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private translator: TranslateService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IUnitTOIntervalComponent) {
    this.translator.get([
      'component.unit.to.interval.title-add-interval',
      'component.unit.to.interval.title-edit-interval',
      'component.unit.to.interval.title-show-interval'
    ]).subscribe((x) => {
      if (!data.originalTO) {
        this.title = x['component.unit.to.interval.title-add-interval'];
        // tslint:disable-next-line:no-bitwise
      } else if (data.isAddRegime || (data.unit.rights & UnitRightType.CHANGE_TO)) {
        this.title = x['component.unit.to.interval.title-edit-interval'];
      } else {
        this.title = x['component.unit.to.interval.title-show-interval'];
      }
    });

    if (!data.originalTO) {
      this.to = { runCount: 0, name: '' };
    } else {
      this.to = {
        ...data.originalTO,
        byDays: data.originalTO.byDays ? { ...data.originalTO.byDays } : null,
        byMh: data.originalTO.byMh ? { ...data.originalTO.byMh } : null,
        byMileage: data.originalTO.byMileage ? { ...data.originalTO.byMileage } : null
      };

      if (this.to.byDays) {
        this.byDaysLast = new Date(this.to.byDays.last as number);
      }
    }

    return super.fillData(data);
  }

  /** Получение признака интервала по пробегу */
  public get isByMileage() {
    return !!this.to.byMileage;
  }

  /** Переключение интервала по пробегу */
  public toggleByMileage() {
    if (this.to.byMileage) {
      delete this.to.byMileage;
    } else {
      this.to.byMileage = { last: 0, value: 0 };
    }
  }

  /** Получение значения счетчика пробега */
  public get mileageCounter() {
    if (!this.unit.mileageCounter) { return 0; }
    return Math.round(this.unit.mileageCounter.value);
  }

  /** Получение признака интервала по моточасам */
  public get isByMh() {
    return !!this.to.byMh;
  }

  /** Переключение интервала по моточасам */
  public toggleByMh() {
    if (this.to.byMh) {
      delete this.to.byMh;
    } else {
      this.to.byMh = { last: 0, value: 0 };
    }
  }

  /** Получение значения счетчика моточасов */
  public get mhCounter() {
    if (!this.unit.mhCounter) { return 0; }
    return Math.round(this.unit.mhCounter.value);
  }

  /** Получение признака интервала по дням */
  public get isByDays() {
    return !!this.to.byDays;
  }

  /** Переключение интервала по дням */
  public toggleByDays() {
    if (this.to.byDays) {
      delete this.to.byDays;
    } else {
      this.byDaysLast = new Date();
      this.to.byDays = { last: this.byDaysLast.getTime(), value: 0 };
    }
  }

  /** Получение признака возможности редактирования записи */
  public get isCanChangeTO() {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.unit.rights & UnitRightType.CHANGE_TO);
  }

  /** Получение признака возможности сохранения изменений */
  public get isCanSave() {
    if (!this.to.name || this.to.name === '') {
      return false;
    }

    if (!this.to.byDays && !this.to.byMh && !this.to.byMileage) {
      return false;
    }

    if (this.to.byDays && (!this.byDaysLast || this.to.byDays.value < 1)) {
      return false;
    }

    if (this.to.byMh && this.to.byMh.value < 1) {
      return false;
    }

    return !(this.to.byMileage && this.to.byMileage.value < 1);
  }

  /** Подтверждение изменений */
  public confirm() {
    if (!this.isCanChangeTO || !this.isCanSave) { return; }

    if (this.to.byDays) { this.to.byDays.last = this.byDaysLast.getTime(); }
    this.result = this.to;
    this.close();
  }
}
