import { Component, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IMessagesRequest } from '../../../shared/messages/IMessagesRequest';
import { getMessageTypeName, MessageType } from '../../../shared/messages/MessageType';
import { IListItem } from '../../classes/IListItem';
import { BGMessagesService } from '../../services/b-g-messages.service';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { StoreService } from '../../services/store.service';
import { UsersService } from '../../services/users.service';
import { getAllEnumValues } from '../../utils/enums';
import { localeSort } from '../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';
import { SelectPeriodComponent } from '../select-period/select-period.component';

/**
 * Компонент для указания параметров запроса сообщений
 */
@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  /** Список типов сообщений */
  public messageTypes: IListItem<MessageType>[];

  /** Список объектов мониторинга */
  public units: IListItem<string>[] = [];

  /** Список всех доступных объектов мониторинга */
  private allUnits: IListItem<string>[];

  /**
   * Конструктор
   * @param messagesService Сервис работы с сообщениями
   * @param monitoringService Сервис мониторинга
   * @param crudService Сервис ДИУП
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param store Сервис для хранения данных мониторинга
   * @param usersService Сервис для работы с пользователями
   */
  constructor(
    public messagesService: BGMessagesService,
    private monitoringService: MonitoringService,
    private crudService: CRUDService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private store: StoreService,
    private usersService: UsersService
  ) {
    this.messageTypes = getAllEnumValues<MessageType>(MessageType)
      .map((type) => ({ id: type, name: getMessageTypeName(type) }));
  }

  /**
   * Получение признака возможности выполнения запроса сообщений
   */
  public get isCanGetMessages() {
    return this.messagesService.selectedUnit && this.messagesService.messageType &&
      this.messagesService.from && this.messagesService.to;
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Выбор периода
   */
  public selectPeriod() {
    const data = {
      from: this.messagesService.from,
      to: this.messagesService.to,
      disableUntil: this.usersService.getUserHistoryPeriodFrom,
      disableSince: this.usersService.getUserHistoryPeriodTo
    };

    this.dialogService.addDialog(SelectPeriodComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.messagesService.from = result.from;
        this.messagesService.to = result.to;
      });
  }

  /**
   * Получение сообщений
   */
  public getMessages() {
    if (!this.isCanGetMessages) {
      return;
    }

    const ps = localStorage.getItem('messagesTablePageSize');
    const limit = (!ps || ps === '' || ps === '0') ? 25 : +ps;
    const messagesRequest: IMessagesRequest = {
      unitId: this.messagesService.selectedUnit.id,
      from: this.messagesService.from.getTime(),
      to: this.messagesService.to.getTime(),
      limit,
      offset: 0,
      type: this.messagesService.messageType
    };

    this.monitoringService.changeMessagesRequest(messagesRequest);
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    // Делаем задержку перед получением списка ТС на тот случай, если список еще не успел загрузиться
    if (!this.store.units.length) {
      this.loadingService.wrap(timer(1000)).subscribe(this.setUnits);
    } else {
      this.setUnits();
    }
  }

  /**
   * Установка списка объектов мониторинга
   */
  private setUnits = () => {
    this.units = this.store.units.map(({ id, name }) => ({ id, name }))
      ?.sort(localeSort);

    if (!this.messagesService.selectedUnit) {
      return;
    }

    const existsUnit = this.units.find((o) => o.id === this.messagesService.selectedUnit.id);
    if (existsUnit) {
      this.messagesService.selectedUnit = existsUnit;
    } else {
      this.units.push(this.messagesService.selectedUnit);
    }
  };

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.allUnits.filter((u) => u.id === this.messagesService.selectedUnit?.id);

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.messages.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.messagesService.selectedUnit = result?.shift();
        if (!this.messagesService.selectedUnit) {
          this.messagesService.selectedUnit = null;
          return;
        }

        const existsUnit = this.units.find((u) => u.id === this.messagesService.selectedUnit.id);
        if (existsUnit) {
          this.messagesService.selectedUnit = existsUnit;
        } else {
          this.units.push(this.messagesService.selectedUnit);
        }
      });
  }
}
