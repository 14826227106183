import { Component, Input } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IOperation } from '../../../../shared/crm/operations/IOperation';
import { IOperationsForPeriod } from '../../../../shared/crm/operations/IOperationsForPeriod';
import { IOperationsRequest } from '../../../../shared/crm/operations/IOperationsRequest';
import {
  getAllOperationTypes,
  getOperationTypeName,
  OperationType
} from '../../../../shared/crm/operations/OperationType';
import { IListItem } from '../../../classes/IListItem';
import { LoadingService } from '../../../services/loading.service';
import { OperationsService } from '../../../services/operations.service';
import { SelectPeriodComponent } from '../../select-period/select-period.component';

/**
 * Компонент для отображения списка операций по учетной записи
 */
@Component({
  selector: 'account-operations',
  templateUrl: './account.operations.component.html',
  styleUrls: ['./account.operations.component.scss']
})
export class AccountOperationsComponent {

  /** Идентификатор учетной записи */
  @Input() public accountId: string;

  /** Данные запроса на получение списка операций */
  public request: IOperationsRequest;

  /** Список типов операций */
  public operationTypes: IListItem<OperationType>[] = [];

  /** Данные об операциях за период */
  public operationsForPeriod: IOperationsForPeriod;

  /**
   * Конструктор
   * @param operationsService Сервис работы с операциями
   * @param loadingService Сервис для отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   */
  constructor(
    private operationsService: OperationsService,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {
    this.operationsForPeriod = { operations: [] } as IOperationsForPeriod;

    this.operationTypes = getAllOperationTypes().map((id) => ({ id, name: getOperationTypeName(id) }));

    const now = new Date();
    const from = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
    const to = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    this.request = {
      accountId: null,
      from: from.getTime(),
      to: to.getTime()
    };
  }

  /**
   * Получение стиля для суммы операции
   * (красный или зеленый цвет в зависимости от списания/поступления)
   * @param operation Операция
   */
  public getSumColor(operation: IOperation) {
    return operation.type === OperationType.DEBIT
        ? 'p-danger'
        : operation.sum >= 0
          ? 'p-success'
          : 'p-warning';
  }

  /**
   * Загрузка операций по запросу
   */
  public load() {
    if (!this.accountId) {
      return;
    }

    this.request.accountId = this.accountId;

    this.loadingService.withLoading(
      this.operationsService.getForPeriod(this.request),
      (operationsForPeriod) => {
        operationsForPeriod.operations?.sort((a, b) => a.date - b.date);

        operationsForPeriod.operations.forEach((operation) => {
          operation.sum /= 100;
          if (operation.cost) {
            operation.cost /= 100;
          }
        });

        operationsForPeriod.startBalance /= 100;
        operationsForPeriod.creditsSum /= 100;
        operationsForPeriod.debitsSum /= 100;
        operationsForPeriod.endBalance /= 100;

        this.operationsForPeriod = operationsForPeriod;
      }
    );
  }

  /**
   * Выбор периода
   */
  public selectPeriod() {
    const data = {from: new Date(this.request.from), to: new Date(this.request.to) };
    this.dialogService.addDialog(SelectPeriodComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.request.from = result.from.getTime();
        this.request.to = result.to.getTime();
      });
  }

}
