/**
 * Типы импорта
 */
export enum ImportType {
    /** Замена */
    REPLACE = 1,
    /** Слияние */
    MERGE,
    /** Добавление */
    ADD
}

/**
 * Получение списка всех типов импорта
 */
export function getAllImportTypes() {
    const result: ImportType[] = [];
    for (let i = 1; i <= 3; i++) {
        result.push(i as ImportType);
    }
    return result;
}

/**
 * Получение наименования типа импорта
 * @param type Тип импорта
 */
export function getImportTypeName(type: ImportType): string {
    switch (type) {
        case ImportType.REPLACE: return 'enums.import.type.change';
        case ImportType.MERGE: return 'enums.import.type.merge';
        case ImportType.ADD: return 'enums.import.type.add';
        default: return 'enums.import.type.unknown';
    }
}
