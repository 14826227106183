import { Component, Input } from '@angular/core';

import { IClientDriver } from '../../../classes/IClientDriver';
import { BgPopoverComponent, BgPopoverService } from '../../../modules/bg-popover';

/**
 * Интерфейс компонента для отображения детальной информации о водителе
 */
interface IDriversDetailComponent {
  /** Водитель, по которому отображается детальная информация на карте */
  driver: IClientDriver;
}

/**
 * Компонент для отображения детальной информации о водителе
 */
@Component({
  selector: 'drivers-detail',
  templateUrl: './drivers.detail.component.html',
  styleUrls: ['./drivers.detail.component.scss']
})
export class DriversDetailComponent
  extends BgPopoverComponent<IDriversDetailComponent>
  implements IDriversDetailComponent {

  /** Водитель */
  @Input() public driver: IClientDriver;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   */
  constructor(popoverService: BgPopoverService) {
    super(popoverService);
  }

  /**
   * Получение css класса для отображения статуса водителя (занят или свободен)
   */
  public get driverStatusClass() {
    if (!this.driver.assignment) { return 'grey'; }

    return this.isBusy ? 'green' : 'red';
  }

  /**
   * Проверка занятости водителя
   */
  public get isBusy() {
    if (!this.driver.assignment) { return false; }

    return !this.driver.assignment.e || this.driver.assignment.e >= (new Date()).getTime();
  }
}
