import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISummary } from '../../shared/analytics/ISummary';

import { ConfigService } from './config.service';
import { IReportTemplateInfo } from './report-templates.service';

/**
 * Сервис для работы с аналитикой
 */
@Injectable()
export class AnalyticsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/analytics`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Получение сводных данных аналитики
   */
  public getSummary(): Observable<ISummary> {
    const url = `${this.baseUrl}/summary`;
    return this.http.get<ISummary>(url);
  }

  /**
   * Получение списка аналитических отчетов
   */
  public getAnalyticsReportTemplates(): Observable<IReportTemplateInfo[]> {
    const url = `${this.baseUrl}/report-templates`;
    return this.http.get<IReportTemplateInfo[]>(url);
  }
}
