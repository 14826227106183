import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { RightType } from '../../../../shared/rights/RightType';
import { ITrailer } from '../../../../shared/trailers/ITrailer';
import { DetailTab } from '../../../classes/DetailTab';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с подробной информацией о прицепе
 * при создании на его основе модального окна
 */
export interface ITrailersEditComponent {
  /** Идентификатор прицепа */
  trailerId: string;
}

/**
 * Компонент, представляющий подробную информацию о прицепе
 */
@Component({
  selector: 'trailers-edit',
  templateUrl: './trailers.edit.component.html'
})
export class TrailersEditComponent
  extends DialogComponent<ITrailersEditComponent, boolean>
  implements ITrailersEditComponent {

  /** Идентификатор прицепа */
  public trailerId: string;

  /** Подробная информация о прицепе */
  public trailer: ITrailer;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Текст сообщения об ошибке */
  public error: string;

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Заголовок окна */
  public title: string;

  /** Фейковые права доступа для управления произвольными полями прицепа */
  // tslint:disable-next-line:no-bitwise
  public readonly rights = RightType.VIEW_ARBITRARY_FIELDS | RightType.CHANGE_ARBITRARY_FIELDS;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для работы с ДИУП
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private translator: TranslateService
  ) {
    super(dialogService);
    this.trailer = {} as ITrailer;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: ITrailersEditComponent) {
    this.translator.get([
      'component.trailers.edit.title-edit',
      'component.trailers.edit.title-add',
      'component.trailers.edit.main',
      'component.trailers.edit.arbitrary'
    ]).subscribe((x) => {
      this.title = data.trailerId
        ? x['component.trailers.edit.title-edit']
        : x['component.trailers.edit.title-add'];

      this.tabs[Tabs.Main] = new DetailTab(x['component.trailers.edit.main']);
      this.tabs[Tabs.Arbitrary] = new DetailTab(x['component.trailers.edit.arbitrary']);
      this.selectedTab = this.tabs[Tabs.Main];
    });

    if (data.trailerId && data.trailerId !== '') {
      this.crudService.get(data.trailerId, CRUDEntityType.TRAILER).subscribe(
        (trailer) => this.trailer = trailer,
        (error) => this.error = error
      );
    }

    return super.fillData(data);
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Проверка на соответствие активной вкладке
   * @param n Индекс проверяемой вкладки
   */
  public selectedTabIs(n: Tabs) {
    return this.selectedTab === this.tabs[n];
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSaveTrailer) { return; }

    this.crudService.addUpdate(
      this.trailer, CRUDEntityType.TRAILER
    ).subscribe(
      () => {
        this.result = true;
        this.close();
      },
      (error) => this.error = error
    );
  }

  /**
   * Получение признака на возможность сохранения прицепа
   */
  public get isCanSaveTrailer() {
    return this.trailer && this.trailer.name && this.trailer.name !== '';
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Arbitrary
}
