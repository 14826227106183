import { ReportType } from '../../../app/services/report-templates.service';

import { ReportTableFilterType } from './ReportTableFilterType';
import { ReportTableType } from './ReportTableType';

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы отчета
 * @param tableType Тип таблицы отчета
 * @param reportType Тип отчета
 */
export function getReportTableFilters(tableType: ReportTableType, reportType: ReportType): ReportTableFilterType[] {
  switch (tableType) {
    case ReportTableType.GEOZONES: return getGeozonesTableFilters();
    case ReportTableType.COUNTERS: return getCountersTableFilters();
    case ReportTableType.FUEL: return getFuelTableFilters();
    case ReportTableType.FILLINGS: return getFillingsTableFilters();
    case ReportTableType.MHS: return getMhTableFilters();
    case ReportTableType.STOPS: return getStopsTableFilters();
    case ReportTableType.RACES: return getRacesTableFilters();
    case ReportTableType.SPEEDING: return getSpeedingTableFilters();
    case ReportTableType.SUMMARY: return getSummaryTableFilters();
    case ReportTableType.THEFTS: return getTheftsTableFilters();
    case ReportTableType.PARKINGS: return getParkingsTableFilters();
    case ReportTableType.LOADINGS: return getLoadingsTableFilters();
    case ReportTableType.DIGITAL_SENSORS: return getDigitalSensorsTableFilters();
    case ReportTableType.FUEL_BY_CHECK: return getFuelByCheckTableFilters();
    case ReportTableType.EVENTS: return getEventsTableFilters();
    case ReportTableType.VIOLATIONS: return getEventsTableFilters();
    case ReportTableType.DRIVING_QUALITY: return getDrivingQualityTableFilters();
    case ReportTableType.MAINTENANCE: return getMaintenanceTableFilters();
    case ReportTableType.ASSIGNMENTS: return getAssignmentsTableFilters(reportType);
    case ReportTableType.CONNECTION_PROBLEMS: return getLostConnectionTableFilters();
    default: return [];
  }
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Геозоны"
 */
function getGeozonesTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Счетчики"
 */
function getCountersTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.COUNTER_BORDERS,
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Движение топлива"
 */
function getFuelTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.COUNTER_BORDERS, // ???
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Заправки"
 */
function getFillingsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Моточасы"
 */
function getMhTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.MH,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Цифровые датчики"
 */
function getDigitalSensorsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.MH,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Остановки"
 */
function getStopsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Поездки"
 */
function getRacesTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.MH,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Превышения скорости"
 */
function getSpeedingTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Сводка"
 */
function getSummaryTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.MH,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Сливы"
 */
function getTheftsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Стоянки"
 */
function getParkingsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Загрузки"
 */
function getLoadingsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Топливо по чекам"
 */
function getFuelByCheckTableFilters(): ReportTableFilterType[] {
  return [ReportTableFilterType.GEOZONE_UNIT];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблиц "События"/"Нарушения"
 */
function getEventsTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.GEOZONE_UNIT,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Качество вождения"
 */
function getDrivingQualityTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.SENSOR,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Техобслуживание"
 */
function getMaintenanceTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.GEOZONE_UNIT,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Назначения"
 * @param reportType Тип отчёта
 */
function getAssignmentsTableFilters(reportType: ReportType): ReportTableFilterType[] {
  const filter = (reportType === ReportType.DRIVER || reportType === ReportType.DRIVER_GROUP)
    ? ReportTableFilterType.DRIVER
    : ReportTableFilterType.TRAILER;

  return [
    ReportTableFilterType.COUNTER_BORDERS,
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.MILEAGE,
    ReportTableFilterType.MH,
    ReportTableFilterType.SPEED,
    ReportTableFilterType.MOVE,
    ReportTableFilterType.STOP,
    ReportTableFilterType.PARKING,
    ReportTableFilterType.SENSOR,
    filter,
    ReportTableFilterType.FILLING,
    ReportTableFilterType.THEFT,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}

/**
 * Получение списка типов фильтрации интервалов, доступных для таблицы "Потеря связи"
 */
function getLostConnectionTableFilters(): ReportTableFilterType[] {
  return [
    ReportTableFilterType.UNCOMPLETE_INTERVAL,
    ReportTableFilterType.DURATION,
    ReportTableFilterType.DRIVER,
    ReportTableFilterType.TRAILER,
    ReportTableFilterType.GEOZONE_UNIT
  ];
}
