import { Pipe, PipeTransform } from '@angular/core';

import { HistoryType } from '../../shared/crm/hist';
import { IClientHistory } from '../classes/IClientHistory';

/**
 * Фильтр для списка ретрансляторов
 */
@Pipe({
  name: 'rethistfilter',
  pure: false
})
export class RetranslatorsHistoryFilterPipe implements PipeTransform {
  public transform(items: IClientHistory[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => {
        return (item.type === HistoryType.UNIT_RETRANSLATOR) &&
          (item.item.name.toLowerCase().includes(lowerFilter) ||
          item.item.uid.toLowerCase().includes(lowerFilter))
      }
    );
  }
}
