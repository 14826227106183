import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IMaintenanceInterval } from '../../../../shared/maintenance/IMaintenanceInterval';
import { UnitRightType } from '../../../../shared/rights/RightType';
import { IUnit } from '../../../../shared/units/IUnit';
import { ModalService } from '../../../services/modal.service';
import { ModalResult } from '../../modal/modal.component';

import { UnitTOIntervalComponent } from './unit.to-interval.component';

/** Компонент для отображения списка интервалов техобслуживания */
@Component({
  selector: 'unit-to-intervals',
  templateUrl: './unit.to-intervals.component.html'
})
export class UnitTOIntervalsComponent implements OnInit {

  /** Информация об объекте мониторинга */
  @Input() public unit: IUnit;

  /** Признак режима добавления объекта мониторинга */
  @Input() public isAddRegime: boolean;

  /** Список записей с информацией о состоянии интервалов техобслуживания */
  public states: Array<IMaintenanceIntervalState[]> = [];

  /**
   * Конструктор
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   */
  public constructor(
    private modalService: ModalService,
    private dialogService: DialogService,
    private translator: TranslateService
  ) {
  }

  /** Получение признака возможности изменения интервалов техобслуживания */
  public get isCanChangeTO() {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.unit.rights & UnitRightType.CHANGE_TO);
  }

  /** Добавление интервала техобслуживания */
  public addTO() {
    const data = {
      originalTO: null,
      unit: this.unit,
      isAddRegime: this.isAddRegime
    };

    this.dialogService.addDialog(UnitTOIntervalComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.unit.to.push(result);
        this.states.push(this.getStates(result));
      });
  }

  /**
   * Изменение интервала техобслуживания
   * @param to Интервал техобслуживания
   */
  public updateTO(to: IMaintenanceInterval) {
    const data = {
      originalTO: to,
      unit: this.unit,
      isAddRegime: this.isAddRegime
    };

    this.dialogService.addDialog(UnitTOIntervalComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
          const index = this.unit.to.indexOf(to);
          if (index !== -1) {
            this.unit.to[index] = result;
            this.states[index] = this.getStates(result);
          }
        });
  }

  /**
   * Удаление интервала техобслуживания
   * @param to Интервал техобслуживания
   */
  public deleteTO(to: IMaintenanceInterval) {
    const confirm = this.translator.instant(
      'component.unit.to.intervals.confirm-interval',
      { val: to.name });

      this.modalService.showQuestion(confirm)
        .pipe(filter((result) => result === ModalResult.YES))
        .subscribe(() => {
          const index = this.unit.to.indexOf(to);
          if (index !== -1) {
            this.unit.to.splice(index, 1);
            this.states.splice(index, 1);
          }
        });
  }

  /** Обработки после инициализации компонента */
  public ngOnInit() {
    if (!this.unit.to) {
      this.unit.to = [];
    }

    for (const to of this.unit.to) {
      this.states.push(this.getStates(to));
    }
  }

  /**
   * Получение состояния интервала техобслуживания
   * @param to Интервал техобслуживания
   */
  private getStates(to: IMaintenanceInterval): IMaintenanceIntervalState[] {
    const result: IMaintenanceIntervalState[] = [];

    if (to.byMileage) {
      const dif = this.unit.mileageCounter
        ? to.byMileage.value - this.unit.mileageCounter.value + to.byMileage.last
        : to.byMileage.value;
      result.push(this.fillState(dif, '-km'));
    }

    if (to.byMh) {
      const dif = this.unit.mhCounter
        ? to.byMh.value - this.unit.mhCounter.value + to.byMh.last
        : to.byMh.value;
      result.push(this.fillState(dif, '-mh'));
    }

    if (to.byDays) {
      const dif = to.byDays.value - (new Date().getTime() - (to.byDays.last as number)) / 86400000;
      result.push(this.fillState(dif, '-d'));
    }

    return result;
  }

  /**
   * Заполнение интервала техобслуживания
   * @param val Значение
   * @param metric Метрика
   */
  private fillState(val: number, metric: string): IMaintenanceIntervalState {
    val = Math.ceil(val);
    const overdue = val <= 0;
    val = Math.abs(val);

    const key = 'component.unit.to.intervals.' + (overdue ? 'overdue' : 'remain') + metric;
    const text = this.translator.instant(key, { val });

    return { text, overdue };
  }
}

/** Состояние интервала техобслуживания */
interface IMaintenanceIntervalState {
  /** Текст состояния */
  text: string;
  /** Признак просроченного состояния */
  overdue: boolean;
}
