import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { zip } from 'rxjs';
import { filter } from 'rxjs/operators';

import { deepClone } from '../../../../shared/CloneHelper';
import { FastPeriodType, getAllFastPeriodTypes, getFastPeriodTypeName } from '../../../../shared/FastPeriodType';
import { ISimpleObject } from '../../../../shared/ISimpleObject';
import { ISendReport } from '../../../../shared/reports/ISendReport';
import { IChangeAccess } from '../../../../shared/rights/IChangeAccess';
import {
  AccountRightType,
  getAllRights,
  getAllUnitRights,
  getRightTypeName,
  getUnitRightTypeName,
  RightType,
  UnitRightType
} from '../../../../shared/rights/RightType';
import { getAllSensorTypes, getSensorTypeName, SensorType } from '../../../../shared/sensors/SensorType';
import { ITimeLimits, processingTimeLimits } from '../../../../shared/time/ITimeLimits';
import { IChangeCounter } from '../../../../shared/units/IChangeCounter';
import { DetailComponent } from '../../../classes/DetailComponent';
import { DetailTab } from '../../../classes/DetailTab';
import { ICheckedListItem } from '../../../classes/ICheckedListItem';
import { IListItem } from '../../../classes/IListItem';
import { AccountsService } from '../../../services/accounts.service';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import {
  getNotificationActionTypeName,
  getNotificationTypeName,
  INotificationAction,
  INotificationGeozones,
  INotificationSetting,
  NotificationActionType,
  NotificationsService,
  NotificationType
} from '../../../services/notifications.service';
import {
  getAllTrafficLightTypes,
  getTrafficLightTypeName,
  TrafficLightType
} from '../../../services/online-notifications.service';
import { ToastService } from '../../../services/toast.service';
import { UsersService } from '../../../services/users.service';
import { getAllEnumValues } from '../../../utils/enums';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Интерфейс компонента для редактирования настройки уведомления
 */
interface INotificationSettingComponent {
  /** Идентификатор настройки уведомления */
  nsId?: string;
  /** Признак того, что выполняется копирование настройки уведомления */
  copy?: boolean;
}

/**
 * Компонент для редактирования настройки уведомления
 */
@Component({
  selector: 'notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent
  extends DetailComponent<INotificationSettingComponent>
  implements INotificationSettingComponent {

  /** Идентификатор настройки уведомления */
  public nsId?: string;

  /** Признак того, что выполняется копирование настройки уведомления */
  public copy?: boolean;

  /** Настройки уведомления */
  public notificationSetting: INotificationSetting;

  /** Список типов уведомлений */
  public notificationTypes: IListItem<NotificationType>[] = [];

  /** Список типов значений сигнала светофора */
  public trafficLightTypes: IListItem<TrafficLightType>[] = [];

  /** Список доступных для выбора объектов мониторинга */
  public units: IListItem<string>[] = [];

  /** Список доступных для выбора групп объектов мониторинга */
  public unitGroups: IListItem<string>[] = [];

  /** Список доступных для выбора геозон */
  public geozones: IListItem<string>[] = [];

  /** Список доступных для выбора групп геозон */
  public geozoneGroups: IListItem<string>[] = [];

  /** Список доступных для выбора пользователей */
  public users: ICheckedListItem<string>[] = [];

  /** Список выбранных объектов/групп объектов */
  public selectedItems: IClientNotificationSettingItem[] = [];

  /** Список выбранных геозон для проверки на вход */
  public selectedInsideGeozones: IClientNotificationSettingItem[] = [];

  /** Список выбранных геозон для проверки на выход */
  public selectedOutsideGeozones: IClientNotificationSettingItem[] = [];

  /** Данные по онлайн-уведомлению */
  public onlineNotification: IClientNotificationSettingAction;

  /** Данные по email уведомлению */
  public emailNotification: IClientNotificationSettingAction;

  /** Данные по мобильному уведомлению */
  public mobileNotification: IClientNotificationSettingAction;

  /** Данные по регистрации события при уведомлении */
  public eventNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению о снятии водителя */
  public removeDriverNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению о снятии прицепа */
  public removeTrailerNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению об учете счетчика пробега */
  public setMileageCounterNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению об учете счетчика моточасов */
  public setMhCounterNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению об изменении доступа */
  public changeAccessNotification: IClientNotificationSettingAction;

  /** Данные по уведомлению об отправке отчета */
  public sendReportNotification: IClientNotificationSettingAction;

  /** Список типов датчиков */
  public sensorTypes: IListItem<SensorType>[] = [];

  /** Список доступных учетных записей */
  public accounts: IListItem<string>[] = [];

  /** Список доступных для выбора шаблонов отчетов */
  public reportTemplates: ISimpleObject[] = [];

  /** Список типов периодов формирования отчета */
  public fastPeriodTypes: IListItem<FastPeriodType>[] = [];

  /** Список настроек для прав доступа */
  public rightItems: IChangeRightItem[] = [];

  /** Строка поиска по пользователям */
  public searchUser: string;

  /** Ограничения времени */
  public timeLimits: ITimeLimits;

  /**
   * Строка поиска
   */
  public search: string;

  /**
   * Строка поиска внутри геозон
   */
  public searchInsideGeozones: string;

  /**
   * Строка поиска вне геозон
   */
  public searchOutsideGeozones: string;

  /**
   * Типы уведомлений
   */
  public nType = NotificationType;

  /** Постфикс для копии */
  private copyPostfix = '';

  /**
   * Конструктор
   * @param dialogService Севрис диалоговых окон
   * @param translator Сервис для перевода
   * @param accountsService Сервис работы с учетными записями
   * @param crudService Сервис ДИУП
   * @param loadingService Сервис для отображения процесса загрузки
   * @param toastService Сервис модальных окон
   * @param usersService Сервис работы с пользователями
   * @param notificationsService Сервис для работы с уведомлениями
   */
  constructor(
    dialogService: DialogService,
    translator: TranslateService,
    private accountsService: AccountsService,
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private usersService: UsersService,
    private notificationsService: NotificationsService
  ) {
    super(dialogService);

    translator.get('ui.copy2')
      .subscribe((x) => this.copyPostfix = x);

    this.notificationTypes = getAllEnumValues<NotificationType>(NotificationType)
      .map((type) => ({ id: type, name: getNotificationTypeName(type) }));

    this.trafficLightTypes = getAllTrafficLightTypes()
      .map((type) => ({ id: type, name: getTrafficLightTypeName(type) }));

    this.sensorTypes = getAllSensorTypes()
      .map((type) => ({ id: type, name: getSensorTypeName(type) }));

    this.fastPeriodTypes = getAllFastPeriodTypes()
      .map((type) => ({ id: type, name: getFastPeriodTypeName(type) }));

    this.tabs[Tabs.Main] = new DetailTab('component.notifications.notification-setting.main');
    this.tabs[Tabs.Params] = new DetailTab('component.notifications.notification-setting.params');
    this.tabs[Tabs.Objects] = new DetailTab('component.notifications.notification-setting.objects');
    this.tabs[Tabs.Actions] = new DetailTab('component.notifications.notification-setting.actions');

    this.selectedTab = this.tabs[Tabs.Main];

    this.onlineNotification = {
      type: NotificationActionType.ONLINE,
      checked: false,
      color: '#ff0000',
      sound: true,
      name: getNotificationActionTypeName(NotificationActionType.ONLINE)
    };

    this.emailNotification = {
      type: NotificationActionType.EMAIL,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.EMAIL),
      destinations: []
    };

    this.mobileNotification = {
      type: NotificationActionType.MOBILE,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.MOBILE),
      destinations: []
    };

    this.eventNotification = {
      type: NotificationActionType.EVENT,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.EVENT),
      violation: false
    };

    this.removeDriverNotification = {
      type: NotificationActionType.REMOVE_DRIVER,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.REMOVE_DRIVER)
    };

    this.removeTrailerNotification = {
      type: NotificationActionType.REMOVE_TRAILER,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.REMOVE_TRAILER)
    };

    this.setMileageCounterNotification = {
      type: NotificationActionType.CHANGE_MILEAGE_COUNTER,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.CHANGE_MILEAGE_COUNTER),
      changeCounter: {} as IChangeCounter
    };

    this.setMhCounterNotification = {
      type: NotificationActionType.CHANGE_MH_COUNTER,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.CHANGE_MH_COUNTER),
      changeCounter: {} as IChangeCounter
    };

    this.changeAccessNotification = {
      type: NotificationActionType.CHANGE_ACCESS,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.CHANGE_ACCESS),
      changeAccess: { add: 0, del: 0, users: [] }
    };

    this.sendReportNotification = {
      type: NotificationActionType.SEND_REPORT,
      checked: false,
      name: getNotificationActionTypeName(NotificationActionType.SEND_REPORT),
      sendReport: {
        emails: [],
        excel: false,
        pdf: false,
        periodType: FastPeriodType.TODAY,
        templateId: null,
        zip: false
      }
    };

    this.notificationSetting = {
      actions: [],
      active: true,
      type: NotificationType.SPEED,
      speed: { from: 0, to: 90 },
      minDuration: '00:00:00',
      units: []
    } as INotificationSetting;

    this.loadUnitsAndGroups();
    this.fillRightItems();
    this.loadUsers();
  }

  /**
   * Получение признака наличия лимитов времени
   */
  get isTimeLimits() {
    return !!this.timeLimits;
  }

  /**
   * Признак возможности сохранения настройки уведомления
   */
  public get isCanSave() {
    const ns = this.notificationSetting;

    if (!ns.name?.trim()) {
      return false;
    }

    if (this.sendReportNotification.checked && !this.checkSendReport(this.sendReportNotification.sendReport)) {
      return false;
    }

    if (this.changeAccessNotification.checked && !this.checkChangeAccess(this.changeAccessNotification.changeAccess)) {
      return false;
    }

    if (this.setMileageCounterNotification.checked && !this.checkChangeCounter(this.setMileageCounterNotification.changeCounter)) {
      return false;
    }

    if (this.setMhCounterNotification.checked && !this.checkChangeCounter(this.setMhCounterNotification.changeCounter)) {
      return false;
    }

    switch (ns.type) {

      case NotificationType.SPEED:
        return (ns.speed && ns.speed.from != null && ns.speed.to != null);

      case NotificationType.GEOZONE:
        return !!(ns.geozones
          && (this.selectedInsideGeozones.length
            || this.selectedOutsideGeozones.length));

      case NotificationType.SENSOR:
        return (ns.sensorValue
          && (ns.sensorValue.type || (ns.sensorValue.mask && ns.sensorValue.mask !== ''))
          && ((ns.sensorValue.calcDelta && ns.sensorValue.delta != null)
            || (!ns.sensorValue.calcDelta && ns.sensorValue.from != null && ns.sensorValue.to != null)));

      case NotificationType.LOSS_CONNECTION:
        return !!(ns.lossConnection && ns.lossConnection.interval);

      case NotificationType.PARKING:
        return !!(ns.parking && ns.parking.maxAllowed
          && ns.parking.maxAllowed !== '00:00:00'
          && ns.parking.maxAllowed.match(/^[0-9]+:[0-9]{2}:[0-9]{2}$/i));

      case NotificationType.FILLING:
        return !!ns.filling;

      case NotificationType.THEFT:
        return !!ns.theft;

      case NotificationType.DRIVER:
        return !!ns.driver;

      case NotificationType.TRAILER:
        return !!ns.trailer;

      case NotificationType.MAINTENANCE:
        return ns.maintenance
          ? !!(ns.maintenance.days || ns.maintenance.mh || ns.maintenance.mileage)
          : false;

      case NotificationType.ALARM:
        return true;
    }

    return false;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: INotificationSettingComponent) {
    const action = data.nsId ? data.copy ? 'copy' : 'change' : 'add';
    this.title = `component.notifications.notification-setting.${action}-notification-title`;
    this.isAddRegime = action !== 'change';

    if (data.nsId) {
      this.notificationsService.get(data.nsId).subscribe(
        (notificationSetting) => {
          this.onNotificationSettingDataLoaded(notificationSetting, data.copy);
          this.loadingService.stopLoading();
        },
        () => this.toastService.error('component.notifications.notification-settings.get-error'));

    } else {
      this.afterNotificationSettingLoaded();
    }

    return super.fillData(data);
  }

  /**
   * Обработка изменения типа уведомления
   */
  public onTypeChanged() {
    this.tabs[Tabs.Params].hidden = this.notificationSetting.type === NotificationType.ALARM;

    switch (this.notificationSetting.type) {
      case NotificationType.SPEED:
        this.notificationSetting.geozones = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.speed) {
          this.toggleSpeed();
        }
        break;

      case NotificationType.GEOZONE:
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.geozones) {
          this.toggleGeozones();
        }
        break;

      case NotificationType.SENSOR:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.sensorValue) {
          this.toggleSensorValue();
        }
        break;

      case NotificationType.LOSS_CONNECTION:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.lossConnection) {
          this.toggleLossConnection();
        }
        break;

      case NotificationType.PARKING:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.parking) {
          this.toggleParking();
        }
        break;

      case NotificationType.FILLING:
        this.notificationSetting.speed = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.filling) {
          this.toggleFilling();
        }
        break;

      case NotificationType.THEFT:
        this.notificationSetting.speed = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.theft) {
          this.toggleTheft();
        }
        break;

      case NotificationType.DRIVER:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.driver) {
          this.toggleDriver();
        }
        break;

      case NotificationType.TRAILER:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.maintenance = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.trailer) {
          this.toggleTrailer();
        }
        break;

      case NotificationType.MAINTENANCE:
        this.notificationSetting.speed = null;
        this.notificationSetting.geozones = null;
        this.notificationSetting.sensorValue = null;
        this.notificationSetting.lossConnection = null;
        this.notificationSetting.parking = null;
        this.notificationSetting.filling = null;
        this.notificationSetting.theft = null;
        this.notificationSetting.driver = null;
        this.notificationSetting.trailer = null;
        this.notificationSetting.canFault = null;
        if (!this.notificationSetting.maintenance) {
          this.toggleMaintenance();
        }
        break;

      case NotificationType.ALARM:
        this.notificationSetting = {
          type: NotificationType.ALARM,
          name: this.notificationSetting.name,
          accountId: this.notificationSetting.accountId,
          message: this.notificationSetting.message,
          active: false,
          units: [],
          actions: []
        } as INotificationSetting
    }
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onAccountChanged() {
    if (!this.notificationSetting) {
      return;
    }

    const geozones = this.notificationSetting.geozones ?? {} as INotificationGeozones;
    if (geozones.inside?.length) {
      geozones.inside = [];
    }

    if (geozones.outside?.length) {
      geozones.outside = [];
    }

    this.loadGeozones();

    this.reportTemplates = [];
    const sendReport = this.sendReportNotification.sendReport;
    if (sendReport) {
      sendReport.templateId = null;
      this.loadReportTemplates();
    }
  }

  /**
   * Выбор объектов в уведомление
   */
  public selectUnits() {
    const selectedUnits = this.selectedItems.filter((si) => !si.group);
    const data = {
      items: this.units,
      selected: selectedUnits,
      title: 'component.notifications.notification-setting.select-objects-title',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const newSelectedUnits = result;
        const selectedUnitGroups = this.selectedItems.filter((si) => si.group);
        this.selectedItems = [...newSelectedUnits, ...selectedUnitGroups as IListItem<any>[]]
          ?.sort(localeSort);
      });
  }

  /**
   * Выбор групп объектов в уведомление
   */
  public selectUnitGroups() {
    const selectedUnitGroups = this.selectedItems.filter((si) => si.group);
    const data = {
      items: this.unitGroups,
      selected: selectedUnitGroups,
      title: 'component.notifications.notification-setting.select-object-groups-title',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const newSelectedUnitGroups = result.map((ug) => ({ ...ug, group: true } as IListItem<any>));
        const selectedUnits = this.selectedItems.filter((si) => !si.group);
        this.selectedItems = [...newSelectedUnitGroups, ...selectedUnits as IListItem<any>[]]
          ?.sort(localeSort);
      });
  }

  /**
   * Удаление объекта/группы из списка элементов уведомления
   * @param item Удаляемый элемент
   */
  public deleteItem(item: IClientNotificationSettingItem) {
    const index = this.selectedItems.indexOf(item);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  /**
   * Переключение обработки по скорости
   */
  public toggleSpeed() {
    this.notificationSetting.speed = this.notificationSetting.speed ? null : { from: 0, to: 90 };
  }

  /**
   * Переключение обработки по значению датчика
   */
  public toggleSensorValue() {
    const val = {
      calcDelta: false,
      calcSeparately: false,
      outsideInterval: false
    };

    this.notificationSetting.sensorValue = this.notificationSetting.sensorValue ? null : val;
  }

  /**
   * Переключение обработки по геозонам
   */
  public toggleGeozones() {
    if (this.notificationSetting.geozones) {
      this.notificationSetting.geozones = null;
      this.selectedInsideGeozones = [];
      this.selectedOutsideGeozones = [];
    } else {
      this.notificationSetting.geozones = {
        inside: [],
        outside: []
      };
    }
  }

  /**
   * Переключение обработки по потерям связи
   */
  public toggleLossConnection() {
    this.notificationSetting.lossConnection = this.notificationSetting.lossConnection ? null : { interval: 30 };
  }

  /**
   * Переключение обработки по простоям
   */
  public toggleParking() {
    const parking = {
      maxSpeed: 5,
      maxAllowed: '00:10:00'
    };

    this.notificationSetting.parking = this.notificationSetting.parking ? null : parking;
  }

  /**
   * Переключение обработки по заправкам
   */
  public toggleFilling() {
    this.notificationSetting.filling = this.notificationSetting.filling ? null : { mask: '' };
  }

  /**
   * Переключение обработки по сливам
   */
  public toggleTheft() {
    this.notificationSetting.theft = this.notificationSetting.theft ? null : { mask: '' };
  }

  /**
   * Переключение обработки по водителю
   */
  public toggleDriver() {
    this.notificationSetting.driver = this.notificationSetting.driver ? null : { mask: '', isDeny: false };
  }

  /**
   * Переключение обработки по прицепу
   */
  public toggleTrailer() {
    this.notificationSetting.trailer = this.notificationSetting.trailer ? null : { mask: '', isDeny: false };
  }

  /**
   * Переключение обработки по техобслуживанию
   */
  public toggleMaintenance() {
    this.notificationSetting.maintenance = this.notificationSetting.maintenance ? null : { mask: '', isExpiry: false };
  }

  /**
   * Получение признака необходимости отображения блока
   * с настройками обработки по геозонам
   */
  public showGeozonesBlock() {
    return this.notificationSetting.type === NotificationType.GEOZONE
      || this.notificationSetting.type === NotificationType.FILLING
      || this.notificationSetting.type === NotificationType.THEFT;
  }

  /**
   * Получение признака необходимости отображения блока
   * с настройками обработки по значению датчика
   */
  public showSensorValueBlock() {
    return this.notificationSetting.type === NotificationType.SPEED
      || this.notificationSetting.type === NotificationType.SENSOR
      || this.notificationSetting.type === NotificationType.GEOZONE
      || this.notificationSetting.type === NotificationType.PARKING;
  }

  /**
   * Получение признака необходимости отображения блока
   * с настройками обработки по скорости
   */
  public showSpeedBlock() {
    return this.notificationSetting.type === NotificationType.SPEED
      || this.notificationSetting.type === NotificationType.GEOZONE;
  }

  /**
   * Выбор геозон для обработки вхождения в геозоны
   * @param inside Признак выбора геозон для учета вхождения в геозоны
   */
  public selectGeozones(inside: boolean) {
    const geozones = inside
      ? this.selectedInsideGeozones
      : this.selectedOutsideGeozones;

    const selectedGeozones = geozones.filter((gz) => !gz.group);
    const data = {
      items: this.geozones,
      selected: selectedGeozones,
      title: 'component.notifications.notification-setting.select-geo-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const newSelectedGeozones = result;
        const selectedGeozoneGroups = geozones.filter((si) => si.group);
        const newGeozones = [...newSelectedGeozones, ...selectedGeozoneGroups as IListItem<any>[]]
          ?.sort(localeSort);

        if (inside) {
          this.selectedInsideGeozones = newGeozones;
        } else {
          this.selectedOutsideGeozones = newGeozones;
        }
      });
  }

  /**
   * Выбор групп геозон для обработки вхождения в геозоны
   * @param inside Признак выбора групп геозон для учета вхождения в геозоны
   */
  public selectGeozoneGroups(inside: boolean) {
    const geozones = inside
      ? this.selectedInsideGeozones
      : this.selectedOutsideGeozones;

    const selectedGroups = geozones.filter((gz) => gz.group);
    const data = {
      items: this.geozoneGroups,
      selected: selectedGroups,
      title: 'component.notifications.notification-setting.select-geo-groups-title',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const newSelectedGroups = result.map(({ id, name }) => ({ id, name, group: true } as IListItem<any>));
        const selectedGeozones = geozones.filter((si) => !si.group);
        const newGeozones = [...newSelectedGroups, ...selectedGeozones as IListItem<any>[]]
          ?.sort(localeSort);

        if (inside) {
          this.selectedInsideGeozones = newGeozones;
        } else {
          this.selectedOutsideGeozones = newGeozones;
        }
      });
  }

  /**
   * Удаление геозоны из обработки вхождения в геозоны
   * @param geozone Геозона
   * @param inside Признак удаления из списка для учета входа в геозоны
   */
  public deleteGeozone(geozone: IClientNotificationSettingItem, inside: boolean) {
    const geozones = inside
      ? this.selectedInsideGeozones
      : this.selectedOutsideGeozones;

    const index = geozones.indexOf(geozone);
    if (index !== -1) {
      geozones.splice(index, 1);
    }
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Добавление новой записи в список email
   * @param emails Список email, в который добавляется запись
   */
  public addEmail(emails: string[]) {
    if (emails) {
      emails.push('');
    }
  }

  /**
   * Удаление email из списка
   * @param emails Список email, из которого удаляется запись
   * @param email Индекс удаляемой записи
   */
  public removeEmail(emails: string[], email: string) {
    if (!emails) {
      return;
    }

    const i = emails.indexOf(email);
    emails.splice(i, 1);
  }

  /**
   * Это нужно чтобы нормально работал *ngFor для списка email
   */
  public trackByIndex(index: number, _: number) {
    return index;
  }

  /**
   * Переключение признака наличия лимитов времени
   */
  public toggleTimeLimits() {
    const limits = {
      day: {
        from: { h: 0, m: 0 },
        to: { h: 23, m: 59 }
      },
      week: [],
      month: []
    };

    this.timeLimits = this.isTimeLimits ? undefined : limits;
  }

  /**
   * Обработка изменения выбранного типа периода формирования отчета
   */
  public onSendReportPeriodTypeChanged() {
    if (this.sendReportNotification?.sendReport?.periodType !== FastPeriodType.OTHER) {
      this.sendReportNotification.sendReport.from = null;
      this.sendReportNotification.sendReport.to = null;
    }
  }

  /**
   * Подтверждение изменений (сохранение)
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    if (this.timeLimits) {
      this.notificationSetting.timeLimits = processingTimeLimits(this.timeLimits);

      if (!this.notificationSetting.timeLimits) {
        delete this.notificationSetting.timeLimits;
      }

    } else if (this.notificationSetting.timeLimits) {
      delete this.notificationSetting.timeLimits;
    }

    if (this.notificationSetting.maintenance) {
      if (!this.notificationSetting.maintenance.days) {
        delete this.notificationSetting.maintenance.days;
      }

      if (!this.notificationSetting.maintenance.mh) {
        delete this.notificationSetting.maintenance.mh;
      }

      if (!this.notificationSetting.maintenance.mileage) {
        delete this.notificationSetting.maintenance.mileage;
      }
    }

    this.notificationSetting.actions = [];

    if (this.onlineNotification.checked) {
      this.notificationSetting.actions.push({
        type: NotificationActionType.ONLINE,
        color: this.onlineNotification.color || '#ff0000',
        sound: this.onlineNotification.sound || undefined
      });
    }

    if (this.emailNotification.checked && this.emailNotification.destinations.length) {
      this.notificationSetting.actions.push({
        type: NotificationActionType.EMAIL,
        destinations: this.emailNotification.destinations
      });
    }

    if (this.mobileNotification.checked) {
      this.notificationSetting.actions.push({
        type: NotificationActionType.MOBILE
      });
    }

    if (this.eventNotification.checked) {
      this.notificationSetting.actions.push({
        type: NotificationActionType.EVENT,
        violation: this.eventNotification.violation || undefined
      });
    }

    if (this.removeDriverNotification.checked) {
      this.notificationSetting.actions.push({
        type: this.removeDriverNotification.type
      });
    }

    if (this.removeTrailerNotification.checked) {
      this.notificationSetting.actions.push({
        type: this.removeTrailerNotification.type
      });
    }

    if (this.setMileageCounterNotification.checked) {
      this.notificationSetting.actions.push({
        type: this.setMileageCounterNotification.type,
        changeCounter: this.setMileageCounterNotification.changeCounter
      });
    }

    if (this.setMhCounterNotification.checked) {
      this.notificationSetting.actions.push({
        type: this.setMhCounterNotification.type,
        changeCounter: this.setMhCounterNotification.changeCounter
      });
    }

    if (this.sendReportNotification.checked) {
      this.notificationSetting.actions.push({
        type: this.sendReportNotification.type,
        sendReport: this.sendReportNotification.sendReport
      });
    }

    if (this.changeAccessNotification.checked) {
      this.changeAccessNotification.changeAccess.users = this.users
        .filter((u) => u.checked)
        .map(({ id }) => id);
      this.changeAccessNotification.changeAccess.add = 0;
      this.changeAccessNotification.changeAccess.del = 0;

      for (const rightItem of this.rightItems) {
        if (rightItem.value === ChangeRightValueType.ADD) {
          // tslint:disable-next-line:no-bitwise
          this.changeAccessNotification.changeAccess.add |= rightItem.right;
        } else if (rightItem.value === ChangeRightValueType.DEL) {
          // tslint:disable-next-line:no-bitwise
          this.changeAccessNotification.changeAccess.del |= rightItem.right;
        }
      }

      this.notificationSetting.actions.push({
        type: this.changeAccessNotification.type,
        changeAccess: this.changeAccessNotification.changeAccess
      });
    }

    if (this.notificationSetting.geozones) {
      this.notificationSetting.geozones.inside = this.selectedInsideGeozones.map(({ id, group }) => ({ id, group }));
      this.notificationSetting.geozones.outside = this.selectedOutsideGeozones.map(({ id, group }) => ({ id, group }));
    }

    this.notificationSetting.units = this.selectedItems.map(({ id, group }) => ({ id, group }));

    const observable = this.isAddRegime
      ? this.notificationsService.create(this.notificationSetting)
      : this.notificationsService.update(this.notificationSetting);

    observable.subscribe(
      () => {
        this.result = true;
        this.close();
      },
      () => this.toastService.error('component.notifications.notification-settings.save-error'));
  }

  /**
   * Проверка на правильность заполнения данных настроек счетчика типа "Учет моточасов/пробега"
   * @param changeCounter Данные счетчика
   */
  private checkChangeCounter(changeCounter: IChangeCounter) {
    return changeCounter
      && (changeCounter.setNewValue || changeCounter.saveAsParameter || changeCounter.addEvent)
      && (!changeCounter.setNewValue || changeCounter.newValue)
      && (!changeCounter.saveAsParameter || changeCounter.parameterName);
  }

  /**
   * Проверка на правильность заполнения данных на изменение доступа в действиях
   * @param changeAccess Данные типа "Изменение доступа"
   */
  private checkChangeAccess(changeAccess: IChangeAccess<string>) {
    return changeAccess
      && this.users.some((u) => u.checked)
      && this.rightItems.some((r) => r.value !== ChangeRightValueType.SKIP);
  }

  /**
   * Проверка на правильность заполнения данных на отправку отчета по E-mail в действиях
   * @param report Данные "Отправка отчета"
   */
  private checkSendReport(report: ISendReport<string>): boolean {
    if (!report) {
      return false;
    }

    if (!report.templateId) {
      return false;
    }

    if (!report.periodType) {
      return false;
    }

    if (report.periodType === FastPeriodType.OTHER && (!report.from || !report.to || report.from > report.to)) {
      return false;
    }

    if (!report.emails?.some((e) => !!e)) {
      return false;
    }

    return report.excel || report.pdf;
  }

  /**
   * Загрузка шаблонов отчетов
   */
  private loadReportTemplates() {
    if (!this.notificationSetting?.accountId) {
      return;
    }

    this.loadingService.wrap(this.accountsService.getAccountReportTemplates(this.notificationSetting.accountId), true)
      .subscribe((templates) => this.reportTemplates = templates);
  }

  /**
   * Обработка после загрузки настройки уведомления с сервера
   * @param notificationSetting Настройки уведомления
   * @param copy Признак копирования
   */
  private onNotificationSettingDataLoaded(notificationSetting: INotificationSetting, copy: boolean) {
    this.notificationSetting = notificationSetting;

    this.tabs[Tabs.Params].hidden = this.notificationSetting.type === NotificationType.ALARM;

    if (this.notificationSetting.actions && this.notificationSetting.actions.length) {
      const onlineNotification = this.notificationSetting.actions.find((a) => a.type === NotificationActionType.ONLINE);
      if (onlineNotification) {
        this.onlineNotification.checked = true;
        this.onlineNotification.color = onlineNotification.color || '#ff0000';
        this.onlineNotification.sound = onlineNotification.sound || undefined;
      }
      const emailNotification = this.notificationSetting.actions.find((a) => a.type === NotificationActionType.EMAIL);
      if (emailNotification) {
        this.emailNotification.checked = true;
        this.emailNotification.destinations = emailNotification.destinations;
      }
      const mobileNotification = this.notificationSetting.actions.find((a) => a.type === NotificationActionType.MOBILE);
      if (mobileNotification) {
        this.mobileNotification.checked = true;
      }
      const eventNotification = this.notificationSetting.actions.find((a) => a.type === NotificationActionType.EVENT);
      if (eventNotification) {
        this.eventNotification.checked = true;
        this.eventNotification.violation = eventNotification.violation;
      }
      const removeDriverNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.REMOVE_DRIVER);
      if (removeDriverNotification) {
        this.removeDriverNotification.checked = true;
      }
      const removeTrailerNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.REMOVE_TRAILER);
      if (removeTrailerNotification) {
        this.removeTrailerNotification.checked = true;
      }
      const setMileageCounterNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.CHANGE_MILEAGE_COUNTER);
      if (setMileageCounterNotification) {
        this.setMileageCounterNotification.checked = true;
        if (setMileageCounterNotification.changeCounter) {
          this.setMileageCounterNotification.changeCounter = setMileageCounterNotification.changeCounter;
        }
      }
      const setMhCounterNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.CHANGE_MH_COUNTER);
      if (setMhCounterNotification) {
        this.setMhCounterNotification.checked = true;
        if (setMhCounterNotification.changeCounter) {
          this.setMhCounterNotification.changeCounter = setMhCounterNotification.changeCounter;
        }
      }
      const changeAccessNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.CHANGE_ACCESS);
      if (changeAccessNotification) {
        this.changeAccessNotification.checked = true;
        this.changeAccessNotification.changeAccess = changeAccessNotification.changeAccess;
      }
      const sendReportNotification =
        this.notificationSetting.actions.find((a) => a.type === NotificationActionType.SEND_REPORT);
      if (sendReportNotification) {
        this.sendReportNotification.checked = true;
        this.sendReportNotification.sendReport = sendReportNotification.sendReport;
      }
    }

    if (this.notificationSetting.timeLimits) {
      if (!this.notificationSetting.timeLimits.day) {
        this.notificationSetting.timeLimits.day = {
          from: { h: 0, m: 0 },
          to: { h: 23, m: 59 }
        };
      } else {
        if (!this.notificationSetting.timeLimits.day.from) {
          this.notificationSetting.timeLimits.day.from = { h: 0, m: 0 };
        }
        if (!this.notificationSetting.timeLimits.day.to) {
          this.notificationSetting.timeLimits.day.to = { h: 23, m: 59 };
        }
      }
      this.timeLimits = deepClone(this.notificationSetting.timeLimits);
    }

    if (this.units.length) {
      this.setSelectedUnits();
    }
    if (this.unitGroups.length) {
      this.setSelectedUnitGroups();
    }
    this.selectedItems?.sort(localeSort);

    if (copy) {
      this.notificationSetting.name += this.copyPostfix;
      delete this.notificationSetting.id;
    }
    this.afterNotificationSettingLoaded();
  }

  /**
   * Обработка после загрузки настройки уведомления (а также при создании новой)
   */
  private afterNotificationSettingLoaded() {
    this.loadAccounts();
    this.loadUsers();
    this.fillRightItems();
    this.loadReportTemplates();
  }

  /**
   * Загрузка списка учетных записей
   */
  private loadAccounts() {
    if (this.notificationSetting.id) {
      this.accounts.push({
        id: this.notificationSetting.accountId,
        name: this.notificationSetting.account
      });

      this.loadGeozones();
      return;
    }

    this.loadingService.wrap(this.accountsService.getWithRight(AccountRightType.CHANGE_NOTIFICATIONS), true)
      .subscribe((accounts) => {
        this.accounts = [...accounts?.sort(localeSort)];

        if (!this.notificationSetting.accountId) {
          this.notificationSetting.accountId = accounts?.shift()?.id;
        }

        this.loadGeozones();
        this.loadReportTemplates()
      });
  }

  /**
   * Загрузка списков объектов и групп объектов мониторинга
   */
  private loadUnitsAndGroups() {
    const units$ = this.crudService.getListLight(CRUDEntityType.UNIT);
    const groups$ = this.crudService.getListLight(CRUDEntityType.UNIT_GROUP);

    this.loadingService.wrap(zip(units$, groups$), true)
      .subscribe(([units, groups]) => {
        this.units = units;
        this.unitGroups = groups;
        this.setSelectedUnits();
        this.setSelectedUnitGroups();
        this.selectedItems?.sort(localeSort);
      });
  }

  /** Загрузка пользователей */
  private loadUsers() {
    this.loadingService.wrap(this.usersService.getWithRight(RightType.CHANGE_ACCESS), true)
      .subscribe((users) => {
        this.users = users;
        this.setSelectedUsers();
      });
  }

  /** Заполнение списка элементов прав */
  private fillRightItems() {
    this.rightItems = getAllRights()
      .map((right) => ({ right, name: getRightTypeName(right), value: this.getRightItemValue(right) }));

    this.rightItems.push(...getAllUnitRights()
      .map((right) => ({ right, name: getUnitRightTypeName(right), value: this.getRightItemValue(right) })));
  }

  /**
   * Получение значения элемента права
   * @param right Право доступа
   */
  private getRightItemValue(right: RightType | UnitRightType) {
    // tslint:disable-next-line:no-bitwise
    const isAdd = (this.changeAccessNotification.changeAccess.add & right) > 0;
    // tslint:disable-next-line:no-bitwise
    const isDel = (this.changeAccessNotification.changeAccess.del & right) > 0;

    switch (true) {

      case isAdd:
        return ChangeRightValueType.ADD;

      case isDel:
        return ChangeRightValueType.DEL;

      default:
        return ChangeRightValueType.SKIP;
    }
  }

  /**
   * Загрузка списка геозон
   */
  private loadGeozones() {
    if (!this.notificationSetting.accountId) {
      return;
    }

    const geozones$ = this.accountsService.getAccountGeozones(this.notificationSetting.accountId);
    const geozoneGroups$ = this.accountsService.getAccountGeozoneGroups(this.notificationSetting.accountId);

    zip(geozones$, geozoneGroups$)
      .subscribe(([geozones, groups]) => {
        this.geozones = geozones;
        this.geozoneGroups = groups;
        this.setSelectedGeozones();
        this.selectedInsideGeozones?.sort(localeSort);
        this.selectedOutsideGeozones?.sort(localeSort);
      });
  }

  /**
   * Заполнение списка выбранных объектов
   */
  private setSelectedUnits() {
    if (!this.notificationSetting.units.length) {
      return;
    }

    const selectedUnits = this.units
      .filter((unit) =>
        this.notificationSetting.units.some((nu) => !nu.group && nu.id === unit.id));

    this.selectedItems.push(...selectedUnits);
  }

  /**
   * Заполнение списка выбранных групп объектов
   */
  private setSelectedUnitGroups() {
    if (!this.notificationSetting.units.length) {
      return;
    }

    const selectedUnitGroups = this.unitGroups
      .filter((unitGroup) =>
        this.notificationSetting.units.some((nu) => nu.group && nu.id === unitGroup.id))
      .map((ug) => ({ ...ug, group: true }));

    this.selectedItems.push(...selectedUnitGroups);
  }

  /**
   * Заполнение списков выбранных геозон
   */
  private setSelectedGeozones() {
    const nsGeozones = this.notificationSetting.geozones;
    if (!nsGeozones) {
      return;
    }

    if (nsGeozones.inside && nsGeozones.inside.length) {
      const selectedInside = this.geozones
        .filter((gz) =>
          nsGeozones.inside.some((gi) => !gi.group && gi.id === gz.id));

      const selectedGroupsInside = this.geozoneGroups
        .filter((gr) =>
          nsGeozones.inside.some((gi) => gi.group && gi.id === gr.id))
        .map(({ id, name }) => ({ id, name, group: true }));

      this.selectedInsideGeozones.push(...selectedInside, ...selectedGroupsInside);
    }

    if (nsGeozones.outside && nsGeozones.outside.length) {
      const selectedOutside = this.geozones
        .filter((gz) =>
          nsGeozones.outside.some((go) => !go.group && go.id === gz.id));

      const selectedGroupsOutside = this.geozoneGroups
        .filter((gr) =>
          nsGeozones.outside.some((go) => go.group && go.id === gr.id))
        .map(({ id, name }) => ({ id, name, group: true }));

      this.selectedOutsideGeozones.push(...selectedOutside, ...selectedGroupsOutside);
    }
  }

  /**
   * Заполнение списков выбранных пользователей
   */
  private setSelectedUsers() {
    if (!this.changeAccessNotification?.changeAccess?.users?.length) {
      return;
    }

    for (const user of this.users) {
      user.checked = this.changeAccessNotification.changeAccess.users.includes(user.id);
    }
  }
}

/**
 * Элемент уведомления для использования на стороне клиента
 */
interface IClientNotificationSettingItem extends IListItem<string> {
  /** Признак группы */
  group?: boolean;
}

/**
 * Действие уведомления для использования на стороне клиента
 */
interface IClientNotificationSettingAction extends INotificationAction {
  /** Признак выбранного действия */
  checked: boolean;
  /** Наименование действия */
  name: string;
}

/** Элемент права */
interface IChangeRightItem {
  /** Право доступа */
  right: RightType | UnitRightType;
  /** Наименование права доступа */
  name: string;
  /** Значение */
  value: ChangeRightValueType;
}

/** Значение элемента права */
enum ChangeRightValueType {
  /** Пропустить */
  SKIP,
  /** Добавить */
  ADD,
  /** Удалить */
  DEL
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Params,
  Objects,
  Actions
}
