export enum SensorType {

  // Пробег

  /**
   * Датчик пробега
   */
  MILEAGE = 1,
  /**
   * Относительный одометр
   */
  REL_ODOMETR,

  // Цифровые

  /**
   * Датчик зажигания
   */
  IGNITION,
  /**
   * Тревожная кнопка
   */
  ALERT_BUTTON,
  /**
   * Частный режим
   */
  PRIVATE_REGIME,
  /**
   * Произвольный цифровой датчик
   */
  ARBITRARY_DIGITAL,

  // Показатели

  /**
   * Датчик напряжения
   */
  VOLTAGE,
  /**
   * Датчик веса
   */
  WEIGHT,
  /**
   * Акселерометр
   */
  ACCELEROMETER,
  /**
   * Датчик температуры
   */
  TEMPERATURE,
  /**
   * Коэффициент темпертуры
   */
  TEMPERATURE_KOEF,

  // Двигатель

  /**
   * Обороты двигателя
   */
  ENGINE_SPEED,
  /**
   * Полезная работа двигателя
   */
  ENGINE_WORK,
  /**
   * Абсолютные моточасы
   */
  ABS_MH,
  /**
   * Относительные моточасы
   */
  REL_MH,

  // Топливо

  /**
   * Импульсный датчик расхода топлива
   */
  IMP_FUEL_CONSUMPTION,
  /**
   * Датчик абсолютного расхода топлива
   */
  ABS_FUEL_CONSUMPTION,
  /**
   * Датчик мгновенного расхода топлива
   */
  INSTANTANEOUS_FUEL_CONSUMPTION,
  /**
   * ДУТ
   */
  FUEL_LEVEL,
  /**
   * Импульсный ДУТ
   */
  IMP_FUEL_LEVEL,

  // Другие

  /**
   * Счетчик
   */
  COUNTER,
  /**
   * Произвольный датчик
   */
  ARBITRARY,
  /**
   * Привязка водителя
   */
  DRIVER_SET,
  /**
   * Привязка прицепа
   */
  TRAILER_SET
}

/**
 * Получение списка всех типов датчиков
 */
export function getAllSensorTypes() {
  const result: SensorType[] = [];
  for (let i = 1; i <= 24; i++) {
    result.push(i as SensorType);
  }
  return result;
}

/**
 * Получение наименования типа датчика
 * @param type Тип датчика
 */
export function getSensorTypeName(type: SensorType): string {
  switch (type) {
    case SensorType.MILEAGE: return 'enums.sensors.type.mileage';
    case SensorType.REL_ODOMETR: return 'enums.sensors.type.odometer';
    case SensorType.IGNITION: return 'enums.sensors.type.ignition';
    case SensorType.ALERT_BUTTON: return 'enums.sensors.type.alert';
    case SensorType.PRIVATE_REGIME: return 'enums.sensors.type.private';
    case SensorType.ARBITRARY_DIGITAL: return 'enums.sensors.type.arbitrary-digital';
    case SensorType.VOLTAGE: return 'enums.sensors.type.voltage';
    case SensorType.WEIGHT: return 'enums.sensors.type.weight';
    case SensorType.ACCELEROMETER: return 'enums.sensors.type.acceleration';
    case SensorType.TEMPERATURE: return 'enums.sensors.type.temperature';
    case SensorType.TEMPERATURE_KOEF: return 'enums.sensors.type.temperature-k';
    case SensorType.ENGINE_SPEED: return 'enums.sensors.type.rpm';
    case SensorType.ENGINE_WORK: return 'enums.sensors.type.work';
    case SensorType.ABS_MH: return 'enums.sensors.type.abs-mh';
    case SensorType.REL_MH: return 'enums.sensors.type.rel-mh';
    case SensorType.IMP_FUEL_CONSUMPTION: return 'enums.sensors.type.imp-fuel-usage';
    case SensorType.ABS_FUEL_CONSUMPTION: return 'enums.sensors.type.abs-fuel-usage';
    case SensorType.INSTANTANEOUS_FUEL_CONSUMPTION: return 'enums.sensors.type.inst-fuel-usage';
    case SensorType.FUEL_LEVEL: return 'enums.sensors.type.fuel';
    case SensorType.IMP_FUEL_LEVEL: return 'enums.sensors.type.imp-fuel';
    case SensorType.COUNTER: return 'enums.sensors.type.counter';
    case SensorType.ARBITRARY: return 'enums.sensors.type.arbitrary';
    case SensorType.DRIVER_SET: return 'enums.sensors.type.driver';
    case SensorType.TRAILER_SET: return 'enums.sensors.type.trails';
    default: return 'enums.sensors.type.unknown';
  }
}
