import { OnDestroy, OnInit } from '@angular/core';

import { BgPopoverService } from './bg-popover.service';

export class BgPopoverComponent<T> implements OnInit, OnDestroy {

  constructor(protected popoverService: BgPopoverService) { }

  public fillData(data: T) {
    data = data || {} as T;
    const keys = Object.keys(data);
    for (const key of keys) {
      this[key] = data[key];
    }
  }

  public ngOnInit() {
    window.addEventListener('click', this.privateOnClick);
  }

  public ngOnDestroy() {
    window.removeEventListener('click', this.privateOnClick);
  }

  private privateOnClick = () => {
    this.popoverService.removePopover();
  }
}
