import { Pipe, PipeTransform } from '@angular/core';
import * as dateFormat from 'dateformat';

/**
 * Форматирование для дат
 */
@Pipe({
  name: 'bgdate'
})
export class BgdatePipe implements PipeTransform {
  public transform(value: any, format: string) {
    return value ? dateFormat(value, format) : '';
  }
}
