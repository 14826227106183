import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ISimpleObject } from '../../../shared/ISimpleObject';
import { Category, IYandexTransportRepeater, VehicleType } from '../../../shared/units/IYandexTransportRepeater';
import { StoreService } from '../../services/store.service';
import { UnitGroupsService } from '../../services/unit-groups.service';
import { YandexTransportRepeaterService } from '../../services/yandex-transport-repeater.service';
import { EnumListItem, getEnumList } from '../../utils/enums';

/**
 * Yandex transport repeater
 */
@Component({
  selector: 'yandex-transport-repeater',
  templateUrl: './yandex-transport-repeater.component.html'
})
export class YandexTransportRepeaterComponent implements OnInit {

  /**
   * Object id
   */
  @Input() unitId: string;

  /**
   * Object uid
   */
  @Input() uid: string;

  /**
   * Output repeater to Yandex transport
   */
  @Output() repeaterChange = new EventEmitter<IYandexTransportRepeater>();

  /**
   * Input repeater to Yandex transport
   */
  repeater: IYandexTransportRepeater;

  /**
   * List of {@link Category}
   */
  categories: Array<EnumListItem<Category>> = [];

  /**
   * Selected {@link Category}
   */
  selectedCategory: Category;

  /**
   * List of {@link VehicleType}
   */
  vehicleTypes: Array<EnumListItem<VehicleType>> = [];

  /**
   * Selected {@link VehicleType}
   */
  selectedVehicleType: VehicleType;

  /**
   * List of routes
   */
  routes: Array<ISimpleObject> = [];

  /**
   * Selected route
   */
  selectedRoute: string;

  /**
   * New repeater flag
   */
  isNew: boolean = true;

  /**
   * Instantiates {@link YandexTransportRepeaterComponent}
   * @param storeService Injects {@link StoreService}
   * @param unitGroupsService Injects {@link UnitGroupsService}
   * @param yandexTransportRepeaterService Injects {@link YandexTransportRepeaterComponent}
   */
  constructor(
    private storeService: StoreService,
    private unitGroupsService: UnitGroupsService,
    private yandexTransportRepeaterService: YandexTransportRepeaterService
  ) {
    this.categories = getEnumList<Category>(Category, 'component.yandex-transport.category-');
    this.vehicleTypes = getEnumList<VehicleType>(VehicleType, 'component.yandex-transport.vehicle-type-');
    this.routes = [{ id: null, name: 'component.yandex-transport.route-empty' }];
  }

  /**
   * Implements {@link OnInit} hook
   */
  ngOnInit(): void {
    this.yandexTransportRepeaterService.read(this.unitId).subscribe((repeater) => {
      this.isNew = !repeater;

      this.repeater = repeater || {} as IYandexTransportRepeater;

      if (!this.repeater.uuid) {
        this.repeater.uuid = this.uid;
      }

      if (!this.repeater.clid) {
        this.repeater.clid = this.storeService.user?.account?.ytClientId;
      }

      this.unitGroupsService.getYTRRoutes()
        .subscribe((routes) => {
          this.routes.push(...routes);
          const route = this.routes.find((x) => x.name === this.repeater.route)
            || this.routes[0];
          this.selectedRoute = route.id;
          this.repeater.route = this.selectedRoute === null ? undefined : route.name;
        });

      const category = this.categories.find((x) => x.id === this.repeater.category)
        || this.categories[0];
      this.selectedCategory = category.id;
      this.repeater.category = this.selectedCategory;

      const vehicleType = this.vehicleTypes.find((x) => x.id === this.repeater.vehicleType)
        || this.vehicleTypes[0];
      this.selectedVehicleType = vehicleType.id;
      this.repeater.vehicleType = this.selectedVehicleType;
    });
  }

  /**
   * Changes uuid of {@link IYandexTransportRepeater}
   */
  changeUuid() {
    this.repeaterChange.emit(this.repeater);
  }

  /**
   * Changes route of {@link IYandexTransportRepeater}
   */
  changeRoute() {
    this.repeater.route = this.selectedRoute === null ? undefined : this.routes.find((x) => x.id === this.selectedRoute)?.name;
    this.repeaterChange.emit(this.repeater);
  }

  /**
   * Changes {@link Category} of {@link IYandexTransportRepeater}
   */
  changeCategory() {
    this.repeater.category = this.selectedCategory;
    this.repeaterChange.emit(this.repeater);
  }

  /**
   * Changes {@link VehicleType} of {@link IYandexTransportRepeater}
   */
  changeVehicleType() {
    this.repeater.vehicleType = this.selectedVehicleType;
    this.repeaterChange.emit(this.repeater);
  }
}
