import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { ISortInfo } from '../../../shared/ISortInfo';
import { IListItem } from '../../classes/IListItem';
import { ModalService } from '../../services/modal.service';


/**
 * Интерфейс компонента выбора объектов мониторинга
 */
export interface ISelectItemsComponent {
  /** Список элементов */
  items: IListItem<any>[];
  /** Список уже выбранных элементов */
  selected?: IListItem<any>[];
  /** Заголовок окна */
  title: string;
  /** Признак отображения строки поиска */
  withSearch: boolean;
  /** Действие при выборе */
  selectAction?: (item: IListItem<any>) => void;
  /** Действие при отмене выбора */
  unselectAction?: (item: IListItem<any>) => void;
  /** Признак скрытия кнопки "Отмена" */
  hideCancelButton?: boolean;
  /** Тип ограничения выбора объектов */
  selection?: SelectionType;
  /** Признак сортировки по идентификатору */
  sortById?: boolean;
  /** Признак скрытия выбора всех объектов */
  hideSelectAll?: boolean;
  /** Признак сортировки перетаскиванием */
  isDraggable?: boolean;
}

/**
 * Компонент выбора каких-либо записей
 */
@Component({
  selector: 'select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss']
})
export class SelectItemsComponent
  extends DialogComponent<ISelectItemsComponent, IListItem<any>[]>
  implements ISelectItemsComponent {

  /** Параметры сортировки */
  public sort: ISortInfo = { field: null, isDescending: false };

  /** Список элементов */
  public items: IListItem<any>[];

  /** Список уже выбранных элементов */
  public selected?: IListItem<any>[];

  /** Заголовок окна */
  public title: string;

  /** Признак отображения строки поиска */
  public withSearch: boolean;

  /** Действие при выборе */
  public selectAction?: (item: IListItem<any>) => void;

  /** Действие при отмене выбора */
  public unselectAction?: (item: IListItem<any>) => void;

  /** Признак скрытия кнопки "Отмена" */
  public hideCancelButton?: boolean;

  /** Тип ограничения выбора объектов */
  public selection?: SelectionType = SelectionType.Free;

  /** Признак скрытия выбора всех объектов */
  public hideSelectAll?: boolean;

  /** Текст поиска */
  public search: string;

  /** Список выбираемых элементов */
  public selectableItems: ISelectableItem<any>[] = [];

  /** Признак сортировки по идентификатору */
  public sortById?: boolean;

  /** Признак сортировки перетаскиванием */
  public isDraggable?: boolean;

  /**
   * Конструктор
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   */
  constructor(
    private modalService: ModalService,
    dialogService: DialogService
  ) {
    super(dialogService);
  }

  /**
   * Признак возможности выбора нескольких записей
   */
  public get isMultiSelection() {
    return this.selection !== SelectionType.OnlyOne;
  }

  /**
   * Получение признака всех выбранных объектов
   */
  get allChecked() {
    return this.selectableItems.every((item) => item.checked || false);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: ISelectItemsComponent) {
    if (!data.isDraggable) {
      this.sort.field = data.sortById ? 'id' : 'name';
    }

    const selected = data?.selected?.map((item) => ({
      ...item,
      checked: true
    }));

    const unselected = data?.items?.filter((i) => !selected?.some((x) => i.id === x.id)).map((i) => ({
      ...i,
      checked: false
    }));

    this.selectableItems = [...selected || [], ...unselected || []]

    return super.fillData(data);
  }

  /**
   * Переключение выбранности элемента
   * @param item Элемент
   */
  public toggleItemChecked(item: ISelectableItem<any>) {
    item.checked = !item.checked;

    if (item.checked && this.selectAction) {
      this.selectAction(item);
    }

    if (!item.checked && this.unselectAction) {
      this.unselectAction(item);
    }

    if (this.selection !== SelectionType.OnlyOne || !item.checked) {
      return;
    }

    this.selectableItems
      .filter((i) => i.checked && i !== item)
      .forEach((i) => {
        const checked = i.checked;
        i.checked = false;

        if (checked && this.unselectAction) {
          this.unselectAction(i);
        }
      });
  }

  /**
   * Изменение выбора всех объектов
   */
  public toggleAllChecked() {
    const isAllChecked = this.allChecked;
    this.selectableItems
      .filter((item) => !!item.checked === isAllChecked)
      .forEach((item) => this.toggleItemChecked(item));
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    this.result = this.selectableItems
      .filter((item) => item.checked)
      .map((item) => ({ id: item.id, name: item.name, uid: item.uid }));

    this.close();
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = { field, isDescending: false };
    }
  }

  /**
   * Drag & drop
   * @param event
   */
  public drop(event: CdkDragDrop<string[]>) {
    if (this.isDraggable) {
      this.sort.field = 'index'
    }

    moveItemInArray(this.selectableItems, event.previousIndex, event.currentIndex);
  }
}

/**
 * Элемент списка с возможностью выбора
 */
interface ISelectableItem<T> extends IListItem<T> {
  /** Признак выбранного элемента */
  checked: boolean;
}

/**
 * Ограничения выбора элементов из списка
 */
export enum SelectionType {
  /**
   * Нет ограничений
   */
  Free,
  /**
   * Можно выбрать только один объект
   */
  OnlyOne
}
