import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { RightType, UnitRightType } from '../../../../shared/rights/RightType';
import {
  getAllMhCalcMethods,
  getAllMileageCalcMethods,
  getMhCalcMethodName,
  getMileageCalcMethodName,
  ICounter,
  MhCalcMethod,
  MileageCalcMethod
} from '../../../../shared/units/ICounter';
import { IUnit } from '../../../../shared/units/IUnit';
import { IActUser } from '../../../../shared/users';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { StoreService } from '../../../services/store.service';
import { UsersService } from '../../../services/users.service';
import { EquipmentsEditComponent } from '../../crm/equipments/edit/equipments.edit.component';
import { SelectionType, SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для отображения основной информации о ТС
 */
@Component({
  selector: 'unit-general',
  templateUrl: './unit.general.component.html',
  styleUrls: ['./unit.general.component.scss']
})
export class UnitGeneralComponent implements OnInit {

  /** Признак удаленной  */
  @Input('accountBlock') public isAccountBlock: boolean;
  /** Признак режима добавления */
  @Input() public isAddRegime: boolean;
  /** Список доступных для выбора создателей */
  @Input() public creators: IActUser[];
  /** Генератор события изменения списка создателей */
  @Output() public creatorsChange = new EventEmitter<IActUser[]>();
  /** Информация о ТС */
  public unit: IUnit;
  /** Список доступных методов расчета пробега */
  public mileageCalcMethods: IListItem<MileageCalcMethod>[] = [];
  /** Список доступных методов расчета моточасов */
  public mhCalcMethods: IListItem<MhCalcMethod>[] = [];
  /** Признак того, что страницу просматривает диллер */
  public isDillerView: boolean;
  /** Список трекеров для выбора в ТС */
  private trackers: IListItem<string>[];
  /** Список доп. оборудования для выбора в ТС */
  private optionals: IListItem<string>[];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Севрис отображения процессе загрузки
   * @param modalService Сервис модальных окон
   * @param crudService Сервис для работы с CRUD
   * @param usersService Сервис для работы с пользователями
   * @param store Сервис для хранения данных
   */
  constructor(
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private crudService: CRUDService,
    private usersService: UsersService,
    private store: StoreService
  ) {
    this.isDillerView = this.store?.user?.isDealer;

    this.mileageCalcMethods = getAllMileageCalcMethods()
      .map((id) => ({ id, name: getMileageCalcMethodName(id) }));

    this.mhCalcMethods = getAllMhCalcMethods()
      .map((id) => ({ id, name: getMhCalcMethodName(id) }));
  }

  /** Установка значения ТС */
  @Input('unit')
  public set setUnit(value: IUnit) {
    this.unit = value;
    this.initCounters();
  }

  /** Признак того, что пользователь может переименовывать объект мониторинга */
  get isCanRename() {
    return this.isCan(RightType.RENAME);
  }

  /** Признак того, что пользователь может видеть настройки подключения объекта мониторинга */
  get isCanViewConnectionSetting() {
    return this.isCan(UnitRightType.VIEW_CONNECTION_SETTING);
  }

  /** Признак того, что пользователь может изменять счетчики */
  get isCanChangeCounters() {
    return this.isCan(UnitRightType.CHANGE_COUNTERS);
  }

  /**
   * Обработка изменения выбранного создателя
   */
  public onCreatorChange() {
    const creator = this.creators.find((user) => user.id === this.unit.creatorId);
    this.unit.creator = creator.name;
    this.unit.accountId = creator.accountId;
    this.unit.account = creator.account;
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType | UnitRightType) {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.unit.rights & rightType);
  }

  /**
   * Отображение детальной информации по оборузованию
   * @param equipment Оборудование
   * @param isTracker Признак трекера
   */
  public showEquipmentDetailHandler(equipment: IListItem<string>, isTracker: boolean) {
    if (!equipment) {
      return;
    }

    const data = {
      equipmentId: equipment.id,
      isTerminal: isTracker
    };

    this.dialogService.addDialog(EquipmentsEditComponent, data).subscribe();
  }

  /**
   * Клик по кнопке выбора оборудования в ТС
   * @param isTracker Признак трекера
   */
  public selectEquipmentsHandler(isTracker: boolean) {
    const equipments = isTracker ? this.trackers : this.optionals;

    if (equipments) {
      // Выбираем из загруженного списка
      this.selectFromEquipments(isTracker);
      return;
    }

    const query = isTracker ? { terminals: `${true}` } : null;
    this.loadingService
      .wrap(this.crudService.getListLight(CRUDEntityType.EQUIPMENT, query), true)
      .subscribe((result) => {
        if (isTracker) {
          this.trackers = result;
        } else {
          this.optionals = result;
        }

        // Выбираем из загруженного списка
        this.selectFromEquipments(isTracker);
      });
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (!this.creators) {
      this.loadCreators();
    }

    if (this.isAddRegime && !this.unit.optionals) {
      this.unit.optionals = [];
    }
  }

  /**
   * Обработка изменения значения счетчика пользователем
   */
  public counterChanged<T>(counter: ICounter<T>) {
    if (!counter.isChangedByUser) {
      counter.isChangedByUser = true;
    }
  }

  /**
   * Выбор из списка оборудования
   * @param isTracker Признак трекера
   */
  private selectFromEquipments(isTracker: boolean) {
    const data = {
      items: isTracker ? this.trackers : this.optionals,
      selected: isTracker ? (this.unit.tracker ? [this.unit.tracker] : []) : this.unit.optionals,
      title: `component.unit.general.${isTracker ? 'tracker' : 'equipment'}-selection`,
      withSearch: true,
      selection: isTracker ? SelectionType.OnlyOne : SelectionType.Free
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        let equipments = [];
        if (result.length) {
          equipments = result.map((item) => ({ ...item }));
        }

        if (isTracker) {
          this.unit.tracker = equipments.length ? equipments[0] : null;
        } else {
          this.unit.optionals = equipments;
        }
      });
  }

  /**
   * Ининциализация счетчиков
   */
  private initCounters() {
    if (!this.unit.mileageCounter) {
      this.unit.mileageCounter = {
        method: 1,
        value: 0,
        auto: false,
        isChangedByUser: false
      };
    }

    if (!this.unit.mhCounter) {
      this.unit.mhCounter = {
        method: 1,
        value: 0,
        auto: false,
        isChangedByUser: false
      };
    }
  }

  /**
   * Загрузка списка создателей
   */
  private loadCreators() {
    if (this.unit.id) {
      this.creators = [
        {
          id: this.unit.creatorId,
          name: this.unit.creator,
          account: this.unit.account,
          accountId: this.unit.accountId
        }
      ];
      this.creatorsChange.emit(this.creators);

    } else {
      this.loadingService.wrap(this.usersService.getActUsers(), true)
        .subscribe((users) => {
          this.creators = users;
          const currentUser = this.creators.find((user) => user.current);
          this.unit.creatorId = currentUser.id;
          this.unit.creator = currentUser.name;
          this.unit.accountId = currentUser.accountId;
          this.unit.account = currentUser.account;
          this.creatorsChange.emit(this.creators);
        });
    }
  }

  /**
   * Обработчик на изменение uid
   * @param value
   */
  public uidChange(value: string) {
    this.unit.uid = value
  }
}
