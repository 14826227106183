import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal/dist/dialog.service';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { ITaskListItem } from '../../../shared/tasks/ITaskListItem';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { ModalResult } from '../modal/modal.component';
import { TaskComponent } from '../task/task.component';

/**
 * Компонент для отображения списка заданий
 */
@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html'
})
export class TasksComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: ITaskListItem[];
  /**
   * Выбранный элемент
   */
  public selected: ITaskListItem;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;

  /**
   * Конструктор
   * @param loadingService Сервис для отображения процесса загрузки
   * @param crudService Сервис работы с ДИУП сущностей
   * @param dialogService Сервис работы с диалоговыми окнами
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    private loadingService: LoadingService,
    private crudService: CRUDService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'account', name: 'ui.account' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление задания
   */
  public addItem() {
    this.dialogService.addDialog(TaskComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранной записи
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = { taskId: id, copy: true };
    this.dialogService.addDialog(TaskComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной записи
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { taskId: id };
    this.dialogService.addDialog(TaskComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранной записи
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const err = this.translator.instant('component.tasks.error', { val: name });
    this.modalService.showQuestion(err)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.TASK), true)))
      .subscribe(() => {
        this.error = null;
        this.loadData();
      });
  }

  /**
   * Загрузка данных с сервера
   */
  protected loadData = () => {
    this.error = null;
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.TASK))
      .subscribe(
        (items) => {
          this.items = items;
        },
        (error) => this.error = error);
  }
}
