import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { DocType, GeneratedDoc } from '../../../../../shared/crm/docs';

/**
 * превью документа для отправки его на печать
 */
export interface IPreviewDoc {
  /**
   * Документ
   */
  doc: GeneratedDoc;

  /**
   * Тип документа
   */
  type: DocType;
}

/**
 * Компонент для отображения превью документа и отправки его на печать
 */
@Component({
    selector: 'docs-print-preview',
    templateUrl: './docs.print-preview.component.html',
    styleUrls: ['./docs.print-preview.component.scss']
})
export class DocsPrintPreviewComponent extends DialogComponent<IPreviewDoc, void> {

  /** Ссылка на элемент, в котором содержится превью для печати */
  @ViewChild('printContainer', {static: false}) public printContainer: ElementRef;

  /** Документ */
  public doc: GeneratedDoc;

  /** Тип документа */
  public type: DocType;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  /**
   * Отправка на печать
   */
  public print() {
    const content = this.printContainer.nativeElement.innerHTML;
    const popupWindow = window.open('', '_blank', 'width=1000,height=800');
    popupWindow.document.open();
    popupWindow.document.write(
      `<html>
        <body onload="window.print()">
          ${content}
        </body>
      </html>`
    );
    popupWindow.document.close();
  }
}
