/** Типы протоколов */
export enum ProtocolType {
  /** Wialon IPS */
  WIALON_IPS = 1,
  /** EGTS */
  EGTS,
  /** ADM */
  ADM,
  /** FLEX */
  FLEX,
  /** Wialon Combine */
  WIALON_COMBINE,
  /** Arnavi */
  ARNAVI,
  /** Teltonika (FMXXXX) */
  TELTONIKA_FMXXXX,
  /** Teltonika (FMXXXX Ext) */
  TELTONIKA_FMXXXX_EXT,
  /** Teltonika (GH3000) */
  TELTONIKA_GH3000,
  /** SAT LITE 2+ */
  SAT_LITE_2,
  /** cGuard */
  CGUARD,
  /** AM120 */
  AM120,
  /** GalileoSky */
  GALILEOSKY,
  /** RS906 */
  RS906,
  /** SAT LITE */
  SAT_LITE,
  /** ST270 */
  ST270,
  /** FMB640 */
  FMB640,
  /** FMB130 */
  FMB130,
  /** VOYAGER */
  VOYAGER,
  /** ADM50 */
  ADM50,
  /** ADM333BLE */
  ADM333BLE,
  /** ADM700 */
  ADM700
}

/**
 * Получение списка всех названий типов протоколов
 */
export function getAllProtocolTypes(): ProtocolType[] {
  return [
    ProtocolType.WIALON_IPS,
    ProtocolType.EGTS,
    ProtocolType.ADM,
    ProtocolType.FLEX,
    ProtocolType.WIALON_COMBINE,
    ProtocolType.ARNAVI,
    ProtocolType.TELTONIKA_FMXXXX,
    ProtocolType.TELTONIKA_FMXXXX_EXT,
    ProtocolType.TELTONIKA_GH3000,
    ProtocolType.SAT_LITE_2,
    ProtocolType.CGUARD,
    ProtocolType.AM120,
    ProtocolType.GALILEOSKY,
    ProtocolType.RS906,
    ProtocolType.SAT_LITE,
    ProtocolType.ST270,
    ProtocolType.FMB640,
    ProtocolType.FMB130,
    ProtocolType.VOYAGER,
    ProtocolType.ADM50,
    ProtocolType.ADM333BLE,
    ProtocolType.ADM700
  ];
}

/**
 * Получение наименования типа протокола по типу элемента спарвочника
 * @param type Тип элемента справочника
 */
export function getProtocolTypeName(type: ProtocolType): string {
  switch (type) {
    case ProtocolType.WIALON_IPS:
      return 'enums.crm.protocol-type.WIALON_IPS';
    case ProtocolType.EGTS:
      return 'enums.crm.protocol-type.EGTS';
    case ProtocolType.ADM:
      return 'enums.crm.protocol-type.ADM';
    case ProtocolType.FLEX:
      return 'enums.crm.protocol-type.FLEX';
    case ProtocolType.WIALON_COMBINE:
      return 'enums.crm.protocol-type.WIALON_COMBINE';
    case ProtocolType.ARNAVI:
      return 'enums.crm.protocol-type.ARNAVI';
    case ProtocolType.TELTONIKA_FMXXXX:
      return 'enums.crm.protocol-type.TELTONIKA_FMXXXX';
    case ProtocolType.TELTONIKA_FMXXXX_EXT:
      return 'enums.crm.protocol-type.TELTONIKA_FMXXXX_EXT';
    case ProtocolType.TELTONIKA_GH3000:
      return 'enums.crm.protocol-type.TELTONIKA_GH3000';
    case ProtocolType.SAT_LITE_2:
      return 'enums.crm.protocol-type.SAT_LITE_2';
    case ProtocolType.CGUARD:
      return 'enums.crm.protocol-type.CGUARD';
    case ProtocolType.AM120:
      return 'enums.crm.protocol-type.AM120';
    case ProtocolType.GALILEOSKY:
      return 'enums.crm.protocol-type.GALILEOSKY';
    case ProtocolType.RS906:
      return 'enums.crm.protocol-type.RS906';
    case ProtocolType.SAT_LITE:
      return 'enums.crm.protocol-type.SAT_LITE';
    case ProtocolType.ST270:
      return 'enums.crm.protocol-type.ST270';
    case ProtocolType.FMB640:
      return 'enums.crm.protocol-type.FMB640';
    case ProtocolType.FMB130:
      return 'enums.crm.protocol-type.FMB130';
    case ProtocolType.VOYAGER:
      return 'enums.crm.protocol-type.VOYAGER';
    case ProtocolType.ADM50:
      return 'enums.crm.protocol-type.ADM50';
    case ProtocolType.ADM333BLE:
      return 'enums.crm.protocol-type.ADM333BLE';
    case ProtocolType.ADM700:
      return 'enums.crm.protocol-type.ADM700';
    default:
      return 'enums.crm.protocol-type.unknown';
  }
}
