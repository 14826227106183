/**
 * Типы операций списания/поступления по учетным записям
 */
export enum OperationType {
  /** Списание (начисление) */
  DEBIT = 1,
  /** Поступление (оплата) */
  CREDIT
}

/**
 * Получение списка всех типов операций
 */
export function getAllOperationTypes(): OperationType[] {
  return [OperationType.DEBIT, OperationType.CREDIT];
}

/**
 * Получение наименования типа операций
 * @param type Тип операций
 */
export function getOperationTypeName(type: OperationType): string {
  switch (type) {
    case OperationType.DEBIT: return 'enums.crm.operations.type.debit';
    case OperationType.CREDIT: return 'enums.crm.operations.type.credit';
    default: return 'enums.crm.operations.type.unknown';
  }
}
