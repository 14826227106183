import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ToastService } from '../services/toast.service';

/**
 * Перехватчик ответа
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  /**
   * Конструктор
   * @param router Маршрутизатор
   * @param toastService Сервис всплывающих сообщений
   * @param translateService
   */
  constructor(
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  /**
   * Признак режима наблюдения
   */
  public get isObserve() {
    return !!localStorage.getItem('observable');
  }

  /**
   * Перехватчик
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(map(this.handleResponse), catchError(this.handleError));
  }

  /**
   * Обработка успешного ответа
   * @param e HTTP событие (ответ от сервера)
   */
  private handleResponse = (e) => {
    if (e instanceof HttpResponse && e.body?.hasOwnProperty('success')) {
      if (e.body?.success) {
        this.toastService.connect();
        return e.clone({ body: e.body?.data });
      }

      throw new Error(e.body.data);
    }

    return e;
  }

  /**
   * Обработка ответа с ошибкой
   * @param e HTTP Событие c ошибкой или ошибка
   */
  private handleError = (e: HttpEvent<any> | Error) => {
    if (e instanceof HttpErrorResponse) {
      if (e.error instanceof Blob) {
        return this.handleBlob(e.error);
      }

      console.error('Ошибка: ', e);

      switch (e.status) {
        case undefined:
        case null:
        case 0:
          this.toastService.disconnect();
          return EMPTY;

        case 401:
          if (this.isObserve) {
            localStorage.removeItem('observer');
            localStorage.removeItem('observable');
            console.error('Ошибка: ', e);
            const message = this.translateService.instant(e.error?.message)
            this.toastService.error(message);
            this.router.navigate([''], { replaceUrl: true }).then();
            return EMPTY;
          }

          this.router.navigate(['logout'], { replaceUrl: true }).then();
          return EMPTY;

        case 403:
          this.router.navigate(['client'], { replaceUrl: true }).then();
          return EMPTY;

        case 404:
          this.toastService.error('errors.not-found');
          return EMPTY;

        case 500:
          return throwError(null);

        case 502:
          return EMPTY;

        default:
          if (e.error === e.statusText) {
            return throwError(null);
          }

          this.toastService.error(e.error);
          return EMPTY;
      }

    } else if (e instanceof Error) {
      console.error('Ошибка: ', e.message);
      this.toastService.error(e.message);
      return EMPTY;
    }

    console.error('Непредвиденная ошибка: ', e);
    return throwError('Непредвиденная ошибка');
  };

  /**
   * Обработка ошибки с Blob
   * @param error Ошибка
   */
  private handleBlob(error: Blob) {
    const subject = new Subject<Blob>();
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result as string);
        if (json.message) {
          subject.error(json.message);
        }

        if (json.data) {
          subject.error(json.data);
        }

        subject.error(json);

      } catch (e) {
        subject.error(reader.result);
      }
    };

    reader.onerror = () => subject.error(reader.result);

    reader.readAsText(error);
    return subject.asObservable();
  }
}
