import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { BlockableHistoryEntity, IBlockPeriod } from '../../../../../shared/crm/hist';
import { ISimpleObject } from '../../../../../shared/ISimpleObject';
import { HistoryService } from '../../../../services/history.service';
import { LoadingService } from '../../../../services/loading.service';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с информацией по сущности
 */
export interface IEntityComponent {

  /** Идентификатор сущности */
  entityId?: string;

  /** Тип сущности */
  type: BlockableHistoryEntity;
}

/**
 * Компонент для разблокировки сущностей
 */
@Component({
  selector: 'unblock-entity',
  templateUrl: './diller.unblock.component.html',
  styleUrls: ['./diller.unblock.component.scss']
})
export class UnblockEntityComponent extends DialogComponent<IEntityComponent, boolean> {

  /** Выбранный тип сущности для разблокировки */
  public selectedEntityType: BlockableHistoryEntity;

  /** Идентификатор выбранной сущности для разблокировки */
  public selectedEntityId: string;

  /** Список сущностей доступных для выбора */
  public entities: ISimpleObject[];

  /** Периоды блокировок */
  public periods: IBlockPeriod[];

  /** Выбранный период блокировки */
  public selectedPeriod: IBlockPeriod;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param historyService Сервис работы с историей
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private historyService: HistoryService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: IEntityComponent) {
    this.selectedEntityType = data.type;
    this.selectedEntityId = data.entityId;
    this.periods = null;

    this.loadingService
      .wrap(this.historyService.getBlocking(
        this.selectedEntityId,
        this.selectedEntityType,
        {onlyCompleted: true}
        ),
        true).subscribe((blocks) => {
      this.periods = blocks;
    });

    return super.fillData(data);
  }

  /**
   * Обработка выбора периода блокировки
   * @param period Период блокировки
   */
  public changePeriod(period: IBlockPeriod) {
    this.selectedPeriod = period;
  }

  /**
   * Признак возможности разблокировки
   */
  public isCanUnblock(): boolean {
    return !!this.selectedPeriod;
  }

  /**
   * Подтверждение разблокировки
   */
  public confirm() {
    if (!this.isCanUnblock()) { return; }

    this.loadingService
      .wrap(
        this.historyService.unblock(this.selectedEntityId, this.selectedEntityType, this.selectedPeriod),
        true
      )
      .subscribe(() => {
        this.close();
      });
  }
}
