import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IAccountExport } from '../../../../shared/accounts/IAccountExportSettings';
import { IAccountImportSettings } from '../../../../shared/accounts/IAccountImportSettings';
import { IAccountListItem } from '../../../../shared/accounts/IAccountListItem';
import { getAllImportTypes, getImportTypeName, ImportType } from '../../../../shared/import/ImportType';
import { AccountRightType, RightType } from '../../../../shared/rights/RightType';
import { IImportBlockInfo } from '../../../classes/IImportBlockInfo';
import { IListItem } from '../../../classes/IListItem';
import { ExportImportService } from '../../../services/export-import.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';

/**
 * Интерфейс компонента для импорта учетных записей
 */
interface IImportAccountsComponent {
  /** Список учетных записей, в которые можно импортировать */
  accounts: IAccountListItem[];
  /** Импортируемые данные */
  importAccount?: IAccountExport;
}

/**
 * Компонент для импорта в учетные записи
 */
@Component({
  selector: 'import-accounts',
  templateUrl: './import.accounts.component.html'
})
export class ImportAccountsComponent
extends DialogComponent<IImportAccountsComponent, boolean>
implements IImportAccountsComponent {

  /** Список учетных записей, в которые можно импортировать */
  public accounts: IAccountListItem[];

  /** Импортируемые данные */
  public importAccount?: IAccountExport;

  /** Признак того, что необходимо выбирать файл для импорта */
  public isNeedFileSelect = false;

  /** Список учетных записей, выбранных для импорта */
  public checkedAccounts: IAccountListItem[] = [];

  /** Настройки импорта */
  public settings: IAccountImportSettings;

  /** Строка поиска по наименованиям учетных записей */
  public search: string;

  /** Список типов импорта */
  public importTypes: IListItem<ImportType>[] = [];

  /** Иформация по разделам, которые возможно импортировать */
  public importBlockInfos: IImportBlockInfo<IAccountListItem>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param exportImportService Сервис экспорта/импорта
   * @param loadingService Сервис отображения процесса загрузки
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private exportImportService: ExportImportService,
    private loadingService: LoadingService,
    private translator: TranslateService
  ) {
    super(dialogService);

    this.settings = { type: ImportType.REPLACE, accountIds: [] } as IAccountImportSettings;
    this.importTypes = getAllImportTypes().map((id) => ({ id, name: getImportTypeName(id) }));
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IImportAccountsComponent) {
    if (data.importAccount) {
      this.importAccount = data.importAccount;
      this.afterSelectImportAccount();
    } else {
      this.isNeedFileSelect = true;
    }
    return super.fillData(data);
  }

  /**
   * Обработка события выбора файла
   * @param e Аргумент события
   */
  public onFileSelect(e: Event) {
    if (!e.target || !(e.target as any).files || !(e.target as any).files.length) {
      // Если не выбрали никакого файла
      this.importAccount = null;
      this.importBlockInfos = [];
    } else {
      // Если выбрали файл.
      this.loadingService.startLoading();
      const file = (e.target as any).files[0] as File;
      const reader = new FileReader();

      reader.onloadend = () => {
        this.loadingService.stopLoading();
        if (reader.result) {
          try {
            this.importAccount = JSON.parse(reader.result as string);
            this.afterSelectImportAccount();
          } catch (error) {
            this.modalService.showError('component.import.error');
          }
        } else {
          this.modalService.showError('component.import.error');
        }
      };

      reader.readAsText(file);
    }
  }

  /**
   * Переключение выбранности учетной записи
   * @param account Учетная запись
   */
  public toggleChecked(account: IAccountListItem) {
    const checkedIndex = this.checkedAccounts.indexOf(account);
    if (checkedIndex !== -1) {
      this.checkedAccounts.splice(checkedIndex, 1);
      this.accounts.push(account);
    } else {
      const index = this.accounts.indexOf(account);
      if (index !== -1) {
        this.accounts.splice(index, 1);
        this.checkedAccounts.push(account);
      }
      this.updateImportElements();
    }
  }

  /**
   * Получение признака возможности продолжения импорта
   */
  public get isCanContinue() {
    return this.importAccount && this.checkedAccounts.length &&
      this.importBlockInfos.some((b) => b.checked);
  }

  /**
   * Продолжение импорта
   */
  public continue() {
    if (!this.isCanContinue) { return; }

    this.loadingService.startLoading();
    this.settings.importAccount = this.importAccount;
    this.settings.accountIds = this.checkedAccounts.map(({ id }) => id);
    for (const block of this.importBlockInfos) {
      this.settings[block.field] = !!(block.checked && block.haveRight(this.checkedAccounts));
    }

    this.exportImportService.importAccounts(
      this.settings
    ).subscribe(
      (result) => {
        this.loadingService.stopLoading();
        if (result.length !== this.checkedAccounts.length) {
          const notImported: IAccountListItem[] = [];
          for (const account of this.checkedAccounts) {
            if (!result.includes(account.id)) {
              notImported.push(account);
            }
          }
          this.translator.get('component.import.accounts.error').subscribe((x) => {
            const errorMessage = x['component.import.accounts.error']
              + `: ${ notImported.map((u) => u.name).join('; ') }`;
            this.modalService.showError(errorMessage);
          });
        } else {
          this.result = true;
          this.close();
        }
      },
      (error) => {
        this.loadingService.stopLoading();
        this.modalService.showError(error);
      }
    );
  }

  /**
   * Обновление настроек импорта после изменения списка учетных записей,
   * в которые будет выполняться импорт
   */
  private updateImportElements() {
    for (const blockInfo of this.importBlockInfos) {
      if (blockInfo.checked && !blockInfo.haveRight(this.checkedAccounts)) {
        blockInfo.checked = false;
      }
    }
  }

  /**
   * Обработка после выбора импортируемой учетной записи
   */
  private afterSelectImportAccount() {
    this.fillImportBlockInfos();
    const tmpBlockInfos: IImportBlockInfo<IAccountListItem>[] = [];
    for (const blockInfo of this.importBlockInfos) {
      if (this.importAccount[blockInfo.field]) {
        tmpBlockInfos.push(blockInfo);
      }
    }
    this.importBlockInfos = tmpBlockInfos;
  }

  /**
   * Заполнение информации по разделам импорта
   */
  private fillImportBlockInfos() {
    this.importBlockInfos = [
      {
        field: 'geozones',
        label: 'component.import.accounts.geo',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_GEOZONES)
      }, {
        field: 'tasks',
        label: 'component.import.accounts.tasks',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_TASKS)
      }, {
        field: 'notifications',
        label: 'component.import.accounts.notifications',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_NOTIFICATIONS)
      }, {
        field: 'reportTemplates',
        label: 'component.import.accounts.report-templates',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_REPORT_TEMPLATES)
      }, {
        field: 'drivers',
        label: 'component.import.accounts.drivers',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_DRIVERS)
      }, {
        field: 'trailers',
        label: 'component.import.accounts.trails',
        haveRight: (accounts) => this.checkRights(accounts, AccountRightType.CHANGE_TRAILERS)
      }
    ];
  }

  /**
   * Проверка наличия права доступа
   * @param accounts Список выбранных в импорте учетных записей
   * @param right Право доступа
   */
  private checkRights(accounts: IAccountListItem[], right: RightType | AccountRightType) {
    let result = right;
    for (const account of accounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }
    return result;
  }
}
