import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { DialogService } from 'ng2-bootstrap-modal';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { forkJoin } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { IGeozoneList } from '../../../shared/geozones/IGeozoneList';
import { IGeozoneListGroup } from '../../../shared/geozones/IGeozoneListGroup';
import { IListGeozoneOrGroup } from '../../../shared/geozones/IListGeozoneOrGroup';
import { IServerResponse } from '../../../shared/IServerResponse';
import { ISortInfo } from '../../../shared/ISortInfo';
import { AccountRightType } from '../../../shared/rights/RightType';
import { IClientGeozone } from '../../classes/IClientGeozone';
import { IListItem } from '../../classes/IListItem';
import { BgAuthService } from '../../modules/bg-auth/services/bg-auth.service';
import { BgPopoverService } from '../../modules/bg-popover';
import { AccountsService } from '../../services/accounts.service';
import { ConfigService } from '../../services/config.service';
import { GeozoneGroupService } from '../../services/geozone-group.service';
import { GeozonesService } from '../../services/geozones.service';
import { LoadingService } from '../../services/loading.service';
import { MapService } from '../../services/map.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { RoutesService } from '../../services/routes.service';
import { StoreService } from '../../services/store.service';
import { TrackingService } from '../../services/tracking.service';
import { compare, localeSort } from '../../utils/sort';
import { ModalResult } from '../modal/modal.component';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Компонент для геозон
 */
@Component({
  selector: 'app-geozones',
  templateUrl: './geozones.component.html',
  styleUrls: ['./geozones.component.scss']
})
export class GeozonesComponent implements OnInit, OnDestroy {

  /** Строка поиска */
  public search: string;

  /** Признак отображения строки поиска */
  public showSearch: boolean = false;

  /** Признак отображения групп */
  public showGeozoneGroups: boolean = true;

  /** Сортировка */
  public sort: ISortInfo;

  /** Список учетных записей, геозоны которых может видеть пользователь */
  public accounts: IListItem<string>[] = [];

  /** Загрузчик файлов */
  public uploader: FileUploader;

  /** Геозоны */
  public geozones: IGeozoneList[];

  /** Группы геозон */
  public geozoneGroups: IGeozoneListGroup[];

  /** Смапленные геозоны на группы */
  public geozonesList: IListGeozoneOrGroup[] = []

  /** Инпут с аплоадером геозон */
  @ViewChild('geozoneUploader', { static: false }) geozoneUploader: ElementRef;

  /**
   * Конструктор
   * @param store Сервис для хранения данных мониторинга
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис работы с модальными окнами
   * @param popoverService Сервис всплывающих окон
   * @param accountsService Сервис работы с учетными записями
   * @param loadingService Сервис для отображения процесса загрузки
   * @param geozonesService Сервис для работы с геозонами
   * @param geozoneGroupService
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param authService
   * @param configService
   * @param mapService
   * @param trackingService
   * @param routesService
   * @param route
   * @param router
   */
  constructor(
    public store: StoreService,
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private popoverService: BgPopoverService,
    private accountsService: AccountsService,
    private loadingService: LoadingService,
    private geozonesService: GeozonesService,
    private geozoneGroupService: GeozoneGroupService,
    private dialogService: DialogService,
    private translator: TranslateService,
    private authService: BgAuthService,
    private configService: ConfigService,
    private mapService: MapService,
    private trackingService: TrackingService,
    private routesService: RoutesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sort = { field: 'name', isDescending: false };

    // Получаем список учетных записей,
    // по которым пользователь может видеть геозоны
    this.accountsService.getWithRight(AccountRightType.VIEW_GEOZONES)
      .subscribe((accounts) => this.accounts = accounts?.sort(localeSort));

    let headers = []
    if (localStorage.getItem('observable')) {
      headers = [{ name: 'X-Observable', value: localStorage.getItem('observable') }]
    }

    // Uploader
    this.uploader = new FileUploader({
      url: `${this.configService.url}/geozones/xlsx-import`,
      autoUpload: false,
      authToken: `Bearer ${authService.token}`,
      method: 'post',
      headers
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

    this.uploader.onSuccessItem = this.onSuccessUploadItem;
    this.uploader.onErrorItem = this.onErrorUploadItem;
  }

  /**
   * Инициализация компонента
   */
  ngOnInit(): void {
    this.init();
  }

  /**
   * Инициализация компонента
   */
  private init() {
    forkJoin({
      geozones: this.geozonesService.getAllForList(),
      groups: this.geozoneGroupService.getAllForList(),
      settings: this.trackingService.getOnlyTrackingSettings()
    }).subscribe(({ geozones, groups, settings }) => {
      this.showGeozoneGroups = settings.showGeozoneGroups
      this.geozones = geozones;

      // Предварительно обрабатываем группы
      groups.push({ id: 'services.monitoring.out-of-group', name: 'services.monitoring.out-of-group', geozones: [] })
      this.geozoneGroups = groups.map((group) => ({ ...group, isOpen: true }));

      // мапим localStorage
      const hideGeozoneGroup = localStorage.getItem('hideGeozoneGroup');
      if (hideGeozoneGroup) {
        const ps: string[] = JSON.parse(hideGeozoneGroup)
        this.geozoneGroups.map((g) => g.isOpen = !ps?.some((id) => id === g.id))
      }

      this.mappingList()
    })

    // перезагружаем данные с сервера
    this.mapService.loadOrReloadGeozonesSubject.next()
  }

  /**
   * Реализация плоского списка для групп геозон
   */
  public mappingList(): void {

    let flatList: IListGeozoneOrGroup[] = []

    // Без групп
    if (!this.showGeozoneGroups) {
      flatList = this.geozones
        ?.sort((a, b) => compare(a[this.sort.field], b[this.sort.field], this.sort.isDescending))
        ?.filter((g) => g.name?.toLowerCase().includes(this.search?.toLowerCase() || ''))
      this.geozonesList = [...flatList]
      return;
    }

    // Мапим геозоны на группу
    let geozonesTree: IListGeozoneOrGroup[] = this.geozoneGroups
      .map((g) => ({ ...g, isGroup: true, geozones: [] }))

    // Добавляем геозоны в список
    this.geozones
      ?.sort((a, b) => compare(a[this.sort.field], b[this.sort.field], this.sort.isDescending))
      ?.forEach((g) => {
        let isInGroup = false;
        this.geozoneGroups.forEach((gg) => {
          const geozones: string[] = gg.geozones;
          if (geozones.includes(g.id)) {
            geozonesTree.find((ggl) => ggl.id === gg.id)?.geozones?.push(g);
            isInGroup = true;
          }
        })
        if (!isInGroup) {
          this.geozoneGroups.find((gg) => gg.id === 'services.monitoring.out-of-group')?.geozones?.push(g.id)
          geozonesTree.find((ggl) => ggl.id === 'services.monitoring.out-of-group')?.geozones?.push(g);
        }
      })

    // Проставляем чекбокс на группы
    this.geozoneGroups.forEach((gr) => {
      geozonesTree.find((i) => i.id === gr.id).checked = this.groupChecked(gr)

      if (gr.name === 'services.monitoring.out-of-group' && !gr.geozones.length) {
        geozonesTree = geozonesTree.filter((grr) => grr.name !== 'services.monitoring.out-of-group')
      }
    })

    geozonesTree.forEach((group) => {
      flatList.push({ ...group, geozones: undefined })
      if (group.isOpen) flatList.push(...group.geozones)
    })

    // Применяем поиск
    flatList = flatList.filter((g) => g.isGroup || g.name?.toLowerCase().includes(this.search?.toLowerCase() || ''))

    this.geozonesList = [...flatList]
  }

  /**
   * Чёкнутость группы
   * @param group Группа
   */
  private groupChecked(group: IGeozoneListGroup) {
    const geozones = this.geozones.filter((g) => group.geozones.includes(g.id))
    return geozones.length && geozones.every((g) => g.checked);
  }

  /**
   * Тоглик геозон и груп
   * @param geo Геозона
   */
  public checkedGeozone(geo: IListGeozoneOrGroup): void {
    const checked = !geo.checked
    const geozoneIdsWithAdd: string[] = []
    const geozoneIdsWithDelete: string[] = []

    if (geo.isGroup) {
      const group = this.geozoneGroups.find((g) => g.id === geo.id);
      group.geozones.forEach((geozoneId) => {
        const geozone = this.geozones.find((gg) => gg.id === geozoneId);
        if (geozone) {
          geozone.checked = checked;

          (geozone.checked && geozone.id)
            ? geozoneIdsWithAdd.push(geozone.id)
            : geozoneIdsWithDelete.push(geozone.id)
        }
      })
      group.checked = this.groupChecked(group)
    } else {
      const geozone = this.geozones.find((g) => g.id === geo.id);
      if (geozone) {
        geozone.checked = checked;

        (geozone.checked && geozone.id)
          ? geozoneIdsWithAdd.push(geozone.id)
          : geozoneIdsWithDelete.push(geozone.id)

        this.geozoneGroups
          .filter((gr) => gr.geozones?.includes(geozone.id))
          .forEach((i) => i.checked = this.groupChecked(i))
      }
    }

    // Если удаляем геозоны с карты
    if (geozoneIdsWithDelete.length) {
      this.trackingService.deleteTrackingGeozones(this.store?.sessionId, geozoneIdsWithDelete)
        .subscribe(() => this.mapService.hideGeozonesSubject.next(geozoneIdsWithDelete))
    }

    // Если добавляем геозоны на карты
    if (geozoneIdsWithAdd.length) {
      this.trackingService.addTrackingGeozones(this.store?.sessionId, geozoneIdsWithAdd)
        .subscribe((g) => this.mapService.showGeozonesSubject.next(g))
    }
    this.mappingList()
  }

  /**
   * Получение класса для переключателя отображения
   * детальной информации по группе геозон
   * @param group Группа геозон
   */
  public getGroupDetailsToggleClass(group: IListGeozoneOrGroup) {
    return `fa-${group.isOpen ? 'minus' : 'plus'}-square-o`;
  }

  /**
   * Тоглик развернуть / свернуть группу
   * @param group
   */
  public toggleGroup(group: IListGeozoneOrGroup) {
    const grp = this.geozoneGroups.find((gr) => group.id === gr.id)
    if (grp) {
      grp.isOpen = !grp.isOpen;
    }
    this.mappingList()

    const hideGeozoneGroup: string[] = this.geozoneGroups?.filter((g) => !g.isOpen)?.map((g) => g.id)
    localStorage.setItem('hideGeozoneGroup', JSON.stringify(hideGeozoneGroup))
  }

  /**
   * Перемещение к геозоне
   * @param geozone Геозона
   */
  public fitGeozone(geozone: IListGeozoneOrGroup) {
    if (geozone.isGroup === true) return
    this.monitoringService.fitGeozone(geozone.id);
  }

  /**
   * Изменение геозоны
   * @param geozoneId
   */
  public updateGeozone(geozoneId: string) {
    this.router.navigate([`./${geozoneId}`], { relativeTo: this.route }).then();
  }

  /**
   * Удаление геозоны
   * @param geozone
   */
  public deleteGeozone(geozone: IListGeozoneOrGroup) {
    const confirm = this.translator.instant('component.geo.confirm', { val: geozone.name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.geozonesService.deleteGeozone(geozone.id), true)))
      .subscribe((ids) => {
        this.routesService.deleteRelatedGeozone(ids)

        // Скрываем геозону с карты
        this.mapService.hideGeozonesSubject.next(ids)
        // Удаляем из объекта геозон
        this.geozones = this.geozones.filter((g) => !ids.includes(g.id))
        // Перестраиваем список геозон в левой панели
        this.mappingList();
      });
  }


  /**
   * Получение признака выбранности всех геозон
   */
  public get allChecked() {
    return this.geozones?.every((g) => g.checked);
  }

  /**
   * Признака выбора хотя бы одной геозоны
   */
  get isCheckedGeozone(): boolean {
    return this.geozones?.some(g => g.checked);
  }

  /** Получение признака необходимости отображения панели выбора учетной записи */
  public get showAccounts() {
    return this.store.trackingSettings && this.accounts.length > 1;
  }

  /**
   * Переключение видимости панели поиска
   */
  public toggleSearchPanel() {
    this.showSearch = !this.showSearch;
    this.search = '';
  }

  /**
   * Переключение выбранности всех геозон
   */
  public toggleAllChecked() {
    // идишники геозон
    const ids = this.geozones.map((g) => g.id)

    if (this.allChecked) {
      this.geozones.map((g) => g.checked = false)
      this.trackingService.deleteTrackingGeozones(this.store?.sessionId, ids)
        .subscribe(() => this.mapService.clearGeozonesSubject.next())
    } else {
      this.geozones.map((g) => g.checked = true)
      this.trackingService.addTrackingGeozones(this.store?.sessionId, ids)
        .subscribe((g) => this.mapService.showGeozonesSubject.next(g))
    }

    this.mappingList()
  }

  /**
   * Переключение выбранности геозоны
   * @param geozone Геозона
   */
  public toggleChecked(geozone: IClientGeozone) {
    this.monitoringService.toggleGeozoneChecked(geozone);
  }


  /**
   * Удаление выбранных геозон (пачкой)
   */
  public deleteSelectedGeozone() {
    // Находим выбранные геозоны
    const selectedGeozonesIds: string[] = this.geozones.filter(g => g.checked).map(i => i.id);
    // Показываем подтверждение об удалении
    this.modalService.showQuestion('component.geo.confirm-selected-delete')
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.geozonesService.deleteSelectedGeozones(selectedGeozonesIds), true)))
      .subscribe((ids) => {
        this.geozonesService.deleteRelatedGeozone(ids)
        this.geozones = this.geozones.filter((g) => !ids.includes(g.id))
        this.mappingList()
      });
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = { field, isDescending: false };
    }
    this.mappingList();
  }

  /**
   * Добавление геозоны
   */
  public addGeozone() {
    this.router.navigate(['./add'], { relativeTo: this.route }).then();
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onChangeAccount() {
    this.monitoringService.updateTrackingSettings(this.store.trackingSettings)
      .subscribe(() => this.init())
  }

  /** Выбор учетной записи из списка */
  public selectAccount() {
    const selectedAccount = this.accounts.find((a) => a.id === this.store.trackingSettings.geozonesAccountId);
    const data = {
      items: this.accounts,
      selected: selectedAccount ? [selectedAccount] : [],
      title: 'component.geo.select-account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.store.trackingSettings.geozonesAccountId = result?.shift()?.id;
        this.onChangeAccount();
      });
  }

  /** Переключение признака необходимости отображения групп геозон */
  public toggleGeozoneGroups() {
    this.store.trackingSettings.showGeozoneGroups = !this.showGeozoneGroups
    this.monitoringService.updateTrackingSettings(this.store?.trackingSettings)
      .subscribe(() => {
        this.showGeozoneGroups = !this.showGeozoneGroups;
        this.mappingList();
      })
  }

  /**
   * Добавление группы геозон
   */
  public addGroup() {
    this.router.navigate([`/monitoring/geozones-group/add`]).finally()
  }

  /**
   * Изменение группы геозон
   * @param id Идентификатор группы
   */
  public updateGroup(id: string) {
    this.router.navigate([`/monitoring/geozones-group/${id}`]).finally()
  }

  /**
   * Удаление группы геозон
   * @param group Группа геозон
   */
  public deleteGroup(group: IListGeozoneOrGroup) {
    const confirm = this.translator.instant('component.geo.confirm-group', { val: group.name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.monitoringService.deleteGeozoneGroup(group), true)))
      .subscribe(() => this.init());
  }

  /**
   * Открываем дополнительную информацию по геозоне
   * @param geo Геозона из списка
   */
  public toggleInfo(geo: IListGeozoneOrGroup) {
    const geozone = this.geozones.find((g) => g.id === geo.id)

    // Получаем детольную информацию по геозоне
    this.geozonesService.getGeozone(geo.id)
      .subscribe((res) => {
        geozone.detail = res
        geozone.isOpenInfo = !geo.isOpenInfo
      })

    // Пересобираем список
    this.mappingList()
  }

  /**
   * Экспорт в эксель
   */
  public export() {
    const accountId = this.store?.trackingSettings?.geozonesAccountId || this.store?.user?.account?.id;
    const checkedGeozoneIds: string[] = this.geozones.filter(g => g.checked).map(g => g.id);

    this.loadingService.wrap(this.geozonesService.getExcel(accountId, checkedGeozoneIds), true)
      .subscribe((blob) => {
        const name = this.translator.instant('component.geo.geo').concat('.xlsx');
        FileSaver.saveAs(blob, name);
      });
  }

  /**
   * Импорт геозон
   */
  public handleImportExcel() {
    this.uploader.uploadAll();
    if (this.uploader.isUploading) {
      this.loadingService.startLoading();
    }
    this.geozoneUploader.nativeElement.value = '';
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    if (this.store.editGeozone) {
      this.monitoringService.cancelEditGeozone(this.store.editGeozone);
    }
  }

  /**
   * Успешная загрузка файлов
   */
  private onSuccessUploadItem = (item: FileItem, res: string) => {
    this.loadingService.stopLoading();
    this.monitoringService.getGeozones();

    const result = JSON.parse(res) as IServerResponse;
    if (result.success) {
      this.translator.get('component.geo.import.success', { val: result.data })
        .subscribe((x) => {
          this.modalService.showInfo(x)
          // Перезагрузка всех геозон с сервера
          this.init()
        });
    } else {
      this.modalService.showError(result.data);
    }
  };

  /**
   * Обработка при ошибке загрузки файла
   * @param item Информация о файле
   * @param res Ответ сервера
   */
  private onErrorUploadItem = (item: FileItem, res: string) => {
    this.loadingService.stopLoading();
    this.modalService.showError(res);
  };
}
