import { Component, Input } from '@angular/core';

import { IGeneratedInvoice } from '../../../../../shared/crm/docs';

/**
 * Компонент для отображения сгенерированного счета
 */
@Component({
  selector: 'generated-invoice',
  templateUrl: './generated-invoice.component.html'
})
export class GeneratedInvoiceComponent {
  /** Данные по счету */
  @Input() public invoice: IGeneratedInvoice;

  /** Признак того, что в счете есть ед.измерения, кол-во и цена */
  public get withMCC() {
    return this.invoice && this.invoice.services.some(
      (s) => (s.measure || s.cost || s.count) ? true : false);
  }

  /** Получение суммы по всем услугам */
  public get servicesSum() {
    return this.invoice && this.invoice.services
      ? this.invoice.services.reduce((p, c) => p + c.sum, 0)
      : 0;
  }
}
