import { Component } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { IEquipment } from '../../../../../shared/crm/equipment/IEquipmentGeneric';
import { HistoryEntityType } from '../../../../../shared/crm/hist';
import { IRefItem, RefType } from '../../../../../shared/crm/IRefItem';
import {
  getAllLocationTypes,
  getLocationTypeName,
  LocationType
} from '../../../../../shared/crm/LocationType';
import { ISIMCard } from '../../../../../shared/crm/sim/ISIMCardGeneric';
import { IUnitLight } from '../../../../../shared/units/IUnitLight';
import { DetailComponent } from '../../../../classes/DetailComponent';
import { DetailTab } from '../../../../classes/DetailTab';
import { IClientHistory } from '../../../../classes/IClientHistory';
import { IListItem } from '../../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';
import { AccountComponent } from '../../../account/account.component';
import { SelectionType, SelectItemsComponent } from '../../../select-items/select-items.component';
import { UnitComponent } from '../../../unit/unit.component';
import { RefsItemsModalComponent } from '../../refs/items-modal/refs.items-modal.component';
import { SIMCardsEditComponent } from '../../sim-cards/edit/sim-cards.edit.component';

/**
 * Интерфейс компонента для редактирования оборудования
 */
interface IEquipmentsEditComponent {
  /** Идентификатор оборудования */
  equipmentId?: string;
  /** Признак терминала */
  isTerminal?: boolean;
  /** Признак копирования */
  copy?: boolean;
}

/**
 * Компонент для редактирования оборудования
 */
@Component({
  selector: 'equipments-edit',
  templateUrl: './equipments.edit.component.html'
})
export class EquipmentsEditComponent
  extends DetailComponent<IEquipmentsEditComponent>
  implements IEquipmentsEditComponent {

  /** Идентификатор оборудования */
  public equipmentId?: string;

  /** Признак терминала */
  public isTerminal?: boolean;

  /** Признак копирования */
  public copy?: boolean;

  /** Оборудование */
  public equipment: IEquipment;

  /** Список типов местоположения оборудования */
  public locationTypes: IListItem<LocationType>[];

  /** История по оборудованию */
  public history: IClientHistory[];

  /** Список ТС для выбора привязки */
  public units: IUnitLight[];

  /** Список учетных записей для выбора привязки */
  public accounts: IListItem<string>[];

  /** Список SIM-карт для выбора привязки */
  public simCards: IListItem<string>[];

  /** Тип истории */
  public hType = HistoryEntityType;

  /**
   * Информация о том, для каких учетных записей получены ТС
   * (список идентификаторов учетных записей, или 'all', если для всех)
   */
  private unitsForAccounts: string[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис работы с ДИУП
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.tabs[Tabs.Main] = new DetailTab('component.crm.equipments.edit.main');
    this.tabs[Tabs.History] = new DetailTab('component.crm.equipments.edit.history');
    this.selectedTab = this.tabs[Tabs.Main];

    this.locationTypes = getAllLocationTypes()
      .map((id) => ({ id, name: getLocationTypeName(id) }));

    this.equipment = { isTerminal: true, simCards: [] } as IEquipment;
    this.isAddRegime = true;
  }

  /**
   * Получение признака возможности сохранения
   */
  public get isCanSave(): boolean {
    return !!(this.equipment
      && this.equipment.uid
      && this.equipment.uid !== ''
      && this.equipment.state
      && this.equipment.state.id
      && this.equipment.type
      && this.equipment.type.id);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IEquipmentsEditComponent) {
    if (data.equipmentId) {
      this.loadingService.wrap(this.crudService.get(data.equipmentId, CRUDEntityType.EQUIPMENT), true)
        .subscribe((equipment) => {
          this.equipment = equipment;
          this.isTerminal = this.equipment.isTerminal;
          if (data.copy) {
            delete this.equipment.id;
          }
        });

    } else {
      this.equipment.isTerminal = data.isTerminal || false;
    }

    const type = this.equipment.isTerminal ? 'tracker' : 'equip';
    const action = data.equipmentId ? data.copy ? 'copy' : 'edit' : 'add';
    this.title = `component.crm.equipments.edit.${type}-${action}-title`;
    this.isAddRegime = action !== 'edit';
    this.tabs[Tabs.History].hidden = this.isAddRegime;

    return super.fillData(data);
  }

  /**
   * Клик по кнопке выбора состояния
   */
  public selectState() {
    this.selectFromRef(RefType.EQUIPMENT_STATE, this.equipment.state as IRefItem,
      (state) => this.equipment.state = state);
  }

  /**
   * Клик по кнопке выбора типа оборудования
   */
  public selectType() {
    this.selectFromRef(this.isTerminal ? RefType.TRACKER : RefType.OPTIONAL_EQUIPMENT,
      this.equipment.type as IRefItem, (type) => this.equipment.type = type);
  }

  /**
   * Клик по кнопке выбора производителя
   */
  public selectManufacturer() {
    this.selectFromRef(RefType.MANUFACTURER, this.equipment.manufacturer as IRefItem,
      (manufacturer) => this.equipment.manufacturer = manufacturer);
  }

  /**
   * Клик по кнопке выбора объекта мониторинга
   */
  public selectUnit() {
    const accountId = this.equipment.client?.id;

    let load = !this.unitsForAccounts.length;
    if (accountId) {
      load = load && !this.unitsForAccounts.includes(accountId);
    }

    if (!load) {
      this.selectFromUnits();
      return;
    }

    const query = accountId ? { accountId } : null;
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT, query), true)
      .subscribe((units) => {
        if (this.units && accountId) {
          this.units.push(...units);
          this.unitsForAccounts.push(accountId);

        } else {
          this.units = units;
          this.unitsForAccounts = accountId ? [accountId] : [];
        }

        this.selectFromUnits();
      });
  }

  /**
   * Клик по кнопке выбора учетной записи (клиента)
   */
  public selectClient() {
    // Выбираем из загруженного списка
    if (this.accounts) {
      this.selectFromAccounts();
      return;
    }

    // Загружаем список клиентов
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.ACCOUNT), true)
      .subscribe((accounts) => {
        this.accounts = accounts;
        this.selectFromAccounts();
      });
  }

  /**
   * Клик по кнопке выбора SIM-карт
   */
  public selectSimCards() {
    // Выбираем из загруженного списка
    if (this.simCards) {
      this.selectFromSIMCards();
      return;
    }

    // Загружаем список SIM-карт
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.SIM_CARD), true)
      .subscribe((simCards) => {
        this.simCards = simCards;
        this.selectFromSIMCards();
      });
  }

  /**
   * Клик по кнопке добавления SIM-карты
   */
  public addSimCard() {
    this.dialogService.addDialog(SIMCardsEditComponent, {})
      .pipe(
        filter((result) => !!result),
        flatMap((result) => this.loadingService
          .wrap(this.crudService.get(result as string, CRUDEntityType.SIM_CARD), true)))
      .subscribe((simCard: ISIMCard) => {
        const item = { id: simCard.id, name: simCard.iccid };
        this.equipment.simCards.push(item);
        if (this.simCards) {
          this.simCards.push(item);
        }
      });
  }

  /**
   * Клик по кнопке отображения детальной информации
   * по объекту мониторинга
   * @param unit Объект мониторинга
   */
  public showUnitDetail(unit: IListItem<any>) {
    if (!unit) {
      return;
    }

    const data = { unitId: unit.id };
    this.dialogService.addDialog(UnitComponent, data).subscribe();
  }

  /**
   * Клик по кнопке отображения детальной информации
   * по клиенту (учетной записи)
   * @param client Учетная запись (клиент)
   */
  public showClientDetail(client: IListItem<any>) {
    if (!client) {
      return;
    }

    const data = { accountId: client.id };
    this.dialogService.addDialog(AccountComponent, data).subscribe();
  }

  /**
   * Клик по кнопке отображения детальной информации
   * по SIM-карте
   * @param simCard SIM-карта
   */
  public showSimCardDetail(simCard: IListItem<any>) {
    if (!simCard) {
      return;
    }

    const data = { simCardId: simCard.id };
    this.dialogService.addDialog(SIMCardsEditComponent, data).subscribe();
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.loadingService.wrap(this.crudService.addUpdate(this.equipment, CRUDEntityType.EQUIPMENT), true)
      .subscribe((id) => {
        this.result = id;
        this.close();
      });
  }

  /**
   * Выбор элемента из справочника
   * @param type Тип справочника
   * @param selected Выбранная в данный момент запись справочника
   * @param callback Функция, которая должна выполниться после выбора
   */
  private selectFromRef(type: RefType, selected: IRefItem, callback: (item: IRefItem) => void) {
    const data = { type, selected };
    this.dialogService.addDialog(RefsItemsModalComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => callback(result));
  }

  /**
   * Выбор из списка учетных записей (клиентов)
   */
  private selectFromAccounts() {
    const data = {
      items: this.accounts,
      selected: this.equipment.client ? [this.equipment.client] : null,
      title: 'component.crm.equipments.edit.select-account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const client = result?.shift();

        if (this.equipment?.client?.id !== client?.id) {
          this.equipment.unit = null;
        }

        this.equipment.client = client;
      });
  }

  /**
   * Выбор из списка SIM-карт
   */
  private selectFromSIMCards() {
    const data = {
      items: this.simCards,
      selected: this.equipment.simCards,
      title: 'component.crm.equipments.edit.select-sim',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.equipment.simCards = result;

        if (!this.equipment.simCards) {
          this.equipment.simCards = [];
        }
      });
  }

  /**
   * Выбор из списка объектов мониторинга
   */
  private selectFromUnits() {
    const accountId = this.equipment?.client?.id;
    const items = accountId ? this.units.filter((u) => u.accountId === accountId) : this.units;

    const data = {
      items,
      selected: this.equipment.unit ? [this.equipment.unit] : null,
      title: 'component.crm.equipments.edit.select-car',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.equipment.unit = result?.shift();

        const unit = this.units?.find((u) => u.id === this.equipment.unit?.id);

        if (this.accounts) {
          this.equipment.client = this.accounts?.find((a) => a.id === unit?.accountId);
          return;
        }

        this.loadingService.wrap(this.crudService?.getListLight(CRUDEntityType.ACCOUNT), true)
          .subscribe((accounts) => {
            this.accounts = accounts;
            this.equipment.client = this.accounts?.find((a) => a.id === unit?.accountId);
          });
      });
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  History
}
