import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ItemSelectorService } from '../../services/item-selector.service';

/**
 * Компонент выбора каких-либо записей
 * @template D данные на вход компонента
 * @template R данные на выходе компонента
 */
@Component({
  selector: 'item-selector',
  templateUrl: './item-selector.component.html'
})
export class ItemSelectorComponent implements OnDestroy {
  /**
   * Данные для компонента
   */
  public data: IItemSelectorData;

  /**
   * Признак открытого окна
   */
  public show: boolean;

  /**
   * Значение введеное в поисковую строку
   */
  public search: string;

  /**
   * Элементы списка
   */
  public items: Array<ISelectItem>;

  /**
   * Подписка
   */
  private openSubscribe: Subscription;

  /**
   * Конструктор
   */
  constructor(
    private modalDialogService: ItemSelectorService
  ) {
    this.openSubscribe = this.modalDialogService.openSubject.subscribe(this.onChange);
  }

  /**
   * Обработки при уничтожении компонента
   */
  ngOnDestroy(): void {
    this.openSubscribe.unsubscribe();
  }

  /**
   * Оброаботки при изменении компонента
   * @param data
   */
  onChange = (data) => {
    this.data = data;
    this.items = this.data.items.map((item) => ({
      id: item.id,
      name: item.name,
      checked: this.data.selected?.some((x) => x.id === item.id)
    }));

    this.show = true;
  };

  /**
   * Подтверждение выбора элементов в компоненте
   */
  public confirm() {
    const ids = this.items.filter((item) => item.checked).map((item) => item.id);
    this.data.result.next(this.data.items.filter((item) => ids.includes(item.id)));
    this.cancel();
  }

  /**
   * Отмена действий
   */
  public cancel() {
    this.data = null;
    this.items = null;
    this.search = null;
    this.show = false;
  }
}


/**
 * Интерфейс компонента выбора объектов мониторинга
 */
export interface IItemSelectorData {

  /**
   * Заголовок
   */
  title: string;

  /**
   * Список элементов
   */
  items: Array<IListItem>;

  /**
   * Выбраные элементы
   */
  selected: Array<{ id: string }>;

  /**
   * Объект с результатом от календаря
   */
  result: Subject<Array<IListItem>>;
}

interface IListItem {
  id: string,
  name: string
}

interface ISelectItem extends IListItem {
  checked: boolean
}
