/**
 * Интерфейс, представляющий счетчик
 */
export interface ICounter<T> {
  /** Метод расчета */
  method: T;

  /** Текущее значение */
  value: number;

  /** Поле для сохранения предыдущего значения */
  field?: string;

  /** Автоматическое обновление значения */
  auto: boolean;

  /** Признак того, что было изменено пользователем */
  isChangedByUser?: boolean;
}

/**
 * Методы подсчета пробега
 */
export enum MileageCalcMethod {
  /** GPS */
  GPS = 1,

  /** Датчик пробега */
  MILEAGE_SENSOR,

  /** Относительный одометр */
  REL_ODOMETR,

  /** GPS + датчик зажигания */
  GPS_IGNITION
}

/**
 * Методы подсчета моточасов
 */
export enum MhCalcMethod {
  /** Датчик зажигания */
  IGNITION_SENSOR = 1,

  /** Датчик абсолютных моточасов */
  ABS_MH,

  /** Датчик относительных моточасов */
  REL_MH
}

/**
 * Получение списка всех методов расчета пробега
 */
export function getAllMileageCalcMethods() {
  const result: MileageCalcMethod[] = [];
  for (let i = 1; i <= 4; i++) {
    result.push(i as MileageCalcMethod);
  }
  return result;
}

/**
 * Получение списка всех методов расчета моточасов
 */
export function getAllMhCalcMethods() {
  const result: MhCalcMethod[] = [];
  for (let i = 1; i <= 3; i++) {
    result.push(i as MhCalcMethod);
  }
  return result;
}

/**
 * Получение наименования метода расчета пробега
 * @param method Метод расчета пробега
 */
export function getMileageCalcMethodName(method: MileageCalcMethod): string {
  switch (method) {
    case MileageCalcMethod.GPS: return 'enums.units.counter.gps';
    case MileageCalcMethod.GPS_IGNITION: return 'enums.units.counter.gps-ignition';
    case MileageCalcMethod.MILEAGE_SENSOR: return 'enums.units.counter.mileage';
    case MileageCalcMethod.REL_ODOMETR: return 'enums.units.counter.rel-odometer';
    default: return 'enums.units.counter.unknown';
  }
}

/**
 * Получение наименования метода расчета пробега
 * @param method Метод расчета пробега
 */
export function getMhCalcMethodName(method: MhCalcMethod): string {
  switch (method) {
    case MhCalcMethod.IGNITION_SENSOR: return 'enums.units.counter.ignition';
    case MhCalcMethod.ABS_MH: return 'enums.units.counter.abs-mh';
    case MhCalcMethod.REL_MH: return 'enums.units.counter.rel-mh';
    default: return 'enums.units.counter.unknown';
  }
}
