import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { filter, flatMap, switchMap } from 'rxjs/operators';

import { IListItem } from '../../../../classes/IListItem';
import { ModalResult } from '../../../../components/modal/modal.component';
import { ModalService } from '../../../../services/modal.service';
import { BgRepeatersService, IRepeater, RepeaterProtocols } from '../../bg-repeaters.service';

/**
 * Компонент для отображения списка учетных записей
 */
@Component({
  selector: 'app-repeaters',
  templateUrl: './repeaters.component.html'
})
export class RepeatersComponent implements OnInit, OnDestroy {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: Array<IRepeater>;
  /**
   * Выбранный элемент
   */
  public selected: IRepeater;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Подписка на загрузку списка
   * @private
   */
  private loadListSubscription: Subscription

  constructor(
    private repeatersService: BgRepeatersService,
    private translator: TranslateService,
    private modalService: ModalService
  ) {
    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'unit.name', name: 'ui.unit' },
      { id: 'account.name', name: 'ui.account' },
      { id: 'uid', name: 'ui.uid' },
      { id: 'address', name: 'component.repeaters.address' },
      { id: 'protocol', name: 'component.repeaters.protocol' }
    ];

    this.selectedField = this.searchFields[0]?.id

    this.loadListSubscription = this.repeatersService.loadList$.subscribe(this.loadData)
  }

  ngOnInit() {
    this.loadData()
  }

  ngOnDestroy() {
    this.loadListSubscription.unsubscribe()
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  private loadData = () => {
    this.repeatersService.getList().subscribe((data) => this.items = data)
  }

  /**
   * Создание
   */
  public add() {
    this.repeatersService.visibleRepeater$.next({ isOpen: true })
  }

  /**
   * Редактирование
   * @param id
   */
  public edit(id: string) {
    this.repeatersService.visibleRepeater$.next({ isOpen: true, id })
  }

  /**
   * Удаление
   * @param id
   * @param name
   */
  public remove(id: string, name: string) {
    const confirm = this.translator.instant('component.repeaters.remove-confirmation', { val: name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.repeatersService.remove(id)),
        switchMap(() => this.repeatersService.getList()))
      .subscribe((data) => this.items = data);
  }

  /**
   * Название протокола
   * @param protocol
   */
  public protocolName(protocol: RepeaterProtocols): string {
    return this.repeatersService.getRepeaterProtocolName(protocol)
  }
}