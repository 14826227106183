import { ITrackingGroup } from '../../shared/tracking/ITrackingGroup';

import { TrackingUnit } from './TrackingUnit';

/**
 * Группа объектов мониторинга для отображения в слежении
 */
export class TrackingGroup implements ITrackingGroup {
  /** Идентификатор группы */
  public id: string;
  /** Наименование группы */
  public name: string;
  /** Список идентификаторов объектов в группе */
  public units: string[];
  /** Список ТС, находящихся в группе */
  public trackingUnits?: TrackingUnit[];
  /** Признак отображения ТС группы */
  public showUnits?: boolean;
  /** Признак что это группа */
  public isGroup?: boolean;

  /**
   * Конструктор
   * @param group Данные по группе
   */
  constructor(group: ITrackingGroup) {
    this.id = group.id;
    this.name = group.name;
    this.units = group.units;
    this.trackingUnits = [];
    this.showUnits = true;
  }

  /**
   * Получение признака выбранности группы
   */
  public get checked() {
    return this.trackingUnits.length && this.trackingUnits.every((u) => u.checked);
  }

  /**
   * Получение признака слежения за группой
   */
  public get eye() {
    return this.trackingUnits.length && this.trackingUnits.every((u) => u.eye);
  }
}
