import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { ISimpleGroup, ISimpleObject } from '../../../shared/ISimpleObject';
import { IReportListItem, ReportStatus } from '../../../shared/reports/IReportListItem';
import { AccountRightType } from '../../../shared/rights/RightType';
import { AccountsService } from '../../services/accounts.service';
import { FileService } from '../../services/file.service';
import { LoadingService } from '../../services/loading.service';
import { ReportObjectsService } from '../../services/report-objects.service';
import {
  IReportTemplateInfoWithObjects,
  ReportTemplatesService,
  ReportType
} from '../../services/report-templates.service';
import {
  ICreateReportRequest,
  isGroup,
  isGroupReport,
  ITypedGroup,
  ObjectOrGroup,
  ObjectType,
  ReportsService
} from '../../services/reports.service';
import { StoreService } from '../../services/store.service';
import { ToastService } from '../../services/toast.service';
import { UsersService } from '../../services/users.service';
import { localeSort } from '../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';
import { SelectPeriodComponent } from '../select-period/select-period.component';

/**
 * Компонент для генерации отчета
 */
@Component({
  selector: 'bars-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnDestroy {

  /** Список доступных учётных записей */
  public accounts: Array<ISimpleObject>;
  /** Выбранная учётная запись */
  public selectedAccount: ISimpleObject;
  /** Список доступных шаблонов отчетов */
  public templates: IReportTemplateInfoWithObjects[] = [];
  /** Выбранный шаблон отчёта */
  public selectedTemplate: IReportTemplateInfoWithObjects = null;
  /** Список объектов отчета для выбора */
  public objects: ObjectOrGroup[] = [];
  /** Выбранные объекты */
  public selectedObject: ObjectOrGroup = null;
  /** Признак скрытия интервалов недвижения */
  public hide: boolean;
  /** Дата начала отчётного периода */
  public from: Date;
  /** Дата конца отчётного периода */
  public to: Date;
  /** Выбранный отчёт */
  public reportListItem: IReportListItem;

  /**
   * Конструктор
   * @param reportsService Севрис отчетов
   * @param store Сервис для хранения данных мониторинга
   * @param accountsService Injects {@link AccountsService}
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param reportObjectsService Report Objects Service
   * @param reportTemplatesService Report Templates Service
   * @param translator Сервис для перевода
   * @param usersService
   * @param toastService
   * @param fileService
   */
  constructor(
    public reportsService: ReportsService,
    public store: StoreService,
    private accountsService: AccountsService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private reportObjectsService: ReportObjectsService,
    private reportTemplatesService: ReportTemplatesService,
    private translator: TranslateService,
    private usersService: UsersService,
    private toastService: ToastService,
    private fileService: FileService
  ) {
    this.from = new Date(this.reportsService.reportRequest.begin);
    this.to = new Date(this.reportsService.reportRequest.end);
    this.hide = this.reportsService.reportRequest.hide;

    this.loadAccounts();
  }

  /**
   * Получение заголовка окна для выбора из полного списка
   * @param type Тип отчета
   * @param isGroupTitle
   */
  public static getSelectAllDialogTitle(type: ReportType, isGroupTitle?: boolean) {
    switch (type) {
      case ReportType.UNIT:
      case ReportType.UNIT_GROUP:
        return isGroupTitle ? 'component.report.select-group' : 'component.report.select-object';

      case ReportType.DRIVER:
      case ReportType.DRIVER_GROUP:
        return isGroupTitle ? 'component.report.select-group' : 'component.report.select-driver';

      case ReportType.TRAILER:
      case ReportType.TRAILER_GROUP:
        return isGroupTitle ? 'component.report.select-group' : 'component.report.select-trail';

      case ReportType.USER:
        return isGroupTitle ? 'component.report.select-group' : 'component.report.select-user';

      default:
        return 'component.report.select';
    }
  }

  /**
   * Обработка изменения выбранной учётной записи
   */
  public changeAccount() {
    this.templates = [];
    this.objects = [];

    this.selectedTemplate = null;
    this.selectedObject = null;

    this.loadTemplates();
  }

  /**
   * Обработка изменения выбранного типа отчетов
   */
  public changeTemplate() {
    this.objects = [];

    this.selectedObject = null;

    this.reportObjectsService.loadObjects(this.selectedTemplate.type, ObjectType.Both)
      .subscribe(this.onObjectsLoaded);
  }

  /**
   * Выбор объекта из списка
   */
  public selectObjects() {
    this.reportObjectsService.loadObjects(this.selectedTemplate.type, ObjectType.Both)
      .subscribe(this.showSelectObjectDialog);
  }

  /** Инициализация текущего отчёта */
  public setSelectedReport(item: IReportListItem) {
    this.reportListItem = item;
    if (item?.status === ReportStatus.PROGRESS) {
      this.toastService.warn(this.translator.instant('component.report.queue-not-ready'));
      return;
    }

    if (item?.id) {
      this.reportsService.getReadyReport(this.reportListItem.id).subscribe();
    }
  }

  /**
   * Выбор периода
   */
  public selectPeriod() {
    const data = {
      from: this.from,
      to: this.to,
      disableUntil: this.usersService.getUserHistoryPeriodFrom,
      disableSince: this.usersService.getUserHistoryPeriodTo
    };

    this.dialogService.addDialog(SelectPeriodComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.from = result.from;
        this.to = result.to;
      });
  }

  /**
   * Создание отчета
   */
  public createReport() {
    this.reportsService.createReportSubject.next();

    const objects = !this.selectedObject ? [] : this.selectedObject.id
      ? [this.selectedObject.id]
      : [(this.selectedObject as ISimpleGroup).objects];

    const request: ICreateReportRequest = {
      begin: this.from.getTime(),
      end: this.to.getTime(),
      hide: this.hide,
      template: this.selectedTemplate.id,
      name: this.selectedObject.name,
      objects
    };

    this.reportsService.buildReport(request).subscribe(this.onReportBuild);
  }

  /**
   * При закрытии компонента вызывает очистку списков обьъектов
   */
  public ngOnDestroy() {
    this.reportsService.customGroups = this.reportsService.customGroups
      .filter((x) => x?.name === this.selectedObject?.name);

    this.reportsService.clearReport();
  }

  /**
   * Load accounts list
   */
  private loadAccounts() {
    this.accountsService.getWithRight(AccountRightType.VIEW_REPORT_TEMPLATES)
      .subscribe((accounts) => {
        const all = { id: null, name: 'ui.not-selected' };

        this.accounts = [all];
        this.accounts.push(...accounts?.sort(localeSort));

        const current = accounts.find((x) => x.id === this.store.user.account.id);
        this.selectedAccount = current ? current : all as ISimpleObject

        this.changeAccount();
      });
  }

  /**
   * Загрузка шаблонов отчетов
   */
  private loadTemplates() {
    this.reportTemplatesService.getTemplates(this.selectedAccount.id)
      .subscribe((templates) => this.templates = templates);
  }

  /**
   * Поиск в списке объектов
   * @param oog Объект или группа
   */
  private findExists(oog: ObjectOrGroup) {
    if (!oog) {
      return null;
    }

    const result = isGroup(oog)
      ? this.objects.find((x) => isGroup(x)
        && oog.objects.length === x.objects.length
        && oog.objects.every((o) => x.objects.includes(o)))
      : this.objects.find((x) => oog.id === x.id);

    return result || null;
  }

  /**
   * Обработка создания отчёта
   */
  private onReportBuild = () => {
    this.toastService.success(this.translator.instant('component.report.report-in-production'))
    this.reportsService.loadQueueList();
  };

  /**
   * Обработка загрузки объектов
   * @param list Список объектов
   */
  private onObjectsLoaded = (list: ObjectOrGroup[]) => {
    // Заполняем список ид групп из шаблона
    const gids: string[] = this.selectedTemplate.objects
      .filter((x) => x.group)
      .map((x) => x.id);

    // Если отчёт по группам, заполняем список группами с добавлением пользовательских групп
    if (isGroupReport(this.selectedTemplate.type)) {
      this.objects = list
        .filter((x) => isGroup(x) && (!gids.length || gids.includes(x.id)))
        .concat(this.reportsService.getCustomGroups(this.selectedTemplate.type));

      this.selectedObject = this.findExists(this.selectedObject);
      return;
    }

    // Заполняем список ид объектов из шаблона
    let oids: string[] = this.selectedTemplate.objects
      .filter((x) => !x.group)
      .map((x) => x.id);

    // Дополняем список ид объектов объектами из групп
    oids.push(...list.filter((x) => isGroup(x) && gids.includes(x.id))
      .map((x: ISimpleGroup) => x.objects)
      .reduce((acc, x) => acc.concat(x), []));

    // Фильтруем список ид объектов видимыми объектами (только для ТС)
    if (this.selectedTemplate.type === ReportType.UNIT && this.store.units) {
      oids = this.store.units
        .filter((x) => !oids.length || oids.includes(x.id))
        .map((x) => x.id);
    }

    // Заполняем список объектами
    this.objects = list.filter((x) => !isGroup(x) && (!oids.length || oids.includes(x.id)));

    this.selectedObject = this.findExists(this.selectedObject);
  };

  /**
   * Отображение окна для выбора объектов в отчет из полного списка
   * @param list Список объектов
   */
  private showSelectObjectDialog = (list: ObjectOrGroup[]) => {
    // Заполняем список ид групп из шаблона
    const gids: string[] = this.selectedTemplate.objects
      .filter((x) => x.group)
      .map((x) => x.id);

    // Заполняем список ид объектов из шаблона
    const oids: string[] = this.selectedTemplate.objects
      .filter((x) => !x.group)
      .map((x) => x.id);

    // Дополняем список ид объектов объектами из групп
    oids.push(...list.filter((x) => isGroup(x) && gids.includes(x.id))
      .map((x: ISimpleGroup) => x.objects)
      .reduce((acc, x) => acc.concat(x), []));

    const group = isGroupReport(this.selectedTemplate.type);

    const ids = !this.selectedObject ? [] : isGroup(this.selectedObject)
      ? this.selectedObject.objects
      : [this.selectedObject.id];

    const items = list.filter((x) => !isGroup(x) && (!oids.length || oids.includes(x.id)));
    const selected = items.filter((x) => ids.includes(x.id));

    const data = {
      items,
      selected,
      title: ReportComponent.getSelectAllDialogTitle(this.selectedTemplate.type),
      withSearch: true,
      selection: group
        ? SelectionType.Free
        : SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!result?.length) {
          this.selectedObject = null;
          return;
        }

        let uog;
        if (group) {
          uog = {
            id: null,
            name: result?.map((x) => x.name).join(', '),
            objects: result?.map((x) => x.id)
          };

        } else {
          const item = result?.shift();
          uog = {
            id: item?.id,
            name: item?.name
          };
        }

        // Ищем уже существующие записи в списке объектов
        const exists = this.findExists(uog);
        if (exists) {
          this.selectedObject = exists;
          return;
        }

        if (group) {
          this.reportsService.customGroups.push({ ...uog, type: this.selectedTemplate.type } as ITypedGroup);
        }

        this.objects.push(uog);
        this.selectedObject = uog;
      });
  };
}
