import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IAccountMapKeys } from '../../shared/accounts/IAccount';
import { IActivityDetails } from '../../shared/crm/clients/IActivityDetails';
import { IClientBalanceNotificationSettings } from '../../shared/crm/clients/IClient';
import {
  IClientGenerateInvoiceRequest,
  IDocItem,
  IDocTariffsInfo,
  IGeneratedInvoice,
  IGeneratedWAReport
} from '../../shared/crm/docs';
import { IDogovor } from '../../shared/crm/dogovors/IDogovor';
import { IAccountBalance } from '../../shared/crm/operations/IAccountBalance';
import { IOperation } from '../../shared/crm/operations/IOperation';
import { IPage } from '../../shared/IPage';
import { ICurrentUser, IUserSettings, Locale } from '../../shared/users';
import { ISetLanguageRequest } from '../../shared/users/ISetLanguageRequest';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с текущим клиентом
 */
@Injectable()
export class ClientService {

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/client`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param translator Сервис перевода
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private translator: TranslateService,
    private configService: ConfigService
  ) { }

  /**
   * Получение даных текущего пользователя
   */
  public getUser(): Observable<ICurrentUser> {
    const url = `${this.baseUrl}/user`;
    return this.http.get<ICurrentUser>(url);
  }

  /**
   * Get keys from account of user
   */
  public getAccountKeys(): Observable<IAccountMapKeys> {
    const url = `${this.baseUrl}/keys`;
    return this.http.get<IAccountMapKeys>(url);
  }

  /**
   * Получение списка учетных записей клиента
   */
  public getAccounts(): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/accounts`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получение страницы со списком операций учетной записи
   * @param limit Максимальное кол-во получаемых записей
   * @param offset Смещение от начала списка
   * @param accountId Идентификатор учетной записи клиента
   */
  public getOperations(limit: number, offset: number, accountId: string): Observable<IPage<IOperation>> {
    const url = `${this.baseUrl}/${accountId}/operations`;
    const options = {params: {limit: `${limit}`, offset: `${offset}`}};
    return this.http.get<IPage<IOperation>>(url, options);
  }

  /**
   * Получение страницы со списком счетов учетной записи
   * @param limit Максимальное кол-во получаемых записей
   * @param offset Смещение от начала списка
   * @param accountId Идентификатор учетной записи
   */
  public getInvoices(limit: number, offset: number, accountId: string): Observable<IPage<IDocItem>> {
    const url = `${this.baseUrl}/${accountId}/invoices`;
    const options = {params: {limit: `${limit}`, offset: `${offset}`}};
    return this.http.get<IPage<IDocItem>>(url, options);
  }

  /**
   * Получение страницы со списком актов учетной записи
   * @param limit Максимальное кол-во получаемых записей
   * @param offset Смещение от начала списка
   * @param accountId Идентификатор учетной записи
   */
  public getWAReports(limit: number, offset: number, accountId: string): Observable<IPage<IDocItem>> {
    const url = `${this.baseUrl}/${accountId}/wareports`;
    const options = {params: {limit: `${limit}`, offset: `${offset}`}};
    return this.http.get<IPage<IDocItem>>(url, options);
  }

  /**
   * Получение информации о состоянии счета и последнем платеже по учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getBalance(accountId: string): Observable<IAccountBalance> {
    const url = `${this.baseUrl}/${accountId}/balance`;
    return this.http.get<IAccountBalance>(url);
  }

  /**
   * Получение действующего договора по учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getDogovor(accountId: string): Observable<IDogovor> {
    const url = `${this.baseUrl}/${accountId}/dogovor`;
    return this.http.get<IDogovor>(url);
  }

  /**
   * Регистрация обещанного платежа
   * @param accountId Идентификатор учетной записи
   */
  public promisedPayment(accountId: string): Observable<string> {
    const url = `${this.baseUrl}/${accountId}/promised-payment`;
    return this.http.post<string>(url, {});
  }

  /**
   * Получение счета
   * @param id Идентификатор счета
   * @param accountId Идентификатор учетной записи
   */
  public getInvoice(id: string, accountId: string): Observable<IGeneratedInvoice> {
    const url = `${this.baseUrl}/${accountId}/invoices/${id}`;
    return this.http.get<IGeneratedInvoice>(url);
  }

  /**
   * Получение акта выполненных работ
   * @param id Идентификатор акта выполненных работ
   * @param accountId Идентификатор учетной записи
   */
  public getWAReport(id: string, accountId: string): Observable<IGeneratedWAReport> {
    const url = `${this.baseUrl}/${accountId}/wareports/${id}`;
    return this.http.get<IGeneratedWAReport>(url);
  }

  /**
   * Получение информации по тарифам для генерации актов/счетов
   * @param accountId Идентификатор учетной записи
   */
  public getTariffsInfo(accountId: string): Observable<IDocTariffsInfo> {
    const url = `${this.baseUrl}/${accountId}/tariffs-info`;
    return this.http.get<IDocTariffsInfo>(url);
  }

  /**
   * Генерация счета
   * @param body Запрос на генерацию счета
   * @param accountId Идентификатор учетной записи
   */
  public generateInvoice(body: IClientGenerateInvoiceRequest, accountId: string): Observable<string> {
    const url = `${this.baseUrl}/${accountId}/invoices`;
    return this.http.post<string>(url, body);
  }

  /**
   * Получение списка пользователей учетных записей текущего клиента
   */
  public getUsers(): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/users`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получение активности ТС клиента за выбранный месяц
   * @param accountId Идентификатор учетной записи клиента
   * @param year Год
   * @param month Месяц
   */
  public getUnitsActivity(accountId: string, year: number, month: number): Observable<IActivityDetails> {
    const url = `${this.baseUrl}/${accountId}/units-activity/${year}/${month}`;
    return this.http.get<IActivityDetails>(url);
  }

  /**
   * Получение настроек отправки уведомлений об окончании средств на счете для текущего клиента
   */
  public getBalanceNotificationSettings(): Observable<IClientBalanceNotificationSettings<string>> {
    const url = `${this.baseUrl}/balance-notification-settings`;
    return this.http.get<IClientBalanceNotificationSettings<string>>(url);
  }

  /**
   * Изменение настроек отправки уведомлений об окончании средств на счете для текущего клиента
   */
  public updateBalanceNotificationSettings(body: IClientBalanceNotificationSettings<string>): Observable<string> {
    const url = `${this.baseUrl}/balance-notification-settings`;
    return this.http.post<string>(url, body);
  }

  /**
   * Изменение пароля пользователя
   * @param oldPassword Старый пароль
   * @param newPassword Новый пароль
   */
  public updateUserPassword(oldPassword: string, newPassword: string): Observable<string> {
    const url = `${this.baseUrl}/user/change-password`;
    const body = {oldPassword, newPassword};
    return this.http.post<string>(url, body);
  }

  /**
   * Получение настроек пользователя
   */
  public getUserSettings(): Observable<IUserSettings> {
    const url = `${this.baseUrl}/user/settings`;
    return this.http.get<IUserSettings>(url);
  }

  /**
   * Изменение настроек пользователя
   * @param body Настройки
   */
  public updateUserSettings(body: IUserSettings): Observable<string> {
    const url = `${this.baseUrl}/user/settings`;
    return this.http.post<string>(url, body);
  }

  /**
   * Мониторинг активности пользователя
   * (каждый запрос - плюс одна минута активности)
   */
  public activity(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/activity`);
  }

  /**
   * Установка языка текущего пользователя
   */
  public setLanguage(): Observable<string> {
    const body: ISetLanguageRequest = {
      lang: this.translator.currentLang as Locale
    };

    return this.http.post<string>(`${this.baseUrl}/language`, body);
  }
}
