import { Component } from '@angular/core';

import {
  getAllRights,
  getAllUnitRights,
  getRightTypeName,
  getUnitRightTypeName,
  RightType,
  UnitRightType
} from '../../../shared/rights/RightType';
import { IRightGen } from '../../classes/IRightGen';

@Component({
  selector: 'rights-gen',
  templateUrl: './rights-gen.component.html'
})
export class RightsGenComponent {

  public generalRights: IRightGen<RightType>[] = [];
  public unitRights: IRightGen<UnitRightType>[] = [];

  /**
   * Конструктор
   */
  constructor() {
    this.generalRights = getAllRights().map((type) => ({
      type,
      name: getRightTypeName(type),
      checked: false,
      disabled: false,
      value: false
    }));

    this.unitRights = getAllUnitRights().map((type) => ({
      type,
      name: getUnitRightTypeName(type),
      checked: false,
      disabled: false,
      value: false
    }));
  }

  get value(): number {
    let result = 0;
    this.generalRights.forEach(
      (right) => {
        if (right.checked) {
          // tslint:disable-next-line:no-bitwise
          result |= right.type;
        }
      }
    );
    this.unitRights.forEach(
      (right) => {
        if (right.checked) {
          // tslint:disable-next-line:no-bitwise
          result |= right.type;
        }
      }
    );
    return result;
  }

  public selectAll(rights: IRightGen<RightType | UnitRightType>[]) {
    rights.forEach(
      (right) => {
        right.checked = true;
      }
    );
  }

  public unselectAll(rights: IRightGen<RightType | UnitRightType>[]) {
    rights.forEach(
      (right) => {
        right.checked = false;
      }
    );
  }
}
