import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { ISimpleObject } from '../../../../shared/ISimpleObject';
import { DistrictsService } from '../../../services/districts.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';

/**
 * Компонент справочника районов
 */
@Component({
  selector: 'app-scavenger-districts',
  templateUrl: './ui-districts.component.html',
  styleUrls: ['./ui-districts.component.scss']
})
export class UiDistrictsComponent extends DialogComponent<{}, void> {

  /**
   * Районы
   */
  public districts: ISimpleObject[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param districtsService Сервис для работы с районами
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private districtsService: DistrictsService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.loadingService.withLoading(
      this.districtsService.getList(),
      (districts) => this.districts = districts);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (this.districts.some((x) => !x.name)) {
      this.modalService.showWarning('component.scavenger.districts.error');
      return;
    }

    this.loadingService.withLoading(
      this.districtsService.saveList(this.districts),
      () => this.close()
    );
  }

  /**
   * Добавление элемента списка
   */
  public add() {
    this.districts.push({name: '', id: null});
  }

  /**
   * Удаление района из списка
   * @param district Район
   */
  public remove(district: ISimpleObject) {
    const i = this.districts.indexOf(district);
    this.districts.splice(i, 1);
  }
}
