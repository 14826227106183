import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { CountryCode, getAllCountryCodes, getCountryName } from '../../../../../shared/crm/CountryCodes';
import { IRefItem, RefType } from '../../../../../shared/crm/IRefItem';
import {
  getAllProtocolTypes,
  getProtocolTypeName,
  ProtocolType
} from '../../../../../shared/crm/ProtocolType';
import { IListItem } from '../../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { ModalService } from '../../../../services/modal.service';

/**
 * Интерфейс компонента для редактирования
 * записи справочники
 */
interface IRefsEditComponent {
  /** Редактируемая запись */
  item: IRefItem;
}

/**
 * Компонент для редактирования записи справочника
 */
@Component({
  selector: 'refs-edit',
  templateUrl: './refs.edit.component.html'
})
export class RefsEditComponent
extends DialogComponent<IRefsEditComponent, boolean>
implements IRefsEditComponent {

  /** Редактируемая запись */
  public item: IRefItem;

  /** Заголовок окна */
  public title: string;

  /** Список протоколов (для терминалов) */
  public protocolTypes: IListItem<ProtocolType>[] = [];

  /** Список протоколов */
  public countryCodes: IListItem<CountryCode>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для работы с ДИУП
   * @param modalService Сервис модальных окон
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private modalService: ModalService
  ) {
    super(dialogService);

    this.item = { type: RefType.TRACKER } as IRefItem;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IRefsEditComponent) {
    switch (data.item.type) {
      case RefType.TRACKER:
        this.protocolTypes = getAllProtocolTypes().map((id) => ({ id, name: getProtocolTypeName(id) }));
        break;
      case RefType.COUNTRY_CODES:
        this.countryCodes = getAllCountryCodes().map((id) => ({ id, name: getCountryName(id) }));
        break;
    }

    this.title = 'component.crm.refs.record-' + (data.item.id ? 'edit' : 'add');

    return super.fillData(data);
  }

  /**
   * Получение признака необходимости отображения поля для выбора типа протокола
   */
  public get isShowProtocolTypeField() {
    return this.item.type === RefType.TRACKER;
  }

  /**
   * Получение признака необходимости отображения поля для выбора типа протокола
   */
  public get isShowCodeField() {
    return this.item.type === RefType.COUNTRY_CODES;
  }

  /**
   * Получение признака возможности сохранения записи
   */
  public get isCanSave() {
    return this.item.name && this.item.name !== '';
  }

  /**
   * Получение признака необходимости отображения
   * строки для редактирования цены
   */
  public get isShowCost() {
    return this.item.type === RefType.TRACKER
      || this.item.type === RefType.OPTIONAL_EQUIPMENT
      || this.item.type === RefType.SIM_TARIFF
      || this.item.type === RefType.COUNTRY_CODES;
  }

  /**
   * Получение признака необходимости отображения
   * строки для редактирования признака приостановки обслуживания
   */
  public get isShowSuspend() {
    return this.item.type === RefType.EQUIPMENT_STATE;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) { return; }

    this.crudService.addUpdate(
      this.item, CRUDEntityType.CRM_REF_ITEM
    ).subscribe(
      () => {
        this.result = true;
        this.close();
      },
      (error) => this.modalService.showError(error)
    );
  }
}
