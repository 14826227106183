import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IListItem } from '../../classes/IListItem';
import { correctDisableDatepicker } from '../../utils/intervals';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public from: Date;

  public to: Date;

  public isRange: boolean = false;

  public isTime: boolean = false;

  public periodTypes: IListItem<number>[];

  /** Ограничение прошлого периода */
  public maxDate?: Date;

  /** Ограничение будущего периода */
  public minDate?: Date;

  /** Признак добавления только начала интервала */
  public onlyFrom: boolean;

  constructor(
    public ref: DynamicDialogRef,
    public configDynamic: DynamicDialogConfig
  ) {
    this.periodTypes = [
      { id: 0, name: 'component.select-period.minutes' },
      { id: 1, name: 'component.select-period.hours' },
      { id: 2, name: 'component.select-period.days' }
    ]
  }

  ngOnInit() {
    this.to = this.configDynamic.data?.to;
    this.from = this.configDynamic.data?.from;
    this.isRange = this.configDynamic.data?.isRange;
    this.isTime = this.configDynamic.data?.isTime;
    this.minDate = this.configDynamic.data?.minDate;
    this.maxDate = this.configDynamic.data?.maxDate;
    this.onlyFrom = this.configDynamic.data?.onlyFrom;
  }

  /**
   * Установка периода на сегодняшний день
   */
  public today() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
  }

  /**
   * Установка периода на вчерашний день
   */
  public yesterday() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.maxDate, this.minDate);
  }

  /**
   * Установка периода на последнюю неделю
   */
  public week() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.maxDate, this.minDate);
  }

  /**
   * Установка периода на последний месяц
   */
  public month() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.maxDate, this.minDate);
  }

  /**
   * Конфирм
   */
  public confirm() {
    if (!this.isAllow) return;
    this.ref.close({ from: this.from, to: !this.onlyFrom ? this.to : null })
  }

  /**
   * Отмена
   */
  public cancel() {
    this.ref.close();
  }

  /**
   * Разрешение на сохранение
   */
  public get isAllow() {
    if (this.isRange) {
      if (this.onlyFrom) {
        return !!this.from;
      }

      return !!this.from && !!this.to
    }

    return !!this.from
  }

  onlyFromToggle() {
    this.onlyFrom = !this.onlyFrom;
  }
}
