import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {IGuestAccessItem} from '../../shared/guest-access/IGuestAccessItem';

import {ConfigService} from './config.service';
import {LoadingService} from './loading.service';
import {ToastService} from './toast.service';

/**
 * Сервис для работы с гостевым доступом
 */
@Injectable()
export class GuestAccessService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/guest-access`;

  /**
   * Конструктор
   * @param configService
   * @param http
   * @param loadingService
   * @param toastService - Сервис всплывающих сообщений
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) {
  }

  /**
   * Получение списка ссылок для гостевого доступа
   */
  public getList(): Observable<IGuestAccessItem[]> {
    const options = {headers: this.loadingService.waiterHeader}
    return this.http.get<IGuestAccessItem[]>(this.baseUrl, options)
      .pipe(catchError(() => {
        this.toastService.error('errors.get-list');
        return EMPTY;
      }));
  }

  /**
   * Создание новой ссылки
   * @param data
   */
  public createGuestAccess(data: IGuestAccessItem): Observable<boolean> {
    const options = {headers: this.loadingService.waiterHeader}
    return this.http.post<boolean>(this.baseUrl, data, options);
  }

  /**
   * Удаление новой ссылки
   * @param id
   */
  public deleteGuestAccess(id: string): Observable<string> {
    const options = {headers: this.loadingService.waiterHeader}
    return this.http.delete<string>(`${this.baseUrl}/${id}`, options)
  }
}
