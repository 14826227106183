/** Акции */
export enum Promotion {
  /** Акция с Неоматикой */
  NEOMATICA = 1
}

/**
 * Получение списка всех доступных акций
 */
export function getAllPromotions(): Promotion[] {
  return [Promotion.NEOMATICA];
}

/**
 * Получение наименования акции
 * @param promotion Акция
 */
export function getPromotionName(promotion: Promotion): string {
  switch (promotion) {
    case Promotion.NEOMATICA: return 'enums.crm.promotion.neomatica';
    default: return 'enums.crm.promotion.unknown';
  }
}
