import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { ClientService } from './client.service';
import { ConfigService } from './config.service';
import { StoreService } from './store.service';

@Injectable()
/**
 * Сервис настроек хоста
 */
export class HostConfigService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/host-config`;

  /**
   * Конфигурация хоста
   */
  public config: IHostConfig;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param title Сервис заголовока
   * @param translator Сервис i18n
   * @param configService
   * @param clientService
   * @param store
   */
  constructor(
    private http: HttpClient,
    private title: Title,
    private translator: TranslateService,
    private configService: ConfigService,
    private clientService: ClientService,
    private store: StoreService
) {
  }

  /**
   * Получение названия иконки
   */
  public get icon(): string {
    return this.config?.icon ?? 'default.png';
  }

  /**
   * Получение названия большой иконки
   */
  public get logo(): string {
    return this.config?.logo ?? 'default.png';
  }

  /**
   * Address of client documentation wiki getter
   */
  public get wikiUrl(): string {
    return this.config?.wikiUrl;
  }

  /**
   * hide copyright flag getter
   */
  public get hideCopyright(): boolean {
    return !!this.config?.hideCopyright;
  }

  /**
   * hide copyright flag getter
   */
  public get googleMapKey(): string {
    return this.config?.googleMapKey;
  }

  /**
   * hide copyright flag getter
   */
  public get yandexMapKey(): string {
    return this.config?.yandexMapKey;
  }

  /**
   * Загрузка конфигурации хоста
   */
  public load() {
    const params: HttpParams = new HttpParams()
      .append('host', location.host);
    this.http.get<IHostConfig>(this.baseUrl, { params })
      .subscribe(this.setConfig);
  }

  /**
   * Установка конфигурации хоста
   * @param config Конфигурация хоста
   */
  private setConfig = (config: IHostConfig) => {
    this.config = config;
    this.store.hostConfig = config;
    document.getElementById('favicon').setAttribute('href', `/assets/images/labels/${config.favicon}`)
    this.translator.get('ui.title')
      .subscribe(title => this.title.setTitle(config?.title ?? title));
  }
}

/**
 * Personalisation information by Origin
 */
export interface IHostConfig {

  /**
   * Favicon for page in browser
   */
  favicon?: string;

  /**
   * Title for page in browser
   */
  title?: string;

  /**
   * Icon (for navigation bar)
   */
  icon?: string;

  /**
   * Logo (used on login page)
   */
  logo?: string;

  /**
   * Main color (used in mobile applications)
   */
  color?: string;

  /**
   * Theme (used in mobile applications)
   */
  theme?: AppColorTheme;

  /**
   * Address of client documentation
   */
  wikiUrl?: string;

  /**
   * Address of API documentation
   */
  apiDocUrl?: string;

  /**
   * If set, hides copyright information from bottom line of tracking component
   */
  hideCopyright?: boolean;

  /**
   * Google map API key
   */
  googleMapKey?: string

  /**
   * Yandex map API key
   */
  yandexMapKey?: string
}

enum AppColorTheme {
  // noinspection JSUnusedGlobalSymbols
  UNDEFINED,
  BLUE,
  RED,
  YELLOW,
  GREEN,
  ORANGE
}
