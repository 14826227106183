import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { deepClone } from '../../../shared/CloneHelper';
import { FastPeriodType, getAllFastPeriodTypes, getFastPeriodTypeName } from '../../../shared/FastPeriodType';
import { ISimpleObject } from '../../../shared/ISimpleObject';
import { ISendReport } from '../../../shared/reports/ISendReport';
import { IChangeAccess } from '../../../shared/rights/IChangeAccess';
import {
  AccountRightType,
  getAllRights,
  getAllUnitRights,
  getRightTypeName,
  getUnitRightTypeName,
  RightType,
  UnitRightType
} from '../../../shared/rights/RightType';
import { ITask } from '../../../shared/tasks/ITask';
import { ISendFuelInfoTask } from '../../../shared/tasks/ITaskGeneric';
import { getAllTaskTypes, getTaskTypeName, TaskType } from '../../../shared/tasks/TaskType';
import { ITimeLimits, processingTimeLimits } from '../../../shared/time/ITimeLimits';
import { IChangeCounter } from '../../../shared/units/IChangeCounter';
import { DetailComponent } from '../../classes/DetailComponent';
import { DetailTab } from '../../classes/DetailTab';
import { ICheckedListItem } from '../../classes/ICheckedListItem';
import { IListItem } from '../../classes/IListItem';
import { AccountsService } from '../../services/accounts.service';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { UsersService } from '../../services/users.service';
import { localeSort } from '../../utils/sort';
import { SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Интерфейс компонента для редактирования задания
 */
interface ITaskComponent {
  /** Идентификатор задания */
  taskId?: string;
  /** Признак копирования задания */
  copy?: boolean;
}

/**
 * Компонент для редактирования задания
 */
@Component({
  selector: 'task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent
  extends DetailComponent<ITaskComponent>
  implements ITaskComponent {

  /** Идентификатор задания */
  public taskId?: string;

  /** Признак копирования задания */
  public copy?: boolean;

  /** Задание */
  public task: ITask;

  /** Список учетных записей для выбора */
  public accounts: IListItem<string>[] = [];

  /** Список типов заданий */
  public taskTypes: IListItem<TaskType>[] = [];

  /** Список доступных для выбора шаблонов отчетов */
  public reportTemplates: ISimpleObject[] = [];

  /** Список типов периодов формирования отчета */
  public fastPeriodTypes: IListItem<FastPeriodType>[] = [];

  /** Строка поиска по пользователям */
  public searchUser: string;

  /** Строка поиска по элементам задания */
  public searchItem: string;

  /** Список доступных для выбора пользователей */
  public users: ICheckedListItem<string>[] = [];

  /** Список настроек для прав доступа */
  public rightItems: IChangeRightItem[] = [];

  /** Список доступных для выбора объектов мониторинга */
  public units: IListItem<string>[] = [];

  /** Список доступных для выбора групп объектов мониторинга */
  public unitGroups: IListItem<string>[] = [];

  /** Список выбранных объектов/групп объектов */
  public selectedItems: IClientTaskItem[] = [];

  /** Ограничения времени */
  public timeLimits: ITimeLimits;

  /** Постфикс для копии */
  private copyPostfix = '';

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис работы с ДИУП
   * @param accountsService Сервис работы с учетными записями
   * @param usersService Сервис работы с пользователями
   * @param loadingService Сервис отображения процесса загрузки
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private accountsService: AccountsService,
    private usersService: UsersService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    translator: TranslateService
  ) {
    super(dialogService);

    translator.get('ui.copy2')
      .subscribe((x) => this.copyPostfix = x);

    this.isAddRegime = true;

    this.taskTypes = getAllTaskTypes()
      .map((type) => ({ id: type, name: getTaskTypeName(type) }));

    this.fastPeriodTypes = getAllFastPeriodTypes()
      .map((type) => ({ id: type, name: getFastPeriodTypeName(type) }));

    this.tabs[Tabs.Main] = new DetailTab('component.task.main');
    this.tabs[Tabs.Params] = new DetailTab('component.task.params');
    this.tabs[Tabs.Elements] = new DetailTab('component.task.elements');

    this.selectedTab = this.tabs[Tabs.Main];

    this.task = {
      scheduleAsPeriod: false,
      items: [],
      type: TaskType.CHANGE_ACCESS,
      changeAccess: { add: 0, del: 0, users: [] }
    } as ITask;

    this.loadUnitsAndGroups();
  }

  /**
   * Получение признака наличия лимитов времени
   */
  get isTimeLimits() {
    return !!this.timeLimits;
  }

  /**
   * Получение признака возможности сохранения задания
   */
  public get isCanSave() {
    const task = this.task;
    if (!task.name || task.name === '' || !this.selectedItems.length) {
      return false;
    }

    if (!task.schedule || task.schedule === '') {
      return false;
    }

    if (!task.scheduleAsPeriod && !task.schedule.match(/^([0-9]+:[0-9]{2} *)+$/i)) {
      return false;
    }

    if (task.scheduleAsPeriod && !task.schedule.match(/^[0-9]+:[0-9]{2}$/i)) {
      return false;
    }

    if (!task.accountId || !task.type) {
      return false;
    }

    if (task.active && !task.activationTime) {
      return false;
    }

    switch (task.type) {
      case TaskType.CHANGE_ACCESS:
        return this.checkChangeAccess(task.changeAccess);

      case TaskType.CHANGE_MH_COUNTER:
        return this.checkChangeCounter(task.changeMhCounter);

      case TaskType.CHANGE_MILEAGE_COUNTER:
        return this.checkChangeCounter(task.changeMileageCounter);

      case TaskType.SEND_FUEL_INFO:
        return this.checkSendFuelInfo(task.sendFuelInfo);

      case TaskType.SEND_REPORT:
        return this.checkSendReport(task.sendReport);
    }

    return false;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: ITaskComponent) {
    const action = data.taskId ? this.title = data.copy ? 'copy' : 'edit' : 'add';
    this.title = 'component.task.' + action + '-task-title';
    this.isAddRegime = action !== 'edit';

    if (!data.taskId) {
      this.afterTaskLoaded();
      return super.fillData(data);
    }

    this.loadingService.wrap(this.crudService.get(data.taskId, CRUDEntityType.TASK), true)
      .subscribe((task) => this.onTaskDataLoaded(task, data.copy));

    return super.fillData(data);
  }

  /**
   * Обработка изменения типа задания
   */
  public onTypeChanged() {
    switch (this.task.type) {
      case TaskType.CHANGE_ACCESS:
        this.task.changeAccess = { add: 0, del: 0, users: [] };
        this.task.changeMhCounter = null;
        this.task.changeMileageCounter = null;
        this.task.sendFuelInfo = null;
        this.task.sendReport = null;

        if (!this.users.length) {
          this.loadUsers();
        }
        this.fillRightItems();
        break;

      case TaskType.SEND_REPORT:
        this.task.sendReport = {
          emails: [],
          excel: false,
          pdf: false,
          periodType: FastPeriodType.TODAY,
          templateId: null,
          zip: false
        };

        this.task.changeAccess = null;
        this.task.changeMhCounter = null;
        this.task.changeMileageCounter = null;
        this.task.sendFuelInfo = null;

        if (!this.reportTemplates.length) {
          this.loadReportTemplates();
        }
        break;

      case TaskType.SEND_FUEL_INFO:
        this.task.changeAccess = null;
        this.task.changeMhCounter = null;
        this.task.changeMileageCounter = null;
        this.task.sendFuelInfo = { emails: [], fillings: true, thefts: true, level: true };
        this.task.sendReport = null;
        break;

      case TaskType.CHANGE_MILEAGE_COUNTER:
        this.task.changeAccess = null;
        this.task.changeMhCounter = null;
        this.task.changeMileageCounter = { addEvent: false, setNewValue: false, saveAsParameter: false };
        this.task.sendFuelInfo = null;
        this.task.sendReport = null;
        break;

      case TaskType.CHANGE_MH_COUNTER:
        this.task.changeAccess = null;
        this.task.changeMhCounter = { addEvent: false, setNewValue: false, saveAsParameter: false };
        this.task.changeMileageCounter = null;
        this.task.sendFuelInfo = null;
        this.task.sendReport = null;
        break;
    }
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onAccountChanged() {
    if (!this.task) {
      return;
    }

    this.reportTemplates = [];
    if (this.task.sendReport) {
      this.task.sendReport.templateId = null;
      this.loadReportTemplates();
    }
  }

  /**
   * Выбор объектов в задание
   */
  public selectUnits() {
    const selectedUnits = this.selectedItems.filter((si) => !si.group);
    const data = {
      items: this.units,
      selected: selectedUnits,
      title: 'component.task.select-objects',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const groups = this.selectedItems.filter((si) => si.group);
        this.selectedItems = [...result, ...groups]?.sort(localeSort);
      });
  }

  /**
   * Выбор групп объектов в задание
   */
  public selectUnitGroups() {
    const selectedUnitGroups = this.selectedItems.filter((si) => si.group);
    const data = {
      items: this.unitGroups,
      selected: selectedUnitGroups,
      title: 'component.task.select-object-groups',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const groups = result.map((ug) => ({ ...ug, group: true }));
        const units = this.selectedItems.filter((si) => !si.group);
        this.selectedItems = [...groups, ...units]?.sort(localeSort);
      });
  }

  /**
   * Удаление объекта/группы из списка элементов задания
   * @param item Удаляемый элемент
   */
  public deleteItem(item: IClientTaskItem) {
    const index = this.selectedItems.indexOf(item);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  /**
   * Обработка изменения выбранного типа периода формирования отчета
   */
  public onSendReportPeriodTypeChanged() {
    if (this.task && this.task.sendReport && this.task.sendReport.periodType !== FastPeriodType.OTHER) {
      this.task.sendReport.from = null;
      this.task.sendReport.to = null;
    }
  }

  /**
   * Обработка изменения типа графика задания
   */
  public onScheduleTypeChanged() {
    this.task.schedule = '';
  }

  /**
   * Добавление новой записи в список email
   * @param emails Список email, в который добавляется запись
   */
  public addEmail(emails: string[]) {
    emails.push('');
  }

  /**
   * Удаление email из списка
   * @param emails Список email, из которого удаляется запись
   * @param index Индекс удаляемой записи
   */
  public removeEmail(emails: string[], index: number) {
    emails.splice(index, 1);
  }

  // noinspection JSUnusedLocalSymbols
  /**
   * Это нужно чтобы нормально работал *ngFor для списка email
   */
  public trackByIndex(index: number, value: number) {
    return index;
  }

  /**
   * Переключение признака наличия лимитов времени
   */
  public toggleTimeLimits() {
    if (this.isTimeLimits) {
      this.timeLimits = undefined;
      return;
    }

    this.timeLimits = {
      day: {
        from: { h: 0, m: 0 },
        to: { h: 23, m: 59 }
      },
      week: [],
      month: []
    };
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.task.items = this.selectedItems.map(({ id, group }) => ({ id, group }));

    if (this.timeLimits) {
      this.task.timeLimits = processingTimeLimits(this.timeLimits);

      if (!this.task.timeLimits) {
        delete this.task.timeLimits;
      }

    } else if (this.task.timeLimits) {
      delete this.task.timeLimits;
    }

    switch (this.task.type) {
      case TaskType.CHANGE_ACCESS:
        this.task.changeAccess.users = this.users
          .filter((u) => u.checked)
          .map((x) => x.id);

        this.task.changeAccess.add = 0;
        this.task.changeAccess.del = 0;

        for (const rightItem of this.rightItems) {
          if (rightItem.value === ChangeRightValueType.ADD) {
            // tslint:disable-next-line:no-bitwise
            this.task.changeAccess.add |= rightItem.right;

          } else if (rightItem.value === ChangeRightValueType.DEL) {
            // tslint:disable-next-line:no-bitwise
            this.task.changeAccess.del |= rightItem.right;
          }
        }
        break;

      case TaskType.CHANGE_MH_COUNTER:
        if (!this.task.changeMhCounter.setNewValue) {
          delete this.task.changeMhCounter.newValue;
        }

        if (!this.task.changeMhCounter.saveAsParameter) {
          delete this.task.changeMhCounter.parameterName;
        }
        break;

      case TaskType.CHANGE_MILEAGE_COUNTER:
        if (!this.task.changeMileageCounter.setNewValue) {
          delete this.task.changeMileageCounter.newValue;
        }

        if (!this.task.changeMileageCounter.saveAsParameter) {
          delete this.task.changeMileageCounter.parameterName;
        }
        break;

      case TaskType.SEND_FUEL_INFO:
        this.task.sendFuelInfo.emails = this.task.sendFuelInfo.emails.filter((e) => e && e !== '');
        break;

      case TaskType.SEND_REPORT:
        this.task.sendReport.emails = this.task.sendReport.emails.filter((e) => e && e !== '');
        if (this.task.sendReport.periodType !== FastPeriodType.OTHER) {
          delete this.task.sendReport.from;
          delete this.task.sendReport.to;
        }
        break;
    }

    this.loadingService.wrap(this.crudService.addUpdate(this.task, CRUDEntityType.TASK), true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Проверка на правильность заполнения данных задания типа "Изменение доступа"
   * @param changeAccess Данные задания типа "Изменение доступа"
   */
  private checkChangeAccess(changeAccess: IChangeAccess<string>) {
    if (!changeAccess) {
      return false;
    }

    if (!this.users.some((u) => u.checked)) {
      return false;
    }

    return this.rightItems.some((r) => r.value !== ChangeRightValueType.SKIP);
  }

  /**
   * Проверка на правильность заполнения данных задания типа "Учет моточасов/пробега"
   * @param changeCounter Данные задания типа "Учет моточасов/пробега"
   */
  private checkChangeCounter(changeCounter: IChangeCounter) {
    if (!changeCounter) {
      return false;
    }

    if (!changeCounter.setNewValue && !changeCounter.saveAsParameter && !changeCounter.addEvent) {
      return false;
    }

    return !changeCounter.saveAsParameter || !!changeCounter.parameterName;
  }

  /**
   * Проверка на правильность заполнения данных задания "Отправка данных по топливу"
   * @param sendFuelInfo Данные задания типа "Отправка данных по топливу"
   */
  private checkSendFuelInfo(sendFuelInfo: ISendFuelInfoTask) {
    if (!sendFuelInfo) {
      return false;
    }

    if (!sendFuelInfo.emails || !sendFuelInfo.emails.some((e) => e && e !== '')) {
      return false;
    }

    return sendFuelInfo.fillings || sendFuelInfo.level || sendFuelInfo.thefts;
  }

  /**
   * Проверка на правильность заполнения данных задания "Отправка отчета"
   * @param sendReport Данные задания "Отправка отчета"
   */
  private checkSendReport(sendReport: ISendReport<string>) {
    if (!sendReport) {
      return false;
    }

    if (!sendReport.templateId || sendReport.templateId === '') {
      return false;
    }

    if (!sendReport.periodType) {
      return false;
    }

    if (sendReport.periodType === FastPeriodType.OTHER && (!sendReport.from || !sendReport.to || sendReport.from > sendReport.to)) {
      return false;
    }

    if (!sendReport.emails || !sendReport.emails.some((e) => e && e !== '')) {
      return false;
    }

    return sendReport.excel || sendReport.pdf;

  }

  /**
   * Обработка после загрузки задания с сервера
   * @param task Задание
   * @param copy Признак копирования
   */
  private onTaskDataLoaded(task: ITask, copy: boolean) {
    this.task = task;

    if (copy) {
      this.task.name += this.copyPostfix;
      delete this.task.id;
    }

    if (this.units.length) {
      this.setSelectedUnits();
    }

    if (this.unitGroups.length) {
      this.setSelectedUnitGroups();
    }

    this.selectedItems?.sort(localeSort);
    if (this.users.length) {
      this.setSelectedUsers();
    }

    if (this.task.timeLimits) {
      if (!this.task.timeLimits.day) {
        this.task.timeLimits.day = {
          from: { h: 0, m: 0 },
          to: { h: 23, m: 59 }
        };

      } else {
        if (!this.task.timeLimits.day.from) {
          this.task.timeLimits.day.from = { h: 0, m: 0 };
        }

        if (!this.task.timeLimits.day.to) {
          this.task.timeLimits.day.to = { h: 23, m: 59 };
        }
      }

      this.timeLimits = deepClone(this.task.timeLimits);
    }

    this.afterTaskLoaded();
  }

  /**
   * Загрузка списка учетных записей
   */
  private loadAccounts() {
    if (this.task.id) {
      this.accounts.push({
        id: this.task.accountId,
        name: this.task.account
      });
      return;
    }

    this.loadingService.wrap(this.accountsService.getWithRight(AccountRightType.CHANGE_TASKS), true)
      .subscribe((accounts) => {
        this.accounts = [...accounts?.sort(localeSort)];

        if (!this.task.accountId && this.accounts.length) {
          this.task.accountId = accounts?.shift()?.id;
        }
      });
  }

  /**
   * Загрузка шаблонов отчетов
   */
  private loadReportTemplates() {
    if (!this.task.accountId) {
      return;
    }

    this.loadingService.wrap(this.accountsService.getAccountReportTemplates(this.task.accountId), true)
      .subscribe((templates) => this.reportTemplates = templates);
  }

  /** Загрузка пользователей */
  private loadUsers() {
    this.loadingService.wrap(this.usersService.getWithRight(RightType.CHANGE_ACCESS), true)
      .subscribe((users) => {
        this.users = users;
        this.setSelectedUsers();
      });
  }

  /** Заполнение списка элементов прав */
  private fillRightItems() {
    this.rightItems = getAllRights().map((right) => ({
      right,
      name: getRightTypeName(right),
      value: this.getRightItemValue(right)
    }));

    this.rightItems.push(...getAllUnitRights().map((right) => ({
      right,
      name: getUnitRightTypeName(right),
      value: this.getRightItemValue(right)
    })));
  }

  /**
   * Получение значения элемента права
   * @param right Право доступа
   */
  private getRightItemValue(right: RightType | UnitRightType) {
    // tslint:disable:no-bitwise
    const isAdd = (this.task.changeAccess.add & right) > 0;
    const isDel = (this.task.changeAccess.del & right) > 0;
    // tslint:enable:no-bitwise

    if (isAdd) {
      return ChangeRightValueType.ADD;
    }

    if (isDel) {
      return ChangeRightValueType.DEL;
    }

    return ChangeRightValueType.SKIP;
  }

  /** Обработка после загрузки задания (а также при создании нового задания) */
  private afterTaskLoaded() {
    this.loadAccounts();

    if (this.task.type === TaskType.CHANGE_ACCESS) {
      this.loadUsers();
      this.fillRightItems();

    } else if (this.task.type === TaskType.SEND_REPORT) {
      this.loadReportTemplates();
    }
  }

  /**
   * Загрузка списков объектов и групп объектов мониторинга
   */
  private loadUnitsAndGroups() {
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.units = units;
        this.setSelectedUnits();
        this.selectedItems?.sort(localeSort);
      });

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT_GROUP), true)
      .subscribe((unitGroups) => {
        this.unitGroups = unitGroups;
        this.setSelectedUnitGroups();
        this.selectedItems?.sort(localeSort);
      });
  }

  /**
   * Заполнение списка выбранных объектов
   */
  private setSelectedUnits() {
    if (!this.task.items.length) {
      return;
    }

    const selectedUnits = this.units
      .filter((unit) => this.task.items
        .some((nu) => !nu.group && nu.id === unit.id));
    this.selectedItems.push(...selectedUnits);
  }

  /**
   * Заполнение списка выбранных групп объектов
   */
  private setSelectedUnitGroups() {
    if (!this.task.items.length) {
      return;
    }

    const selectedUnitGroups = this.unitGroups
      .filter((unitGroup) => this.task.items
        .some((nu) => nu.group && nu.id === unitGroup.id))
      .map((ug) => ({ ...ug, group: true }));
    this.selectedItems.push(...selectedUnitGroups);
  }

  private setSelectedUsers() {
    if (!this.task?.changeAccess?.users?.length) {
      return;
    }

    for (const user of this.users) {
      user.checked = this.task.changeAccess.users.includes(user.id);
    }
  }
}

/** Элемент права */
interface IChangeRightItem {
  /** Право доступа */
  right: RightType | UnitRightType;
  /** Наименование права доступа */
  name: string;
  /** Значение */
  value: ChangeRightValueType;
}

/** Значение элемента права */
enum ChangeRightValueType {
  /** Пропустить */
  SKIP,
  /** Добавить */
  ADD,
  /** Удалить */
  DEL
}

/** Элемент задания для использования на стороне клиента */
interface IClientTaskItem extends IListItem<string> {
  /** Признак группы */
  group?: boolean;
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Params,
  Elements
}
