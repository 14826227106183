import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BlockableHistoryEntity,
  HistoryEntityType,
  HistoryType,
  IBlockPeriod,
  IGetBlockingOptions,
  IHistory
} from '../../shared/crm/hist';
import { HistoryItemType, IClientHistory } from '../classes/IClientHistory';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с историей
 */
@Injectable()
export class HistoryService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/crm/history`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Получение истории по сущности
   * @param id Идентификатор сущности
   * @param type Тип сущности
   */
  public get(id: string, type: HistoryEntityType): Observable<IHistory[]> {
    const url = `${this.baseUrl}/${type}/${id}`;
    return this.http.get<IHistory[]>(url);
  }

  /**
   * Получение периодов блокировки
   * @param id Идентификатор сущности
   * @param type Тип сущности
   * @param options Дополнительные опции для получения списка блокировок
   */
  public getBlocking(
    id: string,
    type: BlockableHistoryEntity,
    options: IGetBlockingOptions): Observable<IBlockPeriod[]> {
    const url = `${this.baseUrl}/blocking/${type}/${id}`;
    return this.http.post<IBlockPeriod[]>(url, options);
  }

  /**
   * Снятие блокировки за период
   * @param id Идентификатор сушности
   * @param type Тип сущности
   * @param period Период блокировки
   */
  public unblock(id: string, type: BlockableHistoryEntity, period: IBlockPeriod): Observable<string> {
    const url = `${this.baseUrl}/unblock/${type}/${id}`;
    return this.http.post<string>(url, period);
  }

  /**
   * Обработка истории (получение доп описание)
   * @param history История приходящая с сервера
   * @param type Тип истории
   */
  public processHistory(history: IHistory[], type: number): IClientHistory[] {
    return history
      ?.map((hist) => ({
        ...hist,
        ...this.getItem(hist, type),
        descr: this.getHistoryDescr(hist, type)
      }))
      ?.sort((a, b) => b.date - a.date);
  }

  /**
   * Получение изменившегося элемента в записи истории
   * @param hist Запись истории
   * @param type Тип истории
   */
  private getItem(hist: IHistory, type: number): { item: IListItem<string>, itemType: HistoryItemType } {
    switch (hist.type) {
      case HistoryType.UNIT_ACCOUNT:
        return type === HistoryEntityType.ACCOUNT
          ? { item: hist.unit, itemType: 'unit' }
          : { item: hist.client, itemType: 'account' };

      case HistoryType.SIM_ACCOUNT:
        return type === HistoryEntityType.SIM
          ? { item: hist.client, itemType: 'account' }
          : { item: hist.sim, itemType: 'sim' };

      case HistoryType.EQUIPMENT_STATE:
        return { item: hist.state, itemType: 'state' };

      case HistoryType.EQUIPMENT_UNIT:
        return type === HistoryEntityType.EQUIPMENT
          ? { item: hist.unit, itemType: 'unit' }
          : { item: hist.equipment, itemType: 'equipment' };

      case HistoryType.EQUIPMENT_SIM:
        return type === HistoryEntityType.EQUIPMENT
          ? { item: hist.sim, itemType: 'sim' }
          : { item: hist.equipment, itemType: 'equipment' };

      case HistoryType.EQUIPMENT_ACCOUNT:
        return type === HistoryEntityType.EQUIPMENT
          ? { item: hist.client, itemType: 'account' }
          : { item: hist.equipment, itemType: 'equipment' };

      case HistoryType.UNIT_BLOCK:
        return {
          item: {
            id: null,
            name: hist.isRemove
              ? 'component.crm.history.active-m'
              : 'component.crm.history.blocked-m'
          },
          itemType: 'block'
        };

      case HistoryType.ACCOUNT_BLOCK:
        return { item: hist.changeBlockedClient, itemType: 'accountBlock' };

      case HistoryType.TARIFF_ACCOUNT:
        return { item: hist.tariff, itemType: 'tariff' };

      case HistoryType.CHANGE_PASSWORD:
        return hist.changePasswordUser.id === hist.user.id
          ? { item: { id: null, name: null }, itemType: 'changePassword' }
          : { item: hist.changePasswordUser, itemType: 'changePassword' };

      case HistoryType.UNIT_RETRANSLATOR:
        return type === HistoryEntityType.RETRANSLATOR
          ? { item: hist.unit, itemType: 'unit' }
          : { item: hist.retranslator, itemType: 'retranslator' };

      case HistoryType.TRIAL_OPERATION:
        return {
          item: {
            id: null,
            name: hist.isRemove
              ? 'component.crm.history.unset-trial-operation'
              : 'component.crm.history.set-trial-operation'
          },
          itemType: 'trialOperation'
        };

      case HistoryType.RETRANSLATOR_CREATE:
        return {
          item: {
            id: null,
            name: hist.isRemove
              ? 'component.crm.history.unset-retranslator-create'
              : 'component.crm.history.set-retranslator-create'
          },
          itemType: 'retranslator'
        };

      case HistoryType.RETRANSLATOR_ACTIVATE:
        return {
          item: {
            id: null,
            name: hist.isRemove
              ? 'component.crm.history.unset-retranslator-activate'
              : 'component.crm.history.set-retranslator-activate'
          },
          itemType: 'retranslator'
        };

      default:
        return null;
    }
  }

  /**
   * Получение описания изменения
   * @param hist Запись истории
   * @param type
   */
  private getHistoryDescr(hist: IHistory, type: number) {
    switch (type) {
      case HistoryEntityType.EQUIPMENT:
        return this.getEquipmentHistoryDescr(hist);

      case HistoryEntityType.ACCOUNT:
        return this.getAccountHistoryDescr(hist);

      case HistoryEntityType.UNIT:
        return this.getUnitHistoryDescr(hist);

      case HistoryEntityType.SIM:
        return this.getSimHistoryDescr(hist);

      case HistoryEntityType.CHANGE_PASSWORD:
        return this.getChangePasswordHistoryDescr(hist);

      case HistoryEntityType.RETRANSLATOR:
        return this.getRetranslatorDescr(hist);

      default:
        return '';
    }
  }

  /**
   * Получение описания изменения для истории оборудования
   * @param hist Запись истории
   */
  private getEquipmentHistoryDescr(hist: IHistory) {
    switch (hist.type) {
      case HistoryType.EQUIPMENT_STATE:
        return 'component.crm.history.status-changed';

      case HistoryType.EQUIPMENT_UNIT:
        return !hist.isRemove
          ? 'component.crm.history.set-equip-unit'
          : 'component.crm.history.unset-equip-unit';

      case HistoryType.EQUIPMENT_SIM:
        return !hist.isRemove
          ? 'component.crm.history.set-equip-sim'
          : 'component.crm.history.unset-equip-sim';

      case HistoryType.EQUIPMENT_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-equip-client'
          : 'component.crm.history.unset-equip-client';

      case HistoryType.UNIT_RETRANSLATOR:
        return !hist.isRemove
          ? 'component.crm.history.set-unit-retranslator'
          : 'component.crm.history.unset-unit-retranslator';

      default:
        return '';
    }
  }

  /**
   * Получение описания изменения для истории учетной записи
   * @param hist Запись истории
   */
  private getAccountHistoryDescr(hist: IHistory) {
    switch (hist.type) {
      case HistoryType.EQUIPMENT_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-client-equip'
          : 'component.crm.history.unset-client-equip';

      case HistoryType.UNIT_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-client-unit'
          : 'component.crm.history.unset-client-unit';

      case HistoryType.ACCOUNT_BLOCK:
        return !hist.isRemove
          ? 'component.crm.history.set-account-blocked'
          : 'component.crm.history.unset-account-blocked';

      case HistoryType.SIM_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-client-sim'
          : 'component.crm.history.unset-client-sim';

      case HistoryType.TARIFF_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-client-tariff'
          : 'component.crm.history.unset-client-tariff';

      default:
        return '';
    }
  }

  /**
   * Получение описания изменения для истории ТС
   * @param hist Запись истории
   */
  private getUnitHistoryDescr(hist: IHistory) {
    switch (hist.type) {
      case HistoryType.EQUIPMENT_UNIT:
        return !hist.isRemove
          ? 'component.crm.history.set-unit-equip'
          : 'component.crm.history.unset-unit-equip';

      case HistoryType.UNIT_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-unit-client'
          : 'component.crm.history.unset-unit-client';

      case HistoryType.UNIT_BLOCK:
        return 'component.crm.history.status-changed';

      case HistoryType.UNIT_RETRANSLATOR:
        return !hist.isRemove
          ? 'component.crm.history.set-unit-retranslator'
          : 'component.crm.history.unset-unit-retranslator';

      case HistoryType.TRIAL_OPERATION:
        return 'component.crm.history.status-changed';

      default:
        return '';
    }
  }

  /**
   * Получение описания изменения для истории SIM-карты
   * @param hist Запись истории
   */
  private getSimHistoryDescr(hist: IHistory) {
    switch (hist.type) {
      case HistoryType.EQUIPMENT_SIM:
        return !hist.isRemove
          ? 'component.crm.history.set-sim-equip'
          : 'component.crm.history.unset-sim-equip';

      case HistoryType.SIM_ACCOUNT:
        return !hist.isRemove
          ? 'component.crm.history.set-sim-client'
          : 'component.crm.history.unset-sim-client';

      default:
        return '';
    }
  }

  /**
   * Получение описания для истории изменения пароля
   * @param hist
   */
  private getChangePasswordHistoryDescr(hist: IHistory) {
    return hist.changePasswordUser.id === hist.user.id
      ? 'component.crm.history.change-password-current'
      : 'component.crm.history.change-password';
  }

  /**
   * Получение описания изменения для истории Рестрансляторов
   * @param hist Запись истории
   */
  private getRetranslatorDescr(hist: IHistory) {
    switch (hist.type) {
      case HistoryType.UNIT_RETRANSLATOR:
        return !hist.isRemove
          ? 'component.crm.history.set-retranslator-unit'
          : 'component.crm.history.unset-retranslator-unit';

      case HistoryType.RETRANSLATOR_ACTIVATE:
        return 'component.crm.history.status-changed';

      case HistoryType.RETRANSLATOR_CREATE:
        return 'component.crm.history.status-changed';

      default:
        return '';
    }
  }
}
