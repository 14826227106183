import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import {
  DrivingQualityType,
  getDrivingQualityTypeName
} from '../../../../shared/driving-quality/DrivingQualityType';
import { IDrivingQuality } from '../../../../shared/driving-quality/IDrivingQuality';
import { UnitRightType } from '../../../../shared/rights/RightType';
import { IUnit } from '../../../../shared/units/IUnit';
import { ModalService } from '../../../services/modal.service';
import { ModalResult } from '../../modal/modal.component';

import { UnitDrivingQualityCriteriaComponent } from './unit.driving-quality-criteria.component';

/** Компонент для отображения списка критериев качества вождения */
@Component({
  selector: 'unit-driving-quality',
  templateUrl: './unit.driving-quality.component.html'
})
export class UnitDrivingQualityComponent implements OnInit {

  /** Информация об объекте мониторинга */
  @Input() public unit: IUnit;

  /** Признак режима добавления объекта мониторинга */
  @Input() public isAddRegime: boolean;

  /**
   * Конструктор
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   */
  public constructor(
    private modalService: ModalService,
    private dialogService: DialogService,
    private translator: TranslateService
  ) {
  }

  /** Получение признака возможности изменения критериев качества вождения */
  public get isCanChange() {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.unit.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING);
  }

  /** Добавление критерия */
  public addCriteria() {
    const data = {
      originalCriteria: null,
      unit: this.unit,
      isAddRegime: this.isAddRegime
    };

    this.dialogService.addDialog(UnitDrivingQualityCriteriaComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.unit.drivingQuality.push(result));
  }

  /**
   * Изменение критерия
   * @param drivingQuality Критерий качества вождения
   */
  public updateCriteria(drivingQuality: IDrivingQuality) {
    const data = {
      originalCriteria: drivingQuality,
      unit: this.unit,
      isAddRegime: this.isAddRegime
    };

    this.dialogService.addDialog(UnitDrivingQualityCriteriaComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const index = this.unit.drivingQuality.indexOf(drivingQuality);
        if (index !== -1) {
          this.unit.drivingQuality[index] = result;
        }
      });
  }

  /**
   * Удаление критерия
   * @param drivingQuality Критерий качества вождения
   */
  public deleteCriteria(drivingQuality: IDrivingQuality) {
    const confirm = this.translator.instant(
      'component.unit.driving-quality.confirm',
      { val: drivingQuality.name });

      this.modalService.showQuestion(confirm)
        .pipe(filter((result) => result === ModalResult.YES))
        .subscribe(() => {
          const index = this.unit.drivingQuality.indexOf(drivingQuality);
          if (index !== -1) {
            this.unit.drivingQuality.splice(index, 1);
          }
        });
  }

  /**
   * Получение наименования типа критерия качества вождения
   * @param type Тип критерия качества вождения
   */
  public getTypeName(type: DrivingQualityType) {
    return getDrivingQualityTypeName(type);
  }

  /** Обработки после инициалиации компонента */
  public ngOnInit() {
    if (!this.unit.drivingQuality) {
      this.unit.drivingQuality = [];
    }
  }
}
