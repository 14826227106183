import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IYandexTransportRepeater } from '../../shared/units/IYandexTransportRepeater';

import { ConfigService } from './config.service';
import { ToastService } from './toast.service';

/**
 * Yandex transport repeaters service
 */
@Injectable()
export class YandexTransportRepeaterService {

  /**
   * Base API URL
   */
  private readonly baseUrl = `${this.configService.url}/yt-repeaters`;

  /**
   * Instantiates {@link YandexTransportRepeaterService}
   * @param configService Injects {@link ConfigService}
   * @param http Injects {@link HttpClient}
   * @param toastService Injects {@link ToastService}
   */
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private toastService: ToastService
  ) {
  }

  /**
   * Reads Yandex transport repeater
   * @param unitId Unit id
   */
  public read(unitId: string): Observable<IYandexTransportRepeater> {
    const params = new HttpParams().append('unitId', unitId);
    return this.http.get<IYandexTransportRepeater>(this.baseUrl, { params })
      .pipe(catchError(() => {
        this.toastService.error('component.yandex-transport.error-read');
        return EMPTY;
      }));
  }

  /**
   * Creates Yandex transport repeater
   * @param unitId Unit id
   * @param ytr Yandex transport repeater
   */
  public upsert(unitId: string, ytr: IYandexTransportRepeater): Observable<IYandexTransportRepeater> {
    const params = new HttpParams().append('unitId', unitId);
    return this.http.post<IYandexTransportRepeater>(this.baseUrl, ytr, { params })
      .pipe(catchError(() => {
        this.toastService.error('component.yandex-transport.error-upsert');
        return EMPTY;
      }));
  }
}
