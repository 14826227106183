import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IAddressGeocodingResult } from '../../shared/geocoding/IAddressGeocodingResult';
import { ICoord } from '../../shared/ICoord';

import { ConfigService } from './config.service';

/**
 * Сервис геокодирования
 */
@Injectable()
export class GeocoderService {

  /** Базовая часть url */
  private readonly baseUrl = `${this.configService.url}`

  /**
   * Словарь кешированных адресов по объектам
   */
  private cache: Dict<ICachedAddress> = {};

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param translator Сервис для перевода
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private translator: TranslateService,
    private configService: ConfigService
  ) {}

  /**
   * Получение адреса
   * @param unitId Идентификатор объекта мониторинга (если нужно использовать кеш)
   * @param lat Широта
   * @param lon Долгота
   */
  public getAddress(unitId: string, lat: number, lon: number) {
    return new Observable<string>((subscribe) => {
      if (unitId) {
        const cached = this.cache[unitId];

        if (cached && cached.coords.lt === lat && cached.coords.ln === lon) {
          subscribe.next(cached.address);
          subscribe.complete();
          return;
        }
      }

      const time = Date.now();

      this.translator.get([
        'services.geo-coder.get',
        'services.geo-coder.error'
      ]).subscribe((x) => {
        subscribe.next(x['services.geo-coder.get']);

        this.getAddressFromApi(lat, lon).subscribe(
          (result) => {
            if (unitId && result.found) {
              const cached = this.cache[unitId];

              if (!cached || cached.time < time) {
                this.cache[unitId] = {
                  address: result.address, coords: {lt: lat, ln: lon}, time
                };
              }
            }

            subscribe.next(result.found ? result.address : x['services.geo-coder.error']);
            subscribe.complete();
          },
          () => {
            subscribe.next(x['services.geo-coder.error']);
            subscribe.complete();
          }
        );
      });
    });
  }

  /**
   * Получение адреса из API
   * @param lat Широта
   * @param lon Долгота
   */
  private getAddressFromApi(lat: number, lon: number): Observable<IAddressGeocodingResult> {
    const url = `${this.baseUrl}/geocoder/address`;
    const options = { params: { lat: `${lat}`, lon: `${lon}` } };
    return this.http.get<IAddressGeocodingResult>(url, options);
  }
}

/**
 * Кешированная информация по адресу
 */
interface ICachedAddress {
  /** Строка адреса */
  address: string;
  /** Координаты */
  coords: ICoord;
  /** Время, когда был определен адрес */
  time: number;
}
