import { Timezone } from '../time/timezones';

import { Locale } from './Locale';

/**
 * Настройки пользователя
 */
export interface IUserSettings {
  /** Язык */
  locale: Locale;
  /** Часовой пояс */
  timezone: Timezone;
  /** Настройки отображения дополнительной информации по ТС */
  unitDetail?: IUserUnitDetail;
  /** Признак отображения наименований ТС на карте */
  showUnitNames: boolean;
  /** Признак отображения наименований геозон на карте */
  showGeozoneNames: boolean;
  /** Признак отображения имен водителей на карте */
  showDriverNames: boolean;
  /** Признак отображения имен прицепов на карте */
  showTrailerNames: boolean;
  /** Признак отображения удаленных объектов */
  showDeletedObjects: boolean;
  /** Период отображения истории по юзеру от */
  userPointsPeriodFrom?: Date;
  /** Период отображения истории по юзеру до */
  userPointsPeriodTo?: Date;
}

/**
 * Настройки отображения дополнительной информации по ТС
 */
export interface IUserUnitDetail {
  /** Последнее сообщение */
  time: IUserUnitDetailItem;
  /** Местоположение */
  address: IUserUnitDetailItem;
  /** Геозоны */
  geozones: IUserUnitDetailItem;
  /** Водители */
  drivers: IUserUnitDetailItem;
  /** Прицепы */
  trailers: IUserUnitDetailItem;
  /** Скорость */
  speed: IUserUnitDetailItem;
  /** Счетчики */
  counters: IUserUnitDetailItem;
  /** Настройки подключения */
  connection: IUserUnitDetailItem;
  /** Датчики */
  sensors: IUserUnitDetailItem;
  /** Параметры */
  params: IUserUnitDetailItem;
  /** Произвольные поля */
  arbitrary: IUserUnitDetailItem;
  /** Техобслуживание */
  to: IUserUnitDetailItem;
}

/**
 * Настройка отображения детальной информации ТС по одному элементу
 */
export interface IUserUnitDetailItem {
  /** Признак отображения на карте */
  map: boolean;
  /** Признак отображения в слежении */
  tracking: boolean;
}

/**
 * Получение настроек пользователя по умолчанию
 */
export function getDefaultUserSettings(): IUserSettings {
  return {
    showDeletedObjects: true,
    showUnitNames: true,
    showGeozoneNames: true,
    showDriverNames: true,
    showTrailerNames: true,
    locale: Locale.RU,
    timezone: Timezone.SAMT,
    unitDetail: {
      address: { map: true, tracking: true },
      counters: { map: true, tracking: true },
      geozones: { map: true, tracking: true },
      drivers: { map: true, tracking: true },
      trailers: { map: true, tracking: true },
      sensors: { map: true, tracking: true },
      speed: { map: true, tracking: true },
      time: { map: true, tracking: true },
      arbitrary: null,
      connection: null,
      params: null,
      to: null
    }
  };
}
