/**
 * Тип графика по времени
 */
export enum ReportChartByTimeType {
  /** Скорость */
  SPEED = 1,
  /** Пробег */
  MILEAGE,
  /** Пробег в поездках */
  MILEAGE_CORRECTED,
  /** Зажигание */
  IGNITION,
  /** Произвольные цифровые датчики */
  ARBITRARY_DIGITAL,
  /** Напряжение */
  VOLTAGE,
  /** Вес */
  WEIGHT,
  /** Температура */
  TEMPERATURE,
  /** Обороты двигателя */
  ENGINE_SPEED,
  /** Моточасы */
  MH,
  /** Уровень топлива */
  FUEL_LEVEL,
  /** Расход топлива по ИДРТ */
  FUEL_RATE_IMP,
  /** Расход топлива по ДАРТ */
  FUEL_RATE_ABS,
  /** Расход топлива по ДМРТ */
  FUEL_RATE_INS,
  /** Расход топлива по ДУТ */
  FUEL_RATE_DUT,
  /** Счетчики */
  COUNTER,
  /** Произвольные датчики */
  ARBITRARY,
  /** Потрачено по ИДРТ */
  FUEL_SPENT_IMP,
  /** Потрачено по ДАРТ */
  FUEL_SPENT_ABS,
  /** Потрачено по ДМРТ */
  FUEL_SPENT_INS,
  /** Потрачено по ДУТ */
  FUEL_SPENT_DUT
}

/**
 * Получение списка всех типов графиков по времени
 */
export function getAllReportChartByTimeTypes(): ReportChartByTimeType[] {
  const result: ReportChartByTimeType[] = [];
  for (let i = 1; i <= 21; i++) {
    result.push(i as ReportChartByTimeType);
  }
  return result;
}

/**
 * Получение наименования типа графика по времени
 * @param type Тип графика по времени
 */
export function getReportChartByTimeTypeName(type: ReportChartByTimeType): string {
  switch (type) {
    case ReportChartByTimeType.SPEED: return 'enums.reports.charts.by-time-type.speed';
    case ReportChartByTimeType.MILEAGE: return 'enums.reports.charts.by-time-type.mileage';
    case ReportChartByTimeType.MILEAGE_CORRECTED: return 'enums.reports.charts.by-time-type.mileage-corrected';
    case ReportChartByTimeType.IGNITION: return 'enums.reports.charts.by-time-type.ignition';
    case ReportChartByTimeType.ARBITRARY_DIGITAL: return 'enums.reports.charts.by-time-type.arbitrary-digital';
    case ReportChartByTimeType.VOLTAGE: return 'enums.reports.charts.by-time-type.voltage';
    case ReportChartByTimeType.WEIGHT: return 'enums.reports.charts.by-time-type.weight';
    case ReportChartByTimeType.TEMPERATURE: return 'enums.reports.charts.by-time-type.temperature';
    case ReportChartByTimeType.ENGINE_SPEED: return 'enums.reports.charts.by-time-type.rpm';
    case ReportChartByTimeType.MH: return 'enums.reports.charts.by-time-type.mh';
    case ReportChartByTimeType.FUEL_LEVEL: return 'enums.reports.charts.by-time-type.fuel';
    case ReportChartByTimeType.FUEL_RATE_IMP: return 'enums.reports.charts.by-time-type.fuel-idrt';
    case ReportChartByTimeType.FUEL_RATE_ABS: return 'enums.reports.charts.by-time-type.fuel-dart';
    case ReportChartByTimeType.FUEL_RATE_INS: return 'enums.reports.charts.by-time-type.fuel-dmrt';
    case ReportChartByTimeType.FUEL_RATE_DUT: return 'enums.reports.charts.by-time-type.fuel-dut';
    case ReportChartByTimeType.FUEL_SPENT_IMP: return 'enums.reports.charts.by-time-type.usage-idrt';
    case ReportChartByTimeType.FUEL_SPENT_ABS: return 'enums.reports.charts.by-time-type.usage-dart';
    case ReportChartByTimeType.FUEL_SPENT_INS: return 'enums.reports.charts.by-time-type.usage-dmrt';
    case ReportChartByTimeType.FUEL_SPENT_DUT: return 'enums.reports.charts.by-time-type.usage-dut';
    case ReportChartByTimeType.COUNTER: return 'enums.reports.charts.by-time-type.counters';
    case ReportChartByTimeType.ARBITRARY: return 'enums.reports.charts.by-time-type.arbitrary';
    default: return 'enums.reports.charts.by-time-type.unknown';
  }
}
