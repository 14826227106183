/**
 * Типы группировки строк таблиц отчетов
 */
export enum ReportTableGroupType {
  /** Год */
  YEAR = 1,
  /** Месяц */
  MONTH,
  /** Неделя */
  WEEK,
  /** День недели */
  DAY_OF_THE_WEEK,
  /** Число */
  DAY_OF_THE_MONTH,
  /** Дата */
  DATE,
  /** Смена */
  SHIFT,
  /** Сезон */
  SEASON,
  /** Длительность */
  DURATION
}

/**
 * Получение списка всех типов группировки строк таблиц отчетов
 */
export function getAllReportTableGroupTypes() {
  const result: ReportTableGroupType[] = [];
  for (let i = 1; i <= 9; i++) {
    result.push(i as ReportTableGroupType);
  }
  return result;
}

/**
 * Получение наименования типа группировки строк таблиц отчетов
 * @param type Тип группировки строк таблиц отчетов
 */
export function getReportTableGroupTypeName(type: ReportTableGroupType) {
  switch (type) {
    case ReportTableGroupType.YEAR: return 'enums.reports.tables.group-type.year';
    case ReportTableGroupType.MONTH: return 'enums.reports.tables.group-type.month';
    case ReportTableGroupType.WEEK: return 'enums.reports.tables.group-type.week';
    case ReportTableGroupType.DAY_OF_THE_WEEK: return 'enums.reports.tables.group-type.day-of-week';
    case ReportTableGroupType.DAY_OF_THE_MONTH: return 'enums.reports.tables.group-type.day';
    case ReportTableGroupType.DATE: return 'enums.reports.tables.group-type.date';
    case ReportTableGroupType.SHIFT: return 'enums.reports.tables.group-type.shift';
    case ReportTableGroupType.SEASON: return 'enums.reports.tables.group-type.season';
    case ReportTableGroupType.DURATION: return 'enums.reports.tables.group-type.duration';
    default: return 'enums.reports.tables.group-type.unknown';
  }
}
