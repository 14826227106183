import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, flatMap, switchMap } from 'rxjs/operators';

import { ClientService } from '../../../../services/client.service';
import { HostConfigService } from '../../../../services/host-config.service';
import { OnlineNotificationsService } from '../../../../services/online-notifications.service';
import { BgAuthService } from '../../services/bg-auth.service';

/**
 * Компонент для входа на сайт
 */
@Component({
  selector: 'bg-login',
  templateUrl: './bg-login.component.html',
  styleUrls: ['./bg-login.component.scss']
})
export class BgLoginComponent implements OnInit {

  /** Форма авторизации */
  public authForm: FormGroup;

  /** Сообщение ошибки */
  public error: string;

  /** Имя пользователя */
  public username: string;

  /** Пароль */
  public password: string;

  /**
   * Конструктор
   * @param auth Сервис аутентификации
   * @param hostConfigService
   * @param router Роутер
   * @param activateRoute
   * @param onlineNotificationsService Сервис работы с уведомлениями
   * @param clientService Сервис для работы с текущим клиентом
   * @param formBuilder
   */
  constructor(
    public auth: BgAuthService,
    public hostConfigService: HostConfigService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private onlineNotificationsService: OnlineNotificationsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder
  ) {
    /** Проверка что пользователь зарегистрирован */
    if (this.auth.authenticated) {
      this.enter();
    }
  }

  /**
   * Создаем форму авторизации
   */
  ngOnInit(): void {
    // Костылек для Стаса (ссылка на вход) /login?autologin=true
    this.activateRoute.queryParamMap.pipe(
      filter((q) => q.get('autologin') === 'true'),
      switchMap(() => this.auth.login('demo', 'demo')),
      switchMap(() => this.clientService.setLanguage())
    ).subscribe(() => this.enter());

    this.authForm = this.formBuilder.group({
      username: [null, [ Validators.required, Validators.maxLength(64) ]],
      password: [null, [ Validators.required, Validators.maxLength(64) ]]
    }, { updateOn: 'submit' })
  }

  /**
   * Обработчик смены языка
   */
  public onLangChanged() {
    this.router.navigate(['logout'], {
      queryParams: { redirect: this.router.url },
      skipLocationChange: true,
      replaceUrl: false
    }).then();
  }

  /**
   * Вход
   */
  public onSubmit() {
    if (this.authForm.valid) {
      const error = (e: string) => this.error = e;
      const next = () => this.enter();

      this.auth.login(this.username, this.password)
        .pipe(flatMap(() => this.clientService.setLanguage()))
        .subscribe(next, error);
    }
  }

  /**
   * Подписка на сокет и переход в систему
   */
  private enter (): void {
    this.onlineNotificationsService.clean();
    this.onlineNotificationsService.notificationsSubscribe(this.auth.token);
    this.router.navigate(['']).then();
  }
}
