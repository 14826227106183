import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IUnitAndGeozoneFilterItem } from '../../../../shared/reports/tables/IReportTableFilter';
import {
  getActionName,
  getAllActions,
  TableFilterAction
} from '../../../../shared/reports/tables/TableFilterAction';
import { IClientReportTableFilter } from '../../../classes/IClientReportTableFilter';
import { IListItem } from '../../../classes/IListItem';
import { AccountsService } from '../../../services/accounts.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ReportObjectsService } from '../../../services/report-objects.service';
import { compareStrings, localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для отображения фильтра таблицы отчета
 */
@Component({
  selector: 'report-template-table-filter',
  templateUrl: './report-template.table-filter.component.html',
  styleUrls: ['./report-template.table-filter.component.scss']
})
export class ReportTemplateTableFilterComponent implements OnInit {

  /** Фильтр */
  @Input() public filter: IClientReportTableFilter;

  /** Идентификатор учетной записи, к которой относится отчет */
  @Input() public accountId: string;

  /** Действия */
  public actions: IListItem<TableFilterAction>[] = [];

  /** Объекты */
  public units: IListItem<string>[];

  /** Группы объектов */
  public unitGroups: IListItem<string>[];

  /** Геозоны */
  public geozones: IListItem<string>[];

  /** Группы геозон */
  public geozoneGroups: IListItem<string>[];

  /**
   * Конструктор
   * @param reportObjectsService Сервис работы с отчетами
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param accountsService Сервис учетных записей
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private reportObjectsService: ReportObjectsService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private accountsService: AccountsService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Добавление объектов
   */
  public addUnits() {
    if (this.units) {
      this.selectUnits();
      return;
    }

    this.loadingService.wrap(this.reportObjectsService.getUnits(), true)
      .subscribe((units) => {
        this.units = units;
        this.selectUnits();
      });
  }

  /**
   * Выбор объектов
   */
  public selectUnits = () => {
    const data = {
      items: this.units,
      title: 'component.report-template.table-filter.select-objects',
      withSearch: true,
      selected: this.filter?.units?.filter((f) => !f.group) as IListItem<string>[]
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!this.filter.units) {
          this.filter.units = result.map((unit) => ({ id: unit.id, name: unit.name, radius: 100 }));
          return;
        }

        this.filter.units = this.filter.units
          ?.filter((u) => !!u.group)
          ?.sort((a, b) => compareStrings(a.name, b.name));

        this.filter.units.push(...result?.map((i) => ({
          id: i.id,
          name: i.name,
          radius: 100
        }))?.sort(localeSort));
      });
  };

  /**
   * Добавление групп объектов
   */
  public addUnitGroups() {
    if (this.unitGroups) {
      this.selectUnitGroups();
      return;
    }

    this.loadingService.wrap(this.reportObjectsService.getUnitGroups(), true)
      .subscribe((groups) => {
        this.unitGroups = groups;
        this.selectUnitGroups();
      });
  }

  /**
   * Выбор групп объектов
   */
  public selectUnitGroups = () => {
    const data = {
      items: this.unitGroups,
      title: 'component.report-template.table-filter.select-object-groups',
      withSearch: true,
      selected: this.filter.units?.filter((f) => f.group) as IListItem<string>[]
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!this.filter.units) {
          this.filter.units = result.map((group) => ({ id: group.id, name: group.name, group: true, radius: 100 }));
          return;
        }

        this.filter.units = this.filter.units
          ?.filter((u) => !u.group)
          ?.sort((a, b) => compareStrings(a.name, b.name));

        this.filter.units.push(...result?.map((i) => ({
          id: i.id,
          name: i.name,
          group: true,
          radius: 100
        }))?.sort(localeSort));
      });
  };

  /**
   * Добавление геозон
   */
  public addGeozones() {
    if (this.geozones) {
      this.selectGeozones();
      return;
    }

    this.loadingService.wrap(this.accountsService.getAccountGeozones(this.accountId), true)
      .subscribe((geozones) => {
        this.geozones = geozones;
        this.selectGeozones();
      });
  }

  /**
   * Выбор геозон
   */
  public selectGeozones = () => {
    const data = {
      items: this.geozones,
      title: 'component.report-template.table-filter.select-geo',
      withSearch: true,
      selected: this.filter.geozones?.filter((f) => !f.group) as IListItem<string>[]
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!this.filter.geozones) {
          this.filter.geozones = result.map((item) => ({ id: item.id, name: item.name }));
          return;
        }

        this.filter.geozones = this.filter.geozones
          ?.filter((u) => !!u.group)
          ?.sort((a, b) => compareStrings(a.name, b.name));

        this.filter.geozones.push(...result?.map((i) => ({
          id: i.id,
          name: i.name
        }))?.sort(localeSort));
      });
  };

  /**
   * Добавление групп геозон
   */
  public addGeozoneGroups() {
    if (this.geozoneGroups) {
      this.selectGeozoneGroups();
      return;
    }

    this.loadingService.wrap(this.accountsService.getAccountGeozoneGroups(this.accountId), true)
      .subscribe((groups) => {
        this.geozoneGroups = groups;
        this.selectGeozoneGroups();
      });
  }

  /**
   * Выбор групп геозон
   */
  public selectGeozoneGroups = () => {
    const data = {
      items: this.geozoneGroups,
      title: 'component.report-template.table-filter.select-geo-groups',
      withSearch: true,
      selected: this.filter.geozones?.filter((f) => f.group) as IListItem<string>[]
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!this.filter.geozones) {
          this.filter.geozones = result.map((item) => ({
            id: item.id,
            name: item.name,
            group: true
          }));

          return;
        }

        this.filter.geozones = this.filter.geozones
          ?.filter((u) => !u.group)
          ?.sort((a, b) => compareStrings(a.name, b.name));

        this.filter.geozones.push(...result?.map((i) => ({
          id: i.id,
          name: i.name,
          group: true
        }))?.sort(localeSort));
      });
  };

  /**
   * Переключение исключения элемента фильтра из диапазона
   * @param item Элемент фильтра
   */
  public toggleExclude(item: IUnitAndGeozoneFilterItem<string>) {
    item.exclude = !item.exclude;
  }

  /**
   * Удаление элемента фильтра
   * @param list Список, из которого происходит удаление
   * @param item Элемент фильтра
   */
  public deleteFilterItem(list: IUnitAndGeozoneFilterItem<string>[], item: IUnitAndGeozoneFilterItem<string>) {
    const index = list.indexOf(item);
    if (index !== -1) {
      list.splice(index, 1);
    }
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.actions = getAllActions(this.filter.type)
      .map((action) => ({ id: action, name: getActionName(action, this.filter.type) }));

    if (!this.filter.action) {
      this.filter.action = 1;
    }
  }
}
