import { SensorType } from './SensorType';

/**
 * Получение единицы измерения значения по типу датчика
 * @param value Значение датчика
 * @param type Тип датчика
 */
export function getMeasure(value: number, type: SensorType): string {
  switch (type) {
    case SensorType.IGNITION:
    case SensorType.PRIVATE_REGIME:
    case SensorType.ARBITRARY_DIGITAL:
      return value
        ? 'enums.sensors.measure.on'
        : 'enums.sensors.measure.off';
    case SensorType.VOLTAGE:
      return 'enums.sensors.measure.voltage';
    case SensorType.MILEAGE:
    case SensorType.REL_ODOMETR:
      return 'enums.sensors.measure.mileage';
    case SensorType.WEIGHT:
      return 'enums.sensors.measure.weight';
    case SensorType.ACCELEROMETER:
      return 'enums.sensors.measure.acceleration';
    case SensorType.TEMPERATURE:
      return 'enums.sensors.measure.temperature';
    case SensorType.ENGINE_SPEED:
      return 'enums.sensors.measure.rpm';
    case SensorType.ABS_MH:
    case SensorType.REL_MH:
      return 'enums.sensors.measure.time';
    case SensorType.ABS_FUEL_CONSUMPTION:
    case SensorType.FUEL_LEVEL:
    case SensorType.IMP_FUEL_CONSUMPTION:
    case SensorType.IMP_FUEL_LEVEL:
    case SensorType.INSTANTANEOUS_FUEL_CONSUMPTION:
      return 'enums.sensors.measure.volume';
    default:
      return 'enums.sensors.measure.value';
  }
}
