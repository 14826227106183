import { Component } from '@angular/core';

import { BgPopoverComponent, BgPopoverService } from '../../../modules/bg-popover';

/**
 * Компонент для отображения подсказки к пустой ячейке таблицы отчета
 */
@Component({
  selector: 'app-empty-cell-tooltip',
  template: `<div class="tooltip">{{title}}</div>`,
  styles: [
    `.tooltip {
      background-color: rgba(243, 243, 243, 1);
      opacity: 0.9;
      border: 1px solid rgba(204, 204, 204, 0.5);
      padding: 5px;
      max-width: 300px;
      font-size: 12px;
    }`
  ]
})
export class EmptyCellTooltipComponent extends BgPopoverComponent<{ title: string }> {

  /** Текст подсказки */
  public title: string;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   */
  constructor(popoverService: BgPopoverService) {
    super(popoverService);
  }
}
