import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProtocolType } from '../../shared/crm/ProtocolType';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с типами команд
 */
@Injectable()
export class CommandTypesService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/command-types`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Получить тип команд по идентификатору объекта мониторинга
   * @param oid идентификатор объекта
   */
  public list(oid: string): Observable<ICommandType[]> {
    const params = { oid };
    return this.http.get<ICommandType[]>(this.baseUrl, { params });
  }

  /**
   * Получить тип команд
   * @param id идентификатор типа команды
   */
  public get(id: string): Observable<ICommandType> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<ICommandType>(url);
  }
}

/**
 * Интерфейс типа команды
 */
export interface ICommandType {

  /**
   * Идентификатор
   */
  id?: string;

  /**
   * Название
   */
  name: string;

  /**
   * Описание
   */
  description: string;

  /**
   * Тип протокола
   */
  protocol: ProtocolType;

  /**
   * Признак отсутствия ответа от терминала
   */
  quiet?: boolean;

  /**
   * Признак перезагрузки терминала
   */
  reload?: boolean;

  /**
   * Параметры
   */
  params?: ICommandTypeParam[];
}

/**
 * Интерфейс параметра типа команды
 */
export interface ICommandTypeParam {

  /**
   * Название
   */
  name: string;

  /**
   * Тип
   */
  type: CommandParamType;

  /**
   * Обязательность
   */
  required?: boolean;

  /**
   * Маска (регулярка)
   */
  mask?: string;

  /**
   * Минимальное значение
   */
  min?: number;

  /**
   * Максимальное значение
   */
  max?: number;
}

/**
 * Тип параметра команды
 */
export enum CommandParamType {

  // noinspection JSUnusedGlobalSymbols
  /**
   * Строка
   */
  STRING = 0,

  /**
   * Число
   */
  NUMBER,

  /**
   * Булев
   */
  BOOL
}
