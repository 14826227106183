import { Injectable } from '@angular/core';

import { MonitoringService } from './monitoring.service';
import { StoreService } from './store.service';

/**
 * Сервис для работы с геозонами
 */
@Injectable()
export class RoutesService {

  /**
   * Конструктор
   * @param monitoringService
   * @param store
   */
  constructor(
    private monitoringService: MonitoringService,
    private store: StoreService
  ) {}

  /**
   * Удаление связанных с геозонами элементов
   */
  public deleteRelatedGeozone(ids: string[]): void {
    let isNeedUpdateRoute: boolean = false;

    for (const id of ids) {
      if (this.store.routes.some((r) => r.points.some((p) => p.geozoneId === id))) {
        isNeedUpdateRoute = true;
      }
    }
    // Обновляем маршруты при необходимости
    if(isNeedUpdateRoute) this.monitoringService.getRoutes();
  }

}
