import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IDriverGroup } from '../../../../shared/drivers/IDriverGroup';
import { IClientDriver } from '../../../classes/IClientDriver';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для редактирования группы водителей
 */
@Component({
  selector: 'drivers-edit-group',
  templateUrl: './drivers.edit-group.component.html',
  styleUrls: ['./drivers.edit-group.component.scss']
})
export class DriversGroupEditComponent implements OnInit {
  /**
   * Редактируемая группа
   */
  @Input() public group: IDriverGroup;

  /**
   * Список водителей редактируемой группы
   */
  public drivers: IClientDriver[] = [];

  /** Строка поиска */
  public search: string;

  /**
   * Конструктор
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param store Сервис для хранения данных мониторинга
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private store: StoreService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Получение признака возможности сохранения группы водителей
   */
  public get isCanSave() {
    return this.group.name
      && this.group.name !== ''
      && this.group.drivers.length;
  }

  /** Выбор водителя в группу из списка */
  public selectDrivers() {
    const items = this.store.drivers
      ?.map((d) => ({ id: d.id, name: d.name }))
      ?.sort(localeSort);

    const selected = items
      .filter((d) => this.group.drivers.includes(d.id));

    const data = {
      items,
      selected,
      title: 'component.drivers.edit-group.select-driver-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.group.drivers = result?.map((d) => d.id);
        this.refreshDrivers();
      });
  }

  /**
   * Удаление водителя из группы
   * @param driver Водитель
   */
  public deleteDriver(driver: IClientDriver) {
    let index = this.drivers.indexOf(driver);
    if (index !== -1) {
      this.drivers.splice(index, 1);
    }

    index = this.group.drivers.indexOf(driver.id);
    if (index !== -1) {
      this.group.drivers.splice(index, 1);
    }
  }

  /**
   * Сохранение группы водителей
   */
  public save() {
    if (!this.isCanSave) {
      return;
    }

    this.loadingService.wrap(this.monitoringService.saveDriverGroup(this.group), true).subscribe();
  }

  /**
   * Отмена редактирования группы водителей
   */
  public cancel() {
    this.store.editDriverGroup = null;
  }

  /**
   * Обработка после инициализации компонента
   */
  public ngOnInit() {
    this.refreshDrivers();
  }

  /**
   * Обновление списка водителей группы
   */
  private refreshDrivers() {
    this.drivers = this.store.drivers
      ?.filter((d) => this.group.drivers.includes(d.id))
      ?.sort(localeSort);
  }
}
