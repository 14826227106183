/**
 * Получение списка значений перечисления
 * @param o Объект - перечисление
 * @return Массив значений перечисления
 * @example * const locales: Locale[] = getAllEnumValues<Locale>(Locale)
 */
export function getAllEnumValues<T>(o: object): Array<T> {
  return Object.keys(o)
    .filter((x) => isNaN(+x))
    .map((x) => o[x] as T);
}

/**
 * Поиск значения в перечислении
 * @param o Объект - перечисление
 * @param val Значение
 */
export function existsEnumValue(o: object, val: string | number) {
  return Object.values(o).includes(val);
}

/**
 * Получение списка из значений перечисления и их имён (по ключу)
 * @param o Объект - перечисление
 * @param key Ключ, опционально будет подставлен перед значением перечисления, например 'enums.types-'
 */
export function getEnumList<T>(o: object, key?: string): Array<EnumListItem<T>> {
  return getAllEnumValues<T>(o).map((x) => ({ id: x, name: (key || '') + x }));
}

/**
 * Тип элемента списка значений перечисления
 */
export type EnumListItem<T> = {
  id: T
  name: string
}
