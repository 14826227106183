import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAssignmentListItem } from '../../shared/assignments/IAssignmentListItem';
import { IAssignmentRequest } from '../../shared/assignments/IAssignmentRequest';
import { LinkObjectType } from '../../shared/LinkObjectType';
import { ITrackingAssignment } from '../../shared/tracking/ITrackingAssignment';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с назначениями объектов (водителя/прицепа)
 */
@Injectable()
export class AssignmentsService {

  /**
   * Дата начала рабочей смены (интервала)
   */
  public from: Date;

  /**
   * Дата окончания рабочей смены (интервала)
   */
  public to: Date;

  /**
   * Идентификатор назначаемого объекта
   */
  public objectId: string;

  /**
   * Признак регистрации рабочей смены (интервала)
   */
  public isShiftRegistration: boolean = false;

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/assignments`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Назначение на ТС
   * @param assignmentRequest Запрос о назначении
   * @param objectType Тип объекта
   */
  public assign(assignmentRequest: IAssignmentRequest, objectType: LinkObjectType): Observable<string> {
    const body = {assignmentRequest, objectType};
    const url = `${this.baseUrl}/assign`;
    return this.http.post<string>(url, body);
  }

  /**
   * Снятие с ТС
   * @param objectId Идентификатор объекта
   * @param objectType Тип объекта
   */
  public deny(objectId: string, objectType: LinkObjectType): Observable<string> {
    const body = {objectId, objectType};
    const url = `${this.baseUrl}/deny`;
    return this.http.post<string>(url, body);
  }

  /**
   * Получение последних назначений объектов сессии
   * @param sessionId Идентификатор сессии
   * @param objectType Тип объекта
   */
  public getLastAssignments(sessionId: string, objectType: LinkObjectType): Observable<ITrackingAssignment[]> {
    const url = `${this.baseUrl}/last`;
    const options = {params: {sessionId, objectType: `${objectType}`}};
    return this.http.get<ITrackingAssignment[]>(url, options);
  }

  /**
   * Удаление назначения
   * @param assignmentId Идентификатор назначения
   * @param objectType Тип объекта
   */
  public delete(assignmentId: string, objectType: LinkObjectType): Observable<string> {
    const options = {params: {assignmentId, objectType: `${objectType}`}};
    return this.http.delete<string>(this.baseUrl, options);
  }

  /**
   * Получение списка назначений объекта
   * @param objectId Идентификатор объекта
   * @param objectType Тип объекта
   */
  public getList(objectId: string, objectType: LinkObjectType): Observable<IAssignmentListItem[]> {
    const options = {params: {objectId, objectType: `${objectType}`}};
    return this.http.get<IAssignmentListItem[]>(this.baseUrl, options);
  }
}
