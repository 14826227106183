import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMessagesRequest } from '../../shared/messages/IMessagesRequest';
import { IMessagesResult } from '../../shared/messages/IMessagesResult';
import { MessageType } from '../../shared/messages/MessageType';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с сообщениями
 */
@Injectable()
export class BGMessagesService {

  /**
   * Выбранный объект
   */
  public selectedUnit: IListItem<string>;

  /**
   * Тип сообщений
   */
  public messageType: MessageType;

  /**
   * Дата начала периода
   */
  public from: Date;

  /**
   * Дата окончания периода
   */
  public to: Date;

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/messages`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.selectedUnit = null;
    this.messageType = MessageType.EVENT;
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
  }

  /**
   * Получение списка сообщений
   * @param body Запрос на получение сообщений
   */
  public getList(body: IMessagesRequest): Observable<IMessagesResult> {
    return this.http.post<IMessagesResult>(this.baseUrl, body);
  }

  /**
   * Удаление сообщения
   * @param id Идентификатор сообщения
   * @param type Тип сообщения
   */
  public del(id: string, type: MessageType): Observable<string> {
    const url = `${this.baseUrl}/${type}/${id}`;
    return this.http.delete<string>(url);
  }

  /**
   * Очистка данных сервиса
   */
  public clean() {
    this.selectedUnit = null;
  }
}
