import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { filter, flatMap, switchMap } from 'rxjs/operators';

import { GeozoneType, getAllGeozoneTypes, getGeozoneTypeName } from '../../../../shared/geozones/GeozoneType';
import { IClientGeozone } from '../../../classes/IClientGeozone';
import { IListItem } from '../../../classes/IListItem';
import { BgdatePipe } from '../../../pipes/bgdate.pipe';
import { GeocoderService } from '../../../services/geocoder.service';
import { GeozoneGroupService } from '../../../services/geozone-group.service';
import { MapService } from '../../../services/map.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { RaceEventType, RaceService } from '../../../services/race.service';
import { StoreService } from '../../../services/store.service';
import { ToastService } from '../../../services/toast.service';

/**
 * Компонент для работы с созданием геозоны из рейса
 */
@Component({
  selector: 'app-race-create-geozone',
  templateUrl: './create-geozone.component.html',
  styleUrls: ['./create-geozone.component.scss']
})
export class CreateGeozoneComponent implements OnInit, OnDestroy {

  /** Типы геозон */
  public types: IListItem<GeozoneType>[] = [];
  /** Настройки */
  public settings: IGeozoneSetttings;
  /** Типы геозон */
  public geozoneType = GeozoneType;
  /** Геозоны */
  public geozones: IClientGeozone[] = [];

  /**
   * Конструктор
   * @param raceService
   * @param mapService
   * @param monitoringService
   * @param geocoderService
   * @param geozoneGroupService
   * @param toastService
   * @param translator
   * @param store
   * @param route
   * @param router
   */
  constructor(
    public raceService: RaceService,
    private mapService: MapService,
    private monitoringService: MonitoringService,
    private geocoderService: GeocoderService,
    private geozoneGroupService: GeozoneGroupService,
    private toastService: ToastService,
    private translator: TranslateService,
    private store: StoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  /**
   * При срздании компонента
   */
  ngOnInit(): void {
    // Если не из чего создавать, переходим назад
    if (!this.raceService?.raceForGeozone) this.cancel()

    // Заполняем типы геозон
    this.types = getAllGeozoneTypes()
      .filter((type) => type !== GeozoneType.POLYGON)
      .map((type) => ({ id: type, name: getGeozoneTypeName(type) }));

    // Заполняем настройки
    this.settings = {
      accountId: this.store?.user?.account?.id,
      type: GeozoneType.CIRCLE,
      color: '#000000',
      labelColor: '#0000ff',
      r: 50
    }

    this.getGeozones();
  }

  /**
   * При дестрое
   */
  ngOnDestroy(): void {
    this.raceService.raceForGeozone = null
    this.cancel();
  }

  /**
   * Формируем гуозоны из рейса или событий рейса
   */
  public getGeozones() {
    switch (this.settings.type) {
      case GeozoneType.LINE:
        this.geozones = [{
          type: GeozoneType.LINE,
          checked: false,
          color: '#000000',
          labelColor: '#0000ff',
          r: 50,
          points: this.raceService?.raceForGeozone?.p?.map(({ lt, ln }) => ({ lt, ln }))
        } as IClientGeozone]
        break;
      case GeozoneType.CIRCLE:
        const events = this.raceService?.raceForGeozone?.p?.filter((p) => p.e);
        this.geozones = events?.map((e, index) => {
          return {
            id: `${index}`,
            type: GeozoneType.CIRCLE,
            checked: false,
            color: '#000000',
            labelColor: '#0000ff',
            points: [{ lt: e.lt, ln: e.ln }],
            r: 50,
            eventType: e.e[0]?.t,
            time: e.t
          } as IClientGeozone
        });
    }
  }

  /**
   * Центрирование на геозоне
   * @param geozone
   */
  public onFitGeozone(geozone: IClientGeozone) {
    this.monitoringService.fitGeozoneSubject.next(geozone.id)
  }

  /**
   * Тоглим
   * @param geozone
   */
  public checkedGeozone(geozone: IClientGeozone) {
    geozone.checked = !geozone.checked

    if (geozone.checked) {
      geozone.square = this.monitoringService.calcGeozoneSquare(geozone)
      this.mapService.showGeozonesSubject.next([geozone])
    } else {
      this.mapService.hideGeozonesSubject.next([geozone.id])
    }
  }

  /**
   * При изменении настроек
   */
  public onChangeSetting() {
    this.geozones.forEach((g) => {
      g.color = this.settings.color;
      g.labelColor = this.settings.labelColor;
      g.r = this.settings.r
    })

    const checked = this.geozones.filter((g) => g.checked);
    this.mapService.hideGeozonesSubject.next(checked.map((g) => g.id));
    this.mapService.showGeozonesSubject.next(checked);
  }

  /**
   * Получение наименования поля r для геозоны
   * @param type Тип геозоны
   */
  public getRFieldName(type: GeozoneType) {
    switch (type) {
      case GeozoneType.CIRCLE:
        return 'component.geo.edit.radius';
      case GeozoneType.LINE:
        return 'component.geo.edit.width';
      default:
        return 'component.geo.unknown';
    }
  }

  /**
   * Получаем название события по типу
   * @param type
   */
  public getEventName(type: RaceEventType): string {
    switch (type) {
      case RaceEventType.PARKING:
        return 'component.map.parking';
      case RaceEventType.STOP:
        return 'component.map.stop';
      case RaceEventType.SPEEDING:
        return 'component.map.speeding';
      case RaceEventType.FILLING:
        return 'component.map.filling';
      case RaceEventType.THEFT:
        return 'component.map.theft';
      default:
        return 'component.map.event';
    }
  }

  /**
   * Признак возможности сохранения
   */
  public get isCanSave() {
    return this.geozones?.some((g) => g.checked)
  }

  /**
   * Сохранение геозон
   */
  public save() {
    const datePipe = new BgdatePipe();
    const t = datePipe.transform(this.raceService.raceForGeozone?.t, 'dd.mm.yyyy HH:MM');
    const f = datePipe.transform(this.raceService.raceForGeozone?.f, 'dd.mm.yyyy HH:MM');
    const name = `${this.raceService.raceForGeozone?.unitName} - ${f} - ${t}`;
    const checkedGeozone: IClientGeozone[] = this.geozones?.filter((g) => g.checked);

    const save = (g: IClientGeozone) => this.geocoderService.getAddress(null, g.points[0]?.lt, g.points[0]?.ln).pipe(
      filter((address) => address !== this.translator.instant('services.geo-coder.get')),
      flatMap((address) => {
        g.name = address;
        g.id = undefined;
        return this.monitoringService.saveGeozone(g)
      })
    )

    forkJoin(checkedGeozone.map(save)).pipe(
      switchMap((ids) => {
        return this.geozoneGroupService.updateGroup({
          id: null,
          name,
          descr: '',
          geozones: ids.map((i) => ({ name: '', id: i }))
        })
      })
    ).subscribe(() => {
      this.toastService.success('component.race.add-geozone');
      this.monitoringService.hideGeozonesSubject.next(this.geozones);
      this.mapService.loadOrReloadGeozonesSubject.next()
      this.cancel();
    })
  }

  /**
   * Отмена (переход назад)
   */
  public cancel() {
    if (this.geozones) {
      this.mapService.hideGeozonesSubject.next(this.geozones?.map((g) => g.id));
    }
    this.router.navigate(['../'], { relativeTo: this.route }).then();
  }
}

/**
 * Интерфейс для настроек геозон
 */
interface IGeozoneSetttings {
  accountId: string;
  type: GeozoneType;
  color: string;
  labelColor: string;
  r: number;
}
