import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAutoBinding } from '../../shared/auto-binding/IAutoBinding';
import { LinkObjectType } from '../../shared/LinkObjectType';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с назначениями водителя
 */
@Injectable()
export class AutoBindingService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/auto-bindings`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Добавление/изменение списка автоматического прикрепления
   * @param body Список автоматического прикрепления
   */
  public addUpdate(body: IAutoBinding): Observable<IAutoBinding> {
    return this.http.post<IAutoBinding>(this.baseUrl, body);
  }

  /**
   * Получение списка автоматического прикрепления
   * @param accountId Идентификатор учетной записи
   * @param objectType Тип объектов, для которых получается список
   */
  public get(accountId: string, objectType: LinkObjectType): Observable<IAutoBinding> {
    const options = {params: {accountId, objectType: `${objectType}`}};
    return this.http.get<IAutoBinding>(this.baseUrl, options);
  }
}
