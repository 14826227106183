import { Component, EventEmitter, Output } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { tap } from 'rxjs/operators';

import { DistrictFilterType } from '../../../../shared/garbage/IDistrictFilter';
import { ISimpleObject } from '../../../../shared/ISimpleObject';
import { DistrictsService } from '../../../services/districts.service';
import { LoadingService } from '../../../services/loading.service';
import { localeSort } from '../../../utils/sort';
import { UiDistrictsComponent } from '../districts/ui-districts.component';

@Component({
  selector: 'app-scavenger-mdistrics',
  templateUrl: './ui-mdistrics.components.html'
})
export class UIMDistricsComponent {

  /**
   * Районы
   */
  public districts: IClientDistrict[] = [];

  @Output() districsChange = new EventEmitter();

  constructor(
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private districtsService: DistrictsService
  ) {
    this.loadDistricts();
  }

  /**
   * Открытие справочника районов
   */
  public editDistricts() {
    this.dialogService.addDialog(UiDistrictsComponent, {})
      .pipe(tap(() => this.loadDistricts()))
      .subscribe();
  }

  /**
   * Загрузка районов
   */
  private loadDistricts() {
    this.districts = [];
    this.districts.push({type: DistrictFilterType.All, id: undefined, name: 'ui.all'});
    this.districts.push({type: DistrictFilterType.Undefined, id: undefined, name: 'ui.not-defined'});
    this.loadingService.withLoading(this.districtsService.getList(), (districts) => {
      const clientDistricts = districts?.sort(localeSort)
        .map((x) => ({...x, type: DistrictFilterType.ById}));
      this.districts.push(...clientDistricts);
      this.districsChange.emit(this.districts);
    });
  }
}

/**
 * Район с типом фильтрации
 */
export interface IClientDistrict extends ISimpleObject {

  /**
   * Тип фильтрации
   */
  type?: DistrictFilterType;
}
