import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IDuration, TimeSpan } from '../../../../shared/classes/TimeSpan';
import { GeozoneType } from '../../../../shared/geozones/GeozoneType';
import { IGeozoneTrack } from '../../../../shared/geozones/IGeozoneTrack';
import { ICoord } from '../../../../shared/ICoord';
import { ITrackingUnit } from '../../../../shared/tracking/ITrackingUnit';
import { BgPopoverService } from '../../../modules/bg-popover';
import { GeozonesService } from '../../../services/geozones.service';

/**
 * Компонент отображения расстояний до геозон
 */
@Component({
  selector: 'geozone-tracker',
  templateUrl: './geozone-tracker.component.html',
  styleUrls: ['./geozone-tracker.component.scss']
})
export class GeozoneTrackerComponent implements OnInit {

  /** ТС */
  public unit: ITrackingUnit;

  /** Список геозон с информацией */
  public geozones: IGeozoneTrack[];

  /** Время создания компонента, необходимо для расчёта времени прибытия */
  private readonly now: number;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   * @param geozonesService Сервис для работы с геозонами
   * @param config
   * @param ref
   */
  constructor(
    protected popoverService: BgPopoverService,
    private geozonesService: GeozonesService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.now = Date.now();
  }

  ngOnInit(): void {
    this.unit = this.config.data;

    const point = {ln: this.unit.position.ln, lt: this.unit.position.lt} as ICoord;
    this.geozonesService.getDistances(point).subscribe((geozones) => {
      this.geozones = geozones.map((geozone) => ({
        ...geozone,
        distance: round(geozone.distance / 1000, 3),
        time: geozone.time * 1000
      }));
    });
  }

  /**
   * Получение типа иконки по типу геозоны
   * @param type Тип геозоны
   */
  public getClass(type: GeozoneType) {
    switch (type) {
      case GeozoneType.CIRCLE:
        return 'fa-circle';
      case GeozoneType.LINE:
        return 'fa-minus';
      case GeozoneType.POLYGON:
        return 'fa-square';
      default:
        return '';
    }
  }

  /**
   * Получение строки с длительностью времени
   * @param value Количество миллисекунд
   */
  public getTime(value: number): IDuration {
    return new TimeSpan(value).toDuration();
  }

  /**
   * Получение времени прибытия в геозону
   * @param time Время до геозоны, в секундах
   */
  public getTimeNow(time: number) {
    return this.now + time;
  }
}

/**
 * Округление числа с указанной точностью
 * @param value Число
 * @param fract Точность
 */
function round(value: number, fract: number): number {
  if (!value) {
    return 0;
  }
  const n = Math.pow(10, fract);
  return Math.round(value * n) / n;
}
