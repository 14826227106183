import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { timer } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { ISortInfo } from '../../../shared/ISortInfo';
import { LinkObjectType } from '../../../shared/LinkObjectType';
import { AccountRightType } from '../../../shared/rights/RightType';
import { ITrackingAssignment } from '../../../shared/tracking/ITrackingAssignment';
import { ITrailer } from '../../../shared/trailers/ITrailer';
import { ITrailerGroup } from '../../../shared/trailers/ITrailerGroup';
import { IClientTrailer } from '../../classes/IClientTrailer';
import { IListItem } from '../../classes/IListItem';
import { TrackingTrailerGroup } from '../../classes/TrackingTrailerGroup';
import { AccountsService } from '../../services/accounts.service';
import { AssignmentsService } from '../../services/assignments.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { StoreService } from '../../services/store.service';
import { localeSort } from '../../utils/sort';
import { AssignmentsComponent } from '../assignments/assignments.component';
import { ModalResult } from '../modal/modal.component';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';

import { TrailersEditComponent } from './edit/trailers.edit.component';

/**
 * Компонент для прицепов
 */
@Component({
  selector: 'trailers',
  templateUrl: './trailers.component.html',
  styleUrls: ['./trailers.component.scss']
})
export class TrailersComponent implements OnDestroy {

  /** Строка поиска */
  public search: string;

  /** Признак отображения строки поиска */
  public showSearch: boolean = false;

  /** Сортировка */
  public sort: ISortInfo;

  /** Список учетных записей, прицепы которых может видеть пользователь */
  public accounts: IListItem<string>[] = [];

  /**
   * Конструктор
   * @param assignmentsService Сервис работы с назначениями
   * @param store Сервис для хранения данных мониторинга
   * @param modalService Сервис работы с модальными окнами
   * @param accountsService Сервис работы с учетными записями
   * @param dialogService Сервис диалоговых окон
   * @param monitoringService Сервис мониторинга
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    public assignmentsService: AssignmentsService,
    public store: StoreService,
    private modalService: ModalService,
    private accountsService: AccountsService,
    private dialogService: DialogService,
    private monitoringService: MonitoringService,
    private translator: TranslateService,
    private loadingService: LoadingService
  ) {
    this.sort = { field: 'name', isDescending: false };

    this.loadingService.wrap(this.accountsService.getWithRight(AccountRightType.VIEW_TRAILERS), true)
      .subscribe((accounts) => this.accounts = accounts?.sort(localeSort));
  }

  /**
   * Получение признака выбранности всех прицепов
   */
  get allChecked() {
    return this.store.trailers.every((t) => t.checked);
  }

  /**
   * Получение признака отображения панели выбора учетной записи
   */
  public get showAccounts() {
    return this.store.trackingSettings && this.accounts.length > 1;
  }

  /**
   * Получение признака отображения групп прицепов
   */
  public get showTrailerGroups() {
    return this.store.trackingSettings &&
      this.store.trackingSettings.showTrailerGroups;
  }

  /**
   * Создание прицепа
   */
  public addTrailer() {
    const data = { trailerId: null };
    this.dialogService.addDialog(TrailersEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getTrailers());
  }

  /**
   * Удаление прицепа
   * @param trailer Прицеп
   */
  public deleteTrailer(trailer: IClientTrailer) {
    const confirm = this.translator.instant('component.trailers.confirm', { val: trailer.name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.monitoringService.deleteTrailer(trailer), true)))
      .subscribe(() => this.monitoringService.getTrailers());
  }

  /**
   * Редактирование прицепа
   * @param trailer Прицеп
   */
  public updateTrailer(trailer: ITrailer) {
    const data = { trailerId: trailer.id };
    this.dialogService.addDialog(TrailersEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getTrailers());
  }

  /**
   * Обработка нажатия на кнопку, скрывающей кнопку снятия прицепа
   * @param trailer Прицеп
   */
  public onShowDenyButtonClicked(trailer: IClientTrailer) {
    trailer.denying = true;
    timer(10000).subscribe(() => trailer.denying = false);
  }

  /**
   * Обработка нажатия на кнопку назначения прицепа
   * @param trailer Прицеп
   * @param isShiftRegistration Признак регистрации рабочего интервала
   */
  public assign(trailer: IClientTrailer, isShiftRegistration: boolean) {
    this.assignmentsService.objectId = trailer.id;
    this.assignmentsService.isShiftRegistration = isShiftRegistration;
  }

  /**
   * Обработка нажатия на кнопку снятия прицепа
   * @param trailer Прицеп
   */
  public deny(trailer: IClientTrailer) {
    this.loadingService.wrap(this.assignmentsService.deny(trailer.id, LinkObjectType.TRAILER), true)
      .subscribe(() => this.monitoringService.getTrailers());
  }

  /**
   * Центрирование карты на прицепе
   * @param trailer Прицеп
   */
  public fitTrailer(trailer: IClientTrailer) {
    this.monitoringService.moveToPoint(trailer.assignment);
  }

  /**
   * Выбор учетной записи из списка
   */
  public selectAccount() {
    const selectedAccount = this.accounts
      .find((a) => a.id === this.store.trackingSettings.trailersAccountId);

    const data = {
      items: this.accounts,
      selected: selectedAccount ? [selectedAccount] : [],
      title: 'component.trailers.select-account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.store.trackingSettings.trailersAccountId = result?.shift()?.id;
        this.onChangeAccount();
      });
  }

  /**
   * Открывает окно для работы со списком назначений
   * @param trailer Прицеп
   */
  public showAssignments(trailer: IClientTrailer) {
    const data = {
      object: {
        id: trailer.id,
        name: trailer.name
      },
      objectType: LinkObjectType.TRAILER
    };

    this.dialogService.addDialog(AssignmentsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getTrailers());
  }

  /**
   * Получение класса для переключателя отображения
   * детальной информации по группе прицепов
   * @param group Группа прицепов
   */
  public getGroupDetailsToggleClass(group: TrackingTrailerGroup) {
    return `fa-${group.showTrailers ? 'minus' : 'plus'}-square-o`;
  }

  /**
   * Переключение флажка выбора прицепа
   * @param trailer Прицеп
   */
  public toggleChecked(trailer: IClientTrailer) {
    this.monitoringService.toggleTrailerChecked(trailer);
  }

  /**
   * Переключение выбранности всех прицепов
   */
  public toggleAllChecked() {
    this.monitoringService.setAllTrailersChecked(!this.allChecked);
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = { field, isDescending: false };
    }
  }

  /**
   * Переключение видимости панели поиска
   */
  public toggleSearchPanel() {
    this.showSearch = !this.showSearch;
    this.search = '';
  }

  /**
   * Добавление группы прицепов
   */
  public addGroup() {
    this.store.editTrailerGroup = {
      id: null,
      name: '',
      descr: '',
      trailers: [],
      accountId: this.store.trackingSettings.trailersAccountId
    };
  }

  /**
   * Изменение группы прицепов
   * @param group Группа прицепов
   */
  public updateGroup(group: ITrailerGroup) {
    this.store.editTrailerGroup = {
      id: group.id,
      name: group.name,
      descr: group.descr,
      trailers: group.trailers,
      accountId: group.accountId
    };
  }

  /**
   * Удаление группы прицепов
   * @param group Группа прицепов
   */
  public deleteGroup(group: ITrailerGroup) {
    const confirm = this.translator.instant('component.trailers.confirm-group', { val: group.name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.monitoringService.deleteTrailerGroup(group)))
      .subscribe(() => this.monitoringService.getTrailers());

  }

  /**
   * Переключение выбранности группы прицепов
   * @param group Группа прицепов
   */
  public toggleGroupChecked(group: TrackingTrailerGroup) {
    const checked = !group.checked;
    group.trackingTrailers
      .filter((trailer) => trailer.checked !== checked)
      .forEach((trailer) => this.monitoringService.toggleTrailerChecked(trailer));
  }

  /**
   * Переключение признака необходимости отображения групп прицепов
   */
  public toggleTrailerGroups() {
    if (this.store.trackingSettings) {
      this.store.trackingSettings.showTrailerGroups = !this.store.trackingSettings.showTrailerGroups;
      this.onChangeAccount();
    }
  }

  /**
   * Переключение видимости прицепов в группе
   * @param group
   */
  public toggleShowTrailers(group: TrackingTrailerGroup) {
    group.showTrailers = !group.showTrailers
    const hideTrailerGroup: string[] = this.store.trailerGroups?.filter((g) => !g.showTrailers)?.map((g) => g.id)
    localStorage.setItem('hideTrailerGroup', JSON.stringify(hideTrailerGroup))
  }

  /**
   * Получение css класса для отображения статуса назначения прицепа
   * @param assignment Назначение прицепа
   */
  public getTrailerStatusClass(assignment: ITrackingAssignment): string {
    return assignment ? this.isBusy(assignment) ? 'green' : 'red' : 'grey';
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    this.assignmentsService.objectId = null;
  }

  /**
   * Проверка занятости прицепа
   * @param assignment Назначение прицепа
   */
  public isBusy(assignment: ITrackingAssignment): boolean {
    if (!assignment) {
      return false;
    }

    return !assignment.e || assignment.e >= (new Date()).getTime();
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onChangeAccount() {
    this.loadingService
      .wrap(this.monitoringService.updateTrackingSettings(this.store.trackingSettings), true)
      .subscribe(() => this.monitoringService.getTrailers());
  }
}
