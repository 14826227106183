import { ReportTableMaskType } from './ReportTableMaskType';
import { ReportTableType } from './ReportTableType';

/**
 * Получение списка типов масок, доступных для таблицы отчета
 * @param type Тип таблицы отчета
 */
export function getReportTableMasks(type: ReportTableType): ReportTableMaskType[] {
  switch (type) {
    case ReportTableType.GEOZONES: return getGeozonesTableMasks();
    case ReportTableType.COUNTERS: return getCountersTableMasks();
    case ReportTableType.FUEL: return getFuelTableMasks();
    case ReportTableType.FILLINGS: return getFillingsTableMasks();
    case ReportTableType.MHS: return getMhTableMasks();
    case ReportTableType.STOPS: return getStopsTableMasks();
    case ReportTableType.RACES: return getRacesTableMasks();
    case ReportTableType.SPEEDING: return getSpeedingTableMasks();
    case ReportTableType.SUMMARY: return getSummaryTableMasks();
    case ReportTableType.THEFTS: return getTheftsTableMasks();
    case ReportTableType.PARKINGS: return getParkingsTableMasks();
    case ReportTableType.LOADINGS: return getLoadingsTableMasks();
    case ReportTableType.DIGITAL_SENSORS: return getDigitalSensorsTableMasks();
    case ReportTableType.FUEL_BY_CHECK: return getFuelByCheckTableMasks();
    case ReportTableType.EVENTS: return getEventsTableMasks();
    case ReportTableType.VIOLATIONS: return getEventsTableMasks();
    case ReportTableType.MAINTENANCE: return getEventsTableMasks();
    default: return [];
  }
}

/**
 * Получение списка типов масок, доступных для таблицы "Геозоны"
 */
export function getGeozonesTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.FUEL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.ENGINE_SPEED,
    ReportTableMaskType.TEMPERATURE
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Счетчики"
 */
export function getCountersTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.COUNTER];
}

/**
 * Получение списка типов масок, доступных для таблицы "Движение топлива"
 */
export function getFuelTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.FUEL, ReportTableMaskType.COUNTER];
}

/**
 * Получение списка типов масок, доступных для таблицы "Заправки"
 */
export function getFillingsTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.FUEL, ReportTableMaskType.COUNTER];
}

/**
 * Получение списка типов масок, доступных для таблицы "Моточасы"
 */
export function getMhTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.FUEL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.MH,
    ReportTableMaskType.ENGINE_SPEED,
    ReportTableMaskType.ENGINE_WORK,
    ReportTableMaskType.TEMPERATURE
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Цифровые датчики"
 */
export function getDigitalSensorsTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.DIGITAL,
    ReportTableMaskType.FUEL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.MH,
    ReportTableMaskType.ENGINE_SPEED,
    ReportTableMaskType.ENGINE_WORK,
    ReportTableMaskType.TEMPERATURE
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Остановки"
 */
export function getStopsTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.COUNTER, ReportTableMaskType.WEIGHT];
}

/**
 * Получение списка типов масок, доступных для таблицы "Поездки"
 */
export function getRacesTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.FUEL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.MH,
    ReportTableMaskType.ENGINE_SPEED,
    ReportTableMaskType.TEMPERATURE
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Превышения скорости"
 */
export function getSpeedingTableMasks(): ReportTableMaskType[] {
  return [];
}

/**
 * Получение списка типов масок, доступных для таблицы "Сводка"
 */
export function getSummaryTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.FUEL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.MH,
    ReportTableMaskType.ENGINE_WORK,
    ReportTableMaskType.TEMPERATURE,
    ReportTableMaskType.ARBITRARY
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Сливы"
 */
export function getTheftsTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.FUEL, ReportTableMaskType.COUNTER];
}

/**
 * Получение списка типов масок, доступных для таблицы "Стоянки"
 */
export function getParkingsTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.TEMPERATURE,
    ReportTableMaskType.WEIGHT
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Загрузки"
 */
export function getLoadingsTableMasks(): ReportTableMaskType[] {
  return [
    ReportTableMaskType.DIGITAL,
    ReportTableMaskType.COUNTER,
    ReportTableMaskType.TEMPERATURE,
    ReportTableMaskType.WEIGHT
  ];
}

/**
 * Получение списка типов масок, доступных для таблицы "Топливо по чекам"
 */
export function getFuelByCheckTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.EVENT];
}

/**
 * Получение списка типов масок, доступных для таблицы "Посещенные улицы"
 */
export function getStreetsTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.COUNTER];
}

/**
 * Получение списка типов масок, доступных для таблиц "События"/"Нарушения"
 */
export function getEventsTableMasks(): ReportTableMaskType[] {
  return [ReportTableMaskType.EVENT];
}
