import { Component, ElementRef, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

/**
 * Интерфейс компонента предварительного просмотра отчёта
 */
export interface IReportPreviewComponent {

  /**
   * Данные предпросмотра
   */
  blob: Blob;

  /**
   * Название файла
   */
  name: string;
}

/**
 * Компонент предварительного просмотра отчёта
 */
@Component({
  selector: 'report-preview',
  templateUrl: './report.preview.component.html',
  styleUrls: ['./report.preview.component.scss']
})
export class ReportPreviewComponent
  extends DialogComponent<IReportPreviewComponent, boolean>
  implements IReportPreviewComponent {

  @ViewChild('container', {static: false})
  public container: ElementRef;

  /**
   * Данные предпросмотра
   */
  public blob: Blob;

  /**
   * Название файла
   */
  public name: string;

  /**
   * HTML разметка предпросмотра
   */
  private html: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IReportPreviewComponent) {
    const reader = new FileReader();
    reader.onload = this.show;
    reader.readAsText(data.blob);
    return super.fillData(data);
  }

  /**
   * Вывод на печать
   */
  public print() {
    document.getElementById('print').innerHTML = this.html;
    window.print();
  }

  /**
   * Сохранение в файл
   */
  public save() {
    FileSaver.saveAs(this.blob, this.name);
  }

  /**
   * Отображение превью
   * @param e Событие, данные прочитаны
   */
  private show = (e: ProgressEvent) => {
    this.html = (e.target as any).result;
    this.container.nativeElement.innerHTML = this.html;
  }
}
