import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Locale } from '../../shared/users';

/**
 * Пайп для форматирования числовых полей
 */
@Pipe({
  name: 'bgnumber',
  pure: true
})
export class BgnumberPipe extends DecimalPipe implements PipeTransform {
  constructor(translator: TranslateService) {
    super(getLang(translator));
  }

  public transform(value: any, pattern?: string): string | null {
    return super.transform(value, pattern);
  }
}

/**
 * Получение языка для пайпа
 * @param translator Сервис для перевода
 */
function getLang(translator: TranslateService) {
  switch (translator.currentLang) {
    case Locale.RU:
      return 'ru-RU';
    case Locale.EN:
      return 'en-US';
    default:
      return 'ru-RU';
  }
}
