import { Pipe, PipeTransform } from '@angular/core';

import { ISubjectRight } from '../../shared/rights/ISubjectRight';

/**
 * Фильтр для списка субъектов с их правами на объект в администрировании
 */
@Pipe({
  name: 'srfilter',
  pure: false
})
export class SubjectRightsFilterPipe implements PipeTransform {
  public transform(items: ISubjectRight[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => item.subjectName.toLowerCase().includes(lowerFilter)
    );
  }

}
