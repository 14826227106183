import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

/**
 * Тип модального окна
 */
export enum ModalType {
  /** Ошибка */
  ERROR = 1,
  /** Предупреждение */
  WARNING,
  /** Подтверждение */
  CONFIRM,
  /** Вопрос */
  QUESTION,
  /** Информация */
  INFO
}

/**
 * Результат, возвращаемый окном
 */
export enum ModalResult {
  /** ОК */
  OK = 1,
  /** Да */
  YES,
  /** Нет */
  NO,
  /** Отмена */
  CANCEL
}

/**
 * Интерфейс, описывающий кнопку модального окна
 */
export interface IModalButton {
  /** Наименование окна */
  name: string;
  /** Результат, возвращаемый окном при клике по кнопке */
  result: ModalResult;
}

/**
 * Интерфейс компонента модального окна
 */
export interface IModalComponent {
  /** Текст сообщения */
  message: string;
  /** Тип окна */
  type: ModalType;
  /** Кастомный заголовок */
  customTitle?: string;
  /** Кастомные кнопки */
  customButtons?: any[];
}

/**
 * Компонент модального окна
 */
@Component({
  selector: 'bg-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent extends DialogComponent<IModalComponent, ModalResult> implements IModalComponent {
  /** Текст сообщения */
  public message: string;
  /** Тип окна */
  public type: ModalType;
  /** Кастомный заголовок */
  public customTitle?: string;
  /** Заголовок */
  public title: string;
  /** Кастомные кнопки */
  public customButtons?: any[];
  /** Список отображаемых кнопок */
  public buttons: IModalButton[] = [];
  /** Стиль */
  public style: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  /**
   * Заполнение окна данными
   * @param data Данные окна
   */
  public fillData(data: IModalComponent) {
    switch (data.type) {
      case ModalType.ERROR:
        this.title = 'component.modal.error';
        this.buttons = [{ name: 'ui.ok', result: ModalResult.OK }];
        this.style = 'error-modal';
        this.wrapper.container.nativeElement.style.backgroundColor = 'rgba(219, 118, 116, 0.5)';
        break;

      case ModalType.WARNING:
        this.title = 'component.modal.warn';
        this.buttons = [{ name: 'ui.ok', result: ModalResult.OK }];
        this.style = 'warning-modal';
        this.wrapper.container.nativeElement.style.backgroundColor = 'rgba(255, 223, 128, 0.5)';
        break;

      case ModalType.CONFIRM:
        this.title = 'component.modal.confirmation';
        this.buttons = [{ name: 'ui.ok', result: ModalResult.OK }, { name: 'ui.cancel', result: ModalResult.CANCEL }];
        this.style = 'confirm-modal';
        break;

      case ModalType.QUESTION:
        this.title = 'component.modal.question';
        this.buttons = [{ name: 'ui.yes', result: ModalResult.YES }, { name: 'ui.no', result: ModalResult.NO }];
        this.style = 'question-modal';
        break;

      case ModalType.INFO:
      default:
        this.title = 'component.modal.info';
        this.buttons = [{ name: 'ui.ok', result: ModalResult.OK }];
        this.style = 'info-modal';
    }

    if (data.customTitle && data.customTitle !== '') {
      this.title = data.customTitle;
    }

    if (data.customButtons && data.customButtons.length) {
      this.buttons = data.customButtons;
    }

    return super.fillData(data);
  }

  /**
   * Обработка клика по кнопке окна
   * @param result Результат
   */
  public onButtonClick(result: ModalResult) {
    this.result = result;
    this.close();
  }
}
