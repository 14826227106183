import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Сервис конфигурации
 */
@Injectable()
export class ConfigService {

  /**
   * Базовый url для доступа к api
   */
  public readonly url = '/api';

  /**
   * Уникальный идентификатор сессии клиента
   */
  public readonly uniqueId;

  /**
   * changelog
   */
  public changelog: string;

  /**
   * версия changelog (вырезаем регуляркой)
   */
  public changelogVersion: string;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param translator Сервис для перевода
   */
  constructor(
    private http: HttpClient,
    private translator: TranslateService
  ) {
    const rand = Math.floor(Math.random() * 0xFFFFFFFF)
    this.uniqueId = rand.toString(16).toUpperCase().padStart(8, '0');
  }

  public loadChangelog() {
    const url = `assets/changelog/changelog_${this.translator.currentLang || 'en'}`;
    const responseType = 'text';
    this.http.get(url, { responseType })
      .subscribe((changelog) => {
        this.changelog = changelog;
        this.changelogVersion = changelog.match(/[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,2}/)?.shift();
      });
  }
}
