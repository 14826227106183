import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Компонент для работы со счетами и актами по учетной записи
 */
@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  /** Идентификатор учетной записи */
  public accountId: string;

  /**
   * Конструктор
   * @param route Текущий маршрут
   */
  constructor(private route: ActivatedRoute) { }

  /**
   * Обработки при инициализации компонента
   */
  public ngOnInit() {
    this.accountId = this.route.snapshot.params.id;
  }
}
