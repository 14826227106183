import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { IDillerClientAccount } from '../../../../../shared/crm/dillers/IDillerClientAccount';
import { IDocsPackets, IPacket } from '../../../../../shared/crm/docs';
import { IListItem } from '../../../../classes/IListItem';
import { DillersService } from '../../../../services/dillers.service';
import { DocsService } from '../../../../services/docs.service';
import { LoadingService } from '../../../../services/loading.service';
import { prepareDocForDisplay } from '../../../../utils/docs';
import { AccountComponent } from '../../../account/account.component';
import { ClientsEditComponent } from '../../clients/edit/clients.edit.component';
import { DogovorsEditComponent } from '../../dogovors/edit/dogovors.edit.component';
import { TariffEditComponent } from '../../tariffs/edit/tariffs.edit.component';

import { Table } from 'primeng/table';
import { DocsPacketsRequestParamsComponent } from './request-params/docs-packets-request-params.component';

/**
 * Компонент для формирования пакетов документов
 */
@Component({
  selector: 'docs-packets',
  templateUrl: './docs.packets.component.html',
  styleUrls: ['./docs.packets.component.scss']
})
export class DocsPacketsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClientDillerClientAccount[];
  /**
   * Выбранный элемент
   */
  public selected: IClientDillerClientAccount;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /** Ошибка */
  public error: string;

  /** Результат формирования */
  public result: IDocsPackets;

  /** Пакет для печати (если не указан, то печатаются все счета/акты) */
  public printPacket: IPacket;

  /** Список сгенерированных пакетов документо для печати */
  public packets: IPacket[];

  /**
   * Конструктор
   * @param docsService Сервис для работы с документами
   * @param dillersService Сервис для работы с дилерами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param dialogService Сервис для работы с диалоговыми окнами
   * @param router
   */
  constructor(
    public docsService: DocsService,
    private dillersService: DillersService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'client.name', name: 'ui.client' },
      { id: 'dogovor.name', name: 'component.crm.docs.packets.contract' },
      { id: 'tariff.name', name: 'component.crm.docs.packets.tariff' },
      { id: 'balance', name: 'component.crm.docs.packets.balance' },
      { id: 'units', name: 'component.crm.docs.packets.objects' },
      { id: 'days', name: 'component.crm.docs.packets.days' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Получение признака всех выбранных учетных записей
   */
  public get allChecked() {
    return this.items?.every((item) => item.checked);
  }

  /**
   * Получение класса для отображения строки с учетной записью
   * @param account Учетная запись
   */
  public getAccountClass(account: IDillerClientAccount): string {
    if (account.days == null || account.days > 31) {
      return null;
    }

    return account.days > 0 ? 'yellow' : 'red';
  }

  /**
   * Получение списка выбранных записей
   */
  public getCheckedItems() {
    return this.items?.filter((i) => i.checked);
  }

  /**
   * Переход
   * @param id
   */
  public changeSelected(id: string) {
    this.router.navigate(['/crm/docs', id]).finally()
  }

  /**
   * Генерация счетов/актов по выбранным записям
   */
  public generateChecked() {
    const accountIds = this.getCheckedItems().map((i) => i.id);
    this.generatePackets(accountIds);
  }

  /**
   * Отображение подробной информации о клиенте
   * @param client Клиент
   */
  public showClient(client: IListItem<string>) {
    const data = { clientId: client.id };
    this.dialogService
      .addDialog(ClientsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение подробной информации о тарифе
   * @param tariff Тариф
   */
  public showTariff(tariff: IListItem<string>) {
    const data = { tariffId: tariff.id };
    this.dialogService.addDialog(TariffEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение подробной информации о учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public showAccount(accountId: string) {
    const data = { accountId };
    this.dialogService.addDialog(AccountComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение подробной информации о договоре
   * @param dogovor Договор
   */
  public showDogovor(dogovor: IListItem<string>) {
    const data = { dogovorId: dogovor.id };
    this.dialogService.addDialog(DogovorsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Обработка при изменении источника печати
   */
  public onChangePrintPacket() {
    if (!this.printPacket) {
      this.packets = this.result.packets;
    } else {
      this.packets = [this.printPacket];
    }
  }

  /**
   * Печать выбранных счетов/актов
   */
  public print() {
    const content = document.getElementById('printContainer').innerHTML;
    const popupWindow = window.open('', '_blank', 'width=1000,height=800');
    popupWindow.document.open();
    popupWindow.document.write(
      `<html>
        <body onload="window.print()">
          ${content}
        </body>
      </html>`
    );
    popupWindow.document.close();
  }

  /**
   * Закрытие результатов генерации счетов/актов
   */
  public closeResult() {
    this.result = null;
    this.packets = null;
    this.printPacket = null;
    this.loadData();
  }

  /**
   * Изменение выбора всех учетных записей
   */
  public toggleAllChecked() {
    if (!this.items) {
      return;
    }

    const checked = !this.allChecked;
    this.items.forEach((item) => item.checked = checked);
  }

  /**
   * Загрузка данных компонента
   */
  protected loadData = () => {
    this.loadingService.wrap(this.dillersService.getActiveAccounts(), true)
      .subscribe((accounts) => {
        accounts.forEach((account) => {
          account.balance /= 100;
        });

        this.items = accounts;
      });
  };

  /**
   * Генерация счетов/актов по списку учетных записей
   * @param accountIds Список учетных записей
   */
  private generatePackets(accountIds: string[]) {
    this.dialogService
      .addDialog(DocsPacketsRequestParamsComponent, {})
      .pipe(
        filter((params) => !!params),
        flatMap((params) => {
          const request = { accountIds, ...params };
          return this.loadingService.wrap(this.docsService.packets(request), true);
        }))
      .subscribe((result) => {
        result.packets.forEach((packet) => {
          if (packet.invoice) {
            prepareDocForDisplay(packet.invoice);
          }

          if (packet.report) {
            prepareDocForDisplay(packet.report);
          }
        });

        this.packets = result.packets;
        this.result = result;
        this.printPacket = null;
      });
  }
}

/**
 * Учетная запись клиента дилера для использования на стороне клиента
 */
interface IClientDillerClientAccount extends IDillerClientAccount {
  /** Признак выбранной записи */
  checked?: boolean;
}
