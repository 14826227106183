import * as Highcharts from 'highcharts';

import { tranny } from '../app.component';

/**
 * Класс для работы с графиками
 */
export class ChartManager {

  /**
   * Установка глобальных параметров компонента с графиками
   */
  public static setHighchartsGlobalOptions() {
    if (this.isGlobalOptionsSet) { return; }
    tranny.get([
      'classes.time.month-1',
      'classes.time.month-2',
      'classes.time.month-3',
      'classes.time.month-4',
      'classes.time.month-5',
      'classes.time.month-6',
      'classes.time.month-7',
      'classes.time.month-8',
      'classes.time.month-9',
      'classes.time.month-10',
      'classes.time.month-11',
      'classes.time.month-12',
      'classes.time.short-month-1',
      'classes.time.short-month-2',
      'classes.time.short-month-3',
      'classes.time.short-month-4',
      'classes.time.short-month-5',
      'classes.time.short-month-6',
      'classes.time.short-month-7',
      'classes.time.short-month-8',
      'classes.time.short-month-9',
      'classes.time.short-month-10',
      'classes.time.short-month-11',
      'classes.time.short-month-12',
      'classes.time.day-1',
      'classes.time.day-2',
      'classes.time.day-3',
      'classes.time.day-4',
      'classes.time.day-5',
      'classes.time.day-6',
      'classes.time.day-7',
      'classes.chart-manager.loading',
      'classes.chart-manager.download',
      'classes.chart-manager.print',
      'classes.chart-manager.reset',
      'classes.chart-manager.reset-zoom'
    ]).subscribe((x) => {
      Highcharts.setOptions({
        time: { useUTC: false },
        lang: {
          months: [
            x['classes.time.month-1'],
            x['classes.time.month-2'],
            x['classes.time.month-3'],
            x['classes.time.month-4'],
            x['classes.time.month-5'],
            x['classes.time.month-6'],
            x['classes.time.month-7'],
            x['classes.time.month-8'],
            x['classes.time.month-9'],
            x['classes.time.month-10'],
            x['classes.time.month-11'],
            x['classes.time.month-12']
          ],
          weekdays: [
            x['classes.time.day-7'],
            x['classes.time.day-1'],
            x['classes.time.day-2'],
            x['classes.time.day-3'],
            x['classes.time.day-4'],
            x['classes.time.day-5'],
            x['classes.time.day-6']
          ],
          shortMonths: [
            x['classes.time.short-month-1'],
            x['classes.time.short-month-2'],
            x['classes.time.short-month-3'],
            x['classes.time.short-month-4'],
            x['classes.time.short-month-5'],
            x['classes.time.short-month-6'],
            x['classes.time.short-month-7'],
            x['classes.time.short-month-8'],
            x['classes.time.short-month-9'],
            x['classes.time.short-month-10'],
            x['classes.time.short-month-11'],
            x['classes.time.short-month-12']
          ],
          loading: x['classes.chart-manager.loading'],
          downloadPNG: x['classes.chart-manager.download'] + ' PNG',
          downloadJPEG: x['classes.chart-manager.download'] + ' JPEG',
          downloadPDF: x['classes.chart-manager.download'] + ' PDF',
          downloadSVG: x['classes.chart-manager.download'] + ' SVG',
          printChart: x['classes.chart-manager.print'],
          resetZoom: x['classes.chart-manager.reset'],
          resetZoomTitle: x['classes.chart-manager.reset-zoom']
        }
      });
      ChartManager.isGlobalOptionsSet = true;
    });
  }

  /** Признак того, что глобальные параметры графиков установлены */
  private static isGlobalOptionsSet = false;
}
