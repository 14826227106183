/**
 * Тип задания
 */
export enum TaskType {
  /** Изменение доступа */
  CHANGE_ACCESS = 1,
  /** Отправка отчета на E-mail */
  SEND_REPORT,
  /** Отправка информации о топливе */
  SEND_FUEL_INFO,
  /** Учет пробега */
  CHANGE_MILEAGE_COUNTER,
  /** Учет моточасов */
  CHANGE_MH_COUNTER
}

/**
 * Получение списка всех типов заданий
 */
export function getAllTaskTypes(): TaskType[] {
  const result: TaskType[] = [];
  for (let i = 1; i <= 5; i++) {
    result.push(i as TaskType);
  }
  return result;
}

/**
 * Получение наименования типа задания
 * @param type Тип задания
 */
export function getTaskTypeName(type: TaskType): string {
  switch (type) {
    case TaskType.CHANGE_ACCESS: return 'enums.tasks.type.access';
    case TaskType.SEND_REPORT: return 'enums.tasks.type.email';
    case TaskType.SEND_FUEL_INFO: return 'enums.tasks.type.fuel';
    case TaskType.CHANGE_MILEAGE_COUNTER: return 'enums.tasks.type.mileage';
    case TaskType.CHANGE_MH_COUNTER: return 'enums.tasks.type.motor-hours';
    default: return 'enums.tasks.type.unknown';
  }
}
