import { Control } from 'ol/control';

/**
 * Кнопка на карте
 */
export class MapButton extends Control {

  /**
   * Создание кнопки карты
   * @param content Содержимое кнопки
   * @param styleClass Стили
   * @param title Подсказка при наведении
   * @param handler Обработчик кнопки
   */
  public static create(content: string, styleClass: string, title: string, handler: (val) => void) {
    const button = document.createElement('button');
    button.innerHTML = content;
    button.addEventListener('click', handler, false);
    button.addEventListener('touchstart', handler, false);
    if (title && title !== '') { button.title = title; }

    const element = document.createElement('div');
    element.className = `${styleClass} ol-unselectable ol-control`;
    element.appendChild(button);

    return new MapButton({ element });
  }

  /**
   * Создание кнопки настроек
   * @param handler Обработчик кнопки
   * @param title Заголовок
   */
  public static createSettingsButton(handler: (val) => void, title) {
    const content = '<i class="fa fa-sliders"></i>';
    const styleClass = 'map-source';
    return MapButton.create(content, styleClass, title, handler);
  }

  /**
   * Создание кнопки линейки
   * @param handler Обработчик кнопки
   * @param title Заголовок
   */
  public static createMeasureButton(handler: (val) => void, title) {
    const content = '<img src="assets/images/measure.png" class="">';
    const styleClass = 'map-measure';
    return MapButton.create(content, styleClass, title, handler);
  }

  /**
   * Конструктор
   * @param options Опции
   */
  private constructor(options: any/* ol.olx.control.ControlOptions*/) {
    super(options);
  }
}
