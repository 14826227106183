import { Component } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IDogovor } from '../../../../../shared/crm/dogovors/IDogovor';
import { DetailComponent } from '../../../../classes/DetailComponent';
import { IListItem } from '../../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { DillersService } from '../../../../services/dillers.service';
import { LoadingService } from '../../../../services/loading.service';
import { localeSort } from '../../../../utils/sort';
import { AccountComponent } from '../../../account/account.component';
import { SelectionType, SelectItemsComponent } from '../../../select-items/select-items.component';

/**
 * Интерфейс компонента для редактирования договора
 */
interface IDogovorsEditComponent {
  /** Идентификатор договора */
  dogovorId?: string;
  /** Признак копирования */
  copy?: boolean;
}

/**
 * Компонент для редактирования договора
 */
@Component({
  selector: 'dogovors-edit',
  templateUrl: './dogovors.edit.component.html'
})
export class DogovorsEditComponent
  extends DetailComponent<IDogovorsEditComponent>
  implements IDogovorsEditComponent {

  /** Идентификатор договора */
  public dogovorId?: string;

  /** Признак копирования */
  public copy?: boolean;

  /** Договор */
  public dogovor: IDogovor;

  /** Список учетных записей для выбора клиента по договору */
  public accounts: IListItem<string>[];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для CRUD
   * @param dillersService Сервис для работы с дилерами
   * @param loadingService Сервис отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private dillersService: DillersService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.dogovor = {} as IDogovor;
  }

  /**
   * Получение признака возможности сохранения
   */
  public get isCanSave(): boolean {
    return !!this.dogovor?.dogDate
      && !!this.dogovor?.dogNo
      && !!this.dogovor?.client?.id;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IDogovorsEditComponent) {
    const action = data.dogovorId ? data.copy ? 'copy' : 'edit' : 'add';
    this.title = `component.crm.contracts.edit.contract-${action}-title`;

    if (!data.dogovorId) {
      return super.fillData(data);
    }

    this.loadingService.wrap(this.crudService.get(data.dogovorId, CRUDEntityType.CONTRACT), true)
      .subscribe((dogovor) => {
        this.dogovor = dogovor;

        if (data.copy) {
          delete this.dogovor.id;
        }
      });

    return super.fillData(data);
  }

  /**
   * Клик по кнопке отображения детальной информации
   * по клиенту (учетной записи)
   * @param client Учетная запись (клиент)
   */
  public showClientDetail(client: IListItem<any>) {
    if (!client) {
      return;
    }

    const data = { accountId: client.id };
    this.dialogService.addDialog(AccountComponent, data).subscribe();
  }

  /**
   * Клик по кнопке выбора клиента (учетной записи)
   */
  public selectClient() {
    // Выбираем из загруженного списка
    if (this.accounts) {
      this.selectFromAccounts();
      return;
    }

    // Загружаем список клиентов
    this.loadingService.wrap(this.dillersService.getAccountsLight(), true)
      .subscribe((accounts) => {
        this.accounts = accounts?.sort(localeSort);
        this.selectFromAccounts();
      });

  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.loadingService.wrap(this.crudService.addUpdate(this.dogovor, CRUDEntityType.CONTRACT), true)
      .subscribe((id) => {
        this.result = id;
        this.close();
      });
  }

  /**
   * Выбор из списка клиентов (учетных записей)
   */
  private selectFromAccounts() {
    const data = {
      items: this.accounts,
      selected: this.dogovor.client ? [this.dogovor.client] : null,
      title: 'component.crm.contracts.edit.account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.dogovor.client = result?.shift());
  }
}
