/**
 * Тип графика по пробегу
 */
export enum ReportChartByMileageType {
  /** Напряжение */
  VOLTAGE = 1,
  /** Вес */
  WEIGHT,
  /** Температура */
  TEMPERATURE,
  /** Уровень топлива */
  FUEL_LEVEL,
  /** Счетчики */
  COUNTER
}

/**
 * Получение списка всех типов графиков по пробегу
 */
export function getAllReportChartByMileageTypes(): ReportChartByMileageType[] {
  const result: ReportChartByMileageType[] = [];
  for (let i = 1; i <= 5; i++) {
    result.push(i as ReportChartByMileageType);
  }
  return result;
}

/**
 * Получение наименования типа графика по пробегу
 * @param type Тип графика по пробегу
 */
export function getReportChartByMileageTypeName(type: ReportChartByMileageType): string {
  switch (type) {
    case ReportChartByMileageType.VOLTAGE: return 'enums.reports.charts.by-mileage-type.voltage';
    case ReportChartByMileageType.WEIGHT: return 'enums.reports.charts.by-mileage-type.weight';
    case ReportChartByMileageType.TEMPERATURE: return 'enums.reports.charts.by-mileage-type.temperature';
    case ReportChartByMileageType.FUEL_LEVEL: return 'enums.reports.charts.by-mileage-type.fuel';
    case ReportChartByMileageType.COUNTER: return 'enums.reports.charts.by-mileage-type.counters';
    default: return 'enums.reports.charts.by-mileage-type.unknown';
  }
}
