import { IGeozoneGroup } from '../../shared/geozones/IGeozoneGroup';

import { IClientGeozone } from './IClientGeozone';

/** Группа геозон для отображения в режиме слежения */
export class TrackingGeozoneGroup implements IGeozoneGroup {
  /** Идентификатор записи */
  public id: string;
  /** Наименование */
  public name: string;
  /** Идентификатор учетной записи */
  public accountId: string;
  /** Список идентификаторов геозон группы */
  public geozones: string[];
  /** Описание группы геозон */
  public descr?: string;
  /** Список геозон группы */
  public trackingGeozones: IClientGeozone[];
  /** Признак необходимости отображения геозон группы */
  public showGeozones?: boolean;

  /**
   * Конструктор
   * @param group Информация о группе геозон
   */
  constructor(group: IGeozoneGroup) {
    this.id = group.id;
    this.name = group.name;
    this.accountId = group.accountId;
    this.geozones = group.geozones;
    this.descr = group.descr;
    this.trackingGeozones = [];
    this.showGeozones = true;
  }

  /**
   * Получение признака выбранности группы
   */
  public get checked() {
    return this.trackingGeozones.length && this.trackingGeozones.every((u) => u.checked);
  }
}
