import { Component, Input, OnInit } from '@angular/core';

import {
  getAllRightEntityTypes,
  getRightEntityTypeName,
  RightEntityType
} from '../../../shared/rights/RightEntityType';
import {
  getAccountRightTypeName,
  getAllAccountRights,
  getAllRights,
  getAllUnitRights,
  getAllUserRights,
  getRightTypeName,
  getUnitRightTypeName,
  getUserRightTypeName,
  RightType
} from '../../../shared/rights/RightType';
import { IClientObjectRight } from '../../classes/IClientObjectRight';
import { IListItem } from '../../classes/IListItem';
import { IRightGen } from '../../classes/IRightGen';
import { IUserRights } from '../../classes/IUserRights';
import { LoadingService } from '../../services/loading.service';
import { RightsService, RightTemplateType } from '../../services/rights.service';

// tslint:disable:no-bitwise

/**
 * Компонент для управления правами доступа пользователя
 */
@Component({
  selector: 'user-rights',
  templateUrl: './user-rights.component.html',
  styleUrls: ['./user-rights.component.scss']
})
export class UserRightsComponent implements OnInit {

  /** Идентификатор пользователя */
  @Input('userId')
  public userId: string;

  /** Права доступа пользователя */
  @Input('userRights')
  public userRights: IUserRights;

  /** Права на выбранный тип объектов права */
  public rights: IClientObjectRight[] = [];

  /** Права на выбранный объект права */
  public selectedRight: IClientObjectRight;

  /** Заголовок блока со специальными правами */
  public specialRightsTitle: string;

  /** Текст ошибки */
  public error: string;

  /** Основные права */
  public generalRights: IRightGen<RightType>[] = [];

  /** Специальные права */
  public specialRights: IRightGen<number>[] = [];

  /** Строка поиска по объектам права */
  public search: string;

  /** Тип объектов права */
  public rightEntityType: RightEntityType;

  /** Список всех типов объектов права */
  public rightEntityTypes: IListItem<RightEntityType>[] = [];

  /** Типы шаблоно прав */
  public rightTemplateType = RightTemplateType;

  /** Типы прав */
  public rightEntity = RightEntityType;

  /** Права текущего юзера на объект */
  public currentUserRights: number;

  /**
   * Конструктор
   * @param rightsService Сервис работы с правами
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private rightsService: RightsService,
    private loadingService: LoadingService
  ) {
    this.rightEntityTypes = getAllRightEntityTypes()
      .map((id) => ({ id, name: getRightEntityTypeName(id) }));

    this.rightEntityType = RightEntityType.UNIT;
  }

  /**
   * Выбор прав по шаблону
   * @param type
   */
  public changeAccess(type: RightTemplateType) {
    switch (type) {
      case RightTemplateType.DENIED:
        this.selectedRight.newValue = 0
        break
      case RightTemplateType.READ:
        this.selectedRight.newValue = 142692899
        break
      case RightTemplateType.FULL_NO_DEL:
        this.selectedRight.newValue = 2146435063
    }

    this.setSelectedRight(this.selectedRight)
    this.onRightItemClick();
  }

  /**
   * Выбран шаблон без доступа
   */
  public get isDeniedTemplate() {
    return this.selectedRight?.newValue === 0
  }

  /**
   * Выбран шаблон чтение
   */
  public get isReadTemplate() {
    return this.selectedRight?.newValue === (142692899 & this.currentUserRights)
  }

  /**
   * Выбран шаблон все кроме удаления
   */
  public get isFullNoDelTemplate() {
    return this.selectedRight?.newValue === (2146435063 & this.currentUserRights)
  }

  /**
   * Обработка изменения выбранного типа права
   */
  public onRightEntityTypeChanged() {
    this.loadRights();
  }

  /**
   * Установка права в качестве выбранного
   * @param right Право
   */
  public setSelectedRight(right: IClientObjectRight) {
    right.newValue &= right.rights;
    this.selectedRight = right;

    this.generalRights = getAllRights()
      .map((type) => ({
        type,
        name: getRightTypeName(type),
        checked: !!(type & right.newValue),
        value: !!(type & right.value),
        disabled: !(type & right.rights)
      }));

    switch (this.rightEntityType) {
      case RightEntityType.UNIT:
        this.specialRightsTitle = 'component.rights.unit-rights';
        this.specialRights = getAllUnitRights()
          .map((type) => ({
            type,
            name: getUnitRightTypeName(type),
            checked: !!(type & right.newValue),
            value: !!(type & right.value),
            disabled: !(type & right.rights)
          }));
        break;

      case RightEntityType.ACCOUNT:
        this.specialRightsTitle = 'component.rights.account-rights';
        this.specialRights = getAllAccountRights()
          .map((type) => ({
            type,
            name: getAccountRightTypeName(type),
            checked: !!(type & right.newValue),
            value: !!(type & right.value),
            disabled: !(type & right.rights)
          }));
        break;

      case RightEntityType.USER:
        this.specialRightsTitle = 'component.rights.user-rights';
        this.specialRights = getAllUserRights()
          .map((type) => ({
            type,
            name: getUserRightTypeName(type),
            checked: !!(type & right.newValue),
            value: !!(type & right.value),
            disabled: !(type & right.rights)
          }));
        break;

      case RightEntityType.UNIT_GROUP:
        this.specialRightsTitle = 'component.rights.group-rights';
        this.specialRights = getAllUnitRights()
          .map((type) => ({
            type,
            name: getUnitRightTypeName(type),
            checked: !!(type & right.newValue),
            value: !!(type & right.value),
            disabled: !(type & right.rights)
          }));
        break;
    }
  }

  /**
   * Обработка клика по элементу права
   * @param item Элемент права
   */
  public onRightItemClick(item?: IRightGen<number>) {
    if (!this.selectedRight || item?.disabled) {
      return;
    }

    let result = 0;
    for (const right of this.generalRights) {
      if (right.checked) {
        result |= right.type;
      }
    }
    for (const right of this.specialRights) {
      if (right.checked) {
        result |= right.type;
      }
    }
    this.selectedRight.newValue = result;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadRights();
  }

  /**
   * Загрузка значений прав доступа
   */
  private loadRights() {
    switch (this.rightEntityType) {
      case RightEntityType.UNIT:
        this.rights = this.userRights.units;
        break;
      case RightEntityType.ACCOUNT:
        this.rights = this.userRights.accounts;
        break;
      case RightEntityType.USER:
        this.rights = this.userRights.users;
        break;
      case RightEntityType.UNIT_GROUP:
        this.rights = this.userRights.groups;
        break;
    }

    if (this.rights.length) {
      this.selectedRight = this.rights[0];
      this.setSelectedRight(this.rights[0]);
    } else {
      this.rightsService.getSubjectRights(this.userId, this.rightEntityType)
        .subscribe((rights) => {
          rights.map((right) => ({
              ...right,
              newValue: right.value
            })
          ).forEach((right) => this.rights.push(right));

          if (this.rights.length) {
            this.currentUserRights = this.rights[0]?.rights
            this.setSelectedRight(this.rights[0]);
          }
        });
    }
  }
}
