import { IMessageColumn } from './IMessageColumn';
import { MessageType } from './MessageType';

/**
 * Получение списка колонок по типу сообщений
 * @param type Тип сообщений
 */
export function getMessageTypeColumns(type: MessageType): IMessageColumn[] {
  switch (type) {
    case MessageType.EVENT: return getEventMessagesColumns();
    case MessageType.POINTS: return getPointMessagesColumns();
    default: return [];
  }
}

/**
 * Получение списка колонок для событий
 */
function getEventMessagesColumns(): IMessageColumn[] {
  return [
    { name: 'enums.messages.columns.number', width: 30 },
    { name: 'enums.messages.columns.time', field: 'date', isDate: true, width: 130 },
    { name: 'enums.messages.columns.type', field: 'type', width: 150 },
    { name: 'enums.messages.columns.text', field: 'descr', width: 500 }
  ];
}

/**
 * Получение списка колонок для сообщений с данными
 */
function getPointMessagesColumns(): IMessageColumn[] {
  return [
    { name: 'enums.messages.columns.number', width: 30 },
    { name: 'enums.messages.columns.time', field: 'time', isDate: true, width: 130 },
    { name: 'enums.messages.columns.server-time', field: 'createdAt', isDate: true, width: 130 },
    { name: 'enums.messages.columns.speed', field: 'speed', width: 90 },
    { name: 'enums.messages.columns.azimuth', field: 'bearing', width: 90 },
    { name: 'enums.messages.columns.lat', field: 'lat', width: 130 },
    { name: 'enums.messages.columns.lon', field: 'lon', width: 130 },
    { name: 'enums.messages.columns.validity', field: 'valid', width: 100 },
    { name: 'enums.messages.columns.tValidity', field: 'tValid', width: 150 },
    { name: 'enums.messages.columns.params', width: 0 }
  ];
}
