import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

/**
 * Сортировка прав доступа (сначала идут записи, на которые есть права, а потом по алфавиту)
 */
@Pipe({
  name: 'rightsOrderBy',
  pure: false
})
export class RightsOrderByPipe implements PipeTransform {
  public transform(items: any[], sort: string): any {
    if (!items || !sort || !items.length) {
      return items;
    }

    return _(items).chain()
      .sortBy((item) => (item[sort] as string).toLowerCase())
      .sortBy((item) => (item.newValue || item.combined) ? 0 : 1)
      .value();
  }
}
