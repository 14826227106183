import { Component } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IAccountExport, IAccountExportSettings } from '../../../../shared/accounts/IAccountExportSettings';
import { IAccountListItem } from '../../../../shared/accounts/IAccountListItem';
import { AccountRightType } from '../../../../shared/rights/RightType';
import { ExportImportService } from '../../../services/export-import.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ImportAccountsComponent } from '../../import/accounts/import.accounts.component';

/**
 * Интерфейс компонента для экспорта данных учетных записей
 */
interface IExportAccountsComponent {
  /** Список учетных записей */
  accounts: IAccountListItem[];
}

/**
 * Компонент для экспорта данных учетных записей
 */
@Component({
  selector: 'export-accounts',
  templateUrl: './export.accounts.component.html'
})
export class ExportAccountsComponent
  extends DialogComponent<IExportAccountsComponent, boolean>
  implements IExportAccountsComponent {

  /** Список учетных записей */
  public accounts: IAccountListItem[] = [];

  /** Список учетных записей, выбранных для экспорта */
  public checkedAccounts: IAccountListItem[] = [];

  /** Настройки экспорта */
  public settings: IAccountExportSettings;

  /** Строка поиска по наименованиям учетных записей */
  public search: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param exportImportService Сервис импорта/экспорта
   * @param modalService Сервис модальных окон
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private exportImportService: ExportImportService,
    private modalService: ModalService
  ) {
    super(dialogService);
    this.settings = { exportInAccount: false } as IAccountExportSettings;
  }

  /**
   * Получение признака видимости для пользователя геозон
   */
  public get isCanViewGeozones() {
    let result = AccountRightType.VIEW_GEOZONES;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_GEOZONES;
  }

  /**
   * Получение признака видимости для пользователя заданий
   */
  public get isCanViewTasks() {
    let result = AccountRightType.VIEW_TASKS;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_TASKS;
  }

  /**
   * Получение признака видимости для пользователя уведомлений
   */
  public get isCanViewNotifications() {
    let result = AccountRightType.VIEW_NOTIFICATIONS;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_NOTIFICATIONS;
  }

  /**
   * Получение признака видимости для пользователя шаблонов отчетов
   */
  public get isCanViewReportTemplates() {
    let result = AccountRightType.VIEW_REPORT_TEMPLATES;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_REPORT_TEMPLATES;
  }

  /**
   * Получение признака видимости для пользователя водителей
   */
  public get isCanViewDrivers() {
    let result = AccountRightType.VIEW_DRIVERS;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_DRIVERS;
  }

  /**
   * Получение признака видимости для пользователя прицепов
   */
  public get isCanViewTrailers() {
    let result = AccountRightType.VIEW_TRAILERS;
    for (const account of this.checkedAccounts) {
      // tslint:disable-next-line:no-bitwise
      result &= account.userRights;
      if (!result) { return result; }
    }

    return AccountRightType.VIEW_TRAILERS;
  }

  /**
   * Переключение выбранности учетной записи
   * @param account Учетная запись
   */
  public toggleChecked(account: IAccountListItem) {
    const checkedIndex = this.checkedAccounts.indexOf(account);
    if (checkedIndex !== -1) {
      this.checkedAccounts.splice(checkedIndex, 1);
      this.accounts.push(account);
    } else {
      // Если экспорт в другую учетную запись, то выбрать можно только одну запись
      if (this.settings.exportInAccount && this.checkedAccounts.length) {
        this.toggleChecked(this.checkedAccounts[0]);
      }
      const index = this.accounts.indexOf(account);
      if (index !== -1) {
        this.accounts.splice(index, 1);
        this.checkedAccounts.push(account);
      }
      this.updateExportSettings();
    }
  }

  /**
   * Получение признака возможности продолжения экспорта
   */
  public get isCanContinue() {
    return this.checkedAccounts.length &&
      (this.settings.exportInAccount || (this.settings.fileName &&
        this.settings.fileName !== '' && this.settings.fileName.match(/^[^<>:;,?"*|/]+$/))) &&
      (this.settings.geozones || this.settings.tasks || this.settings.notifications ||
        this.settings.reportTemplates || this.settings.drivers || this.settings.trailers);
  }

  /**
   * Продолжение экспорта
   */
  public continue() {
    if (!this.isCanContinue) {
      return;
    }

    this.settings.accountIds = this.checkedAccounts.map((a) => a.id);
    if (this.settings.exportInAccount) {
      this.loadingService.wrap(this.exportImportService.exportAccounts(this.settings), true)
        .subscribe((accountExport: IAccountExport) => {
          const data = {
            accounts: [...this.accounts, ...this.checkedAccounts],
            importAccount: accountExport
          };

          this.dialogService.addDialog(ImportAccountsComponent, data)
            .pipe(filter((result) => !!result))
            .subscribe(() => this.close());
        });

    } else {
      this.loadingService.wrap(this.exportImportService.exportAccountsFile(this.settings), true)
        .subscribe((blob) => {
          let fileName = this.settings.fileName;
          fileName += this.settings.accountIds.length > 1 ? '.zip' : '.bga';
          FileSaver.saveAs(blob, fileName);
          this.close();
        });
    }
  }

  /**
   * Обновление настроек экспорта
   */
  private updateExportSettings() {
    if (!this.isCanViewGeozones) {
      this.settings.geozones = false;
    }
    if (!this.isCanViewTasks) {
      this.settings.tasks = false;
    }
    if (!this.isCanViewNotifications) {
      this.settings.notifications = false;
    }
    if (!this.isCanViewReportTemplates) {
      this.settings.reportTemplates = false;
    }
    if (!this.isCanViewDrivers) {
      this.settings.drivers = false;
    }
    if (!this.isCanViewTrailers) {
      this.settings.trailers = false;
    }
  }
}
