import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

import { LoadingService } from './loading.service';

/**
 * Сервис всплывающих сообщений
 */
@Injectable()
export class ToastService {

  private isDisconnect = false;

  /**
   * Конструктор
   * @param messageService Сервис сообщений
   * @param loadingService
   */
  constructor(
    private messageService: MessageService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Удаляем сообщение о дисконекте
   */
  public connect(): void {
    this.isDisconnect = false
    this.messageService.clear('disconnect');
  }

  /**
   * Отправка сообщения при отвале соединения
   */
  public disconnect(): void {
    if (this.isDisconnect) return;
    this.isDisconnect = true;
    this.loadingService.stopLoading()
    this.messageService.add({
      key: 'disconnect',
      severity: 'error',
      summary: 'component.modal.error',
      detail: 'errors.connection-lost',
      closable: false,
      life: 10000000
    })
  }

  /**
   * Отправка сообщения об ошибке
   * @param text Текст сообщения
   */
  public error(text: string): void {
    this.loadingService.stopLoading()
    this.messageService.add({
      key: 'tl',
      severity: 'error',
      summary: 'component.modal.error',
      detail: text,
      closable: true,
      life: 10000
    })
  }

  /**
   * Отправка сообщения с предупреждением
   * @param text Текст сообщения
   */
  public warn(text: string): void {
    this.loadingService.stopLoading()
    this.messageService.add({
      key: 'tl',
      severity: 'warn',
      summary: 'component.modal.warn',
      detail: text,
      closable: true,
      life: 10000
    })
  }

  /**
   * Отправка информационного сообщения
   * @param text Текст сообщения
   */
  public info(text: string): void {
    this.loadingService.stopLoading()
    this.messageService.add({
      key: 'tl',
      severity: 'info',
      summary: 'component.modal.info',
      detail: text,
      closable: true,
      life: 5000
    })
  }

  /**
   * Отправка сообщения об успешном выполнении
   * @param text Текст сообщения
   */
  public success(text: string): void {
    this.loadingService.stopLoading()
    this.messageService.add({
      key: 'tl',
      severity: 'success',
      detail: text,
      life: 5000
    })
  }
}
