import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';

import { ICoord } from '../../shared/ICoord';
import { IItemGeneric } from '../../shared/IItemGeneric';
import { SensorType } from '../../shared/sensors/SensorType';
import { ISpeedColor } from '../../shared/units/IExtras';
import { TrackColoringType } from '../../shared/units/TrackColoringType';

import { ConfigService } from './config.service';
import { MonitoringService } from './monitoring.service';

/**
 * Сервис для работы с рейсом
 */
@Injectable()
export class RaceService {

  /**
   * События рейса
   */
  public events: IRaceEvent[] = [];

  /**
   * Идентификатор выбранного объекта
   */
  public unitId: string;

  /**
   * Начало периода
   */
  public from: Date;

  /**
   * Конец периода
   */
  public to: Date;

  /**
   * Толщина линии
   */
  public lineWidth: number;

  /**
   * Признак настраиваемой окраски
   */
  public customColoring: boolean;

  /**
   * Тип окраски трека
   */
  public coloringType: TrackColoringType;

  /**
   * Цвет для однотонного типа окраски трека
   */
  public monoColor: Color;

  /**
   * Цвета по скорости
   */
  public speedColors: ISpeedColor[];

  /**
   * Выбранный датчик
   */
  public selectedSensor: string;

  /**
   * Признак скрытия интервалов недвижения
   */
  public hide: boolean;

  /**
   * Список рейсов
   */
  public races: IClientRace[];

  /**
   * Рейс для создания геозон
   */
  public raceForGeozone: IClientRace;

  /**
   * Базовый путь для запросов сервиса
   */
  private readonly baseUrl = `${this.configService.url}/race`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param monitoringService Сервис мониторинга
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private monitoringService: MonitoringService,
    private configService: ConfigService
  ) {
    this.init();
  }

  /**
   * Загрузка рейса
   * @param unit Объект, по которому необходимо загрузить рейс
   */
  public loadRace(unit: IRaceUnit) {
    if (!unit) {
      return;
    }

    const params: IRaceRequest = {
      uid: unit.id,
      f: this.from.getTime(),
      t: this.to.getTime(),
      e: this.events.filter((event) => event.selected).map((event) => event.type),
      ct: this.customColoring ? this.coloringType : unit.trackColoringType,
      cv: this.getColoringValue(unit),
      hide: this.hide
    };

    return this.loadRaceBase(unit, params);
  }

  /**
   * Загрузка рейса
   * @param unit Объект мониторинга
   * @param params Параметры запроса рейса
   */
  public loadRaceBase(unit: IRaceUnit, params: IRaceRequest): Observable<IClientRace> {
    return this.getRace(params).pipe(map((race) => {
      const clientRace: IClientRace = {
        ...race,
        unitName: unit.name,
        checked: true,
        lineWidth: this.lineWidth,
        color: this.calcRaceColor(params, race),
        playPosition: 0,
        isPlaying: false,
        eye: false,
        icon: unit.icon,
        rotate: unit.rotate,
        labelColor: unit.color
      };
      this.races.push(clientRace);
      this.monitoringService.showRaceSubject.next(clientRace);
      return clientRace
    }))
  }

  /**
   * Добавление записи в таблицу цветов по скорости
   */
  public addSpeedColor() {
    this.speedColors.push({ speed: 0, color: DefaultColor });
  }

  /**
   * Удаление записи из таблицы цветов по скорости
   * @param item Удаляемая запись
   */
  public deleteSpeedColor(item: ISpeedColor) {
    const index = this.speedColors.indexOf(item);
    if (index > -1) {
      this.speedColors.splice(index, 1);
    }
  }

  /**
   * Отображение загруженных рейсов на карте
   * (в случае, когда производится возврат в режим мониторинга из другого режима)
   */
  public showLoadedRaces() {
    timer(1000).subscribe(
      () => {
        for (const race of this.races) {
          this.monitoringService.showRaceSubject.next(race);
        }
      }
    );
  }

  /**
   * Изменение выбранности рейса
   * @param race Рейс
   */
  public toggleRaceChecked(race: IClientRace) {
    race.checked = !race.checked;
    if (race.checked) {
      this.monitoringService.showRaceSubject.next(race);
    } else {
      race.isPlaying = false;
      this.monitoringService.hideRaceSubject.next(race);
    }
  }

  /**
   * Установка признака выбранности для всех рейсов
   * @param checked Признак выбранности
   */
  public setAllRacesChecked(checked: boolean) {
    for (const race of this.races) {
      if (race.checked !== checked) {
        this.toggleRaceChecked(race);
      }
    }
  }

  /**
   * Удаление рейса
   * @param race Рейс
   */
  public deleteRace(race: IClientRace) {
    if (race.checked) {
      race.checked = false;
      this.monitoringService.hideRaceSubject.next(race);
    }
    const index = this.races.indexOf(race);
    if (index > -1) {
      this.races.splice(index, 1);
    }
  }

  /**
   * Удаление всех рейсов
   */
  public deleteAllRaces() {
    for (let i = this.races.length - 1; i >= 0; i--) {
      this.deleteRace(this.races[i]);
    }
  }

  /**
   * Перемещение позиции на начало рейса
   * @param race Рейс
   */
  public moveToStartRace(race: IClientRace) {
    if (!race.p.length) {
      return;
    }
    const firstPoint = race.p[0];
    this.monitoringService.moveToPointSubject.next(firstPoint);
  }

  /**
   * Перемещение позиции на конец рейса
   * @param race Рейс
   */
  public moveToEndRace(race: IClientRace) {
    if (!race.p.length) {
      return;
    }
    const lastPoint = race.p[race.p.length - 1];
    this.monitoringService.moveToPointSubject.next(lastPoint);
  }

  /**
   * Перемещение маркера на точку рейса
   * @param race Рейс
   * @param point Точка рейса
   */
  public animateRace(race: IClientRace, point: IRacePoint) {
    this.monitoringService.animateRaceSubject.next({ race, point });
  }

  /**
   * Очистка данных сервиса
   */
  public clean() {
    this.init();
  }

  /**
   * Получение объекта для отображения в панели создания рейсов
   * @param unitId Идентификатор объекта
   */
  public getUnit(unitId: string): Observable<IRaceUnit> {
    const url = `${this.baseUrl}/units/${unitId}`;
    return this.http.get<IRaceUnit>(url);
  }

  /**
   * Получение рейса
   * @param body Параметры рейса
   */
  public getRace(body: IRaceRequest): Observable<IRace> {
    return this.http.post<IRace>(this.baseUrl, body);
  }

  /**
   * Инициализация параметров сервиса
   */
  private init() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, -1);

    this.unitId = null;
    this.lineWidth = 4;
    this.customColoring = false;
    this.coloringType = TrackColoringType.MONO;
    this.monoColor = DefaultColor;
    this.selectedSensor = null;
    this.speedColors = [];
    this.hide = false;
    this.races = [];

    this.events = getAllRaceEventTypes()
      .map((type) => ({
        selected: false,
        name: getRaceEventTypeName(type),
        image: getRaceEventTypeImageName(type),
        type
      }));
  }

  /**
   * Расчет основного цвета рейса
   * @param params Параметры запроса рейса
   * @param race Рейс
   */
  private calcRaceColor(params: IRaceRequest, race: IRace): Color {
    if (params.ct === TrackColoringType.MONO) {
      return params.cv as Color;
    }

    if (!race.s.length) {
      return DefaultColor;
    }

    const durations: Dict<Duration> = {};
    for (const segment of race.s) {
      if (!durations[segment.c]) {
        durations[segment.c] = 0;
      }
      durations[segment.c] += segment.e - segment.s + 1;
    }

    return _.max(Object.keys(durations), (color) => durations[color]);
  }

  /**
   * Получение значения окраски трека
   * @param unit Объект мониторинга
   */
  private getColoringValue(unit: IRaceUnit): ColoringValue {
    if (this.customColoring) {
      switch (this.coloringType) {
        case TrackColoringType.MONO:
          return this.monoColor || DefaultColor;
        case TrackColoringType.BY_SPEED:
          return this.speedColors;
        case TrackColoringType.BY_SENSOR:
          return this.selectedSensor;
        default:
          return DefaultColor;
      }
    } else {
      switch (unit.trackColoringType) {
        case TrackColoringType.MONO:
          return unit.trackColorMono || DefaultColor;
        case TrackColoringType.BY_SPEED:
          return unit.trackColorBySpeed || [];
        case TrackColoringType.BY_SENSOR:
          return unit.trackColorBySensor;
        default:
          return DefaultColor;
      }
    }
  }
}

/**
 * Параметры запроса рейса
 */
export interface IRaceRequest {
  /** Идентификатор объекта */
  uid: string;

  /** Начало периода */
  f: number;

  /** Конец периода */
  t: number;

  /** Список событий рейса */
  e?: RaceEventType[];

  /** Тип окраски трека */
  ct?: TrackColoringType;

  /** Значение окраски трека */
  cv?: string | ISpeedColor[];

  /** Признак скрытия интервалов недвижения */
  hide?: boolean;
}

/**
 * Рейс на клиенте
 */
export interface IClientRace extends IRace {
  /** Наименование объекта мониторинга */
  unitName: string;
  /** Признак выбранного рейса */
  checked: boolean;
  /** Толщина линии трека */
  lineWidth: number;
  /** Цвет трека */
  color: string;
  /** Позиция в проигрывателе */
  playPosition: number;
  /** Признак воспроизведения рейса */
  isPlaying: boolean;
  /** Признак слежения за объектом в рейсе */
  eye: boolean;
  /** Иконка */
  icon: string;
  /** Признак вращения иконки */
  rotate: boolean;
  /** Цвет подписи к объекту мониторинга */
  labelColor: string;
}

/**
 * Рейс
 */
export interface IRace {
  /** Идентификатор объекта */
  uid: string;

  /** Начало периода */
  f: number;

  /** Конец периода */
  t: number;

  /** Пробег */
  mi: number;

  /** Моточасы */
  mh: number;

  /** Точки рейса */
  p: IRacePoint[];

  /** Отрезки рейса */
  s: IRaceSegment[];

  /** Датчики, используемые в рейсе */
  sens?: IRaceSensor[];
}

/**
 * Отрезок рейса
 */
export interface IRaceSegment {

  /** Индекс начала отрезка */
  s: number;

  /** Индекс окончания рейса */
  e: number;

  /** Цвет отрезка */
  c: string;

  /** Наименование отрезка */
  n?: string;

  /** Признак неточных данных */
  i?: boolean;
}

/**
 * Точка рейса
 */
export interface IRacePoint extends ICoord {
  /** Время */
  t: number;

  /** Скорость */
  s: number;

  /** Азимут */
  b: number;

  /** Пробег */
  mi?: number;

  /** Моточасы */
  mh?: number;

  /** События в точке */
  e?: IRaceEventInfo[];

  /** Значения датчиков */
  sv?: number[];

  /** Параметры точки рейса */
  p?: any;

  /** Признак скрытия */
  h?: boolean;

  /** Адрес точки */
  a?: string;
}

/**
 * Датчик в рейсе
 */
export interface IRaceSensor {
  /** Идентификатор */
  i: string;

  /** Тип датчика */
  t: SensorType;

  /** Наименование */
  n: string;

  /** Единицы измерения */
  m?: string;
}

/**
 * Событие в рейсе
 */
export interface IRaceEventInfo {
  /** Тип события */
  t: RaceEventType;
  /** Время, когда произошло событие */
  tm: number;
  /** Длительность в секундах (кроме события "Событие") */
  s: number;
  /** Максимальная скорость (для события "Превышение скорости") */
  m: number;
  /** Кол-во топлива (для событий "Запрвка", "Слив") */
  v: number;
  /** Признак нарушения (для события "Событие") */
  vi: boolean;
  /** Наименование (для события "Событие") */
  n: string;
  /** Описание (для события "Событие") */
  d: string;
}

/**
 * Объект мониторинга для построения рейса
 */
export interface IRaceUnit {

  /** Идентификатор записи */
  id: string;

  /** Наименование */
  name: string;

  /** Тип окрашивания трека */
  trackColoringType?: TrackColoringType;

  /** Цвет однотонной окраски трека */
  trackColorMono?: string;

  /** Цвета окраски трека по скорости */
  trackColorBySpeed?: ISpeedColor[];

  /** Датчик, для случая окраски трека по датчику */
  trackColorBySensor?: string;

  /** Список датчиков с интервалами */
  sensors?: IItemGeneric<string>[];

  /** Иконка */
  icon: string;

  /** Признак вращения иконки */
  rotate: boolean;

  /** Цвет подписи */
  color: string;
}

export interface IRaceEvent {
  selected: boolean;
  name: string;
  image: string;
  type: RaceEventType;
}

export enum RaceEventType {
  SPEEDING = 1,
  FILLING,
  THEFT,
  STOP,
  PARKING,
  EVENT
}

export function getAllRaceEventTypes() {
  const result: RaceEventType[] = [];
  for (let i = 1; i <= 6; i++) {
    result.push(i as RaceEventType);
  }
  return result;
}

export function getRaceEventTypeName(type: RaceEventType) {
  switch (type) {
    case RaceEventType.SPEEDING:
      return 'enums.race.event-type.speeding';
    case RaceEventType.FILLING:
      return 'enums.race.event-type.fueling';
    case RaceEventType.THEFT:
      return 'enums.race.event-type.theft';
    case RaceEventType.STOP:
      return 'enums.race.event-type.stop';
    case RaceEventType.PARKING:
      return 'enums.race.event-type.parking';
    case RaceEventType.EVENT:
      return 'enums.race.event-type.events';
    default:
      return 'enums.race.event-type.unknown';
  }
}

export function getRaceEventTypeImageName(type: RaceEventType) {
  switch (type) {
    case RaceEventType.SPEEDING:
      return 'speeding';
    case RaceEventType.FILLING:
      return 'filling';
    case RaceEventType.THEFT:
      return 'theft';
    case RaceEventType.STOP:
      return 'stop';
    case RaceEventType.PARKING:
      return 'parking';
    default:
      return 'event';
  }
}

/**
 * Цвет по умолчанию для трека
 */
const DefaultColor = '#0000ff';

type Color = string;
type Duration = number;
type ColoringValue = Color | ISpeedColor[];
