import { Component, ElementRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMyDate, IMyDateModel, IMyDpOptions } from 'mydatepicker';

import { BgPopoverComponent } from '../bg-popover';
import { BgPopoverService } from '../bg-popover';

interface IBgDatetimepickerPopoverComponent {
  date: Date;
  /** Признак того, что необходимо отображать только календарь, без часов */
  onlyDate: boolean;
  onClose: (date: Date) => void;
  /** Ограничение прошлого периода */
  disableUntil?: Date;
  /** Ограничение будущего периода */
  disableSince?: Date;
  /**
   * Признак отображения кнопки confirm
   */
  isConfirmBtn: boolean;
}

@Component({
  selector: 'bg-datetimepicker-popover',
  templateUrl: './bg-datetimepicker.popover.component.html',
  styleUrls: ['./bg-datetimepicker.popover.component.scss']
})
export class BgDatetimepickerPopoverComponent
extends BgPopoverComponent<IBgDatetimepickerPopoverComponent>
implements IBgDatetimepickerPopoverComponent, OnDestroy {

  public date: Date;
  /** Признак того, что необходимо отображать только календарь, без часов */
  public onlyDate: boolean;
  public onClose: (date: Date) => void;

  public mdpValue: IMyDateModel;
  public mdpOptions: IMyDpOptions = { inline: true };
  /**
   * Признак отображения кнопки confirm
   */
  public isConfirmBtn: boolean = true;

  /** Ограничение прошлого периода */
  public disableUntil: Date;

  /** Ограничение будущего периода */
  public disableSince: Date;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   * @param elem Обёртка для элемента
   * @param translator Сервис для перевода
   */
  constructor(
    public popoverService: BgPopoverService,
    public elem: ElementRef,
    public translator: TranslateService
  ) {
    super(popoverService);
  }

  public fillData(data: IBgDatetimepickerPopoverComponent) {
    this.mdpValue = { jsdate: data.date } as IMyDateModel;
    this.mdpOptions.disableUntil = this.dateToDate(data.disableUntil);
    this.mdpOptions.disableSince = this.dateToDate(data.disableSince);
    super.fillData(data);
  }

  public updateMdpDate() {
    if (!this.mdpValue.jsdate) { return; }

    const oldValue = this.date || new Date();
    this.date = new Date(
      this.mdpValue.jsdate.getFullYear(),
      this.mdpValue.jsdate.getMonth(),
      this.mdpValue.jsdate.getDate(),
      oldValue.getHours(),
      oldValue.getMinutes(),
      oldValue.getSeconds(),
      oldValue.getMilliseconds()
    );

    if(!this.isConfirmBtn) this.popoverService.removePopover()
  }

  /**
   * Преобразуем дату
   * @param date Дата
   */
  private dateToDate(date: Date): IMyDate {
    return { year: date?.getFullYear() || 0, month: date?.getMonth() + 1 || 0, day: date?.getDate() || 0 }
  }

  /**
   * Добавление часов для даты начала периода
   */
  public addHours() {
    let newHours = this.date.getHours() + 1;
    if (newHours >= 24) { newHours = 0; }

    this.setHours(newHours);
  }

  /**
   * Вычитание часов для даты начала периода
   */
  public subHours() {
    let newHours = this.date.getHours() - 1;
    if (newHours < 0) { newHours = 23; }

    this.setHours(newHours);
  }

  /**
   * Добавление минут для даты начала периода
   */
  public addMinutes() {
    let newMinutes = this.date.getMinutes() + 1;
    if (newMinutes >= 60) { newMinutes = 0; }

    this.setMinutes(newMinutes);
  }

  /**
   * Вычитание минут для даты начала периода
   */
  public subMinutes() {
    let newMinutes = this.date.getMinutes() - 1;
    if (newMinutes < 0) { newMinutes = 59; }

    this.setMinutes(newMinutes);
  }

  /**
   * Установка часов для даты начала периода
   * @param value Значение часов
   */
  public setHours(value: number) {
    const oldFrom = this.date;
    this.date = new Date(
      oldFrom.getFullYear(),
      oldFrom.getMonth(),
      oldFrom.getDate(),
      value,
      oldFrom.getMinutes(),
      oldFrom.getSeconds(),
      oldFrom.getMilliseconds()
    );
  }

  /**
   * Установка минут для даты начала периода
   * @param value Значение минут
   */
  public setMinutes(value: number) {
    const oldFrom = this.date;
    this.date = new Date(
      oldFrom.getFullYear(),
      oldFrom.getMonth(),
      oldFrom.getDate(),
      oldFrom.getHours(),
      value,
      oldFrom.getSeconds(),
      oldFrom.getMilliseconds()
    );
  }

  /** Получение часов даты начала периода */
  public get hours() { return this.date ? this.date.getHours() : 0; }

  /** Установка часов даты начала периода */
  public set hours(value: number) {
    if (value >= 0 && value <= 23) {
      this.setHours(value);
    }
  }

  /** Получение минут даты начала периода */
  public get minutes() { return this.date ? this.date.getMinutes() : 0; }

  /** Установка минут даты начала периода */
  public set minutes(value: number) {
    if (value >= 0 && value <= 59) {
      this.setMinutes(value);
    }
  }

  /**
   * Обработчик клика по окну
   */
  public onClick = (event: MouseEvent) => {
    // Предотвращаем распространение события,
    // чтобы при клике внутри компонента он не закрывался автоматически
    event.stopPropagation();
  }

  /**
   * Обработка при уничтожении компонента
   */
  public ngOnDestroy() {
    if (this.onClose) {
      this.onClose(this.date);
    }
  }
}
