/** коды стран */
export enum CountryCode {
  /** Undefined */
  UN,
  /** Andorra */
  AD,
  /** United Arab Emirates */
  AE,
  /** Afghanistan */
  AF,
  /** Antigua and Barbuda */
  AG,
  /** Anguilla */
  AI,
  /** Albania */
  AL,
  /** Armenia */
  AM,
  /** Angola */
  AO,
  /** Antarctica */
  AQ,
  /** Argentina */
  AR,
  /** American Samoa */
  AS,
  /** Austria */
  AT,
  /** Australia */
  AU,
  /** Aruba */
  AW,
  /** Aland Islands */
  AX,
  /** Azerbaijan */
  AZ,
  /** Bosnia and Herzegovina */
  BA,
  /** Barbados */
  BB,
  /** Bangladesh */
  BD,
  /** Belgium */
  BE,
  /** Burkina Faso */
  BF,
  /** Bulgaria */
  BG,
  /** Bahrain */
  BH,
  /** Burundi */
  BI,
  /** Benin */
  BJ,
  /** Saint-Barthélemy */
  BL,
  /** Bermuda */
  BM,
  /** Brunei Darussalam */
  BN,
  /** Bolivia */
  BO,
  /** Caribbean Netherlands */
  BQ,
  /** Brazil */
  BR,
  /** Bahamas */
  BS,
  /** Bhutan */
  BT,
  /** Bouvet Island */
  BV,
  /** Botswana */
  BW,
  /** Belarus */
  BY,
  /** Belize */
  BZ,
  /** Canada */
  CA,
  /** Cocos (Keeling) Islands */
  CC,
  /** Democratic Republic of the Congo (Congo-Kinshasa, former Zaire) */
  CD,
  /** Centrafrican Republic */
  CF,
  /** Republic of the Congo (Congo-Brazzaville) */
  CG,
  /** Switzerland */
  CH,
  /** Côte d'Ivoire */
  CI,
  /** Cook Islands */
  CK,
  /** Chile */
  CL,
  /** Cameroon */
  CM,
  /** China */
  CN,
  /** Colombia */
  CO,
  /** Costa Rica */
  CR,
  /** Cuba */
  CU,
  /** Cabo Verde */
  CV,
  /** Curaçao */
  CW,
  /** Christmas Island */
  CX,
  /** Cyprus */
  CY,
  /** Czech Republic */
  CZ,
  /** Germany */
  DE,
  /** Djibouti */
  DJ,
  /** Denmark */
  DK,
  /** Dominica */
  DM,
  /** Dominican Republic */
  DO,
  /** Algeria */
  DZ,
  /** Ecuador */
  EC,
  /** Estonia */
  EE,
  /** Egypt */
  EG,
  /** Western Sahara */
  EH,
  /** Eritrea */
  ER,
  /** Spain */
  ES,
  /** Ethiopia */
  ET,
  /** Finland */
  FI,
  /** Fiji */
  FJ,
  /** Falkland Islands */
  FK,
  /** Micronesia (Federated States of) */
  FM,
  /** Faroe Islands */
  FO,
  /** France */
  FR,
  /** Gabon */
  GA,
  /** United Kingdom */
  GB,
  /** Grenada */
  GD,
  /** Georgia */
  GE,
  /** French Guiana */
  GF,
  /** Guernsey */
  GG,
  /** Ghana */
  GH,
  /** Gibraltar */
  GI,
  /** Greenland */
  GL,
  /** The Gambia */
  GM,
  /** Guinea */
  GN,
  /** Guadeloupe */
  GP,
  /** Equatorial Guinea */
  GQ,
  /** Greece */
  GR,
  /** South Georgia and the South Sandwich Islands */
  GS,
  /** Guatemala */
  GT,
  /** Guam */
  GU,
  /** Guinea Bissau */
  GW,
  /** Guyana */
  GY,
  /** Hong Kong (SAR of China) */
  HK,
  /** Heard Island and McDonald Islands */
  HM,
  /** Honduras */
  HN,
  /** Croatia */
  HR,
  /** Haiti */
  HT,
  /** Hungary */
  HU,
  /** Indonesia */
  ID,
  /** Ireland */
  IE,
  /** Israel */
  IL,
  /** Isle of Man */
  IM,
  /** India */
  IN,
  /** British Indian Ocean Territory */
  IO,
  /** Iraq */
  IQ,
  /** Iran */
  IR,
  /** Iceland */
  IS,
  /** Italy */
  IT,
  /** Jersey */
  JE,
  /** Jamaica */
  JM,
  /** Jordan */
  JO,
  /** Japan */
  JP,
  /** Kenya */
  KE,
  /** Kyrgyzstan */
  KG,
  /** Cambodia */
  KH,
  /** Kiribati */
  KI,
  /** Comores */
  KM,
  /** Saint Kitts and Nevis */
  KN,
  /** North Korea */
  KP,
  /** South Korea */
  KR,
  /** Kuwait */
  KW,
  /** Cayman Islands */
  KY,
  /** Kazakhstan */
  KZ,
  /** Laos */
  LA,
  /** Lebanon */
  LB,
  /** Saint Lucia */
  LC,
  /** Liechtenstein */
  LI,
  /** Sri Lanka */
  LK,
  /** Liberia */
  LR,
  /** Lesotho */
  LS,
  /** Lithuania */
  LT,
  /** Luxembourg */
  LU,
  /** Latvia */
  LV,
  /** Libya */
  LY,
  /** Morocco */
  MA,
  /** Monaco */
  MC,
  /** Moldova */
  MD,
  /** Montenegro */
  ME,
  /** Saint Martin (French part) */
  MF,
  /** Madagascar */
  MG,
  /** Marshall Islands */
  MH,
  /** Macedonia (Former Yugoslav Republic of) */
  MK,
  /** Mali */
  ML,
  /** Myanmar */
  MM,
  /** Mongolia */
  MN,
  /** Macao (SAR of China) */
  MO,
  /** Northern Mariana Islands */
  MP,
  /** Martinique */
  MQ,
  /** Mauritania */
  MR,
  /** Montserrat */
  MS,
  /** Malta */
  MT,
  /** Mauritius */
  MU,
  /** Maldives */
  MV,
  /** Malawi */
  MW,
  /** Mexico */
  MX,
  /** Malaysia */
  MY,
  /** Mozambique */
  MZ,
  /** Namibia */
  NA,
  /** New Caledonia */
  NC,
  /** Niger */
  NE,
  /** Norfolk Island */
  NF,
  /** Nigeria */
  NG,
  /** Nicaragua */
  NI,
  /** The Netherlands */
  NL,
  /** Norway */
  NO,
  /** Nepal */
  NP,
  /** Nauru */
  NR,
  /** Niue */
  NU,
  /** New Zealand */
  NZ,
  /** Oman */
  OM,
  /** Panama */
  PA,
  /** Peru */
  PE,
  /** French Polynesia */
  PF,
  /** Papua New Guinea */
  PG,
  /** Philippines */
  PH,
  /** Pakistan */
  PK,
  /** Poland */
  PL,
  /** Saint Pierre and Miquelon */
  PM,
  /** Pitcairn */
  PN,
  /** Puerto Rico */
  PR,
  /** Palestinian Territory */
  PS,
  /** Portugal */
  PT,
  /** Palau */
  PW,
  /** Paraguay */
  PY,
  /** Qatar */
  QA,
  /** Reunion */
  RE,
  /** Romania */
  RO,
  /** Serbia */
  RS,
  /** Russia */
  RU,
  /** Rwanda */
  RW,
  /** Saudi Arabia */
  SA,
  /** Solomon Islands */
  SB,
  /** Seychelles */
  SC,
  /** Sudan */
  SD,
  /** Sweden */
  SE,
  /** Singapore */
  SG,
  /** Saint Helena */
  SH,
  /** Slovenia */
  SI,
  /** Svalbard and Jan Mayen */
  SJ,
  /** Slovakia */
  SK,
  /** Sierra Leone */
  SL,
  /** San Marino */
  SM,
  /** Sénégal */
  SN,
  /** Somalia */
  SO,
  /** Suriname */
  SR,
  /** São Tomé and Príncipe */
  ST,
  /** South Sudan */
  SS,
  /** El Salvador */
  SV,
  /** Saint Martin (Dutch part) */
  SX,
  /** Syria */
  SY,
  /** Swaziland */
  SZ,
  /** Turks and Caicos Islands */
  TC,
  /** Chad */
  TD,
  /** French Southern and Antarctic Lands */
  TF,
  /** Togo */
  TG,
  /** Thailand */
  TH,
  /** Tajikistan */
  TJ,
  /** Tokelau */
  TK,
  /** Timor-Leste */
  TL,
  /** Turkmenistan */
  TM,
  /** Tunisia */
  TN,
  /** Tonga */
  TO,
  /** Turkey */
  TR,
  /** Trinidad and Tobago */
  TT,
  /** Tuvalu */
  TV,
  /** Taiwan */
  TW,
  /** Tanzania */
  TZ,
  /** Ukraine */
  UA,
  /** Uganda */
  UG,
  /** United States Minor Outlying Islands */
  UM,
  /** United States of America */
  US,
  /** Uruguay */
  UY,
  /** Uzbekistan */
  UZ,
  /** City of the Vatican */
  VA,
  /** Saint Vincent and the Grenadines */
  VC,
  /** Venezuela */
  VE,
  /** British Virgin Islands */
  VG,
  /** United States Virgin Islands */
  VI,
  /** Vietnam */
  VN,
  /** Vanuatu */
  VU,
  /** Wallis and Futuna */
  WF,
  /** Samoa */
  WS,
  /** Yemen */
  YE,
  /** Mayotte */
  YT,
  /** South Africa */
  ZA,
  /** Zambia */
  ZM,
  /** Zimbabwe */
  ZW
}
/**
 * Получение списка всех кодов стран
 */
export function getAllCountryCodes(): CountryCode[] {
  return [
    CountryCode.UN,
    CountryCode.AD,
    CountryCode.AE,
    CountryCode.AF,
    CountryCode.AG,
    CountryCode.AI,
    CountryCode.AL,
    CountryCode.AM,
    CountryCode.AO,
    CountryCode.AQ,
    CountryCode.AR,
    CountryCode.AS,
    CountryCode.AT,
    CountryCode.AU,
    CountryCode.AW,
    CountryCode.AX,
    CountryCode.AZ,
    CountryCode.BA,
    CountryCode.BB,
    CountryCode.BD,
    CountryCode.BE,
    CountryCode.BF,
    CountryCode.BG,
    CountryCode.BH,
    CountryCode.BI,
    CountryCode.BJ,
    CountryCode.BL,
    CountryCode.BM,
    CountryCode.BN,
    CountryCode.BO,
    CountryCode.BQ,
    CountryCode.BR,
    CountryCode.BS,
    CountryCode.BT,
    CountryCode.BV,
    CountryCode.BW,
    CountryCode.BY,
    CountryCode.BZ,
    CountryCode.CA,
    CountryCode.CC,
    CountryCode.CD,
    CountryCode.CF,
    CountryCode.CG,
    CountryCode.CH,
    CountryCode.CI,
    CountryCode.CK,
    CountryCode.CL,
    CountryCode.CM,
    CountryCode.CN,
    CountryCode.CO,
    CountryCode.CR,
    CountryCode.CU,
    CountryCode.CV,
    CountryCode.CW,
    CountryCode.CX,
    CountryCode.CY,
    CountryCode.CZ,
    CountryCode.DE,
    CountryCode.DJ,
    CountryCode.DK,
    CountryCode.DM,
    CountryCode.DO,
    CountryCode.DZ,
    CountryCode.EC,
    CountryCode.EE,
    CountryCode.EG,
    CountryCode.EH,
    CountryCode.ER,
    CountryCode.ES,
    CountryCode.ET,
    CountryCode.FI,
    CountryCode.FJ,
    CountryCode.FK,
    CountryCode.FM,
    CountryCode.FO,
    CountryCode.FR,
    CountryCode.GA,
    CountryCode.GB,
    CountryCode.GD,
    CountryCode.GE,
    CountryCode.GF,
    CountryCode.GG,
    CountryCode.GH,
    CountryCode.GI,
    CountryCode.GL,
    CountryCode.GM,
    CountryCode.GN,
    CountryCode.GP,
    CountryCode.GQ,
    CountryCode.GR,
    CountryCode.GS,
    CountryCode.GT,
    CountryCode.GU,
    CountryCode.GW,
    CountryCode.GY,
    CountryCode.HK,
    CountryCode.HM,
    CountryCode.HN,
    CountryCode.HR,
    CountryCode.HT,
    CountryCode.HU,
    CountryCode.ID,
    CountryCode.IE,
    CountryCode.IL,
    CountryCode.IM,
    CountryCode.IN,
    CountryCode.IO,
    CountryCode.IQ,
    CountryCode.IR,
    CountryCode.IS,
    CountryCode.IT,
    CountryCode.JE,
    CountryCode.JM,
    CountryCode.JO,
    CountryCode.JP,
    CountryCode.KE,
    CountryCode.KG,
    CountryCode.KH,
    CountryCode.KI,
    CountryCode.KM,
    CountryCode.KN,
    CountryCode.KP,
    CountryCode.KR,
    CountryCode.KW,
    CountryCode.KY,
    CountryCode.KZ,
    CountryCode.LA,
    CountryCode.LB,
    CountryCode.LC,
    CountryCode.LI,
    CountryCode.LK,
    CountryCode.LR,
    CountryCode.LS,
    CountryCode.LT,
    CountryCode.LU,
    CountryCode.LV,
    CountryCode.LY,
    CountryCode.MA,
    CountryCode.MC,
    CountryCode.MD,
    CountryCode.ME,
    CountryCode.MF,
    CountryCode.MG,
    CountryCode.MH,
    CountryCode.MK,
    CountryCode.ML,
    CountryCode.MM,
    CountryCode.MN,
    CountryCode.MO,
    CountryCode.MP,
    CountryCode.MQ,
    CountryCode.MR,
    CountryCode.MS,
    CountryCode.MT,
    CountryCode.MU,
    CountryCode.MV,
    CountryCode.MW,
    CountryCode.MX,
    CountryCode.MY,
    CountryCode.MZ,
    CountryCode.NA,
    CountryCode.NC,
    CountryCode.NE,
    CountryCode.NF,
    CountryCode.NG,
    CountryCode.NI,
    CountryCode.NL,
    CountryCode.NO,
    CountryCode.NP,
    CountryCode.NR,
    CountryCode.NU,
    CountryCode.NZ,
    CountryCode.OM,
    CountryCode.PA,
    CountryCode.PE,
    CountryCode.PF,
    CountryCode.PG,
    CountryCode.PH,
    CountryCode.PK,
    CountryCode.PL,
    CountryCode.PM,
    CountryCode.PN,
    CountryCode.PR,
    CountryCode.PS,
    CountryCode.PT,
    CountryCode.PW,
    CountryCode.PY,
    CountryCode.QA,
    CountryCode.RE,
    CountryCode.RO,
    CountryCode.RS,
    CountryCode.RU,
    CountryCode.RW,
    CountryCode.SA,
    CountryCode.SB,
    CountryCode.SC,
    CountryCode.SD,
    CountryCode.SE,
    CountryCode.SG,
    CountryCode.SH,
    CountryCode.SI,
    CountryCode.SJ,
    CountryCode.SK,
    CountryCode.SL,
    CountryCode.SM,
    CountryCode.SN,
    CountryCode.SO,
    CountryCode.SR,
    CountryCode.ST,
    CountryCode.SS,
    CountryCode.SV,
    CountryCode.SX,
    CountryCode.SY,
    CountryCode.SZ,
    CountryCode.TC,
    CountryCode.TD,
    CountryCode.TF,
    CountryCode.TG,
    CountryCode.TH,
    CountryCode.TJ,
    CountryCode.TK,
    CountryCode.TL,
    CountryCode.TM,
    CountryCode.TN,
    CountryCode.TO,
    CountryCode.TR,
    CountryCode.TT,
    CountryCode.TV,
    CountryCode.TW,
    CountryCode.TZ,
    CountryCode.UA,
    CountryCode.UG,
    CountryCode.UM,
    CountryCode.US,
    CountryCode.UY,
    CountryCode.UZ,
    CountryCode.VA,
    CountryCode.VC,
    CountryCode.VE,
    CountryCode.VG,
    CountryCode.VI,
    CountryCode.VN,
    CountryCode.VU,
    CountryCode.WF,
    CountryCode.WS,
    CountryCode.YE,
    CountryCode.YT,
    CountryCode.ZA,
    CountryCode.ZM,
    CountryCode.ZW
  ]
}

/**
 * Получение наименования типа протокола по типу элемента спарвочника
 * @param type Тип элемента справочника
 */
export function getCountryName(type: CountryCode): string {
  switch (type) {
    case CountryCode.UN: return 'enums.crm.country.UN';
    case CountryCode.AD: return 'enums.crm.country.AD';
    case CountryCode.AE: return 'enums.crm.country.AE';
    case CountryCode.AF: return 'enums.crm.country.AF';
    case CountryCode.AG: return 'enums.crm.country.AG';
    case CountryCode.AI: return 'enums.crm.country.AI';
    case CountryCode.AL: return 'enums.crm.country.AL';
    case CountryCode.AM: return 'enums.crm.country.AM';
    case CountryCode.AO: return 'enums.crm.country.AO';
    case CountryCode.AQ: return 'enums.crm.country.AQ';
    case CountryCode.AR: return 'enums.crm.country.AR';
    case CountryCode.AS: return 'enums.crm.country.AS';
    case CountryCode.AT: return 'enums.crm.country.AT';
    case CountryCode.AU: return 'enums.crm.country.AU';
    case CountryCode.AW: return 'enums.crm.country.AW';
    case CountryCode.AX: return 'enums.crm.country.AX';
    case CountryCode.AZ: return 'enums.crm.country.AZ';
    case CountryCode.BA: return 'enums.crm.country.BA';
    case CountryCode.BB: return 'enums.crm.country.BB';
    case CountryCode.BD: return 'enums.crm.country.BD';
    case CountryCode.BE: return 'enums.crm.country.BE';
    case CountryCode.BF: return 'enums.crm.country.BF';
    case CountryCode.BG: return 'enums.crm.country.BG';
    case CountryCode.BH: return 'enums.crm.country.BH';
    case CountryCode.BI: return 'enums.crm.country.BI';
    case CountryCode.BJ: return 'enums.crm.country.BJ';
    case CountryCode.BL: return 'enums.crm.country.BL';
    case CountryCode.BM: return 'enums.crm.country.BM';
    case CountryCode.BN: return 'enums.crm.country.BN';
    case CountryCode.BO: return 'enums.crm.country.BO';
    case CountryCode.BQ: return 'enums.crm.country.BQ';
    case CountryCode.BR: return 'enums.crm.country.BR';
    case CountryCode.BS: return 'enums.crm.country.BS';
    case CountryCode.BT: return 'enums.crm.country.BT';
    case CountryCode.BV: return 'enums.crm.country.BV';
    case CountryCode.BW: return 'enums.crm.country.BW';
    case CountryCode.BY: return 'enums.crm.country.BY';
    case CountryCode.BZ: return 'enums.crm.country.BZ';
    case CountryCode.CA: return 'enums.crm.country.CA';
    case CountryCode.CC: return 'enums.crm.country.CC';
    case CountryCode.CD: return 'enums.crm.country.CD';
    case CountryCode.CF: return 'enums.crm.country.CF';
    case CountryCode.CG: return 'enums.crm.country.CG';
    case CountryCode.CH: return 'enums.crm.country.CH';
    case CountryCode.CI: return 'enums.crm.country.CI';
    case CountryCode.CK: return 'enums.crm.country.CK';
    case CountryCode.CL: return 'enums.crm.country.CL';
    case CountryCode.CM: return 'enums.crm.country.CM';
    case CountryCode.CN: return 'enums.crm.country.CN';
    case CountryCode.CO: return 'enums.crm.country.CO';
    case CountryCode.CR: return 'enums.crm.country.CR';
    case CountryCode.CU: return 'enums.crm.country.CU';
    case CountryCode.CV: return 'enums.crm.country.CV';
    case CountryCode.CW: return 'enums.crm.country.CW';
    case CountryCode.CX: return 'enums.crm.country.CX';
    case CountryCode.CY: return 'enums.crm.country.CY';
    case CountryCode.CZ: return 'enums.crm.country.CZ';
    case CountryCode.DE: return 'enums.crm.country.DE';
    case CountryCode.DJ: return 'enums.crm.country.DJ';
    case CountryCode.DK: return 'enums.crm.country.DK';
    case CountryCode.DM: return 'enums.crm.country.DM';
    case CountryCode.DO: return 'enums.crm.country.DO';
    case CountryCode.DZ: return 'enums.crm.country.DZ';
    case CountryCode.EC: return 'enums.crm.country.EC';
    case CountryCode.EE: return 'enums.crm.country.EE';
    case CountryCode.EG: return 'enums.crm.country.EG';
    case CountryCode.EH: return 'enums.crm.country.EH';
    case CountryCode.ER: return 'enums.crm.country.ER';
    case CountryCode.ES: return 'enums.crm.country.ES';
    case CountryCode.ET: return 'enums.crm.country.ET';
    case CountryCode.FI: return 'enums.crm.country.FI';
    case CountryCode.FJ: return 'enums.crm.country.FJ';
    case CountryCode.FK: return 'enums.crm.country.FK';
    case CountryCode.FM: return 'enums.crm.country.FM';
    case CountryCode.FO: return 'enums.crm.country.FO';
    case CountryCode.FR: return 'enums.crm.country.FR';
    case CountryCode.GA: return 'enums.crm.country.GA';
    case CountryCode.GB: return 'enums.crm.country.GB';
    case CountryCode.GD: return 'enums.crm.country.GD';
    case CountryCode.GE: return 'enums.crm.country.GE';
    case CountryCode.GF: return 'enums.crm.country.GF';
    case CountryCode.GG: return 'enums.crm.country.GG';
    case CountryCode.GH: return 'enums.crm.country.GH';
    case CountryCode.GI: return 'enums.crm.country.GI';
    case CountryCode.GL: return 'enums.crm.country.GL';
    case CountryCode.GM: return 'enums.crm.country.GM';
    case CountryCode.GN: return 'enums.crm.country.GN';
    case CountryCode.GP: return 'enums.crm.country.GP';
    case CountryCode.GQ: return 'enums.crm.country.GQ';
    case CountryCode.GR: return 'enums.crm.country.GR';
    case CountryCode.GS: return 'enums.crm.country.GS';
    case CountryCode.GT: return 'enums.crm.country.GT';
    case CountryCode.GU: return 'enums.crm.country.GU';
    case CountryCode.GW: return 'enums.crm.country.GW';
    case CountryCode.GY: return 'enums.crm.country.GY';
    case CountryCode.HK: return 'enums.crm.country.HK';
    case CountryCode.HM: return 'enums.crm.country.HM';
    case CountryCode.HN: return 'enums.crm.country.HN';
    case CountryCode.HR: return 'enums.crm.country.HR';
    case CountryCode.HT: return 'enums.crm.country.HT';
    case CountryCode.HU: return 'enums.crm.country.HU';
    case CountryCode.ID: return 'enums.crm.country.ID';
    case CountryCode.IE: return 'enums.crm.country.IE';
    case CountryCode.IL: return 'enums.crm.country.IL';
    case CountryCode.IM: return 'enums.crm.country.IM';
    case CountryCode.IN: return 'enums.crm.country.IN';
    case CountryCode.IO: return 'enums.crm.country.IO';
    case CountryCode.IQ: return 'enums.crm.country.IQ';
    case CountryCode.IR: return 'enums.crm.country.IR';
    case CountryCode.IS: return 'enums.crm.country.IS';
    case CountryCode.IT: return 'enums.crm.country.IT';
    case CountryCode.JE: return 'enums.crm.country.JE';
    case CountryCode.JM: return 'enums.crm.country.JM';
    case CountryCode.JO: return 'enums.crm.country.JO';
    case CountryCode.JP: return 'enums.crm.country.JP';
    case CountryCode.KE: return 'enums.crm.country.KE';
    case CountryCode.KG: return 'enums.crm.country.KG';
    case CountryCode.KH: return 'enums.crm.country.KH';
    case CountryCode.KI: return 'enums.crm.country.KI';
    case CountryCode.KM: return 'enums.crm.country.KM';
    case CountryCode.KN: return 'enums.crm.country.KN';
    case CountryCode.KP: return 'enums.crm.country.KP';
    case CountryCode.KR: return 'enums.crm.country.KR';
    case CountryCode.KW: return 'enums.crm.country.KW';
    case CountryCode.KY: return 'enums.crm.country.KY';
    case CountryCode.KZ: return 'enums.crm.country.KZ';
    case CountryCode.LA: return 'enums.crm.country.LA';
    case CountryCode.LB: return 'enums.crm.country.LB';
    case CountryCode.LC: return 'enums.crm.country.LC';
    case CountryCode.LI: return 'enums.crm.country.LI';
    case CountryCode.LK: return 'enums.crm.country.LK';
    case CountryCode.LR: return 'enums.crm.country.LR';
    case CountryCode.LS: return 'enums.crm.country.LS';
    case CountryCode.LT: return 'enums.crm.country.LT';
    case CountryCode.LU: return 'enums.crm.country.LU';
    case CountryCode.LV: return 'enums.crm.country.LV';
    case CountryCode.LY: return 'enums.crm.country.LY';
    case CountryCode.MA: return 'enums.crm.country.MA';
    case CountryCode.MC: return 'enums.crm.country.MC';
    case CountryCode.MD: return 'enums.crm.country.MD';
    case CountryCode.ME: return 'enums.crm.country.ME';
    case CountryCode.MF: return 'enums.crm.country.MF';
    case CountryCode.MG: return 'enums.crm.country.MG';
    case CountryCode.MH: return 'enums.crm.country.MH';
    case CountryCode.MK: return 'enums.crm.country.MK';
    case CountryCode.ML: return 'enums.crm.country.ML';
    case CountryCode.MM: return 'enums.crm.country.MM';
    case CountryCode.MN: return 'enums.crm.country.MN';
    case CountryCode.MO: return 'enums.crm.country.MO';
    case CountryCode.MP: return 'enums.crm.country.MP';
    case CountryCode.MQ: return 'enums.crm.country.MQ';
    case CountryCode.MR: return 'enums.crm.country.MR';
    case CountryCode.MS: return 'enums.crm.country.MS';
    case CountryCode.MT: return 'enums.crm.country.MT';
    case CountryCode.MU: return 'enums.crm.country.MU';
    case CountryCode.MV: return 'enums.crm.country.MV';
    case CountryCode.MW: return 'enums.crm.country.MW';
    case CountryCode.MX: return 'enums.crm.country.MX';
    case CountryCode.MY: return 'enums.crm.country.MY';
    case CountryCode.MZ: return 'enums.crm.country.MZ';
    case CountryCode.NA: return 'enums.crm.country.NA';
    case CountryCode.NC: return 'enums.crm.country.NC';
    case CountryCode.NE: return 'enums.crm.country.NE';
    case CountryCode.NF: return 'enums.crm.country.NF';
    case CountryCode.NG: return 'enums.crm.country.NG';
    case CountryCode.NI: return 'enums.crm.country.NI';
    case CountryCode.NL: return 'enums.crm.country.NL';
    case CountryCode.NO: return 'enums.crm.country.NO';
    case CountryCode.NP: return 'enums.crm.country.NP';
    case CountryCode.NR: return 'enums.crm.country.NR';
    case CountryCode.NU: return 'enums.crm.country.NU';
    case CountryCode.NZ: return 'enums.crm.country.NZ';
    case CountryCode.OM: return 'enums.crm.country.OM';
    case CountryCode.PA: return 'enums.crm.country.PA';
    case CountryCode.PE: return 'enums.crm.country.PE';
    case CountryCode.PF: return 'enums.crm.country.PF';
    case CountryCode.PG: return 'enums.crm.country.PG';
    case CountryCode.PH: return 'enums.crm.country.PH';
    case CountryCode.PK: return 'enums.crm.country.PK';
    case CountryCode.PL: return 'enums.crm.country.PL';
    case CountryCode.PM: return 'enums.crm.country.PM';
    case CountryCode.PN: return 'enums.crm.country.PN';
    case CountryCode.PR: return 'enums.crm.country.PR';
    case CountryCode.PS: return 'enums.crm.country.PS';
    case CountryCode.PT: return 'enums.crm.country.PT';
    case CountryCode.PW: return 'enums.crm.country.PW';
    case CountryCode.PY: return 'enums.crm.country.PY';
    case CountryCode.QA: return 'enums.crm.country.QA';
    case CountryCode.RE: return 'enums.crm.country.RE';
    case CountryCode.RO: return 'enums.crm.country.RO';
    case CountryCode.RS: return 'enums.crm.country.RS';
    case CountryCode.RU: return 'enums.crm.country.RU';
    case CountryCode.RW: return 'enums.crm.country.RW';
    case CountryCode.SA: return 'enums.crm.country.SA';
    case CountryCode.SB: return 'enums.crm.country.SB';
    case CountryCode.SC: return 'enums.crm.country.SC';
    case CountryCode.SD: return 'enums.crm.country.SD';
    case CountryCode.SE: return 'enums.crm.country.SE';
    case CountryCode.SG: return 'enums.crm.country.SG';
    case CountryCode.SH: return 'enums.crm.country.SH';
    case CountryCode.SI: return 'enums.crm.country.SI';
    case CountryCode.SJ: return 'enums.crm.country.SJ';
    case CountryCode.SK: return 'enums.crm.country.SK';
    case CountryCode.SL: return 'enums.crm.country.SL';
    case CountryCode.SM: return 'enums.crm.country.SM';
    case CountryCode.SN: return 'enums.crm.country.SN';
    case CountryCode.SO: return 'enums.crm.country.SO';
    case CountryCode.SR: return 'enums.crm.country.SR';
    case CountryCode.ST: return 'enums.crm.country.ST';
    case CountryCode.SS: return 'enums.crm.country.SS';
    case CountryCode.SV: return 'enums.crm.country.SV';
    case CountryCode.SX: return 'enums.crm.country.SX';
    case CountryCode.SY: return 'enums.crm.country.SY';
    case CountryCode.SZ: return 'enums.crm.country.SZ';
    case CountryCode.TC: return 'enums.crm.country.TC';
    case CountryCode.TD: return 'enums.crm.country.TD';
    case CountryCode.TF: return 'enums.crm.country.TF';
    case CountryCode.TG: return 'enums.crm.country.TG';
    case CountryCode.TH: return 'enums.crm.country.TH';
    case CountryCode.TJ: return 'enums.crm.country.TJ';
    case CountryCode.TK: return 'enums.crm.country.TK';
    case CountryCode.TL: return 'enums.crm.country.TL';
    case CountryCode.TM: return 'enums.crm.country.TM';
    case CountryCode.TN: return 'enums.crm.country.TN';
    case CountryCode.TO: return 'enums.crm.country.TO';
    case CountryCode.TR: return 'enums.crm.country.TR';
    case CountryCode.TT: return 'enums.crm.country.TT';
    case CountryCode.TV: return 'enums.crm.country.TV';
    case CountryCode.TW: return 'enums.crm.country.TW';
    case CountryCode.TZ: return 'enums.crm.country.TZ';
    case CountryCode.UA: return 'enums.crm.country.UA';
    case CountryCode.UG: return 'enums.crm.country.UG';
    case CountryCode.UM: return 'enums.crm.country.UM';
    case CountryCode.US: return 'enums.crm.country.US';
    case CountryCode.UY: return 'enums.crm.country.UY';
    case CountryCode.UZ: return 'enums.crm.country.UZ';
    case CountryCode.VA: return 'enums.crm.country.VA';
    case CountryCode.VC: return 'enums.crm.country.VC';
    case CountryCode.VE: return 'enums.crm.country.VE';
    case CountryCode.VG: return 'enums.crm.country.VG';
    case CountryCode.VI: return 'enums.crm.country.VI';
    case CountryCode.VN: return 'enums.crm.country.VN';
    case CountryCode.VU: return 'enums.crm.country.VU';
    case CountryCode.WF: return 'enums.crm.country.WF';
    case CountryCode.WS: return 'enums.crm.country.WS';
    case CountryCode.YE: return 'enums.crm.country.YE';
    case CountryCode.YT: return 'enums.crm.country.YT';
    case CountryCode.ZA: return 'enums.crm.country.ZA';
    case CountryCode.ZM: return 'enums.crm.country.ZM';
    case CountryCode.ZW: return 'enums.crm.country.ZW';
  }
}
