import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { EMPTY } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { deepClone } from '../../../shared/CloneHelper';
import { FastPeriodType, getAllFastPeriodTypes, getFastPeriodTypeName } from '../../../shared/FastPeriodType';
import { ReportItemType } from '../../../shared/reports/ReportItemType';
import { IReportTable } from '../../../shared/reports/tables/IReportTable';
import { AccountRightType } from '../../../shared/rights/RightType';
import { DetailTab } from '../../classes/DetailTab';
import { IListItem } from '../../classes/IListItem';
import { AccountsService } from '../../services/accounts.service';
import { LoadingService } from '../../services/loading.service';
import { ReportObjectsService } from '../../services/report-objects.service';
import {
  getAllReportTypes,
  getReportTypeName,
  IReportTemplate,
  ReportItem,
  ReportTemplatesService,
  ReportType
} from '../../services/report-templates.service';
import { isGroup, isGroupReport, ObjectOrGroup, ObjectType, ReportsService } from '../../services/reports.service';
import { localeSort } from '../../utils/sort';
import { ReportComponent } from '../report/report.component';
import { SelectItemsComponent } from '../select-items/select-items.component';

import { ReportTemplateChartComponent } from './chart/report-template.chart.component';
import { ReportTemplateTableComponent } from './table/report-template.table.component';

/**
 * Интерфейс компонента для редактирования шаблона отчета
 */
export interface IReportTemplateComponent {
  /** Идентификатор шаблона */
  templateId?: string;
  /** Признак того, что выполняется копирование шаблона отчета */
  copy?: boolean;
}

/**
 * Компонент для редактирования шаблона отчета
 */
@Component({
  selector: 'report-template',
  templateUrl: './report-template.component.html',
  styleUrls: ['./report-template.component.scss']
})
export class ReportTemplateComponent
  extends DialogComponent<IReportTemplateComponent, boolean>
  implements IReportTemplateComponent {

  /** Идентификатор шаблона */
  public templateId?: string;

  /** Признак того, что выполняется копирование шаблона отчета */
  public copy?: boolean;

  /** Шаблон отчета */
  public template: IReportTemplate;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Текст сообщения об ошибке */
  public error: string;

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Признак режима добавления */
  public isAddRegime: boolean;

  /** Заголовок окна */
  public title: string;

  /** Доступные типы отчетов */
  public reportTypes: IListItem<ReportType>[] = [];

  /** Список доступных лицевых счетов */
  public accounts: IListItem<string>[] = [];

  /** Список периодов для аналитического отчета */
  public analyticsPeriods: IListItem<FastPeriodType>[] = [];

  /** Выбранные объекты */
  public selected: ObjectOrGroup[] = [];

  /** Постфикс для копии */
  private copyPostfix = '';

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param reportTemplatesService Сервис для работы с ДИУП
   * @param accountsService Сервис для работы с геозонами
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   * @param reportsService Сервис для работы с отчетами
   * @param reportObjectsService Report Objects Service
   */
  constructor(
    dialogService: DialogService,
    private reportTemplatesService: ReportTemplatesService,
    private accountsService: AccountsService,
    private translator: TranslateService,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private reportObjectsService: ReportObjectsService
  ) {
    super(dialogService);

    translator.get('ui.copy2').subscribe((x) => this.copyPostfix = x);

    this.isAddRegime = true;

    this.reportTypes = getAllReportTypes()
      .map((type) => ({ id: type, name: getReportTypeName(type) }));

    this.analyticsPeriods = getAllFastPeriodTypes()
      .map((type) => ({ id: type, name: getFastPeriodTypeName(type) }));

    this.tabs[Tabs.Content] = new DetailTab('component.report-template.content');
    this.tabs[Tabs.Elements] = new DetailTab('component.report-template.elements');
    this.tabs[Tabs.Settings] = new DetailTab('component.report-template.settings');

    this.selectedTab = this.tabs[Tabs.Content];

    this.template = { objects: [], items: [], type: ReportType.UNIT } as IReportTemplate;
  }

  /** Признак необходимости отображения настроек аналитических отчетов */
  public get showAnalyticsSettings() {
    return this.template.type === ReportType.UNIT_GROUP;
  }

  /** Признак необходимости отображения настроек путевого листа */
  public get showWaybillSettings() {
    return this.template.type === ReportType.UNIT;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IReportTemplateComponent) {
    const action = data.templateId ? data.copy ? 'copy' : 'edit' : 'add';
    this.isAddRegime = action !== 'edit';
    this.title = `component.report-template.${action}-report-template-title`;

    if (data.templateId) {

      const onTemplateLoaded = (template: IReportTemplate) => {

        this.template = template;
        if (data.copy) {
          this.template.name += this.copyPostfix;
          delete this.template.id;
        }

        this.loadAccounts();

        if (template?.objects?.length) {
          const ot = isGroupReport(template?.type) ? ObjectType.Groups : ObjectType.Both;
          return this.reportObjectsService.loadObjects(template?.type, ot);
        }

        return EMPTY;
      };

      this.reportTemplatesService.get(data.templateId)
        .pipe(flatMap(onTemplateLoaded))
        .subscribe(this.fillSelectedObjects);

    } else {
      this.loadAccounts();
    }

    return super.fillData(data);
  }

  /**
   * Перемещение элемента отчета в списке
   * @param item Элемент отчета
   * @param up Признак перемещения вверх
   */
  public moveItem(item: ReportItem, up: boolean) {
    const index = this.template.items.indexOf(item);
    if (index === -1) {
      return;
    }

    if ((up && index === 0) || (!up && index === this.template.items.length - 1)) {
      return;
    }

    const swapIndex = up ? index - 1 : index + 1;
    const swapItem = this.template.items[swapIndex];
    this.template.items[swapIndex] = item;
    this.template.items[index] = swapItem;
  }

  /**
   * Удаление элемента отчета
   * @param item Элемент отчета
   */
  public deleteItem(item: ReportItem) {
    const index = this.template.items.indexOf(item);
    if (index !== -1) {
      this.template.items.splice(index, 1);
    }
  }

  /**
   * Обработка при изменении типа отчета
   */
  public onTypeChanged() {
    if (this.template) {
      this.template.objects = [];
      this.template.items = [];
      delete this.template.analytics;
      delete this.template.analyticsPeriod;
      delete this.template.waybill;
      this.selectedTab = this.tabs[Tabs.Content];
      this.selected = [];
    }
  }

  /**
   * Обработка при изменении учетной записи шаблона
   */
  public onAccountChanged() {
    for (const item of this.template?.items) {
      if (item.itemType !== ReportItemType.TABLE) {
        continue;
      }

      const table = item as IReportTable<string>;
      if (!table.settings?.filters?.length) {
        continue;
      }

      for (const f of table.settings?.filters) {
        if (f.geozones?.length) {
          f.geozones = [];
        }
      }
    }
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Добавление таблицы в отчет
   */
  public addTable() {
    const data = {
      reportType: this.template.type,
      accountId: this.template.accountId
    };

    this.dialogService.addDialog(ReportTemplateTableComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((table) => this.template.items.push(table));
  }

  /**
   * Добавление графика в отчет
   */
  public addChart() {
    this.dialogService.addDialog(ReportTemplateChartComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe((chart) => this.template.items.push(chart));
  }

  /**
   * Изменение элемента отчета
   * @param item Элемент отчета
   */
  public updateItem(item: ReportItem) {
    const index = this.template.items.indexOf(item);
    if (index === -1) {
      return;
    }

    if (item.itemType === ReportItemType.TABLE) {
      const data = {
        table: deepClone(item),
        reportType: this.template.type,
        accountId: this.template.accountId
      };

      this.dialogService.addDialog(ReportTemplateTableComponent, data)
        .pipe(filter((result) => !!result))
        .subscribe((table) => this.template.items[index] = table);

    } else {
      const data = {
        chart: deepClone(item)
      };

      this.dialogService.addDialog(ReportTemplateChartComponent, data)
        .pipe(filter((result) => !!result))
        .subscribe((chart) => this.template.items[index] = chart);
    }
  }

  /**
   * Копирование элемента отчета
   * @param item Элемент отчета
   */
  public copyItem(item: ReportItem) {
    const copies = this.template?.items?.filter((i) => {
      if (!i?.name?.startsWith(item?.name)) {
        return false;
      }

      return !!i?.name?.substring(item?.name?.length)?.match(/^ \(\d+\)$/i);
    });

    const name = `${item.name} (${copies.length + 1})`;
    if (item.itemType === ReportItemType.TABLE) {
      const data = {
        table: {
          ...item,
          name
        },
        reportType: this.template.type,
        accountId: this.template.accountId
      };

      this.dialogService.addDialog(ReportTemplateTableComponent, data)
        .pipe(filter((result) => !!result))
        .subscribe((table) => this.template.items.push(table));

    } else {
      const data = {
        chart: {
          ...item,
          name
        }
      };

      this.dialogService.addDialog(ReportTemplateChartComponent, data)
        .pipe(filter((result) => !!result))
        .subscribe((chart) => this.template.items.push(chart));
    }
  }

  /**
   * Обработка изменения признака аналитического отчета
   */
  public onAnalyticsChanged() {
    if (!this.template?.analytics) {
      delete this.template.analyticsPeriod;
    } else if (!this.template?.analyticsPeriod) {
      this.template.analyticsPeriod = FastPeriodType.YESTERDAY;
    }
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.template?.name) {
      this.error = 'component.report-template.error-1';
      return;
    }

    if (!this.template?.items?.length) {
      this.error = 'component.report-template.error-2';
      return;
    }

    if (!this.template?.accountId) {
      this.error = 'component.report-template.error-3';
      return;
    }

    this.template.objects = this.selected?.map((o) => ({ id: o.id, group: isGroup(o) }));

    const $action = this.template?.id
      ? this.reportTemplatesService.update(this.template)
      : this.reportTemplatesService.create(this.template);

    $action.subscribe(() => {
      this.result = true;
      this.close();
    });
  }

  /**
   * Загрузка и отображение списка объектов или групп для выбора
   * @param group Признак загрузки групп, иначе загружаются объекты
   */
  public selectObjects(group: boolean) {
    const ot = group ? ObjectType.Groups : ObjectType.Objects;

    this.loadingService.wrap(this.reportObjectsService.loadObjects(this.template.type, ot), true)
      .subscribe((list) => this.showSelectObjectDialog(list, group));
  }

  /**
   * Проверка является ли объект группой
   * @param oog Объект или группа
   */
  public isGroup(oog: ObjectOrGroup) {
    return isGroup(oog);
  }

  /**
   * Загрузка списка учетных записей
   */
  private loadAccounts() {
    if (!this.template.id) {
      this.loadingService.wrap(this.accountsService.getWithRight(AccountRightType.CHANGE_REPORT_TEMPLATES), true)
        .subscribe((accounts) => {
          this.accounts = [...accounts?.sort(localeSort)];
          if (!this.template.accountId) {
            this.template.accountId = accounts?.shift()?.id;
          }
        });

    } else {
      this.accounts.push({
        id: this.template.accountId,
        name: this.template.account
      });
    }
  }

  /**
   * Получение списка выбранных элементов
   * @param list Список объектов
   */
  private fillSelectedObjects = (list: ObjectOrGroup[]) => {
    this.selected = list.filter((x) =>
      this.template.objects.some((o) => o.id === x.id && o.group === isGroup(x)));
  };

  /**
   * Отображение окна выбора объектов
   * @param list Список объектов
   * @param group
   */
  private showSelectObjectDialog = (list: ObjectOrGroup[], group: boolean) => {
    const data = {
      items: list,
      selected: this.selected,
      title: ReportComponent.getSelectAllDialogTitle(this.template.type, group),
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result => !!result)))
      .subscribe((selected) => {
        this.selected = this.selected
          ?.filter((x) => isGroup(x) !== group)
          ?.concat(list.filter((x) => selected.some((s) => s.id === x.id)))
          ?.sort(localeSort);
      });
  };
}

/**
 * Список вкладок
 */
enum Tabs {
  Content,
  Elements,
  Settings
}
