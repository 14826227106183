import { Component } from '@angular/core';

import { MapSourceType } from '../../../classes/MapSourceType';
import { BgPopoverComponent, BgPopoverService } from '../../../modules/bg-popover';
import { MapService } from '../../../services/map.service';

/**
 * Данные компонента с настройками карты
 */
interface IMapSettingsComponent {
  /** Источник карты */
  mapSource: MapSourceType;

  /** Обработка при измении источника карты */
  onMapSourceChanged: (type: MapSourceType) => void;
}

/**
 * Компонент с настройками карты
 */
@Component({
  selector: 'map-settings',
  templateUrl: './map.settings.component.html',
  styleUrls: ['./map.settings.component.scss']
})
export class MapSettingsComponent
extends BgPopoverComponent<IMapSettingsComponent>
implements IMapSettingsComponent {

  /** Источник карты */
  public mapSource: MapSourceType;

  /** Обработка при измении источника карты */
  public onMapSourceChanged: (type: MapSourceType) => void;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   * @param mapService Сервис для работы с картой
   */
  constructor(
    popoverService: BgPopoverService,
    public mapService: MapService
  ) {
    super(popoverService);
  }

  /**
   * Выбор источника карты
   * @param type Тип источника карты
   */
  public selectMapSource(type: MapSourceType) {
    if (this.onMapSourceChanged) {
      this.onMapSourceChanged(type);
      this.popoverService.removePopover();
    }
  }

  /**
   * Обработчик клика по окну
   */
  public onClick = (event: MouseEvent) => {
    // Предотвращаем распространение события,
    // чтобы при клике внутри компонента он не закрывался автоматически
    event.stopPropagation();
  }
}
