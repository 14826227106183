import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IRoute } from '../../../../shared/routes/IRoute';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';

/**
 * Интерфейс компонента для настройки повторяемости маршрутов
 */
interface IRoutesRepeatComponent {
  /** Идентификатор маршрута */
  routeId: string;
}

/**
 * Компонент для настройки повторяемости маршрутов
 */
@Component({
  selector: 'app-routes-repeat',
  templateUrl: './routes.repeat.component.html'
})
export class RoutesRepeatComponent extends DialogComponent<IRoutesRepeatComponent, boolean> {

  /** Идентификатор маршрута */
  public routeId: string;

  /** Маршрут */
  public route: IRoute;

  /** Получение признака наличия ограничений времени */
  public get timeLimits() {
    return this.route && !!this.route.timeLimits;
  }

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис CRUD
   * @param loadingService Сервис отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private loadingService: LoadingService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IRoutesRepeatComponent) {
    this.loadingService.withLoading(
      this.crudService.get(data.routeId, CRUDEntityType.ROUTE),
      (route: IRoute) => this.route = route
    );
    return super.fillData(data);
  }

  /**
   * Обработка при переключении повторяемости маршрута
   */
  public onToggleRepeat() {
    if (!this.route) {
      return;
    }

    if (!this.route.repeat) {
      delete this.route.timeLimits;
    }
  }

  /**
   * Переключение наличия ограничений времени
   */
  public toggleTimeLimits() {
    if (!this.route || !this.route.repeat) {
      return;
    }

    if (this.timeLimits) {
      delete this.route.timeLimits;
    } else {
      this.route.timeLimits = {
        week: [],
        month: []
      };
    }
  }

  /**
   * Сохранение изменений
   */
  public save() {
    this.loadingService.withLoading(
      this.crudService.addUpdate(this.route, CRUDEntityType.ROUTE),
      () => {
        this.result = true;
        this.close();
      }
    );
  }
}
