import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ISummary } from '../../../../shared/analytics/ISummary';
import { FastPeriodType, getFastPeriod } from '../../../../shared/FastPeriodType';
import { AnalyticsService } from '../../../services/analytics.service';
import { LoadingService } from '../../../services/loading.service';
import { IReportTemplateInfo, ReportTemplatesService } from '../../../services/report-templates.service';
import { ICreateReportRequest, ReportsService } from '../../../services/reports.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';

/**
 * Компонент для раздела аналитики
 */
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  /** Заголовок раздела с экономией топлива */
  public fuelEconomyTitle = '';

  /** Сводная информация по аналитике */
  public summary: ISummary;

  /** Список шаблонов отчетов с аналитикой экономии топлива */
  public fuelEconomyTemplates: IReportTemplateInfo[];

  /** Список шаблонов аналитических отчетов, которые не попали в другие разделы */
  public templates: IReportTemplateInfo[];

  /** Заголовок раздела с подробностями */
  private detailTitle = '';

  /**
   * Конструктор
   * @param analyticsService Сервис для работы с аналитикой
   * @param loadingService Сервис отображения процесса загрузки
   * @param reportsService Сервис для работы с отчетами
   * @param router Маршрутизатор
   * @param store Сервис для хранения данных мониторинга
   * @param translator Сервис для перевода
   */
  constructor(
    private analyticsService: AnalyticsService,
    private reportTemplatesService: ReportTemplatesService,
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private router: Router,
    private store: StoreService,
    translator: TranslateService
  ) {
    this.fuelEconomyTemplates = [];
    this.templates = [];

    translator.get([
      'component.analytics.title-saved',
      'component.analytics.title-detail'
    ]).subscribe((x) => {
      this.fuelEconomyTitle = x['component.analytics.title-saved'];
      this.detailTitle = x['component.analytics.title-detail'];
    });
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadingService.wrap(this.analyticsService.getSummary(), true)
      .subscribe((summary) => this.summary = summary);

    // Шаблоны аналитических отчетов
    this.loadingService.wrap(this.reportTemplatesService.getAnalytics(), true)
      .subscribe((templates) => {
        const economyTemplates = templates.filter((t) => t.name.startsWith(this.fuelEconomyTitle));

        for (const template of economyTemplates) {
          template.name = template.name.replace(this.fuelEconomyTitle, this.detailTitle);
        }

        this.fuelEconomyTemplates = economyTemplates?.sort(localeSort);
        this.templates = templates
          ?.filter((t) => !economyTemplates.includes(t))
          ?.sort(localeSort);
      });
  }

  /**
   * Признак отсутствия информации по экономии топлива
   */
  public get summaryEmpty(): boolean {
    return !!this.summary?.week || !!this.summary?.day;
  };

  /**
   * Клик по кнопке генерации аналитического отчета
   * @param template Шаблон отчета
   */
  public generateReport(template: IReportTemplateInfo) {
    const periodType = template.analyticsPeriod || FastPeriodType.YESTERDAY;
    const oid = this.store.units.map((u) => u.id);

    const period = getFastPeriod(
      periodType,
      this.reportsService.reportRequest.begin,
      this.reportsService.reportRequest.end);

    const request: ICreateReportRequest = {
      begin: period.f,
      end: period.t,
      template: template.id,
      objects: [oid],
      name: template.name
    };

    this.loadingService.wrap(this.reportsService.buildReport(request), true)
      .subscribe(() => this.router.navigate(['monitoring', 'reports']));
  }
}
