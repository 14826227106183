import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Observable } from 'rxjs';

import {
  DocRequest,
  DocRequestType,
  DocResponse,
  DocType,
  isInvoiceRequest
} from '../../../../../../shared/crm/docs';
import { DocsService } from '../../../../../services/docs.service';
import { LoadingService } from '../../../../../services/loading.service';

/** Интерфейс компонента для редактирования документа */
interface IDocChangeComponent {
  /** Тип документа */
  type: DocType;
  /** Идентификатор документа */
  docId: string;
  /** Идентификатор учетной записи */
  accountId: string;
}

/** Компонент для редактирования документа */
@Component({
  selector: 'doc-change',
  templateUrl: './doc.change.component.html'
})
export class DocChangeComponent extends DialogComponent<IDocChangeComponent, boolean> {
  /** Тип документа */
  public type: DocType;
  /** Идентификатор документа */
  public docId: string;
  /** Идентификатор учетной записи */
  public accountId: string;
  /** Дата документа */
  public date: Date;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param docsService Сервис для работы с документами
   * @param loadingService Сервис отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private docsService: DocsService,
    private loadingService: LoadingService
  ) {
    super(dialogService);
  }

  /** Признак редактирования счета */
  public get isInvoice() {
    return this.type === 'invoice';
  }

  /**
   * Сохранение изменений
   */
  public ok() {
    const request: DocRequest = !this.isInvoice ? {
      type: DocRequestType.CHANGE,
      accountId: this.accountId,
      reportId: this.docId,
      date: this.date ? this.date.getTime() : null
    } : null;

    const observable: Observable<DocResponse> = isInvoiceRequest(request, this.type)
      ? this.docsService.invoice(request)
      : this.docsService.waReport(request);

    this.loadingService.withLoading(
      observable,
      () => {
        this.result = true;
        this.close();
      }
    );
  }

  /**
   * Отмена изменений
   */
  public cancel() {
    this.close();
  }
}
