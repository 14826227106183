import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IAssignmentListItem } from '../../../shared/assignments/IAssignmentListItem';
import { LinkObjectType } from '../../../shared/LinkObjectType';
import { IListItem } from '../../classes/IListItem';
import { AssignmentsService } from '../../services/assignments.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с подробной информацией
 * при создании на его основе модального окна
 */
export interface IAssignmentsComponent {
  /** Объект, с назначениями которого работаем */
  object: IListItem<string>;
  /** Тип объекта */
  objectType: LinkObjectType;
}

/**
 * Компонент для работы с назначениями
 */
@Component({
  selector: 'assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})

export class AssignmentsComponent extends DialogComponent<IAssignmentsComponent, boolean>
  implements IAssignmentsComponent {
  /** Объект, с назначениями которого работаем */
  public object: IListItem<string>;

  /** Тип объекта */
  public objectType: LinkObjectType;

  /** Список назначений объекта */
  public assignments: ICheckedAssignmentListItem[];

  /** Заголовок окна */
  public title: string;

  /**
   * Конструктор
   * @param modalService Сервис работы с модальными окнами
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param assignmentsService Сервис работы с назначениями
   */
  constructor(
    private modalService: ModalService,
    dialogService: DialogService,
    private loadingService: LoadingService,
    private assignmentsService: AssignmentsService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: IAssignmentsComponent) {
    if (data.object && data.object.id !== '') {
      this.title = 'component.assignments.title-'
        + (data.objectType === LinkObjectType.DRIVER ? 'driver' : 'trail');
      this.loadingService.startLoading();
      this.assignmentsService.getList(data.object.id, data.objectType).subscribe(
        (result) => {
          this.assignments = result.map((item) => ({ ...item, checked: false }));
          this.loadingService.stopLoading();
        },
        (error) => {
          this.loadingService.stopLoading();
          this.modalService.showError(error);
        }
      );
    } else {
      this.modalService.showError('component.assignments.undefined');
    }

    return super.fillData(data);
  }

  /**
   * Удаление выбранных назначений
   */
  public deleteCheckedAssignments() {
    const checkedAssignments = this.assignments.filter((s) => s.checked);

    for (const assignment of checkedAssignments) {
      this.loadingService.startLoading();
      this.assignmentsService.delete(assignment.id, this.objectType).subscribe(
        () => {
          const index = this.assignments.indexOf(assignment);
          if (index !== -1) { this.assignments.splice(index, 1); }
          this.loadingService.stopLoading();
        },
        (error) => {
          this.loadingService.stopLoading();
          this.modalService.showError(error);
        }
      );
    }
  }

  /**
   * Выполняет операции по нажатию кнопки закрытия окна
   */
  public closeDialog() {
    this.result = true;
    this.close();
  }

  /**
   * Получение признака возможности удалить выбранные назначения
   */
  public get isCanDeleteAssignments() {
    return this.assignments && this.assignments.length &&
      this.assignments.some((session) => (session.checked));
  }
}

/**
 * Вспомогательный интерфейс элемента списка назначений
 */
interface ICheckedAssignmentListItem extends IAssignmentListItem {
  /** Признак выбранности */
  checked?: boolean;
}
