import { Injectable } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';

import { IModalButton, ModalComponent, ModalType } from '../components/modal/modal.component';

/**
 * Сервис модальных окон
 */
@Injectable()
export class ModalService {

  /**
   * Конструктор
   * @param dialogService Сервис диалогов
   */
  constructor(private dialogService: DialogService) {
  }

  /**
   * Отображение окна с сообщением об ошибке
   * @param message Текст ошибки
   * @param customTitle Заголовок окна. Если не указан, используется стандартный заголовок "Ошибка"
   * @param customButtons Кастомные кнопки
   */
  public showError(message: string, customTitle?: string, customButtons?: IModalButton[]) {
    return this.show(message, ModalType.ERROR, customTitle, customButtons);
  }

  /**
   * Отображение окна с предупреждением
   * @param message Текст предупреждения
   * @param customTitle Заголовок окна. Если не указан, используется стандартный заголовок "Внимание"
   * @param customButtons Кастомные кнопки
   */
  public showWarning(message: string, customTitle?: string, customButtons?: IModalButton[]) {
    return this.show(message, ModalType.WARNING, customTitle, customButtons);
  }

  /**
   * Отображение окна с вопросом
   * @param message Текст вопроса
   * @param customTitle Заголовок окна. Если не указан, используется стандартный заголовок "Внимание"
   * @param customButtons Кастомные кнопки
   */
  public showQuestion(message: string, customTitle?: string, customButtons?: IModalButton[]) {
    return this.show(message, ModalType.QUESTION, customTitle, customButtons);
  }

  /**
   * Отображение окна с подтверждением
   * @param message Текст подтверждения
   * @param customTitle Заголовок окна. Если не указан, используется стандартный заголовок "Подтверждение"
   * @param customButtons Кастомные кнопки
   */
  public showConfirm(message: string, customTitle?: string, customButtons?: IModalButton[]) {
    return this.show(message, ModalType.CONFIRM, customTitle, customButtons);
  }

  /**
   * Отображение окна с информацией
   * @param message Текст информации
   * @param customTitle Заголовок окна. Если не указан, используется стандартный заголовок "Информация"
   * @param customButtons Кастомные кнопки
   */
  public showInfo(message: string, customTitle?: string, customButtons?: IModalButton[]) {
    return this.show(message, ModalType.INFO, customTitle, customButtons);
  }

  /**
   * Отображение модального окна
   * @param message Текст сообщения
   * @param type Тип окна
   * @param customTitle Заголовок окна.
   * Если не указан, то будет исользоваться стандартный заголовок для указанного типа окна
   * @param customButtons Кастомные кнопки
   */
  public show(message: string, type: ModalType, customTitle?: string, customButtons?: IModalButton[]) {
    const data = {
      message,
      type,
      customTitle,
      customButtons
    };

    return this.dialogService.addDialog(ModalComponent, data);
  }
}
