import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { IServerResponse } from '../../../../shared/IServerResponse';
import { BgAuthService } from '../../../modules/bg-auth/services/bg-auth.service';
import { ConfigService } from '../../../services/config.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';

/**
 * Компонент для загрузки платежей
 */
@Component({
  selector: 'accounts-load-payments',
  templateUrl: './accounts.load-payments.component.html'
})
export class AccountsLoadPaymentsComponent extends DialogComponent<{}, boolean> {

  /** Загрузчик файлов */
  public uploader: FileUploader;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param authService Сервис авторизации
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param translator Сервис для перевода
   * @param configService
   */
  constructor(
    dialogService: DialogService,
    authService: BgAuthService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private translator: TranslateService,
    private configService: ConfigService
  ) {
    super(dialogService);

    this.uploader = new FileUploader(
      {
        url: `${this.configService.url}/crm/operations/load-xlsx`,
        autoUpload: false,
        authToken: `Bearer ${authService.token}`
      }
    );
    this.uploader.onSuccessItem = this.onSuccessItem;
    this.uploader.onErrorItem = this.onErrorItem;
  }

  /**
   * Получение признака возможности начала загрузки
   */
  public get isCanLoad() {
    return this.uploader.getNotUploadedItems().length;
  }

  /**
   * Клик по кнопке загрузки
   */
  public load() {
    this.loadingService.startLoading();
    this.uploader.uploadAll();
  }

  /**
   * Обработка при успешной загрузке файла
   * @param item Информация о файле
   * @param res Ответ сервера
   */
  private onSuccessItem = (item: FileItem, res: string) => {
    this.loadingService.stopLoading();
    const result = JSON.parse(res) as IServerResponse;
    if (result.success) {
      this.translator.get('component.accounts.load-payments.message', { val: result.data })
        .subscribe((x) => this.modalService.showInfo(x));
    } else {
      this.modalService.showError(result.data);
    }
  }

  /**
   * Обработка при ошибке загрузки файла
   * @param item Информация о файле
   * @param res Ответ сервера
   */
  private onErrorItem = (item: FileItem, res: string) => {
    this.loadingService.stopLoading();
    this.modalService.showError(res);
  }
}
