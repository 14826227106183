import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

/**
 * Данные компонента выбора страниц
 */
export interface IPageData {

  /**
   * Номер страницы
   */
  page: number;

  /**
   * Размер страницы
   */
  size: number;
}

/**
 * Компонент для выбора страниц
 */
@Component({
  selector: 'bg-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges, IPageData {

  /**
   * Количество строк
   */
  @Input()
  public rows: number;

  /**
   * Событие изменения данных компонента
   */
  @Output()
  public changed: EventEmitter<IPageData>;

  /**
   * Номер страницы
   */
  public page: number;

  /**
   * Размер страницы
   */
  @Input()
  public size: number;

  /**
   * Количество страниц
   */
  public pages: number;

  /**
   * Список страниц
   */
  public pagesList: number[];

  /**
   * Список размеров страницы
   */
  public sizesList: number[];

  /**
   * Конструктор
   */
  constructor() {
    this.changed = new EventEmitter<IPageData>();

    this.pagesList = [1];
    this.sizesList = [25, 50, 100, 250, 500];

    this.page = this.pagesList[0];
    this.size = this.size ? this.size : this.sizesList[0];
  }

  /**
   * Обработки изменения компонента
   */
  public ngOnChanges() {
    const count = Math.ceil(this.rows / this.size);
    this.pages = count ? count : 1;
    this.pagesList = Array.from(Array(this.pages), (_, i) => i + 1);
    if (!this.rows) {
      this.page = this.pagesList[0];
    }
  }

  /**
   * Переход на первую страницу
   * по нажатию на кнопку и при
   * изменении размера страницы
   */
  public first() {
    this.page = this.pagesList[0];
    this.change();
  }

  /**
   * Переход на предыдущую страницу
   */
  public prev() {
    this.page--;
    this.change();
  }

  /**
   * Переход на следующую страницу
   */
  public next() {
    this.page++;
    this.change();
  }

  /**
   * Переход на последнюю страницу
   */
  public last() {
    this.page = this.pages;
    this.change();
  }

  /**
   * Обработка изменения страницы
   */
  public change() {
    const data = {
      page: this.page,
      size: this.size
    } as IPageData;
    this.changed.emit(data);
  }

  /**
   * Получение информации о строках
   */
  public getRowsData() {
    const val1 = (this.page - 1) * this.size + 1;
    const val2 = Math.min(this.rows, val1 + this.size - 1);
    return ({val1, val2, val3: this.rows});
  }
}
