import { Component, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, flatMap } from 'rxjs/operators';

import { IMessage } from '../../../../shared/messages/IMessage';
import { IMessagesRequest } from '../../../../shared/messages/IMessagesRequest';
import { BGMessagesService } from '../../../services/b-g-messages.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { ModalResult } from '../../modal/modal.component';
import { AbstractMessagesTableComponent } from '../table/AbstractMessagesTableComponent';

/**
 * Компонент для просмотра таблицы с событиями
 */
@Component({
  selector: 'messages-events',
  templateUrl: './messages.events.component.html',
  styleUrls: ['../table/messages-table.scss']
})
export class MessagesEventsComponent
extends AbstractMessagesTableComponent<IMessage>
implements OnInit, OnChanges {

  /**
   * Конструктор
   * @param loadingService Сервис отображения процесса загрузки
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис модальных окон
   * @param messagesService Сервис работы с сообщениями
   * @param translator Сервис для перевода
   */
  constructor(
    loadingService: LoadingService,
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private messagesService: BGMessagesService,
    private translator: TranslateService
  ) {
    super(loadingService, (req: IMessagesRequest) => messagesService.getList(req));
  }

  /**
   * Выбор строки таблицы
   * @param row Строка таблицы
   */
  public selectRow(row: IMessage) {
    super.selectRow(row);

    if (row && row.d) {
      this.monitoringService.showMessagePoint(row, this.messagesService.selectedUnit.id);
    }
  }

  /**
   * Удаление выбранного события
   */
  public deleteSelected() {
    this.modalService.showConfirm('component.messages.confirm')
      .pipe(
        filter((result) => result === ModalResult.OK),
        flatMap(() => this.loadingService
          .wrap(this.messagesService.del(this.selectedRow.i, this.request.type), true)))
      .subscribe(this.updatePage);
  }

  /**
   * Очистка данных по сообщениям
   */
  public clear() {
    this.monitoringService.changeMessagesRequest(null);
    this.monitoringService.hideMessagePoint();
  }
}
