import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { IAccountListItem } from '../../../shared/accounts/IAccountListItem';
import { HistoryEntityType } from '../../../shared/crm/hist';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { AccountComponent } from '../account/account.component';
import { IEntityComponent, UnblockEntityComponent } from '../crm/diller/unblock/diller.unblock.component';
import { ExportAccountsComponent } from '../export/accounts/export.accounts.component';
import { ImportAccountsComponent } from '../import/accounts/import.accounts.component';
import { ModalResult } from '../modal/modal.component';

import { AccountsLoadPaymentsComponent } from './load-payments/accounts.load-payments.component';

/**
 * Компонент для отображения списка учетных записей
 */
@Component({
  selector: 'accounts',
  templateUrl: './accounts.component.html'
})
export class AccountsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IAccountListItem[];
  /**
   * Выбранный элемент
   */
  public selected: IAccountListItem;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;

  /**
   * Конструктор
   * @param crudService Сервис работы с ДИУП
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис работы с диалоговыми окнами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param router Маршрутизатор
   * @param translator Сервис для перевода
   */
  constructor(
    private crudService: CRUDService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private router: Router,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'creator', name: 'ui.creator' },
      { id: 'parent', name: 'component.accounts.parent' },
      { id: 'tariff', name: 'component.accounts.tariff' },
      { id: 'balance', name: 'component.accounts.balance' },
      { id: 'unitsCount', name: 'component.accounts.object-number' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Экспорт учетных записей
   */
  public export() {
    const data = { accounts: [...this.items] };
    this.dialogService.addDialog(ExportAccountsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Импорт учетных записей
   */
  public import() {
    const data = { accounts: [...this.items] };
    this.dialogService.addDialog(ImportAccountsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Добавление учетной записи
   */
  public addItem() {
    this.dialogService.addDialog(AccountComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной учетной записи
   */
  public changeSelected(id: string) {
    const data = { accountId: id };
    this.dialogService.addDialog(AccountComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранной учетной записи
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant('component.accounts.confirm', { val: name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.ACCOUNT), true)))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка платежей из файла
   */
  public loadPayments() {
    this.dialogService
      .addDialog(AccountsLoadPaymentsComponent, {})
      .subscribe(this.loadData);
  }

  /**
   * Разблокировка сущностей
   */
  public unblockEntity(id: string) {
    if (!id) {
      return;
    }

    const data = {
      type: HistoryEntityType.ACCOUNT,
      entityId: id
    } as IEntityComponent;

    this.dialogService.addDialog(UnblockEntityComponent, data).subscribe();
  }

  /**
   * Показать ТС по учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public showUnits(accountId: string) {
    this.router.navigate(['admin', 'units'], { queryParams: { a: accountId } }).then();
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    this.loadingService
      .wrap(this.crudService.getList(CRUDEntityType.ACCOUNT), true)
      .subscribe((items: IAccountListItem[]) => {
        items.forEach((i) => {
          if (!i.tariff) {
            i.tariff = '';
          }

          i.balance = (i.balance || 0) / 100;
        });

        this.items = items;

        if (this.selected) {
          this.selected = this.items.find((item) => item.id === this.selected.id);
        }
      });
  }
}
