import { Component } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { RightType, UnitRightType } from '../../../../shared/rights/RightType';
import { IUnitExport, IUnitExportSettings } from '../../../../shared/units/IUnitExportSettings';
import { IUnitListItem } from '../../../../shared/units/IUnitListItem';
import { ExportImportService } from '../../../services/export-import.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ImportUnitsComponent } from '../../import/units/import.units.component';

/**
 * Интерфейс компонента для экспорта объектов мониторинга
 */
interface IExportUnitsComponent {
  /** Список объектов мониторинга */
  units: IUnitListItem[];
}

/**
 * Компонент для экспорта объектов мониторинга
 */
@Component({
  selector: 'export-units',
  templateUrl: './export.units.component.html'
})
export class ExportUnitsComponent
extends DialogComponent<IExportUnitsComponent, boolean>
implements IExportUnitsComponent {

  /** Список объектов мониторинга */
  public units: IUnitListItem[] = [];

  /** Список объектов мониторинга, выбранных для экспорта */
  public checkedUnits: IUnitListItem[] = [];

  /** Настройки экспорта */
  public settings: IUnitExportSettings;

  /** Строка поиска по объектам */
  public search: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param exportImportService Сервис импорта/экспорта
   * @param modalService Сервис модальных окон
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private exportImportService: ExportImportService,
    private modalService: ModalService
  ) {
    super(dialogService);
    this.settings = { exportInUnit: false } as IUnitExportSettings;
  }

  /**
   * Получение признака видимости для пользователя настроек подключения
   */
  public get isCanViewConnectionSettings() {
    let result = UnitRightType.VIEW_CONNECTION_SETTING;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Получение признака видимости для пользователя дополнительных параметров
   */
  public get isCanViewAdvanced() {
    let result = RightType.VIEW_ADVANCED;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Получение признака видимости для пользователя произвольных полей
   */
  public get isCanViewArbitraryFields() {
    let result = RightType.VIEW_ARBITRARY_FIELDS;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Получение признака видимости для пользователя администраторских полей
   */
  public get isCanViewAdminFields() {
    let result = RightType.VIEW_ADMIN_FIELDS;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Получение признака видимости для пользователя команд
   */
  public get isCanViewCommands() {
    let result = UnitRightType.VIEW_COMMANDS;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Получение признака видимости для пользователя техобслуживания
   */
  public get isCanViewTO() {
    let result = UnitRightType.VIEW_TO;
    for (const unit of this.checkedUnits) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }

  /**
   * Переключение выбранности объекта мониторинга
   * @param unit Объект мониторинга
   */
  public toggleChecked(unit: IUnitListItem) {
    const checkedIndex = this.checkedUnits.indexOf(unit);
    if (checkedIndex !== -1) {
      this.checkedUnits.splice(checkedIndex, 1);
      this.units.push(unit);
    } else {
      // Если экспорт в другой объект, то выбрать можно только одну запись
      if (this.settings.exportInUnit && this.checkedUnits.length) {
        this.toggleChecked(this.checkedUnits[0]);
      }
      const index = this.units.indexOf(unit);
      if (index !== -1) {
        this.units.splice(index, 1);
        this.checkedUnits.push(unit);
      }
      this.updateExportElements();
    }
  }

  /**
   * Получение признака возможности продолжения экспорта
   */
  public get isCanContinue() {
    return this.checkedUnits.length &&
      (this.settings.exportInUnit || (this.settings.fileName &&
        this.settings.fileName !== '' && this.settings.fileName.match(/^[^<>:;,?"*|/]+$/))) &&
      (this.settings.adminFields || this.settings.arbitraryFields || this.settings.characteristics ||
      this.settings.commands || this.settings.connectionSettings || this.settings.counters ||
      this.settings.drivingQuality || this.settings.extras || this.settings.fuel || this.settings.general ||
      this.settings.icon || this.settings.moveDetection || this.settings.sensors || this.settings.to);
  }

  /**
   * Продолжение экспорта
   */
  public continue() {
    if (!this.isCanContinue) {
      return;
    }

    this.settings.units = this.checkedUnits.map((u) => u.id);
    if (this.settings.exportInUnit) {
      this.loadingService.wrap(this.exportImportService.exportUnits(this.settings), true)
        .subscribe((unitExport: IUnitExport) => {
          const data = {
            units: [...this.units, ...this.checkedUnits],
            importUnit: unitExport
          };

          this.dialogService.addDialog(ImportUnitsComponent, data)
            .pipe(filter((result) => !!result))
            .subscribe(() => this.close());
        });

    } else {
      this.loadingService.wrap(this.exportImportService.exportUnitsFile(this.settings), true)
        .subscribe((blob) => {
          let fileName = this.settings.fileName;
          fileName += this.settings.units.length > 1 ? '.zip' : '.bgu';
          FileSaver.saveAs(blob, fileName);
          this.close();
        });
    }
  }

  /**
   * Обновление настроек экспорта
   */
  private updateExportElements() {
    if (!this.isCanViewAdminFields) {
      this.settings.adminFields = false;
    }
    if (!this.isCanViewAdvanced) {
      this.settings.extras = false;
      this.settings.moveDetection = false;
      this.settings.fuel = false;
    }
    if (!this.isCanViewTO) {
      this.settings.to = false;
    }
    if (!this.isCanViewArbitraryFields) {
      this.settings.arbitraryFields = false;
    }
    if (!this.isCanViewConnectionSettings) {
      this.settings.connectionSettings = false;
    }
    if (!this.isCanViewCommands) {
      this.settings.commands = false;
    }
  }
}
