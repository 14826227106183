import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

import { getMeasure } from '../../../../shared/sensors/sensor-measure';
import { IClientRace, IRaceSensor, RaceService } from '../../../services/race.service';
import { StoreService } from '../../../services/store.service';

/**
 * Компонент для воспроизведения рейса
 */
@Component({
  selector: 'app-race-player',
  templateUrl: './race.player.component.html',
  styleUrls: ['./race.player.component.scss']
})
export class RacePlayerComponent implements OnInit, OnDestroy {

  /** Рейс, воспроизведение которого выполняет компонент */
  @Input() public race: IClientRace;

  /** Скорость воспроизведения */
  public speed: number;

  /** Подписка на таймер анимации рейса */
  public timerSubscription: Subscription;

  /** Текущее время внутри рейса */
  public time: number;

  /** Признак отображения подробной информации */
  public detail = false;

  /**
   * Конструктор
   * @param raceService Сервис работы с рейсами
   * @param store Сервис для хранения данных мониторинга
   * @param translator Сервис для перевода
   */
  constructor(
    private raceService: RaceService,
    private store: StoreService,
    private translator: TranslateService
  ) {
    this.speed = 1;
    this.race = { p: [], playPosition: 0 } as IClientRace;
    this.animate = this.animate.bind(this);
  }

  /**
   * Получение признака проигрывания рейса
   */
  get isPlaying() {
    return this.timerSubscription != null;
  }

  /**
   * Получение текущей точки рейса
   */
  get currentPoint() {
    return this.race.p[this.race.playPosition];
  }

  /**
   * Получение индекса текущей точки рейса
   */
  get currentPointIndex() {
    return this.race.playPosition;
  }

  /**
   * Установка индекса текущей точки рейса
   */
  set currentPointIndex(value: number) {
    this.race.playPosition = value;
  }

  /**
   * Получение списка наименований всех параметров текущей точки рейса
   */
  get currentPointParamKeys() {
    return Object.keys(this.currentPoint.p);
  }

  /**
   * Получение признака необходимости отображения параметров точки рейса
   */
  get isShowParams() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.params &&
      this.store.user.settings.unitDetail.params.tracking &&
      this.currentPoint && this.currentPoint.p;
  }

  /**
   * Запуск воспроизведения рейса
   */
  public play() {
    this.pause();
    if (!this.time) {
      this.time = this.currentPoint.t;
    }
    if (this.currentPointIndex >= this.race.p.length - 1) {
      this.time = this.race.p[0].t;
      this.moveToStart();
    }

    this.timerSubscription = timer(100, 100).subscribe(this.animate);
  }

  /**
   * Остановка воспроизведения рейса
   */
  public pause() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  /**
   * Остановка/воспроизведение рейса
   */
  public playPause() {
    if (this.isPlaying) {
      this.pause();
    } else { this.play(); }
  }

  /**
   * Анимация рейса
   */
  public animate() {
    this.time += 100 * this.speed;
    if (this.currentPointIndex < this.race.p.length - 1) {
      const nextPoint = this.race.p[this.currentPointIndex + 1];
      if (nextPoint.t <= this.time) {
        this.nextPoint();
      }
    } else {
      this.pause();
    }
  }

  /**
   * Перемещение прогресса воспроизведения рейса на указанную позицию
   * @param value Значение позиции от 0 до 1
   */
  public moveToPosition(value: number) {
    if (!this.race.p.length) { return; }

    const firstTime = this.race.p[0].t;
    const lastTime = this.race.p[this.race.p.length - 1].t;
    this.time = firstTime + (lastTime - firstTime) * value;
    for (let i = 1; i < this.race.p.length; i++) {
      const prevPoint = this.race.p[i - 1];
      const curPoint = this.race.p[i];
      if (curPoint.t > this.time && prevPoint.t <= this.time) {
        this.currentPointIndex = i - 1;
        this.raceService.animateRace(this.race, this.currentPoint);
        break;
      }
    }
  }

  /**
   * Переход на начало рейса
   */
  public moveToStart() {
    if (!this.race.p.length) { return; }

    this.currentPointIndex = 0;
    this.time = this.currentPoint.t;
    this.raceService.animateRace(this.race, this.currentPoint);
  }

  /**
   * Переход на конец рейса
   */
  public moveToEnd() {
    if (!this.race.p.length) { return; }

    this.currentPointIndex = this.race.p.length - 1;
    this.time = this.currentPoint.t;
    this.raceService.animateRace(this.race, this.currentPoint);
  }

  /**
   * Переход к следующей точке рейса
   */
  public nextPoint() {
    if (!this.race.p.length) { return; }

    if (this.currentPointIndex < this.race.p.length - 1) {
      this.currentPointIndex++;
      this.time = this.currentPoint.t;
      this.raceService.animateRace(this.race, this.currentPoint);
    }
  }

  /**
   * Переход к предыдущей точке рейса
   */
  public prevPoint() {
    if (!this.race.p.length) { return; }

    if (this.currentPointIndex > 0) {
      this.currentPointIndex--;
      this.time = this.currentPoint.t;
      this.raceService.animateRace(this.race, this.currentPoint);
    }
  }

  /**
   * Переключение видимости детальной информации
   */
  public toggleDetail() {
    this.detail = !this.detail;
  }

  /**
   * Переключение слежения за объектом в рейсе
   */
  public toggleEye() {
    this.race.eye = !this.race.eye;
    this.raceService.animateRace(this.race, this.currentPoint);
  }

  /**
   * Получение строки датчика
   * @param value Значение датчика
   * @param sensor Датчик
   */
  public getSensorValueString(value: number, sensor: IRaceSensor) {
    if (value == null) {
      return this.translator.instant('enums.sensors.measure.no-data');
    }

    if (Math.round(value) !== value) {
      value = Math.round(value * 1000) / 1000;
    }

    if (sensor.m && sensor.m !== '') {
      return `${value} ${sensor.m}`;
    }

    return this.translator.instant(getMeasure(value, sensor.t), {val: value});
  }

  /**
   * Обработка клика по прогрессу рейса
   * @param event Объект события клика
   */
  public onSeekbarClick(event: MouseEvent) {
    const navigationWidth = 50;
    let workPanelWidth = 400;
    const workPanelWidthString = localStorage.getItem('workPanelWidth');
    if (workPanelWidthString && +workPanelWidthString) {
      workPanelWidth = +workPanelWidthString;
    }
    const seekbarBegin = navigationWidth + 5;
    const seekbarEnd = navigationWidth + workPanelWidth - 5;
    const clientX = event.clientX;
    const percent = (clientX - seekbarBegin) / (seekbarEnd - seekbarBegin);
    this.moveToPosition(percent);
  }

  /**
   * Получение процента воспроизведения рейса
   */
  get percent() {
    if (!this.race.p.length) { return 0; }

    const firstTime = this.race.p[0].t;
    const lastTime = this.race.p[this.race.p.length - 1].t;
    if (firstTime === lastTime || this.time >= lastTime) {
      this.time = this.raceService.to.getTime();
      return 100;
    }
    return ((this.time - firstTime) / (lastTime - firstTime)) * 100;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.race && this.race.p.length) {
      this.time = this.currentPoint.t;
    }
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    if (this.timerSubscription) { this.timerSubscription.unsubscribe(); }
  }
}
