import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IArbitraryField } from '../../../shared/IArbitraryField';
import { RightType } from '../../../shared/rights/RightType';

/**
 * Компонент для управления произвольными полями
 */
@Component({
  selector: 'arbitrary-fields',
  templateUrl: './arbitrary-fields.component.html'
})
export class ArbitraryFieldsComponent {

  /** Список полей */
  @Input() public fields: IArbitraryField[] = [];
  @Output() public fieldsChange = new EventEmitter<IArbitraryField[]>();

  /** Права доступа на элемент */
  @Input() public rights: number = 0;

  /** Признак режима добавления */
  @Input() public isAddRegime: boolean = false;

  /** Признак того, что пользователь может видеть произвольные поля */
  get isCanViewFields() {
    return this.isCan(RightType.VIEW_ARBITRARY_FIELDS);
  }

  /** Признак того, что пользователь может изменять произвольные поля */
  get isCanChangeFields() {
    return this.isCan(RightType.CHANGE_ARBITRARY_FIELDS);
  }

  /** Признак того, что пользователь может видеть административные поля */
  get isCanViewAdminFields() {
    return this.isCan(RightType.VIEW_ADMIN_FIELDS);
  }

  /** Признак того, что пользователь может изменять административные поля */
  get isCanChangeAdminFields() {
    return this.isCan(RightType.CHANGE_ADMIN_FIELDS);
  }

  /** Получение признака того, являются ли все произвольные поля административными */
  get isAllFieldsAdmin() {
    return this.fields && this.fields.every((f) => f.isAdmin);
  }

  /**
   * Установка признака того, являются ли все произвольные поля административными
   * @param value Новое значение признака
   */
  set isAllFieldsAdmin(value: boolean) {
    if (!this.fields) { return; }
    for (const field of this.fields) {
      field.isAdmin = value;
    }
  }

  /**
   * Признак того, что произвольное поле заблокировано для изменения
   * @param field Проверяемое поле
   */
  public isFieldDisabled(field: IArbitraryField) {
    return !this.isCanChangeFields || (!this.isCanChangeAdminFields && field.isAdmin);
  }

  /**
   * Добавление произвольного поля
   */
  public addField() {
    if (!this.fields) { this.fields = []; }
    this.fields.push({ name: '', value: '', isAdmin: false });
    this.fieldsChange.emit(this.fields);
  }

  /**
   * Удаление произвольного поля
   * @param field Удаляемое поле
   */
  public deleteField(field: IArbitraryField) {
    if (!this.fields) { return; }
    const index = this.fields.indexOf(field);
    if (index >= 0) { this.fields.splice(index, 1); }
    this.fieldsChange.emit(this.fields);
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType) {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.rights & rightType);
  }
}
