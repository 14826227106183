import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { getLocaleName, Locale } from '../../../../../shared/users';
import { getAllEnumValues } from '../../../../utils/enums';

/**
 * Компонент для выбора языка
 */
@Component({
  selector: 'bg-language-selector',
  templateUrl: './bg-language-selector.component.html',
  styleUrls: ['./bg-language-selector.component.scss']
})
export class BgLanguageSelectorComponent {

  /**
   * Событие изменения языка
   */
  @Output()
  public langChanged = new EventEmitter<void>();

  /**
   * Признак раскрытия списка
   */
  public open: boolean = false;

  /**
   * Список доступных языков
   */
  public langs: Locale[];

  /**
   * Текущий язык
   */
  public get lang() {
    return this.translator.currentLang as Locale;
  }

  /**
   * Конструктор
   * @param translator Сервис для перевода
   */
  constructor(
    private translator: TranslateService
  ) {
    this.langs = getAllEnumValues<Locale>(Locale);
  }

  /**
   * Изменение языка
   * @param l Язык
   */
  public changeLang(l: Locale) {
    this.open = false;
    this.translator.use(l);
    this.langChanged.emit();
  }

  /**
   * Получение названия языка
   * @param l Язык
   */
  public langName(l: Locale) {
    return getLocaleName(l);
  }
}
