/**
 * Локализации
 */
export enum Locale {
  EN = 'en',
  RU = 'ru',
  ES = 'es',
  DE = 'de',
  AZ = 'az'
}

/**
 * Получение названия локализации
 * @param l Локализация
 */
export function getLocaleName(l: Locale): string {
  switch (l) {
    case Locale.RU: return 'Русский';
    case Locale.EN: return 'English';
    case Locale.ES: return 'Español';
    case Locale.DE: return 'Deutsche';
    case Locale.AZ: return 'Azərbaycanca ';
    default: return '';
  }
}
