import { Component, Input } from '@angular/core';

import { GarbageZoneType } from '../../../../shared/geozones/GarbageZoneType';
import { GeozoneType } from '../../../../shared/geozones/GeozoneType';
import { IClientGeozone } from '../../../classes/IClientGeozone';
import { BgPopoverComponent } from '../../../modules/bg-popover';
import { BgPopoverService } from '../../../modules/bg-popover';

interface IGeozonesDetailComponent {
  geozone: IClientGeozone;
}

/**
 * Компонент для отображения детальной информации
 */
@Component({
  selector: 'geozones-detail',
  templateUrl: './geozones.detail.component.html',
  styleUrls: ['./geozones.detail.component.scss']
})
export class GeozonesDetailComponent
extends BgPopoverComponent<IGeozonesDetailComponent>
implements IGeozonesDetailComponent {

  /** Геозона, по которой отображается детальная информация */
  @Input() public geozone: IClientGeozone;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   */
  constructor(popoverService: BgPopoverService) {
    super(popoverService);
  }

  /**
   * Получение наименования типа "мусорной" геозоны
   * @param type Тип "мусорной" геозоны
   */
  public getGarbageZoneTypeName(type: GarbageZoneType): string {
    switch (type) {
      case GarbageZoneType.BASE: return 'component.geo.base';
      case GarbageZoneType.CONTAINERS: return 'component.geo.containers';
      case GarbageZoneType.LANDFILL: return 'component.geo.landfill';
      default: return 'component.geo.unknown';
    }
  }

  /**
   * Получение наименования поля r для геозоны
   * @param type Тип геозоны
   */
  public getRFieldName(type: GeozoneType) {
    switch (type) {
      case GeozoneType.CIRCLE: return 'component.geo.detail.radius';
      case GeozoneType.LINE: return 'component.geo.detail.width';
      default: return 'component.geo.unknown';
    }
  }
}
