/**
 * Часовые пояса
 */
export enum Timezone {
  AOE, NUT, SST, HST, CKT, TAHT, MART, AKST, HDT, GAMT, AKDT, PST, PST2, MST,
  PDT, MDT, CST, EAST, GALT, CIST, CST2, CDT, EST, EASST, ACT, COT, ECT, PET,
  AST, CDT2, CIDST, EDT, AMT, BOT, FKT, GYT, PYT, VET, CLT, NST, ROTT, ADT,
  PMST, WGT, ART, AMST, BRT, FKST, GFT, PYST, SRT, UYT, WARST, CLST, NDT, PMDT,
  WGST, BRST, FNT, GST, UYST, CVT, AZOT, EGT, UTC, WT, AZOST, WET, GMT, EGST,
  WAT, WST, BST, IST, CET, WEST, CAT, SAST, WAST, IST2, CEST, EET, EAT, SYOT,
  AST2, IDT, TRT, FET, EEST, MSK, IRST, MUT, RET, SCT, ADT2, AMT2, AZT, GET,
  GST2, KUYT, MSD, SAMT, AFT, IRDT, MAWT, AMST2, AQTT, AZST, MVT, ORAT, PKT,
  TJT, TMT, UZT, YEKT, TFT, IST3, NPT, VOST, ALMT, BST2, BTT, KGT, OMST, QYZT,
  YEKST, IOT, MMT, CCT, DAVT, HOVT, ICT, KRAT, NOVST, NOVT, OMSST, WIB, CXT,
  CAST, BNT, CHOT, CST3, HKT, HOVST, IRKT, KRAST, MYT, PHT, SGT, ULAT, WITA,
  AWST, PYT2, ACWST, CHOST, IRKST, JST, KST, TLT, ULAST, WIT, YAKT, AWDT, PWT,
  ACST, DDUT, VLAT, YAKST, AEST, CHUT, CHST, PGT, YAPT, ACDT, LHST, MAGT, SAKT,
  SRET, VLAST, AEDT, LHDT, NFT, BST3, KOST, NCT, PONT, SBT, VUT, ANAST, ANAT,
  MAGST, PETST, PETT, FJT, GILT, MHT, NRT, TVT, WAKT, WFT, NZST, CHAST, FJST,
  PHOT, TKT, TOT, WST2, NZDT, CHADT, LINT, TOST
}

/**
 * Получение названия часового пояса
 * @param t Часовой пояс
 */
export function getTimezoneName(t: Timezone): string {
  switch (t) {
    case Timezone.AOE: return 'classes.timezones.aoe';
    case Timezone.NUT: return 'classes.timezones.nut';
    case Timezone.SST: return 'classes.timezones.sst';
    case Timezone.HST: return 'classes.timezones.hst';
    case Timezone.CKT: return 'classes.timezones.ckt';
    case Timezone.TAHT: return 'classes.timezones.taht';
    case Timezone.MART: return 'classes.timezones.mart';
    case Timezone.AKST: return 'classes.timezones.akst';
    case Timezone.HDT: return 'classes.timezones.hdt';
    case Timezone.GAMT: return 'classes.timezones.gamt';
    case Timezone.AKDT: return 'classes.timezones.akdt';
    case Timezone.PST: return 'classes.timezones.pst';
    case Timezone.PST2: return 'classes.timezones.pst2';
    case Timezone.MST: return 'classes.timezones.mst';
    case Timezone.PDT: return 'classes.timezones.pdt';
    case Timezone.MDT: return 'classes.timezones.mdt';
    case Timezone.CST: return 'classes.timezones.cst';
    case Timezone.EAST: return 'classes.timezones.east';
    case Timezone.GALT: return 'classes.timezones.galt';
    case Timezone.CIST: return 'classes.timezones.cist';
    case Timezone.CST2: return 'classes.timezones.cst2';
    case Timezone.CDT: return 'classes.timezones.cdt';
    case Timezone.EST: return 'classes.timezones.est';
    case Timezone.EASST: return 'classes.timezones.easst';
    case Timezone.ACT: return 'classes.timezones.act';
    case Timezone.COT: return 'classes.timezones.cot';
    case Timezone.ECT: return 'classes.timezones.ect';
    case Timezone.PET: return 'classes.timezones.pet';
    case Timezone.AST: return 'classes.timezones.ast';
    case Timezone.CDT2: return 'classes.timezones.cdt2';
    case Timezone.CIDST: return 'classes.timezones.cidst';
    case Timezone.EDT: return 'classes.timezones.edt';
    case Timezone.AMT: return 'classes.timezones.amt';
    case Timezone.BOT: return 'classes.timezones.bot';
    case Timezone.FKT: return 'classes.timezones.fkt';
    case Timezone.GYT: return 'classes.timezones.gyt';
    case Timezone.PYT: return 'classes.timezones.pyt';
    case Timezone.VET: return 'classes.timezones.vet';
    case Timezone.CLT: return 'classes.timezones.clt';
    case Timezone.NST: return 'classes.timezones.nst';
    case Timezone.ROTT: return 'classes.timezones.rott';
    case Timezone.ADT: return 'classes.timezones.adt';
    case Timezone.PMST: return 'classes.timezones.pmst';
    case Timezone.WGT: return 'classes.timezones.wgt';
    case Timezone.ART: return 'classes.timezones.art';
    case Timezone.AMST: return 'classes.timezones.amst';
    case Timezone.BRT: return 'classes.timezones.brt';
    case Timezone.FKST: return 'classes.timezones.fkst';
    case Timezone.GFT: return 'classes.timezones.gft';
    case Timezone.PYST: return 'classes.timezones.pyst';
    case Timezone.SRT: return 'classes.timezones.srt';
    case Timezone.UYT: return 'classes.timezones.uyt';
    case Timezone.WARST: return 'classes.timezones.warst';
    case Timezone.CLST: return 'classes.timezones.clst';
    case Timezone.NDT: return 'classes.timezones.ndt';
    case Timezone.PMDT: return 'classes.timezones.pmdt';
    case Timezone.WGST: return 'classes.timezones.wgst';
    case Timezone.BRST: return 'classes.timezones.brst';
    case Timezone.FNT: return 'classes.timezones.fnt';
    case Timezone.GST: return 'classes.timezones.gst';
    case Timezone.UYST: return 'classes.timezones.uyst';
    case Timezone.CVT: return 'classes.timezones.cvt';
    case Timezone.AZOT: return 'classes.timezones.azot';
    case Timezone.EGT: return 'classes.timezones.egt';
    case Timezone.UTC: return 'classes.timezones.utc';
    case Timezone.WT: return 'classes.timezones.wt';
    case Timezone.AZOST: return 'classes.timezones.azost';
    case Timezone.WET: return 'classes.timezones.wet';
    case Timezone.GMT: return 'classes.timezones.gmt';
    case Timezone.EGST: return 'classes.timezones.egst';
    case Timezone.WAT: return 'classes.timezones.wat';
    case Timezone.WST: return 'classes.timezones.wst';
    case Timezone.BST: return 'classes.timezones.bst';
    case Timezone.IST: return 'classes.timezones.ist';
    case Timezone.CET: return 'classes.timezones.cet';
    case Timezone.WEST: return 'classes.timezones.west';
    case Timezone.CAT: return 'classes.timezones.cat';
    case Timezone.SAST: return 'classes.timezones.sast';
    case Timezone.WAST: return 'classes.timezones.wast';
    case Timezone.IST2: return 'classes.timezones.ist2';
    case Timezone.CEST: return 'classes.timezones.cest';
    case Timezone.EET: return 'classes.timezones.eet';
    case Timezone.EAT: return 'classes.timezones.eat';
    case Timezone.SYOT: return 'classes.timezones.syot';
    case Timezone.AST2: return 'classes.timezones.ast2';
    case Timezone.IDT: return 'classes.timezones.idt';
    case Timezone.TRT: return 'classes.timezones.trt';
    case Timezone.FET: return 'classes.timezones.fet';
    case Timezone.EEST: return 'classes.timezones.eest';
    case Timezone.MSK: return 'classes.timezones.msk';
    case Timezone.IRST: return 'classes.timezones.irst';
    case Timezone.MUT: return 'classes.timezones.mut';
    case Timezone.RET: return 'classes.timezones.ret';
    case Timezone.SCT: return 'classes.timezones.sct';
    case Timezone.ADT2: return 'classes.timezones.adt2';
    case Timezone.AMT2: return 'classes.timezones.amt2';
    case Timezone.AZT: return 'classes.timezones.azt';
    case Timezone.GET: return 'classes.timezones.get';
    case Timezone.GST2: return 'classes.timezones.gst2';
    case Timezone.KUYT: return 'classes.timezones.kuyt';
    case Timezone.MSD: return 'classes.timezones.msd';
    case Timezone.SAMT: return 'classes.timezones.samt';
    case Timezone.AFT: return 'classes.timezones.aft';
    case Timezone.IRDT: return 'classes.timezones.irdt';
    case Timezone.MAWT: return 'classes.timezones.mawt';
    case Timezone.AMST2: return 'classes.timezones.amst2';
    case Timezone.AQTT: return 'classes.timezones.aqtt';
    case Timezone.AZST: return 'classes.timezones.azst';
    case Timezone.MVT: return 'classes.timezones.mvt';
    case Timezone.ORAT: return 'classes.timezones.orat';
    case Timezone.PKT: return 'classes.timezones.pkt';
    case Timezone.TJT: return 'classes.timezones.tjt';
    case Timezone.TMT: return 'classes.timezones.tmt';
    case Timezone.UZT: return 'classes.timezones.uzt';
    case Timezone.YEKT: return 'classes.timezones.yekt';
    case Timezone.TFT: return 'classes.timezones.tft';
    case Timezone.IST3: return 'classes.timezones.ist3';
    case Timezone.NPT: return 'classes.timezones.npt';
    case Timezone.VOST: return 'classes.timezones.vost';
    case Timezone.ALMT: return 'classes.timezones.almt';
    case Timezone.BST2: return 'classes.timezones.bst2';
    case Timezone.BTT: return 'classes.timezones.btt';
    case Timezone.KGT: return 'classes.timezones.kgt';
    case Timezone.OMST: return 'classes.timezones.omst';
    case Timezone.QYZT: return 'classes.timezones.qyzt';
    case Timezone.YEKST: return 'classes.timezones.yekst';
    case Timezone.IOT: return 'classes.timezones.iot';
    case Timezone.MMT: return 'classes.timezones.mmt';
    case Timezone.CCT: return 'classes.timezones.cct';
    case Timezone.DAVT: return 'classes.timezones.davt';
    case Timezone.HOVT: return 'classes.timezones.hovt';
    case Timezone.ICT: return 'classes.timezones.ict';
    case Timezone.KRAT: return 'classes.timezones.krat';
    case Timezone.NOVST: return 'classes.timezones.novst';
    case Timezone.NOVT: return 'classes.timezones.novt';
    case Timezone.OMSST: return 'classes.timezones.omsst';
    case Timezone.WIB: return 'classes.timezones.wib';
    case Timezone.CXT: return 'classes.timezones.cxt';
    case Timezone.CAST: return 'classes.timezones.cast';
    case Timezone.BNT: return 'classes.timezones.bnt';
    case Timezone.CHOT: return 'classes.timezones.chot';
    case Timezone.CST3: return 'classes.timezones.cst3';
    case Timezone.HKT: return 'classes.timezones.hkt';
    case Timezone.HOVST: return 'classes.timezones.hovst';
    case Timezone.IRKT: return 'classes.timezones.irkt';
    case Timezone.KRAST: return 'classes.timezones.krast';
    case Timezone.MYT: return 'classes.timezones.myt';
    case Timezone.PHT: return 'classes.timezones.pht';
    case Timezone.SGT: return 'classes.timezones.sgt';
    case Timezone.ULAT: return 'classes.timezones.ulat';
    case Timezone.WITA: return 'classes.timezones.wita';
    case Timezone.AWST: return 'classes.timezones.awst';
    case Timezone.PYT2: return 'classes.timezones.pyt2';
    case Timezone.ACWST: return 'classes.timezones.acwst';
    case Timezone.CHOST: return 'classes.timezones.chost';
    case Timezone.IRKST: return 'classes.timezones.irkst';
    case Timezone.JST: return 'classes.timezones.jst';
    case Timezone.KST: return 'classes.timezones.kst';
    case Timezone.TLT: return 'classes.timezones.tlt';
    case Timezone.ULAST: return 'classes.timezones.ulast';
    case Timezone.WIT: return 'classes.timezones.wit';
    case Timezone.YAKT: return 'classes.timezones.yakt';
    case Timezone.AWDT: return 'classes.timezones.awdt';
    case Timezone.PWT: return 'classes.timezones.pwt';
    case Timezone.ACST: return 'classes.timezones.acst';
    case Timezone.DDUT: return 'classes.timezones.ddut';
    case Timezone.VLAT: return 'classes.timezones.vlat';
    case Timezone.YAKST: return 'classes.timezones.yakst';
    case Timezone.AEST: return 'classes.timezones.aest';
    case Timezone.CHUT: return 'classes.timezones.chut';
    case Timezone.CHST: return 'classes.timezones.chst';
    case Timezone.PGT: return 'classes.timezones.pgt';
    case Timezone.YAPT: return 'classes.timezones.yapt';
    case Timezone.ACDT: return 'classes.timezones.acdt';
    case Timezone.LHST: return 'classes.timezones.lhst';
    case Timezone.MAGT: return 'classes.timezones.magt';
    case Timezone.SAKT: return 'classes.timezones.sakt';
    case Timezone.SRET: return 'classes.timezones.sret';
    case Timezone.VLAST: return 'classes.timezones.vlast';
    case Timezone.AEDT: return 'classes.timezones.aedt';
    case Timezone.LHDT: return 'classes.timezones.lhdt';
    case Timezone.NFT: return 'classes.timezones.nft';
    case Timezone.BST3: return 'classes.timezones.bst3';
    case Timezone.KOST: return 'classes.timezones.kost';
    case Timezone.NCT: return 'classes.timezones.nct';
    case Timezone.PONT: return 'classes.timezones.pont';
    case Timezone.SBT: return 'classes.timezones.sbt';
    case Timezone.VUT: return 'classes.timezones.vut';
    case Timezone.ANAST: return 'classes.timezones.anast';
    case Timezone.ANAT: return 'classes.timezones.anat';
    case Timezone.MAGST: return 'classes.timezones.magst';
    case Timezone.PETST: return 'classes.timezones.petst';
    case Timezone.PETT: return 'classes.timezones.pett';
    case Timezone.FJT: return 'classes.timezones.fjt';
    case Timezone.GILT: return 'classes.timezones.gilt';
    case Timezone.MHT: return 'classes.timezones.mht';
    case Timezone.NRT: return 'classes.timezones.nrt';
    case Timezone.TVT: return 'classes.timezones.tvt';
    case Timezone.WAKT: return 'classes.timezones.wakt';
    case Timezone.WFT: return 'classes.timezones.wft';
    case Timezone.NZST: return 'classes.timezones.nzst';
    case Timezone.CHAST: return 'classes.timezones.chast';
    case Timezone.FJST: return 'classes.timezones.fjst';
    case Timezone.PHOT: return 'classes.timezones.phot';
    case Timezone.TKT: return 'classes.timezones.tkt';
    case Timezone.TOT: return 'classes.timezones.tot';
    case Timezone.WST2: return 'classes.timezones.wst2';
    case Timezone.NZDT: return 'classes.timezones.nzdt';
    case Timezone.CHADT: return 'classes.timezones.chadt';
    case Timezone.LINT: return 'classes.timezones.lint';
    case Timezone.TOST: return 'classes.timezones.tost';
  }
}

/**
 * Получение разницы в минутах относительно UTC для часового пояса
 * @param t Часовой пояс
 */
export function getTimezoneOffset(t: Timezone): number {
  switch (t) {
    case Timezone.AOE: return -720;
    case Timezone.NUT: return -660;
    case Timezone.SST: return -660;
    case Timezone.HST: return -600;
    case Timezone.CKT: return -600;
    case Timezone.TAHT: return -600;
    case Timezone.MART: return -570;
    case Timezone.AKST: return -540;
    case Timezone.HDT: return -540;
    case Timezone.GAMT: return -540;
    case Timezone.AKDT: return -480;
    case Timezone.PST: return -480;
    case Timezone.PST2: return -480;
    case Timezone.MST: return -420;
    case Timezone.PDT: return -420;
    case Timezone.MDT: return -360;
    case Timezone.CST: return -360;
    case Timezone.EAST: return -360;
    case Timezone.GALT: return -360;
    case Timezone.CIST: return -300;
    case Timezone.CST2: return -300;
    case Timezone.CDT: return -300;
    case Timezone.EST: return -300;
    case Timezone.EASST: return -300;
    case Timezone.ACT: return -300;
    case Timezone.COT: return -300;
    case Timezone.ECT: return -300;
    case Timezone.PET: return -300;
    case Timezone.AST: return -240;
    case Timezone.CDT2: return -240;
    case Timezone.CIDST: return -240;
    case Timezone.EDT: return -240;
    case Timezone.AMT: return -240;
    case Timezone.BOT: return -240;
    case Timezone.FKT: return -240;
    case Timezone.GYT: return -240;
    case Timezone.PYT: return -240;
    case Timezone.VET: return -240;
    case Timezone.CLT: return -240;
    case Timezone.NST: return -210;
    case Timezone.ROTT: return -180;
    case Timezone.ADT: return -180;
    case Timezone.PMST: return -180;
    case Timezone.WGT: return -180;
    case Timezone.ART: return -180;
    case Timezone.AMST: return -180;
    case Timezone.BRT: return -180;
    case Timezone.FKST: return -180;
    case Timezone.GFT: return -180;
    case Timezone.PYST: return -180;
    case Timezone.SRT: return -180;
    case Timezone.UYT: return -180;
    case Timezone.WARST: return -180;
    case Timezone.CLST: return -180;
    case Timezone.NDT: return -150;
    case Timezone.PMDT: return -120;
    case Timezone.WGST: return -120;
    case Timezone.BRST: return -120;
    case Timezone.FNT: return -120;
    case Timezone.GST: return -120;
    case Timezone.UYST: return -120;
    case Timezone.CVT: return -60;
    case Timezone.AZOT: return -60;
    case Timezone.EGT: return -60;
    case Timezone.UTC: return 0;
    case Timezone.WT: return 0;
    case Timezone.AZOST: return 0;
    case Timezone.WET: return 0;
    case Timezone.GMT: return 0;
    case Timezone.EGST: return 0;
    case Timezone.WAT: return 60;
    case Timezone.WST: return 60;
    case Timezone.BST: return 60;
    case Timezone.IST: return 60;
    case Timezone.CET: return 60;
    case Timezone.WEST: return 60;
    case Timezone.CAT: return 120;
    case Timezone.SAST: return 120;
    case Timezone.WAST: return 120;
    case Timezone.IST2: return 120;
    case Timezone.CEST: return 120;
    case Timezone.EET: return 120;
    case Timezone.EAT: return 180;
    case Timezone.SYOT: return 180;
    case Timezone.AST2: return 180;
    case Timezone.IDT: return 180;
    case Timezone.TRT: return 180;
    case Timezone.FET: return 180;
    case Timezone.EEST: return 180;
    case Timezone.MSK: return 180;
    case Timezone.IRST: return 210;
    case Timezone.MUT: return 240;
    case Timezone.RET: return 240;
    case Timezone.SCT: return 240;
    case Timezone.ADT2: return 240;
    case Timezone.AMT2: return 240;
    case Timezone.AZT: return 240;
    case Timezone.GET: return 240;
    case Timezone.GST2: return 240;
    case Timezone.KUYT: return 240;
    case Timezone.MSD: return 240;
    case Timezone.SAMT: return 240;
    case Timezone.AFT: return 270;
    case Timezone.IRDT: return 270;
    case Timezone.MAWT: return 300;
    case Timezone.AMST2: return 300;
    case Timezone.AQTT: return 300;
    case Timezone.AZST: return 300;
    case Timezone.MVT: return 300;
    case Timezone.ORAT: return 300;
    case Timezone.PKT: return 300;
    case Timezone.TJT: return 300;
    case Timezone.TMT: return 300;
    case Timezone.UZT: return 300;
    case Timezone.YEKT: return 300;
    case Timezone.TFT: return 300;
    case Timezone.IST3: return 330;
    case Timezone.NPT: return 345;
    case Timezone.VOST: return 360;
    case Timezone.ALMT: return 360;
    case Timezone.BST2: return 360;
    case Timezone.BTT: return 360;
    case Timezone.KGT: return 360;
    case Timezone.OMST: return 360;
    case Timezone.QYZT: return 360;
    case Timezone.YEKST: return 360;
    case Timezone.IOT: return 360;
    case Timezone.MMT: return 390;
    case Timezone.CCT: return 390;
    case Timezone.DAVT: return 420;
    case Timezone.HOVT: return 420;
    case Timezone.ICT: return 420;
    case Timezone.KRAT: return 420;
    case Timezone.NOVST: return 420;
    case Timezone.NOVT: return 420;
    case Timezone.OMSST: return 420;
    case Timezone.WIB: return 420;
    case Timezone.CXT: return 420;
    case Timezone.CAST: return 480;
    case Timezone.BNT: return 480;
    case Timezone.CHOT: return 480;
    case Timezone.CST3: return 480;
    case Timezone.HKT: return 480;
    case Timezone.HOVST: return 480;
    case Timezone.IRKT: return 480;
    case Timezone.KRAST: return 480;
    case Timezone.MYT: return 480;
    case Timezone.PHT: return 480;
    case Timezone.SGT: return 480;
    case Timezone.ULAT: return 480;
    case Timezone.WITA: return 480;
    case Timezone.AWST: return 480;
    case Timezone.PYT2: return 510;
    case Timezone.ACWST: return 525;
    case Timezone.CHOST: return 540;
    case Timezone.IRKST: return 540;
    case Timezone.JST: return 540;
    case Timezone.KST: return 540;
    case Timezone.TLT: return 540;
    case Timezone.ULAST: return 540;
    case Timezone.WIT: return 540;
    case Timezone.YAKT: return 540;
    case Timezone.AWDT: return 540;
    case Timezone.PWT: return 540;
    case Timezone.ACST: return 570;
    case Timezone.DDUT: return 600;
    case Timezone.VLAT: return 600;
    case Timezone.YAKST: return 600;
    case Timezone.AEST: return 600;
    case Timezone.CHUT: return 600;
    case Timezone.CHST: return 600;
    case Timezone.PGT: return 600;
    case Timezone.YAPT: return 600;
    case Timezone.ACDT: return 630;
    case Timezone.LHST: return 630;
    case Timezone.MAGT: return 660;
    case Timezone.SAKT: return 660;
    case Timezone.SRET: return 660;
    case Timezone.VLAST: return 660;
    case Timezone.AEDT: return 660;
    case Timezone.LHDT: return 660;
    case Timezone.NFT: return 660;
    case Timezone.BST3: return 660;
    case Timezone.KOST: return 660;
    case Timezone.NCT: return 660;
    case Timezone.PONT: return 660;
    case Timezone.SBT: return 660;
    case Timezone.VUT: return 660;
    case Timezone.ANAST: return 720;
    case Timezone.ANAT: return 720;
    case Timezone.MAGST: return 720;
    case Timezone.PETST: return 720;
    case Timezone.PETT: return 720;
    case Timezone.FJT: return 720;
    case Timezone.GILT: return 720;
    case Timezone.MHT: return 720;
    case Timezone.NRT: return 720;
    case Timezone.TVT: return 720;
    case Timezone.WAKT: return 720;
    case Timezone.WFT: return 720;
    case Timezone.NZST: return 720;
    case Timezone.CHAST: return 765;
    case Timezone.FJST: return 780;
    case Timezone.PHOT: return 780;
    case Timezone.TKT: return 780;
    case Timezone.TOT: return 780;
    case Timezone.WST2: return 780;
    case Timezone.NZDT: return 780;
    case Timezone.CHADT: return 825;
    case Timezone.LINT: return 840;
    case Timezone.TOST: return 840;
  }
}
