/**
 * Тип геозоны
 */
export enum GeozoneType {
  /** Полигон */
  POLYGON = 1,
  /** Круг */
  CIRCLE,
  /** Линия */
  LINE
}

/**
 * Получение списка всех типов геозон
 */
export function getAllGeozoneTypes() {
  const result: GeozoneType[] = [];
  for (let i = 1; i <= 3; i++) {
    result.push(i as GeozoneType);
  }
  return result;
}

/**
 * Получение наименования типа геозоны
 * @param type Тип геозоны
 */
export function getGeozoneTypeName(type: GeozoneType) {
  switch (type) {
    case GeozoneType.POLYGON: return 'enums.geo.type.poly';
    case GeozoneType.CIRCLE: return 'enums.geo.type.circle';
    case GeozoneType.LINE: return 'enums.geo.type.line';
    default: return 'enums.geo.type.unknown';
  }
}
