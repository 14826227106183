import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter } from 'rxjs/operators';

import { IGuestAccessItem } from '../../../shared/guest-access/IGuestAccessItem';
import { IListItem } from '../../classes/IListItem';
import { GuestAccessService } from '../../services/guest-access.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { StoreService } from '../../services/store.service';

import { GuestAccessAddComponent } from './add/guest-access.add.component';

/**
 * Компонент для отображения списка пользователей
 */
@Component({
  selector: 'guest-access',
  templateUrl: './guest-access.component.html'
})
export class GuestAccessComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IGuestAccessItem[];
  /**
   * Выбранный элемент
   */
  public selected: IGuestAccessItem;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;

  /**
   * Конструктор
   * @param guestAccessService
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис работы с диалоговыми окнами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param translator Сервис для перевода
   * @param store
   * @param router
   */
  constructor(
    private guestAccessService: GuestAccessService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private translator: TranslateService,
    private store: StoreService,
    private router: Router
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'unitName', name: 'ui.unit' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление новой ссылки
   */
  public addItem() {
    this.dialogService.addDialog(GuestAccessAddComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  public isDeleted(item: IGuestAccessItem): boolean {
    return !!item.deletedAt || item.expirationAt < Date.now()
  }

  /**
   * Удаляние выбранной ссылки
   */
  public deleteSelected(id: string, name?: string) {
    this.guestAccessService.deleteGuestAccess(id)
      .subscribe(this.loadData);
  }

  public copyLink(hash: string) {
    const link = this.router.createUrlTree(['/guest', hash]).toString()
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = window.location.origin + link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    this.guestAccessService.getList()
      .subscribe((items) => {
        this.items = items;
      });
  }
}
