import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { forkJoin } from 'rxjs';

import { HistoryEntityType } from '../../../shared/crm/hist';
import { IRight } from '../../../shared/rights/IRight';
import { RightEntityType } from '../../../shared/rights/RightEntityType';
import { RightType, UnitRightType } from '../../../shared/rights/RightType';
import { ICharacteristics } from '../../../shared/units/ICharacteristics';
import { MhCalcMethod, MileageCalcMethod } from '../../../shared/units/ICounter';
import { IExtras } from '../../../shared/units/IExtras';
import { IFuel } from '../../../shared/units/IFuel';
import { IMoveDetection } from '../../../shared/units/IMoveDetection';
import { IUnit } from '../../../shared/units/IUnit';
import { IYandexTransportRepeater } from '../../../shared/units/IYandexTransportRepeater';
import { IActUser } from '../../../shared/users';
import { DetailComponent } from '../../classes/DetailComponent';
import { DetailTab } from '../../classes/DetailTab';
import { IClientHistory } from '../../classes/IClientHistory';
import { IClientSubjectRight } from '../../classes/IClientSubjectRight';
import { IClientUnitInGroup } from '../../classes/IClientUnitInGroup';
import { AccountsService } from '../../services/accounts.service';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { RightsService } from '../../services/rights.service';
import { StoreService } from '../../services/store.service';
import { UnitGroupsService } from '../../services/unit-groups.service';
import { YandexTransportRepeaterService } from '../../services/yandex-transport-repeater.service';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с подробной информацией по объекту мониторинга
 * при создании на его основе модального окна
 */
export interface IUnitComponent {

  /** Идентификатор объекта мониторинга */
  unitId?: string;

  /** Признак копирования */
  copy?: boolean;
}

/**
 * Компонент, представляющий подробную информацию по объекту мониторинга
 */
@Component({
  selector: 'unit',
  templateUrl: './unit.component.html'
})
export class UnitComponent extends DetailComponent<IUnitComponent> implements IUnitComponent {

  /** Идентификатор объекта мониторинга */
  public unitId: string;

  /** Признак копирования */
  public copy?: boolean;

  /** Подробная информация по объекту мониторинга */
  public unit?: IUnit;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Текст сообщения об ошибке */
  public error: string;

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Признак режима добавления */
  public isAddRegime: boolean;

  /** Заголовок окна */
  public title: string;

  /** Данные по детектору поездок */
  public moveDetection: IMoveDetection;

  /** Права пользователей на объект мониторинга */
  public userRights: IClientSubjectRight[] = [];

  /** Информация по вхождению объекта мониторинга в группы */
  public unitInGroups: IClientUnitInGroup[];

  /** Список создателей */
  public creators: IActUser[];

  /** Список записей истории */
  public history: IClientHistory[];

  /** Тип истории */
  public hType = HistoryEntityType;

  /* Признак блокировки учетной записи тс */
  public isAccountBlock: boolean;

  /** Yandex transport repeater */
  public ytRepeater: IYandexTransportRepeater;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param accountsService Сервис работы с учётными записями
   * @param crudService Сервис для работы с ДИУП
   * @param groupsService Сервис для работы с группами объектов мониторинга
   * @param loadingService Сервис для отображения процесса загрузки
   * @param rightsService Сервис для работы с правами
   * @param store Сервис для хранения данных
   * @param translator Сервис для перевода
   * @param yandexTransportRepeaterService Injects {@link YandexTransportRepeaterService}
   */
  constructor(
    dialogService: DialogService,
    private accountsService: AccountsService,
    private crudService: CRUDService,
    private groupsService: UnitGroupsService,
    private loadingService: LoadingService,
    private rightsService: RightsService,
    private store: StoreService,
    private translator: TranslateService,
    private yandexTransportRepeaterService: YandexTransportRepeaterService
  ) {
    super(dialogService);
    this.isAddRegime = true;

    this.unit = {
      extras: {
        maxMinutes: 10,
        zeroCoords: true
      },
      moveDetection: {},
      fuel: {},
      mileageCounter: { method: MileageCalcMethod.GPS, value: 0 },
      mhCounter: { method: MhCalcMethod.IGNITION_SENSOR, value: 0 },
      characteristics: {}
    } as IUnit;

    this.moveDetection = {
      minMoveSpeed: 5,
      minParkingTime: 600,
      maxPointTime: 1800
    };

    this.tabs[Tabs.Main] = new DetailTab('component.unit.main');
    this.tabs[Tabs.Access] = new DetailTab('component.unit.access');
    this.tabs[Tabs.Icon] = new DetailTab('component.unit.icon-tab');
    this.tabs[Tabs.Advanced] = new DetailTab('component.unit.additional');
    this.tabs[Tabs.Sensors] = new DetailTab('component.unit.sensors-tab');
    this.tabs[Tabs.Arbitrary] = new DetailTab('component.unit.arbitrary');
    this.tabs[Tabs.Groups] = new DetailTab('component.unit.groups');
    this.tabs[Tabs.Commands] = new DetailTab('component.unit.commands');
    this.tabs[Tabs.Quality] = new DetailTab('component.unit.quality');
    this.tabs[Tabs.Characteristics] = new DetailTab('component.unit.characteristics-tab');
    this.tabs[Tabs.Detector] = new DetailTab('component.unit.detector');
    this.tabs[Tabs.Fuel] = new DetailTab('component.unit.fuel-tab');
    this.tabs[Tabs.Service] = new DetailTab('component.unit.service');
    this.tabs[Tabs.Validation] = new DetailTab('component.unit.validation-tab');
    this.tabs[Tabs.YandexTransport] = new DetailTab('component.unit.yandex-transport-tab');
    this.tabs[Tabs.History] = new DetailTab('component.unit.history', true);

    this.title = 'component.unit.title-add';
  }

  public changeRepeater(ytRepeater: IYandexTransportRepeater) {
    this.ytRepeater = ytRepeater
  }

  /**
   * Получение признака возможности сохранения изменений
   */
  public get isCanSave(): boolean {
    return this.unit && this.unit.name && this.unit.name !== '';
  }

  /** Признак того, что пользователь может управлять ретрансляцией в Яндекс транспорт */
  public get isCanViewYTR():boolean {
    return this.isCan(UnitRightType.USE_IN_NOTIF_TASK_ROUTE_REPEATER);
  }

  /** Признак того, что пользователь может управлять доступом к объекту мониторинга */
  private get isCanChangeAccess() {
    return this.isCan(RightType.CHANGE_ACCESS);
  }

  /** Признак того, что пользователь может видеть расширенные свойства объекта мониторинга */
  private get isCanViewAdvanced() {
    return this.isCan(RightType.VIEW_ADVANCED);
  }

  /** Признак того, что пользователь может видеть информацию о ТО объекта мониторинга */
  private get isCanViewTo() {
    return this.isCan(UnitRightType.VIEW_TO);
  }

  /** Признак того, что пользователь может видеть произвольные поля объекта мониторинга */
  private get isCanViewArbitraryFields() {
    return this.isCan(RightType.VIEW_ARBITRARY_FIELDS);
  }

  /** Признак того, что пользователь может изменять иконку объекта мониторинга */
  private get isCanChangeIcon() {
    return this.isCan(RightType.CHANGE_ICON);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: IUnitComponent) {
    this.isAddRegime = !data.unitId || data.copy;

    if (data.copy) {
      this.title = 'component.unit.title-copy';
    } else if (data.unitId) {
      this.title = 'component.unit.title-edit';
    }

    if (data.unitId && data.unitId !== '') {
      const unitObservable = this.crudService.get(data.unitId, CRUDEntityType.UNIT);
      this.loadingService.withLoading(unitObservable, (unit) => {
          this.onDataLoaded(unit, data.copy);
          this.selectedTab = this.tabs[Tabs.Main];
        },
        (error) => this.error = error );
    } else {
      this.selectedTab = this.tabs[Tabs.Main];
    }

    this.tabs[Tabs.Access].hidden = this.isAddRegime;
    this.tabs[Tabs.Sensors].hidden = this.isAddRegime;
    this.tabs[Tabs.Commands].hidden = this.isAddRegime;
    this.tabs[Tabs.Groups].hidden = this.isAddRegime;
    this.tabs[Tabs.YandexTransport].hidden = this.isAddRegime;

    return super.fillData(data);
  }

  /**
   * Обработки после загруки информации по объекту мониторинга
   * @param unit Объект мониторинга
   * @param copy Признак копирования
   */
  public onDataLoaded(unit: IUnit, copy: boolean) {
    if (!unit.extras) {
      unit.extras = {} as IExtras<string>;
    }

    if (!unit.fuel) {
      unit.fuel = {} as IFuel;
    }

    if (copy) {
      delete unit.id;
      delete unit.sensors;
      unit.tracker = null;
      unit.optionals = [];
      unit.uid = null;
    }

    this.unit = unit;
    this.accountsService.getAccountBlock(unit.accountId).subscribe((isAccountBlock) => {
      this.isAccountBlock = isAccountBlock;
    });

    if (this.isCanViewAdvanced) {
      this.moveDetection = this.unit.moveDetection || {
        minMoveSpeed: 5,
        minParkingTime: 600,
        maxPointTime: 1800
      };
      this.unit.characteristics = this.unit.characteristics || {} as ICharacteristics;
    }

    const user = this.store.user;

    this.tabs[Tabs.Access].hidden = !this.isCanChangeAccess;
    this.tabs[Tabs.Icon].hidden = !this.isCanChangeIcon;
    this.tabs[Tabs.Advanced].hidden = !this.isCanViewAdvanced;
    this.tabs[Tabs.Arbitrary].hidden = !this.isCanViewArbitraryFields;
    this.tabs[Tabs.Quality].hidden = !this.isCanViewAdvanced;
    this.tabs[Tabs.Characteristics].hidden = !this.isCanViewAdvanced;
    this.tabs[Tabs.Detector].hidden = !this.isCanViewAdvanced;
    this.tabs[Tabs.Fuel].hidden = !this.isCanViewAdvanced;
    this.tabs[Tabs.Service].hidden = !this.isCanViewTo;
    this.tabs[Tabs.YandexTransport].hidden = !this.isCanViewYTR;
    this.tabs[Tabs.History].hidden = copy || !unit.optionals || !user || !user.isDealer;
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType | UnitRightType): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!this.isAddRegime || !!(this.unit.rights & rightType);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.unit.moveDetection = this.moveDetection;
    if (this.ytRepeater) {
      this.yandexTransportRepeaterService.upsert(this.unitId, this.ytRepeater).subscribe();
    }

    this.crudService.addUpdate(this.unit, CRUDEntityType.UNIT)
      .subscribe(
        () => this.updateRights(),
        (error) => this.error = error);
  }

  /**
   * Обновление прав доступа
   */
  private updateRights() {
    const rights: IRight[] = this.userRights
      .filter((right) => right.value !== right.newValue)
      .map((right) => ({
        id: right.id,
        subjectId: right.subjectId,
        objectId: right.objectId,
        objectType: RightEntityType.UNIT,
        value: right.newValue,
        // tslint:disable-next-line:no-bitwise
        combined: (right.combined | right.newValue)
      }));

    if (rights.length) {
      this.rightsService.updateRights(rights).subscribe(
        () => this.updateUnitInGroups(),
        (error) => this.translator.get('component.unit.error-1', { val: error })
          .subscribe((x) => this.error = x)
      );
    } else {
      this.updateUnitInGroups();
    }
  };

  /**
   * Обновление вхождения объекта в группы
   */
  private updateUnitInGroups() {
    const groups = this.unitInGroups
      ? this.unitInGroups.filter((g) => g.checked !== g.hasUnit)
      : [];

    if (groups.length) {
      const updates = groups.map(({ id, checked }) => ({ groupId: id, hasUnit: checked }));
      this.groupsService.updateUnitInGroups(this.unit.id, updates).subscribe(
        () => {
          this.result = true;
          this.close();
        },
        (error) => this.translator.get('component.unit.error-2', {val: error})
          .subscribe((x) => this.error = x)
      );
    } else {
      this.result = true;
      this.close();
    }
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Access,
  Icon,
  Advanced,
  Sensors,
  Arbitrary,
  Groups,
  Commands,
  Quality,
  Characteristics,
  Detector,
  Fuel,
  Service,
  Validation,
  YandexTransport,
  History
}
