import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as SocketIO from 'socket.io';
import * as io from 'socket.io-client';
import * as _ from 'underscore';

import { BgAuthService } from '../modules/bg-auth/services/bg-auth.service';
import { hexToRgb } from '../utils/hex-to-rgb';

import { CleanerService, ICleanerNotification } from './cleaner.service';
import { ConfigService } from './config.service';
import { FileService } from './file.service';
import { LoadingService } from './loading.service';
import { ModalService } from './modal.service';
import { ReportsService } from './reports.service';
import { StoreService } from './store.service';
import { SystemNotificationsService } from './system-notifications.service';

/**
 * Сервис для работы с онлайн уведомлениями
 */
@Injectable()
export class OnlineNotificationsService {

  /**
   * Список уведомлений
   */
  public notifications: IClientOnlineNotification[] = [];

  /**
   * Идентификатор выбранного ТС
   */
  public selectedUnit: string = null;

  /**
   * Событие получения уведомления с желтым/красным сигналом светофора
   */
  public notificationReceivedSubject = new Subject<ITrafficLightNotification[]>();

  /**
   * Клиент socket.io
   */
  private socket: SocketIO.Socket;

  /**
   * Конструктор
   * @param authService Сервис работы с аутентификацией
   * @param http HTTP клиент
   * @param modalService Сервис для работы с модальными окнами
   * @param configService Сервис конфигурации
   * @param systemNotificationsService Сервис для работы с системными уведомлениями
   * @param reportService
   * @param fileService
   * @param cleanerService
   * @param store
   * @param loadingService
   */
  constructor(
    private authService: BgAuthService,
    private http: HttpClient,
    private modalService: ModalService,
    private configService: ConfigService,
    private systemNotificationsService: SystemNotificationsService,
    private reportService: ReportsService,
    private fileService: FileService,
    private cleanerService: CleanerService,
    private store: StoreService,
    private loadingService: LoadingService
  ) {
    this.loadNotifications();
    if (this.authService.authenticated) {
      this.notificationsSubscribe(this.authService.token);
    }
  }

  /**
   * Получение количества непрочитанных уведомлений
   */
  public get notReadedNotificationsCount() {
    return this.notifications.filter((n) => !n.readed)
      .length + this.systemNotificationsService.systemNotifications.length;
  }

  /**
   * Проверяет содержит ли уведомление желтый/красный сигнал светофора
   * @param notification Уведомление
   */
  private static isLighted(notification: IClientOnlineNotification) {
    return notification.tl === TrafficLightType.GREEN
      || notification.tl === TrafficLightType.RED
      || notification.tl === TrafficLightType.YELLOW;
  }

  /**
   * Подписка на получение уведомлений
   * @param token Токен пользователя
   */
  public notificationsSubscribe(token: string) {
    const query: Record<string, string> = {token};
    const observable = localStorage.getItem('observable');
    if (!!observable) {
      query.observable = observable;
    }

    this.socket = io({transports: ['websocket'], query});

    this.socket.on('report', (rn: IReportNotification) => {
      if (rn.userId === this.store.user.id) {
        this.reportService.updateQueueList(rn);
      }
    });

    this.socket.on('file', this.fileService.processFile);

    this.socket.on('cleaner', (cn: ICleanerNotification) => {
      this.cleanerService.delete(cn)
    });

    this.socket.on('notification', (notification: IClientOnlineNotification) => {

      if (notification.sound) {
        const audio = new Audio('/assets/sounds/notification.mp3');
        audio.play().finally();
      }

      if (notification.tl) {
        notification.color = getTrafficLightTypeColor(notification.tl);
      }

      if (!notification.color) {
        notification.color = '#00FF00';
      }

      const rgb = hexToRgb(notification.color);
      notification.bgColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`;

      this.notifications.unshift(notification);
      this.saveNotifications();

      if (OnlineNotificationsService.isLighted(notification)) {
        this.notificationReceivedSubject.next([{
          unitId: notification.unitId,
          trafficLight: notification.tl
        }]);
      }
    });
  }

  /**
   * Сохранить список уведомлений где-то (хранилище, или т.п.)
   */
  public saveNotifications() {
    sessionStorage.setItem('notifications', JSON.stringify(this.notifications));
  }

  /**
   * Загрузка списка уведомлений откуда-то (хранилище, или т.п.)
   */
  public loadNotifications() {
    const notificationsString = sessionStorage.getItem('notifications');
    if (notificationsString && notificationsString !== '') {
      this.notifications = JSON.parse(notificationsString);
    }
  }

  /**
   * Очистка уведомлений (используется при выходе из системы)
   */
  public clean() {
    this.notifications = [];
    this.saveNotifications();

    if (this.socket) {
      try {
        this.socket.disconnect(true)
      } catch (error) {
        // ignored
      }
      this.socket = null;
    }
  }

  /**
   * Возвращает цвет светофора для уведомлений по ТС
   * @param unitId Идентификатор ТС
   */
  public getUnitNotificationTrafficLight(unitId: string): TrafficLightType {
    let trafficLight: TrafficLightType = null;
    for (const n of this.notifications) {
      if (n.unitId === unitId && !n.readed && n.tl && OnlineNotificationsService.isLighted(n)) {
        trafficLight = n.tl;
        if (n.tl === TrafficLightType.RED) {
          break;
        }
      }
    }

    return trafficLight;
  }

  /**
   * Обновление подсветки ТС в списке ТС
   * @param notification Уведомление
   */
  public changeUnitTrafficLight(notification: IClientOnlineNotification) {
    if (OnlineNotificationsService.isLighted(notification)) {
      this.notificationReceivedSubject.next([
        {
          unitId: notification.unitId,
          trafficLight: this.getUnitNotificationTrafficLight(notification.unitId)
        }
      ]);
    }
    this.saveNotifications();
  }

  /**
   * Удаление уведомления
   * @param notification Уведомление
   */
  public deleteNotification(notification: IClientOnlineNotification) {
    const delIndex = this.notifications.indexOf(notification);
    this.notifications.splice(delIndex, 1);
    this.changeUnitTrafficLight(notification);
  }

  /**
   * Удаление всех уведомлений
   */
  public deleteAllNotifications() {
    const data = _.uniq(this.notifications.map((n) => n.unitId)).map((i) =>
      ({unitId: i, trafficLight: null}));
    this.notifications = [];
    this.selectedUnit = null;
    this.saveNotifications();
    if (data.length) {
      this.notificationReceivedSubject.next(data);
    }
  }

  /**
   * Удаление прочитанных сообщений
   */
  public deleteReadNotifications() {
    this.notifications = this.notifications.filter((x) => !x.readed);
    this.saveNotifications();
  }
}

/**
 * Типы сигнала светофора
 */
export enum TrafficLightType {
  /** Зеленый */
  GREEN = 1,
  /** Желтый */
  YELLOW,
  /** Красный */
  RED
}

/**
 * Получение списка всех типов сигналов светофора
 */
export function getAllTrafficLightTypes(): TrafficLightType[] {
  return [
    TrafficLightType.GREEN,
    TrafficLightType.YELLOW,
    TrafficLightType.RED
  ];
}

/**
 * Получение наименования типа сигнала светофора
 * @param type Тип сигнала светофора
 */
export function getTrafficLightTypeName(type: TrafficLightType): string {
  switch (type) {
    case TrafficLightType.GREEN:
      return 'enums.notifications.traffic-light-type.green';
    case TrafficLightType.YELLOW:
      return 'enums.notifications.traffic-light-type.yellow';
    case TrafficLightType.RED:
      return 'enums.notifications.traffic-light-type.red';
    default:
      return 'enums.notifications.traffic-light-type.unknown';
  }

}

/**
 * Получение цвета, соответствующего сигналу светофора
 * @param type Тип сигнала светофора
 */
export function getTrafficLightTypeColor(type: TrafficLightType): string {
  switch (type) {
    case TrafficLightType.GREEN:
      return '#2dc937';
    case TrafficLightType.YELLOW:
      return '#e7b416';
    case TrafficLightType.RED:
      return '#cc3232';
    default:
      return '#00FF00';
  }
}

/**
 * Онлайн-уведомление для использования на стороне клиента
 */
export interface IClientOnlineNotification {
  /** Признак прочитанности */
  readed: boolean;
  /** Признак того, что отображается детальная информация */
  detail: boolean;
  /** Цвет фона */
  bgColor: string;
  /** Идентификатор учетной записи */
  accountId: string;
  /** Идентификатор ТС */
  unitId: string;
  /** Наименование */
  name: string;
  /** Значение сигнала светофора */
  tl?: TrafficLightType;
  /** Время, когда произошло событие */
  t: number;
  /** Наименование ТС, вызвавшего событие */
  unit: string;
  /** Текст сообщения */
  message: string;
  /** Цвет */
  color: string;
  /** Звук */
  sound: boolean;
}

/**
 * Интерфейс параметров уведомления с сигналом светофора
 */
export interface ITrafficLightNotification {
  /** Идентификатор ТС */
  unitId: string;
  /** Значение сигнала светофора уведомления */
  trafficLight: TrafficLightType;
}

/**
 * Notification interface for reports
 */
export interface IReportNotification {
  /** Account identifier */
  accountId: string,
  userId: string,
  /** Report identifier */
  reportId: string,
  /** Name of report template */
  name: string,
  /** Message from report service */
  message: string,
  /** Report status */
  status: number
}
