import { AfterViewInit, Component, ElementRef } from '@angular/core';

import { BgPopoverComponent, BgPopoverService } from '../../modules/bg-popover';

/**
 * Интерфейс для компонента подсказки
 */
interface ITooltip {
  /** Текст */
  text: string;
}

/**
 * Компонент подсказки
 */
@Component({
  selector: 'bg-tooltip',
  templateUrl: './bg-tooltip.component.html',
  styleUrls: ['./bg-tooltip.component.scss']
})
export class BgTooltipComponent extends BgPopoverComponent<ITooltip> implements ITooltip, AfterViewInit {
  public text: string;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   * @param element Элемент
   */
  constructor(
    protected popoverService: BgPopoverService,
    private element: ElementRef
) {
    super(popoverService);
  }

  /**
   * Подгонка размеров и положения компонента после инициализации
   */
  public ngAfterViewInit(): void {
    this.popoverService.fitPopover();
  }
}
