import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';

/**
 * Допускающие типы для getList
 */
type getListType = Exclude<CRUDEntityType, CRUDEntityType.AUTO_BINDING>;

/**
 * Допускающие типы для getListLight
 */
type getListLightType = CRUDEntityType.UNIT | CRUDEntityType.UNIT_GROUP
  | CRUDEntityType.ACCOUNT | CRUDEntityType.DRIVER | CRUDEntityType.TARIFF
  | CRUDEntityType.EQUIPMENT | CRUDEntityType.SIM_CARD | CRUDEntityType.CONTRACT
  | CRUDEntityType.CLIENT | CRUDEntityType.DRIVER_GROUP | CRUDEntityType.TRAILER_GROUP
  | CRUDEntityType.TRAILER | CRUDEntityType.USER;

/**
 * Допускающие типы для get
 */
type getType = Exclude<CRUDEntityType,
  CRUDEntityType.GEOZONE_GROUP | CRUDEntityType.CRM_REF_ITEM | CRUDEntityType.DRIVER_GROUP
  | CRUDEntityType.TRAILER_GROUP | CRUDEntityType.ASSIGNMENT>;

/**
 * Допускающие типы для addUpdate
 */
type addUpdateType = Exclude<CRUDEntityType, CRUDEntityType.ASSIGNMENT>;

type HttpOptions = {
  headers?: HttpHeaders | { [p: string]: string | string[] },
  params?: HttpParams | { [p: string]: string | string[] }
}

/**
 * Сервис для работы с ДИУП
 */
@Injectable()
export class CRUDService {

  /** Базовая часть url */
  private readonly baseUrl = `${this.configService.url}`

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param loadingService Сервис для отображения процесса загрузки
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private configService: ConfigService
  ) {
  }

  /**
   * Получение списка
   * @param type Тип сущности
   * @param query Дополнительные аргументы
   */
  public getList(type: getListType, query?: Dict<string>): Observable<any[]> {
    const url = `${this.baseUrl}/${getCRUDEntityTypePath(type)}`;
    const options: HttpOptions = { headers: this.loadingService.waiterHeader }
    if (query) {
      options.params = query
    }
    return this.http.get<any[]>(url, options);
  }

  /**
   * Получение списка (облегченная версия)
   * @param type Тип сущности
   * @param query Дополнительные аргументы
   */
  public getListLight(type: getListLightType, query?: Dict<string>): Observable<any[]> {
    const url = `${this.baseUrl}/${getCRUDEntityTypePath(type)}/light`;
    const options: HttpOptions = { headers: this.loadingService.waiterHeader }
    if (query) {
      options.params = query
    }
    return this.http.get<any[]>(url, options);
  }

  /**
   * Получение подробной информации о записи
   * @param id Идентификатор записи
   * @param type Тип сущности
   */
  public get(id: string, type: getType): Observable<any> {
    const url = `${this.baseUrl}/${getCRUDEntityTypePath(type)}/${id}`;
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.get<any>(url, options);
  }

  /**
   * Добавление/изменение записи
   * @param body Данные записи
   * @param type Тип сущности
   */
  public addUpdate(body: any, type: addUpdateType): Observable<string> {
    const url = `${this.baseUrl}/${getCRUDEntityTypePath(type)}`;
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.post<string>(url, body, options);
  }

  /**
   * Удаление записи
   * @param id Идентификатор записи
   * @param type Тип сущности
   */
  public del(id: string, type: CRUDEntityType): Observable<string> {
    const url = `${this.baseUrl}/${getCRUDEntityTypePath(type)}/${id}`;
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.delete<string>(url, options);
  }
}

/**
 * Типы сущностей, доступные в сервисе
 */
export enum CRUDEntityType {
  /** Учетная запись */
  ACCOUNT = 1,
  /** Пользователь */
  USER,
  /** Объект мониторинга */
  UNIT,
  /** Группа объектов */
  UNIT_GROUP,
  /** Датчик */
  SENSOR = 7,
  /** Геозона */
  GEOZONE,
  /** Задание */
  TASK,
  /** Группа геозон */
  GEOZONE_GROUP,
  /** Запись справочника CRM */
  CRM_REF_ITEM,
  /** Оборудование */
  EQUIPMENT,
  /** Водитель */
  DRIVER,
  /** Группа водителей */
  DRIVER_GROUP,
  /** Тариф */
  TARIFF,
  /** SIM-карта */
  SIM_CARD,
  /** Автоматическое прикрепление */
  AUTO_BINDING,
  /** Договор */
  CONTRACT,
  /** Клиент */
  CLIENT,
  /** Прицеп */
  TRAILER,
  /** Группа прицепов */
  TRAILER_GROUP,
  /** Назначение */
  ASSIGNMENT,
  /** Маршрут */
  ROUTE = 24,
  /** Ретранслятор */
  REPEATER
}

/**
 * Получение пути, по которому находятся операции с сущность в REST api
 * @param type Тип сущности
 */
function getCRUDEntityTypePath(type: CRUDEntityType): string {
  switch (type) {
    case CRUDEntityType.ACCOUNT:
      return 'accounts';
    case CRUDEntityType.USER:
      return 'users';
    case CRUDEntityType.UNIT_GROUP:
      return 'unit-groups';
    case CRUDEntityType.UNIT:
      return 'units';
    case CRUDEntityType.SENSOR:
      return 'sensors';
    case CRUDEntityType.GEOZONE:
      return 'geozones';
    case CRUDEntityType.TASK:
      return 'tasks';
    case CRUDEntityType.GEOZONE_GROUP:
      return 'geozone-groups';
    case CRUDEntityType.CRM_REF_ITEM:
      return 'crm/ref-items';
    case CRUDEntityType.EQUIPMENT:
      return 'crm/equipments';
    case CRUDEntityType.DRIVER:
      return 'drivers';
    case CRUDEntityType.DRIVER_GROUP:
      return 'driver-groups';
    case CRUDEntityType.TARIFF:
      return 'crm/tariffs';
    case CRUDEntityType.SIM_CARD:
      return 'crm/sim-cards';
    case CRUDEntityType.AUTO_BINDING:
      return 'auto-bindings';
    case CRUDEntityType.CONTRACT:
      return 'crm/dogovors';
    case CRUDEntityType.CLIENT:
      return 'crm/clients';
    case CRUDEntityType.TRAILER:
      return 'trailers';
    case CRUDEntityType.TRAILER_GROUP:
      return 'trailer-groups';
    case CRUDEntityType.ASSIGNMENT:
      return 'assignments';
    case CRUDEntityType.ROUTE:
      return 'routes';
    case CRUDEntityType.REPEATER:
      return 'retranslators';
    default:
      return '';
  }
}
