import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { IDuration, TimeSpan } from '../../../../shared/classes/TimeSpan';
import { BgPopoverService } from '../../../modules/bg-popover';
import { IPopoverPosition } from '../../../modules/bg-popover/IPopoverPosition';
import { LoadingService } from '../../../services/loading.service';
import {
  GetIntervalsResponse,
  IPointColumn,
  IPointTable,
  IReportColumn,
  IReportElement,
  IReportInterval,
  IReportPointInfo,
  IReportRow,
  IReportRowsInfo,
  IReportTableInfo,
  ReportChartType, ReportColumnType,
  ReportsService
} from '../../../services/reports.service';
import { getScreenCordinates } from '../../../utils/coords';
import { IMapReportInterval } from '../../map/map.component';
import { IPageData } from '../../paginator/paginator.component';

import { EmptyCellTooltipComponent } from './empty-cell-tooltip.component';

/**
 * Минимальная ширина колонки
 */
const minColumnWidth = 30;

/**
 * Компонент для просмотра таблицы отчета
 */
@Component({
  selector: 'report-table',
  templateUrl: './report.table.component.html',
  styleUrls: ['./report.table.component.scss']
})
export class ReportTableComponent implements OnChanges {

  /** Идентификатор таблицы отчета */
  @Input() public reportTable: IReportElement;

  /** Column data types */
  public cTypes = ReportColumnType;

  /** Размер страницы */
  public pageSize: number;

  /** Список колонок */
  public columns: IClientReportColumn[];

  /** Признак наличия строки итогов */
  public isTotal: boolean;

  /** Признак наличия группировки */
  public isGroups: boolean;

  /** Признак наличия столбца с группировкой */
  public isGroupColumn: boolean;

  /** Признак наличия столбца с номерами строк */
  public isRowNumbers: boolean;

  /** Колонка для группировки */
  public grColumn: IClientReportColumn;

  /** Колонка для номера строки */
  public rnColumn: IClientReportColumn;

  /** Строки страницы */
  public rows: IReportRow[];

  /** Номер первой видимой строки */
  public offset: number;

  /** Общее количество видимых строк */
  public rowsCount: number;

  /** Ширина колонки статуса группировки */
  public gColWidth: number;

  /** HTML элемент, ширина которого изменяется */
  public element: HTMLElement;

  /** Колонка таблицы, ширина которой изменяется */
  public column: IClientReportColumn;

  /** Координата Х указателя мыши */
  public mouseX: number;

  /** Ширина таблицы */
  public tableWidth: number;

  /** Ширина последней колонки */
  public lastColumnWidth: number = 0;

  /** Состояние отображения интервалов по всем строкам */
  public eye: boolean = false;

  /** Контейнер таблицы */
  @ViewChild('columnsBox', {static: false}) private cBox: ElementRef;

  /** Контейнер таблицы */
  @ViewChild('totalBox', {static: false}) private tBox: ElementRef;

  /**
   * Конструктор
   * @param reportsService Сервис для работы с отчетами
   * @param popoverService Сервис всплывающих окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    public reportsService: ReportsService,
    private popoverService: BgPopoverService,
    private loadingService: LoadingService
  ) {
    this.reset();
  }

  /**
   * Обработки после изменения компонента
   */
  public ngOnChanges() {
    this.reset();
    if (!this.reportTable) {
      return;
    }

    this.loadingService.withLoading(
      this.reportsService.getTable(this.reportTable.id),
      this.onTableLoaded
    );
  }

  /**
   * Обработка события скрола элемента со строками таблицы
   * @param event Событие
   */
  public onTableScroll(event: Event) {
    const scrollLeft = (event.target as Element).scrollLeft;
    this.cBox.nativeElement.style.left = `${-scrollLeft}px`;
    if (this.isTotal) {
      this.tBox.nativeElement.style.left = `${-scrollLeft}px`;
    }
  }

  /**
   * Обработка события начала изменения размера колонки таблицы
   * @param event Событие
   * @param column Колонка
   */
  public onStartResize(event: MouseEvent, column: IClientReportColumn) {
    this.element = (event.target as Element).parentElement;
    this.column = column;
    this.column.width = this.element.offsetWidth;
    this.mouseX = event.pageX;
  }

  /**
   * Получение строки с длительностью времени
   * @param value Количество миллисекунд
   */
  public getTime(value: number): IDuration {
    return new TimeSpan(value).toDuration();
  }

  /**
   * Получение CSS класса строки
   * @param row Строка
   */
  public getRowClass(row: IReportRow) {
    if (row.l) {
      return `gr${row.l % 10}`;
    }
    return null;
  }

  /**
   * Переключение признака отображения дочерних элементов для строки
   * @param row Строка
   */
  public toggleGroup(row: IReportRow) {
    if (!!row.g && row.g !== 0) {
      this.updatePage(row.i);
    }
  }

  /**
   * Отображение на карте точки, соответствующей определенному времени
   * @param value Время
   * @param oid Идентификатор объекта
   */
  public showOnMap(value: number, oid: string) {
    this.loadingService.withLoading(
      this.reportsService.getPoint(oid, value, ReportChartType.TIME),
      (point: IReportPointInfo) => this.reportsService.showPoint(point));
  }

  /**
   * Переключение отображения строки на карте
   * @param row Строка
   */
  public toggleEye(row: IReportRow) {
    this.loadingService.withLoading(
      this.reportsService.getIntervals(this.reportTable.id, row.i, !row.e),
      this.onIntervalsLoaded);
  }

  /**
   * Переключение отображения всех строк на карте
   */
  public toggleAllEyes() {
    if (!this.rows.length) {
      return;
    }

    this.loadingService.withLoading(
      this.reportsService.getAllIntervals(this.reportTable.id, !this.eye),
      this.onIntervalsLoaded);
  }

  /**
   * Обработка события входа мыши на пустую ячейку таблицы
   * @param e Объект события
   * @param colIndex Индекс колонки
   */
  public mouseEnterEmptyCell(e: MouseEvent, colIndex: number) {
    const col = this.columns[colIndex];
    if (!col || !col.whyEmpty || col.whyEmpty === '') {
      return;
    }

    const target = e.target as HTMLElement;
    const position: IPopoverPosition = getScreenCordinates(target);
    const halfHeight = window.innerHeight / 2;
    if (position.y - TooltipPadding > halfHeight) {
      position.y -= TooltipPadding;
    } else if (position.y + target.offsetHeight + TooltipPadding < halfHeight) {
      position.y += target.offsetHeight + TooltipPadding;
    } else {
      position.y += target.offsetHeight + TooltipPadding;
      position.noRecalcY = true;
    }

    const halfWidth = window.innerWidth / 2;
    if (position.x + target.offsetWidth - TooltipPadding > halfWidth) {
      position.x += target.offsetWidth - TooltipPadding;
    } else {
      position.x += TooltipPadding;
    }

    this.popoverService.addPopover(
      EmptyCellTooltipComponent, position, {title: col.whyEmpty});
  }

  /**
   * Обработка события покидания мыши пустой ячейки таблицы
   */
  public mouseLeaveEmptyCell() {
    this.popoverService.removePopover();
  }

  /**
   * Обработка события изменения пагинатора
   * @param e Данные пагинатора
   */
  public onPageChanged(e: IPageData) {
    this.pageSize = e.size;
    localStorage.setItem('reportTablePageSize', e.size.toString());
    this.offset = (e.page - 1) * e.size;
    this.updatePage();
  }

  /**
   * Обработка события изменения размера колонки таблицы
   * @param event Событие
   */
  public onResize = (event: MouseEvent) => {
    if (this.element) {
      let dif = event.pageX - this.mouseX;
      this.mouseX = event.pageX;

      const width = this.element.offsetWidth;
      if (width + dif < minColumnWidth) {
        dif = minColumnWidth - width;
      }

      this.column.width += dif;
      this.setTableWidth();
    }
  }

  /**
   * Обработка события завершения изменения размера колонки таблицы
   */
  public onEndResize() {
    this.element = null;
    this.column = null;
  }

  /**
   * Обновление страницы
   * @param id Идентификатор строки
   */
  private updatePage(id?: string) {
    this.loadingService.withLoading(
      this.reportsService.getRows(this.reportTable.id, this.offset, this.pageSize, id),
      this.onRowsLoaded
    );
  }

  /**
   * Обработка загрузки информации о таблице
   * @param table Информация о таблице
   */
  private onTableLoaded = (table: IReportTableInfo) => {
    this.isTotal = table.total;
    this.isGroups = table.grouping;

    this.isRowNumbers = table.rowNums;
    if (this.isRowNumbers) {
      this.rnColumn = {name: null, type: null, width: minColumnWidth};
    }

    this.isGroupColumn = table.groupColumn;
    if (this.isGroupColumn) {
      this.grColumn = {name: null, type: null};
    }

    this.columns = table.columns;
    this.fitColumns();
    this.updatePage();
  }

  /**
   * Обработка загрузки информации о строках таблицы
   * @param rowsInfo Список строк с количеством
   */
  private onRowsLoaded = (rowsInfo: IReportRowsInfo) => {
    this.rows = rowsInfo.rows;
    this.rowsCount = rowsInfo.count;
    this.gColWidth = rowsInfo.depth * 10 + 17;
    this.eye = rowsInfo.eye;
  }

  /**
   * Обработка загрузки интервалов
   * @param intervals Интервалы
   */
  private onIntervalsLoaded = (intervals: GetIntervalsResponse) => {
    if (intervals.some((i) => typeof (i) === 'object')) {
      const mapIntervals: IMapReportInterval[] = (intervals as IReportInterval[])
        .map((interval) => ({
          ...interval,
          table: ({
            name: this.reportTable.name,
            columns: this.columns.map((c, i) => ({
              name: c.name,
              type: c.type,
              value: interval.r.c[i]
            } as IPointColumn))
          } as IPointTable),
          features: []
        }));
      this.reportsService.showIntervals(mapIntervals);
    } else {
      this.reportsService.hideIntervals(intervals as string[]);
    }
    this.updatePage();
  }

  /**
   * Установка ширины таблицы по ширине колонок
   */
  private setTableWidth() {
    const box = this.cBox.nativeElement;
    const boxWidth = box.offsetWidth + box.offsetLeft - 17;
    this.tableWidth = this.getColsWidth();
    const dif = boxWidth - this.tableWidth;
    this.lastColumnWidth = dif < 0 ? 0 : dif;
  }

  /**
   * Подгонка размеров колонок при загрузке строк
   */
  private fitColumns() {
    const width = this.cBox.nativeElement.offsetWidth - 17;
    this.tableWidth = width;

    let numCols = this.columns.filter((x) => !x.width).length;
    if (this.rnColumn && !this.rnColumn.width) {
      numCols++;
    }

    if (this.grColumn && !this.grColumn.width) {
      numCols++;
    }

    const columnsWidth = this.getColsWidth();
    let colWidth = (width - columnsWidth) / numCols;

    if (colWidth < minColumnWidth) {
      colWidth = minColumnWidth;
    }

    if (this.rnColumn && !this.rnColumn.width) {
      this.rnColumn.width = colWidth;
    }

    if (this.grColumn && !this.grColumn.width) {
      this.grColumn.width = colWidth;
    }

    this.columns.forEach((x) => x.width = x.width ? x.width : colWidth);
  }

  /**
   * Получение ширины всех колонок таблицы
   */
  private getColsWidth() {
    let result = this.columns.reduce((a, b) => a + (b.width ? b.width : 0), 0);
    result += this.isGroups ? this.gColWidth : 0;
    result += this.reportsService.reportInfo.showIntervals ? 23 : 0;
    result += this.rnColumn && this.rnColumn.width ? this.rnColumn.width : 0;
    result += this.grColumn && this.grColumn.width ? this.grColumn.width : 0;
    return result;
  }

  /**
   * Сброс состояния компонента
   */
  private reset() {
    const ps = localStorage.getItem('reportTablePageSize');
    this.pageSize = (!ps || ps === '' || ps === '0') ? 25 : +ps;
    this.columns = [];
    this.isTotal = null;
    this.isGroups = null;
    this.isGroupColumn = null;
    this.isRowNumbers = null;
    this.grColumn = null;
    this.rnColumn = null;
    this.rows = [];
    this.offset = 0;
    this.rowsCount = 0;
    this.gColWidth = 17;
    this.element = null;
    this.column = null;
    this.mouseX = null;
    this.tableWidth = null;
  }
}

/**
 * Свойства столбца таблицы на стороне клиента
 */
interface IClientReportColumn extends IReportColumn {
  /** Ширина колонки */
  width?: number;
}

const TooltipPadding = 3;
