/**
 * Тип активности ТС
 */
export enum UnitActivityType {
  /** Активно */
  ACTIVE = 1,
  /** ТС заблокировано */
  UNIT_BLOCKED,
  /** Клиент заблокирован */
  CLIENT_BLOCKED,
  /** Нет данных */
  NO_DATA,
  /** Опытная эксплуатация ТС */
  TRIAL_OPERATION
}
