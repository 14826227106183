import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IObjectRight } from '../../shared/rights/IObjectRight';
import { IRight } from '../../shared/rights/IRight';
import { ISubjectRight } from '../../shared/rights/ISubjectRight';
import { RightEntityType } from '../../shared/rights/RightEntityType';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с правами
 */
@Injectable()
export class RightsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/rights`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Получение прав доступа по объекту права
   * @param objectId Идентификатор объекта права
   * @param type Тип объекта права
   */
  public getObjectRights(objectId: string, type: RightEntityType): Observable<ISubjectRight[]> {
    const url = `${this.baseUrl}/object`;
    const body = {id: objectId, type};
    return this.http.post<ISubjectRight[]>(url, body);
  }

  /**
   * Получение прав доступа по субъекту права
   * @param subjectId Идентификатор субъекта права
   * @param type Тип объекта права
   */
  public getSubjectRights(subjectId: string, type: RightEntityType): Observable<IObjectRight[]> {
    const url = `${this.baseUrl}/subject`;
    const body = {id: subjectId, type};
    return this.http.post<IObjectRight[]>(url, body);
  }

  /**
   * Обновление прав доступа
   * @param body Список прав доступа
   */
  public updateRights(body: IRight[]): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(this.baseUrl, body);
  }
}

/**
 * Типы шаблонов прав
 */
export enum RightTemplateType {
  DENIED,
  READ,
  FULL_NO_DEL
}
