import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMyDate, IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IListItem } from '../../classes/IListItem';
import { correctDisableDatepicker } from '../../utils/intervals'

/**
 * Интерфейс для выбора периода
 */
interface ISelectPeriod {
  /** Начало периода */
  from: Date;

  /** Конец периода */
  to: Date;

  /** Упрощенная версия компонента (без времени) */
  isShortVersion?: boolean

  /** Ограничение прошлого периода */
  disableUntil?: Date;

  /** Ограничение будущего периода */
  disableSince?: Date;

  /** Признак отображения часов */
  isDisableTimes?: boolean;
}

/**
 * Компонент для выбора периода
 */
@Component({
  selector: 'select-period',
  templateUrl: './select-period.component.html',
  styleUrls: ['./select-period.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectPeriodComponent
extends DialogComponent<ISelectPeriod, ISelectPeriod>
implements ISelectPeriod {

  /** Начало периода */
  public from: Date;

  /** Конец периода */
  public to: Date;

  /** Упрощенная версия компонента (без времени) */
  public isShortVersion: boolean = false;

  /** Выбранный тип последних (минут, часов, дней) */
  public selectedLast: PeriodType = 1;

  /** Выбранное количество последних (минут, часов, дней) */
  public selectedLastTime: number;

  /** Параметры дата-пикера в заголовке */
  public headerDatePickerOptions: IMyDpOptions = { showClearDateBtn: false };

  /** Параметры дата-пикера */
  public datePickerOptions: IMyDpOptions = { inline: true };

  /** Дата начала периода (для пикеров) */
  public dateFrom: IMyDateModel;

  /** Дата окончания периода (для пикеров) */
  public dateTo: IMyDateModel;

  /** Ограничение прошлого периода */
  public disableUntil: Date;

  /** Ограничение будущего периода */
  public disableSince: Date;

  public periodTypes: IListItem<number>[] = []

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для переводов
   */
  constructor(
    dialogService: DialogService,
    public translator: TranslateService
  ) {
    super(dialogService);

    this.periodTypes = [
      { id: 0, name: 'component.select-period.minutes' },
      { id: 1, name: 'component.select-period.hours' },
      { id: 2, name: 'component.select-period.days' }
    ]
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: ISelectPeriod) {
    this.dateFrom = { jsdate: data.from } as IMyDateModel;
    this.dateTo = { jsdate: data.to } as IMyDateModel;
    this.datePickerOptions.disableUntil = this.dateToDate(data.disableUntil);
    this.datePickerOptions.disableSince = this.dateToDate(data.disableSince);
    return super.fillData(data);
  }

  /**
   * Преобразуем дату
   * @param date Дата
   */
  private dateToDate(date: Date): IMyDate {
    return { year: date?.getFullYear() || 0, month: date?.getMonth() + 1 || 0, day: date?.getDate() || 0 }
  }

  /**
   * Установка периода на сегодняшний день
   */
  public today() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    this.updateMyDatepickerDates();
  }

  /**
   * Установка периода на вчерашний день
   */
  public yesterday() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.disableUntil, this.disableSince);
    this.updateMyDatepickerDates();
  }

  /**
   * Установка периода на последнюю неделю
   */
  public week() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.disableUntil, this.disableSince);
    this.updateMyDatepickerDates();
  }

  /**
   * Установка периода на последний месяц
   */
  public month() {
    const now = new Date();
    this.from = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1, 0, 0, 0, 0);
    this.to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.disableUntil, this.disableSince);
    this.updateMyDatepickerDates();
  }

  /**
   * Обработка при изменении количества последних (дней, месяцев, часов)
   */
  public onSelectedLastTimeChanged() {
    if (this.selectedLastTime && this.selectedLastTime > 0) {
      const now = new Date();
      let date = now.getDate();
      let hours = now.getHours();
      let minutes = now.getMinutes();

      switch (this.selectedLast) {
        case PeriodType.Minutes:
          minutes -= this.selectedLastTime;
          break;
        case PeriodType.Hours:
          hours -= this.selectedLastTime;
          break;
        case PeriodType.Days:
          date -= this.selectedLastTime;
          break;
      }
      this.from = new Date(
        now.getFullYear(), now.getMonth(), date, hours,
        minutes, now.getSeconds(), now.getMilliseconds());
      this.to = now;
      [this.from, this.to] = correctDisableDatepicker(this.from, this.to, this.disableUntil, this.disableSince);

      this.updateMyDatepickerDates();
    }
  }

  /**
   * Обновление дат для дата-пикеров
   */
  public updateMyDatepickerDates() {
    this.dateFrom = { jsdate: this.from } as IMyDateModel;
    this.dateTo = { jsdate: this.to } as IMyDateModel;
  }

  /**
   * Обновление даты начала периода из даты для дата-пикера
   */
  public updateFrom() {
    const oldFrom = this.from;
    this.from = new Date(
      this.dateFrom.jsdate.getFullYear(),
      this.dateFrom.jsdate.getMonth(),
      this.dateFrom.jsdate.getDate(),
      oldFrom.getHours(),
      oldFrom.getMinutes(),
      oldFrom.getSeconds(),
      oldFrom.getMilliseconds()
    );
  }

  /**
   * Обновление даты окончания периода из даты для дата-пикера
   */
  public updateTo() {
    const oldTo = this.to;
    this.to = new Date(
      this.dateTo.jsdate.getFullYear(),
      this.dateTo.jsdate.getMonth(),
      this.dateTo.jsdate.getDate(),
      oldTo.getHours(),
      oldTo.getMinutes(),
      oldTo.getSeconds(),
      oldTo.getMilliseconds()
    );
  }

  /**
   * Добавление часов для даты начала периода
   */
  public addHoursFrom() {
    let newHours = this.from.getHours() + 1;
    if (newHours >= 24) { newHours = 0; }

    this.setFromHours(newHours);
  }

  /**
   * Вычитание часов для даты начала периода
   */
  public subHoursFrom() {
    let newHours = this.from.getHours() - 1;
    if (newHours < 0) { newHours = 23; }

    this.setFromHours(newHours);
  }

  /**
   * Добавление часов для даты окончания периода
   */
  public addHoursTo() {
    let newHours = this.to.getHours() + 1;
    if (newHours >= 24) { newHours = 0; }

    this.setToHours(newHours);
  }

  /**
   * Вычитание часов для даты окончания периода
   */
  public subHoursTo() {
    let newHours = this.to.getHours() - 1;
    if (newHours < 0) { newHours = 23; }

    this.setToHours(newHours);
  }

  /**
   * Добавление минут для даты начала периода
   */
  public addMinutesFrom() {
    let newMinutes = this.from.getMinutes() + 1;
    if (newMinutes >= 60) { newMinutes = 0; }

    this.setFromMinutes(newMinutes);
  }

  /**
   * Вычитание минут для даты начала периода
   */
  public subMinutesFrom() {
    let newMinutes = this.from.getMinutes() - 1;
    if (newMinutes < 0) { newMinutes = 59; }

    this.setFromMinutes(newMinutes);
  }

  /**
   * Добавление минут для даты окончания периода
   */
  public addMinutesTo() {
    let newMinutes = this.to.getMinutes() + 1;
    if (newMinutes >= 60) { newMinutes = 0; }

    this.setToMinutes(newMinutes);
  }

  /**
   * Вычитание минут для даты окончания периода
   */
  public subMinutesTo() {
    let newMinutes = this.to.getMinutes() - 1;
    if (newMinutes < 0) { newMinutes = 59; }

    this.setToMinutes(newMinutes);
  }

  /**
   * Установка часов для даты начала периода
   * @param value Значение часов
   */
  public setFromHours(value: number) {
    const oldFrom = this.from;
    this.from = new Date(
      oldFrom.getFullYear(),
      oldFrom.getMonth(),
      oldFrom.getDate(),
      value,
      oldFrom.getMinutes(),
      oldFrom.getSeconds(),
      oldFrom.getMilliseconds()
    );
  }

  /**
   * Установка минут для даты начала периода
   * @param value Значение минут
   */
  public setFromMinutes(value: number) {
    const oldFrom = this.from;
    this.from = new Date(
      oldFrom.getFullYear(),
      oldFrom.getMonth(),
      oldFrom.getDate(),
      oldFrom.getHours(),
      value,
      oldFrom.getSeconds(),
      oldFrom.getMilliseconds()
    );
  }

  /**
   * Установка часов для даты окончания периода
   * @param value Значение часов
   */
  public setToHours(value: number) {
    const oldTo = this.to;
    this.to = new Date(
      oldTo.getFullYear(),
      oldTo.getMonth(),
      oldTo.getDate(),
      value,
      oldTo.getMinutes(),
      oldTo.getSeconds(),
      oldTo.getMilliseconds()
    );
  }

  /**
   * Установка минут для даты окончания периода
   * @param value Значение минут
   */
  public setToMinutes(value: number) {
    const oldTo = this.to;
    this.to = new Date(
      oldTo.getFullYear(),
      oldTo.getMonth(),
      oldTo.getDate(),
      oldTo.getHours(),
      value,
      oldTo.getSeconds(),
      oldTo.getMilliseconds()
    );
  }

  /** Получение часов даты начала периода */
  public get fromHours() { return this.from ? this.from.getHours() : 0; }

  /** Установка часов даты начала периода */
  public set fromHours(value: number) {
    if (value >= 0 && value <= 23) {
      this.setFromHours(value);
    }
  }

  /** Получение минут даты начала периода */
  public get fromMinutes() { return this.from ? this.from.getMinutes() : 0; }

  /** Установка минут даты начала периода */
  public set fromMinutes(value: number) {
    if (value >= 0 && value <= 59) {
      this.setFromMinutes(value);
    }
  }

  /** Получение часов даты окончания периода */
  public get toHours() { return this.to ? this.to.getHours() : 0; }

  /** Установка часов даты окончания периода */
  public set toHours(value: number) {
    if (value >= 0 && value <= 23) {
      this.setToHours(value);
    }
  }

  /** Получение минут даты окончания периода */
  public get toMinutes() { return this.to ? this.to.getMinutes() : 0; }

  /** Установка минут даты окончания периода */
  public set toMinutes(value: number) {
    if (value >= 0 && value <= 59) {
      this.setToMinutes(value);
    }
  }

  /** Применение изменений */
  public ok() {
    this.result = { from: this.from, to: this.to };
    this.close();
  }
}

/**
 * Типы периодов
 */
enum PeriodType {
  /** Минуты */
  Minutes,
  /** Часы */
  Hours,
  /** Дни */
  Days
}
