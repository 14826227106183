import { Pipe, PipeTransform } from '@angular/core';

/**
 * Поиск по объекту
 */
@Pipe({
  name: 'search',
  pure: false
})
export class SearchPipe implements PipeTransform {
  public transform(items: any[], search: string, ...keys: string[]): any {
    return items?.filter((item) => keys.some((key) => item[key]?.toLowerCase()?.includes(search?.toLowerCase() || ''))) || []
  }
}
