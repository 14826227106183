import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { IRight } from '../../../shared/rights/IRight';
import { RightEntityType } from '../../../shared/rights/RightEntityType';
import { RightType, UnitRightType } from '../../../shared/rights/RightType';
import { IUnitGroup } from '../../../shared/unit-groups/IUnitGroup';
import { IActUser } from '../../../shared/users';
import { DetailTab } from '../../classes/DetailTab';
import { IClientSubjectRight } from '../../classes/IClientSubjectRight';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { RightsService } from '../../services/rights.service';
import { UsersService } from '../../services/users.service';
import { localeSort } from '../../utils/sort';
import { SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Интерфейс компонента для отображения подробной информации по группе объектов мониторинга
 */
interface IUnitGroupComponent {
  /** Идентификатор группы */
  groupId?: string;

  /** Признак того, что выполняется копирование группы объектов */
  copy?: boolean;
}

/**
 * Компонент для отображения подробной информации по группе объектов мониторинга
 */
@Component({
  selector: 'unit-group',
  templateUrl: './unit-group.component.html',
  styleUrls: ['./unit-group.component.scss']
})
export class UnitGroupComponent extends DialogComponent<IUnitGroupComponent, boolean> implements IUnitGroupComponent {

  /** Идентификатор группы */
  public groupId?: string;

  /** Признак того, что выполняется копирование группы объектов */
  public copy?: boolean;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Текст сообщения об ошибке */
  public error: string;

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Признак режима добавления */
  public isAddRegime: boolean;

  /** Заголовок окна */
  public title: string;

  /** Данные группы объектов */
  public group: IUnitGroup = {} as IUnitGroup;

  /** Список доступных для выбора создателей */
  public creators: IActUser[] = [];

  /** Список доступных для выбора объектов мониторинга */
  public units: IListItem<string>[] = [];

  /** Список выбранных объектов мониторинга */
  public selectedUnits: IListItem<string>[] = [];

  /** Строка поиска по выбранным объектам мониторинга */
  public search: string;

  /** Права пользователей на группу объектов мониторинга */
  public userRights: IClientSubjectRight[] = [];

  /** Постфикс для копии */
  private copyPostfix = '';

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис работы с ДИУП
   * @param usersService Сервис работы с пользователями
   * @param rightsService Сервис работы с правами
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private usersService: UsersService,
    private rightsService: RightsService,
    private translator: TranslateService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    translator.get('ui.copy2')
      .subscribe((x) => this.copyPostfix = x);

    this.loadUnits();
  }

  /**
   * Получение признака наличия прав на управление правами доступа к группе
   */
  get isCanChangeAccess() {
    return this.isCan(RightType.CHANGE_ACCESS);
  }

  /**
   * Получение признака наличия прав на переименование группы
   */
  get isCanRename() {
    return this.isCan(RightType.RENAME);
  }

  /**
   * Получение признака наличия прав на изменение списка объектов группы
   */
  get isCanChangeUnits() {
    return this.isCan(RightType.CHANGE_GROUP_UNITS);
  }

  /**
   * Получение признака наличия прав на просмотр произвольных свойств группы
   */
  get isCanViewArbitraryFields() {
    return this.isCan(RightType.VIEW_ARBITRARY_FIELDS);
  }

  /**
   * Получение признака возможности сохранения группы объектов
   */
  get isCanSave() {
    return this.group.name && this.group.creatorId;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IUnitGroupComponent) {
    const action = data.groupId ? data.copy ? 'copy' : 'change' : 'add';
    this.title = 'component.unit-group.' + action + '-group-title';
    this.isAddRegime = action !== 'change';

    this.tabs[Tabs.Main] = new DetailTab('component.unit-group.main');
    this.tabs[Tabs.Access] = new DetailTab('component.unit-group.access');
    this.tabs[Tabs.Arbitrary] = new DetailTab('component.unit-group.arbitrary');
    this.selectedTab = this.tabs[Tabs.Main];
    this.tabs[Tabs.Access].hidden = !data.groupId || data.copy;

    if (data.groupId) {
      this.loadingService.wrap(this.crudService.get(data.groupId, CRUDEntityType.UNIT_GROUP), true)
        .subscribe((group) => this.onUnitGroupDataLoaded(group, data.copy));
    }

    if (!(!data.groupId || data.copy)) {
      return super.fillData(data);
    }

    this.loadingService.wrap(this.usersService.getActUsers(), true)
      .subscribe((users) => {
        this.creators = users;

        if (!this.group.id) {
          const currentUser = this.creators.find((user) => user.current);
          this.group.creatorId = currentUser.id;
          this.group.creator = currentUser.name;
          this.group.accountId = currentUser.accountId;
          this.group.account = currentUser.account;
        }
      });

    return super.fillData(data);
  }

  /**
   * Загрузка списка доступных для выбора объектов мониторинга
   */
  public loadUnits() {
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.units = units;
        this.selectedUnits = units.filter((unit) => this.group.units?.includes(unit.id));
      });
  }

  /**
   * Обработка изменения выбранного создателя группы
   */
  public onCreatorChange() {
    const creator = this.creators.find((user) => user.id === this.group.creatorId);
    this.group.creator = creator.name;
    this.group.accountId = creator.accountId;
    this.group.account = creator.account;
  }

  /**
   * Обработка загрузки данных группы объектов с сервера
   * @param group Группа объектов
   * @param copy Признак создания копии
   */
  public onUnitGroupDataLoaded(group: IUnitGroup, copy: boolean) {
    this.group = group;
    this.selectedUnits = this.units.filter((unit) => this.group.units?.includes(unit.id));

    if (copy) {
      this.group.id = null;
      this.group.name += this.copyPostfix;
      return;
    }

    this.creators.push({
      id: this.group.creatorId,
      name: this.group.creator,
      account: this.group.account,
      accountId: this.group.accountId
    });

    this.tabs[Tabs.Access].hidden = !this.isCanChangeAccess;
    this.tabs[Tabs.Arbitrary].hidden = !this.isCanViewArbitraryFields;
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType | UnitRightType) {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.group.rights & rightType);
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Выбор объектов в группу
   */
  public selectUnits() {
    if (!this.isCanChangeUnits) {
      return;
    }

    const data = {
      items: this.units,
      selected: this.selectedUnits,
      title: 'component.unit-group.select-object-title',
      withSearch: true,
      hideSelectAll: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.selectedUnits = result?.sort(localeSort));
  }

  /**
   * Удаление объекта из группы
   * @param unit Объект мониторинга
   */
  public deleteUnit(unit: IListItem<string>) {
    if (!this.isCanChangeUnits) {
      return;
    }

    const index = this.selectedUnits.indexOf(unit);
    if (index !== -1) {
      this.selectedUnits.splice(index, 1);
    }
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.group.units = this.selectedUnits.map((unit) => unit.id);
    const rights: IRight[] = this.userRights
      .filter((right) => right.value !== right.newValue)
      .map((right) => ({
        id: right.id,
        subjectId: right.subjectId,
        objectId: right.objectId,
        objectType: RightEntityType.UNIT_GROUP,
        value: right.newValue,
        // tslint:disable-next-line:no-bitwise
        combined: (right.combined | right.newValue)
      }));

    this.loadingService.wrap(this.crudService.addUpdate(this.group, CRUDEntityType.UNIT_GROUP), true)
      .pipe(flatMap((result) => rights.length ? this.rightsService.updateRights(rights) : result))
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Access,
  Arbitrary
}
