import { Component } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { IServerResponse } from '../../../shared/IServerResponse';
import { IRegInfo } from '../../../shared/register/IRegInfo';
import { ConfigService } from '../../services/config.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { RegisterService } from '../../services/register.service';

/**
 * Компонент для регистрации дилера в системе
 */
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  /** Загрузчик файлов */
  public uploader: FileUploader;

  /** Регистрационная информация */
  public regInfo: IRegInfo;

  /** Признак того, что данные отправлены */
  public isSended: boolean;

  /**
   * Конструктор
   * @param loadingService Сервис отображения процесса загрузки
   * @param modalService Сервис модальных окон
   * @param registerService Сервис регистрации дилеров
   * @param configService
   */
  constructor(
    private loadingService: LoadingService,
    private modalService: ModalService,
    private registerService: RegisterService,
    private configService: ConfigService
  ) {
    this.regInfo = { } as IRegInfo;
    this.uploader = new FileUploader({
      url: `${this.configService.url}/register/card`,
      autoUpload: false
    });
    this.uploader.onSuccessItem = this.onSuccessItem;
    this.uploader.onErrorItem = this.onErrorItem;
  }

  /**
   * Признак того, что данные можно отправить
   */
  public get isCanSend() {
    return this.regInfo.name && this.regInfo.name !== ''
      && this.regInfo.email && this.regInfo.email !== ''
      && this.regInfo.phone && this.regInfo.phone !== ''
      && this.regInfo.fio && this.regInfo.fio !== ''
      && this.uploader.getNotUploadedItems().length;
  }

  /**
   * Клик по кнопке отправки данных
   */
  public send() {
    this.loadingService.startLoading();
    this.uploader.uploadAll();
  }

  /**
   * Обработка при успешной загрузке файла
   * @param item Информация о файле
   * @param res Ответ сервера
   */
  private onSuccessItem = (item: FileItem, res: string) => {
    try {
      const result = JSON.parse(res) as IServerResponse;
      if (result.success) {
        this.regInfo.file = result.data;
        this.registerService.register(this.regInfo).subscribe(
          () => {
            this.loadingService.stopLoading();
            this.isSended = true;
          },
          (error) => {
            this.loadingService.stopLoading();
            this.modalService.showError(error);
          }
        );
      } else {
        this.loadingService.stopLoading();
        this.modalService.showError(result.data);
      }
    } catch (error) {
      this.loadingService.stopLoading();
      this.modalService.showError(error);
    }
  }

  /**
   * Обработка при ошибке загрузки файла
   * @param item Информация о файле
   * @param res Ответ сервера
   */
  private onErrorItem = (item: FileItem, res: string) => {
    this.loadingService.stopLoading();
    this.modalService.showError(res);
  }
}
