import { Component, Input } from '@angular/core';

/**
 * Компонент alert
 */
@Component({
  selector: 'bg-alert',
  templateUrl: './bg-alert.component.html',
  styleUrls: ['./bg-alert.component.scss']
})
export class BgAlertComponent {

  /** Текст алерта */
  @Input('text') public text: string

  /** Цвет алерта */
  @Input('variant') public variant: string

  @Input('closable') public closable: boolean = false

  @Input('class') public class: string

  /**
   * Закрываем алерт
   */
  public closeAlert(): void {
    this.text = ''
  }

  /**
   * Получаем наименования класса компонента
   */
  public get getVariantClass(): string {
    switch (this.variant) {
      case 'danger' || 'success' || 'info':
        return this.variant;
      default:
        return 'primary';
    }
  }

}
