import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subscription, timer } from 'rxjs';

import { IMessagesRequest } from '../../../shared/messages/IMessagesRequest';
import { SectionComponent } from '../../classes/SectionComponent';
import { ActivityService } from '../../services/activity.service';
import { ConfigService } from '../../services/config.service';
import { FileService } from '../../services/file.service';
import { HostConfigService } from '../../services/host-config.service';
import { MapService, Section } from '../../services/map.service';
import { IExpand, MonitoringService } from '../../services/monitoring.service';
import { NavigationPanelType } from '../../services/navigation.service';
import { OnlineNotificationsService } from '../../services/online-notifications.service';
import { RaceService } from '../../services/race.service';
import { IReportElement, ReportElementType, ReportsService } from '../../services/reports.service';
import { StoreService } from '../../services/store.service';
import { ChangelogComponent } from '../changelog/changelog.component';
import { ReportChartComponent } from '../report/chart/report.chart.component';

/**
 * Компонент, представляющий часть сайта, связанную непосредственно с мониторингом
 */
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent
  extends SectionComponent
  implements AfterViewChecked, OnInit, OnDestroy {

  /**
   * Ссылка на типы панели навигации
   */
  public nType = NavigationPanelType;

  /** Ширина развернутой панели навигации */
  public readonly navigationWidth = 170;

  /** Ширина свернутой панели навигации */
  public readonly navigationWidthSmall = 50;

  /** Ширина панели изменения размера */
  public readonly resizerWidth = 5;

  /** Высота нижней панели */
  public readonly bottomToolbarHeight = 25;

  /** Позиция левого тригера */
  private posLeftResizer;

  /** Позиция правого тригера */
  private posRightResizer;

  /** Признак необходимости обновления карты */
  private needMapUpdate: boolean = false;

  /** Компонент графика */
  @ViewChild(ReportChartComponent, {static: true}) public chart: ReportChartComponent;

  /** Текущая ширина рабочей панели */
  public workPanelWidth: number = 400;

  /** Текущая ширина панели с уведомлениями */
  public notificationsPanelWidth = 400;

  /** Признак отображения панели с уведомлениями */
  public showNotificationsPanel = false;

  /** Текущая высота карты */
  public mapPanelHeight: number = 400;

  /** Признак необходимости отображения панели с детальной информацией */
  public showDetailPanel = false;

  /** Признак необходимости отображения рабочей панели */
  public isWorkPanelVisible = true;

  /** Признак свернутой/развернутой панели */
  public isNavigationExpand: boolean;

  /** Отображаемая в данный момент таблица отчета */
  public reportTable: IReportElement;

  /** Отображаемый в данный момент график отчета */
  public reportChart: IReportElement;

  /** Подписка на выбор элемента отчета для отображения */
  public selectReportElementSubscription: Subscription;

  /** Подписка на изменение запроса на получение списка сообщений */
  public changeMessagesRequestSubscription: Subscription;

  /** Запрос на получение списка сообщений */
  public messagesRequest: IMessagesRequest;

  /** Наименование текущей страницы */
  public section = Section.Tracking;

  /** Подписка на завершение навигации */
  public navigationEndSubscription: Subscription;

  /** Признак обрыва соединения с сервером */
  public isLoseConnection = 0;

  /** Ссылка на перечисление */
  public layers = Section;

  /** Подписка на смену выбранного объекта мониторинга в панели онлайн-уведомлений  */
  private onlineNotificationsUnitChangedSubscription: Subscription;

  /** Подписка на событие потери связи с сервером */
  private loseConnectionSubscription: Subscription;

  /** Подписка на событие изменения видимости рабочей панели */
  private setWorkPanelVisibilitySubscription: Subscription;

  /** Подписка на событие изменения видимости рабочей панели */
  private setNavigationExpandSubscription: Subscription;

  /**
   * Конструктор
   * @param activityService Сервис учета активности пользователей
   * @param configService Сервис конфигурации
   * @param dialogService Сервис диалоговых окон
   * @param hostConfigService Сервис настроек хоста
   * @param onlineNotificationsService Сервис работы с уведомлениями
   * @param fileService Сервис для работы с файлами
   * @param el Дочерний элемент DOM текущего компонента
   * @param mapService Сервис для работы с картой
   * @param monitoringService Сервис мониторинга
   * @param raceService Сервис работы с рейсами
   * @param reportsService Сервис для работы с отчетами
   * @param router Маршрутизатор
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    activityService: ActivityService,
    public configService: ConfigService,
    public dialogService: DialogService,
    public hostConfigService: HostConfigService,
    public onlineNotificationsService: OnlineNotificationsService,
    public fileService: FileService,
    private el: ElementRef,
    private mapService: MapService,
    private monitoringService: MonitoringService,
    private raceService: RaceService,
    private reportsService: ReportsService,
    private router: Router,
    private store: StoreService
  ) {
    super(activityService);

    this.mapService.setLayersVisibility(this.section);

    this.selectReportElementSubscription = this.reportsService.selectElementSubject
      .subscribe(this.onSelectReportItem);
    this.changeMessagesRequestSubscription = this.monitoringService.changeMessagesRequestSubject
      .subscribe(this.onChangeMessagesRequest);
    this.onlineNotificationsUnitChangedSubscription = this.monitoringService.activateNotificationsPanelSubject
      .subscribe(this.onActivateNotificationsPanel);
    this.loseConnectionSubscription = this.monitoringService.loseConnectionSubject
      .subscribe(this.onLoseConnection);
    this.setWorkPanelVisibilitySubscription = this.monitoringService.setWorkPanelVisibilitySubject
      .subscribe(this.setWorkPanelVisibility);
    this.setNavigationExpandSubscription = this.monitoringService.setNavigationExpandSubject
      .subscribe(this.setNavigationExpand);

    this.navigationEndSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const regexp = /^\/monitoring\/(tracking|race|geozones|drivers|trailers|routes|reports|messages|analytics)\b.*$/i;
        const matchArray = e.urlAfterRedirects.match(regexp);
        if (matchArray && matchArray.length > 1) {
          this.section = matchArray[1] as Section;
        }

        if (this.section === Section.Analytics) {
          this.section = Section.Tracking;
        }

        this.mapService.setLayersVisibility(this.section);

        const detailPanelVisible = this.isShowReportTable || this.isShowReportChart || this.isShowMessages;
        this.setDetailPanelVisibility(detailPanelVisible);
      }
    });
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.configService.loadChangelog();
    const workPanelWidth = parseInt(localStorage.getItem('workPanelWidth'), 10)
    if (workPanelWidth) this.workPanelWidth = workPanelWidth;

    this.posLeftResizer = this.workPanelWidth + (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall);
    this.posRightResizer = this.notificationsPanelWidth;
    super.ngOnInit();
    this.monitoringService.start();
    this.raceService.showLoadedRaces();
    window.addEventListener('resize', this.onWindowResize);
  }

  /** Лимиты лицензий ТС */
  public get limits() {
    return this.store?.user?.limits
  }

  /**
   * Получение признака отображения таблицы отчета
   */
  public get isShowReportTable() {
    return this.section === Section.Reports && !!this.reportTable;
  }

  /**
   * Получение признака отображения графика отчета
   */
  public get isShowReportChart() {
    return this.section === Section.Reports && !!this.reportChart;
  }

  /**
   * Получение признака отображения сообщений
   */
  public get isShowMessages() {
    return this.section === Section.Messages && !!this.messagesRequest;
  }

  /**
   * Действия после проверки на изменения в представлении компонента
   */
  ngAfterViewChecked() {
    if (this.needMapUpdate) {
      this.updateSize();
      this.needMapUpdate = false;
    }
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    super.ngOnDestroy();

    this.monitoringService.stop();
    window.removeEventListener('resize', this.onWindowResize);
    this.selectReportElementSubscription.unsubscribe();
    this.navigationEndSubscription.unsubscribe();
    this.changeMessagesRequestSubscription.unsubscribe();
    this.onlineNotificationsUnitChangedSubscription.unsubscribe();
    this.loseConnectionSubscription.unsubscribe();
    this.setWorkPanelVisibilitySubscription.unsubscribe();
  }

  /**
   * Открываем changelog
   */
  public openChangelog = () => {
    return this.dialogService.addDialog(ChangelogComponent, {}, {closeByClickingOutside: true})
      .subscribe();
  }

  /**
   * Получение видимости слоя
   * @param layer Слой
   */
  public isLayerVisible(layer: Section) {
    return this.section === layer || this.mapService.isLayerVisible(layer);
  }

  /**
   * Переключение видимости слоя слежения
   * @param layer Слой
   */
  public toggleLayerVisibility(layer: Section) {
    if (this.section === layer) {
      return;
    }

    this.mapService.toggleLayersVisibility(this.section, layer);
  }

  /**
   * Переключение видимости панели с уведомлениями
   */
  public toggleNotificationsPanel() {
    this.showNotificationsPanel = !this.showNotificationsPanel;
    this.needMapUpdate = true;
    this.reportsService.chatReflowSubject.next();
  }

  /**
   * Получение текущего года
   */
  public currentYear() {
    return new Date().getFullYear();
  }

  /**
   * Изменение видимости рабочей панели
   * @param b Флаг видимости, true - отображать, иначе - скрывать
   */
  private setWorkPanelVisibility = (b: boolean) => {
    this.isWorkPanelVisible = b;
    this.needMapUpdate = true;
  }

  /**
   * Изменение размера навигационной панели
   */
  private setNavigationExpand = (b: IExpand) => {
    this.isNavigationExpand = b.isExpand;
    this.needMapUpdate = true;

    if (b.updatePosition) {
      const widthDiff = this.navigationWidth - this.navigationWidthSmall
      this.posLeftResizer = b.isExpand ? this.posLeftResizer + widthDiff : this.posLeftResizer - widthDiff;
    } else {
      this.posLeftResizer = this.workPanelWidth + (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall);
    }
  }

  /**
   * Завершение перетаскивания левой панели
   * @param e Аргумент события
   */
  public dragLeftPanelStop = (e) => {
    this.workPanelWidth += e.distance.x;
    this.needMapUpdate = true;
    this.reportsService.chatReflowSubject.next();
    localStorage.setItem('workPanelWidth', this.workPanelWidth.toString())
  };

  /**
   * Завершение перетаскивания правой панели
   * @param e
   */
  public dragRightPanelStop = (e) => {
    this.notificationsPanelWidth -= e.distance.x;
    this.needMapUpdate = true;
    this.reportsService.chatReflowSubject.next();
  };

  /**
   * Завершение перетаскивания правой панели
   * @param e
   */
  public dragVerticalPanelStop = (e) => {
    this.mapPanelHeight += e.distance.y;
    this.needMapUpdate = true;
    this.reportsService.chatReflowSubject.next();
  };

  /**
   * Получение ширины левой панели
   */
  public getLeftPanelWidth = () => {
    return {
      'width': this.workPanelWidth + 'px'
    }
  };

  /**
   * Получение позиции левого тригера перетаскивания
   */
  public getLeftResizerPosition = () => {
    return {
      'left': this.posLeftResizer + 'px'
    }
  };

  /**
   * Получение позиции центральной панели
   */
  public getCenterPanelPosition = () => {
    return {
      'left': this.isWorkPanelVisible
        ? this.workPanelWidth + (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) + this.resizerWidth + 'px'
        : (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) + 'px'
    }
  };

  /**
   * Получение позиции карты
   */
  public getMapPosition = () => {
    if (this.showNotificationsPanel) {
      return {
        'right': this.notificationsPanelWidth + this.resizerWidth + 'px'
      }
    } else {
      return {
        'right': '0px'
      }
    }
  };

  /**
   * Получение высоты карты
   */
  public getTop = () => {
    return {
      'height': this.showDetailPanel
        ? this.mapPanelHeight + 'px'
        : window.innerHeight - this.bottomToolbarHeight + 'px'
    }
  };

  /**
   * Получение позиции панели с детальной информацией
   */
  public getResizableBottom = () => {
    return {
      'top': this.mapPanelHeight + this.resizerWidth + 'px'
    }
  };

  /**
   * Получение позиции правого тригера
   */
  public getRightResizerPosition = () => {
    return {
      'right': this.posRightResizer + 'px'
    }
  };

  /**
   * Получение ширины правой панели
   */
  public getResizableRight = () => {
    return {
      'width': this.notificationsPanelWidth + 'px'
    }
  };

  /**
   * Изменение размера элементов
   */
  private updateSize() {
    this.mapService.resizeMap();
    if (this.chart) {
      this.reportsService.chartReflow();
    }
  }

  /**
   * Обработка изменения размера окна
   */
  private onWindowResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (this.workPanelWidth + (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) + this.resizerWidth * 2 > width) {
      this.workPanelWidth = width - (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) - this.resizerWidth * 2;
    }

    if (this.notificationsPanelWidth + this.resizerWidth * 2 + this.workPanelWidth + (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) > width) {
      this.notificationsPanelWidth = width - (this.isNavigationExpand ? this.navigationWidth : this.navigationWidthSmall) - this.resizerWidth * 2 - this.workPanelWidth;
    }

    if (this.mapPanelHeight + this.bottomToolbarHeight + this.resizerWidth > height) {
      this.mapPanelHeight = height - this.bottomToolbarHeight - this.resizerWidth;
    }

    this.updateSize();
  }

  /**
   * Обработка изменения запроса на получение списка сообщений
   * @param messagesRequest Запрос на получение списка сообщений
   */
  private onChangeMessagesRequest = (messagesRequest: IMessagesRequest) => {
    this.messagesRequest = messagesRequest;
    this.setDetailPanelVisibility(!!messagesRequest);
  }

  /**
   * Обработка изменения выбранного объекта мониторинга в панели онлайн-уведомлений
   */
  private onActivateNotificationsPanel = () => {
    if (!this.showNotificationsPanel) {
      if (this.notificationsPanelWidth < 150) {
        this.notificationsPanelWidth = 400;
      }
      this.toggleNotificationsPanel();
    }
  }

  /**
   * Обработка при выборе элемента отчета для отображения
   * @param item Элемент отчета
   */
  private onSelectReportItem = (item: IReportElement) => {
    if (!item) {
      this.reportTable = null;
      this.reportChart = null;
      this.setDetailPanelVisibility(false);

    } else if (item.type === ReportElementType.TABLE) {
      this.reportTable = item;
      this.reportChart = null;
      this.setDetailPanelVisibility(true);

    } else if (item.type === ReportElementType.CHART) {
      this.reportTable = null;
      this.reportChart = item;
      this.setDetailPanelVisibility(true);
    }
  }

  /**
   * Установка видимости панели с детальной информацией
   * @param visible признак видимости
   */
  private setDetailPanelVisibility(visible: boolean) {
    if (visible !== this.showDetailPanel) {
      this.showDetailPanel = visible;
      this.needMapUpdate = true;
    }
  }

  /**
   * Обработка потери связи с сервером
   */
  private onLoseConnection = () => {
    this.isLoseConnection++;
    timer(1100).subscribe(() => this.isLoseConnection--);
  }
}
