import { Component, ViewChild } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';

import {
  DocRequest,
  DocRequestType,
  DocResponse,
  DocType,
  ISearchDocItem,
  isGeneratedDoc,
  isInvoiceRequest
} from '../../../../../shared/crm/docs';
import { ITaskListItem } from '../../../../../shared/tasks/ITaskListItem';
import { IListItem } from '../../../../classes/IListItem';
import { DocsService } from '../../../../services/docs.service';
import { LoadingService } from '../../../../services/loading.service';
import { prepareDocForDisplay } from '../../../../utils/docs';
import { compareStrings } from '../../../../utils/sort';
import { DocsPrintPreviewComponent, IPreviewDoc } from '../print-preview/docs.print-preview.component';

/**
 * Компонент для поиска документов
 */
@Component({
  selector: 'app-docs-search',
  templateUrl: './docs.search.component.html',
  styleUrls: ['./docs.search.component.scss']
})
export class DocsSearchComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: ITaskListItem[];
  /**
   * Выбранный элемент
   */
  public selected: ITaskListItem;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;
  /**
   * Номер документа
   */
  public docNo: string;
  /**
   * Признак полного совпадения
   */
  public fullMatch = true;
  /**
   * Список найденных документов
   */
  public docs: ISearchDocItem[] = [];

  /**
   * Конструктор
   * @param docsService Сервис для работы с документами
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   */
  constructor(
    private docsService: DocsService,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Признак того, что поиск может быть произведен
   */
  public get isCanSearch() {
    return this.docNo && this.docNo !== '';
  }

  /**
   * Поиск
   */
  public search() {
    if (!this.isCanSearch) {
      return;
    }

    this.loadingService
      .wrap(this.docsService.search(this.docNo, this.fullMatch), true)
      .subscribe((docs) => {
        docs.forEach((doc) => doc.sum /= 100);

        this.docs = docs?.sort((a, b) => compareStrings(a.docNo, b.docNo));
      });
  }

  /**
   * Получение документа (и отображение превью)
   * @param item Документ
   */
  public get(item: ISearchDocItem) {
    const request: DocRequest = item.type === 'invoice'
      ? { type: DocRequestType.GET, accountId: item.accountId, invoiceId: item.id }
      : { type: DocRequestType.GET, accountId: item.accountId, reportId: item.id };

    this.loadingService
      .wrap(this.docRequest(request, item.type), true)
      .pipe(
        tap((doc) => {
          if (isGeneratedDoc(doc)) {
            prepareDocForDisplay(doc);
          }
        }),
        flatMap((doc) => {
          const data = { doc, type: item.type } as IPreviewDoc;
          return this.dialogService.addDialog(DocsPrintPreviewComponent, data);
        }))
      .subscribe();
  }

  /**
   * Получение запроса на получение документа
   * @param request Параметры запроса
   * @param type Тип документа
   */
  private docRequest(request: DocRequest, type: DocType): Observable<DocResponse> {
    return isInvoiceRequest(request, type)
      ? this.docsService.invoice(request)
      : this.docsService.waReport(request);
  }
}
