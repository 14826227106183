import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IBalanceNotificationSettings } from '../../../../shared/crm/clients/IBalanceNotificationSettings';
import { IClientBalanceNotificationSettings } from '../../../../shared/crm/clients/IClient';
import { IListItem } from '../../../classes/IListItem';
import { ClientService } from '../../../services/client.service';
import { LoadingService } from '../../../services/loading.service';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для настройки отправки уведомлений об окончании средств на счете
 * (по клиенту)
 */
@Component({
  selector: 'client-balance-notification-settings',
  templateUrl: './client-balance-notification-settings.component.html',
  styleUrls: ['./client-balance-notification-settings.component.scss']
})
export class ClientBalanceNotificationSettingsComponent extends DialogComponent<{}, boolean> {

  /** Настройки отправки уведомлений */
  public settings: IClientBalanceNotificationSettings<string>;

  /** Словарь имен доступных для выбора пользователей */
  public userNames: { [key: string]: string };

  /** Словарь имен доступных для выбора учетных записей */
  public accountNames: { [key: string]: string };

  /** Список доступных для выбора пользователей */
  public users: IListItem<string>[];

  /** Список доступных для выбора учетных записей */
  public accounts: IListItem<string>[];

  /** Признак наличия персональных настроек для отдельных учетных записей */
  public personalSettings: boolean;

  /** Выбранная в данный момент персональная настройка по учетной записи */
  public selectedAccount: IBalanceNotificationSettings<string>;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param clientService Сервис для работы с текущим клиентом
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private clientService: ClientService
  ) {
    super(dialogService);

    this.accountNames = {};
    this.userNames = {};
    this.settings = {
      general: this.defaultSettings(),
      accounts: null
    };

    this.loadingService
      .wrap(this.clientService.getBalanceNotificationSettings(), true)
      .pipe(filter((settings) => !!settings))
      .subscribe((settings) => {
        for (const accSettings of settings.accounts) {
          accSettings.threshold /= 100;
        }

        if (!settings.general) {
          settings.general = this.defaultSettings();
        } else {
          settings.general.threshold /= 100;
        }

        this.settings = settings;

        if (this.settings.accounts && this.settings.accounts.length) {
          this.personalSettings = true;
        }
      });

    this.loadingService.wrap(this.clientService.getAccounts(), true)
      .subscribe((accounts) => {
        this.accounts = accounts?.sort(localeSort);
        for (const account of accounts) {
          this.accountNames[account.id] = account.name;
        }
      });

    this.loadingService.wrap(this.clientService.getUsers(), true)
      .subscribe((users) => {
        this.users = users?.sort(localeSort);
        for (const user of this.users) {
          this.userNames[user.id] = user.name;
        }
      });
  }

  /**
   * Выбор учетных записей из списка
   */
  public selectAccounts() {
    const data = {
      items: this.accounts,
      selected: this.accounts.filter((account) => this.settings.accounts.some((s) => s.accountId === account.id)),
      title: 'component.client.client-balance-notification-settings.select-account-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const tmpAccounts: IBalanceNotificationSettings<string>[] = result
          ?.sort(localeSort)
          ?.map((a) => this.defaultSettings(a.id));

        for (let account of tmpAccounts) {
          const oldAccount = this.settings.accounts.find((a) => a.accountId === account.accountId);
          if (oldAccount) {
            account = oldAccount;
          }
        }

        this.settings.accounts = tmpAccounts;

        if (this.selectedAccount) {
          this.selectedAccount = this.settings.accounts.find((a) => a.accountId === this.selectedAccount.accountId);
        }
      });
  }

  /**
   * Удаление настроек по учетной записи из списка
   * @param account Настройки по учетной записи
   */
  public removeAccount(account: IBalanceNotificationSettings<string>) {
    const index = this.settings.accounts.indexOf(account);
    if (index !== -1) {
      this.settings.accounts.splice(index, 1);
    }

    if (this.selectedAccount === account) {
      this.selectedAccount = null;
    }
  }

  /**
   * Переключение признака наличия персональных настроек по учетным записям
   */
  public personalSettingsChange() {
    if (this.personalSettings && !this.settings.accounts) {
      this.settings.accounts = [];
    } else if (!this.personalSettings) {
      this.settings.accounts = null;
      this.selectedAccount = null;
    }
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    const settings: IClientBalanceNotificationSettings<string> = {
      accounts: []
    };

    if (this.settings.general) {
      settings.general = {
        ...this.settings.general,
        threshold: Math.round(this.settings.general.threshold * 100)
      };
    }

    if (this.settings.accounts) {
      settings.accounts = this.settings.accounts.map((accSettings) => ({
        ...accSettings,
        threshold: Math.round(accSettings.threshold * 100)
      }));
    }

    this.loadingService.wrap(this.clientService.updateBalanceNotificationSettings(settings), true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Получение объекта для настроек по умолчанию
   * @param accountId Идентификатор учетной записи
   */
  private defaultSettings(accountId?: string) {
    const result: IBalanceNotificationSettings<string> = {
      threshold: 100,
      emails: null,
      users: null
    };

    if (accountId) {
      result.accountId = accountId;
    }

    return result;
  }
}
