import { IAccountGeneric } from './IAccountGeneric';

/**
 * Тип аккаунтов
 */
export enum AccountType {
  // Пустой
  BLANK,
  // Мусорщики
  SCAVENGER,
  // Физ. лица
  PHIZIC,
  // Фины
  GLOBAL_POSITION,
  // Вараксино
  VRS
}

/**
 * Учетная запись
 */
export interface IAccount extends IAccountGeneric<string, number> {
  id: string;
  /** Наименование создателя */
  creator: string;
  /** Наименование родительской учетной записи */
  parent: string;
  /** Наименование тарифа */
  tariff: string;
  /** Права доступа на учетную запись */
  rights: number;
}

/**
 * Account map keys
 */
export interface IAccountMapKeys {
  /** Google map key */
  googleKey: string;
  /** Yandex map key */
  yandexKey: string;
}
