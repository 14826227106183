/**
 * Тип "мусорной" зоны
 */
export enum GarbageZoneType {
  /** База */
  BASE = 1,
  /** Место накопления ТКО */
  CONTAINERS,
  /** Место выгрузки ТКО */
  LANDFILL
}
