import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { DialogService as primeDialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';

import { TimeSpan } from '../../../shared/classes/TimeSpan';
import { FastPeriodType, getFastPeriod } from '../../../shared/FastPeriodType';
import { ISortInfo } from '../../../shared/ISortInfo';
import { ITrackingItemsVisibility } from '../../../shared/tracking/ITrackingSettings';
import { IClientGeozone } from '../../classes/IClientGeozone';
import { IListItem } from '../../classes/IListItem';
import { TrackingGroup } from '../../classes/TrackingGroup';
import { TrackingUnit } from '../../classes/TrackingUnit';
import { BgPopoverService } from '../../modules/bg-popover';
import { IPopoverPosition } from '../../modules/bg-popover/IPopoverPosition';
import { CommandsService } from '../../services/commands.service';
import { CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { getTrafficLightTypeColor } from '../../services/online-notifications.service';
import { IRaceRequest, IRaceUnit, RaceService } from '../../services/race.service';
import { ICreateReportRequest, ReportsService } from '../../services/reports.service';
import { StoreService } from '../../services/store.service';
import { TrackingService } from '../../services/tracking.service';
import { UnitGroupsService } from '../../services/unit-groups.service';
import { getScreenCordinates } from '../../utils/coords';
import { intervalToString } from '../../utils/intervals';
import { compare, localeSort } from '../../utils/sort';
import { GeozonesDetailComponent } from '../geozones/detail/geozones.detail.component';
import { SelectItemsComponent } from '../select-items/select-items.component';
import { SelectUnitsComponent } from '../select-units/select-units.component';
import { UnitGroupComponent } from '../unit-group/unit-group.component';
import { GeozoneTrackerComponent } from '../unit/geozone-tracker/geozone-tracker.component';
import { UnitComponent } from '../unit/unit.component';

/**
 * Компонент слежения
 */
@Component({
  selector: 'tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [primeDialogService]
})
export class TrackingComponent implements OnInit, OnDestroy {
  /** Строка поиска */
  public search: string;
  /** Признак отображения строки поиска */
  public showSearch: boolean = false;
  /** Сортировка */
  public sort: ISortInfo;
  /** Элементы контекстного меню */
  public contextMenuItems: MenuItem[];
  /** Ссылка на диалог */
  private ref: DynamicDialogRef;
  /** Список */
  public dataSource: any = [];
  /** Подписка на изменение списка */
  private trackingUnitFillSubscription: Subscription;
  /** Подписка на прокрутку списка к элементу по индексу */
  private trackingScrollToIndexSubscription: Subscription;
  /** виртуал скрол */
  @ViewChild('virtualScrollViewport', { static: false }) private virtualScrollViewport: CdkVirtualScrollViewport;

  /**
   * Конструктор
   * @param store Сервис для хранения данных мониторинга
   * @param monitoringService Сервис мониторинга
   * @param dialogService Сервис диалоговых окон
   * @param popoverService Сервис всплывающих окон
   * @param router Роутер
   * @param modalService Сервис модальных окон
   * @param raceService Сервис работы с рейсами
   * @param reportsService Сервис работы с отчетами
   * @param crudService Сервис работы с ДИУП
   * @param loadingService Сервис для отображения процесса загрузки
   * @param trackingService Сервис для работы со слежением
   * @param unitGroupService
   * @param translator Сервис для перевода
   * @param commandsService Сервис для работы с командами
   * @param dynamicDialogService
   */
  constructor(
    public store: StoreService,
    public monitoringService: MonitoringService,
    private dialogService: DialogService,
    private popoverService: BgPopoverService,
    private router: Router,
    private modalService: ModalService,
    private raceService: RaceService,
    private reportsService: ReportsService,
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private trackingService: TrackingService,
    private unitGroupService: UnitGroupsService,
    private translator: TranslateService,
    private commandsService: CommandsService,
    private dynamicDialogService: primeDialogService
  ) {
    this.sort = {field: 'name', isDescending: false};
  }

  ngOnInit() {
    this.fillDataSource();
    this.trackingUnitFillSubscription = this.monitoringService.trackingUnitFillSubject.subscribe(this.fillDataSource);
    this.trackingScrollToIndexSubscription = this.trackingService.trackingScrollToIndexSubject.subscribe(this.scrollToIndex);
  }

  /**
   * Обработки после закрытия компонента
   */
  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  /**
   * Прокрутка к индексу
   * @param index индекс элемента
   */
  private scrollToIndex = (index: number) => {
    this.virtualScrollViewport.scrollToIndex(index, 'smooth')
  }

  /**
   * Прокрутка до элемента по индексу
   * @param e
   */
  public scrolledIndexChange(e) {
    console.log(e)
  }

  /**
   * Делаем плоский список из того что есть
   */
  public fillDataSource = (): void => {
    const groups = this.store.groups;

    const dataSource = [];

    if (!groups.length) {
      const units = [ ...this.store.units ]
      for (const unit of units?.sort((a, b) => compare(a[this.sort.field], b[this.sort.field], this.sort.isDescending))) {
        if (this.search && !unit?.name?.includes(this.search)) continue
        unit.isGroup = false
        dataSource.push(unit)
      }
      this.dataSource = dataSource;
      return
    }

    for (const group of groups) {
      group.isGroup = true
      dataSource.push(group)

      if (!group.showUnits) {
        continue
      }

      const units = [ ...group.trackingUnits ]
      for (const unit of units?.sort((a, b) => compare(a[this.sort.field], b[this.sort.field], this.sort.isDescending))) {
        if (this.search && !unit?.name?.includes(this.search)) continue
        unit.isGroup = false
        dataSource.push(unit)
      }
    }

    this.dataSource = dataSource;
  }

  /** Получение настроек слежения для списка */
  public get settings(): ITrackingItemsVisibility {
    return this.store.trackingSettings
      ? this.store.trackingSettings
      : {
        actual: true, connection: true, del: true, event: true, eye: true,
        move: true, properties: true, race: true, sensor: true, report: true, trackGeozone: true, commands: true
      };
  }

  /**
   * Получение признака всех выбранных объектов
   */
  get allChecked() {
    return this.store.units.every((unit) => unit.checked || false);
  }

  /**
   * Получение признака необходимости отображения времени последнего сообщения
   */
  public get isShowLastMessage() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.time &&
      this.store.user.settings.unitDetail.time.tracking;
  }

  /**
   * Получение признака необходимости отображения адреса
   */
  public get isShowAddress() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.address &&
      this.store.user.settings.unitDetail.address.tracking;
  }

  /**
   * Получение признака необходимости отображения строки со счетчиками
   */
  public get isShowCountersRow() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      ((this.store.user.settings.unitDetail.speed &&
        this.store.user.settings.unitDetail.speed.tracking) ||
        (this.store.user.settings.unitDetail.counters &&
          this.store.user.settings.unitDetail.counters.tracking));
  }

  /**
   * Получение признака необходимости отображения скорости
   */
  public get isShowSpeed() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.speed &&
      this.store.user.settings.unitDetail.speed.tracking;
  }

  /**
   * Получение признака необходимости отображения счетчиков
   */
  public get isShowCounters() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.counters &&
      this.store.user.settings.unitDetail.counters.tracking;
  }

  /**
   * Получение признака необходимости отображения геозон
   */
  public get isShowGeozones() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.geozones &&
      this.store.user.settings.unitDetail.geozones.tracking;
  }

  /**
   * Получение признака необходимости отображения водителей
   */
  public get isShowDrivers() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.drivers &&
      this.store.user.settings.unitDetail.drivers.tracking;
  }

  /**
   * Получение признака необходимости отображения прицепов
   */
  public get isShowTrailers() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.trailers &&
      this.store.user.settings.unitDetail.trailers.tracking;
  }

  /**
   * Получение стиля для строки с ТС в списке
   */
  public getRowStyle(unit: TrackingUnit) {
    if (unit?.trafficLight) {
      return {'background-color': getTrafficLightTypeColor(unit.trafficLight)};
    }
  }

  /**
   * Обработка клика по кнопке отображения свойств объекта/группы
   * @param item Элемент слежения (объект или группа)
   */
  public showProperties(item: TrackingUnit | TrackingGroup) {
    if (item instanceof TrackingUnit) {
      const uData = {unitId: item.id};
      this.dialogService.addDialog(UnitComponent, uData)
        .pipe(filter((result) => !!result))
        .subscribe(() => this.monitoringService.restartTracking());

      return;
    }

    const gData = {groupId: item.id};
    this.dialogService.addDialog(UnitGroupComponent, gData)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.restartTracking());
  }

  /**
   * Обработка клика по кнопке удаления объекта/группы из списка слежения
   * @param item Элемент слежения (объект или группа)
   */
  public delFromTracking(item: TrackingUnit | TrackingGroup) {
    if (!(item instanceof TrackingUnit)) {
      this.loadingService.wrap(this.trackingService.deleteGroup(this.store.sessionId, item.id), true)
        .subscribe(() => this.monitoringService.restartTracking());
      return;
    }

    this.loadingService.wrap(this.trackingService.deleteUnit(this.store.sessionId, item.id), true)
      .subscribe(() => {
        this.monitoringService.hideUnitsSubject.next([item]);

        if (item.geozones) {
          this.monitoringService.deleteUnitFromGeozones(item);
        }

        this.monitoringService.deleteUnitFromGroups(item);
        this.monitoringService.deleteUnitFromUnits(item);
      });
  }

  /**
   * Обработка клика по кнопке отображения окна слежения за геозонами
   * @param unit Элемент слежения (объект)
   */
  public showGeozoneTracker(unit: TrackingUnit) {
    this.ref = this.dynamicDialogService.open(GeozoneTrackerComponent, {
      header: this.translator.instant('component.tracking.geo-tracker'),
      data: unit,
      width: '70%'
    });
  }

  /**
   * Обработка клика по кнопке отображения окна с командами для тс
   * @param event Событие
   * @param unit Элемент слежения (объект)
   */
  public showUnitCommands(event: MouseEvent, unit: TrackingUnit) {
    event.stopPropagation();
    this.commandsService.oidSubject.next(unit.id);
  }

  /**
   * Клик по кнопке выбора объектов для слежения
   */
  public selectUnits() {
    const data = {sessionId: this.store.sessionId};
    this.dialogService.addDialog(SelectUnitsComponent, data)
      .subscribe(() => this.monitoringService.restartTracking());
  }

  /**
   * Клик по кнопке выбора групп объектов для слежения
   */
  public selectGroups() {
    this.unitGroupService.getList()
      .pipe(
        flatMap((unitGroups) => {
          const items: IListItem<string>[] = unitGroups?.sort(localeSort);
          const data = {
            items,
            selected: this.store?.groups?.filter((x) => x.id),
            title: 'component.tracking.item-selector-title',
            withSearch: true,
            isDraggable: true,
            hideCancelButton: true
          };
          return this.dialogService.addDialog(SelectItemsComponent, data);
        }),
        filter((result) => !!result),
        map((result) => result.map((v) => v.id)),
        flatMap((result) => this.trackingService.updateGroups(this.store.sessionId, result)))
      .subscribe(() => this.monitoringService.restartTracking());
  }

  /**
   * Изменение видимости панели слежения
   */
  public toggleSearchPanel() {
    this.showSearch = !this.showSearch;
    this.search = null;
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = {field, isDescending: false};
    }
    this.fillDataSource()
  }

  /**
   * Изменение выбора всех объектов
   */
  public toggleAllChecked() {
    const isAllChecked = this.allChecked;
    this.monitoringService.setAllUnitsChecked(!isAllChecked);
  }

  /**
   * Переключение признака видимости объекта
   * @param unit Объект мониторинга
   */
  public toggleChecked(unit: TrackingUnit) {
    this.monitoringService.toggleUnitChecked(unit);
  }

  /**
   * Переключение признака видимости группы объектов
   * @param group Группа объектов мониторинга
   */
  public toggleGroupChecked(group: TrackingGroup) {
    const checked = !group.checked;
    group.trackingUnits
      .filter((unit) => unit.checked !== checked)
      .forEach((unit) => this.monitoringService.toggleUnitChecked(unit));
  }

  /**
   * Переключение видимости ТС в группе
   * @param group Группа объектов мониторинга
   */
  public toggleShowUnits(group: TrackingGroup) {
    group.showUnits = !group.showUnits
    const hideUnitsGroup: string[] = this.store.groups?.filter((g) => !g.showUnits)?.map((g) => g.id)
    localStorage.setItem('hideUnitGroup', JSON.stringify(hideUnitsGroup))
    this.fillDataSource()
  }

  /**
   * Переключение признака слежения за объектом
   * @param unit Объект мониторинга
   */
  public toggleEye(unit: TrackingUnit) {
    this.monitoringService.toggleUnitEye(unit);
  }

  /**
   * Переключение признака слежения за группой объектов
   * @param group Группа объектов мониторинга
   */
  public toggleGroupEye(group: TrackingGroup) {
    const eye = !group.eye;
    group.trackingUnits
      .filter((unit) => unit.eye !== eye)
      .forEach((unit) => this.monitoringService.toggleUnitEye(unit));
  }

  /**
   * Переключение признака отображения детальной информации по объекту
   * @param unit Объект мониторинга
   */
  public toggleDetail(unit: TrackingUnit) {
    this.monitoringService.toggleUnitDetail(unit);
  }

  /**
   * Получение признака необходимости отображения значений датчиков
   * @param unit Объект слежения
   */
  public isShowSensors(unit: TrackingUnit) {
    return this.store?.user?.settings?.unitDetail?.sensors?.tracking && unit.sensors?.length;
  }

  /**
   * Получение признака необходимости отображения параметров
   * @param unit Объект слежения
   */
  public isShowParams(unit: TrackingUnit) {
    return this.store?.user?.settings?.unitDetail?.params?.tracking && unit.params;
  }

  /**
   * Получение признака необходимости отображения ТО
   * @param unit Объект слежения
   */
  public isShowTo(unit: TrackingUnit) {
    return this.store?.user?.settings?.unitDetail?.to?.tracking && unit?.toInfos?.length;
  }

  /**
   * Получение списка наименований всех параметров объекта слежения
   */
  public getParamKeys(unit: TrackingUnit) {
    return Object.keys(unit.params);
  }

  /**
   * Получение css класса для отображения актуальности данных от объекта
   * @param unit Объект мониторинга
   */
  public getActualClass(unit: TrackingUnit) {
    if (!unit.position.t) {
      return 'grey';
    }

    const now = new Date().getTime();
    const dif = (now - unit.position.t) / 60000;
    switch (true) {
      case dif <= 5:
        return 'green';

      case (dif <= 60):
        return 'yellow';

      case (dif <= 1440):
        return 'orange';

      default:
        return 'red';
    }
  }

  /**
   * Получение css класса для отображения состояния подключения объекта
   * @param unit Объект мониторинга
   */
  public getConnectionClass(unit: TrackingUnit) {
    if (!unit.position.m) {
      return 'grey';
    }

    const now = new Date().getTime();
    const dif = (now - unit.position.m) / 60000;
    const threshold = this.store?.trackingSettings?.connectionThreshold ?? 15;

    return dif <= threshold ? 'green' : 'red';
  }

  /**
   * Получение количества времени, прошедшего с момента получения последних данных от объекта
   * @param unit Объект мониторинга
   */
  public getActualTitle(unit: TrackingUnit) {
    if (!unit.position.t) {
      return this.translator.instant('ui.never');
    }

    const timeSpan = TimeSpan.fromPeriod(new Date(unit.position.t), new Date());
    return intervalToString(timeSpan);
  }

  /**
   * Получение количества времени, прошедшего с момента последней отправки терминалом данных
   * @param unit Объект мониторинга
   */
  public getConnectionTitle(unit: TrackingUnit) {
    if (!unit.position.m) {
      return this.translator.instant('ui.never');
    }

    const timeSpan = TimeSpan.fromPeriod(new Date(unit.position.m), new Date());
    return intervalToString(timeSpan);
  }

  /**
   * Отображение подробной информации по геозоне
   * @param event Объект события
   * @param geozone Геозона
   */
  public showGeozoneDetail(event: MouseEvent, geozone: IClientGeozone) {
    const target = event.target as HTMLElement;
    const position: IPopoverPosition = getScreenCordinates(target);
    if (position.y > window.innerHeight / 2) {
      position.y += target.offsetHeight;
    }
    position.x += target.offsetWidth + GeozoneDetailPadding;
    this.popoverService.addPopover(GeozonesDetailComponent, position, {geozone});
  }

  /**
   * Скрытие подробной информации по геозоне
   */
  public hideGeozoneDetail() {
    this.popoverService.removePopover();
  }

  /**
   * Формирование быстрого рейса
   * @param unit Объект мониторинга
   */
  public fastRace(unit: TrackingUnit) {
    const raceUnit: IRaceUnit = {
      id: unit.id,
      name: unit.name,
      icon: unit.icon,
      rotate: unit.rotate,
      color: unit.color
    };

    const periodType = this.store.trackingSettings.fastRacePeriod || FastPeriodType.YESTERDAY;
    const period = getFastPeriod(periodType, this.raceService.from.getTime(), this.raceService.to.getTime());

    const params: IRaceRequest = {
      uid: unit.id,
      f: period.f,
      t: period.t
    };

    this.loadingService.wrap(this.raceService.loadRaceBase(raceUnit, params), true)
      .subscribe(() => this.router.navigate(['monitoring', 'race']).then());
  }

  /**
   * Формирование быстрого отчета
   * @param item Объект/группа мониторинга (если не указан, то отчет по всем выделенным объектам)
   */
  public fastReport(item: TrackingUnit | TrackingGroup) {
    if (!this.store.trackingSettings) {
      return;
    }

    const templateId = item && !(item instanceof TrackingGroup)
      ? this.store.trackingSettings.fastUnitReportTemplateId
      : this.store.trackingSettings.fastGroupReportTemplateId;

    if (!templateId) {
      return;
    }

    const periodType = item && !(item instanceof TrackingGroup)
      ? this.store.trackingSettings.fastUnitReportPeriod
      : this.store.trackingSettings.fastGroupReportPeriod;

    if (!periodType) {
      return;
    }

    const period = getFastPeriod(
      periodType,
      this.reportsService.reportRequest.begin,
      this.reportsService.reportRequest.end);

    let oid: string | string[];
    if (item) {
      if (item instanceof TrackingGroup) {
        oid = item.trackingUnits.filter((u) => u.checked).map((u) => u.id);

      } else {
        oid = item.id;
      }

    } else {
      oid = this.store.units.filter((u) => u.checked).map((u) => u.id);
    }

    const request: ICreateReportRequest = {
      begin: period.f,
      end: period.t,
      template: templateId,
      objects: [oid],
      name: item.name
    };

    this.loadingService.withLoading(
      this.reportsService.buildReport(request),
      () => this.router.navigate(['monitoring', 'reports']));
  }

  /**
   * Добавление события
   * @param unit Объект слежения, по которому необходимо добавить событие
   */
  public addEvent(unit: TrackingUnit) {
    this.store.addEventUnitId = unit.id;
  }

  /**
   * Добавление события нажатия на объект мониторинга
   * @param unit Объект мониторинга
   */
  public onUnitClick(unit: TrackingUnit) {
    this.monitoringService.clickOnUnitSubject.next(unit);
  }

  /**
   * Вызов контекстного меню на объекте мониторинга
   * @param cm объект контекстного меню
   * @param event событие
   * @param unit объект мониторинга
   */
  public unitContextMenu(cm: ContextMenu, event: MouseEvent, unit: TrackingUnit) {
    this.contextMenuItems = [
      {
        label: this.translator.instant('component.tracking.add-event'),
        icon: 'fa fa-flag',
        visible: this.store.trackingSettings.event,
        command: () => this.addEvent(unit)
      },
      {
        label: this.translator.instant('component.tracking.commands'),
        icon: 'fa fa-terminal',
        visible: this.store.trackingSettings.commands,
        command: () => this.showUnitCommands(event, unit)
      },
      {
        label: this.translator.instant('component.tracking.fast-report'),
        icon: 'fa fa-file-text-o',
        visible: this.store.trackingSettings.report,
        command: () => this.fastReport(unit)
      },
      {
        label: this.translator.instant('component.tracking.fast-race'),
        icon: 'fa fa-road',
        visible: this.store.trackingSettings.race,
        command: () => this.fastRace(unit)
      },
      {
        label: unit.eye ?
          this.translator.instant('component.tracking.eye-on') :
          this.translator.instant('component.tracking.eye-off'),
        icon: unit.eye ? 'fa fa-eye' : 'fa fa-eye-slash',
        visible: this.store.trackingSettings.eye,
        command: () => this.toggleEye(unit)
      },
      {
        label: this.translator.instant('component.tracking.show-geo-tracker'),
        icon: 'fa fa-globe',
        visible: this.store.trackingSettings.trackGeozone,
        command: () => this.showGeozoneTracker(unit)
      },
      {
        label: this.translator.instant('component.tracking.props'),
        icon: 'fa fa-cog',
        visible: this.store.trackingSettings.properties,
        command: () => this.showProperties(unit)
      },
      {
        separator: true,
        visible: this.store.trackingSettings.del
      },
      {
        label: this.translator.instant('component.tracking.remove-track'),
        icon: 'fa fa-trash-o',
        visible: this.store.trackingSettings.del,
        command: () => this.delFromTracking(unit)
      }
    ]
    cm.show(event);
    event.stopPropagation();
  }

  /**
   * Вызов контекстного меню на группе объектов мониторинга
   * @param cm
   * @param event
   * @param group
   */
  groupContextMenu(cm: ContextMenu, event: MouseEvent, group: TrackingGroup) {
    this.contextMenuItems = [
      {
        label: this.translator.instant('component.tracking.fast-report'),
        icon: 'fa fa-file-text-o',
        visible: this.store.trackingSettings.report,
        command: () => this.fastReport(group)
      },
      {
        label: group.eye ?
          this.translator.instant('component.tracking.group-eye-on') :
          this.translator.instant('component.tracking.group-eye-off'),
        icon: group.eye ? 'fa fa-eye' : 'fa fa-eye-slash',
        visible: this.store.trackingSettings.eye,
        command: () => this.toggleGroupEye(group)
      },
      {
        label: this.translator.instant('component.tracking.props'),
        icon: 'fa fa-cog',
        visible: this.store.trackingSettings.properties,
        command: () => this.showProperties(group)
      },
      {
        separator: true,
        visible: this.store.trackingSettings.del
      },
      {
        label: this.translator.instant('component.tracking.remove-track'),
        icon: 'fa fa-trash-o',
        visible: this.store.trackingSettings.del,
        command: () => this.delFromTracking(group)
      }
    ];

    cm.show(event);
    event.stopPropagation();
  }

  /**
   * Вызов контекстного меню
   * @param cm
   * @param event
   */
  toolsContextMenu(cm: ContextMenu, event: MouseEvent) {
    this.contextMenuItems = [
      {
        label: this.translator.instant('component.tracking.fast-report-all'),
        icon: 'fa fa-file-text-o',
        visible: this.settings.report,
        command: () => this.fastReport(null)
      },
      {
        label: this.translator.instant('component.tracking.sort-by-connected'),
        icon: 'fa fa-plug',
        visible: this.settings.connection,
        command: () => this.sortBy('connected')
      },
      {
        label: this.translator.instant('component.tracking.sort-by-time'),
        icon: 'fa fa-database',
        visible: this.settings.actual,
        command: () => this.sortBy('time')
      },
      {
        label: this.translator.instant('component.tracking.sort-by-eye'),
        icon: 'fa fa-eye',
        visible: this.settings.eye,
        command: () => this.sortBy('eye')
      },
      {
        label: this.translator.instant('component.tracking.sort-by-move'),
        icon: 'fa fa-location-arrow',
        visible: this.settings.move,
        command: () => this.sortBy('moveState')
      }
    ];

    cm.show(event);
    event.stopPropagation();
  }
}

/** Отступ детальной информации по геозоне */
const GeozoneDetailPadding = 3;
