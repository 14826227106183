import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IListItem } from '../../classes/IListItem';
import { ISelectItemsComponent, ModalDialogService } from '../../services/modal-dialog.service';

/**
 * Компонент выбора каких-либо записей
 * @template D данные на вход компонента
 * @template R данные на выходе компонента
 */
@Component({
  selector: 'select-items-modal',
  styleUrls: ['./select-items-model.component.scss'],
  templateUrl: './select-items-modal.component.html'
})
export class SelectItemsModalComponent implements OnDestroy {
  /**
   * Данные для компонента
   */
  public selectItemsData: ISelectItemsComponent;

  /**
   * Признак открытого окна
   */
  public show: boolean;

  /**
   * Значение введеное в поисковую строку
   */
  public search: string;

  /**
   * Элементы списка
   */
  public items: IListItem<string>[];

  /**
   * Элементы списка
   */
  public selectedItems: IListItem<string>[];

  /**
   * Подписка
   */
  private openSubscribe: Subscription;

  /**
   * Конструктор
   */
  constructor(
    private modalDialogService: ModalDialogService
  ) {
    this.openSubscribe = this.modalDialogService.openSubject.subscribe(this.onChange);
  }

  /**
   * Обработки при уничтожении компонента
   */
  ngOnDestroy(): void {
    this.openSubscribe.unsubscribe();
  }

  /**
   * Оброаботки при изменении компонента
   * @param data
   */
  onChange = (data) => {
    this.selectItemsData = data;
    this.selectedItems = this.selectItemsData.selected || [];
    this.items = this.selectItemsData.items;
    this.show = true;
  };

  /**
   * Подтверждение выбора элементов в компоненте
   */
  public confirm() {
    this.selectItemsData.result.next(this.selectedItems);
    this.show = false;
  }

  /**
   * Отмена действий
   */
  public cancel() {
    this.search = null;
    this.show = false;
    this.selectedItems = null;
  }
}
