import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { filter } from 'rxjs/operators';

import { TimeSpan } from '../../../shared/classes/TimeSpan';
import { HistoryEntityType } from '../../../shared/crm/hist';
import { RightType } from '../../../shared/rights/RightType';
import { IClientUnitListItem } from '../../classes/IClientUnitListItem';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { StoreService } from '../../services/store.service';
import { intervalToString } from '../../utils/intervals';
import { IEntityComponent, UnblockEntityComponent } from '../crm/diller/unblock/diller.unblock.component';
import { ExportUnitsComponent } from '../export/units/export.units.component';
import { ImportUnitsComponent } from '../import/units/import.units.component';
import { UnitComponent } from '../unit/unit.component';

import { UnitsMoveComponent } from './move/units.move.component';

/**
 * Компонент для работы со списком объектов мониторинга
 */
@Component({
  selector: 'units',
  templateUrl: './units.component.html'
})
export class UnitsComponent implements OnInit {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClientUnitListItem[];
  /**
   * Выбранный элемент
   */
  public selected: IClientUnitListItem;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;
  /**
   * Идентификатор учетной записи, по которой необходимо отображать ТС
   */
  private accountId?: string;

  public show: IListItem<string>;

  /**
   * Конструктор
   * @param crudService Сервис для работы с ДИУП
   * @param dialogService Сервис для работы с диалогами
   * @param modalService Сервис для работы с модальными окнами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param route Маршрут, который был активирован
   * @param translator Сервис для перевода
   * @param store Сервис для работы с хранилищем
   * @param confirmationService
   */
  constructor(
    private crudService: CRUDService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private translator: TranslateService,
    private store: StoreService,
    private confirmationService: ConfirmationService
  ) {
    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'creator', name: 'ui.creator' },
      { id: 'account', name: 'ui.account' },
      { id: 'uid', name: 'component.units.id' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Изменение выбранного объекта мониторинга
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { unitId: id };
    this.dialogService.addDialog(UnitComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранного объекта мониторинга
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    this.show = { id, name };
  }

  /**
   * Подтверждение удаления
   */
  public confirm() {
    this.loadingService.wrap(this.crudService.del(this.show?.id, CRUDEntityType.UNIT), true)
      .subscribe(() => {
        this.error = null;
        this.loadData();
        this.show = null;
      });
  }

  /**
   * Очистка состояния компонента
   */
  public clean() {
    this.show = null;
  }

  /**
   * Получение списка выбранных объектов
   */
  public getCheckedUnits() {
    return this.items ? this.items.filter((item) => item.checked && !item.deletedAt) : [];
  }

  /**
   * Получение признака необходимости скрытия конпки удаления объекта
   */
  public isCanDeleteSelected(item: IClientUnitListItem) {
    // tslint:disable-next-line:no-bitwise
    return item && !item.isDeleted ? (item.rights & RightType.DELETE) : false;
  }

  /**
   * Получение признака возможности разблокировки ТС
   */
  public isCanUnblockSelected(item: IClientUnitListItem) {
    return item && this.store.user.isDealer;
  }

  /**
   * Разблокировка сущностей
   */
  public unblockSelected(id: string) {
    if (!id) {
      return;
    }

    const data = {
      type: HistoryEntityType.UNIT,
      entityId: id
    } as IEntityComponent;

    this.dialogService.addDialog(UnblockEntityComponent, data).subscribe();
  }

  /**
   * Добавление нового объекта мониторинга
   */
  public addItem() {
    this.dialogService.addDialog(UnitComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранного объекта мониторинга
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = {
      unitId: id,
      copy: true
    };

    this.dialogService.addDialog(UnitComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Экспорт объектов мониторинга
   */
  public export() {
    const data = { units: [...this.items] };
    this.dialogService.addDialog(ExportUnitsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Импорт в объекты мониторинга
   */
  public import() {
    const data = {units: [...this.items]};
    this.dialogService.addDialog(ImportUnitsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Перенос объектов из одной учетной записи в другую
   */
  public move() {
    const unitIds = this.getCheckedUnits().map((u) => u.id);
    if (!unitIds.length) {
      return;
    }

    const data = {unitIds};
    this.dialogService.addDialog(UnitsMoveComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.route.queryParams.subscribe((params) => {
        this.accountId = params.a;
        this.loadData();
      });
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    const query = this.accountId ? { accountId: this.accountId } : null;

    this.loadingService
      .wrap(this.crudService.getList(CRUDEntityType.UNIT, query))
      .subscribe(
        (items) => {
          this.items = items;
          this.fillLastMessage();
        },
        (error) => this.error = error);
  }

  /**
   * Заполнение информации о последнем пришедшем сообщении от объекта
   */
  private fillLastMessage() {
    if (!this.items) {
      return;
    }

    const now = new Date();
    this.items.forEach((item) => {
      if (item.lastMessageAt) {
        const timeSpan = TimeSpan.fromPeriod(new Date(item.lastMessageAt), now);
        item.lastMessage = intervalToString(timeSpan);
      }
    });
  }
}
