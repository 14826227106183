import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class OverlayPanelService {
  public openSubject = new Subject<IOverlayPanelData>();
}

/**
 * Интерфейс компонента выбора объектов мониторинга
 */
export interface IOverlayPanelData {
  /** width of overlay panel */
  style: object;
  /** Event */
  event: any;
  /** Target element */
  element?: any;
  /** List of values */
  info: Map<any, any>;
}
