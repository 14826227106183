/**
 * Типы объектов права
 */
export enum RightEntityType {
  /** Объект мониторинга */
  UNIT,
  /** ЛС */
  ACCOUNT,
  /** Пользователь */
  USER,
  /** Группа объектов мониторинга */
  UNIT_GROUP
}

/**
 * Получение списка всех типов объектов права
 */
export function getAllRightEntityTypes(): RightEntityType[] {
  const result: RightEntityType[] = [];
  for (let i = 0; i < 4; i++) {
    result.push(i as RightEntityType);
  }
  return result;
}

/**
 * Получение наименования типа объектов права
 * @param type Тип объектов права
 */
export function getRightEntityTypeName(type: RightEntityType): string {
  switch (type) {
    case RightEntityType.UNIT: return 'enums.rights.entity-type.object';
    case RightEntityType.ACCOUNT: return 'enums.rights.entity-type.account';
    case RightEntityType.USER: return 'enums.rights.entity-type.user';
    case RightEntityType.UNIT_GROUP: return 'enums.rights.entity-type.group';
    default: return 'enums.rights.entity-type.unknown';
  }
}
