import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter } from 'rxjs/operators';

import { IListItem } from '../../../classes/IListItem';
import { LoadingService } from '../../../services/loading.service';
import { ISystemNotificationShort, SystemNotificationsService } from '../../../services/system-notifications.service';
import { ToastService } from '../../../services/toast.service';
import { SystemNotificationComponent } from '../system-notification/system-notification.component';

/**
 * Компонент системных уведомлений
 */
@Component({
  selector: 'system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: ISystemNotificationShort[];
  /**
   * Выбранный элемент
   */
  public selected: ISystemNotificationShort;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Ошибка
   */
  public error: string;
  /**
   * Признак отображения закрытых уведомлений
   */
  public showClosed: boolean;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param systemNotificationsService Сервис работы с ДИУП
   * @param toastService Сервис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private dialogService: DialogService,
    private systemNotificationsService: SystemNotificationsService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'autoClose', name: 'component.crm.system-notifications.table.date-start' },
      { id: 'createdAt', name: 'component.crm.system-notifications.table.date-end' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadNotifications();
  }

  /**
   * Добавление системного уведомления
   */
  public addItem() {
    this.dialogService.addDialog(SystemNotificationComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadNotifications);
  }

  /**
   * Изменение системного уведомления
   * @param id
   */
  public changeSelected(id: string) {
    this.dialogService.addDialog(SystemNotificationComponent, { id })
      .pipe(filter((result) => !!result))
      .subscribe(this.loadNotifications);
  }

  /**
   * Удаление системного уведомления
   * @param id
   */
  public deleteSelected(id: string) {
    this.systemNotificationsService.remove(id).subscribe(
      this.loadNotifications,
      () => this.toastService.error('component.notifications.notification-settings.delete-error'));
  }

  /**
   * Загрузка списка системных уведомлений
   */
  protected loadNotifications = () => {
    this.systemNotificationsService.getDealerList().subscribe(
      (notifications) => this.items = notifications,
      () => this.toastService.error('component.notifications.notification-settings.get-list-error'));
  };
}
