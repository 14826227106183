import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { getAllImportTypes, getImportTypeName, ImportType } from '../../../../shared/import/ImportType';
import { RightType, UnitRightType } from '../../../../shared/rights/RightType';
import { IUnitExport } from '../../../../shared/units/IUnitExportSettings';
import { IUnitImportSettings } from '../../../../shared/units/IUnitImportSettings';
import { IUnitListItem } from '../../../../shared/units/IUnitListItem';
import { IImportBlockInfo } from '../../../classes/IImportBlockInfo';
import { IListItem } from '../../../classes/IListItem';
import { ExportImportService } from '../../../services/export-import.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';

/**
 * Интерфейс компонента для импорта в объекты мониторинга
 */
interface IImportUnitsComponent {
  /** Список объектов, в которые можно импортировать */
  units: IUnitListItem[];
  /** Импортируемые данные */
  importUnit?: IUnitExport;
}

/**
 * Компонент для импорта в объекты мониторинга
 */
@Component({
  selector: 'import-units',
  templateUrl: './import.units.component.html'
})
export class ImportUnitsComponent
extends DialogComponent<IImportUnitsComponent, boolean>
implements IImportUnitsComponent {

  /** Список объектов, в которые можно импортировать */
  public units: IUnitListItem[];

  /** Импортируемые данные */
  public importUnit?: IUnitExport;

  /** Признак того, что необходимо выбирать файл для импорта */
  public isNeedFileSelect = false;

  /** Список объектов мониторинга, выбранных для импорта */
  public checkedUnits: IUnitListItem[] = [];

  /** Настройки импорта */
  public settings: IUnitImportSettings;

  /** Строка поиска по объектам */
  public search: string;

  /** Список типов импорта */
  public importTypes: IListItem<ImportType>[] = [];

  /** Иформация по разделам, которые возможно импортировать */
  public importBlockInfos: IImportBlockInfo<IUnitListItem>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param exportImportService Сервис экспорта/импорта
   * @param loadingService Сервис отображения процесса загрузки
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private exportImportService: ExportImportService,
    private loadingService: LoadingService,
    private translator: TranslateService
  ) {
    super(dialogService);

    this.settings = { type: ImportType.REPLACE, units: [] } as IUnitImportSettings;

    this.importTypes = getAllImportTypes().map((id) => ({ id, name: getImportTypeName(id) }));
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IImportUnitsComponent) {
    if (data.importUnit) {
      this.importUnit = data.importUnit;
      this.afterSelectImportUnit();
    } else {
      this.isNeedFileSelect = true;
    }
    return super.fillData(data);
  }

  /**
   * Обработка события выбора файла
   * @param e Аргумент события
   */
  public onFileSelect(e: Event) {
    if (!e.target || !(e.target as any).files || !(e.target as any).files.length) {
      // Если не выбрали никакого файла
      this.importUnit = null;
      this.importBlockInfos = [];
    } else {
      // Если выбрали файл.
      this.loadingService.startLoading();
      const file = (e.target as any).files[0] as File;
      const reader = new FileReader();

      reader.onloadend = () => {
        this.loadingService.stopLoading();
        if (reader.result) {
          try {
            this.importUnit = JSON.parse(reader.result as string);
            this.afterSelectImportUnit();
          } catch (error) {
            this.modalService.showError('component.import.error');
          }
        } else {
          this.modalService.showError('component.import.error');
        }
      };

      reader.readAsText(file);
    }
  }

  /**
   * Переключение выбранности объекта мониторинга
   * @param unit Объект мониторинга
   */
  public toggleChecked(unit: IUnitListItem) {
    const checkedIndex = this.checkedUnits.indexOf(unit);
    if (checkedIndex !== -1) {
      this.checkedUnits.splice(checkedIndex, 1);
      this.units.push(unit);
    } else {
      const index = this.units.indexOf(unit);
      if (index !== -1) {
        this.units.splice(index, 1);
        this.checkedUnits.push(unit);
      }
      this.updateImportElements();
    }
  }

  /**
   * Получение признака возможности продолжения импорта
   */
  public get isCanContinue() {
    return this.importUnit && this.checkedUnits.length &&
      this.importBlockInfos.some((b) => b.checked);
  }

  /**
   * Продолжение импорта
   */
  public continue() {
    if (!this.isCanContinue) { return; }

    this.loadingService.startLoading();
    this.settings.importUnit = this.importUnit;
    this.settings.units = this.checkedUnits.map(({ id }) => id);
    for (const block of this.importBlockInfos) {
      this.settings[block.field] = !!(block.checked && block.haveRight(this.checkedUnits));
    }

    this.exportImportService.importUnits(
      this.settings
    ).subscribe(
      (result) => {
        this.loadingService.stopLoading();
        if (result.length !== this.checkedUnits.length) {
          const notImported: IUnitListItem[] = [];
          for (const unit of this.checkedUnits) {
            if (!result.includes(unit.id)) {
              notImported.push(unit);
            }
          }
          // tslint:disable-next-line:max-line-length
          this.translator.get('component.import.units.error').subscribe((x) => {
            const errorMessage = x['component.import.units.error']
              + `: ${ notImported.map((u) => u.name).join('; ') }`;
            this.modalService.showError(errorMessage);
          });
        } else {
          this.result = true;
          this.close();
        }
      },
      (error) => {
        this.loadingService.stopLoading();
        this.modalService.showError(error);
      }
    );
  }

  /**
   * Обновление настроек импорта после изменения списка объектов,
   * в которые будет выполняться импорт
   */
  private updateImportElements() {
    for (const blockInfo of this.importBlockInfos) {
      if (blockInfo.checked && !blockInfo.haveRight(this.checkedUnits)) {
        blockInfo.checked = false;
      }
    }
  }

  /**
   * Обработка после выбора импортируемого объекта
   */
  private afterSelectImportUnit() {
    this.fillImportBlockInfos();
    const tmpBlockInfos: IImportBlockInfo<IUnitListItem>[] = [];
    for (const blockInfo of this.importBlockInfos) {
      if (this.importUnit[blockInfo.field]) {
        tmpBlockInfos.push(blockInfo);
      }
    }
    this.importBlockInfos = tmpBlockInfos;
  }

  /**
   * Заполнение информации по разделам импорта
   */
  private fillImportBlockInfos() {
    this.importBlockInfos = [
      {
        field: 'general',
        label: 'component.import.units.main',
        haveRight: (units) => this.checkRights(units, RightType.RENAME)
      }, {
        field: 'counters',
        label: 'component.import.units.counters',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_COUNTERS)
      }, {
        field: 'icon',
        label: 'component.import.units.icon',
        haveRight: (units) => this.checkRights(units, RightType.CHANGE_ICON)
      }, {
        field: 'extras',
        label: 'component.import.units.more',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)
      }, {
        field: 'sensors',
        label: 'component.import.units.sensors',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_SENSORS)
      }, {
        field: 'arbitraryFields',
        label: 'component.import.units.arbitrary',
        haveRight: (units) => this.checkRights(units, RightType.CHANGE_ARBITRARY_FIELDS)
      }, {
        field: 'adminFields',
        label: 'component.import.units.admin',
        haveRight: (units) => this.checkRights(units, RightType.CHANGE_ADMIN_FIELDS)
      }, {
        field: 'commands',
        label: 'component.import.units.commands',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_COMMANDS)
      }, {
        field: 'drivingQuality',
        label: 'component.import.units.quality',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)
      }, {
        field: 'characteristics',
        label: 'component.import.units.characteristics',
        haveRight: () => false
      }, {
        field: 'moveDetection',
        label: 'component.import.units.detector',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)
      }, {
        field: 'fuel',
        label: 'component.import.units.fuel',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)
      }, {
        field: 'to',
        label: 'component.import.units.service',
        haveRight: (units) => this.checkRights(units, UnitRightType.CHANGE_TO)
      }
    ];
  }

  /**
   * Проверка наличия права доступа на все выбранные объекты
   * @param units Список выбранных объектов
   * @param right Право доступа
   */
  private checkRights(units: IUnitListItem[], right: RightType | UnitRightType) {
    let result = right;
    for (const unit of units) {
      // tslint:disable-next-line:no-bitwise
      result &= unit.rights;
      if (!result) { return result; }
    }
    return result;
  }
}
