import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { getTimezoneName, Timezone } from '../../../shared/time/timezones';
import { getLocaleName, IUserSettings, IUserUnitDetail, Locale } from '../../../shared/users';
import { IListItem } from '../../classes/IListItem';
import { ClientService } from '../../services/client.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { StoreService } from '../../services/store.service';
import { getAllEnumValues } from '../../utils/enums';

/**
 * Компонент для настроек пользователя
 */
@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent
extends DialogComponent<{ }, boolean> {

  /** Настройки пользователя */
  public settings: IUserSettings;

  /** Языки */
  public locales: IListItem<Locale>[] = [];

  /** Список временных зон, доступных для выбора */
  public timezones: IListItem<Timezone>[] = [];

  /** Список полей для выбора отображения детальной информации по ТС */
  public unitDetails: IListItem<string>[] = [];

  /** Данные для изменения пароля пользователя */
  public changePasswordData: IChangePassword = null;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param clientService Сервис для работы с текущим клиентом
   * @param router Маршрутизатор
   * @param translator Сервис для перевода
   * @param store Сервис для хранения данных
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private clientService: ClientService,
    private router: Router,
    private translator: TranslateService,
    private store: StoreService
  ) {
    super(dialogService);

    this.settings = {} as IUserSettings;

    this.loadingService.withLoading(
      this.clientService.getUserSettings(),
      (settings) => this.settings = settings);

    this.locales = getAllEnumValues<Locale>(Locale)
      .map((id) => ({ id, name: getLocaleName(id) }));

    this.unitDetails = [
      {id: 'time', name: 'component.user-settings.last-changes'},
      {id: 'address', name: 'component.user-settings.location'},
      {id: 'geozones', name: 'component.user-settings.geo'},
      {id: 'drivers', name: 'component.user-settings.drivers'},
      {id: 'trailers', name: 'component.user-settings.trails'},
      {id: 'speed', name: 'component.user-settings.speed'},
      {id: 'counters', name: 'component.user-settings.counters'},
      {id: 'connection', name: 'component.user-settings.connections'},
      {id: 'sensors', name: 'component.user-settings.sensors'},
      {id: 'params', name: 'component.user-settings.params'},
      {id: 'arbitrary', name: 'component.user-settings.arbitrary'},
      {id: 'to', name: 'component.user-settings.service'}
    ];

    this.timezones = getAllEnumValues<Timezone>(Timezone)
      .map((id) => ({ id, name: getTimezoneName(id) }));
  }

  /**
   * Инициализация режима изменения пароля пользователя
   */
  public changePassword() {
    this.changePasswordData = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  }

  /**
   * Признак возможности подтверждения изменения пароля
   */
  public get isCanConfirmChangePassword() {
    return this.changePasswordData && this.changePasswordData.newPassword &&
      this.changePasswordData.newPassword !== '' && this.changePasswordData.oldPassword &&
      this.changePasswordData.oldPassword !== '' &&
      this.changePasswordData.oldPassword !== this.changePasswordData.newPassword &&
      this.changePasswordData.confirmPassword === this.changePasswordData.newPassword;
  }

  /**
   * Подтверждение изменения пароля
   */
  public confirmChangePassword() {
    if (!this.isCanConfirmChangePassword) {
      return;
    }

    this.loadingService.withLoading(
      this.clientService.updateUserPassword(
        this.changePasswordData.oldPassword,
        this.changePasswordData.newPassword),
      () => {
        this.changePasswordData = null;
        this.modalService.showInfo('component.user-settings.password-changed');
      }, null, true);
  }

  /**
   * Отмена изменения пароля
   */
  public cancelChangePassword() {
    this.changePasswordData = null;
  }

  /**
   * Получение признака отображения элемента детальной информации
   * @param field Поле в детальной информации
   * @param isMap Признак настройки для отображения на карте
   */
  public getDetail(field: string, isMap: boolean) {
    if (!this.settings.unitDetail || !this.settings.unitDetail[field]) {
      return false;
    }

    return this.settings.unitDetail[field][isMap ? 'map' : 'tracking'];
  }

  /**
   * Переключение признака отображения элемента детальной информации
   * @param field Поле в детальной информации
   * @param isMap Признак настройки для отображения на карте
   */
  public toggleDetail(field: string, isMap: boolean) {
    if (!this.settings.unitDetail) {
      this.settings.unitDetail = { } as IUserUnitDetail;
    }
    if (!this.settings.unitDetail[field]) {
      this.settings.unitDetail[field] = { };
    }
    this.settings.unitDetail[field][isMap ? 'map' : 'tracking'] =
      !this.settings.unitDetail[field][isMap ? 'map' : 'tracking'];
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    this.loadingService.withLoading(
      this.clientService.updateUserSettings(this.settings),
      () => {
        // запоминаем состояние кнопки удаленные объекты
        const showDeletedObjects: boolean = this.store?.user?.settings?.showDeletedObjects

        // Обновляем настройки пользователя в хранилище
        if (this.store.user) {
          this.store.user.settings = this.settings;
        }

        this.result = true;

        if (this.translator.currentLang !== this.settings.locale || showDeletedObjects !== this.settings.showDeletedObjects) {
          this.translator.use(this.settings.locale);

          this.router.navigate(['redirect'], {
            queryParams: { url: this.router.url },
            skipLocationChange: true,
            replaceUrl: false
          }).then();
        }

        this.close();
      }
    );
  }
}

/**
 * Интерфейс для изменения пароля
 */
interface IChangePassword {
  /** Старый пароль */
  oldPassword: string;
  /** Новый пароль */
  newPassword: string;
  /** Подтверждение нового пароля */
  confirmPassword: string;
}
