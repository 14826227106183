import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IPointDetail, IPointParam } from '../../../../shared/points/IPointDetail';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { PointsService } from '../../../services/points.service';

/**
 * Интерфейс компонента для редактирования точки
 */
interface IPointEditComponent {
  /** Идентификатор точки */
  id: string;
}

/**
 * Компонент для редактирования точки
 */
@Component({
  selector: 'point-edit',
  templateUrl: './point.edit.component.html'
})
export class PointEditComponent
  extends DialogComponent<IPointEditComponent, boolean> {

  /** Точка */
  public point: IPointDetail;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param pointsService Сервис для работы с точками
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private pointsService: PointsService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IPointEditComponent) {
    this.loadingService.withLoading(this.pointsService.get(data.id), (point) => this.point = point);
    return super.fillData(data);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    let empty = false;
    if (this.point.lat == null) {
      empty = true;
    }
    if (this.point.lon == null) {
      empty = true;
    }
    if (this.point.bearing == null) {
      empty = true;
    }
    if (this.point.speed == null) {
      empty = true;
    }
    if (empty) {
      this.modalService.showError('component.point.error');
      return;
    }

    this.loadingService.withLoading(this.pointsService.update(this.point), () => {
      this.result = true;
      this.close();
    });
    return;
  }

  /**
   * Получение типа значения
   * @param value Значение
   */
  public getType(value: string | number | boolean) {
    return typeof value;
  }

  /**
   * Валидация значения
   * @param param параметр
   */
  public validate(param: IPointParam) {
    const value = Number(param.value);
    if (Number.isNaN(value)) {
      param.value = value;
    }
  }
}
