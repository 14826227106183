import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';

import { IClient } from '../../../../../shared/crm/clients/IClient';
import { DetailComponent } from '../../../../classes/DetailComponent';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';

/**
 * Интерфейс компонента для редактирования клиента
 */
interface IClientsEditComponent {
  /** Идентификатор клиента */
  clientId?: string;
}

/**
 * Компонент для редактирования клиента
 */
@Component({
  selector: 'clients-edit',
  templateUrl: './clients.edit.component.html'
})
export class ClientsEditComponent
extends DetailComponent<IClientsEditComponent>
implements IClientsEditComponent {

  /** Идентификатор клиента */
  public clientId?: string;

  /** Клиент */
  public client: IClient;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для CRUD
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private translator: TranslateService
  ) {
    super(dialogService);

    this.client = {} as IClient;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IClientsEditComponent) {
    this.title = 'component.crm.clients.edit.' + (data.clientId ? 'client-edit-title' : 'client-add-title');

    if (data.clientId) {
      this.crudService.get(
        data.clientId,
        CRUDEntityType.CLIENT
      ).subscribe(
        (client) => this.client = client,
        (error) => this.modalService.showError(error)
      );
    }

    return super.fillData(data);
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /**
   * Получение признака возможности сохранения клиента
   */
  public get isCanSave(): boolean {
    return !!(this.client && this.client.name);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) { return; }

    this.loadingService.startLoading();
    this.crudService.addUpdate(
      this.client, CRUDEntityType.CLIENT
    ).subscribe(
      (id) => {
        this.loadingService.stopLoading();
        this.result = id;
        this.close();
      },
      (error) => {
        this.loadingService.stopLoading();
        this.modalService.showError(error);
      }
    );
  }
}
