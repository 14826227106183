import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { timer } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { IDriver } from '../../../shared/drivers/IDriver';
import { ISortInfo } from '../../../shared/ISortInfo';
import { LinkObjectType } from '../../../shared/LinkObjectType';
import { AccountRightType } from '../../../shared/rights/RightType';
import { ITrackingAssignment } from '../../../shared/tracking/ITrackingAssignment';
import { IClientDriver } from '../../classes/IClientDriver';
import { IListItem } from '../../classes/IListItem';
import { TrackingDriverGroup } from '../../classes/TrackingDriverGroup';
import { AccountsService } from '../../services/accounts.service';
import { AssignmentsService } from '../../services/assignments.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { StoreService } from '../../services/store.service';
import { localeSort } from '../../utils/sort';
import { AssignmentsComponent } from '../assignments/assignments.component';
import { ModalResult } from '../modal/modal.component';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';

import { DriversEditComponent } from './edit/drivers.edit.component';

/**
 * Компонент для водителей
 */
@Component({
  selector: 'drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnDestroy {

  /** Строка поиска */
  public search: string;

  /** Признак отображения строки поиска */
  public showSearch: boolean = false;

  /** Сортировка */
  public sort: ISortInfo;

  /**
   * Список учетных записей, водителей которых может видеть пользователь
   */
  public accounts: IListItem<string>[] = [];

  /**
   * Конструктор
   * @param assignmentsService Сервис для работы с назначениями
   * @param store Сервис для хранения данных мониторинга
   * @param modalService Сервис работы с модальными окнами
   * @param accountsService Сервис работы с учетными записями
   * @param dialogService Сервис диалоговых окон
   * @param monitoringService Сервис мониторинга
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    public assignmentsService: AssignmentsService,
    public store: StoreService,
    private modalService: ModalService,
    private accountsService: AccountsService,
    private dialogService: DialogService,
    private monitoringService: MonitoringService,
    private translator: TranslateService,
    private loadingService: LoadingService
  ) {
    this.sort = { field: 'name', isDescending: false };

    // Получаем список учетных записей,
    // водителей которых может видеть пользователь
    this.accountsService.getWithRight(AccountRightType.VIEW_DRIVERS)
      .subscribe((accounts) =>
        this.accounts = accounts?.sort(localeSort));
  }

  /**
   * Получение признака выбранности всех водителей
   */
  get allChecked() {
    return this.store.drivers.every((g) => g.checked);
  }

  /**
   * Получение признака отображения панели выбора учетной записи
   */
  public get showAccounts() {
    return this.store.trackingSettings && this.accounts.length > 1;
  }

  /**
   * Получение признака отображения групп водителей
   */
  public get showDriverGroups() {
    return this.store.trackingSettings &&
      this.store.trackingSettings.showDriverGroups;
  }

  /**
   * Создание водителя
   */
  public addDriver() {
    const data = { driverId: null };
    this.dialogService.addDialog(DriversEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Удаление водителя
   * @param driver Водитель
   */
  public deleteDriver(driver: IClientDriver) {
    const confirm = this.translator.instant('component.drivers.confirm', { val: driver.name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.monitoringService.deleteDriver(driver), true)))
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Редактирование водителя
   * @param driver Водитель
   */
  public updateDriver(driver: IDriver) {
    const data = { driverId: driver.id };
    this.dialogService.addDialog(DriversEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Обработка нажатия на кнопку, скрывающей кнопку снятия водителя с ТС
   * @param driver Водитель
   */
  public onShowDenyButtonClicked(driver: IClientDriver) {
    driver.denying = true;
    timer(10000).subscribe(() => driver.denying = false);
  }

  /**
   * Обработка нажатия на кнопку назначения водителя
   * @param driver Водитель
   * @param isShiftRegistration Признак регистрации рабочей смены
   */
  public assign(driver: IClientDriver, isShiftRegistration: boolean) {
    this.assignmentsService.objectId = driver.id;
    this.assignmentsService.isShiftRegistration = isShiftRegistration;
  }

  /**
   * Обработка нажатия на кнопку снятия водителя
   * @param driver Водитель
   */
  public deny(driver: IClientDriver) {
    this.loadingService.wrap(this.assignmentsService.deny(driver.id, LinkObjectType.DRIVER), true)
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Центрирование карты на водителе
   * @param driver Водитель
   */
  public fitDriver(driver: IClientDriver) {
    this.monitoringService.moveToPoint(driver.assignment);
  }

  /**
   * Открывает окно для работы со списком назначений
   * @param driver Водитель
   */
  public showAssignments(driver: IClientDriver) {
    const data = {
      objectType: LinkObjectType.DRIVER,
      object: {
        id: driver.id,
        name: driver.name
      }
    };

    this.dialogService.addDialog(AssignmentsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Получение класса для переключателя отображения
   * детальной информации по группе водителей
   * @param group Группа водителей
   */
  public getGroupDetailsToggleClass(group: TrackingDriverGroup) {
    return `fa-${group.showDrivers ? 'minus' : 'plus'}-square-o`;
  }

  /**
   * Переключение флажка выбора водителя
   * @param driver Водитель
   */
  public toggleChecked(driver: IClientDriver) {
    this.monitoringService.toggleDriverChecked(driver);
  }

  /**
   * Переключение выбранности всех водителей
   */
  public toggleAllChecked() {
    this.monitoringService.setAllDriversChecked(!this.allChecked);
  }

  /**
   * Выбор учетной записи из списка
   */
  public selectAccount() {
    const selectedAccount = this.accounts
      .find((a) => a.id === this.store.trackingSettings.driversAccountId);

    const data = {
      items: this.accounts,
      selected: selectedAccount ? [selectedAccount] : [],
      title: 'component.drivers.select-account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.store.trackingSettings.driversAccountId = result?.shift()?.id;
        this.onChangeAccount();
      });
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = { field, isDescending: false };
    }
  }

  /**
   * Переключение видимости панели поиска
   */
  public toggleSearchPanel() {
    this.showSearch = !this.showSearch;
    this.search = '';
  }

  /**
   * Добавление группы водителей
   */
  public addGroup() {
    this.store.editDriverGroup = {
      id: null,
      name: '',
      descr: '',
      drivers: [],
      accountId: this.store.trackingSettings.driversAccountId
    };
  }

  /**
   * Изменение группы водителей
   * @param group Группа водителей
   */
  public updateGroup(group: TrackingDriverGroup) {
    this.store.editDriverGroup = {
      id: group.id,
      name: group.name,
      descr: group.descr,
      drivers: group.drivers,
      accountId: group.accountId
    };
  }

  /**
   * Удаление группы водителей
   * @param group Группа водителей
   */
  public deleteGroup(group: TrackingDriverGroup) {
    const confirm = this.translator.instant('component.drivers.confirm-group', { val: group.name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.monitoringService.deleteDriverGroup(group), true)))
      .subscribe(() => this.monitoringService.getDrivers());
  }

  /**
   * Переключение выбранности группы водителей
   * @param group Группа водителей
   */
  public toggleGroupChecked(group: TrackingDriverGroup) {
    const checked = !group.checked;
    for (const driver of group.trackingDrivers) {
      if (driver.checked !== checked) {
        this.monitoringService.toggleDriverChecked(driver);
      }
    }
  }

  /**
   * Переключение видимости водителей в группе
   * @param group
   */
  public toggleShowDrivers(group: TrackingDriverGroup) {
    group.showDrivers = !group.showDrivers
    const hideDriverGroup: string[] = this.store.driverGroups?.filter((g) => !g.showDrivers)?.map((g) => g.id)
    localStorage.setItem('hideDriverGroup', JSON.stringify(hideDriverGroup))
  }

  /**
   * Переключение признака необходимости отображения групп водителей
   */
  public toggleDriverGroups() {
    if (this.store.trackingSettings) {
      this.store.trackingSettings.showDriverGroups =
        !this.store.trackingSettings.showDriverGroups;
      this.onChangeAccount();
    }
  }

  /**
   * Получение css класса для отображения статуса водителя (занят или свободен)
   * @param assignment Назначение
   */
  public getDriverStatusClass(assignment: ITrackingAssignment): string {
    return assignment ? this.isBusy(assignment) ? 'green' : 'red' : 'grey';
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    // Очищаем идентификатор назначаемого объекта
    this.assignmentsService.objectId = null;
  }

  /**
   * Проверка занятости водителя
   * @param assignment Назначение
   */
  public isBusy(assignment: ITrackingAssignment): boolean {
    if (!assignment) {
      return false;
    }

    return !assignment.e || assignment.e >= (new Date()).getTime();
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onChangeAccount() {
    this.loadingService.wrap(this.monitoringService.updateTrackingSettings(this.store.trackingSettings), true)
      .subscribe(() => this.monitoringService.getDrivers());
  }
}
