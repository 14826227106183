import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { TimeSpan } from '../../../../shared/classes/TimeSpan';
import { GeocoderService } from '../../../services/geocoder.service';
import { IPointChart, IPointTable, IReportPoint } from '../../../services/reports.service';

/**
 * Компонент для отображения детальной информации по элементам отчета на карте
 */
@Component({
  selector: 'map-report-detail',
  templateUrl: './map.report-detail.component.html',
  styleUrls: ['./map.report-detail.component.scss']
})
export class MapReportDetailComponent implements OnInit, OnDestroy {

  /** Точка рейса отчета */
  @Input() public details: IMapReportDetails;

  /** Подписка на таймер перед получением адреса */
  private subscribe: Subscription;

  /** Признак отмены получения адреса */
  private cancelGetAddress = false;

  /**
   * Конструктор
   * @param geocoderService Сервис геокодирования
   */
  constructor(private geocoderService: GeocoderService) {}

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.details && this.details.point && !this.details.address) {
      this.subscribe = timer(500).subscribe(this.getAddress);
    }
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    this.cancelGetAddress = true;
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  /**
   * Получение строки с длительностью времени
   * @param value Количество миллисекунд
   */
  public getTime(value: number) {
    return new TimeSpan(value).toShortString();
  }

  /**
   * Получение количества часов по длительности времени
   * @param value Количество миллисекунд
   */
  public getHours(value: number) {
    return Math.round(value / 3600000);
  }

  /**
   * Получение адреса
   */
  public getAddress = () => {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
      this.subscribe = null;
    }

    if (this.cancelGetAddress) {
      return;
    }

    this.geocoderService.getAddress(null, this.details.point.lt, this.details.point.ln)
      .subscribe((address) => this.details.address = address);
  }
}

/**
 * Точка рейса с информацией о линиях графика
 */
export interface IMapReportDetails {
  /** Название ТС */
  name?: string;
  /** Идентификатор ТС */
  objectId?: string;
  /** Адрес */
  address?: string;
  /** Точка */
  point?: IReportPoint;
  /** Информация о графиках в точке */
  charts?: IPointChart[];
  /** Информация о таблице в точке */
  table?: IPointTable;
}
