import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { ClientService } from './client.service';

/**
 * Сервис учета активности пользователей
 */
@Injectable()
export class ActivityService {

  /**
   * Подписка на отслеживание активности клиента
   */
  private activityTrackSubscription: Subscription;

  /**
   * Конструктор
   * @param clientService Сервис слежения
   */
  constructor(private clientService: ClientService) { }

  /**
   * Запуск отслеживания активности
   */
  public startActivityTrack() {
    const period = 1000 * 60;
    this.activityTrackSubscription = timer(period, period).subscribe(this.onActivityTrack);
  }

  /**
   * Остановка отслеживания активности
   */
  public stopActivityTrack() {
    if (this.activityTrackSubscription) {
      this.activityTrackSubscription.unsubscribe();
    }
  }

  /**
   * Отправка уведомления об активности пользователя
   */
  private onActivityTrack = () => {
    this.clientService.activity().subscribe(
      () => { /** ignored */ },
      (error) => console.error(error)
    );
  }
}
