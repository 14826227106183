import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { IClient } from '../../../../shared/crm/clients/IClient';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ModalResult } from '../../modal/modal.component';

import { ClientsEditComponent } from './edit/clients.edit.component';

/**
 * Компонент для отображения списка клиентов
 */
@Component({
  selector: 'clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClient[];
  /**
   * Выбранный элемент
   */
  public selected: IClient;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;

  /**
   * Конструктор
   * @param crudService Сервис для работы с CRUD
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'email', name: 'ui.email' },
      { id: 'phone', name: 'component.crm.clients.phone' },
      { id: 'inn', name: 'component.crm.clients.inn' },
      { id: 'kpp', name: 'component.crm.clients.kpp' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление новой записи
   */
  public addItem() {
    this.dialogService.addDialog(ClientsEditComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной записи
   */
  public changeSelected(id) {
    if (!id) {
      return;
    }

    const data = { clientId: id };
    this.dialogService.addDialog(ClientsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранной записи
   */
  public deleteSelected(id, name) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant('component.crm.clients.confirm', { val: name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.CLIENT), true)))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка данных компонента
   */
  protected loadData = () => {
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.CLIENT), true)
      .subscribe((clients) => {
        this.items = clients;
      });
  };
}
