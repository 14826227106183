/**
 * Тип выгружаемых документов при пакетной выгрузке
 */
export enum PacketDocs {
  /** Только счета */
  INVOICE = 1,
  /** Только акты */
  REPORT,
  /** Акты и счета */
  BOTH
}
