/** Типы окрашивания трека */
export enum TrackColoringType {
  /** Однотонный */
  MONO = 1,
  /** По скорости */
  BY_SPEED,
  /** По датчику */
  BY_SENSOR
}

/**
 * Получение списка всех типов окрашивания трека
 */
export function getAllTrackColoringTypes() {
  const result: TrackColoringType[] = [];
  for (let i = 1; i <= 3; i++) {
    result.push(i as TrackColoringType);
  }

  return result;
}

/**
 * Получение наименования типа окрашивания трека
 * @param type Тип окрашивания трека
 */
export function getTrackColoringTypeName(type: TrackColoringType) {
  switch (type) {
    case TrackColoringType.MONO: return 'enums.units.track-coloring-type.mono';
    case TrackColoringType.BY_SPEED: return 'enums.units.track-coloring-type.speed';
    case TrackColoringType.BY_SENSOR: return 'enums.units.track-coloring-type.sensor';
    default: return 'enums.units.track-coloring-type.unknown';
  }
}
