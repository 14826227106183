import * as _ from 'underscore';

import { ISensorInterval } from '../../shared/sensors/ISensorInterval';
import { ITrackingInfo } from '../../shared/tracking/ITrackingInfo';
import { ITrackingMaintenanceInterval } from '../../shared/tracking/ITrackingMaintenanceInterval';
import { ITrackingUnit } from '../../shared/tracking/ITrackingUnit';
import { tranny } from '../app.component';
import { TrafficLightType } from '../services/online-notifications.service';

import { IClientDriver } from './IClientDriver';
import { IClientGeozone } from './IClientGeozone';
import { IClientTrailer } from './IClientTrailer';

/**
 * Объект слежения
 */
export class TrackingUnit implements ITrackingUnit {
  /** Идентификатор объекта */
  public id: string;

  /** Наименование объекта */
  public name: string;

  /** Признак выбранного объекта */
  public checked: boolean;

  /** Признак отображения детальной информации */
  public detail: boolean;

  /** Данные местоположения */
  public position: ITrackingInfo;

  /** Признак слежения */
  public eye: boolean;

  /** Цвет подписи к объекту */
  public color: string;

  /** Иконка */
  public icon: string;

  /** Признак вращения иконки */
  public rotate: boolean;

  /** Признак блокировки */
  public blocked?: boolean;

  /** Наименование датчика */
  public sensor?: string;

  /** Список интервалов для датчика */
  public intervals?: ISensorInterval[];

  /** Текущий адрес объекта */
  public address: string;

  /** Список геозон, в которые входит объект */
  public geozones?: IClientGeozone[];

  /** Список водителей, которые находятся в объекте */
  public drivers?: IClientDriver[];

  /** Список прицепов, назначенных на объект */
  public trailers?: IClientTrailer[];

  /** Порог перехода состояния движения в разряд устаревших */
  public moveThreshold: number;

  /** Информация по техобслуживанию */
  public to?: ITrackingMaintenanceInterval[];

  /** Обработанная информация по техобслуживанию */
  public toInfos?: IMaintenanceInfo[];

  /** Признак видимости в списке объектов мониторинга */
  public infoIconVisible: boolean;
  /** Признак что это группа */
  public isGroup?: boolean;

  /**
   * Конструктор
   * @param data Данные объекта слежения
   * @param moveThreshold Порог перехода состояния движения в разряд устаревших
   * @param trafficLight Цвет сигнала светофора для подсвечивания объекта в списке при срабатывании уведомления
   */
  constructor(
    data: ITrackingUnit,
    moveThreshold?: number,
    public trafficLight?: TrafficLightType
  ) {
    const keys = _.keys(data);
    for (const key of keys) {
      this[key] = data[key];
    }

    // Заполняем информацию о техобслуживанию
    if (data.to) {
      this.toInfos = [];
      for (const to of data.to) {
        const info: IMaintenanceInfo = { name: to.name, items: [] };
        this.toInfos.push(info);
        if (to.mi != null) {
          info.items.push(this.fillInfo(to.mi, 'km'));
        }
        if (to.mh != null) {
          info.items.push(this.fillInfo(to.mh, 'mh'));
        }
        if (to.days != null) {
          info.items.push(this.fillInfo(to.days, 'd'));
        }
      }
    }

    this.moveThreshold = moveThreshold && moveThreshold > 0 ? moveThreshold : 15;
  }

  /**
   * Заполнение информации о техобслуживании
   * @param value Значение
   * @param metric Единица измерения
   */
  private fillInfo(value: number, metric: string): IMaintenanceInfoItem {
    const overdue = value <= 0;
    value = Math.abs(value);
    const text = tranny.instant(
      `classes.tracking-unit.${overdue ? 'more' : 'less'}-${metric}`,
      { val: value });
    return { overdue, text };
  }

  /**
   * Время последних валидных данных от объекта
   */
  get time() {
    return this.position ? this.position.t : 0;
  }

  /**
   * Время последнего получения данных
   */
  get connected() {
    return this.position ? (this.position.m || 0) : 0;
  }

  /**
   * Скорость
   */
  get speed() {
    return this.position ? this.position.s : 0;
  }

  /**
   * Список параметров из сообщений
   */
  get params() {
    return this.position && this.position.a ? this.position.a.params : [];
  }

  /**
   * Список данных датчиков
   */
  get sensors() {
    return this.position && this.position.a ? this.position.a.sensors : [];
  }

  /**
   * Счетчик пробега
   */
  get mileageCounter() {
    return Math.round(this.position && this.position.a ? this.position.a.mic : 0);
  }

  /**
   * Счетчик моточасов
   */
  get mhCounter() {
    return Math.round(this.position && this.position.a ? this.position.a.mhc : 0);
  }

  /**
   * Статус движения
   */
  get moveState() {
    if (this.position.t === 0) {
      return TrackingMoveState.NO_DATA;
    }

    const ignition = this.position.i || false;
    const now = new Date().getTime();
    if ((now - this.position.t) / 60000 <= this.moveThreshold) {
      return this.position.s
        ? (ignition ? TrackingMoveState.MOVE_WITH_IGNITION : TrackingMoveState.MOVE)
        : (ignition ? TrackingMoveState.STOP_WITH_IGNITION : TrackingMoveState.STOP);
    } else {
      return this.position.s ? TrackingMoveState.OLD_MOVE : TrackingMoveState.OLD_STOP;
    }
  }

  /**
   * Статус датчика
   */
  get sensorState() {
    if (!this.intervals || !this.intervals.length) {
      return TrackingSensorState.MISSING;
    } else if (!_.isNumber(this.position.d)) {
      return TrackingSensorState.NO_DATA;
    } else {
      return TrackingSensorState.OK;
    }
  }

  /**
   * Текущий цвет для датчика
   */
  get sensorColor() {
    if (!this.intervals || !this.intervals.length || !_.isNumber(this.position.d)) {
      return null;
    }
    const lowerThanValue = this.intervals.filter((i) => i.from <= this.position.d);
    if (lowerThanValue.length) {
      const max = _.max(lowerThanValue, (i) => i.from);
      return max.color;
    } else {
      const min = _.min(this.intervals, (i) => i.from);
      return min.color;
    }
  }

  /**
   * Текущий текст для датчика
   */
  get sensorText() {
    if (!this.intervals || !this.intervals.length || !_.isNumber(this.position.d)) {
      return null;
    }
    const lowerThanValue = this.intervals.filter((i) => i.from <= this.position.d);
    if (lowerThanValue.length) {
      const max = _.max(lowerThanValue, (i) => i.from);
      return `${this.sensor}: ${max.text}`;
    } else {
      const min = _.min(this.intervals, (i) => i.from);
      return `${this.sensor}: ${min.text}`;
    }
  }
}

/**
 * Перечисление типов состояния датчика
 */
export enum TrackingSensorState {
  MISSING = 1,
  NO_DATA = 2,
  OK = 3
}

/**
 * Перечисление типов статуса движения
 */
export enum TrackingMoveState {
  /** Движение */
  MOVE = 1,

  /** Движение с включенным зажиганием */
  MOVE_WITH_IGNITION = 2,

  /** Стоянка */
  STOP = 3,

  /** Стоянка с включенным зажиганием */
  STOP_WITH_IGNITION = 4,

  /** Движение (данные устарели) */
  OLD_MOVE = 5,

  /** Стоянка (данные устарели) */
  OLD_STOP = 6,

  /** Нет данных */
  NO_DATA = 9
}

/** Обработанная инфомрация по ТО */
interface IMaintenanceInfo {
  /** Наименование интервала */
  name: string;
  /** Список элементов интервала */
  items: IMaintenanceInfoItem[];
}

/** Элемент интервала ТО */
interface IMaintenanceInfoItem {
  /** Признак просрочки */
  overdue: boolean;
  /** Текст, выводимый пользователю */
  text: string;
}
