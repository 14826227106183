import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap, map } from 'rxjs/operators';

import { IActUser } from '../../../../shared/users';
import { IListItem } from '../../../classes/IListItem';
import { BgPopoverComponent, BgPopoverService } from '../../../modules/bg-popover';
import { LoadingService } from '../../../services/loading.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { UsersService } from '../../../services/users.service';
import { ISelectItemsComponent, SelectionType, SelectItemsComponent } from '../../select-items/select-items.component';
import { UserSettingsComponent } from '../../user-settings/user-settings.component';

/**
 * Компонент для раздела "Дополнительно" в навигации
 */
@Component({
  selector: 'navigation-more',
  templateUrl: './navigation.more.component.html',
  styleUrls: ['./navigation.more.component.scss']
})
export class NavigationMoreComponent
  extends BgPopoverComponent<{}> {

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   * @param store Сервис для хранения данных
   * @param router Маршрутизатор
   * @param dialogService Сервис диалоговых окон
   * @param monitoringService Сервис мониторинга
   * @param usersService Сервис для работы с пользователями
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    popoverService: BgPopoverService,
    public store: StoreService,
    private router: Router,
    private dialogService: DialogService,
    private monitoringService: MonitoringService,
    private usersService: UsersService,
    private loadingService: LoadingService
  ) {
    super(popoverService);
  }

  /**
   * Признак режима наблюдения
   */
  public get isObserve() {
    return !!localStorage.getItem('observable');
  }

  /**
   * Имя текущего пользователя
   */
  public get name() {
    const observer = localStorage.getItem('observer');
    const username = this.store.user ? this.store.user.name : '';
    return observer ? `${observer} (${username})` : username;
  }

  /**
   * Обработчик клика по какому-либо элементу окна
   */
  public onClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  /**
   * Клик по кнопка изменения настроек пользователя
   */
  public changeUserSettings() {
    this.popoverService.removePopover();

    this.dialogService.addDialog(UserSettingsComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.restartTracking());
  }

  /**
   * Клик по кнопке выхода из системы
   */
  public logout() {
    this.router.navigate(['logout']).then();
    this.popoverService.removePopover();
  }

  /**
   * Клик по кнопке перехода в кабинет клиента
   */
  public cabinet() {
    this.router.navigate(['client', 'finances']).then();
    this.popoverService.removePopover();
  }

  /**
   * Обработка после инициализации компонента
   */
  public ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Переход в режим наблюдения
   */
  public observe() {
    this.popoverService.removePopover();

    const selectUser = (users: IActUser[]) => {
      const notCurrent = (user: IActUser) => !user.current;
      const toItemList = (user: IActUser) => ({ id: user.id, name: user.name } as IListItem<string>);

      const data: ISelectItemsComponent = {
        title: 'component.navigation.more.select-user',
        items: users.filter(notCurrent).map(toItemList),
        withSearch: true,
        selection: SelectionType.OnlyOne
      };

      return this.dialogService.addDialog(SelectItemsComponent, data);
    };

    const notEmpty = (users: IListItem<string>[]) => !!users?.length;
    const first = (users: IListItem<string>[]) => users?.shift();

    const next = (user: IListItem<string>) => {
      localStorage.setItem('observer', this.store.user.name);
      localStorage.setItem('observable', user.id);
      this.router.navigate(['login'], { skipLocationChange: true, replaceUrl: true }).then();
    };

    this.loadingService.wrap(this.usersService.getActUsers(), true)
      .pipe(flatMap(selectUser), filter(notEmpty), map(first))
      .subscribe(next);
  }

  /**
   * Выход из режима наблюдения
   */
  public observeEnd() {
    this.popoverService.removePopover();
    localStorage.removeItem('observer');
    localStorage.removeItem('observable');
    this.router.navigate(['login'], { skipLocationChange: true, replaceUrl: true }).then();
  }
}
