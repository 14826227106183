import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { UnitRightType } from '../../../../shared/rights/RightType';
import { ISensorBase } from '../../../../shared/sensors/ISensorBase';
import { getSensorTypeName, SensorType } from '../../../../shared/sensors/SensorType';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { UnitsService } from '../../../services/units.service';
import { ModalResult } from '../../modal/modal.component';
import { SensorComponent } from '../../sensor/sensor.component';

/**
 * Список датчиков объекта мониторинга
 */
@Component({
  selector: 'unit-sensors',
  templateUrl: './unit.sensors.component.html',
  styleUrls: ['./unit.sensors.component.scss']
})
export class UnitSensorsComponent implements OnInit {

  /** Идентификатор объекта мониторинга */
  @Input() public unitId: string;

  /** Список датчиков */
  @Input() public sensors: ISensorBase[];

  /** Генератор события изменения списка датчиков */
  @Output() public sensorsChange = new EventEmitter<ISensorBase[]>();

  /** Права доступа */
  @Input() public rights: number;

  /**
   * Конструктор
   * @param unitsService Сервис работы с ТС
   * @param modalService Сервис модальных окон
   * @param crudService Сервис работы с CRUD
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param loadingService Сервис отображения процесса загрузки
   */
  constructor(
    private unitsService: UnitsService,
    private modalService: ModalService,
    private crudService: CRUDService,
    private dialogService: DialogService,
    private translator: TranslateService,
    private loadingService: LoadingService
  ) {
  }

  /** Признак того, что пользователь может управлять датчиками объекта мониторинга */
  get isCanChangeSensors() {
    // tslint:disable-next-line:no-bitwise
    return this.rights & UnitRightType.CHANGE_SENSORS;
  }

  /**
   * Получение наименования типа датчика
   * @param type Тип датчика
   */
  public getSensorTypeName(type: SensorType): string {
    return getSensorTypeName(type);
  }

  /**
   * Добавление датчика
   */
  public addSensor() {
    this.updateSensor(null);
  }

  /**
   * Изменение датчика
   * @param sensor Датчик
   */
  public updateSensor(sensor: ISensorBase) {
    const data = {
      sensorId: sensor ? sensor.id : null,
      unitId: this.unitId,
      sensors: [...this.sensors],
      isReadOnly: !this.isCanChangeSensors
    };

    this.dialogService.addDialog(SensorComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadSensors);
  }

  /**
   * Удаление датчика
   * @param sensor Датчик
   */
  public deleteSensor(sensor: ISensorBase) {
    const confirm = this.translator.instant(
      'component.unit.sensors.confirm-sensor',
      { val: sensor.name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(sensor.id, CRUDEntityType.SENSOR), true)))
      .subscribe(this.loadSensors);
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (!this.sensors) {
      this.loadSensors();
    }
  }

  /**
   * Загрузка списка датчиков
   */
  private loadSensors = () => {
    this.loadingService.wrap(this.unitsService.getUnitSensors(this.unitId), true)
      .subscribe((sensors) => {
        this.sensors = sensors;
        this.sensorsChange.emit(this.sensors);
      });
  };
}
