import { IDriverGroup } from '../../shared/drivers/IDriverGroup';

import { IClientDriver } from './IClientDriver';

/**
 * Группа водителей для отображения в режиме слежения
 */
export class TrackingDriverGroup implements IDriverGroup {
  /** Идентификатор записи */
  public id: string;

  /** Наименование группы */
  public name: string;

  /** Идентификатор учетной записи, к которой относится группа */
  public accountId: string;

  /** Список идентификаторов водителей группы */
  public drivers: string[];

  /** Описание группы */
  public descr?: string;

  /** Список отслеживаемых водителей группы */
  public trackingDrivers: IClientDriver[];

  /** Признак необходимости отображения водителей группы */
  public showDrivers?: boolean;

  /**
   * Конструктор
   * @param group Информация о группе водителей
   */
  constructor(group: IDriverGroup) {
    this.id = group.id;
    this.name = group.name;
    this.accountId = group.accountId;
    this.drivers = group.drivers;
    this.descr = group.descr;
    this.trackingDrivers = [];
    this.showDrivers = true;
  }

  /**
   * Получение признака выбранности группы
   */
  public get checked() {
    return this.trackingDrivers.length && this.trackingDrivers.every((u) => u.checked);
  }
}
