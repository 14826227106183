import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Provider, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IListItem } from '../../classes/IListItem';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ButtonEditComponent),
  multi: true
};

/**
 * Компонент с input'ом и кнопкой
 */
@Component({
  selector: 'button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class ButtonEditComponent
implements ControlValueAccessor {

  @ViewChild('input', {static: false}) public inputContainer: ElementRef;

  /**
   * Генератор события клика по кнопке
   * отображения детальной информации
   */
  @Output() public detailClick = new EventEmitter<IListItem<any>>();

  /**
   * Генератор события клика по кнопке
   * выбора из списка
   */
  @Output() public listClick = new EventEmitter<any>();

  /**
   * Генератор события клика по кнопке
   * добавления новой записи
   */
  @Output() public addClick = new EventEmitter<any>();

  /**
   * Генератор события при вводе значения
   */
  @Output() public inputChanged = new EventEmitter<any>();

  /** Признак заблокированности компонента */
  public isDisabled = false;

  /** Значение, отображаемое пользователю */
  public displayValue: string;

  /** Значение, привязанное к компоненту */
  private value: IListItem<any>;

  /**
   * Установка значения компонента
   * @param value Новое значение компонента
   */
  public writeValue(value: IListItem<any>) {
    this.value = value;
    this.displayValue = this.value ? this.value.name : '';
  }

  /**
   * Установка признака заблокированности компонента
   * @param disabled Признак заблокированности
   */
  public setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  /**
   * Регистрация функции, вызываемой при измении значения компонента
   * @param fn Функция, вызываемая при изменении
   */
  public registerOnChange(fn: any) {
    this.onChangeCb = fn;
  }

  /**
   * ХЗ что это, пусть будет
   */
  public registerOnTouched(fn: any) {
    this.onTouchedCb = fn;
  }

  /**
   * Обработка клика на кнопку отображения детальной информации
   */
  public onDetailClick() {
    this.detailClick.emit(this.value);
  }

  /**
   * Обработка клика на кнопку выбора из списка
   */
  public onListClick() {
    if (this.isDisabled) { return; }

    this.listClick.emit();
  }

  /**
   * Обработка клика на кнопку добавления записи
   */
  public onAddClick() {
    if (this.isDisabled) { return; }

    this.addClick.emit();
  }

  public changeInput($event: any) {
    this.inputChanged.emit($event?.target?.value)
  }

  /**
   * Получение признака необходимости отображения
   * кнопки вызова детальной информации
   */
  public get isDetailClickVisible() {
    return this.detailClick.observers.length > 0;
  }

  /**
   * Получение признака необходимости отображения
   * кнопки добавления новой записи
   */
  public get isAddClickVisible() {
    return this.addClick.observers.length > 0;
  }

  /**
   * Получение признака активности ввода
   */
  public get isInputAvailable() {
    return this.inputChanged.observers.length > 0 && !this.value;
  }

  /**
   * Получение стиля для блока с кнопками действий
   */
  public actionsStyle() {
    // Ширина каждой кнопки - 25px
    let actionsWidth = 50;
    if (this.isDetailClickVisible) { actionsWidth += 25; }
    if (this.isAddClickVisible) { actionsWidth += 25; }

    return {
      width: `${actionsWidth}px`
    };
  }

  /**
   * Обработка клика по кнопке очистки значения компонента
   */
  public clean() {
    if (this.isDisabled) { return; }

    this.value = null;
    this.displayValue = '';
    this.onChangeCb(this.value);
    this.onTouchedCb();
    this.inputContainer.nativeElement.value = '';
    this.inputChanged.emit('')
  }

  // tslint:disable-next-line:no-empty
  private onChangeCb: (_: any) => void = () => { };
  // tslint:disable-next-line:no-empty
  private onTouchedCb: () => void = () => { };
}
