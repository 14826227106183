import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MonitoringService } from '../../services/monitoring.service';
import {
  NavigationPanelType,
  NavigationService,
  PanelItemType
} from '../../services/navigation.service';

/**
 * Базовый класс для компонентов навигации
 */
@Component({
  selector: 'app-navigation',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  /** Тип панели навигации */
  @Input()
  public type: NavigationPanelType;

  /** Ссылка на типы элементов панели */
  public panelItemType = PanelItemType;

  /** Ссылка на типы панели навигации */
  public navigationPanelType = NavigationPanelType;

  /**
   * Конструктор
   * @param router Маршрутизатор
   * @param monitoringService Сервис для управления синхронизацией разделов мониторинга
   * @param navigationService Сервис навигации
   */
  constructor(
    private router: Router,
    private monitoringService: MonitoringService,
    public navigationService: NavigationService
  ) {
  }

  /**
   * Действия при уничтожении компонента
   */
  public ngOnInit() {
    this.navigationService.fillNavigation(this.type)
    this.navigationService.isExpand = (localStorage.getItem('navigationExpand') === 'true')
    this.monitoringService.setNavigationExpandSubject.next({ isExpand: this.navigationService.isExpand, updatePosition: false });
  }

  /**
   * Действия при создании компонента
   */
  public ngOnDestroy() {
    this.navigationService.topNav = [];
    this.navigationService.bottomNav = [];
  }

  /**
   * Изменение видимости рабочей панели или переход по адресу
   * @param link Адрес
   */
  public onClick(link: string) {
    this.navigationService.isWorkPanelVisible = !this.isActive(link) || !this.navigationService.isWorkPanelVisible;
    this.monitoringService.setWorkPanelVisibility(this.navigationService.isWorkPanelVisible);
  }

  /**
   * Сворачивание/разворачивание панели
   * @private
   */
  public expand() {
    this.navigationService.isExpand = !this.navigationService.isExpand;
    this.monitoringService.setNavigationExpandSubject.next({ isExpand: this.navigationService.isExpand, updatePosition: true });
    localStorage.setItem('navigationExpand', String(this.navigationService.isExpand))
  }

  /**
   * Проверка маршрута на активность
   * @param link Маршрут
   */
  public isActive(link: string) {
    return this.router.isActive(link, false);
  }

  /**
   * Проверка отображения рабочей панели
   * @param link Ссылка
   */
  public isHidden(link: string) {
    return this.isActive(link) && !this.navigationService.isWorkPanelVisible;
  }
}

