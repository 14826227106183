import { Component, Input, OnInit } from '@angular/core';

import { RightEntityType } from '../../../shared/rights/RightEntityType';
import {
  getAccountRightTypeName,
  getAllAccountRights,
  getAllRights,
  getAllUnitRights,
  getAllUserRights,
  getRightTypeName,
  getUnitRightTypeName,
  getUserRightTypeName,
  RightType
} from '../../../shared/rights/RightType';
import { IClientSubjectRight } from '../../classes/IClientSubjectRight';
import { IRightGen } from '../../classes/IRightGen';
import { LoadingService } from '../../services/loading.service';
import { RightsService, RightTemplateType } from '../../services/rights.service';

// tslint:disable:no-bitwise

/**
 * Компонент для настройки и отображения прав
 */
@Component({
  selector: 'rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.scss']
})
export class RightsComponent implements OnInit {

  /** Тип объектов права */
  @Input('type')
  public rightEntityType: RightEntityType;

  /** Идентификатор объекта права */
  @Input('objectId')
  public objectId: string;

  /** Права текущего пользователя на объект права */
  @Input('currentUserRights')
  public currentUserRights: number;

  /** Права пользователей на объект права */
  @Input('userRights')
  public userRights: IClientSubjectRight[];

  /** Права выбранного пользователя */
  public selectedRight: IClientSubjectRight;

  /** Заголовок блока со специальными правами */
  public specialRightsTitle: string;

  /** Текст ошибки */
  public error: string;

  /** Основные права */
  public generalRights: IRightGen<RightType>[] = [];

  /** Специальные права */
  public specialRights: IRightGen<number>[] = [];

  /** Строка поиска по пользователям */
  public search: string;

  /** Типы шаблоно прав */
  public rightTemplateType = RightTemplateType;

  /** Типы прав */
  public rightEntity = RightEntityType;


  /**
   * Конструктор
   * @param rightsService Сервис для работы с правами
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private rightsService: RightsService,
    private loadingService: LoadingService
  ) {
    this.generalRights = [];
    this.specialRights = [];
  }

  /**
   * Выбор прав по шаблону
   * @param type
   */
  public changeAccess(type: RightTemplateType) {
    switch (type) {
      case RightTemplateType.DENIED:
        this.selectedRight.newValue = 0
        break
      case RightTemplateType.READ:
        this.selectedRight.newValue = 142692899
        break
      case RightTemplateType.FULL_NO_DEL:
        this.selectedRight.newValue = 2146435063
    }

    this.setSelectedRight(this.selectedRight)
    this.onRightItemClick();
  }

  /**
   * Выбран шаблон без доступа
   */
  public get isDeniedTemplate() {
    return this.selectedRight?.newValue === 0
  }

  /**
   * Выбран шаблон чтение
   */
  public get isReadTemplate() {
    return this.selectedRight?.newValue === (142692899 & this.currentUserRights)
  }

  /**
   * Выбран шаблон все кроме удаления
   */
  public get isFullNoDelTemplate() {
    return this.selectedRight?.newValue === (2146435063 & this.currentUserRights)
  }

  /**
   * Установка права в качестве выбранного
   * @param right Право
   */
  public setSelectedRight(right: IClientSubjectRight) {
    right.newValue &= this.currentUserRights
    this.selectedRight = right;

    this.generalRights = getAllRights().map((type) => ({
      type,
      name: getRightTypeName(type),
      checked: !!(right.newValue & type),
      value: !!(right.value & type),
      disabled: !(type & this.currentUserRights)
    }));

    switch (this.rightEntityType) {
      case RightEntityType.UNIT:
        this.specialRightsTitle = 'component.rights.unit-rights';
        this.specialRights = getAllUnitRights().map((type) => ({
          type,
          name: getUnitRightTypeName(type),
          checked: !!(right.newValue & type),
          value: !!(right.value & type),
          disabled: !(type & this.currentUserRights)
        }));
        break;

      case RightEntityType.ACCOUNT:
        this.specialRightsTitle = 'component.rights.account-rights';
        this.specialRights = getAllAccountRights().map((type) => ({
          type,
          name: getAccountRightTypeName(type),
          checked: !!(right.newValue & type),
          value: !!(right.value & type),
          disabled: !(type & this.currentUserRights)
        }));
        break;

      case RightEntityType.USER:
        this.specialRightsTitle = 'component.rights.user-rights';
        this.specialRights = getAllUserRights().map((type) => ({
          type,
          name: getUserRightTypeName(type),
          checked: !!(right.newValue & type),
          value: !!(right.value & type),
          disabled: !(type & this.currentUserRights)
        }));
        break;

      case RightEntityType.UNIT_GROUP:
        this.specialRightsTitle = 'component.rights.group-rights';
        this.specialRights = getAllUnitRights().map((type) => ({
          type,
          name: getUnitRightTypeName(type),
          checked: !!(right.newValue & type),
          value: !!(right.value & type),
          disabled: !(type & this.currentUserRights)
        }));
        break;
    }
  }

  /**
   * Обработка клика по элементу права
   * @param item Элемент права
   */
  public onRightItemClick(item?: IRightGen<number>) {
    if (!this.selectedRight || item?.disabled) {
      return;
    }

    let result = 0;
    for (const right of this.generalRights) {
      if (right.checked) {
        result |= right.type;
      }
    }
    for (const right of this.specialRights) {
      if (right.checked) {
        result |= right.type;
      }
    }
    this.selectedRight.newValue = result;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.userRights.length) {
      this.selectedRight = this.userRights[0];
      this.setSelectedRight(this.userRights[0]);
    } else {
      this.rightsService.getObjectRights(this.objectId, this.rightEntityType)
        .subscribe((rights) => {
          rights.map((right) => ({
            ...right,
            newValue: right.value
          }))
            .forEach((right) => this.userRights.push(right));

          if (this.userRights.length) {
            this.setSelectedRight(this.userRights[0]);
          }
        });
    }
  }
}
