import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IDriver } from '../../../../shared/drivers/IDriver';
import { RightType } from '../../../../shared/rights/RightType';
import { DetailTab } from '../../../classes/DetailTab';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с подробной информацией о водителе
 * при создании на его основе модального окна
 */
export interface IDriversEditComponent {
  /** Идентификатор водителя */
  driverId: string;
}

/**
 * Компонент, представляющий подробную информацию о водителе
 */
@Component({
  selector: 'drivers-edit',
  templateUrl: './drivers.edit.component.html'
})
export class DriversEditComponent
extends DialogComponent<IDriversEditComponent, boolean>
implements IDriversEditComponent {

  /** Идентификатор водителя */
  public driverId: string;

  /** Подробная информация о водителе */
  public driver: IDriver;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Текст сообщения об ошибке */
  public error: string;

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Заголовок окна */
  public title: string;

  /** Фейковые права доступа для управления произвольными полями водителя */
  // tslint:disable-next-line:no-bitwise
  public readonly rights = RightType.VIEW_ARBITRARY_FIELDS | RightType.CHANGE_ARBITRARY_FIELDS;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для работы с ДИУП
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private loadingService: LoadingService
  ) {
    super(dialogService);
    this.driver = {} as IDriver;

    this.title = 'component.drivers.edit.title-create';

    this.tabs[Tabs.Main] = new DetailTab('component.drivers.edit.main');
    this.tabs[Tabs.Arbitrary] = new DetailTab('component.drivers.edit.arbitrary');

    this.selectedTab = this.tabs[Tabs.Main];
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: IDriversEditComponent) {
    if (data.driverId && data.driverId !== '') {
      this.title = 'component.drivers.edit.title-edit';

      this.crudService.get(data.driverId, CRUDEntityType.DRIVER).subscribe(
        (driver) => this.driver = driver,
        (error) => this.error = error
      );
    }

    return super.fillData(data);
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Проверка на соответствие активной вкладке
   * @param n Индекс проверяемой вкладки
   */
  public selectedTabIs(n: Tabs) {
    return this.selectedTab === this.tabs[n];
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSaveDriver) { return; }

    this.loadingService.wrap(this.crudService.addUpdate(this.driver, CRUDEntityType.DRIVER)).subscribe(() => {
      this.result = true;
      this.close();
    });
  }

  /**
   * Получение признака на возможность сохранения водителя
   */
  public get isCanSaveDriver() {
    return this.driver && this.driver.name && this.driver.name !== '';
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Arbitrary
}
