import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dateFormat from 'dateformat';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { IClientDogovor } from '../../../classes/IClientDogovor';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { AccountComponent } from '../../account/account.component';
import { ModalResult } from '../../modal/modal.component';

import { DogovorsEditComponent } from './edit/dogovors.edit.component';

/**
 * Компонент для отображения списка договоров
 */
@Component({
  selector: 'dogovors',
  templateUrl: './dogovors.component.html',
  styleUrls: ['./dogovors.component.scss']
})
export class DogovorsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClientDogovor[];
  /**
   * Выбранный элемент
   */
  public selected: IClientDogovor;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Текст ошибки, если есть
   */
  public error: string;

  /**
   * Конструктор
   * @param crudService Сервис для работы с CRUD
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   * @param modalService Сервис модальных окон
   * @param translator Сервис для перевода
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'client.name', name: 'ui.account' },
      { id: 'dogNo', name: 'ui.number' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление новой записи
   */
  public addItem() {
    this.dialogService.addDialog(DogovorsEditComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной записи
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { dogovorId: id };
    this.dialogService.addDialog(DogovorsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранной записи
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = {
      dogovorId: id,
      copy: true
    };
    this.dialogService.addDialog(DogovorsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранной записи
   */
  public deleteSelected(id: string, dogNo: string, dogDate: Date) {
    if (!id) {
      return;
    }
    console.log(dogNo)
    const date = dateFormat(dogDate, this.translator.instant('formats.date'));
    const confirm = this.translator.instant('component.crm.contracts.confirm', { no: dogNo, date });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.CONTRACT), true)))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о клиенте,
   * с которым заключен договор
   * @param clientId Идентификатор клиента
   */
  public showClient(clientId: string) {
    const data = { accountId: clientId };
    this.dialogService.addDialog(AccountComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка данных компонента
   */
  protected loadData = () => {
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.CONTRACT), true)
      .subscribe((dogovors) => this.items = dogovors?.map((d) => ({
        ...d,
        dogDateString: dateFormat(d.dogDate, this.translator.instant('formats.date'))
      } as IClientDogovor)));
  };
}
