import { Component, Input } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IBalanceNotificationSettings } from '../../../../shared/crm/clients/IBalanceNotificationSettings';
import { IListItem } from '../../../classes/IListItem';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для настройки отправки уведомлений об окончании средств на счете
 * (по учетной записи)
 */
@Component({
  selector: 'balance-notification-settings',
  templateUrl: './balance-notification-settings.component.html',
  styleUrls: ['./balance-notification-settings.component.scss']
})
export class BalanceNotificationSettingsComponent {

  /** Данные настроек */
  @Input() public settings: IBalanceNotificationSettings<string>;

  /** Словарь имен доступных для выбора пользователей */
  @Input() public userNames: { [key: string]: string };

  /** Список доступных для выбора пользователей */
  @Input() public users: IListItem<string>[];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(private dialogService: DialogService) {
  }

  /**
   * Выбор пользователей из списка
   */
  public selectUsers() {
    if (!this.users) {
      this.users = Object.keys(this.userNames).map((key) => ({ id: key, name: this.userNames[key] }))
        ?.sort(localeSort);
    }

    const data = {
      items: this.users,
      selected: this.users.filter((user) => this.settings.users.includes(user.id)),
      title: 'component.client.balance-notification-settings.select-user-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.settings.users = result
        ?.sort(localeSort)
        ?.map((u) => u.id));
  }

  /**
   * Удаление пользователя из списка для рассылки онлайн уведомлений
   * @param userId Идентификатор пользовтаеля
   */
  public removeUser(userId: string) {
    const index = this.settings.users.indexOf(userId);
    if (index !== -1) {
      this.settings.users.splice(index, 1);
    }
  }

  /**
   * Добавление Email
   */
  public addEmail() {
    this.settings.emails = this.settings.emails ?? [];
    this.settings.emails.push('');
  }

  /**
   * Удаление Email
   * @param index Индекс удаляемого Email в списке
   */
  public removeEmail(index: number) {
    if (index !== -1) {
      this.settings.emails.splice(index, 1);
    }
  }

  /**
   * Это нужно чтобы нормально работал *ngFor для списка Email
   */
  public trackByIndex(index: number) {
    return index;
  }

  /**
   * Изменение признака отправки онлайн уведомлений пользователям
   */
  public sendUsersChange() {
    if (!this.settings.users) {
      this.settings.users = [];
    } else {
      this.settings.users = null;
    }
  }

  /**
   * Изменение признака отправки Email
   */
  public sendEmailsChange() {
    if (!this.settings.emails) {
      this.settings.emails = [];
    } else {
      this.settings.emails = null;
    }
  }
}
