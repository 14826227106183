import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { CommandParamType, CommandTypesService, ICommandType } from '../../../services/command-types.service';
import { CommandsService, ICommand, ICommandParam } from '../../../services/commands.service';
import { ToastService } from '../../../services/toast.service';

/**
 * Компонент для отправки команды
 */
@Component({
  selector: 'app-send-command',
  templateUrl: './send-command.component.html'
})
export class SendCommandComponent implements OnDestroy {

  /**
   * Признак отображения окна отправки команды
   */
  public show: boolean;
  /**
   * Тип команды
   */
  public type: ICommandType;
  /**
   * Список типов команд
   */
  public types: ICommandType[];
  /**
   * Тип параметра команды
   */
  public pType = CommandParamType;
  /**
   * Идентификатор объекта мониторинга
   */
  private oid: string;
  /**
   * Подписка на сабж с идентификатором объекта мониторинга
   */
  private readonly oidSubscription: Subscription;

  /**
   * Конструктор
   * @param commandsService Сервис для работы с командами
   * @param commandTypesService Сервис для работы с типами команд
   * @param toastService Сервис всплывающих сообщений
   */
  constructor(
    private commandsService: CommandsService,
    private commandTypesService: CommandTypesService,
    private toastService: ToastService
  ) {
    this.oidSubscription = this.commandsService.oidSubject.subscribe(this.onChange);
  }

  /**
   * Получение списка параметров с значениями
   */
  public get params() {
    return this.type?.params as ICommandParam[]
  }

  public ngOnDestroy(): void {
    if (this.oidSubscription) this.oidSubscription.unsubscribe();
  }

  /**
   * Обработка изменений данных компонента
   * @param oid Идентификатор объекта мониторинга
   */
  public onChange = (oid: string): void => {
    if (!oid) return;

    this.clean();
    this.oid = oid;
    this.commandTypesService.list(oid)
      .pipe(
        tap((types) => {
          if (!types?.length) this.toastService.warn('component.command.warn-no-types')
        }),
        filter((types) => !!types?.length))
      .subscribe((types) => {
        this.types = types;
        this.show = true;
      }, () => this.toastService.error('component.command.error-get-types'));
  };

  /**
   * Выбор типа команды
   */
  public onSelect($event: any) {
    this.commandTypesService.get($event.value.id)
      .pipe(filter((type) => !!type))
      .subscribe((type) => this.type = type,
        (error) => this.toastService.error(error || 'component.unit.command.error-get-type'));
  }

  /**
   * Действие при подтверждении отправки команды
   */
  public confirm() {
    this.params?.forEach(this.validate);

    if (this.params.some((p) => p.error)) {
      return;
    }

    const command: ICommand = {
      typeId: this.type.id,
      unitId: this.oid,
      params: this.type.params
    };

    this.commandsService.add(command)
      .pipe(tap(() => this.commandsService.commandSentSubject.next()))
      .subscribe(() => this.toastService.success('component.command.send-success'),
        () => this.toastService.error('component.command.error-send-command'));

    this.clean();
  }

  /**
   * Очистка состояния компонента
   */
  public clean() {
    this.show = false;
    this.type = null;
    this.types = null;
    this.oid = null;
  }

  /**
   * Валидация параметра
   * @param p Параметр
   */
  private validate = (p) => {
    if (p.required && (p.value == null || p.value === '')) {
      p.error = 'component.command.error-param-required';
    }

    switch (p.type) {
      case CommandParamType.STRING:
        const str = p.value;

        if (str != null && p.mask != null && !str.match(p.mask)) {
          p.error = 'component.command.error-param-mask-mismatch';
        }

        break;

      case CommandParamType.NUMBER:
        const num = p.value;
        p.value = Number.isNaN(num) || num === '' || num == null ? null : +num;

        if (num != null && p.min != null && num < p.min) {
          p.error = 'component.command.error-param-min';
        }

        if (num != null && p.max != null && num > p.max) {
          p.error = 'component.command.error-param-max';
        }
    }
  };
}
