import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Chart, Options } from 'highcharts';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { timer } from 'rxjs';
import * as _ from 'underscore';

import { ICalcTableItem } from '../../../shared/sensors/ICalcTableItem';
import { ICalibrationTableItem } from '../../../shared/sensors/ICalibrationTableItem';
import { ISensor } from '../../../shared/sensors/ISensor';
import { ISensorBase } from '../../../shared/sensors/ISensorBase';
import { ISensorInterval } from '../../../shared/sensors/ISensorInterval';
import { getAllSensorTypes, getSensorTypeName, SensorType } from '../../../shared/sensors/SensorType';
import {
  getAllValidationTypes,
  getValidationTypeName,
  ValidationType
} from '../../../shared/sensors/ValidationType';
import { ChartManager } from '../../classes/ChartManager';
import { DetailTab } from '../../classes/DetailTab';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { PointsService } from '../../services/points.service';

/**
 * Интерфейс для компонента редактирования датчика
 */
export interface ISensorComponent {
  /** Идентификатор датчика */
  sensorId: string;

  /** Идентификатор объекта мониторинга */
  unitId: string;

  /** Список всех датчиков */
  sensors: ISensorBase[];

  /** Признак только для чтения */
  isReadOnly: boolean;
}

/**
 * Компонент редактирования датчика
 */
@Component({
  selector: 'sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent
extends DialogComponent<ISensorComponent, boolean>
implements ISensorComponent {

  /** Идентификатор датчика */
  public sensorId: string;

  /** Идентификатор объекта мониторинга */
  public unitId: string;

  /** Список всех датчиков */
  public sensors: ISensorBase[];

  /** Признак только для чтения */
  public isReadOnly: boolean;

  /** Датчик */
  public sensor: ISensor;

  /** Заголовок окна */
  public title: string;

  /** Типы датчиков */
  public types: IListItem<SensorType>[] = [];

  /** Типы валидации */
  public validationTypes: IListItem<ValidationType>[] = [];

  /** Текст ошибки */
  public error: string;

  /** Вкладки */
  public tabs: DetailTab[] = [];

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Текст ошибки, возникшей при генерации таблицы расчета */
  public calcTableGenerateError: string;

  /** Параметры графика */
  public chartOptions: Options;

  /** Список параметров точки */
  public params: string[];

  /** Объект графика */
  private highchart: Chart;

  /**
   * Конструктор
   * @param dialogService Сервис для работы с диалоговыми окнами
   * @param crudService Сервис для работы с ДИУП
   * @param pointsService Сервис для работы с точками
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private pointsService: PointsService
  ) {
    super(dialogService);

    this.sensor = { } as ISensor;
    this.sensors = [];

    this.types = getAllSensorTypes()
      .map((type) => ({ id: type, name: getSensorTypeName(type) }));

    this.validationTypes = getAllValidationTypes()
      .map((type) => ({ id: type, name: getValidationTypeName(type) }));

    this.title = 'component.sensor.title-add';

    this.tabs[Tabs.Main] = new DetailTab('component.sensor.main');
    this.tabs[Tabs.Intervals] = new DetailTab('component.sensor.intervals');
    this.tabs[Tabs.Calc] = new DetailTab('component.sensor.calc');
    this.tabs[Tabs.Graph] = new DetailTab('component.sensor.graph');

    this.selectedTab = this.tabs[Tabs.Main];

    this.chartOptions = {
      chart: { renderTo : 'sensorChartContainer', height: 500, width: 790 },
      title: { text: null },
      credits: { enabled: false },
      legend: { enabled: false },
      yAxis: [{ title: { text: null } }],
      xAxis: [{
        gridLineWidth: 1,
        plotLines: [{ color: '#E6E6E6', value: 0, width: 1 }]
      }],
      tooltip: {
        pointFormat: '<span>x: <b>{point.x}</b> y: <b>{point.y}</b></span>',
        headerFormat: null
      },
      series: []
    };
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: ISensorComponent) {
    if (!data.sensorId) { // Новый датчик
      // Настройка для нового датчика
      this.sensor.calcTable = [];
      this.sensor.intervals = [];
      this.sensor.unitId = data.unitId;
      this.sensor.isVisible = true;
      this.sensor.validatorId = null; // Надо, чтобы норм работал комбо-бокс с датчиками-валидаторами
    } else { // Существующий датчик
      if (data.isReadOnly) {
        this.title = 'component.sensor.title-show';
      } else {
        this.title = 'component.sensor.title-edit';
      }

      // Удаляем из списка датчиков-валидаторов
      const index = data.sensors.findIndex((s) => s.id === data.sensorId);
      data.sensors.splice(index, 1);
      // Получаем подробную информацию по датчику
      this.crudService.get(
        data.sensorId, CRUDEntityType.SENSOR
      ).subscribe(
        (sensor) => this.sensor = sensor,
        (error) => this.error = error
      );
    }

    this.pointsService.getParams(data.unitId).subscribe(
      (params) => this.params = params,
      (error) => this.error = error);
    return super.fillData(data);
  }

  /**
   * Обработка изменения выбранного валидатора
   */
  public onValidatorChanged() {
    if (!this.sensor.validatorId) {
      this.sensor.validationType = null;
    }
  }

  /**
   * Изменение выбранной вкладки
   * @param tab Новая вкладка
   */
  public selectTab(tab: DetailTab) {
    if (tab === this.tabs[Tabs.Graph]) {
      ChartManager.setHighchartsGlobalOptions();
      const chartData = [];
      this.chartOptions.series = [];

      if (this.sensor.calcTable && this.sensor.calcTable.length > 1) {
        const sorted = _.sortBy(this.sensor.calcTable, (item) => +item.x);
        const firstItem = _.first(sorted);
        const lastItem = _.last(sorted);
        const firstValue = +firstItem.x - ((+lastItem.x - +firstItem.x) / 2) || 100;
        const lastValue = +lastItem.x + ((+lastItem.x - +firstItem.x) / 2) || 100;

        chartData.push([
          firstValue,
          +firstItem.a * firstValue + +firstItem.b
        ]);

        for (let i = 0; i < sorted.length - 1; i++) {
          const currentItem = sorted[i];
          const nextItem = sorted[i + 1];

          chartData.push([
            +currentItem.x,
            +currentItem.a * +currentItem.x + +currentItem.b
          ]);

          chartData.push([
            +nextItem.x,
            +currentItem.a * +nextItem.x + +currentItem.b
          ]);

          if (nextItem === lastItem) {
            chartData.push([
              +nextItem.x,
              +nextItem.a * +nextItem.x + +nextItem.b
            ]);
          }
        }

        chartData.push([
          lastValue,
          +lastItem.a * lastValue + +lastItem.b
        ]);

        this.chartOptions.series.push({
          type: 'line',
          data: chartData
        });
      }

      if (this.highchart) {
        this.highchart.destroy();
      }

      timer(1).subscribe(() => this.highchart = new Highcharts.Chart(this.chartOptions));

    } else if (tab === this.tabs[Tabs.Intervals]) {
      if (!this.sensor.intervals) {
        this.sensor.intervals = [];
      }
    }

    this.selectedTab = tab;
  }

  /**
   * Проверка на соответствие активной вкладке
   * @param n Индекс проверяемой вкладки
   */
  public selectedTabIs(n: Tabs) {
    return this.selectedTab === this.tabs[n];
  }

  /**
   * Запуск генератора таблицы расчёта
   */
  public startCalibrationTableGeneration() {
    this.sensor.calibrationTable = [];
    this.addCalibrationTableItem();
  }

  /**
   * Генерация таблицы расчёта
   */
  public generateCalcTable() {
    if (!this.sensor.calibrationTable) { return; }

    this.calcTableGenerateError = null;
    try {
      if (this.sensor.calibrationTable.length < 2) {
        this.calcTableGenerateError = 'component.sensor.error-1';
      } else {
        this.sensor.calcTable = [];
        for (let i = 1; i < this.sensor.calibrationTable.length; i++) {
          const currentItem = this.sensor.calibrationTable[i];
          const prevItem = this.sensor.calibrationTable[i - 1];
          const a = (currentItem.y - prevItem.y) / (currentItem.x - prevItem.x);
          const b = (currentItem.x * prevItem.y - prevItem.x * currentItem.y) / (currentItem.x - prevItem.x);
          this.sensor.calcTable.push({ a, x: prevItem.x, b });
        }
      }
    } catch (error) {
      this.calcTableGenerateError = error.message || error;
    }
  }

  /**
   * Очистка тарировочной таблицы расчёта
   */
  public clearCalibrationTable() {
    this.calcTableGenerateError = null;
    this.sensor.calibrationTable = null;
  }

  /**
   * Добавление элемента таблицы расчёта
   */
  public addCalcTableItem() {
    if (!this.sensor.calcTable) {
      this.sensor.calcTable = [];
    }

    this.sensor.calcTable.push({ a: 0, x: 0, b: 0 });
  }

  /**
   * Удаление элемента таблицы расчёта
   * @param item Удаляемый элемент
   */
  public deleteCalcTableItem(item: ICalcTableItem) {
    if (this.sensor.calcTable) {
      const index = this.sensor.calcTable.indexOf(item);
      if (index >= 0) {
        this.sensor.calcTable.splice(index, 1);
      }
    }
  }

  /**
   * Добавление элемента таблицы генератора расчёта
   */
  public addCalibrationTableItem() {
    if (!this.sensor.calibrationTable) {
      this.sensor.calibrationTable = [];
    }

    this.calcTableGenerateError = null;
    this.sensor.calibrationTable.push({ x: 0, y: 0 });
  }

  /**
   * Удаление элемента таблицы генератора расчёта
   * @param item Удаляемый элемент
   */
  public deleteCalibrationTableItem(item: ICalibrationTableItem) {
    if (this.sensor.calibrationTable) {
      const index = this.sensor.calibrationTable.indexOf(item);
      if (index >= 0) {
        this.sensor.calibrationTable.splice(index, 1);
      }
    }
  }

  /**
   * Метод, не позволяющий вводить текст в блок для вставки данных тарировочной таблицы
   * @param e Аргумент события клавиатуры
   */
  public pasteCalibrationTableFromClipboardGuad = (e: KeyboardEvent) => {
    if (!(e.ctrlKey && (e.which === 118 || e.which === 86)) && !(e.shiftKey && e.which === 45)) {
      e.preventDefault();
    }
  }

  /**
   * Обработка события вставки данных тарировочной таблицы
   * @param e Аргумент события вставки
   */
  public pasteCalibrationTableFromClipboard = (e: Event) => {
    this.calcTableGenerateError = null;
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = (e as any).clipboardData || (window as any).clipboardData;
    if (!clipboardData) {
      this.calcTableGenerateError = 'component.sensor.error-2';
      return;
    }
    const pastedData = clipboardData.getData('Text');
    if (typeof pastedData !== 'string' && !(pastedData instanceof String)) {
      this.calcTableGenerateError = 'component.sensor.error-3';
      return;
    }
    const str = pastedData as string;
    if (str.startsWith('[')) {
      const parsedCtd = JSON.parse(str) as ICalibrationTableItem[];
      const cti: ICalibrationTableItem[] = [];
      for (const item of parsedCtd) {
        if (item.x == null || item.y == null) {
          this.calcTableGenerateError = 'component.sensor.error-3';
          return;
        }

        const x = +item.x;
        const y = +item.y;
        if (isNaN(x) || !isFinite(x) || isNaN(y) || !isFinite(y)) {
          this.calcTableGenerateError = 'component.sensor.error-3';
          return;
        }
        cti.push({ x, y });
      }
      this.sensor.calibrationTable = cti;
    } else {
      const splitted = str.split(',');
      const cti: ICalibrationTableItem[] = [];
      if (splitted.length % 2 !== 0) {
        this.calcTableGenerateError = 'component.sensor.error-3';
        return;
      }
      for (let i = 0, l = splitted.length / 2; i < l; i++) {
        const x = +splitted[i * 2];
        const y = +splitted[i * 2 + 1];
        if (isNaN(x) || !isFinite(x) || isNaN(y) || !isFinite(y)) {
          this.calcTableGenerateError = 'component.sensor.error-3';
          return;
        }
        cti.push({ x, y });
      }
      this.sensor.calibrationTable = cti;
    }
  }

  /**
   * Добавление интервала
   */
  public addInterval() {
    if (!this.sensor.intervals) {
      this.sensor.intervals = [];
    }
    this.sensor.intervals.push({
      from: null,
      color: '#000000',
      text: ''
    });
  }

  /**
   * Удаление интервала
   * @param interval Удаляемый интервал
   */
  public deleteInterval(interval: ISensorInterval) {
    if (this.sensor.intervals) {
      const index = this.sensor.intervals.indexOf(interval);
      if (index >= 0) {
        this.sensor.intervals.splice(index, 1);
      }
    }
  }

  /**
   * Возвращает признак видимости поля кода снятия датчика
   */
  public get denyCodeFieldVisible() {
    return this.sensor && this.sensor.type === SensorType.DRIVER_SET;
  }

  /**
   * Получение признака возможности сохранения
   */
  public get isCanSave(): boolean {
    return !!this.sensor.name && this.sensor.type && !!this.sensor.param;
  }

  /**
   * Подтверждение редактирования датчика
   */
  public confirm() {
    if (this.isReadOnly) {
      this.close();
      return;
    }

    this.crudService.addUpdate(
      this.sensor, CRUDEntityType.SENSOR
    ).subscribe(
      () => {
        // Если получили идентификатор датчика, то значит все норм
        this.result = true;
        this.close();
      },
      (error) => this.error = error
    );
}

  /**
   * Добавление названия параметра в формулу
   * @param param Параметр
   */
  public addParam(param: string) {
    if (!this.sensor.param) {
      this.sensor.param = '';
    }
    this.sensor.param += param;
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Intervals,
  Calc,
  Graph
}
