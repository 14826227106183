import { Component, Input, OnInit } from '@angular/core';

import { IIcon } from '../../../../shared/units/IUnitGeneric';
import { UnitsService } from '../../../services/units.service';

/**
 * Компонент для иконок объекта мониторинга
 */
@Component({
  selector: 'unit-icon',
  templateUrl: './unit.icon.component.html',
  styleUrls: ['./unit.icon.component.scss']
})
export class UnitIconComponent implements OnInit {

  /** Объект мониторинга */
  @Input() public icon: IIcon;

  /** Список доступных вкладок */
  public categories: ICategory[] = [];

  /** Выбранная вкладка */
  public category?: ICategory;

  /** Список иконок */
  public icons: string[] = [];

  /** Текст ошибки */
  public error: string;

  /**
   * Конструктор
   * @param unitsService Сервис для работы с объектами мониторинга
   */
  constructor(private unitsService: UnitsService) {
    this.category = {name: 'component.unit.icon.default'};
    this.categories.push(this.category);
    this.unitsService.getIconDirs().subscribe(
      (dirs) => this.categories.push(...dirs.map((x) => ({
        name: `component.unit.icon.${x}`,
        directory: `${x}/`
      }))),
      (error) => this.error = error);
    this.getIcons();
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (!this.icon) {
      this.icon = {path: 'default.png', rotate: true};
    }
  }

  /**
   * Выбор категории
   * @param cat Категория
   */
  public selectCat(cat: ICategory) {
    this.category = cat;
    this.getIcons();
  }

  /**
   * Выбор иконки
   * @param path Иконка
   */
  public selectIcon(path: string) {
    this.icon.path = path;
  }

  /**
   * Получение иконок выбранной категории
   */
  private getIcons() {
    const dir = this.category.directory;
    this.unitsService.getIcons(dir)
      .subscribe(
        (icons) => this.icons = icons.map((x) => (dir || '') + x),
        (error) => this.error = error);
  }
}

/**
 * Категория иконок
 */
interface ICategory {

  /**
   * Название
   */
  name: string;

  /**
   * Каталог
   */
  directory?: string;
}
