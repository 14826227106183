import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ModalService } from './modal.service';

/**
 * Сервис для отображения процесса загрузки
 */
@Injectable()
export class LoadingService {

  /**
   * Заголовок no-waiter
   */
  public waiterHeader = new HttpHeaders({ 'waiter': 'true' });

  /**
   * Количество загружемых элементов
   */
  private cnt = 0;

  /**
   * Конструктор
   * @param modalService Сервис модальных окон
   */
  constructor(private modalService: ModalService) {}

  /**
   * Получение признака загрузки
   */
  public get isLoading() {
    return this.cnt > 0;
  }

  /**
   * Начало загрузки
   */
  public startLoading() {
    this.cnt++;
  }

  /**
   * Окончание загрузки
   */
  public stopLoading() {
    if (this.cnt > 0) {
      this.cnt--;
    }
  }

  /**
   * Выполнение асинхронной операции с отображением процесса загрузки
   * @param observable Выполняемая операция
   * @param onSuccess Обработка при успешном выполнении операции
   * @param onError Обработка при ошибке
   * @param stopLoadingBeforeOnSuccess Признак скрытия панели загрузки до того как
   * выполнится обработка при успешном выполнении (по умолчанию она скрывается после)
   */
  public withLoading<T>(
    observable: Observable<T>,
    onSuccess: (data: T) => void,
    onError?: (error: any) => void,
    stopLoadingBeforeOnSuccess?: boolean
  ) {
    let loadingStopped = false;
    this.startLoading();
    observable.subscribe(
      (data) => {
        if (stopLoadingBeforeOnSuccess) {
          loadingStopped = true;
          this.stopLoading();
        }
        onSuccess(data);
        if (!stopLoadingBeforeOnSuccess) {
          loadingStopped = true;
          this.stopLoading();
        }
      },
      (error) => {
        loadingStopped = true;
        this.stopLoading();
        if (!onError) {
          this.modalService.showError(error);
        } else {
          onError(error);
        }
      },
      () => {
        if (!loadingStopped) {
          this.stopLoading();
        }
      }
    );
  }

  /**
   * Обертка над последовательностью действий,
   * которая добавляет отображение процесса загрузки
   * на время выполнения действий
   * @param observable Выполняемая последовательность действий
   * @param suppressError Признак того, что необходимо подавить ошибку
   * (ошибка выводится в диалоговом окне)
   */
  public wrap<T>(observable: Observable<T>, suppressError?: boolean): Observable<T> {
    this.startLoading();
    let loadingStopped = false;

    let result = observable.pipe(tap({
      next: () => {
        this.stopLoading();
        loadingStopped = true;
      },
      error: () => {
        this.stopLoading();
        loadingStopped = true;
      },
      complete: () => {
        if (!loadingStopped) {
          // Актуально, когда перехватчик HTTP-запросов
          // получает 401, или 403 и возвращает Observable.empty
          this.stopLoading();
        }
      }
    }));

    if (suppressError) {
      result = result.pipe(catchError((error) => {
        this.modalService.showError(error);
        return EMPTY;
      }));
    }

    return result;
  }
}
