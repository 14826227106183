import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ICoord } from '../../shared/ICoord';
import { ISimpleGroup, ISimpleObject } from '../../shared/ISimpleObject';
import { IReportListItem } from '../../shared/reports/IReportListItem';
import { IMapReportInterval } from '../components/map/map.component';
import { IMapReportDetails } from '../components/map/report-detail/map.report-detail.component';
import { localeSort } from '../utils/sort';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { IReportNotification } from './online-notifications.service';
import { ReportType } from './report-templates.service';
import { StoreService } from './store.service';

/**
 * Сервис для работы с отчетами
 */
@Injectable()
export class ReportsService {

  /**
   * Событие очистки информации по отчету
   */
  public clearReportInfoSubject = new Subject<void>();

  /**
   * Событие выбора элемента отчета
   */
  public selectElementSubject = new Subject<IReportElement>();

  /**
   * Событие отображения рейсов на карте
   */
  public showRaceOnMapSubject = new Subject<void>();

  /**
   * Событие отображения интервала на карте
   */
  public showIntervalsOnMapSubject = new Subject<IMapReportInterval[]>();

  /**
   * Событие скрытия интервала с карты
   */
  public hideIntervalsFromMapSubject = new Subject<string[]>();

  /**
   * Событие отображения точки на карте
   */
  public showPointOnMapSubject = new Subject<IMapReportDetails>();

  /**
   * Событие изменения размера графика
   */
  public chatReflowSubject = new Subject<void>();

  /**
   * Event of building new report
   */
  public createReportSubject = new Subject<void>();

  /**
   * Запрос на создание отчёта
   */
  public reportRequest: ICreateReportRequest;

  /**
   * Информация по отчёту
   */
  public reportInfo: IReportInfo;

  /**
   * Рейсы по отчёту
   */
  public reportRaces: IReportRace[];

  /**
   * Выбранный элемент отчёта
   */
  public selectedElement: IReportElement;

  /**
   * Список пользовательских групп
   */
  public customGroups: ITypedGroup[] = [];

  /**
   * Queue items
   */
  public queueItems: IReportListItem[] = [];

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/reports`;

  /**
   * Конструктор
   * @param configService Сервис конфигурации
   * @param http HTTP клиент
   * @param loadingService Сервис для отображения процесса загрузки
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    public configService: ConfigService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private store: StoreService
  ) {
    this.initRequest();
  }

  /**
   * Получаем отчёт
   * @param id
   */
  public getReadyReport(id: string): Observable<IReportInfo> {
    const url = `${this.baseUrl}/${id}/`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IReportInfo>(url, { headers }).pipe(tap(this.onReportLoaded));
  }

  /**
   * Отправка запроса на построение отчёта.
   * Возвращает информацию по созданному отчёту.
   * @param request Данные для запроса на генерацию отчёта
   */
  public buildReport(request: ICreateReportRequest): Observable<string> {
    this.clearReport();
    this.reportRequest = request;
    return this.http.post<string>(this.baseUrl, this.reportRequest);
  }

  /**
   * Request of user`s reports list
   */
  public getReportList(): Observable<IReportListItem[]> {
    return this.http.get<IReportListItem[]>(this.baseUrl);
  }

  /**
   * Отправка запроса на удаление отчёта.
   */
  public deleteReport(): Observable<string> {
    const url = `${this.baseUrl}/${this.reportInfo.id}`;
    return this.http.delete<string>(url);
  }

  /**
   * Отправка запроса на удаление отчёта по иднетификатору.
   * @param id
   */
  public deleteReportById(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<string>(url);
  }

  /**
   * Clear list of reports
   */
  public deleteReportList(): Observable<any> {
    return this.http.delete<string>(this.baseUrl);
  }

  /**
   * Delete reports from queue list
   * @param id
   */
  public deleteFromLists(id: string) {
    this.queueItems = this.queueItems?.filter((item) => item?.id !== id)
    if (this.reportInfo?.id === id) {
      this.clearReport()
    }
  }

  /**
   * Очистка очереди
   */
  public clearQueue() {
    this.queueItems = [];
  }

  /**
   * Отправка запроса на получение рейсов по отчёту
   */
  public getRaces(): Observable<IReportRace[]> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/races`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IReportRace[]>(url, { headers });
  }

  /**
   * Отправка запроса на получение графика отчёта
   * @param chartId Идентификатор графика
   */
  public getChart(chartId: string): Observable<IReportChartInfo> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/charts`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams().append('id', chartId);
    return this.http.get<IReportChartInfo>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение таблицы отчёта
   * @param tableId Идентификатор таблицы
   */
  public getTable(tableId: string): Observable<IReportTableInfo> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/tables`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams().append('tableId', tableId);
    return this.http.get<IReportTableInfo>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение строк таблицы отчёта
   * @param tableId Идентификатор таблицы
   * @param offset Стартовая строка
   * @param limit Количество строк
   * @param row Идентификатор строки (для групп)
   */
  public getRows(tableId: string, offset: number, limit: number, row?: string): Observable<IReportRowsInfo> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/rows`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams()
      .append('tableId', tableId)
      .append('offset', `${offset}`)
      .append('limit', `${limit}`)
      .append('row', row);
    return this.http.get<IReportRowsInfo>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение интервалов для отображения или скрытия
   * @param tableId Идентификатор таблицы
   * @param id Идентификатор строки
   * @param state Признак отображения
   */
  public getIntervals(tableId: string, id: string, state: boolean): Observable<GetIntervalsResponse> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/intervals`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams()
      .append('tableId', tableId)
      .append('id', id)
      .append('state', `${state}`);
    return this.http.get<GetIntervalsResponse>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение интервалов для отображения или скрытия по всей таблице
   * @param tableId Идентификатор таблицы
   * @param state Признак отображения
   */
  public getAllIntervals(tableId: string, state: boolean): Observable<GetIntervalsResponse> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/intervals`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams()
      .append('tableId', tableId)
      .append('state', `${state}`);
    return this.http.get<GetIntervalsResponse>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение точки рейса отчёта
   * @param objectId Идентификатор объекта
   * @param value Значение в точке
   * @param type Тип графика
   */
  public getPoint(objectId: string, value: number, type?: ReportChartType): Observable<IReportPointInfo> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/points`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams()
      .append('objectId', `${objectId}`)
      .append('value', `${value}`)
      .append('type', `${type}`);
    return this.http.get<IReportPointInfo>(url, { headers, params });
  }

  /**
   * Отправка запроса на получение документа
   * @param format
   * @param items
   */
  public getFile(format: ReportDocumentType, items: string[]): Observable<void> {
    const url = `${this.baseUrl}/${this.reportInfo.id}/docs`;

    const body: IGetFileBody = {
      format,
      items,
      uniqueId: this.configService?.uniqueId
    };

    const headers = this.loadingService.waiterHeader;
    return this.http.post<void>(url, body, { headers });
  }

  /**
   * Возвращает список пользовательских групп по типу
   * @param type Тип отчёта
   */
  public getCustomGroups(type: ReportType): ITypedGroup[] {
    return this.customGroups
      .filter((x) => x.type === type);
  }

  /**
   * Обработка выбора элемента отчёта
   * @param element выбранный элемент отчёта
   */
  public selectElement(element: IReportElement) {
    this.selectedElement = element;
    this.selectElementSubject.next(element);
  }

  /**
   * Обработка отображения точки на карте
   * @param point Информация по точке
   */
  public showPoint(point: IReportPointInfo) {
    const details: IMapReportDetails = {
      objectId: point.o,
      name: point.n,
      point,
      charts: point.c
    };
    this.showPointOnMapSubject.next(details);
  }

  /**
   * Обработка отображения интервалов на карте
   * @param intervals Список строк с точками
   */
  public showIntervals(intervals: IMapReportInterval[]) {
    this.showIntervalsOnMapSubject.next(intervals);
  }

  /**
   * Обработка отображения интервалов на карте
   * @param ids Список идентификаторов строк
   */
  public hideIntervals(ids: string[]) {
    this.hideIntervalsFromMapSubject.next(ids);
  }

  /**
   * Обработка изменения размеров графика
   */
  public chartReflow() {
    this.chatReflowSubject.next();
  }

  /**
   * Очистка информации по отчёту и переинициализация запроса
   */
  public fullClearReport() {
    this.initRequest();
    this.clearReport();
    this.customGroups = [];
  }

  /**
   * Очистка информации по отчёту
   */
  public clearReport() {
    if (this.reportInfo) {
      this.deleteReport();
    }
    this.reportInfo = null;
    this.reportRaces = null;
    this.selectedElement = null;
    this.selectElementSubject.next(null);
    this.clearReportInfoSubject.next();
  }

  /**
   * Sync report info with backend
   * @param rn report info from back
   */
  public updateQueueList(rn: IReportNotification) {
    this.loadQueueList();
  }

  public loadQueueList() {
    this.getReportList()
      .subscribe((items) => this.queueItems = items?.sort((a, b) => a.created - b.created));
  }

  /**
   * Инициализация данных запроса на генерацию отчёта
   */
  private initRequest() {
    const now = new Date();
    const begin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
    this.reportRequest = {
      begin,
      end,
      template: '',
      objects: [],
      name: ''
    };
  }

  /**
   * Действия при загрузке отчёта
   * @param reportInfo Информация по отчёту
   */
  private onReportLoaded = (reportInfo: IReportInfo) => {
    this.reportInfo = reportInfo;

    if (reportInfo.elements && reportInfo.elements.length) {
      this.selectElement(this.reportInfo.elements[0]);
    }

    if (reportInfo.showRaces) {
      this.getRaces().subscribe(
        (races: IReportRace[]) => {
          this.reportRaces = races;
          this.showRaceOnMapSubject.next();
        }, (error) => throwError(error));
    }
  }
}

interface IGetFileBody {
  format: ReportDocumentType;
  items: string[];
  uniqueId: any;
}

/**
 * Данные запроса на генерацию отчёта
 */
export interface ICreateReportRequest {
  /**
   * Начало отчётного периода
   */
  begin: number;
  /**
   * Конец отчётного периода
   */
  end: number;
  /**
   * Шаблон отчётов
   */
  template: string;
  /**
   * Название объекта
   */
  name: string;
  /**
   * Список объектов в отчёте
   * Если объектом является массив, то это массив элементов кастомной группы
   */
  objects: (string | string[])[];
  /**
   * Признак скрытия интервалов недвижения
   */
  hide?: boolean;
}

/**
 * Информация по отчёту
 */
export interface IReportInfo {
  /**
   * Идентификатор
   */
  id: string;
  /**
   * Начало отчётного периода
   */
  begin: number;
  /**
   * Конец отчётного периода
   */
  end: number;
  /**
   * Список элементов
   */
  elements: IReportElement[];
  /**
   * Список объектов
   */
  objectIds: string[];
  /**
   * Признак отображения рейсов на карте
   */
  showRaces?: boolean;
  /**
   * Признак отображения интервалов на карте
   */
  showIntervals?: boolean;
  /**
   * Признак путевого листа
   */
  waybill?: boolean;
  /**
   * Тип отчета
   */
  type: ReportType;
  /**
   * Наименование шаблона
   */
  tmpl_name: string
}

/**
 * Элемент отчёта
 */
export interface IReportElement {
  /**
   * Идентификатор
   */
  id: string;
  /**
   * Тип
   */
  type: ReportElementType;
  /**
   * Название
   */
  name: string;
}

/**
 * Рейс по отчёту
 */
export interface IReportRace {
  /**
   * Идентификатор ТС
   */
  i: string;
  /**
   * Название ТС
   */
  n: string;
  /**
   * Пробег
   */
  mi: number;
  /**
   * Моточасы
   */
  mh: number;
  /**
   * Точки
   */
  p: IReportPoint[];
  /**
   * Сегменты
   */
  s: IReportSegment[];
}

/**
 * Точка рейса
 */
export interface IReportPoint extends ICoord {
  /**
   * Время
   */
  t: number;
  /**
   * Скорость
   */
  s: number;
  /**
   * Азимут
   */
  b: number;
  /**
   * Пробег в точке
   */
  mi: number;
  /**
   * Моточасы в точке
   */
  mh: number;
  /**
   * Признак скрытия
   */
  h?: boolean;
}

/**
 * Сегмент рейса
 */
interface IReportSegment {
  /**
   * Начало
   */
  s: number;
  /**
   * Конец
   */
  e: number;
  /**
   * Цвет (hex, rgb16)
   */
  c: string;
  /**
   * Название
   */
  n?: string;
  /**
   * Признак наличия неточных данных
   */
  i?: boolean;
}

/**
 * Информация о графике
 */
export interface IReportChartInfo extends IReportElement {
  /**
   * Тип графика (по времени или по пробегу)
   */
  chartType: ReportChartType;
  /**
   * Заголовок оси Х
   */
  title: string;
  /**
   * Список осей Y
   */
  axes: IReportAxis[];
  /**
   * Список линий
   */
  lines: IReportLine[];
  /**
   * Данные точек
   */
  data: ReportPlot[];
  /**
   * Список заливок по оси X
   */
  bgs?: IColorInterval[];
}

/**
 * Свойства оси Y графика
 */
interface IReportAxis {
  /**
   * Заголовок оси
   */
  title: string;
  /**
   * Единицы измерения оси
   */
  measure: string;
  /**
   * Позиция оси
   */
  opposite?: boolean;
}

/**
 * Свойства линии графика
 */
interface IReportLine {
  /**
   * Название
   */
  name: string;
  /**
   * Цвет
   */
  color: string;
  /**
   * Признак ступенчатой линии
   */
  step?: boolean;
  /**
   * Тип линии
   */
  type: ReportLineType;
  /**
   * Номер оси Y
   */
  y: number;
  /**
   * Индекс значения
   */
  value: number;
  /**
   * Список отрезков с цветом линии
   */
  intervals: IInterval[];
}

/**
 * Интервал
 */
interface IInterval {
  /**
   * Начало
   */
  s: number;
  /**
   * Конец
   */
  e: number;
}

/**
 * Интервал с цветом
 */
interface IColorInterval extends IInterval {
  /**
   * Цвет (hex, rgb16)
   */
  c: string;
}

/**
 * Информация о таблице
 */
export interface IReportTableInfo extends IReportElement {
  /**
   * Список столбцов
   */
  columns: IReportColumn[];
  /**
   * Признак наличия строки с итогами
   */
  total?: boolean;
  /**
   * Признак отображения номеров строк
   */
  rowNums?: boolean;
  /**
   * Признак наличия группировки
   */
  grouping?: boolean;
  /**
   * Признак необходимости колонки для группировки
   */
  groupColumn?: boolean;
}

/**
 * Свойства столбца таблицы
 */
export interface IReportColumn {
  /**
   * Название
   */
  name: string;
  /**
   * Тип данных
   */
  type: ReportColumnType;
  /**
   * Итоговое значение
   */
  total?: number | string;
  /**
   * Причина пустой ячейки
   */
  whyEmpty?: string;
}

/**
 * Список строк с количеством
 */
export interface IReportRowsInfo {
  /**
   * Список строк
   */
  rows: IReportRow[];
  /**
   * Количество строк
   */
  count: number;
  /**
   * Максимальная вложенность
   */
  depth: number;
  /**
   * Состояние отображения интервалов по всем строкам
   */
  eye: boolean;
}

/**
 * Строка таблицы отчёта
 */
export interface IReportRow {
  /**
   * Идентификатор строки
   */
  i: string;
  /**
   * Идентификатор объекта
   */
  o: string;
  /**
   * Массив значений
   */
  c: number[] | string[];
  /**
   * Индексы точек, связанных со строкой
   */
  p: number[];
  /**
   * Уровень вложенности строки
   */
  l?: number;
  /**
   * Состояние группировки
   */
  g?: ReportRowGroupState;
  /**
   * Значение сгруппированного поля
   */
  gv?: number | string;
  /**
   * Признак отображения на карте
   */
  e?: boolean;
}

/**
 * Строка таблицы с точками рейса
 */
export interface IReportInterval {
  /**
   * Строка таблицы
   */
  r: IReportRow;
  /**
   * Список точек рейса
   */
  p: IReportPoint[];
  /**
   * Название ТС
   */
  n: string;
}

/**
 * Точка рейса с информацией о линиях графика
 */
export interface IReportPointInfo extends IReportPoint {
  /**
   * Идентификатор ТС
   */
  o: string;
  /**
   * Название ТС
   */
  n: string;
  /**
   * Информация о графиках в точке
   */
  c?: IPointChart[];
}

/**
 * Информация о графике в точке
 */
export interface IPointChart {
  /**
   * Название
   */
  name: string;
  /**
   * Список линий
   */
  lines: IPointLine[];
}

/**
 * Информация о линии графика в точке
 */
export interface IPointLine {
  /**
   * Название
   */
  name: string;
  /**
   * Метрика
   */
  measure: string;
  /**
   * Цвет
   */
  color: string;
  /**
   * Значение
   */
  value: number;
}

/**
 * Информация о таблице в точке
 */
export interface IPointTable {
  /**
   * Название
   */
  name: string;
  /**
   * Список столбцов
   */
  columns: IPointColumn[];
}

/**
 * Информация о столбцах таблицы в точке
 */
export interface IPointColumn {
  /**
   * Название
   */
  name: string;
  /**
   * Тип данных
   */
  type: ReportColumnType;
  /**
   * Значение
   */
  value?: string | number;
}

/**
 * Информация об объекте или группе с типом
 */
export interface ITypedGroup extends ISimpleGroup {
  /**
   * Тип отчёта
   */
  type: ReportType;
}

/**
 * Тип элемента отчёта
 */
export enum ReportElementType {
  /**
   * Таблица
   */
  TABLE = 1,
  /**
   * График
   */
  CHART
}

/**
 * Тип данных столбца
 */
export enum ReportColumnType {
  // noinspection JSUnusedGlobalSymbols
  /**
   * Число
   */
  NUMBER,
  /**
   * Строка
   */
  STRING,
  /**
   * Дата
   */
  DATE,
  /**
   * Время
   */
  TIME
}

/**
 * Состояние группировки строки
 */
export enum ReportRowGroupState {
  // noinspection JSUnusedGlobalSymbols
  /**
   * Без группировки
   */
  NO_GROUPING,
  /**
   * Свёрнуто
   */
  FOLDED,
  /**
   * Развёрнуто
   */
  UNFOLDED
}

/**
 * Тип графика
 */
export enum ReportChartType {
  // noinspection JSUnusedGlobalSymbols
  /**
   * По времени
   */
  TIME = 1,
  /**
   * По пробегу
   */
  MILEAGE
}

/**
 * Тип линии графика
 */
export enum ReportLineType {
  // noinspection JSUnusedGlobalSymbols
  /**
   * Цельная
   */
  SOLID,
  /**
   * Пунктирная
   */
  DASH
}

/**
 * Тип документа
 */
export enum ReportDocumentType {
  /**
   * HTML превью, для печати
   */
  HTML = 'html',
  /**
   * PDF файл
   */
  PDF = 'pdf',
  /**
   * Таблица эксель
   */
  XLSX = 'xlsx'
}

/**
 * Тип объектов для загрузки
 */
export enum ObjectType {
  /**
   * Объекты
   */
  Objects,
  /**
   * Группы
   */
  Groups,
  /**
   * Объекты и группы
   */
  Both
}

/**
 * Ответ на запрос на получение интервалов для отображения на карте
 */
export type GetIntervalsResponse = IReportInterval[] | string[];

/**
 * Массив точек графика, 1е число - значение по X, остальные - по Y
 */
type ReportPlot = number[];

/**
 * Объект или группа объектов
 */
export type ObjectOrGroup = ISimpleObject | ISimpleGroup;

/**
 * Проверяет является ли объект группой
 * @param oog Объект
 */
export function isGroup(oog: ObjectOrGroup): oog is ISimpleGroup {
  return !!oog && oog.hasOwnProperty('objects');
}

/**
 * Проверка на то что тип выбранного отчёта - групповой
 * @param type Тип отчёта
 */
export function isGroupReport(type: ReportType) {
  const groups = [
    ReportType.UNIT_GROUP,
    ReportType.DRIVER_GROUP,
    ReportType.TRAILER_GROUP
  ];
  return type && groups.includes(type);
}

/**
 * Лексикографическая сортировка
 * @param list Список объектов для сортировки
 */
export function sortLex<T extends { name: string }>(list: Array<T>): Array<T> {
  return list?.sort(localeSort);
}
