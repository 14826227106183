import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountsComponent } from './components/accounts/accounts.component';
import { AdminComponent } from './components/admin/admin.component';
import { AnalyticsComponent } from './components/analytics';
import { ClientComponent } from './components/client/client/client.component';
import { ClientFinancesComponent } from './components/client/finances/client.finances.component';
import { UnitsActivityComponent } from './components/client/units-activity/units-activity.component';
import { ClientsComponent } from './components/crm/clients/clients.component';
import { CrmComponent } from './components/crm/crm/crm.component';
import { DillerComponent } from './components/crm/diller/diller.component';
import { DillerDocsSettingsComponent } from './components/crm/diller/docs-settings/diller.docs-settings.component';
import { DocsComponent } from './components/crm/docs/docs.component';
import { DocsPacketsComponent } from './components/crm/docs/packets/docs.packets.component';
import { DocsSearchComponent } from './components/crm/docs/search/docs.search.component';
import { DogovorsComponent } from './components/crm/dogovors/dogovors.component';
import { EquipmentsComponent } from './components/crm/equipments/equipments.component';
import { RefsItemsComponent } from './components/crm/refs/items/refs.items.component';
import { RefsComponent } from './components/crm/refs/refs.component';
import { SIMCardsComponent } from './components/crm/sim-cards/sim-cards.component';
import { TariffsComponent } from './components/crm/tariffs/tariffs.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { GeozonesEditGroupComponent } from './components/geozones/edit-group/geozones.edit-group.component';
import { GeozonesEditComponent } from './components/geozones/edit/geozones.edit.component';
import { GeozonesComponent } from './components/geozones/geozones.component';
import { GuestAccessComponent } from './components/guest-access/guest-access.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { NotificationSettingsComponent } from './components/notifications/notification-settings/notification-settings.component';
import { SystemNotificationsComponent } from './components/notifications/system-notifications/system-notifications.component';
import { CreateGeozoneComponent } from './components/race/create-geozone/create-geozone.component';
import { RaceComponent } from './components/race/race.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { RegisterComponent } from './components/register/register.component';
import { ReportTemplatesComponent } from './components/report-templates/report-templates.component';
import { ReportComponent } from './components/report/report.component';
import { RightsGenComponent } from './components/rights-gen/rights-gen.component';
import { RoutesComponent } from './components/routes/routes.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { TrailersComponent } from './components/trailers/trailers.component';
import { UnitGroupsComponent } from './components/unit-groups/unit-groups.component';
import { UnitsComponent } from './components/units/units.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './services/auth-guard.service';
import { ClientGuard } from './services/client-guard.service';
import { DillerGuard } from './services/diller-guard.service';

/**
 * Список маршрутов приложения
 */
const routes: Routes = [
  { path: '', redirectTo: 'monitoring', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'redirect', component: RedirectComponent },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'tracking', pathMatch: 'full' },
      { path: 'tracking', component: TrackingComponent },
      {
        path: 'race',
        component: RaceComponent,
        children: [{ path: 'geozone-create', component: CreateGeozoneComponent }]
      },
      { path: 'geozones', component: GeozonesComponent },
      { path: 'geozones/add', component: GeozonesEditComponent },
      { path: 'geozones/:id', component: GeozonesEditComponent },
      { path: 'geozones-group/add', component: GeozonesEditGroupComponent },
      { path: 'geozones-group/:id', component: GeozonesEditGroupComponent },
      { path: 'reports', component: ReportComponent },
      { path: 'messages', component: MessagesComponent },
      { path: 'drivers', component: DriversComponent },
      { path: 'trailers', component: TrailersComponent },
      { path: 'analytics', component: AnalyticsComponent },
      { path: 'routes', component: RoutesComponent }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'report-templates', pathMatch: 'full' },
      { path: 'units', component: UnitsComponent },
      { path: 'unit-groups', component: UnitGroupsComponent },
      { path: 'report-templates', component: ReportTemplatesComponent },
      { path: 'notifications', component: NotificationSettingsComponent },
      { path: 'users', component: UsersComponent },
      { path: 'rights-gen', component: RightsGenComponent },
      { path: 'tasks', component: TasksComponent },
      { path: 'guest-access', component: GuestAccessComponent },
      {
        path: 'logistic',
        loadChildren: () => import('./modules/bg-logistic/bg-logistic.module').then(m => m.BgLogisticModule)
      }
    ]
  },
  {
    /**
     * Роуты для CRM
     */
    path: 'crm',
    component: CrmComponent,
    canActivate: [DillerGuard],
    children: [
      { path: '', redirectTo: 'contracts', pathMatch: 'full' },
      { path: 'docs-packets', component: DocsPacketsComponent },
      { path: 'docs-search', component: DocsSearchComponent },
      { path: 'accounts', component: AccountsComponent },
      { path: 'tariffs', component: TariffsComponent },
      { path: 'terminals', component: EquipmentsComponent },
      { path: 'optionals', component: EquipmentsComponent },
      { path: 'sim-cards', component: SIMCardsComponent },
      {
        path: 'contracts', component: DillerComponent, children: [
          { path: 'settings', component: DillerDocsSettingsComponent },
          { path: 'packets', component: DocsPacketsComponent },
          { path: '', component: DogovorsComponent },
          { path: 'search', component: DocsSearchComponent }
        ]
      },
      { path: 'docs/:id', component: DocsComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'system-notifications', component: SystemNotificationsComponent },
      {
        path: 'refs',
        component: RefsComponent,
        children: [
          { path: '', component: RefsItemsComponent },
          { path: ':id', component: RefsItemsComponent }
        ]
      },
      {
        path: 'repeaters',
        loadChildren: () => import('./modules/bg-repeaters/bg-repeaters.module').then(m => m.BgRepeatersModule)
      }
    ]
  },
  {
    path: 'client',
    component: ClientComponent,
    canActivate: [ClientGuard],
    children: [
      { path: '', redirectTo: 'finances', pathMatch: 'full' },
      { path: 'finances', component: ClientFinancesComponent },
      { path: 'units-activity', component: UnitsActivityComponent }
    ]
  },
  {
    path: 'guest',
    loadChildren: () => import('./modules/bg-guest-access/bg-guest-access.module').then(m => m.BgGuestAccessModule)
  },
  { path: '**', redirectTo: 'monitoring' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}
