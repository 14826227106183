import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ITime } from '../../../shared/time/ITime';
import { ITimeLimits } from '../../../shared/time/ITimeLimits';
import { IListItem } from '../../classes/IListItem';

/** Компонент для настройке ограничений по времени */
@Component({
  selector: 'time-limits',
  templateUrl: './time-limits.component.html',
  styleUrls: ['./time-limits.component.scss']
})
export class TimeLimitsComponent implements OnInit {

  /** Настройки ограничения времени */
  @Input() public timeLimits: ITimeLimits;

  /** Обработчик события изменения ограничений времени */
  @Output() public timeLimitsChange = new EventEmitter<ITimeLimits>();

  /** Дни недели */
  public weekDays: IListItem<number>[];

  /** Дни мясяца */
  public monthDays: IListItem<number>[];

  /**
   * Конструктор
   */
  constructor() {
    this.weekDays = this.getNumbers(1, 7)
      .map((id) => ({ id, name: 'component.time-limits.day-' + id }));

    this.monthDays = this.getNumbers(1, 31)
      .map((id) => ({ id, name: id.toString() }));
  }

  /**
   * Добавление часа
   * @param item Элемент времени
   */
  public addHours(item: ITime) {
    let newHours = item.h + 1;
    if (newHours >= 24) { newHours = 0; }

    item.h = newHours;
  }

  /**
   * Вычитание часа
   * @param item Элемент времени
   */
  public subHours(item: ITime) {
    let newHours = item.h - 1;
    if (newHours < 0) { newHours = 23; }

    item.h = newHours;
  }

  /**
   * Добавление минуты
   * @param item Элемент времени
   */
  public addMinutes(item: ITime) {
    let newMinutes = item.m + 1;
    if (newMinutes >= 60) { newMinutes = 0; }

    item.m = newMinutes;
  }

  /**
   * Вычитание минуты
   * @param item Элемент времени
   */
  public subMinutes(item: ITime) {
    let newMinutes = item.m - 1;
    if (newMinutes < 0) { newMinutes = 59; }

    item.m = newMinutes;
  }

  /**
   * Получение признака того, что выбран день недели
   * @param day Номер дня
   */
  public isWeekDaySelected(day: number) {
    const week = this.timeLimits.week;
    if (!week) { return false; }
    const index = week.indexOf(day);
    return index !== -1;
  }

  /**
   * Переключение признака того, что выбран день недели
   * @param day Номер дня
   */
  public toggleWeekDaySelected(day: number) {
    let week = this.timeLimits.week;
    if (!week) {
      week = [];
      this.timeLimits.week = week;
    }
    const index = week.indexOf(day);
    if (index !== -1) {
      week.splice(index, 1);
    } else {
      week.push(day);
    }
  }

  /**
   * Получение признака того, что выбран день месяца
   * @param day Номер дня
   */
  public isMonthDaySelected(day: number) {
    const month = this.timeLimits.month;
    if (!month) { return false; }
    const index = month.indexOf(day);
    return index !== -1;
  }

  /**
   * Переключение признака того, что выбран день месяца
   * @param day Номер дня
   */
  public toggleMonthDaySelected(day: number) {
    let month = this.timeLimits.month;
    if (!month) {
      month = [];
      this.timeLimits.month = month;
    }
    const index = month.indexOf(day);
    if (index !== -1) {
      month.splice(index, 1);
    } else {
      month.push(day);
    }
  }

  /**
   * Получение списка номеров
   * @param min Минимальное количество
   * @param max Максимальное количество
   */
  public getNumbers(min: number, max: number): number[] {
    const result: number[] = [];
    for (let i = min; i <= max; i++) {
      result.push(i);
    }
    return result;
  }

  /** Обработки после инициализации компонента */
  public ngOnInit() {
    if (!this.timeLimits) {
      this.timeLimits = {
        day: {
          from: { h: 0, m: 0 },
          to: { h: 23, m: 59 }
        },
        week: [],
        month: []
      };
      this.timeLimitsChange.emit(this.timeLimits);
    }
  }
}
