import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { ToastService } from './toast.service';

/**
 * Сервис для работы с системными уведомлениями
 */
@Injectable()
export class SystemNotificationsService {

  /**
   * Список системных уведомлений
   */
  public systemNotifications: ISystemNotificationShort[] = [];

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/system-notifications`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param toastService Сервис для работы с модальными окнами
   * @param configService Сервис конфигурации
   */
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private configService: ConfigService
  ) {
  }

  /**
   * Загрузка списка системных уведомлений
   */
  public getList(): Observable<ISystemNotificationShort[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<ISystemNotificationShort[]>(url)
      .pipe(tap((notifications: ISystemNotificationShort[]) => {
        this.systemNotifications = notifications?.sort((a, b) => b.createdAt - a.createdAt);
      }));
  }

  /**
   * Добавление записи
   * @param body Данные записи
   */
  public create(body: ISystemNotification): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.post<string>(url, body);
  }

  /**
   * Изменение записи
   * @param body Данные записи
   */
  public update(body: ISystemNotification): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.put<string>(url, body);
  }

  /**
   * Получение списка
   */
  public getDealerList(): Observable<ISystemNotificationShort[]> {
    const url = `${this.baseUrl}/dealer`;
    return this.http.get<ISystemNotificationShort[]>(url);
  }

  /**
   * Получение подробной информации о записи
   * @param id Идентификатор записи
   */
  public get(id: string): Observable<ISystemNotification> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<ISystemNotification>(url);
  }

  /**
   * Удаление записи
   * @param id Идентификатор записи
   */
  public remove(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<string>(url);
  }

  /**
   * Пометка уведомления как прочитанного
   * @param id Идентификатор уведомления
   */
  public markAsRead(id: string) {
    const url = `${this.baseUrl}/${id}/read`;
    this.http.put<string>(url, {})
      .subscribe(
        () => {
          const index = this.systemNotifications.findIndex((n) => n.id === id);
          if (index !== -1) {
            this.systemNotifications.splice(index, 1);
          }
        },
        () => this.toastService.error('component.notifications.system-notification.mark-error')
      );
  }

  /**
   * Очистка уведомлений (используется при выходе из системы)
   */
  public clean() {
    this.systemNotifications = [];
  }
}

/**
 * Системное уведомление
 */
export interface ISystemNotification {
  /** Идентификатор записи */
  id: string;
  /** Создатель уведомления */
  creatorId: string;
  /** Дилер, всем клиентам которого предназначено уведомление */
  dillerId?: string;
  /** Учетная запись, всем пользователям которой предназначено уведомление */
  accountId?: string;
  /** Пользователь, которому предназначено уведомление */
  userId?: string;
  /** Наименование уведомления (заголовок) */
  name: string;
  /** Сообщение уведомления */
  message: string;
  /** Признак закрепленного уведомления (пользователь не может удалить его) */
  pinned?: boolean;
  /** Признак надоедливого уведомления (показывается пользователю раз в текущей сессии) */
  annoying?: boolean;
  /** Список прочитавших уведомление пользователей (только если оно не закреплено и не указан пользователь) */
  readers?: string[];
  /** Признак того, что уведомление закрыто (не нужно выводить) */
  isClosed?: boolean;
  /** Дата и время автоматического закрытия уведомления системой */
  autoClose?: number;
  /** Дата создания */
  createdAt?: number;
  /** Дата последнего изменения */
  updatedAt?: number;
}

/**
 * Системное уведомление для отображения в списке
 */
export interface ISystemNotificationShort {
  /** Идентификатор записи */
  id: string;
  /** Наименование уведомления (заголовок) */
  name: string;
  /** Текст уведомления */
  message: string;
  /** Признак закрепленного уведомления */
  pinned: boolean;
  /** Признак надоедливого уведомления */
  annoying: boolean;
  /** Признак закрытого уведомления */
  isClosed: boolean;
  /** Дата автоматического закрытия */
  autoClose: number;
  /** Дата создания уведомления */
  createdAt: number;
}
