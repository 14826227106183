import { IGeneratedInvoice } from './IGeneratedInvoice';
import { IGeneratedWAReport } from './IGeneratedWAReport';
import { InvoiceRequest } from './InvoiceRequest';
import { WAReportRequest } from './WAReportRequest';

/** Тип документа */
export type DocType = 'invoice' | 'wareport';

/** Сгенерированный документ */
export type GeneratedDoc = IGeneratedInvoice | IGeneratedWAReport;

/** Запрос на получение сгенерированного документа */
export type DocRequest = InvoiceRequest | WAReportRequest;

/** Ответ сервера на запрос по счету */
export type InvoiceResponse = IGeneratedInvoice | string;

/** Ответ сервера на запрос по акту */
export type WAReportResponse = IGeneratedWAReport | string;

/** Ответ сервера на запрос по документу */
export type DocResponse = InvoiceResponse | WAReportResponse;

/**
 * Проверка на то, является ли документ счетом
 * @param doc Документ
 * @param type Тип документа
 */
export function isInvoiceDoc(doc: GeneratedDoc, type: DocType): doc is IGeneratedInvoice {
  return type === 'invoice';
}

/**
 * Проверка на то, является ли запрос запросом счета
 * @param request Запрос
 * @param type Тип документа
 */
export function isInvoiceRequest(request: DocRequest, type: DocType): request is InvoiceRequest {
  return type === 'invoice';
}

/**
 * Проверка на то, что ответ сервера - сгенерированный документ
 * @param response Ответ сервера на запрос по документу
 */
export function isGeneratedDoc(response: DocResponse): response is GeneratedDoc {
  return typeof response === 'object';
}
