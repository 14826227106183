/**
 * Период формирования при пакетной выгрузке документов
 */
export enum PacketPeriod {
  /** Предыдущий */
  PREV = 1,
  /** Текущий */
  CUR,
  /** Следующий */
  NEXT
}
