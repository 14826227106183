import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ICurrentUser } from '../../shared/users';
import { BgAuthService } from '../modules/bg-auth/services/bg-auth.service';

import { ClientService } from './client.service';
import { StoreService } from './store.service';

/**
 * Сервис для защиты машрутов кабинета клиента
 */
@Injectable()
export class ClientGuard implements CanActivate {
  /**
   * Конструктор
   * @param auth Сервис аутентификации
   * @param router Роутер
   * @param clientService Сервис для работы с клиентом
   * @param store Севрис для хранения данных
   * @param translator Сервис для перевода
   */
  constructor(
    private auth: BgAuthService,
    private router: Router,
    private clientService: ClientService,
    private store: StoreService,
    private translator: TranslateService
  ) {}

  /**
   * Проверка на возможность перехода
   * @returns True, если можно перейти, иначе - false
   */
  public canActivate(): Observable<boolean> {
    if (!this.auth.authenticated) {
      this.router.navigate(['logout']).then();
      return of(false);
    }

    return this.clientService.getUser().pipe(map(this.onLoad), catchError(this.onError));
  }

  /**
   * Обработка загрузки пользователя
   * @param user Пользователь
   */
  private onLoad = (user: ICurrentUser) => {
    this.store.user = user;
    if (user.settings.locale && user.settings.locale !== this.translator.currentLang) {
      this.translator.use(user.settings.locale);
    }

    return true;
  }

  /**
   * Обработка ошибки
   * @param error Ошибка
   */
  private onError = (error: any) => {
    console.error(error);
    return of(true);
  }
}
