import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HostConfigService } from './host-config.service';

@Injectable()
/**
 * Сервис раздела помощь
 */
export class HelpService {

  /** Конструктор */
  constructor(
    private http: HttpClient,
    private hostConfigService: HostConfigService
  ) {
  }

  /**
   * Получаем ссылку на раздел помощи
   */
  public openHelpBlank(route: string) {
    const url = `${this.hostConfigService.wikiUrl}/${this.getHelpUrl(route)}`;
    window.open(url, '_blank');
  }

  /**
   * Получаем ссылку на документ вики
   * @param url
   */
  public getHelpUrl(url: string): string {
    switch (url) {
      case '/monitoring/tracking':
        return '/ru/monitoring/tracking'
      case '/monitoring/race':
        return '/ru/monitoring/race'
      case '/monitoring/geozones':
        return '/ru/monitoring/geozones'
      case '/monitoring/drivers':
        return '/ru/monitoring/drivers'
      case '/monitoring/trailers':
        return '/ru/monitoring/trailers'
      case '/monitoring/routes':
        return '/ru/monitoring/routes'
      case '/monitoring/reports':
        return '/ru/monitoring/reports'
      case '/monitoring/messages':
        return '/ru/monitoring/messages'
      case '/monitoring/analytics':
        return '/ru/monitoring/analytics'

      case '/admin/report-templates':
        return '/ru/admin/report-templates'
      case '/admin/notifications':
        return '/ru/admin/notifications'
      case '/admin/tasks':
        return '/ru/admin/tasks'
      case '/admin/units':
        return '/ru/admin/units'
      case '/admin/unit-groups':
        return '/ru/admin/unit-groups'
      case '/admin/users':
        return '/ru/admin/users'
      case '/admin/guest-access':
        return '/ru/admin/guest-access'

      case '/crm/accounts':
        return '/ru/crm/accounts'
      case '/crm/clients':
        return '/ru/crm/clients'
      case '/crm/tariffs':
        return '/ru/crm/tariffs'
      case '/crm/terminals':
        return '/ru/crm/terminals'
      case '/crm/optionals':
        return '/ru/crm/optionals'
      case '/crm/sim-cards':
        return '/ru/crm/sim-cards'
      case '/crm/repeaters':
        return '/ru/crm/retranslators'
      case '/crm/system-notifications':
        return '/ru/crm/system-notifications'

      default:
        if (url.startsWith('/crm/contracts')) return '/ru/crm/dogovors'
        if (url.startsWith('/crm/refs')) return '/ru/crm/refs'
        if (url.startsWith('/crm')) return '/ru/crm'
        if (url.startsWith('/admin/logistic/scavenger')) return '/ru/monitoring/summary'
        if (url.startsWith('/admin')) return '/ru/admin'
        if (url.startsWith('/client')) return '/ru/personal'
        return ''
    }
  }
}
