import { Component, Input } from '@angular/core';

import { IClientTrailer } from '../../../classes/IClientTrailer';
import { BgPopoverComponent, BgPopoverService } from '../../../modules/bg-popover';

/**
 * Интерфейс компонента для отображения детальной информации о прицепе
 */
interface ITrailersDetailComponent {
  /** Прицеп, по которому отображается детальная информация на карте */
  trailer: IClientTrailer;
}

/**
 * Компонент для отображения детальной информации о прицепе
 */
@Component({
  selector: 'trailers-detail',
  templateUrl: './trailers.detail.component.html',
  styleUrls: ['./trailers.detail.component.scss']
})
export class TrailersDetailComponent
  extends BgPopoverComponent<ITrailersDetailComponent>
  implements ITrailersDetailComponent {

  /** Прицеп */
  @Input() public trailer: IClientTrailer;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих окон
   */
  constructor(popoverService: BgPopoverService) {
    super(popoverService);
  }

  /**
   * Получение css класса для отображения статуса прицепа (занят или свободен)
   */
  public get trailerStatusClass() {
    if (!this.trailer.assignment) { return 'grey'; }

    return this.isBusy ? 'green' : 'red';
  }

  /**
   * Проверка занятости прицепа
   */
  public get isBusy() {
    if (!this.trailer.assignment) { return false; }

    return !this.trailer.assignment.e || this.trailer.assignment.e >= (new Date()).getTime();
  }
}
