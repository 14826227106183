import { DialogComponent } from 'ng2-bootstrap-modal';

import { AnyRightType } from '../../shared/rights/RightType';

import { DetailTab } from './DetailTab';

/**
 * Компонент для отображения детальной информации по сущности
 * Возвращает true или id, если произведены какие-либо изменения
 * (в зависимости от реализации)
 */
export abstract class DetailComponent<T> extends DialogComponent<T, boolean | string> {

  /** Список вкладок */
  public tabs: DetailTab[] = [];
  /** Текст сообщения об ошибке */
  public error: string;
  /** Выбранная вкладка */
  public selectedTab?: DetailTab;
  /** Признак режима добавления */
  public isAddRegime: boolean;
  /** Заголовок окна */
  public title: string;

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public abstract isCan(rightType: AnyRightType): boolean;

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Получение признака возможности сохранения
   */
  public abstract get isCanSave(): boolean;

  /**
   * Подтверждение изменений
   */
  public abstract confirm(): void;
}
