import { ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { ISortInfo } from '../../../../shared/ISortInfo';
import { IMessageColumn } from '../../../../shared/messages/IMessageColumn';
import { IMessagesRequest } from '../../../../shared/messages/IMessagesRequest';
import { getMessageTypeColumns } from '../../../../shared/messages/MessageColumns';
import { LoadingService } from '../../../services/loading.service';
import { IPageData } from '../../paginator/paginator.component';

/**
 * Абстрактный компонент таблицы с сообщениями
 */
export abstract class AbstractMessagesTableComponent<T> implements OnInit, OnChanges {

  /** Запрос на получение списка сообщений */
  @Input() public request: IMessagesRequest;

  /** Данные по сообщениям */
  public rows: T[];

  /** Выбранная строка */
  public selectedRow: T = null;

  /** Список колонок таблицы */
  public columns: IMessageColumn[] = [];

  /** Параметры сортировки */
  public sort: ISortInfo;

  /** HTML элемент, ширина которого изменяется */
  public element: HTMLElement;

  /** Колонка таблицы, ширина которой изменяется */
  public column: IMessageColumn;

  /** Координата Х указателя мыши */
  public pageX: number;

  /** Ширина таблицы */
  public tableWidth: number;

  /** Ширина последней колонки */
  public lastColumnWidth: number = 0;

  /** Общее количество видимых строк */
  public rowsCount: number;

  /** Контейнер таблицы */
  @ViewChild('columnsBox', {static: false})
  protected tableContainer: ElementRef;

  /**
   * Конструктор
   * @param loadingService Сервис отображения процесса загрузки
   * @param loadFunc Метод загрузки страницы сообщений
   */
  protected constructor(
    protected loadingService: LoadingService,
    private loadFunc: LoadFunc<T>
  ) {
    this.request = {} as IMessagesRequest;
    this.reset();
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.columns = getMessageTypeColumns(this.request.type);
    this.loadPage();
  }

  /**
   * Обработка изменений в компоненте
   * @param changes Изменения
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.request
      && !changes.request.isFirstChange()
      && changes.request.previousValue
      && changes.request.currentValue
    ) {
      this.reset();
      this.loadPage();
    }
  }

  /**
   * Обработка события изменения пагинатора
   * @param e Данные пагинатора
   */
  public onPageChanged(e: IPageData) {
    localStorage.setItem('messagesTablePageSize', e.size.toString());
    this.request.limit = e.size;
    this.request.offset = (e.page - 1) * e.size;
    this.updatePage();
  }

  /**
   * Обработка события скрола элемента со строками таблицы
   * @param event Событие
   */
  public onTableScroll(event: Event) {
    const scrollLeft = (event.target as Element).scrollLeft;
    this.tableContainer.nativeElement.style.left = `${-scrollLeft}px`;
  }

  /**
   * Обработка события начала изменения размера колонки таблицы
   * @param event Событие
   * @param column Колонка таблицы
   */
  public onStartResize(event: MouseEvent, column: IMessageColumn) {
    this.element = (event.target as Element).parentElement;
    this.column = column;
    this.column.width = this.element.offsetWidth;
    this.pageX = event.pageX;
  }

  /**
   * Выбор строки таблицы
   * @param row Строка таблицы
   */
  public selectRow(row: T) {
    this.selectedRow = row;
  }

  /**
   * Установка сортировки
   * @param field Поле
   */
  public sortBy(field: string) {
    if (!field) {
      return;
    }

    if (!this.sort) {
      this.sort = { field, isDescending: false };
    } else if (this.sort.field !== field) {
      this.sort.field = field;
      this.sort.isDescending = false;
    } else {
      this.sort.isDescending = !this.sort.isDescending;
    }

    this.request.sort = this.sort;
    this.updatePage();
  }

  /**
   * Обработка события изменения размера колонки таблицы
   * @param event Событие
   */
  public onResize = (event: MouseEvent) => {
    if (this.element) {
      let dif = event.pageX - this.pageX;
      this.pageX = event.pageX;

      const width = this.element.offsetWidth;
      if (width + dif < 30) {
        dif = 30 - width;
      }
      this.column.width += dif;
      this.setTableWidth();
    }
  }

  /**
   * Обработка события завершения изменения размера колонки таблицы
   */
  public onEndResize = () => {
    this.element = null;
    this.column = null;
  }

  /**
   * Обновление страницы
   */
  protected updatePage = () => {
    this.selectRow(null);
    this.loadPage();
  };

  /**
   * Обработка завершения загрузки сообщений
   * @param page Загруженная страница
   */
  protected onRowsLoaded = (page: IPage<T>) => {
    this.rows = page.rows;
    this.rowsCount = page.count;

    this.columns.forEach((col, i) => {
      if (!col.width) {
        col.width = this.rows.map((x) => (x as any).d[i].toString().length * 6 + 50)
          .reduce((a, b) => a > b ? a : b, 0);
      }
    });

    this.selectRow(null);
  }

  /**
   * Установка ширины таблицы по ширине колонок
   */
  protected setTableWidth() {
    const box = this.tableContainer.nativeElement;
    const boxWidth = box.offsetWidth + box.offsetLeft - 17;
    this.tableWidth = this.columns.reduce((a, b) => a + b.width, 0);
    const dif = boxWidth - this.tableWidth;
    this.lastColumnWidth = dif < 0 ? 0 : dif;
  }

  /**
   * Загрузка страницы с сервера
   */
  protected loadPage() {
    this.loadingService.wrap(this.loadFunc(this.request), true)
      .subscribe(this.onRowsLoaded);
  }

  /**
   * Сброс состояния компонента
   */
  protected reset() {
    const ps = localStorage.getItem('messagesTablePageSize');
    this.request.limit = (!ps || ps === '' || ps === '0') ? 25 : +ps;
    this.rowsCount = 0;
    this.request.offset = 0;
    this.rows = [];
  }
}

/**
 * Функция загрузки страницы с сообщениями
 * @param req Запрос на загрузку
 */
export type LoadFunc<T> = (req: IMessagesRequest) => Observable<IPage<T>>;

/**
 * Страница с сообщениями
 */
export interface IPage<T> {
  /** Строки страницы */
  rows: T[];
  /** Полное кол-во строк на всех страницах */
  count: number;
}
