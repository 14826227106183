import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import area from '@turf/area';
import distance from '@turf/distance';
import { polygon } from '@turf/helpers';
import { DialogService } from 'ng2-bootstrap-modal';
import { Observable, Observer, of, Subject, Subscription, timer } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { IDriver } from '../../shared/drivers/IDriver';
import { IDriverGroup } from '../../shared/drivers/IDriverGroup';
import { FastPeriodType } from '../../shared/FastPeriodType';
import { GeozoneType } from '../../shared/geozones/GeozoneType';
import { IGeozone } from '../../shared/geozones/IGeozone';
import { IGeozoneGroup } from '../../shared/geozones/IGeozoneGroup';
import { IGeozoneMap } from '../../shared/geozones/IGeozoneMap';
import { IListGeozoneOrGroup } from '../../shared/geozones/IListGeozoneOrGroup';
import { ICoord } from '../../shared/ICoord';
import { LinkObjectType } from '../../shared/LinkObjectType';
import { IMessage } from '../../shared/messages/IMessage';
import { IMessagesRequest } from '../../shared/messages/IMessagesRequest';
import { ITrackingGroup } from '../../shared/tracking/ITrackingGroup';
import { ITrackingInfo } from '../../shared/tracking/ITrackingInfo';
import { ITrackingSettings } from '../../shared/tracking/ITrackingSettings';
import { ITrackingUnit } from '../../shared/tracking/ITrackingUnit';
import { TrackingUnitChangeType } from '../../shared/tracking/ITrackingUnitChange';
import { ITrackingUpdate } from '../../shared/tracking/ITrackingUpdate';
import { ITrailer } from '../../shared/trailers/ITrailer';
import { ITrailerGroup } from '../../shared/trailers/ITrailerGroup';
import { tranny } from '../app.component';
import { IClientDriver } from '../classes/IClientDriver';
import { IClientGeozone } from '../classes/IClientGeozone';
import { IClientRoute } from '../classes/IClientRoute';
import { IClientTrailer } from '../classes/IClientTrailer';
import { IMapMessagePointInfo } from '../classes/IMapMessagePointInfo';
import { IMapRoute } from '../classes/IMapRoute';
import { TrackingDriverGroup } from '../classes/TrackingDriverGroup';
import { TrackingGeozoneGroup } from '../classes/TrackingGeozoneGroup';
import { TrackingGroup } from '../classes/TrackingGroup';
import { TrackingTrailerGroup } from '../classes/TrackingTrailerGroup';
import { TrackingUnit } from '../classes/TrackingUnit';
import { localeSort } from '../utils/sort';

import { AssignmentsService } from './assignments.service';
import { CRUDEntityType, CRUDService } from './crud.service';
import { GeocoderService } from './geocoder.service';
import { LoadingService } from './loading.service';
import { MapService } from './map.service';
import { ModalService } from './modal.service';
import { NotificationsService } from './notifications.service';
import { ITrafficLightNotification, OnlineNotificationsService } from './online-notifications.service';
import { IClientRace, IRacePoint } from './race.service';
import { ReportsService } from './reports.service';
import { StoreService } from './store.service';
import { ISystemNotificationShort, SystemNotificationsService } from './system-notifications.service';
import { TrackingService } from './tracking.service';

/**
 * Сервис мониторинга
 * (в дальнейшем сервис для управления синхронизацией разделов мониторинга)
 */
@Injectable()
export class MonitoringService {

  /** Событие отображения рейса */
  public showRaceSubject = new Subject<IClientRace>();

  /** Событие скрытия рейса */
  public hideRaceSubject = new Subject<IClientRace>();

  /** Событие перемещения центра карты на точку */
  public moveToPointSubject = new Subject<ICoord>();

  /** Событие перемещения маркера рейса */
  public animateRaceSubject = new Subject<{ race: IClientRace, point: IRacePoint }>();

  /** Событие отображения списка геозон */
  public showGeozonesSubject = new Subject<IGeozoneMap[]>();

  /** Событие скрытия списка геозон */
  public hideGeozonesSubject = new Subject<IClientGeozone[]>();

  /** Событие центрирования карты на геозоне */
  public fitGeozoneSubject = new Subject<string>();

  /** Событие начала рисования геозоны */
  public startDrawGeozoneSubject = new Subject<IClientGeozone>();

  /** Событие отмены рисования геозоны */
  public cancelDrawGeozoneSubject = new Subject<void>();

  /** Событие отображения списка водителей */
  public showDriversSubject = new Subject<IClientDriver[]>();

  /** Событие скрытия списка водителей */
  public hideDriversSubject = new Subject<IClientDriver[]>();

  /** Событие отображения списка прицепов */
  public showTrailersSubject = new Subject<IClientTrailer[]>();

  /** Событие скрытия списка прицепов */
  public hideTrailersSubject = new Subject<IClientTrailer[]>();

  /** Событие смены выбранного объекта мониторинга в панели онлайн-уведомлений */
  public activateNotificationsPanelSubject = new Subject<void>();

  /** Подписка на событие получения онлайн-уведомления со светофором */
  public notificationReceivedSubscription: Subscription;

  /** Подписка на получение вхождений объектов мониторинга в геозоны */
  public entranceSubscription: Subscription;

  /** Подписка на обновление объектов слежения */
  public updateTrackingUnitsSubscription: Subscription;

  /** Подписка на получение данных слежения */
  public trackingSubscription: Subscription;

  /** Событие изменения объектов слежения */
  public showUnitsSubject: Subject<TrackingUnit[]> = new Subject<TrackingUnit[]>();

  /** Событие снятия видимости объектов слежения */
  public hideUnitsSubject: Subject<TrackingUnit[]> = new Subject<TrackingUnit[]>();

  /** Событие скрытия детальной информации по адресу */
  public removeAddressDetailSubject: Subject<void> = new Subject<void>();

  /** Событие сокрытия точки сообщения */
  public hideMessagePointSubject = new Subject<IMapMessagePointInfo>();

  /** Событие отображения точки сообщения */
  public showMessagePointSubject = new Subject<IMapMessagePointInfo>();

  /** Событие уведомления об изменении сообщений */
  public changeMessagesRequestSubject = new Subject<IMessagesRequest>();

  /** Событие отображения маршрута */
  public showRouteSubject = new Subject<IMapRoute>();

  /** Событие скрытия маршрута */
  public hideRouteSubject = new Subject<string>();

  /** Подписка на обновление назначений водителей */
  public updateDriverAssignmentsSubscription: Subscription;

  /** Подписка на обновление назначений прицепов */
  public updateTrailerAssignmentsSubscription: Subscription;

  /** Подписка на загрузку системных уведомлений */
  public loadSystemNotificationsSubscription: Subscription;

  /** Событие потери связи с сервером */
  public loseConnectionSubject = new Subject<void>();

  /** Событие нажатия на ТС */
  public clickOnUnitSubject = new Subject<TrackingUnit>();

  /** Событие изменеия видимости рабочей панели */
  public setWorkPanelVisibilitySubject = new Subject<boolean>();

  /** Событие изменеия размера панели */
  public setNavigationExpandSubject = new Subject<IExpand>();

  /** Последнее выбраное тс на карте */
  public lastCheckedUnit: TrackingUnit;

  /**
   * Видимость настроек трэкинга
   */
  public trackingSettingsVisibilities = new Subject<boolean>();

  public trackingUnitFillSubject = new Subject<void>();

  /**
   * Конструктор
   * @param crudService Сервис работы с ДИУП
   * @param trackingService Сервис работы с режимом слежения
   * @param geocoderService Сервис геокодирования
   * @param reportsService Сервис работы с отчетами
   * @param notificationsService Сервис работы с уведомлениями
   * @param onlineNotificationsService Сервис работы с уведомлениями
   * @param systemNotificationsService Сервис работы с уведомлениями
   * @param assignmentsService Сервис работы с назначениями
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param router Сервис навигации
   * @param store Сервис для хранения данных мониторинга
   * @param translator Сервис для перевода
   * @param http
   * @param mapService
   */
  constructor(
    private crudService: CRUDService,
    private trackingService: TrackingService,
    private geocoderService: GeocoderService,
    private reportsService: ReportsService,
    private notificationsService: NotificationsService,
    private onlineNotificationsService: OnlineNotificationsService,
    private systemNotificationsService: SystemNotificationsService,
    private assignmentsService: AssignmentsService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private router: Router,
    private store: StoreService,
    private translator: TranslateService,
    private http: HttpClient,
    private mapService: MapService
  ) {
    this.notificationReceivedSubscription =
      this.onlineNotificationsService.notificationReceivedSubject.subscribe(this.onTrafficLightedNotificationReceived);
  }

  /**
   * Запуск мониторинга
   */
  public start() {
    // Грузим ТС для режима слежения
    this.startTracking();
  }

  /**
   * Остановка мониторинга
   */
  public stop() {
    // Остановить получение обновлений по ТС в режиме слежения
    this.stopTracking();
    this.store.cleanTrackingLists();
    // Очистить отчет
    this.reportsService.clearReport();
  }

  /**
   * Изменение выбранности геозоны
   * @param geozone Геозона
   */
  public toggleGeozoneChecked(geozone: IClientGeozone) {
    geozone.checked = !geozone.checked;

    const event = geozone.checked
      ? this.trackingService.addGeozone(this.store.sessionId, geozone.id)
      : this.trackingService.deleteGeozone(this.store.sessionId, geozone.id);

    event.subscribe(
      () => {
        if (geozone.checked) {
          this.showGeozonesSubject.next([geozone]);
        } else {
          this.hideGeozonesSubject.next([geozone]);
        }
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Open window of tracking settings
   */
  public openTrackingSettingsModal() {
    this.trackingSettingsVisibilities.next(true);
  }

  /**
   * Перемещение к геозоне
   * @param geozoneId Идентификатор геозоны
   */
  public fitGeozone(geozoneId: string) {
    this.fitGeozoneSubject.next(geozoneId);
  }

  /**
   * Начало рисования геозоны
   * @param geozone Геозона
   */
  public startDrawGeozone(geozone: IClientGeozone) {
    this.startDrawGeozoneSubject.next(geozone);
  }

  /**
   * Отмена рисования геозоны
   */
  public cancelDrawGeozone() {
    this.cancelDrawGeozoneSubject.next();
  }

  /**
   * Начало редактирования геозоны
   * @param geozone Геозона
   */
  public startEditGeozone(geozone: IClientGeozone) {
    this.store.editGeozone = geozone;
    this.mapService.hideGeozonesSubject.next([geozone.id])
    this.startDrawGeozone(geozone);
  }

  /**
   * Удаление объекта из списка геозон
   * @param unit Объект
   */
  public deleteUnitFromGeozones(unit: TrackingUnit) {
    for (const geozone of unit.geozones) {
      geozone.units = geozone.units.filter((object) => {
        return object.id !== unit.id;
      });
    }
  }

  /**
   * Удаление объекта из списка групп
   * @param unit Объект
   */
  public deleteUnitFromGroups(unit: TrackingUnit) {
    for (const group of this.store.groups) {
      group.trackingUnits = group.trackingUnits.filter((object) => {
        return object.id !== unit.id;
      });
    }
  }

  /**
   * Удаление объекта из списка объектов
   * @param unit Объект
   */
  public deleteUnitFromUnits(unit: TrackingUnit) {
    this.store.units = this.store.units.filter((object) => {
      return object.id !== unit.id;
    });
  }

  /**
   * Сохранение изменений по геозоне
   * @param geozone Геозона
   */
  public saveGeozone(geozone: IClientGeozone): Observable<string> {
    const isAdd = !geozone.id;
    const updGeozone: IGeozone = {
      id: geozone.id,
      accountId: geozone.accountId,
      color: geozone.color,
      descr: geozone.descr,
      extCode: geozone.extCode,
      track: geozone.track,
      garbageZone: geozone.garbageZone,
      garbageType: geozone.garbageType,
      garbageVol: geozone.garbageVol,
      garbageDistrict: geozone.garbageDistrict,
      garbageSettlement: geozone.garbageSettlement,
      garbageLocality: geozone.garbageLocality,
      legalPersonName: geozone.legalPersonName,
      inn: geozone.inn,
      homeNumber: geozone.homeNumber,
      regNumber: geozone.regNumber,
      street: geozone.street,
      district: geozone.district,
      labelColor: geozone.labelColor,
      name: geozone.name,
      points: geozone.points,
      r: geozone.r,
      square: geozone.square,
      type: geozone.type
    };
    if (updGeozone.type === GeozoneType.CIRCLE && updGeozone.r) {
      localStorage.setItem('circle_geozone_radius', `${updGeozone.r}`);
    }
    return new Observable((observer: Observer<string>) => {
      this.crudService.addUpdate(updGeozone, CRUDEntityType.GEOZONE).subscribe(
        (id) => {
          geozone.id = id;
          this.crudService.get(id, CRUDEntityType.GEOZONE).subscribe(
            (updatedGeozone) => {
              const updClientGeozone: IClientGeozone = {
                ...updatedGeozone,
                checked: geozone.checked,
                count: geozone.count || 0,
                units: geozone.units,
                showUnits: geozone.showUnits
              };
              if (!isAdd) {
                const existGeozone = this.store.geozones.find((g) => g.id === geozone.id);
                if (existGeozone) {
                  const index = this.store.geozones.indexOf(existGeozone);
                  this.store.geozones[index] = updClientGeozone;
                  for (const unit of this.store.units) {
                    if (!unit.geozones || !unit.geozones.length) { continue; }
                    const inUnitIndex = unit.geozones.indexOf(existGeozone);
                    if (inUnitIndex !== -1) {
                      unit.geozones[inUnitIndex] = updClientGeozone;
                    }
                  }
                } else {
                  this.store.geozones.push(updClientGeozone);
                }

                for (const item of this.store.geozoneGroups) {

                  const index = item.trackingGeozones.findIndex((g) => g.id === geozone.id);

                  if (index !== -1) {
                    item.trackingGeozones[index] = updClientGeozone;
                  }
                }

              } else {
                let otherGroup: TrackingGeozoneGroup = this.store.geozoneGroups.find((g) => g.id === '');
                if (!otherGroup) {
                  otherGroup = new TrackingGeozoneGroup({
                    id: '',
                    name: this.translator.instant('services.monitoring.out-of-group'),
                    geozones: [],
                    accountId: null
                  });

                  this.store.geozoneGroups.push(otherGroup);
                }

                otherGroup.trackingGeozones.push(updClientGeozone);
                this.store.geozones.push(updClientGeozone);
                this.trackingService.addGeozone(
                  this.store.sessionId, updClientGeozone.id
                ).subscribe();
              }
              if (updClientGeozone.checked) {
                this.showGeozonesSubject.next([updClientGeozone]);
              }
              this.cancelDrawGeozone();
              this.store.editGeozone = null;
              observer.next(id);
              observer.complete();
            },
            (error) => observer.error(error)
          );
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Отмена редактирования геозоны
   * @param geozone Геозона
   */
  public cancelEditGeozone(geozone: IClientGeozone) {
    this.store.editGeozone = null;
    this.cancelDrawGeozone();
    if (geozone.id) {
      const existGeozone = this.store.geozones.find((g) => g.id === geozone.id);
      if (existGeozone && existGeozone.checked) {
        this.showGeozonesSubject.next([existGeozone]);
      }
    }
  }

  /**
   * Удаление группы геозон
   * @param group Группа геозон
   */
  public deleteGeozoneGroup(group: IListGeozoneOrGroup): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(
        group.id, CRUDEntityType.GEOZONE_GROUP
      ).subscribe(
        (id) => {
          this.getGeozoneGroups();
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Обновление точек геозоны, а также радиуса/ширины
   * @param points Точки геозоны
   * @param r Радиус/ширина
   */
  public updateEditGeozonePointsAndR(points: ICoord[], r?: number) {
    if (!this.store.editGeozone) { return; }
    this.store.editGeozone.points = points;
    if (r) { this.store.editGeozone.r = Math.round(r * 1000) / 1000; }
    this.store.editGeozone.square = this.calcGeozoneSquare(this.store.editGeozone);
  }

  /**
   * Переключение выбранности водителя в списке
   * @param driver Водитель
   */
  public toggleDriverChecked(driver: IClientDriver) {
    driver.checked = !driver.checked;

    const event = driver.checked
      ? this.trackingService.addDriver(this.store.sessionId, driver.id)
      : this.trackingService.deleteDriver(this.store.sessionId, driver.id);

    event.subscribe(
      () => {
        if (driver.checked) {
          this.showDriversSubject.next([driver]);
        } else {
          this.hideDriversSubject.next([driver]);
        }
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Установка признака выбранности для всех водителей
   * @param checked Признак выбранности
   */
  public setAllDriversChecked(checked: boolean) {
    for (const driver of this.store.drivers) {
      if (driver.checked !== checked) {
        this.toggleDriverChecked(driver);
      }
    }
  }

  /**
   * Удаление водителя
   * @param driver Водитель
   */
  public deleteDriver(driver: IClientDriver): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(driver.id, CRUDEntityType.DRIVER).subscribe(
        () => {
          if (driver.checked) {
            this.hideDriversSubject.next([driver]);
          }

          let index = this.store.drivers.indexOf(driver);
          if (index !== -1) {
            this.store.drivers.splice(index, 1);
          }

          for (const group of this.store.driverGroups) {
            index = group.trackingDrivers.indexOf(driver);

            if (index !== -1) {
              group.trackingDrivers.splice(index, 1);

              index = group.drivers.indexOf(driver.id);
              if (index !== -1) {
                group.drivers.splice(index, 1);
              }
            }
          }

          observer.next(driver.id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Сохранение группы водителей
   * @param group Группа водителей
   */
  public saveDriverGroup(group: IDriverGroup): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.addUpdate(
        group, CRUDEntityType.DRIVER_GROUP
      ).subscribe(
        (id) => {
          this.getDriverGroups();
          this.store.editDriverGroup = null;
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Удаление группы водителей
   * @param group Группа водителей
   */
  public deleteDriverGroup(group: IDriverGroup): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(
        group.id, CRUDEntityType.DRIVER_GROUP
      ).subscribe(
        (id) => {
          this.getDriverGroups();
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Переключение выбранности прицепа в списке
   * @param trailer Прицеп
   */
  public toggleTrailerChecked(trailer: IClientTrailer) {
    trailer.checked = !trailer.checked;

    const event = trailer.checked
      ? this.trackingService.addTrailer(this.store.sessionId, trailer.id)
      : this.trackingService.deleteTrailer(this.store.sessionId, trailer.id);

    event.subscribe(
      () => {
        if (trailer.checked) {
          this.showTrailersSubject.next([trailer]);
        } else {
          this.hideTrailersSubject.next([trailer]);
        }
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Установка признака выбранности для всех прицепов
   * @param checked Признак выбранности
   */
  public setAllTrailersChecked(checked: boolean) {
    for (const trailer of this.store.trailers) {
      if (trailer.checked !== checked) {
        this.toggleTrailerChecked(trailer);
      }
    }
  }

  /**
   * Удаление прицепа
   * @param trailer Прицеп
   */
  public deleteTrailer(trailer: IClientTrailer): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(trailer.id, CRUDEntityType.TRAILER).subscribe(
        () => {
          if (trailer.checked) {
            this.hideTrailersSubject.next([trailer]);
          }

          let index = this.store.trailers.indexOf(trailer);
          if (index !== -1) {
            this.store.trailers.splice(index, 1);
          }

          for (const group of this.store.trailerGroups) {
            index = group.trackingTrailers.indexOf(trailer);

            if (index !== -1) {
              group.trackingTrailers.splice(index, 1);

              index = group.trailers.indexOf(trailer.id);
              if (index !== -1) {
                group.trailers.splice(index, 1);
              }
            }
          }

          observer.next(trailer.id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Сохранение группы прицепов
   * @param group Группа прицепов
   */
  public saveTrailerGroup(group: ITrailerGroup): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.addUpdate(
        group, CRUDEntityType.TRAILER_GROUP
      ).subscribe(
        (id) => {
          this.getTrailerGroups();
          this.store.editTrailerGroup = null;
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Удаление группы прицепов
   * @param group Группа прицепов
   */
  public deleteTrailerGroup(group: ITrailerGroup): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(
        group.id, CRUDEntityType.TRAILER_GROUP
      ).subscribe(
        (id) => {
          this.getTrailerGroups();
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Сохранение изменений по маршруту
   * @param route Маршрут
   */
  public saveRoute(route: IClientRoute): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.addUpdate(route, CRUDEntityType.ROUTE).subscribe(
        (id) => {
          this.store.editRoute = null;
          this.getRoutes();
          observer.next(id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Отмена редактирования маршрута
   */
  public cancelEditRoute() {
    this.store.editRoute = null;
  }

  /**
   * Удаление маршрута
   * @param route Маршрут
   */
  public deleteRoute(route: IClientRoute): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.crudService.del(route.id, CRUDEntityType.ROUTE).subscribe(
        () => {
          if (route.showed) {
            this.hideRouteSubject.next(route.id);
          }

          const index = this.store.routes.indexOf(route);
          if (index !== -1) { this.store.routes.splice(index, 1); }
          observer.next(route.id);
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * Переключение отображения маршрута на карте
   * @param route Маршрут
   */
  public toggleRouteShowing(route: IClientRoute) {
    route.showed = !route.showed;
    if (route.showed) {
      this.crudService.getList(CRUDEntityType.GEOZONE).subscribe(
        (geozones) => {
          const result: IMapRoute = { id: route.id, points: [] };
          for (const point of route.points) {
            const geozone = geozones.find((g) => g.id === point.geozoneId);
            if (geozone) {
              result.points.push(geozone);
            }
          }
          this.showRouteSubject.next(result);
        },
        (error) => this.modalService.showError(error)
      );
    } else {
      this.hideRouteSubject.next(route.id);
    }
  }

  /* --- Объекты слежения --- */

  /**
   * Переключение признака видимости объекта
   * @param unit Объект мониторинга
   */
  public toggleUnitChecked(unit: TrackingUnit) {
    unit.checked = !unit.checked;
    if (!unit.checked) { unit.eye = false; }

    this.trackingService.updateUnit(
      this.store.sessionId, unit.id,
      unit.checked
        ? TrackingUnitChangeType.CHECKED
        : TrackingUnitChangeType.UNCHECKED
    ).subscribe(
      () => {
        if (unit.checked) {
          this.showUnitsSubject.next([unit]);
        } else {
          this.hideUnitsSubject.next([unit]);
        }
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Переключение признака слежения за объектом
   * @param unit Объект мониторинга
   */
  public toggleUnitEye(unit: TrackingUnit) {
    if (!unit.checked) { return; }

    unit.eye = !unit.eye;
    this.trackingService.updateUnit(
      this.store.sessionId, unit.id,
      unit.eye
        ? TrackingUnitChangeType.EYE_ON
        : TrackingUnitChangeType.EYE_OFF
    ).subscribe(
      () => this.showUnitsSubject.next([unit]),
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Переключение признака отображения детальной информации по объекту
   * @param unit Объект мониторинга
   */
  public toggleUnitDetail(unit: TrackingUnit) {
    unit.detail = !unit.detail;
    unit.infoIconVisible = !unit.infoIconVisible;

    this.trackingService.updateUnit(
      this.store.sessionId, unit.id,
      unit.detail
        ? TrackingUnitChangeType.SHOW_DETAIL
        : TrackingUnitChangeType.HIDE_DETAIL
    ).subscribe(
      () => {
        if (unit.checked) {
          this.showUnitsSubject.next([unit]);
        }
        if (unit.detail) {
          unit.address = null;
          this.trackingService.getUnitTrackingInfo(
            this.store.sessionId, unit.id
          ).subscribe(
            (info) => this.onTrackingInfoReceived([info]),
            (error) => this.modalService.showError(error)
          );
        }
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Установление признака выбранности для всех объектов слежения
   * @param unit Признак выбранности
   */
  public switchVisibilityOfUnitDetail(unit: TrackingUnit) {
    if (!unit.detail && !unit.infoIconVisible) {

      unit.detail = !unit.detail;
      unit.address = null;

      this.loadingService
        .wrap(this.trackingService.getFullUnitTrackingInfo(this.store.sessionId, unit.id))
        .subscribe((info) => {
          this.onTrackingInfoReceived([info]);
        });

      this.lastCheckedUnit = unit;
    }

    const unitElem = document.getElementById(unit.name);
    if (unitElem) {
      unitElem.scrollIntoView({ block: 'center', behavior: 'smooth' });
      unitElem.classList.add('unit-highlight');
    }
  }

  /**
   * Установление признака выбранности для всех объектов слежения
   * @param checked Признак выбранности
   */
  public setAllUnitsChecked(checked: boolean) {
    for (const unit of this.store.units) {
      if (unit.checked !== checked) {
        this.toggleUnitChecked(unit);
      }
    }
  }

  /**
   * Перезапуск слежения
   */
  public restartTracking() {
    this.stopTracking();
    this.hideUnitsSubject.next(this.store.units);
    this.mapService.loadOrReloadGeozonesSubject.next()
    this.hideDriversSubject.next(this.store.drivers);
    this.startTracking();
  }

  /**
   * Обновление настроек слежения
   * @param trackingSettings Настройки слежения
   */
  public updateTrackingSettings(trackingSettings: ITrackingSettings): Observable<string> {
    return this.trackingService.updateTrackingSettings(this.store.sessionId, trackingSettings)
      .pipe(tap(() => {
        this.store.trackingSettings = trackingSettings;
        let moveThreshold = this.store.trackingSettings ? this.store.trackingSettings.moveThreshold : 15;
        if (!moveThreshold || moveThreshold < 0) {
          moveThreshold = 15;
        }
        for (const unit of this.store.units) {
          unit.moveThreshold = moveThreshold;
        }
      }));
  }

  /**
   * Перемещение к точке на карте
   * @param point Точка
   */
  public moveToPoint(point: ICoord) {
    if (!point) { return; }

    this.moveToPointSubject.next(point);
  }

  /* -------------------- Сообщения -------------------- */

  /**
   * Изменение запроса на получение сообщений
   * @param request Запрос на получение сообщений
   */
  public changeMessagesRequest(request: IMessagesRequest) {
    this.store.messagesRequest = request;
    this.changeMessagesRequestSubject.next(this.store.messagesRequest);
  }

  /**
   * Отображение точки сообщения
   * @param messageInfo Точка
   * @param unitId Идентификатор объекта мониторинга
   */
  public showMessagePoint(messageInfo: IMessage, unitId: string) {
    if (this.store.currentMessage) {
      this.hideMessagePointSubject.next(this.store.currentMessage);
    }

    if (messageInfo && this.isValidCoordinate(messageInfo)) {
      this.store.currentMessage = { message: messageInfo, uid: unitId };
      this.showMessagePointSubject.next(this.store.currentMessage);
    } else {
      this.store.currentMessage = null;
    }
  }

  /**
   * Скрывает текущую точку на карте
   */
  public hideMessagePoint() {
    if (this.store.currentMessage) {
      this.hideMessagePointSubject.next(this.store.currentMessage);
      this.store.currentMessage = null;
    }
  }

  /** Загрузка списка геозон и всего, что с ними связано */
  public getGeozones() {
    // Очищаем список групп геозон
    this.store.geozoneGroups = [];

    // Убираем геозоны с карты, если они на ней же есть
    if (this.store.geozones && this.store.geozones.length) {
      this.hideGeozonesSubject.next(this.store.geozones);
    }

    // Отписываемся от получения обновлений вхождения в геозоны
    if (this.entranceSubscription) {
      this.entranceSubscription.unsubscribe();
    }
  }

  /** Загрузка списка водителей и всего, что с ними связано */
  public getDrivers() {
    // Очищаем список групп водителей
    this.store.driverGroups = [];

    // Убираем водителей с карты, если они на ней же есть
    if (this.store.drivers && this.store.drivers.length) {
      this.hideDriversSubject.next(this.store.drivers);
    }

    // Отписываемся от получения обновлений назначений водителей
    if (this.updateDriverAssignmentsSubscription) {
      this.updateDriverAssignmentsSubscription.unsubscribe();
    }

    // Запрос для выбора по идентификатору определенной учетной записи
    let query: Dict<string> = null;
    if (this.store.trackingSettings && this.store.trackingSettings.driversAccountId) {
      query = { accountId: this.store.trackingSettings.driversAccountId };
    }

    this.crudService.getList(CRUDEntityType.DRIVER, query).subscribe(
      this.onGetDrivers,
      (error) => this.modalService.showError(error)
    );
  }

  /** Загрузка списка прицепов и всего, что с ними связано */
  public getTrailers() {
    this.store.trailerGroups = [];

    if (this.store.trailers && this.store.trailers.length) {
      this.hideTrailersSubject.next(this.store.trailers);
    }

    if (this.updateTrailerAssignmentsSubscription) {
      this.updateTrailerAssignmentsSubscription.unsubscribe();
    }

    let query: Dict<string> = null;
    if (this.store.trackingSettings && this.store.trackingSettings.trailersAccountId) {
      query = { accountId: this.store.trackingSettings.trailersAccountId };
    }

    this.crudService.getList(CRUDEntityType.TRAILER, query).subscribe(
      this.onGetTrailers,
      (error) => this.modalService.showError(error)
    );
  }

  /** Загрузка списка маршрутов */
  public getRoutes() {
    for (const route of this.store.routes) {
      if (route.showed) {
        this.hideRouteSubject.next(route.id);
      }
    }
    this.crudService.getList(CRUDEntityType.ROUTE).subscribe(
      (routes) => {
        this.store.routes = routes.map(
          (route) => ({
            ...route,
            showed: false
          })
        );
      },
      () => {
        // ignored
      }
    );
  }

  /**
   * Изменение видимости рабочей панели
   * @param b флаг видимости
   */
  public setWorkPanelVisibility(b: boolean) {
    this.setWorkPanelVisibilitySubject.next(b);
  }

  /**
   * Расчет площади геозоны
   * @param geozone Геозона
   */
  public calcGeozoneSquare(geozone: IClientGeozone): number {
    let result = 0;
    switch (geozone.type) {
      case GeozoneType.CIRCLE:
        result = Math.PI * Math.pow(geozone.r || 0, 2);
        break;
      case GeozoneType.POLYGON:
        const coords = geozone.points.map((point) => [point.ln, point.lt]);
        const polygonFeature = polygon([coords]);
        result = area(polygonFeature);
        break;
      case GeozoneType.LINE:
        for (let i = 1; i < geozone.points.length; i++) {
          const point = geozone.points[i];
          const prevPoint = geozone.points[i - 1];
          const dist = distance([prevPoint.ln, prevPoint.lt], [point.ln, point.lt]);
          result += (dist * 1000) * (geozone.r || 0);
        }
        break;
    }
    return Math.round(result * 1000) / 1000;
  }

  /** Получение списка групп геозон */
  private getGeozoneGroups() {
    if (!this.store.trackingSettings || !this.store.trackingSettings.showGeozoneGroups) {
      return;
    }

    // Если выбрана какая то одна учетная запись,
    // по которой необходимо получать группы геозон,
    // то передаем ее в качестве параметра.
    let query: Dict<string> = null;
    if (this.store.trackingSettings.geozonesAccountId) {
      query = { accountId: this.store.trackingSettings.geozonesAccountId };
    }

    this.crudService.getList(
      CRUDEntityType.GEOZONE_GROUP, query
    ).subscribe(
      this.onGetGeozoneGroups,
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Обработка при получении списка групп геозон
   * @param groups Список групп геозон
   */
  private onGetGeozoneGroups = (groups: IGeozoneGroup[]) => {
    this.store.geozoneGroups = [];

    const geozonesInGroups: { [key: string]: boolean } = {};
    groups?.sort(localeSort);
    for (const group of groups) {
      const trackingGroup = new TrackingGeozoneGroup(group);
      for (const geozoneId of group.geozones) {
        const geozone = this.store.geozones.find((u) => u.id === geozoneId);
        if (geozone) {
          trackingGroup.trackingGeozones.push(geozone);
          geozonesInGroups[geozoneId] = true;
        }
      }
      this.store.geozoneGroups.push(trackingGroup);
    }

    // Создаем группу для геозон, не вошедших в группы
    let otherGroup: TrackingGeozoneGroup = null;
    for (const geozone of this.store.geozones) {
      if (geozonesInGroups[geozone.id]) { continue; }

      if (!otherGroup) {
        otherGroup = new TrackingGeozoneGroup({
          id: '',
          name: this.translator.instant('services.monitoring.out-of-group'),
          geozones: [],
          accountId: null
        });

        this.store.geozoneGroups.push(otherGroup);
      }
      otherGroup.geozones.push(geozone.id);
      otherGroup.trackingGeozones.push(geozone);
    }
  }

  /**
   * Обработка при получении списка групп с сервера
   * @param groups Список групп
   */
  private onGetGroups = (groups: ITrackingGroup[]) => {
    this.store.groups = [];
    if (!groups || !groups.length) { return; }

    const unitsInGroups: { [key: string]: boolean } = {};

    for (const group of groups) {
      const trackingGroup = new TrackingGroup(group);
      for (const unitId of group.units) {
        const unit = this.store.units.find((u) => u.id === unitId);
        if (unit) {
          trackingGroup.trackingUnits.push(unit);
          unitsInGroups[unitId] = true;
        }
      }
      this.store.groups.push(trackingGroup);
    }

    // Создаем группу для ТС не вошедших в группы
    let otherGroup: TrackingGroup = null;
    for (const unit of this.store.units) {
      if (unitsInGroups[unit.id]) { continue; }

      if (!otherGroup) {
        otherGroup = new TrackingGroup({
          id: '',
          name: this.translator.instant('services.monitoring.out-of-group'),
          units: []
        });
        this.store.groups.push(otherGroup);
      }
      otherGroup.units.push(unit.id);
      otherGroup.trackingUnits.push(unit);
    }

    // мапим localStorage
    const hideUnitGroup = localStorage.getItem('hideUnitGroup');
    if (hideUnitGroup) {
      const ps: string[] = JSON.parse(hideUnitGroup)
      this.store.groups.map((g) => g.showUnits = !ps?.some((id) => id === g.id))
    }
  }

  /**
   * Обработка при получении списка водителей с сервера
   * @param drivers Список водителей
   */
  private onGetDrivers = (drivers: IDriver[]) => {
    this.store.drivers = drivers.map(
      (driver) => ({
        ...driver,
        checked: false,
        denying: false,
        detail: false,
        assignment: null
      })
    );

    this.getDriverGroups();

    // Получаем список водителей текущей сессии
    this.trackingService.getDrivers(
      this.store.sessionId
    ).subscribe(
      this.onGetTrackingDrivers,
      (error) => this.modalService.showError(error)
    );

    this.startUpdateDriverAssignments();
  }

  /**
   * Получение списка групп водителей
   */
  private getDriverGroups() {
    if (!this.store.trackingSettings || !this.store.trackingSettings.showDriverGroups) {
      return;
    }

    let query: Dict<string> = null;
    if (this.store.trackingSettings.driversAccountId) {
      query = { accountId: this.store.trackingSettings.driversAccountId };
    }

    this.crudService.getList(
      CRUDEntityType.DRIVER_GROUP, query
    ).subscribe(
      this.onGetDriverGroups,
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Обработка при получении списка групп водителей
   * @param groups Список групп водителей
   */
  private onGetDriverGroups = (groups: IDriverGroup[]) => {
    this.store.driverGroups = [];

    const driversInGroups: { [key: string]: boolean } = {};
    groups?.sort(localeSort);
    for (const group of groups) {
      const trackingGroup = new TrackingDriverGroup(group);
      for (const driverId of group.drivers) {
        const driver = this.store.drivers.find((d) => d.id === driverId);
        if (driver) {
          trackingGroup.trackingDrivers.push(driver);
          driversInGroups[driverId] = true;
        }
      }
      this.store.driverGroups.push(trackingGroup);
    }

    // Создаем группу для водителей, не вошедших в группы
    let otherGroup: TrackingDriverGroup = null;
    for (const driver of this.store.drivers) {
      if (driversInGroups[driver.id]) { continue; }

      if (!otherGroup) {
        otherGroup = new TrackingDriverGroup({
          id: '',
          name: this.translator.instant('services.monitoring.out-of-group'),
          drivers: [],
          accountId: null
        });

        this.store.driverGroups.push(otherGroup);
      }
      otherGroup.drivers.push(driver.id);
      otherGroup.trackingDrivers.push(driver);
    }

    // мапим localStorage
    const hideDriverGroup = localStorage.getItem('hideDriverGroup');
    if (hideDriverGroup) {
      const ps: string[] = JSON.parse(hideDriverGroup)
      this.store.driverGroups.map((g) => g.showDrivers = !ps?.some((id) => id === g.id))
    }
  }

  /**
   * Обработка при получении списка отслеживаемых водителей с сервера
   * @param driverIds Список идентификаторов водителей
   */
  private onGetTrackingDrivers = (driverIds: string[]) => {
    for (const id of driverIds) {
      const driver = this.store.drivers.find((g) => g.id === id);
      if (driver) { driver.checked = true; }
    }
    this.updateDriverAssignments();
  }

  /**
   * Обработка при получении списка прицепов с сервера
   * @param trailers Список прицепов
   */
  private onGetTrailers = (trailers: ITrailer[]) => {
    this.store.trailers = trailers.map(
      (trailer) => ({
        ...trailer,
        checked: false,
        denying: false,
        detail: false,
        assignment: null
      })
    );

    this.getTrailerGroups();

    this.trackingService.getTrailers(
      this.store.sessionId
    ).subscribe(
      this.onGetTrackingTrailers,
      (error) => this.modalService.showError(error)
    );

    this.startUpdateTrailerAssignments();
  }

  /**
   * Обработка при получении списка отслеживаемых прицепов с сервера
   * @param trailerIds Список идентификаторов прицепов
   */
  private onGetTrackingTrailers = (trailerIds: string[]) => {
    for (const id of trailerIds) {
      const trailer = this.store.trailers.find((t) => t.id === id);
      if (trailer) { trailer.checked = true; }
    }
    this.updateTrailerAssignments();
  }

  /**
   * Получение списка групп прицепов
   */
  private getTrailerGroups() {
    if (!this.store.trackingSettings || !this.store.trackingSettings.showTrailerGroups) {
      return;
    }

    let query: Dict<string> = null;
    if (this.store.trackingSettings.trailersAccountId) {
      query = { accountId: this.store.trackingSettings.trailersAccountId };
    }

    this.crudService.getList(
      CRUDEntityType.TRAILER_GROUP, query
    ).subscribe(
      this.onGetTrailerGroups,
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Обработка при получении списка групп прицепов
   * @param groups Список групп прицепов
   */
  private onGetTrailerGroups = (groups: ITrailerGroup[]) => {
    this.store.trailerGroups = [];

    const trailersInGroups: { [key: string]: boolean } = {};
    groups?.sort(localeSort);
    for (const group of groups) {
      const trackingGroup = new TrackingTrailerGroup(group);
      for (const trailerId of group.trailers) {
        const trailer = this.store.trailers.find((d) => d.id === trailerId);
        if (trailer) {
          trackingGroup.trackingTrailers.push(trailer);
          trailersInGroups[trailerId] = true;
        }
      }
      this.store.trailerGroups.push(trackingGroup);
    }

    let otherGroup: TrackingTrailerGroup = null;
    for (const trailer of this.store.trailers) {
      if (trailersInGroups[trailer.id]) { continue; }

      if (!otherGroup) {
        otherGroup = new TrackingTrailerGroup({
          id: '',
          name: this.translator.instant('services.monitoring.out-of-group'),
          trailers: [],
          accountId: null
        });

        this.store.trailerGroups.push(otherGroup);
      }
      otherGroup.trailers.push(trailer.id);
      otherGroup.trackingTrailers.push(trailer);
    }

    // мапим localStorage
    const hideTrailerGroup = localStorage.getItem('hideTrailerGroup');
    if (hideTrailerGroup) {
      const ps: string[] = JSON.parse(hideTrailerGroup)
      this.store.trailerGroups.map((g) => g.showTrailers = !ps?.some((id) => id === g.id))
    }
  }

  /**
   * Запуск обновления объектов слежения
   */
  private startUpdateTrackingUnits() {
    const period = 1000 * 60 * 15;
    this.updateTrackingUnitsSubscription = timer(period, period)
      .subscribe(this.updateTrackingUnits);
  }

  /**
   * Запуск подписки на обновление назначений водителей
   */
  private startUpdateDriverAssignments() {
    const period = 1000 * 60;
    this.updateDriverAssignmentsSubscription = timer(period, period)
      .subscribe(this.updateDriverAssignments);
  }

  /**
   * Запуск подписки на обновление назначений прицепов
   */
  private startUpdateTrailerAssignments() {
    const period = 1000 * 60;
    this.updateTrailerAssignmentsSubscription = timer(period, period)
      .subscribe(this.updateTrailerAssignments);
  }

  /**
   * Обновление объектов слежения и списка групп объектов
   */
  private updateTrackingUnits = () => {
    this.trackingService.getTrackingUnits(this.store.sessionId).subscribe(
      (newUnits) => {
        const moveThreshold = this.store.trackingSettings ? this.store.trackingSettings.moveThreshold : 15;
        let visibleUnits = this.store.units.filter((u) => u.checked);
        this.hideUnitsSubject.next(visibleUnits);
        this.store.units = newUnits.map((unit) => new TrackingUnit(unit, moveThreshold));
        visibleUnits = this.store.units.filter((u) => u.checked);
        this.showUnitsSubject.next(visibleUnits);
        this.trackingService.getGroups(this.store.sessionId)
          .pipe(tap(this.onGetGroups))
          .subscribe(() => this.trackingUnitFillSubject.next(), (error) => this.handlePeriodicRequestError(error));
      },
      (error) => this.handlePeriodicRequestError(error)
    );
  }

  /**
   * Обновление назначений водителей
   */
  private updateDriverAssignments = () => {
    this.assignmentsService.getLastAssignments(this.store.sessionId, LinkObjectType.DRIVER).subscribe(
      (assignments) => {
        for (const unit of this.store.units) {
          if (unit.drivers) { delete unit.drivers; }
        }

        for (const driver of this.store.drivers) {
          const assignment = assignments.find((d) => d.oid === driver.id);

          if (!assignment) {
            if (driver.assignment) {
              delete driver.assignment;
            }
            continue;
          }

          driver.assignment = assignment;
          const unit = this.store.units.find((u) => u.id === assignment.uid);
          if (unit) {
            if (!unit.drivers) { unit.drivers = []; }
            if (!assignment.e || assignment.e > (new Date()).getTime()) {
              unit.drivers.push(driver);
            }
          }

          if (driver.checked) {
            this.showDriversSubject.next([driver]);
          }
        }

        for (const unit of this.store.units) {
          if (unit.drivers && unit.drivers.length > 1) {
            unit.drivers?.sort(localeSort);
          }
        }
      },
      (error) => this.handlePeriodicRequestError(error)
    );
  }

  /**
   * Обновление назначений прицепов
   */
  private updateTrailerAssignments = () => {
    this.assignmentsService.getLastAssignments(this.store.sessionId, LinkObjectType.TRAILER).subscribe(
      (assignments) => {
        for (const unit of this.store.units) {
          if (unit.trailers) { delete unit.trailers; }
        }

        for (const trailer of this.store.trailers) {
          const assignment = assignments.find((s) => s.oid === trailer.id);

          if (!assignment) {
            if (trailer.assignment) {
              delete trailer.assignment;
            }
            continue;
          }

          trailer.assignment = assignment;
          const unit = this.store.units.find((u) => u.id === assignment.uid);
          if (unit) {
            if (!unit.trailers) { unit.trailers = []; }
            if (!assignment.e || assignment.e > (new Date()).getTime()) {
              unit.trailers.push(trailer);
            }
          }

          if (trailer.checked) {
            this.showTrailersSubject.next([trailer]);
          }
        }

        for (const unit of this.store.units) {
          if (unit.trailers && unit.trailers.length > 1) {
            unit.trailers?.sort(localeSort);
          }
        }
      },
      (error) => this.handlePeriodicRequestError(error)
    );
  }

  /**
   * Остановка получения вхождений объектов в геозоны
   */
  private stopGetEntrance() {
    if (this.entranceSubscription) {
      this.entranceSubscription.unsubscribe();
    }
  }

  /**
   * Обработка после получения записей с информацией о слежении
   * @param update Обновленные данных слежения
   */
  private onTrackingInfoReceived(update: ITrackingUpdate | ITrackingInfo[]) {
    const infos = _.isArray(update) ? update : update.i;
    if (infos !== update) {
      this.store.lastGetTrackingInfo = (update as ITrackingUpdate).t;
    }

    const units: TrackingUnit[] = [];
    for (const info of infos) {
      const unit = this.store.units.find((ti) => ti.id === info.u);
      if (!unit) {
        continue;
      }

      if (unit.checked) {
        units.push(unit);
      }

      if (unit.detail && this.store?.user?.settings?.unitDetail?.address?.tracking) {
        this.geocoderService.getAddress(unit.id, info.lt, info.ln)
          .subscribe((address) => unit.address = address);
      }

      unit.position = info;
    }

    this.showUnitsSubject.next(units);
  }

  /**
   * Обработчик события получения онлайн-уведомлений со значением светофора
   * @param data Данные уведомлений
   */
  private onTrafficLightedNotificationReceived = (data: ITrafficLightNotification[]) => {
    for (const obj of data) {
      const index = this.store.units.findIndex((u) => u.id === obj.unitId);
      if (index !== -1) {
        this.store.units[index].trafficLight = obj.trafficLight;

        const unitElem = document.getElementById(this.store.units[index].name);
        if (unitElem) {
          unitElem.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    }
  }

  /**
   * Получение информации слежения
   */
  private getTrackingInfo = () => {
    this.trackingService.getTrackingInfo(
      this.store.sessionId, this.store.lastGetTrackingInfo || 0
    ).subscribe(
      (update) => this.onTrackingInfoReceived(update),
      (error) => this.handlePeriodicRequestError(error)
    );
  }

  /**
   * Остановка слежения
   */
  private stopTracking() {
    if (this.trackingSubscription) {
      this.trackingSubscription.unsubscribe();
    }
    if (this.updateTrackingUnitsSubscription) {
      this.updateTrackingUnitsSubscription.unsubscribe();
    }
    if (this.updateDriverAssignmentsSubscription) {
      this.updateDriverAssignmentsSubscription.unsubscribe();
    }
    if (this.updateTrailerAssignmentsSubscription) {
      this.updateTrailerAssignmentsSubscription.unsubscribe();
    }
    if (this.notificationReceivedSubscription) {
      this.notificationReceivedSubscription.unsubscribe();
    }
    if (this.loadSystemNotificationsSubscription) {
      this.loadSystemNotificationsSubscription.unsubscribe();
    }
    // Остановить получение обновлений нахождения ТС в геозонах
    this.stopGetEntrance();
  }

  /**
   * Начало слежения
   */
  private startTracking() {
    this.initSession().subscribe(
      this.onInitSession,
      (error) => this.modalService.showError(error)
    );

    this.startUpdateTrackingUnits();

    // Раз в пять минут загружаем список системных уведомлений
    this.loadSystemNotificationsSubscription = timer(0, 300000).subscribe(
      () => {
        this.systemNotificationsService.getList().subscribe(
          (notifications) => {
            this.checkAnnoyingNotifications(notifications);
          },
          (error) => this.handlePeriodicRequestError(error)
        );
      }
    );
  }

  /**
   * Проверка надоедливых уведомлений
   * @param notifications системные уведомления
   */
  private checkAnnoyingNotifications(notifications: ISystemNotificationShort[]) {
    const loadedAnnoyingNotifications = notifications.filter((n) => n.annoying);
    const sessionData = sessionStorage.getItem('annoyingNotificationsIds');
    if (!loadedAnnoyingNotifications.length) {
      sessionStorage.removeItem('annoyingNotificationsIds');
      return;
    }
    const loadedAnnoyingNotificationsIds = loadedAnnoyingNotifications.map((o) => (o.id));
    if (sessionData && sessionData.length) {
      const sessionObjects: string[] = JSON.parse(sessionData);
      let needShow: boolean = false;
      for (const notification of loadedAnnoyingNotifications) {
        if (!sessionObjects.includes(notification.id)) {
          needShow = true;
          break;
        }
      }
      if (needShow) {
        this.activateNotificationsPanelSubject.next();
      }
    } else {
      this.activateNotificationsPanelSubject.next();
    }
    sessionStorage.setItem('annoyingNotificationsIds', JSON.stringify(loadedAnnoyingNotificationsIds));
  }

  /**
   * Инициализация сессии слежения
   */
  private initSession(): Observable<string> {
    return this.trackingService.getSessions().pipe(flatMap((sessions) => {
      if (sessions.length) {
        const session = sessions[0];
        this.store.trackingSettings = session.trackingSettings || this.getDefaultTrackingSettings();
        this.store.sessionId = session.id;
        return of(session.id);
      }

      return this.trackingService.addSession().pipe(tap((sessionId) => {
        this.store.trackingSettings = this.getDefaultTrackingSettings();
        this.store.sessionId = sessionId;
      }));
    }));
  }

  /**
   * Обработки после инициализации сессии слежения
   * @param sessionId Идентификатор инициализированной сессии
   */
  private onInitSession = (sessionId: string) => {
    this.trackingService.getTrackingUnits(sessionId).subscribe(
      this.onGetTrackingUnits,
      (error) => this.modalService.showError(error)
    );

    // Грузим список геозон
    this.getGeozones();

    // Грузим список водителей
    this.getDrivers();

    // Грузим список прицепов
    this.getTrailers();

    // Грузим список маршрутов
    this.getRoutes();
  }

  /**
   * Обработки при получении объектов слежения
   * @param units Список объектов слежения
   */
  private onGetTrackingUnits = (units: ITrackingUnit[]) => {
    const moveThreshold = this.store.trackingSettings
      ? this.store.trackingSettings.moveThreshold
      : 15;

    this.store.units = units.map((unit) => {
      const light = this.onlineNotificationsService.getUnitNotificationTrafficLight(unit.id);
      unit.infoIconVisible = unit.detail;
      return new TrackingUnit(unit, moveThreshold, light);
    });

    this.showUnitsSubject.next(this.store.units.filter((unit) => unit.checked));
    this.centerMapByGeoloc();

    if (this.trackingSubscription) {
      this.trackingSubscription.unsubscribe();
    }
    this.trackingSubscription = timer(0, 1000).subscribe(this.getTrackingInfo);

    // Грузим список групп объектов после того, как загрузятся ТС
    this.trackingService.getGroups(this.store.sessionId)
      .pipe(tap(this.onGetGroups))
      .subscribe(() => this.trackingUnitFillSubject.next(), (error) => this.modalService.showError(error));
  }

  /**
   * Центрирование карты по геолокации (если нет отслеживаемых ТС)
   */
  private centerMapByGeoloc() {
    if (this.store.units.some((unit) => unit.eye)) {
      return;
    }

    const defaultPos: ICoord = { ln: 53.224853, lt: 56.852023 };

    if (!navigator.geolocation) {
      this.translator.get('component.map.geolocation-error2')
        .subscribe((x) => this.modalService.showWarning(x));
      this.moveToPointSubject.next(defaultPos);
    }

    const success = (pos: Position) => {
      const point: ICoord = { lt: pos.coords.latitude, ln: pos.coords.longitude };
      this.moveToPointSubject.next(point);
    };

    const error = (err: PositionError) => {
      if (err.code !== err.PERMISSION_DENIED) {
        this.translator.get('component.map.geolocation-error', { val: err.message })
          .subscribe((x) => this.modalService.showWarning(x));
      }

      this.moveToPointSubject.next(defaultPos);
    };

    const options: PositionOptions = { timeout: 30000 };

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  /**
   * Проверка валидности координаты точки
   * @param coordinate Координата
   */
  private isValidCoordinate(coordinate: ICoord): boolean {
    return coordinate.lt && coordinate.ln && Math.abs(coordinate.lt) <= 90 && Math.abs(coordinate.ln) <= 180;
  }

  /** Получение объекта настроек сессии слежения по умолчанию */
  private getDefaultTrackingSettings(): ITrackingSettings {
    return {
      connectionThreshold: 15,
      fastRacePeriod: FastPeriodType.TODAY,
      actual: true,
      connection: true,
      del: true,
      event: true,
      eye: true,
      move: true,
      properties: true,
      race: true,
      report: true,
      sensor: true,
      trackGeozone: true,
      commands: true,
      moveThreshold: 15
    };
  }

  /**
   * Обработка ошибки при выполнении периодического запроса
   * @param error Текст ошибки
   */
  private handlePeriodicRequestError(error: string) {
    if (error === tranny.instant('errors.connection-lost')) {
      this.loseConnectionSubject.next();
    } else {
      // Не отображаем ошибку пользователю для периодических запросов.
      // Иначе может вылезти множество ошибок при обновлении bgps или ннвм.
      console.error(error);
    }
  }
}

export interface IExpand {
  isExpand: boolean,
  updatePosition: boolean
}
