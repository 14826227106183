import { Pipe, PipeTransform } from '@angular/core';

import { IClientUnitListItem } from '../classes/IClientUnitListItem';

/**
 * Фильтр для списка ТС в администрировании
 */
@Pipe({
  name: 'unitsfilter',
  pure: false
})
export class UnitsFilterPipe implements PipeTransform {
  public transform(items: IClientUnitListItem[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => {
        return (item.name && item.name.toLowerCase().includes(lowerFilter)) ||
          (item.creator && item.creator.toLowerCase().includes(lowerFilter)) ||
          (item.account && item.account.toLowerCase().includes(lowerFilter)) ||
          (item.uid && item.uid.toLowerCase().includes(lowerFilter));
      }
    );
  }
}
