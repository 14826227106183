import { Component, ComponentFactoryResolver, Injector, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { BgPopoverComponent } from './bg-popover.component';

@Component({
  selector: 'bg-popover-wrapper',
  template: `
    <div #container style="position: absolute;z-index: 1090;">
      <ng-template #element></ng-template>
    </div>`
})
export class BgPopoverWrapperComponent {

  @ViewChild('element', {read: ViewContainerRef, static: true})
  public element: ViewContainerRef;

  @ViewChild('container', {static: true})
  public container;

  private content: BgPopoverComponent<any>;

  constructor(private resolver: ComponentFactoryResolver) { }

  public addComponent<T>(component: Type<BgPopoverComponent<T>>) {
    const factory = this.resolver.resolveComponentFactory(component);
    const injector = Injector.create({
      providers: [],
      parent: this.element.injector
    });
    const componentRef = factory.create(injector);
    this.element.insert(componentRef.hostView);
    this.content = componentRef.instance as BgPopoverComponent<T>;
    return this.content;
  }
}
