import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGeozoneGroup } from '../../shared/geozone-group/IGeozoneGroup';
import { IGeozoneListGroup } from '../../shared/geozones/IGeozoneListGroup';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';

/**
 * Сервис для работы с группами геозон
 */
@Injectable()
export class GeozoneGroupService {

  /** Базовая часть url */
  private readonly baseUrl = `${this.configService.url}/geozone-groups`;

  /**
   * Конструктор
   * @param http
   * @param configService
   * @param loadingService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Метод получения одной группы
   * @param id Идентификатор группы
   */
  public getOne(id: string): Observable<IGeozoneGroup> {
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.get<IGeozoneGroup>(`${this.baseUrl}/${id}`, options)
  }

  /**
   * Получение групп геозон для левой панели
   * @param accountId Идентификатор аккаунта
   */
  public getAllForList(accountId?: string): Observable<IGeozoneListGroup[]> {
    const options = { headers: this.loadingService.waiterHeader }
    const params = accountId ? `?accountId=${accountId}` : ''
    return this.http.get<IGeozoneListGroup[]>(`${this.baseUrl}/small${params}`, options)
  }

  /**
   * Создаем или обновляем группу
   * @param group Группа
   */
  public updateGroup(group: IGeozoneGroup): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl, group)
  }
}
