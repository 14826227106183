import { ReportTableFilterType } from './ReportTableFilterType';

/**
 * Действие фильтра
 */
export type TableFilterAction = UncompleteIntervalFilterAction | OtherFilterAction | WithGroupFilterAction;

/**
 * Действие фильтра по незавершенным интервалам
 */
export enum UncompleteIntervalFilterAction {
  /** Обрезать */
  TRUNCATE = 1,
  /** Скрывать */
  HIDE,
  /** Помечать незавершенным */
  MARK_UNCOMPLETE
}

/**
 * Действие фильтра с группой
 */
export enum WithGroupFilterAction {
  /** С элементом */
  WITH = 1,
  /** Без элемента */
  WITHOUT,
  /** Группа элементов */
  GROUP
}

/**
 * Действие других фильтров
 */
export enum OtherFilterAction {
  /** С элементом */
  WITH = 1,
  /** Без элемента */
  WITHOUT
}

/**
 * Получение списка всех действий фильтра
 * @param filterType Тип фильтра
 */
export function getAllActions(
    filterType: ReportTableFilterType
): Array<UncompleteIntervalFilterAction | OtherFilterAction | WithGroupFilterAction> {
  switch (filterType) {
    case ReportTableFilterType.UNCOMPLETE_INTERVAL:
      return [
        UncompleteIntervalFilterAction.TRUNCATE,
        UncompleteIntervalFilterAction.HIDE,
        UncompleteIntervalFilterAction.MARK_UNCOMPLETE
      ];
    case ReportTableFilterType.MOVE:
    case ReportTableFilterType.STOP:
    case ReportTableFilterType.PARKING:
    case ReportTableFilterType.SENSOR:
    case ReportTableFilterType.FILLING:
    case ReportTableFilterType.THEFT:
      return [
        OtherFilterAction.WITH,
        OtherFilterAction.WITHOUT
      ];
    case ReportTableFilterType.DRIVER:
    case ReportTableFilterType.TRAILER:
      return [
        WithGroupFilterAction.WITH,
        WithGroupFilterAction.WITHOUT,
        WithGroupFilterAction.GROUP
      ];
    default:
      return [];
  }
}

/**
 * Получение наименования действия фильтра
 * @param action Действие
 * @param filterType Тип фильтра
 */
export function getActionName(
  action: UncompleteIntervalFilterAction | OtherFilterAction | WithGroupFilterAction,
  filterType: ReportTableFilterType
): string {
  switch (filterType) {
    case ReportTableFilterType.UNCOMPLETE_INTERVAL:
      switch (action) {
        case UncompleteIntervalFilterAction.TRUNCATE: return 'enums.reports.tables.filter-action.cut';
        case UncompleteIntervalFilterAction.HIDE: return 'enums.reports.tables.filter-action.hide';
        case UncompleteIntervalFilterAction.MARK_UNCOMPLETE: return 'enums.reports.tables.filter-action.mark';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.MOVE:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-move';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-move';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.STOP:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-stop';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-stop';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.PARKING:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-parking';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-parking';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.SENSOR:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-sensors';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-sensors';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.FILLING:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-fueling';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-fueling';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.THEFT:
      switch (action) {
        case OtherFilterAction.WITH: return 'enums.reports.tables.filter-action.with-theft';
        case OtherFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-theft';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.DRIVER:
      switch (action) {
        case WithGroupFilterAction.WITH: return 'enums.reports.tables.filter-action.with-driver';
        case WithGroupFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-driver';
        case WithGroupFilterAction.GROUP: return 'enums.reports.tables.filter-action.driver-group';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    case ReportTableFilterType.TRAILER:
      switch (action) {
        case WithGroupFilterAction.WITH: return 'enums.reports.tables.filter-action.with-trail';
        case WithGroupFilterAction.WITHOUT: return 'enums.reports.tables.filter-action.without-trail';
        case WithGroupFilterAction.GROUP: return 'enums.reports.tables.filter-action.trail-group';
        default: return 'enums.reports.tables.filter-action.unknown';
      }
    default:
      return 'enums.reports.tables.filter-action.null';
  }
}
