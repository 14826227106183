import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IGeozoneMap } from '../../shared/geozones/IGeozoneMap';
import { ITrackingGroup } from '../../shared/tracking/ITrackingGroup';
import { ITrackingInfo } from '../../shared/tracking/ITrackingInfo';
import { ITrackingSession } from '../../shared/tracking/ITrackingSession';
import { ITrackingSettings } from '../../shared/tracking/ITrackingSettings';
import { ITrackingUnit } from '../../shared/tracking/ITrackingUnit';
import { ITrackingUnitChange, TrackingUnitChangeType } from '../../shared/tracking/ITrackingUnitChange';
import { ITrackingUpdate } from '../../shared/tracking/ITrackingUpdate';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';

/**
 * Сервис слежения
 */
@Injectable()
export class TrackingService {

  /** Базовый url */
  private readonly baseUrl = `${this.configService.url}/tracking`;
  /** Подписка на прокрутку списка к элементу по индексу */
  public trackingScrollToIndexSubject: Subject<number> = new Subject<number>();

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   * @param loadingService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private loadingService: LoadingService
  ) {}

  /**
   * Добавление сессии
   */
  public addSession(): Observable<string> {
    const url = `${this.baseUrl}/new`;
    return this.http.get<string>(url);
  }

  /**
   * Получение данных слежения
   * @param sessionId Идентификатор сессии
   * @param time Время последнего получения данных сессии
   */
  public getTrackingInfo(sessionId: string, time: number): Observable<ITrackingUpdate> {
    const url = `${this.baseUrl}/update/${sessionId}/${time}`;
    return this.http.get<ITrackingUpdate>(url);
  }

  /**
   * Получение данных слежения по объекту мониторинга
   * @param sessionId Идентификатор сессии
   * @param unitId: Идентификатор объекта мониторинга
   */
  public getUnitTrackingInfo(sessionId: string, unitId: string): Observable<ITrackingInfo> {
    const url = `${this.baseUrl}/${sessionId}/${unitId}`;
    return this.http.get<ITrackingInfo>(url);
  }

  /**
   * Получение данных слежения по объекту мониторинга
   * @param sessionId Идентификатор сессии
   * @param unitId: Идентификатор объекта мониторинга
   */
  public getFullUnitTrackingInfo(sessionId: string, unitId: string): Observable<ITrackingInfo> {
    const url = `${this.baseUrl}/${sessionId}/${unitId}?fullInfo=1`;
    return this.http.get<ITrackingInfo>(url);
  }

  /**
   * Получение объектов слежения
   * @param sessionId Идентификатор сессии
   */
  public getTrackingUnits(sessionId: string): Observable<ITrackingUnit[]> {
    const url = `${this.baseUrl}/${sessionId}/units`;
    return this.http.get<ITrackingUnit[]>(url);
  }

  /**
   * Получение объекта слежения
   * @param unitId Идентификатор объекта
   */
  public getTrackingUnit(unitId: string): Observable<ITrackingUnit> {
    const url = `${this.baseUrl}/units/${unitId}`;
    return this.http.get<ITrackingUnit>(url);
  }

  /**
   * Добавление объекта в сессию
   * @param sessionId Идентификатор сессии
   * @param unitId Идентификатор объекта мониторинга
   */
  public addUnit(sessionId: string, unitId: string): Observable<ITrackingInfo> {
    return this.updateUnit(sessionId, unitId, TrackingUnitChangeType.ADD);
  }

  /**
   * Удаление объекта из сессии
   * @param sessionId Идентификатор сессии
   * @param unitId Идентификатор объекта мониторинга
   */
  public deleteUnit(sessionId: string, unitId: string): Observable<string> {
    return this.updateUnit(sessionId, unitId, TrackingUnitChangeType.DELETE);
  }

  /**
   * Получение списка сессий текущего пользователя
   */
  public getSessions(): Observable<ITrackingSession[]> {
    const url = `${this.baseUrl}/sessions`;
    return this.http.get<ITrackingSession[]>(url);
  }

  /**
   * Изменение объекта сессии
   * @param sessionId Идентификатор сессии
   * @param unitId Идентификатор объекта мониторинга
   * @param type Тип изменения
   */
  public updateUnit(sessionId: string, unitId: string, type: TrackingUnitChangeType): Observable<any> {
    const url = `${this.baseUrl}/${sessionId}`;
    const body: ITrackingUnitChange = { unitId, type };
    return this.http.post<any>(url, body);
  }

  /**
   * Добавление геозоны в сессию
   * @param sessionId Идентификатор сессии
   * @param geozoneId Идентификатор геозоны
   */
  public addGeozone(sessionId: string, geozoneId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/geozones/${geozoneId}`;
    return this.http.get<string>(url);
  }

  /**
   * Удаление геозоны из сессии
   * @param sessionId Идентификатор сессии
   * @param geozoneId Идентификатор геозоны
   */
  public deleteGeozone(sessionId: string, geozoneId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/geozones/${geozoneId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Получение списка водителей сессии
   * @param sessionId Идентификатор сессии
   */
  public getDrivers(sessionId: string): Observable<string[]> {
    const url = `${this.baseUrl}/${sessionId}/drivers`;
    return this.http.get<string[]>(url);
  }

  /**
   * Добавление водителя в сессию
   * @param sessionId Идентификатор сессии
   * @param driverId Идентификатор водителя
   */
  public addDriver(sessionId: string, driverId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/drivers/${driverId}`;
    return this.http.get<string>(url);
  }

  /**
   * Удаление водителя из сессии
   * @param sessionId Идентификатор сессии
   * @param driverId Идентификатор водителя
   */
  public deleteDriver(sessionId: string, driverId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/drivers/${driverId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Получение списка прицепов сессии
   * @param sessionId Идентификатор сессии
   */
  public getTrailers(sessionId: string): Observable<string[]> {
    const url = `${this.baseUrl}/${sessionId}/trailers`;
    return this.http.get<string[]>(url);
  }

  /**
   * Добавление прицепа в сессию
   * @param sessionId Идентификатор сессии
   * @param trailerId Идентификатор прицепа
   */
  public addTrailer(sessionId: string, trailerId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/trailers/${trailerId}`;
    return this.http.get<string>(url);
  }

  /**
   * Удаление прицепа из сессии
   * @param sessionId Идентификатор сессии
   * @param trailerId Идентификатор прицепа
   */
  public deleteTrailer(sessionId: string, trailerId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/trailers/${trailerId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Обновляем список групп в сессии слежения
   * @param sessionId - идентификатор сессии
   * @param groupList - список групп
   */
  public updateGroups(sessionId: string, groupList: string[]): Observable<void> {
    const url = `${this.baseUrl}/${sessionId}/groups/`;
    return this.http.put<void>(url, groupList);
  }

  /**
   * Удаление группы из сессии
   * @param sessionId Идентификатор сессии
   * @param groupId Идентификатор группы
   */
  public deleteGroup(sessionId: string, groupId: string): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/groups/${groupId}`;
    return this.http.delete<string>(url);
  }

  /**
   * Получение списка групп сессии
   * @param sessionId Идентификатор сессии
   */
  public getGroups(sessionId: string): Observable<ITrackingGroup[]> {
    const url = `${this.baseUrl}/${sessionId}/groups`;
    return this.http.get<ITrackingGroup[]>(url);
  }

  /**
   * Обновление настроек слежения
   * @param sessionId Идентификатор сессии
   * @param body Настройки слежения
   */
  public updateTrackingSettings(sessionId: string, body: ITrackingSettings): Observable<string> {
    const url = `${this.baseUrl}/${sessionId}/settings`;
    return this.http.post<string>(url, body);
  }

  /**
   * Добавляем геозоны в сессию
   * @param sessionId Идентификатор сессии
   * @param geozoneIds Идентификаторы геозон
   */
  public addTrackingGeozones(sessionId: string, geozoneIds: string[]): Observable<IGeozoneMap[]> {
    const options = { headers: this.loadingService.waiterHeader }
    return this.http.post<IGeozoneMap[]>(`${this.baseUrl}/${sessionId}/geozones/add`, { geozoneIds }, options)
  }

  /**
   * Удаляем геозоны в сессию
   * @param sessionId Идентификатор сессии
   * @param geozoneIds Идентификаторы геозон
   */
  public deleteTrackingGeozones(sessionId: string, geozoneIds: string[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/${sessionId}/geozones/delete`, { geozoneIds })
  }

  /**
   * Получаем настройки сервера текущего пользователя
   */
  public getOnlyTrackingSettings(): Observable<ITrackingSettings> {
    return this.http.get<ITrackingSettings>(`${this.baseUrl}/settings`)
  }
}
