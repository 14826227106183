import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';

import { IDocsSettings } from '../../../../../shared/crm/dillers/IDocsSettings';
import { DetailTab } from '../../../../classes/DetailTab';
import { DillersService } from '../../../../services/dillers.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';

/**
 * Компонент для настроек формирования документов клиентов дилера
 */
@Component({
  selector: 'diller-docs-settings',
  templateUrl: './diller.docs-settings.component.html',
  styleUrls: ['./diller.docs-settings.component.scss']
})
export class DillerDocsSettingsComponent {

  /** Настройки формирования документов клиентов дилера */
  public settings: IDocsSettings;

  /** Вкладки */
  public tabs: DetailTab[];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param dillersService Сервис для работы с дилерами
   * @param modalService Сервис для отображения модельных окон
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private dillersService: DillersService,
    private modalService: ModalService,
    private translator: TranslateService
  ) {
    this.tabs = [
      new DetailTab('component.crm.dealer.docs-settings.requisites'),
      new DetailTab('component.crm.dealer.docs-settings.logo'),
      new DetailTab('component.crm.dealer.docs-settings.signature'),
      new DetailTab('component.crm.dealer.docs-settings.stamp'),
      new DetailTab('component.crm.dealer.docs-settings.more')
    ];

    this.settings = { } as IDocsSettings;

    this.loadingService.withLoading(
      this.dillersService.getDocsSettings(),
      (settings) => this.settings = settings
    );

  }

  /**
   * Чтение изображения как DataURL
   * @param event Объект события, срабатывающего при выборе файла
   * @param field Поле, в которое необходимо поместить результат
   */
  public readImageAsDataURL(event: any, field: string) {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      if (file.size > 51200) {
        this.modalService.showError(this.translator.instant('component.crm.dealer.docs-settings.error'));
        event.target.value = '';
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent) => {
        this.settings[field] = (e.target as FileReader).result as string;
      };

      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.settings[field] = null;
    }
  }

  /** Здесь не нужно */
  public isCan(): boolean {
    return true;
  }

  /** Проверка на возможность сохранения */
  public get isCanSave() {
    return this.settings && !this.nullOrEmpty(this.settings.name) &&
      !this.nullOrEmpty(this.settings.inn) && !this.nullOrEmpty(this.settings.kpp) &&
      !this.nullOrEmpty(this.settings.bank) && !this.nullOrEmpty(this.settings.rSchet) &&
      !this.nullOrEmpty(this.settings.kSchet) && !this.nullOrEmpty(this.settings.bik) &&
      !this.nullOrEmpty(this.settings.address) && !this.nullOrEmpty(this.settings.phone);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) { return; }

    this.loadingService.wrap(this.dillersService.saveDocsSettings(this.settings)).subscribe();
  }

  /**
   * Проверка на то что значение поля не заполнено
   * @param field Значение поля
   */
  private nullOrEmpty(field: string) {
    return !field || field === '';
  }
}
