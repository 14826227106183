import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IItemOrGroupGeneric } from '../../shared/IItemOrGroup';
import { ISendReport } from '../../shared/reports/ISendReport';
import { IChangeAccess } from '../../shared/rights/IChangeAccess';
import { SensorType } from '../../shared/sensors/SensorType';
import { ITimeLimits } from '../../shared/time/ITimeLimits';
import { IChangeCounter } from '../../shared/units/IChangeCounter';

import { ConfigService } from './config.service';
import { TrafficLightType } from './online-notifications.service';

/**
 * Сервис для работы с уведомлениями
 */
@Injectable()
export class NotificationsService {

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/notifications`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService Сервис конфигурации
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Получение списка
   */
  public getList(): Observable<INotificationSettingInfo[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<INotificationSettingInfo[]>(url);
  }

  /**
   * Получение подробной информации о записи
   * @param id Идентификатор записи
   */
  public get(id: string): Observable<INotificationSetting> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<INotificationSetting>(url);
  }

  /**
   * Добавление записи
   * @param body Данные записи
   */
  public create(body: INotificationSetting): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.post<string>(url, body);
  }

  /**
   * Изменение записи
   * @param body Данные записи
   */
  public update(body: INotificationSetting): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.put<string>(url, body);
  }

  /**
   * Удаление записи
   * @param id Идентификатор записи
   */
  public remove(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<string>(url);
  }
}

/**
 * Типы уведомлений
 */
export enum NotificationType {
  /** Скорость */
  SPEED = 1,
  /** Геозона */
  GEOZONE,
  /** Значение датчика */
  SENSOR,
  /** Потеря связи */
  LOSS_CONNECTION,
  /** Простой */
  PARKING,
  /** Заправка */
  FILLING,
  /** Слив */
  THEFT,
  /** Водитель */
  DRIVER,
  /** Прицеп */
  TRAILER,
  /** Техобслуживание */
  MAINTENANCE,
  /** Тревога */
  ALARM = 12
}

/**
 * Получение наименования типа уведомления
 * @param type Тип уведомления
 */
export function getNotificationTypeName(type: NotificationType): string {
  switch (type) {
    case NotificationType.SPEED:
      return 'enums.notifications.type.speed';
    case NotificationType.GEOZONE:
      return 'enums.notifications.type.geo';
    case NotificationType.SENSOR:
      return 'enums.notifications.type.sensor';
    case NotificationType.LOSS_CONNECTION:
      return 'enums.notifications.type.disconnection';
    case NotificationType.PARKING:
      return 'enums.notifications.type.idle';
    case NotificationType.FILLING:
      return 'enums.notifications.type.fueling';
    case NotificationType.THEFT:
      return 'enums.notifications.type.theft';
    case NotificationType.DRIVER:
      return 'enums.notifications.type.driver';
    case NotificationType.TRAILER:
      return 'enums.notifications.type.trail';
    case NotificationType.MAINTENANCE:
      return 'enums.notifications.type.service';
    case NotificationType.ALARM:
      return 'enums.notifications.type.alarm';
    default:
      return 'enums.notifications.type.unknown';
  }
}

/**
 * Тип действия уведомления
 */
export enum NotificationActionType {
  /** Онлайн-уведомление */
  ONLINE = 1,
  EMAIL,
  EVENT,
  REMOVE_DRIVER,
  REMOVE_TRAILER,
  CHANGE_MILEAGE_COUNTER,
  CHANGE_MH_COUNTER,
  CHANGE_ACCESS,
  SEND_REPORT,
  MOBILE
}

/**
 * Получение наименования типа действия уведомления
 * @param type Тип действия уведомления
 */
export function getNotificationActionTypeName(type: NotificationActionType): string {
  switch (type) {
    case NotificationActionType.ONLINE:
      return 'enums.notifications.action-type.online-notification';
    case NotificationActionType.EMAIL:
      return 'enums.notifications.action-type.email';
    case NotificationActionType.EVENT:
      return 'enums.notifications.action-type.add-event';
    case NotificationActionType.REMOVE_DRIVER:
      return 'enums.notifications.action-type.remove-driver';
    case NotificationActionType.REMOVE_TRAILER:
      return 'enums.notifications.action-type.remove-trail';
    case NotificationActionType.CHANGE_MILEAGE_COUNTER:
      return 'enums.notifications.action-type.mileage';
    case NotificationActionType.CHANGE_MH_COUNTER:
      return 'enums.notifications.action-type.motor-hours';
    case NotificationActionType.CHANGE_ACCESS:
      return 'enums.notifications.action-type.access';
    case NotificationActionType.SEND_REPORT:
      return 'enums.notifications.action-type.send-email';
    case NotificationActionType.MOBILE:
      return 'enums.notifications.action-type.mobile';
    default:
      return 'enums.notifications.action-type.unknown';
  }
}

/**
 * Информация по настройкам уведомления
 */
export interface INotificationSettingInfo {
  /** Идентификатор записи */
  id: string;
  /** Наименование */
  name: string;
  /** Тип */
  type: NotificationType;
  /** Тип */
  typeName: string;
  /** Признак активности */
  active: boolean;
  /** Учетная запись */
  account: string;
  /** Дата создания */
  createdAt: number;
}

/**
 * Настройки уведомления
 */
export interface INotificationSetting extends INotificationSettingGeneric {
  /** Идентификатор записи */
  id: string;
  /** Наименование учетной записи, к которой относится уведомление */
  account: string;
}

/**
 * Настройки уведомления (обощенный интерфейс)
 */
export interface INotificationSettingGeneric {
  /** Наименование */
  name: string;
  /** Идентификатор учетной записи */
  accountId: string;
  /** Тип уведомления */
  type: NotificationType;
  /** Действия */
  actions: INotificationAction[];
  /** Шаблон сообщения */
  message: string;
  /** Признак активного уведомления */
  active: boolean;
  /** Минимальная длительность */
  minDuration?: string;
  /** Максимальное количество срабатываний */
  maxCount?: number;
  /** Значение светофора */
  trafficLight?: TrafficLightType;
  /** Список объектов уведомления */
  units: IItemOrGroupGeneric<string>[];
  /** Ограничения по времени выполнения */
  timeLimits?: ITimeLimits;
  /** Список идентификаторов пользователей которым нужно отослать уведомление */
  deviceUserIds?: string[];
  /** Ограничения по скорости */
  speed?: INotificationSpeed;
  /** Ограничения по значению датчика */
  sensorValue?: INotificationSensorValue;
  /** Ограничения по вхождению в геозоны */
  geozones?: INotificationGeozones;
  /** Ограничения по потере связи */
  lossConnection?: INotificationLossConnection;
  /** Ограничения по длительности простоя */
  parking?: INotificationParking;
  /** Ограничения по заправкам */
  filling?: INotificationFuel;
  /** Ограничения по сливам */
  theft?: INotificationFuel;
  /** Ограничение по водителю */
  driver?: INotificationAssignment;
  /** Ограничение по прицепу */
  trailer?: INotificationAssignment;
  /** Ограничения по техобслуживанию */
  maintenance?: INotificationMaintenance;
  /** Ограничение по ошибке с CAN-шины */
  canFault?: INotificationCANFault;
  /** Дата создания */
  createdAt?: Date;
  /** Дата изменения */
  updatedAt?: Date;
}

/**
 * Действие уведомления
 */
export interface INotificationAction {
  /** Тип действия */
  type: NotificationActionType;
  /** Цвет */
  color?: string;
  /** Назначения */
  destinations?: string[];
  /** Настройки по учету счетчика */
  changeCounter?: IChangeCounter;
  /** Настройки отправляемого по e-mail отчета */
  sendReport?: ISendReport<string>;
  /** Настройки по изменению доступа */
  changeAccess?: IChangeAccess<string>;
  /** Признак регистрации события в качестве нарушения */
  violation?: boolean;
  /** Звук */
  sound?: boolean;
}

/**
 * Ограничения по вхождению в геозоны
 */
export interface INotificationGeozones {
  /** Список геозон для расчета вхождения */
  inside: IItemOrGroupGeneric<string>[];
  /** Список геозон для расчета выхода */
  outside: IItemOrGroupGeneric<string>[];
}

/**
 * Ограничения по скорости
 */
interface INotificationSpeed {
  /** Минимальная скорость */
  from: number;
  /** Максимальная скорость */
  to: number;
}

/**
 * Ограничения по значению датчика
 */
interface INotificationSensorValue {
  /** Тип датчика */
  type?: SensorType;
  /** Маска датчика */
  mask?: string;
  /** Признак срабатывания по изменению значения */
  calcDelta?: boolean;
  /** Минимальное значение */
  from?: number;
  /** Максимальное значение */
  to?: number;
  /** Изменение значения */
  delta?: number;
  /** Считать одинаковые датчики отдельно */
  calcSeparately?: boolean;
  /** Срабатывать вне диапазона */
  outsideInterval?: boolean;
}

/**
 * Ограничения по потере связи
 */
interface INotificationLossConnection {
  /** Интервал отсутствия связи перед срабатыванием уведомления (в минутах) */
  interval: number;
  /** Признак проверки на отсутствие валидных координат */
  noCoords?: boolean;
  /** Признак необходимости уведомления о восстановлении связи */
  notifyOnRestoreConnection?: boolean;
}

/**
 * Ограничения по простою
 */
interface INotificationParking {
  /** Максимально разрешенная длительность простоя */
  maxAllowed: string;
  /** Максимальная скорость, при которой считается, что объект стоит */
  maxSpeed: number;
}

/**
 * Ограничения по топливу
 */
interface INotificationFuel {
  /** Маска ДУТ */
  mask: string;
  /** Игнорировать пересчитанные данные */
  ignoreRecalc?: boolean;
}

/**
 * Ограничения по уведомлениям назначения/снятия
 */
interface INotificationAssignment {
  /** Маска кода */
  mask: string;
  /** Признак о снятии */
  isDeny?: boolean;
}

/**
 * Ограничения по техобслуживанию
 */
interface INotificationMaintenance {
  /** Маска интервала */
  mask: string;
  /** Интервал срабатывания уведомления в днях */
  days?: number;
  /** Интервал срабатывания уведомления в моточасах */
  mh?: number;
  /** Интервал срабатывания уведомления в километрах */
  mileage?: number;
  /** Признак об истечении наступления срока ТО */
  isExpiry?: boolean;
}

/**
 * Ограничения по ошибке с CAN-шины
 */
interface INotificationCANFault {
  /** Маска кода ошибки */
  mask: string;
}
