import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';

import { BgPopoverHolderComponent } from './bg-popover-holder.component';
import { BgPopoverComponent } from './bg-popover.component';
import { IPopoverPosition } from './IPopoverPosition';

@Injectable()
export class BgPopoverService {

  private popoverHolderComponent: BgPopoverHolderComponent;

  private container: HTMLElement;

  constructor(
    private resolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) { }

  public addPopover<T>(component: Type<BgPopoverComponent<T>>, position: IPopoverPosition, data?: T) {
    if (!this.popoverHolderComponent) {
      this.popoverHolderComponent = this.createPopoverHolder();
    }
    this.popoverHolderComponent.clear();
    this.popoverHolderComponent.addPopover(component, position, data);
  }

  public fitPopover() {
    const container = this.popoverHolderComponent.wrapper.container.nativeElement;
    const rect = container.firstElementChild.getBoundingClientRect();
    if (rect.width > window.innerWidth) {
      container.style.left = '0';
      container.style.right = 'unset';
      container.style.width = `${window.innerWidth}px`;
    } else if (rect.left < 0) {
      container.style.left = '0';
      container.style.right = 'unset';
      container.style.width = `${rect.width}px`;
    } else if (rect.right > window.innerWidth) {
      container.style.left = 'unset';
      container.style.right = '0';
      container.style.width = `${rect.width}px`;
    }
    if (rect.height > window.innerHeight) {
      container.style.top = '0';
      container.style.bottom = 'unset';
      container.style.height = `${window.innerHeight}px`;
    } else if (rect.top < 0) {
      container.style.top = '0';
      container.style.bottom = 'unset';
      container.style.height = `${rect.height}px`;
    } else if (rect.bottom > window.innerHeight) {
      container.style.top = 'unset';
      container.style.bottom = '0';
      container.style.height = `${rect.height}px`;
    }
  }

  public removePopover() {
    if (this.popoverHolderComponent) {
      this.popoverHolderComponent.clear();
    }
  }

  private createPopoverHolder() {
    const componentFactory = this.resolver.resolveComponentFactory(BgPopoverHolderComponent);

    const componentRef = componentFactory.create(this.injector);
    const componentRootNode = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    if (!this.container) {
      this.container = (this.applicationRef.components[0].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }
    this.applicationRef.attachView(componentRef.hostView);

    componentRef.onDestroy(
      () => {
        this.applicationRef.detachView(componentRef.hostView);
      }
    );
    this.container.appendChild(componentRootNode);

    return componentRef.instance;
  }
}
