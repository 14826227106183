import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMaintenanceEventInfo } from '../../shared/maintenance/IMaintenanceEventInfo';
import { RightType, UnitRightType } from '../../shared/rights/RightType';
import { ISensorBase } from '../../shared/sensors/ISensorBase';
import { IUnitLight } from '../../shared/units/IUnitLight';
import { IUnitMoveOptions } from '../../shared/units/IUnitMoveOptions';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с объектами мониторинга
 */
@Injectable()
export class UnitsService {

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/units`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Получение списка датчиков объекта мониторинга
   * @param unitId Идентификатор объекта мониторинга
   */
  public getUnitSensors(unitId: string): Observable<ISensorBase[]> {
    const url = `${this.baseUrl}/${unitId}/sensors`;
    return this.http.get<ISensorBase[]>(url);
  }

  /**
   * Получение списка категорий иконок
   */
  public getIconDirs(): Observable<string[]> {
    const url = `${this.baseUrl}/icon-dirs`;
    return this.http.get<string[]>(url);
  }

  /**
   * Получение списка доступных иконок
   */
  public getIcons(dir: string): Observable<string[]> {
    const url = `${this.baseUrl}/icons`;
    let params = new HttpParams();
    if (dir) {
      params = params.append('dir', dir);
    }
    return this.http.get<string[]>(url, { params });
  }

  /**
   * Получение информации по ТО для создания события "Техобслуживание"
   * @param unitId Идентификатор объекта мониторинга
   */
  public getMaintenanceInfo(unitId: string): Observable<IMaintenanceEventInfo> {
    const url = `${this.baseUrl}/${unitId}/maintenance`;
    return this.http.get<IMaintenanceEventInfo>(url);
  }

  /**
   * Получение списка объектов, на которые у текущего пользователя есть указанное право доступа
   * @param right Право доступа
   */
  public getWithRight(right: RightType | UnitRightType): Observable<IUnitLight[]> {
    const url = `${this.baseUrl}/right/${right}`;
    return this.http.get<IUnitLight[]>(url);
  }

  /**
   * Перенос объектов мониторинга из одной учетной записи в другую
   * @param unitIds Список идентификаторов объектов мониторинга
   * @param destAccountId Идентификатор учетной записи назначения
   * @param options Дополнительные параметры
   */
  public move(unitIds: string[], destAccountId: string, options: IUnitMoveOptions): Observable<string[]> {
    const url = `${this.baseUrl}/move`;
    const body = { unitIds, destAccountId, options };
    return this.http.post<string[]>(url, body);
  }
}
