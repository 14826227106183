import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IItemSelectorData } from '../components/select-items-modal/item-selector.component';

@Injectable()
export class ItemSelectorService {

  /**
   * Субъект для передачи данных в компонент выбора элемента
   */
  public openSubject = new Subject<IItemSelectorData>();
}
