import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '../../../services/config.service';

/**
 * Сервис аутентификации
 */
@Injectable()
export class BgAuthService {

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param router Роутер
   * @param translator Сервис для перевода
   * @param jwtHelperService Сервис для работы с JWT
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private translator: TranslateService,
    private jwtHelperService: JwtHelperService,
    private configService: ConfigService
  ) {}

  /**
   * Признак того, что пользователь аутентифицирован
   * @returns True, если аутентифицирован, иначе - false
   */
  get authenticated(): boolean {
    return localStorage.getItem('token') && !this.jwtHelperService.isTokenExpired();
  }

  /**
   * Получение токена авторизации
   */
  get token(): string {
    if (this.authenticated) {
      return localStorage.getItem('token');
    }
    return null;
  }

  /**
   * Вход в систему
   * @param username Имя пользователя
   * @param password Пароль
   * @returns True, если вход удачный, иначе - false
   */
  public login(username: string, password: string): Observable<boolean> {
    const body = { username, password };
    const url = `${this.configService.url}/auth/login`;
    return this.http.post<string>(url, body)
      .pipe(map((token: string) => {
        if (!token) {
          return false;
        }

        localStorage.setItem('token', token);
        return true;
      }));
  }

  /**
   * Выход из системы
   */
  public logout() {
    localStorage.removeItem('token');
    this.router.navigate(['login']).then();
  }
}
