import { Component, Input, OnInit } from '@angular/core';

import { UnitRightType } from '../../../../shared/rights/RightType';
import { IExtras } from '../../../../shared/units/IExtras';

/**
 * Вилидация
 */
@Component({
  selector: 'unit-validation',
  templateUrl: './unit.validation.component.html'
})
export class UnitValidationComponent implements OnInit {

  /** Дополнительная информация о объекте мониторинга */
  @Input() public extras: IExtras<string>;

  /** Признак режима добавления */
  @Input() public isAddRegime: boolean;

  /** Права доступа на объект мониторинга */
  @Input() public rights: number;

  /** Признак только для чтения */
  public isReadOnly: boolean;

  /**
   * При инициализации компонента
   */
  ngOnInit(): void {
    // tslint:disable-next-line:no-bitwise
    if (!this.isAddRegime && !(this.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)) {
      this.isReadOnly = true;
    }
  }
}
