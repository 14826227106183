import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TimeSpan } from '../../../../shared/classes/TimeSpan';
import { ITrackingUnit } from '../../../../shared/tracking/ITrackingUnit';
import { IClientGeozone } from '../../../classes/IClientGeozone';
import { TrackingUnit } from '../../../classes/TrackingUnit';
import { GeocoderService } from '../../../services/geocoder.service';
import { StoreService } from '../../../services/store.service';
import { TrackingService } from '../../../services/tracking.service';
import { intervalToString } from '../../../utils/intervals';

/**
 * Компонент с краткой информацией на карте
 */
@Component({
    selector: 'map-detail-light',
    templateUrl: './map.light.component.html',
    styleUrls: ['./map.light.component.scss']
})
export class MapDetailLightComponent implements OnInit {
    /** Идентификатор объекта */
    @Input() public unitId: string;

    /** Список геозон, в которые входит объект */
    @Input() public geozones?: IClientGeozone[];

    /** Объект мониторинга */
    public unit: TrackingUnit;

    /** Текст ошибки */
    public error: string;

    /**
     * Конструктор
     * @param trackingService Сервис мониторинга
     * @param geocoderService Сервис геокодирования
     * @param store Сервис для хранения данных мониторинга
     * @param translator Сервис для перевода
     */
    constructor(
        private trackingService: TrackingService,
        private geocoderService: GeocoderService,
        private store: StoreService,
        private translator: TranslateService
    ) {
        this.unit = new TrackingUnit({} as ITrackingUnit);
    }

    /**
     * Получение признака необходимости отображения датчиков
     */
    public get isShowSensors() {
        return this.store?.user?.settings?.unitDetail?.sensors?.map && this.unit?.sensors?.length;
    }

    /**
     * Получение признака необходимости отображения параметров
     */
    public get isShowParams() {
        return this.store?.user?.settings?.unitDetail?.params?.map && this.unit?.params;
    }

    /**
     * Получение признака необходимости отображения интервалов ТО
     */
    public get isShowTo() {
        return this.store?.user?.settings?.unitDetail?.to?.map && this.unit?.toInfos?.length;
    }

    /**
     * Получение признака необходимости отображения водителей
     */
    public get isShowDrivers() {
        return this.store?.user?.settings?.unitDetail?.drivers?.map;
    }

    /**
     * Получение признака необходимости отображения прицепов
     */
    public get isShowTrailers() {
        return this.store?.user?.settings?.unitDetail?.trailers?.map;
    }

    /**
     * Получение списка наименований всех параметров объекта слежения
     */
    public get paramKeys() {
        return Object.keys(this.unit.params);
    }


    /**
     * Получение признака необходимости отображения времени последнего сообщения
     */
    public get isShowLastMessage() {
        return this.store?.user?.settings?.unitDetail?.time?.map;
    }

    /**
     * Получение признака необходимости отображения адреса
     */
    public get isShowAddress() {
        return this.store?.user?.settings?.unitDetail?.address?.map;
    }

    /**
     * Получение количества времени, прошедшего с момента получения последних данных от объекта
     * @param unit Объект мониторинга
     */
    public getActualTitle(unit: TrackingUnit) {
        if (!unit || !unit.position || unit.position.t === 0) {
            return this.translator.instant('ui.never');
        }
        const timeSpan = TimeSpan.fromPeriod(new Date(unit.position.t), new Date());
        return intervalToString(timeSpan);
    }

    /**
     * Получение признака необходимости отображения геозон
     */
    public get isShowGeozones() {
        return this.store?.user?.settings?.unitDetail?.geozones?.map;
    }

    /**
     * Получение признака необходимости отображения строки со счетчиками
     */
    public get isShowCountersRow() {
        return this.store?.user?.settings?.unitDetail?.speed?.map || this.store?.user?.settings?.unitDetail?.counters?.map;
    }

    /**
     * Получение признака необходимости отображения скорости
     */
    public get isShowSpeed() {
        return this.store?.user?.settings?.unitDetail?.speed?.map;
    }

    /**
     * Получение признака необходимости отображения счетчиков
     */
    public get isShowCounters() {
        return this.store?.user?.settings?.unitDetail?.counters?.map;
    }

    /**
     * Обработки после инициализации компонента
     */
    public ngOnInit() {
        this.trackingService.getTrackingUnit(
            this.unitId
        ).subscribe(
            (result) => {
                const moveThreshold = this.store.trackingSettings
                    ? this.store.trackingSettings.moveThreshold
                    : 15;

                this.unit = new TrackingUnit(result, moveThreshold);
                this.unit.geozones = this.geozones;

                if (this.isShowAddress) {
                    this.geocoderService.getAddress(
                        this.unitId,
                        this.unit.position.lt,
                        this.unit.position.ln
                    ).subscribe((address) => this.unit.address = address);
                }
            },
            (error) => this.error = error
        );
    }
}
