/**
 * Типы фильтрации интервалов в таблицах отчетов
 */
export enum ReportTableFilterType {
  /** Границы значений датчика счетчика */
  COUNTER_BORDERS = 1,
  /** Незавершенный интервал */
  UNCOMPLETE_INTERVAL,
  /** Длительность */
  DURATION,
  /** Пробег */
  MILEAGE,
  /** Моточасы */
  MH,
  /** Скорость */
  SPEED,
  /** Поездки */
  MOVE,
  /** Остановки */
  STOP,
  /** Стоянки */
  PARKING,
  /** Датчики */
  SENSOR,
  /** Водитель */
  DRIVER,
  /** Прицеп */
  TRAILER,
  /** Заправки */
  FILLING,
  /** Сливы */
  THEFT,
  /** Геозоны/объекты */
  GEOZONE_UNIT
}

/**
 * Получение списка всех типов фильтрации интервалов в таблицах отчетов
 */
export function getAllReportTableFilterTypes(): ReportTableFilterType[] {
  const result: ReportTableFilterType[] = [];
  for (let i = 1; i <= 17; i++) {
    result.push(i as ReportTableFilterType);
  }
  return result;
}

/**
 * Получение наименования типа фильтрации интервалов в таблицах отчетов
 * @param type Тип фильтрации интервалов в таблицах отчетов
 */
export function getReportTableFilterTypeName(type: ReportTableFilterType): string {
  switch (type) {
    case ReportTableFilterType.COUNTER_BORDERS: return 'enums.reports.tables.filter-type.limits';
    case ReportTableFilterType.UNCOMPLETE_INTERVAL: return 'enums.reports.tables.filter-type.interval';
    case ReportTableFilterType.DURATION: return 'enums.reports.tables.filter-type.duration';
    case ReportTableFilterType.MILEAGE: return 'enums.reports.tables.filter-type.mileage';
    case ReportTableFilterType.MH: return 'enums.reports.tables.filter-type.motor-hours';
    case ReportTableFilterType.SPEED: return 'enums.reports.tables.filter-type.speed';
    case ReportTableFilterType.MOVE: return 'enums.reports.tables.filter-type.move';
    case ReportTableFilterType.STOP: return 'enums.reports.tables.filter-type.stop';
    case ReportTableFilterType.PARKING: return 'enums.reports.tables.filter-type.parking';
    case ReportTableFilterType.SENSOR: return 'enums.reports.tables.filter-type.sensor';
    case ReportTableFilterType.DRIVER: return 'enums.reports.tables.filter-type.driver';
    case ReportTableFilterType.TRAILER: return 'enums.reports.tables.filter-type.trail';
    case ReportTableFilterType.FILLING: return 'enums.reports.tables.filter-type.fueling';
    case ReportTableFilterType.THEFT: return 'enums.reports.tables.filter-type.theft';
    case ReportTableFilterType.GEOZONE_UNIT: return 'enums.reports.tables.filter-type.geo';
    default: return 'enums.reports.tables.filter-type.unknown';
  }
}
