/**
 * Типы сообщений
 */
export enum MessageType {
  /** События */
  EVENT = 1,
  /** Сообщения с данными */
  POINTS
}

/**
 * Получение наименование типа сообщения
 * @param type Тип сообщения
 */
export function getMessageTypeName(type: MessageType): string {
  switch (type) {
    case MessageType.EVENT: return 'enums.messages.type.message';
    case MessageType.POINTS: return 'enums.messages.type.data';
    default: return 'enums.messages.type.unknown';
  }
}
