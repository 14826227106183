/**
 * Типы событий
 */
export enum EventType {
  /** Заправка */
  FILLING = 1,
  /** Произвольное событие */
  ARBITRARY,
  /** Техобслуживание */
  MAINTENANCE,
  /** Статус объекта */
  STATE,
  /** Запись в журнале объекта */
  JOURNAL
}

/**
 * Получение списка всех типов событий
 */
export function getAllEventTypes(): EventType[] {
  const result: EventType[] = [];
  // TODO: пока получаем только заправку, произвольное событие и ТО
  for (let i = 1; i <= 3; i++) {
    result.push(i as EventType);
  }
  return result;
}

/**
 * Получение наименования типа события
 * @param type Тип события
 */
export function getEventTypeName(type: EventType): string {
  switch (type) {
    case EventType.FILLING: return 'enums.events.type.fueling';
    case EventType.ARBITRARY: return 'enums.events.type.event';
    case EventType.MAINTENANCE: return 'enums.events.type.service';
    case EventType.STATE: return 'enums.events.type.status';
    case EventType.JOURNAL: return 'enums.events.type.journal';
    default: return 'enums.events.type.unknown';
  }
}
