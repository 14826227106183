import { Component } from '@angular/core';

import { SectionComponent } from '../../../classes/SectionComponent';
import { ActivityService } from '../../../services/activity.service';
import { NavigationPanelType, NavigationService } from '../../../services/navigation.service';

/**
 * Компонент, представляющий CRM
 */
@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent extends SectionComponent {

  /**
   * Ссылка на типы панели навигации
   */
  public nType = NavigationPanelType;

  /**
   * Конструктор
   * @param activityService Сервис учета активности пользователей
   * @param navigationService
   */
  constructor(
    activityService: ActivityService,
    public navigationService: NavigationService
  ) {
    super(activityService);
  }
}
