import { Component, ElementRef, Input } from '@angular/core';

import { BgPopoverService } from '../../modules/bg-popover';
import { IPopoverPosition } from '../../modules/bg-popover/IPopoverPosition';
import { getScreenCordinates } from '../../utils/coords';
import { BgTooltipComponent } from '../tooltip/bg-tooltip.component';

@Component({
  selector: 'bg-info',
  templateUrl: './bg-info.component.html',
  styleUrls: ['./bg-info.component.scss']
})

/**
 * Подсказка
 */
export class BgInfoComponent {

  /**
   * Текст подсказки или ключ на строку в языковом файле
   */
  @Input()
  public text: string;

  /**
   * Конструктор
   * @param popoverService Сервис всплывающих сообщений
   * @param element Элемент
   */
  constructor(
    private popoverService: BgPopoverService,
    private element: ElementRef
  ) {}

  /**
   * Отображение всплывашки с текстом
   * @param event Событие
   */
  public show(event: MouseEvent) {
    event.stopPropagation();
    const ne = this.element.nativeElement;
    const position: IPopoverPosition = getScreenCordinates(ne);
    position.x += ne.offsetWidth;
    position.y += ne.offsetHeight;
    this.popoverService.addPopover(BgTooltipComponent, position, {text: this.text});
  }
}
