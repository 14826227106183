import { Pipe, PipeTransform } from '@angular/core';

/**
 * Фильтр по наименованию
 */
@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  public transform(items: Array<{ name: string }>, filter: string): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
  }
}
