import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { ITariff } from '../../../../shared/crm/tariffs';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ModalResult } from '../../modal/modal.component';

import { TariffEditComponent } from './edit/tariffs.edit.component';

/**
 * Компонент для отображения списка тарифов
 */
@Component({
  selector: 'tariffs',
  templateUrl: './tariffs.component.html'
})
export class TariffsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: ITariff[];
  /**
   * Выбранный элемент
   */
  public selected: ITariff;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;

  /**
   * Конструктор
   * @param crudService Сервис для работы с CRUD
   * @param loadingService Сервис отображения процесса загрузки
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private translator: TranslateService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'name', name: 'ui.name' },
      { id: 'debitPeriod', name: 'component.crm.tariffs.debit' },
      { id: 'price', name: 'component.crm.tariffs.price' },
      { id: 'paymentPeriod', name: 'component.crm.tariffs.period' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление тарифа
   */
  public addItem() {
    this.dialogService.addDialog(TariffEditComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранного тарифа
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { tariffId: id };
    this.dialogService.addDialog(TariffEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранного тарифа
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = { tariffId: id, copy: true };
    this.dialogService.addDialog(TariffEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление выбранного тарифа
   */
  public deleteSelected(id: string, name: string) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant(
      'component.crm.tariffs.confirm',
      { val: name });

    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.TARIFF), true)))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка данных
   */
  protected loadData = () => {
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.TARIFF), true)
      .subscribe((tariffs: ITariff[]) => {
        this.items = tariffs.map((tariff) => ({ ...tariff, price: tariff.price / 100 }));
      });
  };
}
