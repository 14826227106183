import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { PacketDocs, PacketPeriod } from '../../../../../../shared/crm/docs';

/**
 * Компонент для заполнения параметров запроса на формирование пакетов документов
 */
@Component({
  selector: 'doc-packets-request-params',
  templateUrl: './docs-packets-request-params.component.html'
})
export class DocsPacketsRequestParamsComponent extends DialogComponent<{}, IParams> {

  /** Дата документов */
  public date: Date;
  /** Типы формируемых документов */
  public docs: PacketDocs;
  /** Период, за который выполняется формирование */
  public period: PacketPeriod;
  /** Признак включения баланса в сумму счета */
  public withBalance: boolean;
  /** Признак формирования только новых документов */
  public onlyNew: boolean;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
      super(dialogService);

      this.docs = PacketDocs.BOTH;
      this.period = PacketPeriod.CUR;
      this.withBalance = false;
  }

  /**
   * Применение изменений
   */
  public ok() {
    this.result = {
      date: this.date ? this.date.getTime() : null,
      withBalance: this.withBalance,
      period: this.period,
      docs: this.docs,
      onlyNew: this.onlyNew
    };

    this.close();
  }

  /**
   * Отмена
   */
  public cancel() {
    this.close();
  }
}

/**
 * Параметры, возвращаемые данным диалоговым окном
 */
interface IParams {
  /** Дата документов */
  date: number;
  /** Типы формируемых документов */
  docs: PacketDocs;
  /** Период, за который выполняется формирование */
  period: PacketPeriod;
  /** Признак включения баланса в сумму счета */
  withBalance: boolean;
  /** Признак формирования только новых документов */
  onlyNew: boolean;
}
