import { Component } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal/dist/dialog.service';
import { filter } from 'rxjs/operators';

import { EventType, getAllEventTypes, getEventTypeName } from '../../../shared/events/EventType';
import { IEvent } from '../../../shared/events/IEvent';
import { IEventDescription } from '../../../shared/events/IEventDescription';
import { IMaintenanceEventInfo } from '../../../shared/maintenance/IMaintenanceEventInfo';
import { DurationType, getDurationTypeName } from '../../../shared/time/DurationType';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { EventsService } from '../../services/events.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { StoreService } from '../../services/store.service';
import { UnitsService } from '../../services/units.service';
import { localeSort } from '../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Компонент для внесения событий по объектам мониторинга
 */
@Component({
  selector: 'unit-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent {

  /** Выбранный объект мониторинга */
  public selectedUnit: IListItem<string> = null;

  /** Событие */
  public event: IEvent;

  /** Типы событий */
  public eventTypes: IListItem<EventType>[] = [];

  /** Список объектов мониторинга */
  public units: IListItem<string>[];

  /** Информация по ТО объекта мониторинга */
  public maintenanceInfo: IMaintenanceEventInfo;

  /** Типы длительностей для ТО */
  public durationTypes: IListItem<DurationType>[] = [];

  /** Выбранное описание события */
  public currentEventDescription: IListItem<string> = null;

  /** Список сохраненных описаний для произвольных событий */
  public eventDescriptions: IListItem<string>[] = null;

  /** Список всех доступных объектов мониторинга */
  private allUnits: IListItem<string>[];

  /**
   * Конструктор
   * @param eventsService Сервис работы с событиями
   * @param monitoringService Сервис мониторинга
   * @param loadingService Сервис отображения процесса загрузки
   * @param modalService Сервис модальных окон
   * @param crudService Сервис ДИУП
   * @param dialogService Сервис диалоговых окон
   * @param unitsService Сервис работы с объектами мониторинга
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    private eventsService: EventsService,
    private monitoringService: MonitoringService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private crudService: CRUDService,
    private dialogService: DialogService,
    private unitsService: UnitsService,
    private store: StoreService
  ) {
    this.eventTypes = getAllEventTypes()
      .map((type) => ({ id: type, name: getEventTypeName(type) }));

    this.durationTypes = [DurationType.MINUTE, DurationType.HOUR, DurationType.DAY, DurationType.MONTH]
      .map((type) => ({ id: type, name: getDurationTypeName(type) }));

    this.event = {
      date: new Date(),
      type: EventType.FILLING,
      filling: { value: null }
    } as IEvent;

    this.units = this.store.units
      ?.map(({ id, name }) => ({ id, name }))
      ?.sort(localeSort);

    this.selectedUnit = this.units.find((unit) => unit.id === this.store.addEventUnitId);
    this.updateEventDescriptions();
  }

  /**
   * Получение признака возможности сохранения события
   */
  public get isCanSaveEvent() {
    return this.selectedUnit && this.event && this.event.date &&
      this.event.descr && this.event.descr !== '' &&
      (this.event.type !== EventType.FILLING || (this.event.filling && this.event.filling.value > 0)) &&
      (this.event.type !== EventType.MAINTENANCE || (this.event.maintenance && this.event.maintenance.work &&
        this.event.maintenance.work !== '' && this.event.maintenance.intervals &&
        this.event.maintenance.intervals.length && this.event.maintenance.duration));
  }

  /**
   * Получение признака возможности сохранения описания события
   */
  public get isCanSaveDescription() {
    return this.event?.descr && this.event.type === EventType.ARBITRARY;
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Обработка при изменении типа события
   */
  public onChangeType() {
    switch (this.event.type) {

      case EventType.FILLING:
        this.event.filling = { value: null };
        this.event.maintenance = null;
        break;

      case EventType.MAINTENANCE:
        this.event.filling = null;
        this.event.maintenance = {
          intervals: [],
          duration: { value: 1, type: DurationType.HOUR },
          work: ''
        };

        if (!this.maintenanceInfo) {
          this.loadMaintenanceInfo();
        } else {
          this.event.maintenance.mileage = this.maintenanceInfo.mileage;
          this.event.maintenance.mh = this.maintenanceInfo.mh;
        }

        break;

      default:
        this.event.filling = null;
    }
  }

  /**
   * Обработка изменения выбранного ТС
   */
  public onChangeUnit() {
    if (this.event.maintenance) {
      this.event.maintenance.intervals = [];
      this.event.maintenance.work = '';
      this.event.maintenance.mileage = null;
      this.event.maintenance.mh = null;
    }

    this.maintenanceInfo = null;

    if (this.event.type === EventType.MAINTENANCE) {
      this.loadMaintenanceInfo();
    }
  }

  /**
   * Получение признака выбранности интервала ТО
   * @param intervalName Наименование интервала ТО
   */
  public isToChecked(intervalName: string) {
    return this.event?.maintenance?.intervals?.includes(intervalName);
  }

  /**
   * Обработка переключения выбранности интервала ТО
   * @param intervalName Наименование интервала ТО
   */
  public onToCheckedToggle(intervalName: string) {
    if (this.isToChecked(intervalName)) {
      const index = this.event.maintenance.intervals.indexOf(intervalName);
      this.event.maintenance.intervals.splice(index, 1);
    } else {
      this.event.maintenance.intervals.push(intervalName);
    }

    this.event.maintenance.work = this.event.maintenance.intervals.join('; ');
  }

  /**
   * Обработка при выборе сохраненного описания
   * @param item Выбранное описание события
   */
  public onSelectEventDescription(item: IListItem<string>) {
    this.currentEventDescription = item;
    this.event.descr = item.name;
  }

  /**
   * Сохранение события
   */
  public save() {
    if (!this.isCanSaveEvent) {
      return;
    }

    this.event.unitId = this.selectedUnit.id;
    this.loadingService.wrap(this.eventsService.add(this.event), true)
      .subscribe(() => {
        // Если тип уведомления - "Техобслуживание", то перезапускаем слежение, чтобы обновилась информация по ТО
        if (this.event.type === EventType.MAINTENANCE) {
          this.monitoringService.restartTracking();
        }
        this.store.addEventUnitId = null;
      });
  }

  /**
   * Обновление сохраненных описаний событий
   */
  public updateEventDescriptions() {
    this.loadingService.wrap(this.eventsService.getDescriptions(), true)
      .subscribe((descriptions) => this.eventDescriptions = descriptions);
  }

  /**
   * Сохранение описания события
   */
  public saveDescription() {
    if (!this.isCanSaveDescription) {
      return;
    }

    const eventDescription = {
      name: this.event.descr
    } as IEventDescription;

    this.loadingService.wrap(this.eventsService.addDescription(eventDescription), true)
      .subscribe(() => this.updateEventDescriptions());
  }

  /**
   * Удаление текущего выбранного описания события
   */
  public deleteCurrentEventDescription() {
    if (!this.currentEventDescription) {
      return;
    }

    this.deleteEventDescription(this.currentEventDescription);
    this.currentEventDescription = null;
  }

  /**
   * Удаление всех описаний событий
   */
  public async deleteAllEventDescriptions() {
    const deletedEventDescriptions = [...this.eventDescriptions];

    for (const deletedEventDescription of deletedEventDescriptions) {
      this.deleteEventDescription(deletedEventDescription);
    }

    this.currentEventDescription = null;
  }

  /**
   * Отмена добавления события
   */
  public cancel() {
    this.store.addEventUnitId = null;
  }

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.selectedUnit
      ? this.allUnits.filter((u) => u.id === this.selectedUnit.id)
      : [];

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.event.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.maintenanceInfo = null;

        if (this.event.maintenance) {
          this.event.maintenance.intervals = [];
          this.event.maintenance.work = '';
          this.event.maintenance.mh = null;
          this.event.maintenance.mileage = null;
        }

        this.selectedUnit = result?.shift();
        if (!this.selectedUnit) {
          return;
        }

        const existsUnit = this.units.find((u) => u.id === this.selectedUnit.id);
        if (existsUnit) {
          this.selectedUnit = existsUnit;
        } else {
          this.units.push(this.selectedUnit);
        }

        if (this.event.type === EventType.MAINTENANCE) {
          this.loadMaintenanceInfo();
        }
      });
  }

  /**
   * Удаление описания события
   * @param eventDescription Удаляемое описание события
   */
  private deleteEventDescription(eventDescription: IListItem<string>) {
    this.loadingService.wrap(this.eventsService.deleteDescription(eventDescription.id), true)
      .subscribe(() => {
        const index = this.eventDescriptions.findIndex((e) => e.id === eventDescription.id);
        if (index !== -1) {
          this.eventDescriptions.splice(index, 1);
        }
      });
  }

  /**
   * Загрузка информации по ТО объекта мониторинга
   */
  private loadMaintenanceInfo() {
    if (!this.selectedUnit) {
      return;
    }

    this.loadingService.wrap(this.unitsService.getMaintenanceInfo(this.selectedUnit.id), true)
      .subscribe((result) => {
        this.maintenanceInfo = result;
        this.event.maintenance.mileage = result.mileage;
        this.event.maintenance.mh = result.mh;
      });
  }
}
