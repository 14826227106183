import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { of, throwError } from 'rxjs';
import { catchError, filter, flatMap } from 'rxjs/operators';

import { IAccount } from '../../../shared/accounts/IAccount';
import { RefType } from '../../../shared/crm/IRefItem';
import { IRight } from '../../../shared/rights/IRight';
import { RightEntityType } from '../../../shared/rights/RightEntityType';
import { AccountRightType, RightType, UnitRightType, UserRightType } from '../../../shared/rights/RightType';
import { getTimezoneName, Timezone } from '../../../shared/time/timezones';
import { getDefaultUserSettings, getLocaleName, IActUser, IUser, Locale } from '../../../shared/users';
import { DetailComponent } from '../../classes/DetailComponent';
import { DetailTab } from '../../classes/DetailTab';
import { IClientHistory } from '../../classes/IClientHistory';
import { IClientSubjectRight } from '../../classes/IClientSubjectRight';
import { IListItem } from '../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ReportTemplatesService } from '../../services/report-templates.service';
import { RightsService } from '../../services/rights.service';
import { StoreService } from '../../services/store.service';
import { UsersService } from '../../services/users.service';
import { getAllEnumValues } from '../../utils/enums';
import { ClientsEditComponent } from '../crm/clients/edit/clients.edit.component';
import { RefsItemsModalComponent } from '../crm/refs/items-modal/refs.items-modal.component';
import { SelectionType, SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Интерфейс компонента для отображения подробной информации по учетной записи
 */
interface IAccountComponent {
  /** Идентификатор учетной записи */
  accountId?: string;
}

/**
 * Компонент для отображения подробной информации по учетной записи
 */
@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends DetailComponent<IAccountComponent> implements IAccountComponent {

  /** Идентификатор учетной записи */
  public accountId?: string;

  /** Данные учетной записи */
  public account: IAccount;

  /** Список доступных для выбора создателей */
  public creators: IActUser[] = [];

  /** Права пользователей на учетную запись */
  public userRights: IClientSubjectRight[] = [];

  /** Признак созданий учетной записи от имени существующего пользователя */
  public createByExistUser = false;

  /** Новый пользователь, который будет создателем учетной записи */
  public user: IUser;

  /** Пароль подтверждения */
  public confirmPassword: string;

  /** Список доступных тарифов */
  public tariffs: IListItem<string>[] = [];

  /** Список клиентов */
  public clients: IListItem<string>[];

  /** Признак учетной записи текущего пользователя */
  public isCurrentUserAccount: boolean;

  /** Список доступных языков */
  public locales: IListItem<Locale>[];

  /** Список доступных часовых поясов */
  public timezones: IListItem<Timezone>[];

  /** Список записей истории */
  public history: IClientHistory[];

  /** Выбранный год */
  public year: number;

  /** Выбранный месяц */
  public month: number;

  /** Список доступных для выбора месяцев */
  public months: IListItem<number>[] = [];

  /** Тип аккаунта */
  public accountTypes: IListItem<number>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис даилоговых окон
   * @param crudService Сервис для работы с ДИУП сущностей
   * @param rightsService Сервис для работы с правами
   * @param usersService Сервис для работы с пользователями
   * @param loadingService Сервис отображения процесса загрузки
   * @param translator Сервис для перевода
   * @param reportTemplatesService
   * @param store Севрис для хранения данных
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private rightsService: RightsService,
    private usersService: UsersService,
    private loadingService: LoadingService,
    private translator: TranslateService,
    private reportTemplatesService: ReportTemplatesService,
    private store: StoreService
  ) {
    super(dialogService);

    this.isAddRegime = true;
    this.account = { unitsCount: 0 } as IAccount;
    this.user = { extras: {}, settings: getDefaultUserSettings() } as IUser;

    this.title = 'component.account.account-add-title';

    this.tabs[Tabs.Main] = new DetailTab('component.account.main');
    this.tabs[Tabs.Access] = new DetailTab('component.account.access', true);
    this.tabs[Tabs.More] = new DetailTab('component.account.more', true);
    this.tabs[Tabs.Pay] = new DetailTab('component.account.pay', true);
    this.tabs[Tabs.Operations] = new DetailTab('component.account.operation', true);
    this.tabs[Tabs.Arbitrary] = new DetailTab('component.account.arbitrary', true);
    this.tabs[Tabs.History] = new DetailTab('component.account.history', true);
    this.tabs[Tabs.Map] = new DetailTab('component.account.map', false);

    this.selectedTab = this.tabs[Tabs.Main];

    this.locales = getAllEnumValues<Locale>(Locale)
      .map((locale) => ({ id: locale, name: getLocaleName(locale) }));

    this.timezones = getAllEnumValues<Timezone>(Timezone)
      .map((timezone) => ({ id: timezone, name: getTimezoneName(timezone) }));

    this.months = [
      { id: 0, name: 'classes.time.month-1' },
      { id: 1, name: 'classes.time.month-2' },
      { id: 2, name: 'classes.time.month-3' },
      { id: 3, name: 'classes.time.month-4' },
      { id: 4, name: 'classes.time.month-5' },
      { id: 5, name: 'classes.time.month-6' },
      { id: 6, name: 'classes.time.month-7' },
      { id: 7, name: 'classes.time.month-8' },
      { id: 8, name: 'classes.time.month-9' },
      { id: 9, name: 'classes.time.month-10' },
      { id: 10, name: 'classes.time.month-11' },
      { id: 11, name: 'classes.time.month-12' }
    ];

    // заполняем accountTypes
    this.accountTypes = [
      {id: 0, name: 'component.account.account-type.none'},
      {id: 1, name: 'component.account.account-type.scavenger'},
      {id: 2, name: 'component.account.account-type.phizic'},
      {id: 3, name: 'component.account.account-type.global-position'},
      {id: 4, name: 'component.account.account-type.vrs'}
    ]
  }

  /**
   * Получение признака наличия прав на переименование
   */
  public get isCanRename() {
    return this.isCan(RightType.RENAME);
  }

  /**
   * Получение признака наличия прав на изменение учетной записи
   */
  public get isCanChangeAccount() {
    return this.isCan(AccountRightType.CHANGE_ACCOUNT);
  }

  /**
   * Получение наименования учетной записи создателя учетной записи
   */
  public get creatorAccount() {
    if (this.account.creatorId) {
      const creator = this.creators.find((c) => c.id === this.account.creatorId);
      if (creator) {
        return creator.account;
      }
    }

    return '';
  }

  /**
   * Получение признака возможности сохранения
   */
  public get isCanSave(): boolean {
    if (this.account.periodLimit <= 0) {
      this.account.periodLimit = 90
      return false;
    }

    return !!(this.account.name && this.account.name !== ''
      && (!this.isAddRegime
        || (this.createByExistUser && this.account.creatorId)
        || (!this.createByExistUser
          && this.user.name && this.user.name !== ''
          && this.user.password && this.user.password !== ''
          && this.confirmPassword === this.user.password)));
  }

  /**
   * Получение признака наличия прав на управление правами доступа
   */
  private get isCanChangeAccess() {
    return this.isCan(RightType.CHANGE_ACCESS);
  }

  /**
   * Получение признака наличия прав на просмотр произвольных свойств
   */
  private get isCanViewArbitraryFields() {
    return this.isCan(RightType.VIEW_ARBITRARY_FIELDS);
  }

  /**
   * Обработка изменения выбранного создателя для нового пользователя
   */
  public onUserCreatorChange() {
    const creator = this.creators.find((user) => user.id === this.user.creatorId);
    this.user.creator = creator.name;
    this.user.accountId = creator.accountId;
    this.user.account = creator.account;
  }

  /**
   * Обработка изменений выбранного создателя для учетной записи
   */
  public onAccountCreatorChange() {
    const creator = this.creators.find((user) => user.id === this.account.creatorId);
    this.account.creator = creator.name;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IAccountComponent) {
    if (data.accountId) {
      this.title = 'component.account.account-edit-title';
      this.isAddRegime = false;

      this.loadingService
        .wrap(this.crudService.get(data.accountId, CRUDEntityType.ACCOUNT), true)
        .subscribe(this.onAccountDataLoaded);
    } else {
      this.tabs[Tabs.Arbitrary].hidden = false;

      this.loadTariffs();

      this.loadingService
        .wrap(this.usersService.getActUsers(), true)
        .subscribe((users) => {
          this.creators = users;

          const currentUser = this.creators.find((user) => user.current);

          this.account.creatorId = currentUser.id;
          this.account.creator = currentUser.name;

          this.user.creatorId = currentUser.id;
          this.user.creator = currentUser.name;
          this.user.accountId = currentUser.accountId;
          this.user.account = currentUser.account;
        });
    }

    return super.fillData(data);
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType | UserRightType | AccountRightType | UnitRightType): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(this.isAddRegime || (this.account.rights & rightType));
  }

  /**
   * Клик по кнопке выбора клиента
   */
  public selectClient() {
    if (!this.clients) {
      // Загружаем список клиентов
      this.loadingService
        .wrap(this.crudService.getListLight(CRUDEntityType.CLIENT), true)
        .subscribe((clients) => {
          this.clients = clients;
          // Выбираем из загруженного списка
          this.selectFromClients();
        });
    } else {
      // Выбираем из загруженного списка
      this.selectFromClients();
    }
  }

  /**
   * Выбор источника учетной записи
   */
  public selectOrigin() {
    const data = { type: RefType.ACCOUNT_ORIGIN, selected: this.account.origin };
    this.dialogService.addDialog(RefsItemsModalComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.account.origin = result);
  }

  /**
   * Клик по кнопке отображения детальной информации по клиенту
   * @param client Клиент
   */
  public showClientDetail(client: IListItem<any>) {
    if (!client) {
      return;
    }

    const data = { clientId: client.id };
    this.dialogService.addDialog(ClientsEditComponent, data).subscribe();
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.account.tariffDate = new Date(this.year, this.month, 15, 0, 0, 0, 0).getTime();

    const rights: IRight[] = this.userRights
      .filter((right) => right.value !== right.newValue)
      .map((right) => ({
        id: right.id,
        subjectId: right.subjectId,
        objectId: right.objectId,
        objectType: RightEntityType.ACCOUNT,
        value: right.newValue,
        // tslint:disable-next-line:no-bitwise
        combined: (right.combined | right.newValue)
      }));

    const data: any = {
      account: {
        ...this.account,
        blockBalance: this.account.blockBalance
          ? Math.round(this.account.blockBalance * 100)
          : null
      }
    };

    if (this.isAddRegime && !this.createByExistUser) {
      data.user = this.user;
    }

    const observable = this.crudService.addUpdate(data, CRUDEntityType.ACCOUNT)
      .pipe(flatMap((id) => {
        return !rights.length
          ? of(id)
          : this.rightsService.updateRights(rights)
            .pipe(catchError((error) => {
              const message = this.translator.instant('component.account.error', { val: error });
              return throwError(message);
            }));
      }));

    this.loadingService
      .wrap(observable, true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Загрузка списка тарифов
   */
  private loadTariffs() {
    this.loadingService
      .wrap(this.crudService.getListLight(CRUDEntityType.TARIFF), true)
      .subscribe((tariffs) => {
        this.tariffs = tariffs;

        // Если в списке тарифов нет того,
        // что указан у учетной записи, то добавляем
        // его в список (первым)
        if (this.account && this.account.tariffId) {
          const tariff = this.tariffs.find((t) => t.id === this.account.tariffId);

          if (!tariff) {
            this.tariffs.unshift({
              id: this.account.tariffId,
              name: this.account.tariff
            });
          }
        }
      });
  }

  /**
   * Обработка загрузки данных учетной записи с сервера
   * @param account Учетная запись
   */
  private onAccountDataLoaded = (account: IAccount) => {
    if (account.blockBalance) {
      account.blockBalance /= 100;
    }

    this.account = account;

    const date = new Date(this.account.tariffDate);
    this.year = date.getFullYear();
    this.month = date.getMonth();

    this.loadTariffs();

    this.creators.push({
      id: this.account.creatorId,
      name: this.account.creator,
      account: this.account.name,
      accountId: this.account.id
    });

    const user = this.store.user;

    this.tabs[Tabs.History].hidden = !user || !user.isDealer;

    this.tabs[Tabs.More].hidden = false;
    this.tabs[Tabs.Access].hidden = !this.isCanChangeAccess;
    this.tabs[Tabs.Arbitrary].hidden = !this.isCanViewArbitraryFields;

    this.isCurrentUserAccount = user && user.account.id === account.id;
    this.tabs[Tabs.Pay].hidden = this.isCurrentUserAccount;
    this.tabs[Tabs.Operations].hidden = this.isCurrentUserAccount;
  }

  /**
   * Выбор из списка клиентов
   */
  private selectFromClients() {
    const data = {
      items: this.clients,
      selected: this.account.client ? [this.account.client] : null,
      title: 'component.account.client-selection',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.account.client = result?.shift());
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Access,
  More,
  Arbitrary,
  Pay,
  Operations,
  History,
  Map
}
