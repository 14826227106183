import { Pipe, PipeTransform } from '@angular/core';

import { IAccountListItem } from '../../shared/accounts/IAccountListItem';

/**
 * Фильтр для списка учетных записей в администрировании
 */
@Pipe({
  name: 'accountsfilter',
  pure: false
})
export class AccountsFilterPipe implements PipeTransform {
  public transform(items: IAccountListItem[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => {
        return item.name.toLowerCase().includes(lowerFilter) ||
          item.creator.toLowerCase().includes(lowerFilter) ||
          (item.parent && item.parent.toLowerCase().includes(lowerFilter)) ||
          (item.tariff && item.tariff.toLowerCase().includes(lowerFilter));
      }
    );
  }
}
