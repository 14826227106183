import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

import { TimeSpan } from '../../../../shared/classes/TimeSpan';
import { getMeasure } from '../../../../shared/sensors/sensor-measure';
import { BgnumberPipe } from '../../../pipes/bgnumber.pipe';
import { GeocoderService } from '../../../services/geocoder.service';
import { IClientRace, IRaceEventInfo, IRacePoint, IRaceSensor } from '../../../services/race.service';
import { StoreService } from '../../../services/store.service';
import { getString } from '../../../utils/intervals';

/**
 * Компонент для детальной информации рейса на карте
 */
@Component({
  selector: 'map-race-detail',
  templateUrl: './map.race-detail.component.html',
  styleUrls: ['./map.race-detail.component.scss']
})
export class MapRaceDetailComponent implements OnInit, OnDestroy {

  /** Рейс */
  @Input() public race: IClientRace;

  /** Точка рейса */
  @Input() public point: IRacePoint;

  /** События рейса в точке */
  @Input() public eventInfos: IRaceEventInfo[];

  /** Подписка на таймер перед получением адреса */
  private subscribe: Subscription;

  /** Признак отмены получения адреса */
  private cancelGetAddress = false;

  /**
   * Конструктор
   * @param geocoderService Сервис геокодирования
   * @param store Сервис для хранения данных мониторинга
   * @param translator Сервис для перевода
   */
  constructor(
    private geocoderService: GeocoderService,
    private store: StoreService,
    private translator: TranslateService
  ) {
    this.eventInfos = [];
    this.getAddress = this.getAddress.bind(this);
  }

  /**
   * Получение списка наименований всех параметров точки рейса
   */
  get pointParamKeys() {
    return Object.keys(this.point.p);
  }

  /**
   * Получение признака необходимости отображения параметров точки рейса
   */
  public get isShowParams() {
    return this.store.user &&
      this.store.user.settings.unitDetail &&
      this.store.user.settings.unitDetail.params &&
      this.store.user.settings.unitDetail.params.map &&
      this.point && this.point.p;
  }

  /**
   * Получение строку длительности
   * @param seconds Количество секунд
   */
  public getLengthString(seconds: number) {
    return getString(new TimeSpan(seconds * 1000));
  }

  /**
   * Получение строки датчика
   * @param value Значение датчика
   * @param sensor Датчик
   */
  public getSensorValueString(value: number, sensor: IRaceSensor) {
    if (value == null) {
      return this.translator.instant('enums.sensors.measure.no-data');
    }

    if (Math.round(value) !== value) {
      value = Math.round(value * 1000) / 1000;
    }

    const bgnumberPipe = new BgnumberPipe(this.translator);
    const valueString = bgnumberPipe.transform(value);
    if (sensor.m && sensor.m !== '') {
      return `${valueString} ${sensor.m}`;
    }

    return this.translator.instant(getMeasure(value, sensor.t), {val: valueString});
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.point && !this.point.a) {
      this.subscribe = timer(500).subscribe(this.getAddress);
    }
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    this.cancelGetAddress = true;
    if (this.subscribe) { this.subscribe.unsubscribe(); }
  }

  /**
   * Получение адреса
   */
  private getAddress() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
      this.subscribe = null;
    }

    if (this.cancelGetAddress || this.point.a) {
      return;
    }

    this.geocoderService.getAddress(
      null, this.point.lt, this.point.ln
    ).subscribe((address) => this.point.a = address);
  }
}
