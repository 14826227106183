import { Component, Input, OnInit } from '@angular/core';

import { RightType } from '../../../../shared/rights/RightType';
import { ICharacteristics } from '../../../../shared/units/ICharacteristics';

/**
 * Компонент с настройками характеристик объекта мониторинга
 */
@Component({
  selector: 'unit-characteristics',
  templateUrl: './unit.characteristics.component.html',
  styleUrls: ['./unit.characteristics.component.scss']
})
export class UnitCharacteristicsComponent implements OnInit {

  /** Характеристики объекта мониторинга */
  @Input() public characteristics: ICharacteristics;

  /** Права доступа на объект мониторинга */
  @Input() public rights: number;

  /** Признак режима добавления объекта мониторинга */
  @Input() public isAddRegime: boolean;

  /** Признак только для чтения */
  public isReadOnly: boolean;

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.isAddRegime) {
      this.isReadOnly = false;
    // tslint:disable-next-line:no-bitwise
    } else if (!(this.rights & RightType.CHANGE_OTHER)) {
      this.isReadOnly = true;
    }
    this.characteristics.dimensions = this.characteristics.dimensions ||
      { width: undefined, height: undefined, depth: undefined };
  }
}
