import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { PipesModule } from '../../pipes.module';

import { BgRepeatersService } from './bg-repeaters.service';
import { RepeaterComponent } from './components/repeater/repeater.component';
import { RepeatersComponent } from './components/repeaters/repeaters.component';

const routes: Routes = [
  { path: '', component: RepeatersComponent }
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    CardModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    TooltipModule,
    FormsModule,
    RouterModule.forChild(routes),
    DialogModule,
    TabViewModule,
    ReactiveFormsModule,
    CalendarModule,
    DividerModule
  ],
  declarations: [
    RepeatersComponent,
    RepeaterComponent
  ],
  entryComponents: [],
  exports: [
    RouterModule
  ],
  providers: [
    BgRepeatersService
  ]
})
export class BgRepeatersModule {
}
