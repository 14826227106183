import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IReportChart } from '../../../../shared/reports/charts/IReportChart';
import { IReportChartBg } from '../../../../shared/reports/charts/IReportChartBg';
import { IReportChartLine } from '../../../../shared/reports/charts/IReportChartLine';
import {
  getAllReportChartBgTypes,
  getReportChartBgTypeName
} from '../../../../shared/reports/charts/ReportChartBgType';
import {
  getAllReportChartByMileageTypes,
  getReportChartByMileageTypeName
} from '../../../../shared/reports/charts/ReportChartByMileageType';
import {
  getAllReportChartByTimeTypes,
  getReportChartByTimeTypeName
} from '../../../../shared/reports/charts/ReportChartByTimeType';
import {
  getAllReportChartTypes,
  getReportChartTypeName,
  ReportChartType
} from '../../../../shared/reports/charts/ReportChartType';
import { ReportItemType } from '../../../../shared/reports/ReportItemType';
import { DetailTab } from '../../../classes/DetailTab';
import { IListItem } from '../../../classes/IListItem';

/**
 * Интерфейс компонента для отображения подробной информации по графику отчета
 */
export interface IReportTemplateChartComponent {
  /** Данные графика отчета */
  chart?: IReportChart;
}

/**
 * Компонент для отображения подробной информации по графику отчета
 */
@Component({
  selector: 'report-template-chart',
  templateUrl: './report-template.chart.component.html',
  styleUrls: ['./report-template.chart.component.scss']
})
export class ReportTemplateChartComponent
extends DialogComponent<IReportTemplateChartComponent, IReportChart>
implements IReportTemplateChartComponent {

  /** Данные графика отчета */
  public chart?: IReportChart;

  /** Текст сообщения об ошибке */
  public error: string;

  /** Заголовок окна */
  public title: string;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Редактируемая линия графика */
  public editLine: IClientReportChartLine = null;

  /** Выбранные линии графика */
  public checkedLines: IClientReportChartLine[] = [];

  /** Все линии графика */
  public allLines: IClientReportChartLine[] = [];

  /** Выбранные фоны графика */
  public checkedBgs: IClientReportChartBg[] = [];

  /** Невыбранные фоны графика */
  public uncheckedBgs: IClientReportChartBg[] = [];

  /** Доступные типы графиков */
  public chartTypes: IListItem<ReportChartType>[] = [];

  /** Список заготовленых цветов для линий графика */
  private colors20: string[] = [
    '#e6194b', '#3cb44b', '#ffe119', '#0082c8', '#f58231',
    '#911eb4', '#46f0f0', '#f032e6', '#d2f53c', '#fabebe',
    '#008080', '#e6beff', '#aa6e28', '#fffac8', '#800000',
    '#aaffc3', '#808000', '#ffd8b1', '#000080', '#808080'
  ];

  /** Список заготовленных цветов для фонов графика */
  private colors10: string[] = [
    '#ddf5fb', '#bbd8fa', '#e1c3ff', '#fad6fb', '#ffead7',
    '#d1e8ee', '#d3f9fe', '#f7cafe', '#ffc1e7', '#fcf8d3'
  ];

  /**
   * Конструктор
   * @param dialogService Сервис для работы с диалоговыми окнами
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private translator: TranslateService
  ) {
    super(dialogService);

    this.chart = {
      chartType: ReportChartType.BY_TIME,
      backgrounds: [],
      itemType: ReportItemType.CHART,
      lines: [],
      name: ''
    };

    this.title = 'component.report-template.chart.add-graph-title';

    this.tabs[Tabs.Data] = new DetailTab('component.report-template.chart.data');
    this.tabs[Tabs.Background] = new DetailTab('component.report-template.chart.background');

    this.selectedTab = this.tabs[Tabs.Data];

    this.chartTypes = getAllReportChartTypes()
      .map((type) => ({ id: type, name: getReportChartTypeName(type) }));
  }

  /**
   * Обработка изменения типа графика
   */
  public onTypeChanged() {
    this.editLine = null;

    this.checkedLines = [];
    this.getAllReportChartLines(this.chart.chartType)
      .subscribe((lines) => this.allLines = lines);

    this.checkedBgs = [];
    this.uncheckedBgs = this.getAllReportChartBgTypes();
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IReportTemplateChartComponent) {
    if (!data.chart) {
      data.chart = this.chart;
    } else {
      this.title = 'component.report-template.chart.edit-graph-title';
    }

    this.getAllReportChartLines(data.chart.chartType).subscribe(
      (lines) => {
        this.allLines = lines;

        for (const line of this.allLines) {
          const chartLine = data.chart.lines.find((x) => line.type === x.type);

          if (chartLine) {
            this.checkedLines.push({
              ...chartLine,
              checked: true,
              initialName: line.name,
              useColorSensorMask: chartLine.colorSensorMask && chartLine.colorSensorMask !== ''
            });
          }
        }
      }
    );

    if (!data.chart.backgrounds) {
      data.chart.backgrounds = [];
    }

    const allBgs = this.getAllReportChartBgTypes();

    for (const bg of allBgs) {
      const chartBg = data.chart.backgrounds.find((x) => bg.type === x.type);

      if (chartBg) {
        this.checkedBgs.push({ ...chartBg, name: bg.name, checked: true });
      } else {
        this.uncheckedBgs.push(bg);
      }
    }

    return super.fillData(data);
  }

  /**
   * Перемещение линии графика в иерархии
   * @param line Перемещаемая линия
   * @param up Признак перемещения вверх
   */
  public moveLine(line: IClientReportChartLine, up: boolean) {
    const index = this.checkedLines.indexOf(line);
    if (index === -1) { return; }
    if ((up && index === 0) || (!up && index === this.checkedLines.length - 1)) {
      return;
    }

    const swapIndex = up ? index - 1 : index + 1;
    const swapLine = this.checkedLines[swapIndex];
    this.checkedLines[swapIndex] = line;
    this.checkedLines[index] = swapLine;
  }

  /**
   * Перемещение фона графика в иерархии
   * @param bg Перемещаемый фон
   * @param up Признак перемещения вверх
   */
  public moveBg(bg: IClientReportChartBg, up: boolean) {
    const index = this.checkedBgs.indexOf(bg);
    if (index === -1) { return; }
    if ((up && index === 0) || (!up && index === this.checkedBgs.length - 1)) {
      return;
    }

    const swapIndex = up ? index - 1 : index + 1;
    const swapBg = this.checkedBgs[swapIndex];
    this.checkedBgs[swapIndex] = bg;
    this.checkedBgs[index] = swapBg;
  }

  /**
   * Переключение выбора линии графика
   * @param line Линия графика
   */
  public toggleCheckedLine(line: IClientReportChartLine) {
    if (line.checked) {
      const index = this.checkedLines.indexOf(line);
      if (index !== -1) {
        this.checkedLines.splice(index, 1);
      }
    } else {
      const newLine = { ...line, checked: true };
      this.checkedLines.push(newLine);
    }
  }

  /**
   * Переключение выбора фона графика
   * @param bg Фон графика
   */
  public toggleCheckedBg(bg: IClientReportChartBg) {
    const bgsFrom = bg.checked ? this.checkedBgs : this.uncheckedBgs;
    const bgsTo = bg.checked ? this.uncheckedBgs : this.checkedBgs;
    bg.checked = !bg.checked;
    const indexFrom = bgsFrom.indexOf(bg);
    if (indexFrom !== -1) {
      bgsFrom.splice(indexFrom, 1);
    }
    const indexTo = bgsTo.indexOf(bg);
    if (indexTo === -1) {
      bgsTo.push(bg);
    }
  }

  /**
   * Признак возможности сохранения настроек графика
   */
  get isCanSave() {
    return this.chart.name && this.chart.name !== '' && this.checkedLines.length;
  }

  /**
   * Получение признака необходимости отображения кнопки сброса наименования линии графика
   * @param line Линия графика
   */
  public isShowResetLineNameButton(line: IClientReportChartLine) {
    return line.name !== line.initialName;
  }

  /**
   * Сброс наименования линии графика к стандартному
   * @param line Линия графика
   */
  public resetLineName(line: IClientReportChartLine) {
    line.name = line.initialName;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) { return; }
    const lineWithoutName = this.checkedLines.find(({ name }) => !name || name === '');
    if (lineWithoutName) {
      this.translator.get('component.report-template.chart.error')
        .subscribe((x) => this.error = x);
      return;
    }
    this.chart.lines = this.checkedLines.map(
      ({ type, name, mask, color, colorSensorMask }) => ({ type, name, mask, color, colorSensorMask })
    );
    if (this.checkedBgs) {
      this.chart.backgrounds = this.checkedBgs.map(
        ({ type, color }) => ({ type, color })
      );
    } else {
      this.chart.backgrounds = undefined;
    }
    this.result = this.chart;
    this.close();
  }

  /**
   * Получение цвета для линии графика
   * @param index Индекс линии
   */
  private getColor(index: number) {
    return this.colors20[index];
  }

  /**
   * Получение цвета для фона графика
   * @param index Индекс фона
   */
  private getBgColor(index: number) {
    return this.colors10[index];
  }

  /**
   * Получение списка всех доступных для типа графика линий
   * @param chartType Тип графика
   */
  private getAllReportChartLines(chartType: ReportChartType): Observable<IClientReportChartLine[]> {
    let lines: IClientReportChartLine[];

    switch (chartType) {

      case ReportChartType.BY_TIME:
        lines = getAllReportChartByTimeTypes().map(
          (type, index) => ({
            type,
            name: getReportChartByTimeTypeName(type),
            color: this.getColor(index),
            checked: false,
            initialName: getReportChartByTimeTypeName(type)
          })
        );
        break;

      case ReportChartType.BY_MILEAGE:
        lines = getAllReportChartByMileageTypes().map(
          (type, index) => ({
            type,
            name: getReportChartByMileageTypeName(type),
            color: this.getColor(index),
            checked: false,
            initialName: getReportChartByMileageTypeName(type)
          })
        );
        break;

      default:
        lines = [];
        break;
    }

    return this.translator.get(lines.map((line) => line.name)).pipe(map(
      (names) => {
        for (const line of lines) {
          line.name = names[line.name];
          line.initialName = names[line.initialName];
        }

        return lines;
      }
    ));
  }

  /**
   * Получение списка всех типов фона графика
   */
  private getAllReportChartBgTypes(): IClientReportChartBg[] {
    return getAllReportChartBgTypes().map((type, index) => ({
      type,
      color: this.getBgColor(index),
      name: getReportChartBgTypeName(type),
      checked: false
    }));
  }
}

/**
 * Линия графика для использования на стороне клиента
 */
interface IClientReportChartLine extends IReportChartLine {
  /** Признак выбранной линии */
  checked: boolean;
  /** Изначальное наименование линии */
  initialName: string;
  /** Признак использования маски датчика для определения цвета */
  useColorSensorMask?: boolean;
}

/**
 * Фон графика для использования на стороне клиента
 */
interface IClientReportChartBg extends IReportChartBg {
  /** Признак выбранного фона */
  checked: boolean;
  /** Наименование фона */
  name: string;
}

/**
 * Список вкладок
 */
enum Tabs {
  Data,
  Background
}
