import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';

import { AccountType } from '../../../../shared/accounts/IAccount';
import { deepClone } from '../../../../shared/CloneHelper';
import { ReportItemType } from '../../../../shared/reports/ReportItemType';
import { IReportTable } from '../../../../shared/reports/tables/IReportTable';
import { IReportTableColumn } from '../../../../shared/reports/tables/IReportTableColumn';
import { IReportTableFilter } from '../../../../shared/reports/tables/IReportTableFilter';
import { IReportTableGroup } from '../../../../shared/reports/tables/IReportTableGroup';
import { IReportTableMasks } from '../../../../shared/reports/tables/IReportTableMasks';
import { IReportTableSortInfo } from '../../../../shared/reports/tables/IReportTableSortInfo';
import { ReportTableColumnType } from '../../../../shared/reports/tables/ReportTableColumnType';
import {
  getReportTableFilterTypeName,
  ReportTableFilterType
} from '../../../../shared/reports/tables/ReportTableFilterType';
import {
  getAllReportTableGroupTypes,
  getReportTableGroupTypeName
} from '../../../../shared/reports/tables/ReportTableGroupType';
import { ReportTableMaskType } from '../../../../shared/reports/tables/ReportTableMaskType';
import {
  getReportTableTypeName,
  getReportTableTypes,
  ReportTableType
} from '../../../../shared/reports/tables/ReportTableType';
import { getReportTableColumns } from '../../../../shared/reports/tables/TableColumns';
import { getReportTableFilters } from '../../../../shared/reports/tables/TableFilters';
import { getReportTableMasks } from '../../../../shared/reports/tables/TableMasks';
import { ITimeLimits, processingTimeLimits } from '../../../../shared/time/ITimeLimits';
import { DetailTab } from '../../../classes/DetailTab';
import { IClientReportTableFilter } from '../../../classes/IClientReportTableFilter';
import { IListItem } from '../../../classes/IListItem';
import { ReportType } from '../../../services/report-templates.service';
import { StoreService } from '../../../services/store.service';

/**
 * Интерфейс для представления данных компонента для редактирования таблицы отчета
 */
export interface IReportTemplateTableComponent {
  /** Данные таблицы отчета */
  table?: IReportTable<string>;

  /** Тип отчета */
  reportType: ReportType;

  /** Идентификатор учетной записи */
  accountId: string;
}

/**
 * Компонент для редактирования таблицы отчета
 */
@Component({
  selector: 'report-template-table',
  templateUrl: './report-template.table.component.html',
  styleUrls: ['./report-template.table.component.scss']
})
export class ReportTemplateTableComponent
  extends DialogComponent<IReportTemplateTableComponent, IReportTable<string>>
  implements IReportTemplateTableComponent {

  /** Данные таблицы отчета */
  public table: IReportTable<string>;

  /** Тип отчета */
  public reportType: ReportType;

  /** Идентификатор учетной записи */
  public accountId: string;

  /** Текст сообщения об ошибке */
  public error: string;

  /** Заголовок окна */
  public title: string;

  /** Список доступных вкладок */
  public tabs: DetailTab[] = [];

  /** Выбранная вкладка */
  public selectedTab?: DetailTab;

  /** Список выбранных колонок */
  public checkedColumns: IClientReportTableColumn[] = [];

  /** Список невыбранных колонок */
  public uncheckedColumns: IClientReportTableColumn[] = [];

  /** Типы таблиц */
  public tableTypes: IListItem<ReportTableType>[] = [];

  /** Редактируемая колонка */
  public editColumn: IClientReportTableColumn;

  /** Сортировка */
  public sort: IReportTableSortInfo;

  /** Список выбранных группировок */
  public checkedGroups: IClientReportTableGroup[] = [];

  /** Список невыбранных группировок */
  public uncheckedGroups: IClientReportTableGroup[] = [];

  /** Список фильтров */
  public filters: IClientReportTableFilter[] = [];

  /** Ограничения времени */
  public timeLimits: ITimeLimits;

  /** Маски таблицы */
  public masks: IReportTableMasks;

  /** Типы масок, доступных для таблицы */
  public maskTypes: ReportTableMaskType[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис для работы с диалоговыми окнами
   * @param translator Сервис для перевода
   * @param store
   */
  constructor(
    dialogService: DialogService,
    private translator: TranslateService,
    private store: StoreService
  ) {
    super(dialogService);

    this.reportType = ReportType.UNIT;

    this.title = 'component.report-template.table.add-table-title';

    this.tabs[Tabs.Columns] = new DetailTab('component.report-template.table.columns');
    this.tabs[Tabs.Masks] = new DetailTab('component.report-template.table.masks');
    this.tabs[Tabs.Grouping] = new DetailTab('component.report-template.table.grouping-sorting');
    this.tabs[Tabs.Time] = new DetailTab('component.report-template.table.time');
    this.tabs[Tabs.Filters] = new DetailTab('component.report-template.table.filters');
    this.tabs[Tabs.Additional] = new DetailTab('component.report-template.table.additional');

    this.selectedTab = this.tabs[Tabs.Columns];

    this.sort = {
      column: null,
      desc: false
    };
    this.masks = {};
    this.table = {
      columns: [],
      itemType: ReportItemType.TABLE,
      tableType: ReportTableType.GEOZONES,
      settings: {
        rowNumbers: false,
        detalling: false,
        total: false,
        groupByTotal: false
      }
    } as IReportTable<string>;
  }

  /**
   * Обработка при изменении типа таблицы
   */
  public onTypeChanged() {
    this.editColumn = null;
    this.checkedColumns = [];
    this.sort.column = null;
    this.checkedGroups = [];

    this.uncheckedGroups = this.getAllReportTableGroupTypes();

    this.getTranslatedColumns(this.table.tableType)
      .subscribe((columns) => this.uncheckedColumns = columns);

    this.getTranslatedFilters(this.table.tableType)
      .subscribe((filters) => this.filters = filters);

    this.maskTypes = getReportTableMasks(this.table.tableType);

    this.tabs[Tabs.Filters].hidden = !this.filters.length;
    this.tabs[Tabs.Masks].hidden = !this.maskTypes.length;
  }

  /**
   * Выбор вкладки
   * @param tab Выбираемая вкладка
   */
  public selectTab(tab: DetailTab) {
    this.selectedTab = tab;
  }

  /**
   * Перемещение колонки таблицы
   * @param column Колонка
   * @param up Перемещение вверх
   */
  public moveColumn(column: IClientReportTableColumn, up: boolean) {
    const index = this.checkedColumns.indexOf(column);
    if (index === -1) { return; }
    if ((up && index === 0) || (!up && index === this.checkedColumns.length - 1)) {
      return;
    }

    const swapIndex = up ? index - 1 : index + 1;
    const swapColumn = this.checkedColumns[swapIndex];
    this.checkedColumns[swapIndex] = column;
    this.checkedColumns[index] = swapColumn;
  }

  /**
   * Перемещение группировки таблицы
   * @param group Группировка
   * @param up Перемещение вверх
   */
  public moveGroup(group: IClientReportTableGroup, up: boolean) {
    const index = this.checkedGroups.indexOf(group);
    if (index === -1) { return; }
    if ((up && index === 0) || (!up && index === this.checkedGroups.length - 1)) {
      return;
    }

    const swapIndex = up ? index - 1 : index + 1;
    const swapGroup = this.checkedGroups[swapIndex];
    this.checkedGroups[swapIndex] = group;
    this.checkedGroups[index] = swapGroup;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IReportTemplateTableComponent) {
    if (!data.table) {
      switch (data.reportType) {
        case ReportType.DRIVER:
        case ReportType.DRIVER_GROUP:
        case ReportType.TRAILER:
        case ReportType.TRAILER_GROUP:
          this.table.tableType = ReportTableType.ASSIGNMENTS;
          break;
        case ReportType.USER:
          this.table.tableType = ReportTableType.USER_ACTIVITY;
          break;
      }
      data.table = this.table;
    } else {
      this.title = 'component.report-template.table.edit-table-title';
    }

    this.reportType = data.reportType;

    this.getTranslatedColumns(data.table.tableType).subscribe(
      (columns) => {
        for (const col of columns) {
          const column = data.table.columns.find((x) => col.type === x.type);

          if (column) {
            this.checkedColumns.push({ ...column, initialName: col.name, checked: true });

            if (col.type !== ReportTableColumnType.NOTE) {
              continue;
            }
          }

          this.uncheckedColumns.push(col);
        }
      }
    );

    const allGroups = this.getAllReportTableGroupTypes();

    if (data.table.settings && data.table.settings.groupBy) {
      for (const checked of data.table.settings.groupBy) {
        const group = allGroups.find((g) => g.type === checked.type);

        if (group) {
          this.checkedGroups.push({
            ...checked,
            checked: true,
            name: group.name,
            sort: (checked.sort || group.sort)
          });

          const index = allGroups.indexOf(group);
          allGroups.splice(index, 1);
        }
      }
    }
    this.uncheckedGroups = [...allGroups];

    this.maskTypes = getReportTableMasks(data.table.tableType);

    if (!data.table.settings) {
      data.table.settings = {
        detalling: false,
        groupByTotal: false,
        rowNumbers: false,
        total: false
      };
    }
    if (data.table.settings.masks) {
      this.masks = data.table.settings.masks;
    }
    if (data.table.settings.sort) {
      this.sort = data.table.settings.sort;
    }
    if (data.table.settings.timeLimits) {
      if (!data.table.settings.timeLimits.day) {
        data.table.settings.timeLimits.day = {
          from: { h: 0, m: 0 },
          to: { h: 23, m: 59 }
        };
      } else {
        if (!data.table.settings.timeLimits.day.from) {
          data.table.settings.timeLimits.day.from = { h: 0, m: 0 };
        }
        if (!data.table.settings.timeLimits.day.to) {
          data.table.settings.timeLimits.day.to = { h: 23, m: 59 };
        }
      }
      this.timeLimits = deepClone(data.table.settings.timeLimits) as ITimeLimits;
    }

    this.getTranslatedFilters(data.table.tableType).subscribe(
      (filters) => {
        for (const filter of filters) {

          if (data.table.settings && data.table.settings.filters) {
            const tableFilter = data.table.settings.filters.find((x) => filter.type === x.type);

            if (tableFilter) {
              this.filters.push({ ...tableFilter, checked: true, name: filter.name });
              continue;
            }
          }

          this.filters.push(filter);
        }
      }
    );

    this.tableTypes = getReportTableTypes(data.reportType)
      .map((type) => ({ id: type, name: getReportTableTypeName(type) }));

    this.table.tableType = this.tableTypes && this.tableTypes.length
      ? this.tableTypes[0].id
      : null;

    this.tabs[Tabs.Time].hidden = this.reportType === ReportType.USER;
    this.tabs[Tabs.Filters].hidden = !this.filters.length;
    this.tabs[Tabs.Masks].hidden = !this.maskTypes.length;

    return super.fillData(data);
  }

  /**
   * Переключение выбранности колонки таблицы
   * @param column Колонка
   */
  public toggleChecked(column: IClientReportTableColumn) {
    if (column.type !== ReportTableColumnType.NOTE) {
      const columnsFrom = column.checked ? this.checkedColumns : this.uncheckedColumns;
      const columnsTo = column.checked ? this.uncheckedColumns : this.checkedColumns;
      column.checked = !column.checked;
      const indexFrom = columnsFrom.indexOf(column);
      if (indexFrom !== -1) {
        columnsFrom.splice(indexFrom, 1);
      }
      const indexTo = columnsTo.indexOf(column);
      if (indexTo === -1) {
        columnsTo.push(column);
      }
      if (column === this.editColumn) { this.editColumn = null; }
    } else {
      if (column.checked) {
        const indexFrom = this.checkedColumns.indexOf(column);
        if (indexFrom !== -1) {
          this.checkedColumns.splice(indexFrom, 1);
        }
      } else {
        this.checkedColumns.push({ ...column, checked: true });
      }
    }
  }

  /**
   * Переключение выбранности группировки таблицы
   * @param group Группировка
   */
  public toggleCheckedGroup(group: IClientReportTableGroup) {
    const groupsFrom = group.checked ? this.checkedGroups : this.uncheckedGroups;
    const groupsTo = group.checked ? this.uncheckedGroups : this.checkedGroups;
    group.checked = !group.checked;
    const indexFrom = groupsFrom.indexOf(group);
    if (indexFrom !== -1) {
      groupsFrom.splice(indexFrom, 1);
    }
    const indexTo = groupsTo.indexOf(group);
    if (indexTo === -1) {
      groupsTo.push(group);
    }
    group.sort = { column: null, desc: false };
  }

  /**
   * Признак необходимости отображения кнопки сброса названия колонки
   * @param column Колонка
   */
  public isShowResetColumnNameButton(column: IClientReportTableColumn) {
    return column.name !== column.initialName;
  }

  /**
   * Сброс названия колонки
   * @param column Колонка
   */
  public resetColumnName(column: IClientReportTableColumn) {
    column.name = column.initialName;
  }

  /**
   * Переключение направления сортировки
   * @param sort Сортировка
   */
  public toggleSortDesc(sort: IReportTableSortInfo) {
    sort.desc = !sort.desc;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    this.error = null;

    if (!this.table.name || this.table.name === '') {
      this.translator.get('component.report-template.table.error-1')
        .subscribe((x) => this.error = x);
    }

    if (!this.checkedColumns.length) {
      this.translator.get([
        'component.report-template.table.error-2',
        'component.report-template.table.error-2b'
      ]).subscribe((x) => {
        this.error = this.error
          ? `${this.error}, ${x['component.report-template.table.error-2b']}`
          : x['component.report-template.table.error-2'];
      });
    }

    const columnWithoutName = this.checkedColumns.find(({ name }) => !name || name === '');
    if (columnWithoutName) {
      this.translator.get([
        'component.report-template.table.error-3',
        'component.report-template.table.error-3b'
      ]).subscribe((x) => {
        this.error = this.error
          ? `${this.error}, ${x['component.report-template.table.error-3b']}`
          : x['component.report-template.table.error-3'];
      });
    }

    if (this.error && this.error.length) {
      return;
    }

    this.table.columns = this.checkedColumns.map(({ type, name, whyEmpty }) => ({ type, name, whyEmpty }));
    if (this.timeLimits) {
      this.table.settings.timeLimits = processingTimeLimits(this.timeLimits);
      if (!this.table.settings.timeLimits) {
        delete this.table.settings.timeLimits;
      }
    } else if (this.table.settings.timeLimits) {
      delete this.table.settings.timeLimits;
    }
    if (this.sort.column) {
      this.table.settings.sort = { column: this.sort.column };
      if (this.sort.desc) { this.table.settings.sort.desc = true; }
    } else if (this.table.settings.sort) {
      delete this.table.settings.sort;
    }
    this.table.settings.groupBy = this.checkedGroups.map(
      ({ type, sort }) => {
        const result: IReportTableGroup = { type };
        if (sort && sort.column) {
          result.sort = { column: sort.column };
          if (sort.desc) { result.sort.desc = true; }
        }
        return result;
      }
    );
    this.table.settings.masks = this.masks;
    this.table.settings.filters = this.filters.filter(this.isFilterValid).map(this.getResultFilter);
    this.result = this.table;
    this.close();
  }

  /**
   * Признак необходимости отображения маски
   * @param maskType Тип маски
   */
  public isShowMask(maskType: ReportTableMaskType): boolean {
    return this.maskTypes.includes(maskType);
  }

  /**
   * Проверка фильтра на валидность
   * @param filter Фильтр
   */
  public isFilterValid = (filter: IClientReportTableFilter): boolean => {
    if (!filter.checked) { return false; }
    switch (filter.type) {
      case ReportTableFilterType.COUNTER_BORDERS:
        return this.isFilterHaveMinOrMaxNumber(filter);
      case ReportTableFilterType.UNCOMPLETE_INTERVAL:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.DURATION:
        return this.isFilterHaveMinOrMaxTime(filter);
      case ReportTableFilterType.MILEAGE:
        return this.isFilterHaveMinOrMaxNumber(filter);
      case ReportTableFilterType.MH:
        return this.isFilterHaveMinOrMaxTime(filter);
      case ReportTableFilterType.SPEED:
        return this.isFilterHaveMinOrMaxNumber(filter);
      case ReportTableFilterType.MOVE:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.STOP:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.PARKING:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.SENSOR:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.DRIVER:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.TRAILER:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.FILLING:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.THEFT:
        return this.isFilterHaveAction(filter);
      case ReportTableFilterType.GEOZONE_UNIT:
        return !!((filter.units && filter.units.length) || (filter.geozones && filter.geozones.length));
      default: throw new Error(this.translator.instant('component.report-template.table.error-4'));
    }
  }

  /**
   * Проверка фильтра на наличия действия
   * @param filter Фильтр
   */
  public isFilterHaveAction(filter: IClientReportTableFilter): boolean {
    return !!filter.action;
  }

  /**
   * Проверка фильтра на наличие минимального или максимального значения
   * @param filter Фильтр
   */
  public isFilterHaveMinOrMaxNumber(filter: IClientReportTableFilter): boolean {
    if ((_.isNull(filter.min) || _.isUndefined(filter.min)) &&
      (_.isNull(filter.max) || _.isUndefined(filter.max))) {
      return false;
    }
    let hasMin = true;
    let hasMax = true;
    if (!_.isNull(filter.min) && !_.isUndefined(filter.min) && (!_.isNumber(filter.min) || _.isNaN(filter.min))) {
      hasMin = false;
    }
    if (!_.isNull(filter.max) && !_.isUndefined(filter.max) && (!_.isNumber(filter.max) || _.isNaN(filter.max))) {
      hasMax = false;
    }
    return hasMin || hasMax;
  }

  /**
   * Проверка фильтра на наличие минимального или максимального времени
   * @param filter Фильтр
   */
  public isFilterHaveMinOrMaxTime(filter: IClientReportTableFilter): boolean {
    if ((!filter.min || filter.min === '') && (!filter.max || filter.max === '')) {
      return false;
    }
    const regex = /^\d\d:\d\d:\d\d$/;
    let hasMin = true;
    let hasMax = true;
    if (filter.min && filter.min !== '') {
      if (!(filter.min as string).match(regex)) {
        hasMin = false;
      } else {
        const vals = (filter.min as string).split(':');
        const min = +vals[1];
        const sec = +vals[2];
        if (min > 59 || sec > 59) {
          hasMin = false;
        }
      }
    }
    if (filter.max && filter.max !== '') {
      if (!(filter.max as string).match(regex)) {
        hasMax = false;
      } else {
        const vals = (filter.max as string).split(':');
        const min = +vals[1];
        const sec = +vals[2];
        if (min > 59 || sec > 59) {
          hasMax = false;
        }
      }
    }
    return hasMin || hasMax;
  }

  /**
   * Получение значения числа в фильтре
   * @param value Значение
   */
  public getFilterNumber(value: any): number {
    if (_.isNull(value) || _.isUndefined(value)) { return undefined; }
    if (!_.isNumber(value) || _.isNaN(value)) { return undefined; }
    return +value;
  }

  /**
   * Получение значения времени в фильтре
   * @param value Значение
   */
  public getFilterTime(value: string): string {
    if (!value || value === '') { return undefined; }
    const regex = /^\d\d:\d\d:\d\d$/;
    if (!value.match(regex)) {
      return undefined;
    } else {
      const vals = value.split(':');
      const min = +vals[1];
      const sec = +vals[2];
      if (min > 59 || sec > 59) {
        return undefined;
      }
    }
    return value;
  }

  /**
   * Получение результирующего фильтра
   * @param filter Фильтр
   */
  public getResultFilter = (filter: IClientReportTableFilter): IReportTableFilter<string> => {
    const result: IReportTableFilter<string> = { type: filter.type };
    switch (filter.type) {
      case ReportTableFilterType.COUNTER_BORDERS:
        result.min = this.getFilterNumber(filter.min);
        result.max = this.getFilterNumber(filter.max);
        break;
      case ReportTableFilterType.UNCOMPLETE_INTERVAL:
        result.action = filter.action;
        break;
      case ReportTableFilterType.DURATION:
        result.min = this.getFilterTime(filter.min as string);
        result.max = this.getFilterTime(filter.max as string);
        break;
      case ReportTableFilterType.MILEAGE:
        result.min = this.getFilterNumber(filter.min);
        result.max = this.getFilterNumber(filter.max);
        break;
      case ReportTableFilterType.MH:
        result.min = this.getFilterTime(filter.min as string);
        result.max = this.getFilterTime(filter.max as string);
        break;
      case ReportTableFilterType.SPEED:
        result.min = this.getFilterNumber(filter.min);
        result.max = this.getFilterNumber(filter.max);
        result.ejectIntervals = filter.ejectIntervals;
        break;
      case ReportTableFilterType.MOVE:
        result.action = filter.action;
        break;
      case ReportTableFilterType.STOP:
        result.action = filter.action;
        break;
      case ReportTableFilterType.PARKING:
        result.action = filter.action;
        result.min = this.getFilterTime(filter.min as string);
        result.sum = filter.sum;
        break;
      case ReportTableFilterType.SENSOR:
        result.action = filter.action;
        result.mask = filter.mask && filter.mask !== '' ? filter.mask : undefined;
        result.min = this.getFilterTime(filter.min as string);
        result.max = this.getFilterTime(filter.max as string);
        result.ejectIntervals = filter.ejectIntervals;
        result.sum = filter.sum;
        break;
      case ReportTableFilterType.DRIVER:
        result.action = filter.action;
        result.mask = filter.mask && filter.mask !== '' ? filter.mask : undefined;
        result.ejectIntervals = filter.ejectIntervals;
        break;
      case ReportTableFilterType.TRAILER:
        result.action = filter.action;
        result.mask = filter.mask && filter.mask !== '' ? filter.mask : undefined;
        result.ejectIntervals = filter.ejectIntervals;
        break;
      case ReportTableFilterType.FILLING:
        result.action = filter.action;
        result.mask = filter.mask && filter.mask !== '' ? filter.mask : undefined;
        result.min = this.getFilterNumber(filter.min);
        result.max = this.getFilterNumber(filter.max);
        result.sum = filter.sum;
        break;
      case ReportTableFilterType.THEFT:
        result.action = filter.action;
        result.mask = filter.mask && filter.mask !== '' ? filter.mask : undefined;
        result.min = this.getFilterNumber(filter.min);
        result.max = this.getFilterNumber(filter.max);
        result.sum = filter.sum;
        break;
      case ReportTableFilterType.GEOZONE_UNIT:
        result.geozones = filter.geozones ? filter.geozones : undefined;
        result.units = filter.units ? filter.units : undefined;
        result.ejectIntervals = filter.ejectIntervals;
        break;
      default: throw new Error(this.translator.instant('component.report-template.table.error-4'));
    }
    return result;
  }

  /**
   * Получение признака наличия лимитов времени
   */
  get isTimeLimits() {
    return !!this.timeLimits;
  }

  /**
   * Переключение признака наличия лимитов времени
   */
  public toggleTimeLimits() {
    if (this.isTimeLimits) {
      this.timeLimits = undefined;
    } else {
      this.timeLimits = {
        day: {
          from: { h: 0, m: 0 },
          to: { h: 23, m: 59 }
        },
        week: [],
        month: []
      };
    }
  }

  /**
   * Получение списка переведенных колонок таблицы отчета
   * @param tableType Тип таблицы
   */
  private getTranslatedColumns(tableType: ReportTableType): Observable<IClientReportTableColumn[]> {
    const isScavenger: boolean = this.store?.user?.account?.accountType === AccountType.SCAVENGER;
    const columns = getReportTableColumns(tableType, isScavenger);

    return this.translator.get(columns.map((col) => col.name)).pipe(map(
      (names) => columns.map(
        (col) => {
          col.name = names[col.name];
          return { ...col, initialName: col.name, checked: false };
        }
      )
    ));
  }

  /**
   * Получение списка группировок
   */
  private getAllReportTableGroupTypes(): IClientReportTableGroup[] {
    return getAllReportTableGroupTypes().map((type) => ({
      type,
      checked: false,
      name: getReportTableGroupTypeName(type),
      sort: { column: null, desc: false }
    }));
  }

  /**
   * Получение списка переведенных фильтров таблицы отчета
   * @param tableType Тип таблицы
   */
  private getTranslatedFilters(tableType: ReportTableType): Observable<IClientReportTableFilter[]> {
    const filters = getReportTableFilters(tableType, this.reportType).map(
      (type) => ({
        type,
        checked: false,
        name: getReportTableFilterTypeName(type)
      } as IClientReportTableFilter)
    );

    if (!filters.length) {
      return of<IClientReportTableFilter[]>([]);
    }

    return this.translator.get(filters.map((filter) => filter.name)).pipe(map(
      (names) => {
        for (const filter of filters) {
          filter.name = names[filter.name];
        }

        return filters;
      }
    ));
  }
}

/**
 * Колонка таблицы отчета для использования в компоненте
 */
interface IClientReportTableColumn extends IReportTableColumn {
  /** Изначальное наименование колонки */
  initialName: string;
  /** Признак отмеченной колонки */
  checked: boolean;
}

/**
 * Группировка таблицы отчета для использования в компоненте
 */
interface IClientReportTableGroup extends IReportTableGroup {
  /** Признак отмеченной группировки */
  checked: boolean;
  /** Наименование группировки */
  name: string;
}

/**
 * Список вкладок
 */
enum Tabs {
  Columns,
  Masks,
  Grouping,
  Time,
  Filters,
  Additional
}
