/**
 * Сравнение строк (для сортировки)
 * @param a Первая строка
 * @param b Вторая строка
 * @param isDescending направление сортировки
 */
export function compareStrings(a: string, b: string, isDescending?: boolean): number {
  const options = {
    // caseFirst: 'upper',
    numeric: true,
    // ignorePunctuation: true,
    sensitivity: 'case'
  };
  return a?.localeCompare(b, [], options) * (isDescending ? -1 : 1);
}

export function compare(a: any, b: any, isDescending?: boolean): number {
  if(typeof a === 'number' && typeof b === 'number') {
    return (b - a) * (isDescending ? -1 : 1)
  }
  return compareStrings(a?.toString(), b?.toString(), isDescending);
}

/**
 * Функция лексического сравнения строк с учётом локализации
 */
export const localeSort = (a: { name: string }, b: { name: string }) => compareStrings(a?.name, b?.name)
