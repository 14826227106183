/** Тип месторасположения */
export enum LocationType {
  /** Офис */
  OFFICE = 1,
  /** Клиент */
  CLIENT,
  /** Производитель */
  MANUFACTURER
}

/** Получение списка всех типов месторасположения */
export function getAllLocationTypes(): LocationType[] {
  return [LocationType.OFFICE, LocationType.CLIENT, LocationType.MANUFACTURER];
}

/**
 * Получение наименования типа месторасположения
 * @param type Тип месторасположения
 */
export function getLocationTypeName(type: LocationType): string {
  switch (type) {
    case LocationType.OFFICE: return 'enums.crm.location-type.office';
    case LocationType.CLIENT: return 'enums.crm.location-type.user';
    case LocationType.MANUFACTURER: return 'enums.crm.location-type.manufacturer';
    default: return 'enums.crm.location-type.unknown';
  }
}
