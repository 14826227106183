import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap } from 'rxjs/operators';

import { ISortInfo } from '../../../shared/ISortInfo';
import { IClientRoute } from '../../classes/IClientRoute';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { StoreService } from '../../services/store.service';
import { ModalResult } from '../modal/modal.component';

import { RoutesRepeatComponent } from './repeat/routes.repeat.component';

/**
 * Компонент для маршрутов
 */
@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent {

  /** Строка поиска */
  public search: string;

  /** Признак отображения строки поиска */
  public showSearch: boolean = false;

  /** Сортировка */
  public sort: ISortInfo;

  /**
   * Конструктор
   * @param store Сервис для хранения данных мониторинга
   * @param modalService Сервис работы с модальными окнами
   * @param monitoringService Сервис мониторинга
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    public store: StoreService,
    private modalService: ModalService,
    private monitoringService: MonitoringService,
    private dialogService: DialogService,
    private translator: TranslateService,
    private loadingService: LoadingService
  ) {
    this.sort = { field: 'name', isDescending: false };
  }

  /**
   * Удаление маршрута
   * @param route Маршрут
   */
  public delete(route: IClientRoute) {
    const confirm = this.translator.instant('component.routes.confirm', { val: route.name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.monitoringService.deleteRoute(route), true)))
      .subscribe();
  }

  /**
   * Обработка нажатия на кнопку добавления и изменения маршрута
   * @param route Маршрут
   */
  public addUpdate(route: IClientRoute) {
    if (!route) {
      this.store.editRoute = {
        points: [],
        unitId: null,
        driverId: null
      } as IClientRoute;

    } else {
      this.store.editRoute = {
        ...route,
        points: route.points.map((point) => ({ ...point }))
      };
    }
  }

  /**
   * Обработка нажатия на кнопку отображения маршрута на карте
   * @param route Маршрут
   */
  public showOnMap(route: IClientRoute) {
    this.monitoringService.toggleRouteShowing(route);
  }

  /**
   * Переключение видимости панели поиска
   */
  public toggleSearchPanel() {
    this.showSearch = !this.showSearch;
    this.search = '';
  }

  /**
   * Сортировка
   * @param field Поле, по которому выполняется сортировка
   */
  public sortBy(field: string) {
    if (this.sort.field === field) {
      this.sort.isDescending = !this.sort.isDescending;
    } else {
      this.sort = { field, isDescending: false };
    }
  }

  /**
   * Клик по кнопке изменения повторяемости маршрута
   * @param route Маршрут
   */
  public changeRepeat(route: IClientRoute) {
    const data = { routeId: route.id };
    this.dialogService.addDialog(RoutesRepeatComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.monitoringService.getRoutes());
  }
}
