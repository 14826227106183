import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Компонент input
 */
@Component({
  selector: 'bg-input',
  templateUrl: './bg-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BgInputComponent),
    multi: true
  }]
})
export class BgInputComponent implements OnInit, ControlValueAccessor {

  public value: string = ''

  /** Плейсхолдер */
  @Input('placeholder') placeholder: string = ''

  /** Тип */
  @Input('type') type: string = 'text'

  /** Имя */
  @Input('name') name: string = ''

  /** Текст ошибки */
  @Input('formControl') formControl: AbstractControl

  /** Признак необходимости заполнения */
  @Input('required') required: boolean = false

  constructor() {
    // dummy
  }

  ngOnInit(): void {
    // dummy
  }

  onChange(_: any): void {
    // dummy
  }

  onTouched(_: any): void {
    // dummy
  }

  updateChanges() {
    this.onChange(this.value);
  }

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: string): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Признак отобрежения ошибки
   */
  get isError() {
    return this.formControl?.invalid && (this.formControl?.dirty || this.formControl?.touched)
  }

  /**
   * Возвращаем первый ключ из объекта ошибок
   */
  get getErrorFirstKey() {
    const errorsKeys = Object.keys(this.formControl?.errors)
    return errorsKeys.shift()
  }

  /**
   * Возвращаем максимально разрешенную длину поля
   */
  get getMaxLength() {
    return this.formControl?.errors?.maxlength?.requiredLength
  }

  /**
   * Возвращаем минимально разрешенную длину поля
   */
  get getMinLength() {
    return this.formControl?.errors?.minlength?.requiredLength
  }
}
