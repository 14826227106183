import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';

import { RightType } from '../../../../../shared/rights/RightType';
import { IListItem } from '../../../../classes/IListItem';
import { SelectionType } from '../../../../components/select-items/select-items.component';
import { AccountsService } from '../../../../services/accounts.service';
import { ISelectItemsComponent, ModalDialogService } from '../../../../services/modal-dialog.service';
import { ToastService } from '../../../../services/toast.service';
import {
  BgRepeatersService,
  IRepeater,
  IRepeaterHistory,
  IRepeaterPeriod,
  RepeaterHistoryType,
  RepeaterPeriodType,
  RepeaterProtocols
} from '../../bg-repeaters.service';

@Component({
  selector: 'app-repeater',
  templateUrl: './repeater.component.html'
})
export class RepeaterComponent implements OnDestroy {
  /**
   * Подиска на открытие модального окна
   */
  private visibleModalSubscription: Subscription;

  /**
   * Признак открытости окна
   */
  public isOpen: boolean = false;

  /**
   * ретранслятор
   */
  public repeater: IRepeater;

  /**
   * Все доступные аккаунты
   */
  public accounts$: Observable<Array<IListItem<string>>>;

  /**
   * Список протоколов
   */
  public protocols: Array<IListItem<string>>;

  /**
   * Доступные для выбора ТС
   */
  public allUnits: Array<IListItem<string>> = [];

  /**
   * Выбраные объекты из списка
   */
  public units: IListItem<string>[] = [];

  /**
   * История по ретранслятору
   */
  public history: IRepeaterHistory[] = [];

  private protocolsEnum: Array<RepeaterProtocols> = [
    RepeaterProtocols.WIALON_IPS,
    RepeaterProtocols.EGTS
  ];

  /**
   * Конструктор
   * @param repeaterService
   * @param toastService
   * @param formBuilder
   * @param accountService
   * @param modalDialogService
   */
  constructor(
    public repeaterService: BgRepeatersService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private accountService: AccountsService,
    private modalDialogService: ModalDialogService
  ) {
    this.accounts$ = this.accountService.getWithRight(RightType.VIEW_BASIC);

    this.visibleModalSubscription = this.repeaterService.visibleRepeater$.subscribe(this.changeVisibility)

    this.protocols = this.protocolsEnum
      .map((n) => ({ name: this.repeaterService.getRepeaterProtocolName(n), id: n }));
  }

  private changeVisibility = (b) => {
    if (!b.id) {
      this.repeater = {} as IRepeater;
      this.history = [];
      this.units = [];
      this.addPeriod();
      this.isOpen = b.isOpen
      return;
    }
    forkJoin([
      this.repeaterService.getOne(b.id),
      this.repeaterService.getHistory(b.id)
    ]).subscribe(([r, h]) => {
      this.repeater = {
        ...r,
        periods: r.periods.map((p) => ({
          begin: p.begin ? new Date(p.begin) : null,
          end: p.end ? new Date(p.end) : null
        }))
      }
      this.history = h
      this.isOpen = b.isOpen
    })
  };

  /**
   * При уничтожении
   */
  ngOnDestroy() {
    this.visibleModalSubscription.unsubscribe()
  }

  /**
   * Загрузка объектов
   */
  public loadUnits(): void {
    if (!this.repeater?.accountId) {
      return
    }

    this.repeaterService.getUnitsList(this.repeater?.accountId)
      .subscribe((units) => {
        if (!units.length) {
          this.toastService.warn('component.crm.repeaters.modal.toast.no-units');
        }
        this.allUnits = units
      });
  }

  /**
   * Выбор объектов мониторинга
   */
  public selectUnits() {
    const result = new Subject<IListItem<any>[]>();
    const data = {
      items: this.allUnits,
      selected: this.units,
      title: 'component.repeater.select-objects',
      selection: SelectionType.Free,
      result
    } as ISelectItemsComponent;

    this.modalDialogService.openSubject.next(data);
    result.subscribe((r) => {
      this.units = r;
    });
  }

  /**
   * Удаление тс
   * @param id
   */
  public deleteUnit(id: string) {
    this.units = this.units.filter((i) => i.id !== id)
  }

  /**
   * Добавление периода ретрансляции
   */
  public addPeriod(isOld?: boolean) {
    if (!this.repeater.periods) {
      this.repeater.periods = [];
    }
    this.repeater.periods.push({
      begin: null,
      end: null,
      isNew: true,
      type: isOld ? RepeaterPeriodType.OLD : RepeaterPeriodType.FUTURE
    })
  }

  /**
   * Удаление периода ретрансляции
   * @param i индекс
   */
  public deletePeriod(i: number): void {
    this.repeater.periods.splice(i, 1)
  }

  /**
   * Отправка формы
   */
  public confirm(): void {
    if (this.repeater.id) {
      this.repeaterService.update(this.repeater)
        .subscribe(() => {
          this.repeaterService.loadList$.next()
          this.close()
        })
      return
    }
    this.repeaterService.add(this.repeater, this.units)
      .subscribe(() => {
        this.repeaterService.loadList$.next()
        this.close()
      })
  }

  /**
   * Звкрыть окно
   */
  public close(): void {
    this.repeaterService.visibleRepeater$.next({ isOpen: false });
    this.repeater = {} as IRepeater;
    this.addPeriod();
  }

  /**
   * Возможность сохранения
   */
  public get isDisabled(): boolean {
    return !this.repeater.name
      || !this.repeater.address
      || !this.repeater.protocol
      || (this.repeater?.id ? !this.repeater?.uid : !this.units.length || !this.repeater.accountId);
  }

  /**
   * Проверка на прошлый период
   * (запрещаем редактировать прошлый период)
   * @param date
   * @param isNew
   */
  public disabledPeriod(date: Date, isNew: boolean): boolean {
    if (!this.repeater.id) return false;
    return !isNew && (date?.getTime() < (new Date()).getTime());
  }

  /**
   * Отключение мусорки
   * @param item
   */
  public disabledTrash(item: IRepeaterPeriod) {
    return (item.begin < new Date() || item.end < new Date()) && !item.isNew;
  }

  public minDateBegin(item: IRepeaterPeriod) {
    if (item.type === RepeaterPeriodType.FUTURE) {
      return new Date();
    }
    return null;
  }

  public maxDateBegin(item: IRepeaterPeriod) {
    if (item.type === RepeaterPeriodType.FUTURE) {
      return null;
    }
    return new Date()
  }

  public minDateEnd(item: IRepeaterPeriod) {
    if (item.type === RepeaterPeriodType.FUTURE) {
      return new Date();
    }
    return null;
  }

  public maxDateEnd(item: IRepeaterPeriod) {
    if (item.type === RepeaterPeriodType.FUTURE) {
      return null;
    }
    return new Date()
  }

  /**
   * Возвращает название типа истории
   * @param item
   */
  getHistoryItem(item: RepeaterHistoryType): string {
    switch (item) {
      case RepeaterHistoryType.Create:
        return 'component.history.unit-block';
      case RepeaterHistoryType.Update:
        return 'component.history.retranslator-change';
      case RepeaterHistoryType.Delete:
        return 'component.history.retranslator-delete';
    }
  }

}