/**
 * Сервис для работы с гостевым доступом
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGuestAccessUnit } from '../../../../shared/guest-access/IGuestAccessUnit';
import { ConfigService } from '../../../services/config.service';

@Injectable()
export class BgGuestAccessService {

  private readonly baseUrl = `${this.configService.url}/guest-access-unit`;

  /**
   * Конструктор
   * @param http
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }

  /**
   * Получаем фичу на карту
   * @param id
   */
  public getItem(id: string): Observable<IGuestAccessUnit> {
    return this.http.get<IGuestAccessUnit>(`${this.baseUrl}/${id}`)
  }
}
