import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subscription } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';

import { CountryCode, getCountryName } from '../../../../../shared/crm/CountryCodes';
import { getAllRefTypes, IRefItem, RefType } from '../../../../../shared/crm/IRefItem';
import { getProtocolTypeName, ProtocolType } from '../../../../../shared/crm/ProtocolType';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';
import { RefsService } from '../../../../services/refs.service';
import { localeSort } from '../../../../utils/sort';
import { ModalResult } from '../../../modal/modal.component';
import { RefsEditComponent } from '../edit/refs.edit.component';

/**
 * Компонент для списка записей справочника
 */
@Component({
  selector: 'refs-items',
  templateUrl: './refs.items.component.html',
  styleUrls: ['./refs.items.component.scss']
})
export class RefsItemsComponent implements OnInit, OnDestroy {

  /** Тип справочника */
  @Input() public type: RefType;

  /** Выбранная запись справочника */
  @Input() public selected: IRefItem;

  /** Генератор событий об изменении выбранной записи справочника */
  @Output() public selectedChange = new EventEmitter<IRefItem>();

  /** Список записей справочника */
  public items: IRefItem[];

  /** Содержимое строки поиска */
  public search: string;

  /** Подписка на нажатие кнопки */
  public addItemSubscription: Subscription;

  /** Текст сообщения об ошибке */
  public error: string;

  /**
   * Конструктор
   * @param crudService Сервис работы с ДИУП
   * @param loadingService Сервис для отображения прогресса загрузки
   * @param modalService Сервис модальных окон
   * @param route Текущий маршрут
   * @param router Маршрутизатор
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param refsService
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private translator: TranslateService,
    public refsService: RefsService
  ) {
    this.addItemSubscription = this.refsService.addItem.subscribe(this.addItem)
  }

  /**
   * Получение признака необходимости отображения колонки с ценой
   */
  public get isShowCostColumn() {
    return this.type === RefType.TRACKER
      || this.type === RefType.OPTIONAL_EQUIPMENT
      || this.type === RefType.SIM_TARIFF
      || this.type === RefType.COUNTRY_CODES;
  }

  /**
   * Получение признака необходимости отображения колонки
   * с признаков приостановки обслуживания
   */
  public get isShowSuspendColumn() {
    return this.type === RefType.EQUIPMENT_STATE;
  }

  /**
   * Получение признака необходимости отображения колонки с типом протокола
   */
  public get isShowProtocolTypeColumn() {
    return this.type === RefType.TRACKER;
  }

  /**
   * Получение признака необходимости отображения колонки с кодом страны
   */
  public get isShowCountrysColumns() {
    return this.type === RefType.COUNTRY_CODES;
  }

  /**
   * Получение признака режима выбора из справочника
   */
  public get isSelectMode() {
    return this.selectedChange.observers.length > 0;
  }

  /**
   * Выбор записи из справочника
   * @param item Запись справочника
   */
  public select(item: IRefItem) {
    if (!this.isSelectMode) {
      return;
    }

    this.selected = item;
    this.selectedChange.emit(this.selected);
  }

  /**
   * Получение признака выбранности записи справочника
   * @param item Запись справочника
   */
  public isSelected(item: IRefItem) {
    return this.selected && this.selected.id === item.id;
  }

  /**
   * Добавление записи в справочник
   */
  public addItem = () => {
    const item: IRefItem = { type: this.type } as IRefItem;
    const data = { item };
    this.dialogService.addDialog(RefsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение записи справочника
   * @param item Запись справочника
   */
  public updateItem(item: IRefItem) {
    const data = { item: { ...item } };
    this.dialogService.addDialog(RefsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Удаление записи справочника
   * @param item Запись справочника
   */
  public delItem(item: IRefItem) {
    const confirm = this.translator.instant('component.crm.refs.confirm', { val: item.name });
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(item.id, CRUDEntityType.CRM_REF_ITEM), true)))
      .subscribe(this.loadData);
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    if (this.type) {
      this.loadData();
      return;
    }

    this.route.params.subscribe((params) => {
      this.type = +params.id;
      if (!getAllRefTypes().includes(this.type)) {
        this.router.navigate(['crm', 'refs', RefType.TRACKER]).then();
      } else {
        this.loadData();
      }
    });
  }

  /**
   * Обработки после дестроя компонента
   */
  ngOnDestroy(): void {
    this.addItemSubscription.unsubscribe();
  }

  /**
   * Получение названия типа протокола
   * @param protocolType Тип протокола
   */
  public getProtocolTypeName(protocolType: ProtocolType) {
    return getProtocolTypeName(protocolType);
  }

  /**
   * Получение названия кода страны
   * @param code Код страны
   */
  public getCountryCodeName(code: CountryCode) {
    return getCountryName(code);
  }

  /**
   * Загрузка записей справочника с сервера
   */
  protected loadData = () => {
    const query = { type: `${this.type}` };
    this.refsService.search = '';

    this.loadingService
      .wrap(this.crudService.getList(CRUDEntityType.CRM_REF_ITEM, query), true)
      .subscribe((items: IRefItem[]) => {
        this.items = items?.sort(localeSort);

        if (this.selected && !this.items.some((i) => i.id === this.selected.id)) {
          this.selected = null;
        }

        if (this.type === RefType.COUNTRY_CODES) {
          this.error = !this.items.find((item) => item.countryCurrency) ? 'component.crm.refs.error-base-country' : null;
        }
      });
  }
}
