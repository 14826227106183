import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';

import { AccountType } from '../../../../shared/accounts/IAccount';
import { getAllRefTypes, getRefTypeName, RefType } from '../../../../shared/crm/IRefItem';
import { IListItem } from '../../../classes/IListItem';
import { RefsService } from '../../../services/refs.service';
import { StoreService } from '../../../services/store.service';

/**
 * Компонент для отображения списка всех справочников
 */
@Component({
  selector: 'crud-refs',
  templateUrl: './refs.component.html',
  styleUrls: ['./refs.component.scss']
})
export class RefsComponent {

  /** Список справочников */
  public refs: IListItem<RefType>[] = [];

  /**
   * Консруктор
   * @param router Маршрутизатор
   * @param refsService
   * @param dialogService
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    private router: Router,
    public refsService: RefsService,
    public dialogService: DialogService,
    store: StoreService
  ) {
    this.refs = getAllRefTypes()
      .filter((value) => store.user?.account?.accountType === AccountType.GLOBAL_POSITION
        || value !== RefType.COUNTRY_CODES)
      .map((id) => ({ id, name: getRefTypeName(id) }));
  }

  /**
   * Получение признака выбранного в данный момент справочника
   * @param ref Справочник
   */
  public isSelected(ref: IListItem<RefType>) {
    const url = `/crm/refs/${ref.id}`;
    return this.router.isActive(url, true);
  }

  public addItem() {
    this.refsService.addItem.next()
  }
}
