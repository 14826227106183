import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';

import { IGeozoneMap } from '../../shared/geozones/IGeozoneMap';

import { HostConfigService } from './host-config.service';

/**
 * Сервис для работы с картой
 */
@Injectable()
export class MapService {

  /**
   * Конструктор
   * @param hostConfigService
   */
  constructor(
    private hostConfigService: HostConfigService
  ) {
  }

  /**
   * Видимость слоёв для всех разделов по умолчанию
   */
  private static readonly defaultLayersVisibility: ISectionLayersVisibility = {
    tracking: {tracking: true, race: true, geozones: true},
    race: {tracking: true, race: true, geozones: true},
    geozones: {tracking: true, race: true, geozones: true},
    drivers: {tracking: true, race: true, geozones: true, drivers: true},
    trailers: {tracking: true, race: true, geozones: true, trailers: true},
    routes: {tracking: true, geozones: true, routes: true},
    reports: {tracking: true, race: true, geozones: true, reports: true},
    messages: {tracking: true, race: true, geozones: true, messages: true}
  };

  /** Событие обновления размера карты */
  public resizeMapSubject = new Subject<void>();

  /** Событие обновления видимости слоёв */
  public layersVisibilitySubject = new Subject<void>();

  /** Открыт раздел мониторинг */
  public isTracking: boolean;

  /** Видимость слоев текущего раздела */
  public layersVisibility: ILayersVisibility;

  /** Событие печати карты */
  public printMapSubject = new Subject<Subscriber<string>>();

  /** Событие обновления карты (костыль для печати яндекс карт) */
  public refreshMapSubject = new Subject<Subscriber<void>>();

  /** Видимость слоёв всех разделов */
  private sectionLayersVisibility: ISectionLayersVisibility;

  /** Событие релоада карты */
  public loadOrReloadGeozonesSubject = new Subject<void>()

  /** Событие отображения списка геозон */
  public showGeozonesSubject = new Subject<IGeozoneMap[]>();

  /** Событие скрытия списка геозон */
  public hideGeozonesSubject = new Subject<string[]>();

  /** Событие очистки слоя геозон */
  public clearGeozonesSubject = new Subject<void>()

  /** Признак включения редактирования геозоны */
  public isEditGeozone: boolean = false;

  /**
   * Признак инициализации карт Google
   */
  public initGoogleMap$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Признак инициализации карт Yandex
   */
  public initYandexMap$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Получение изображения карты с рейсами (в виде DataURL)
   */
  public getMapImage(): Observable<string> {
    return new Observable((subscriber) => this.printMapSubject.next(subscriber));
  }

  /**
   * Запуск события на обновление размера карты
   */
  public resizeMap() {
    this.resizeMapSubject.next();
  }

  /**
   * Установка видимости слоёв
   * @param section Текущий раздел мониторинга
   */
  public setLayersVisibility(section: Section) {
    if (!this.sectionLayersVisibility) {
      this.initLayersVisibility();
    }

    this.isTracking = (section === Section.Tracking);

    this.layersVisibility = this.sectionLayersVisibility[section];
    this.layersVisibilitySubject.next();
  }

  /**
   * Проверка видимости слоя
   * @param layer Слой
   */
  public isLayerVisible(layer: Section) {
    return this.layersVisibility[layer];
  }

  /**
   * Переключение видимости слоя
   * @param section Текущий раздел мониторинга
   * @param layer Слой
   */
  public toggleLayersVisibility(section: Section, layer: Section) {
    this.layersVisibility[layer] = !this.layersVisibility[layer];
    this.layersVisibility[section] = true;

    this.sectionLayersVisibility[section] = this.layersVisibility;
    localStorage.setItem('layerSettings', JSON.stringify(this.sectionLayersVisibility));

    this.layersVisibilitySubject.next();
  }

  /**
   * Инициализация настроек отображения слоёв карты
   */
  private initLayersVisibility() {
    const settings = localStorage.getItem('layerSettings');
    if (!settings || settings === '') {
      this.sectionLayersVisibility = MapService.defaultLayersVisibility;
      return;
    }

    const lv: ISectionLayersVisibility = JSON.parse(settings);

    for (const section of Object.keys(MapService.defaultLayersVisibility)) {
      if (!lv[section]) {

        lv[section] = MapService.defaultLayersVisibility[section];

      } else if (!lv[section][section]) {

        // Слой, соответствующий разделу, всегда должен быть видим
        lv[section][section] = true;

      }
    }

    this.sectionLayersVisibility = lv;
  }
}

/**
 * Видимость слоёв всех разделов
 */
export interface ISectionLayersVisibility extends Partial<Record<Exclude<Section, Section.Analytics>, ILayersVisibility>> { }

/**
 * Видимость слоев
 */
export interface ILayersVisibility extends Partial<Record<Exclude<Section, Section.Analytics>, boolean>> { }

/**
 * Разделы мониторинга
 */
export enum Section {
  /**
   * Мониторинг
   */
  Tracking = 'tracking',

  /**
   * Рейсы
   */
  Race = 'race',

  /**
   * Геозоны
   */
  Geozone = 'geozones',

  /**
   * Водители
   */
  Driver = 'drivers',

  /**
   * Прицепы
   */
  Trailer = 'trailers',

  /**
   * Маршруты
   */
  Route = 'routes',

  /**
   * Отчёты
   */
  Reports = 'reports',

  /**
   * Сообщения
   */
  Messages = 'messages',

  /**
   * Аналитика
   */
  Analytics = 'analytics'
}
