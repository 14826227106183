import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

import { NavigationMoreComponent } from '../components/navigation/more/navigation.more.component';
import { BgPopoverService } from '../modules/bg-popover';

import { HelpService } from './help.service';
import { HostConfigService } from './host-config.service';
import { StoreService } from './store.service';

/**
 * Сервис конфигурации
 */
@Injectable()
export class NavigationService {

  /** Размер панели навигации */
  public isExpand: boolean = true;

  /** Список элементов для верхнего списка навигации */
  public topNav: IPanelItem[] = [];

  /** Список элементов для нижнего списка навигации */
  public bottomNav: IPanelItem[] = [];

  /** Признак отображения рабочей панели */
  public isWorkPanelVisible: boolean = true;

  /**
   * Конструктор
   * @param confirmationService Injects {@link ConfirmationService}
   * @param helpService Сервис справки
   * @param hostConfigService Сервис настроек хоста
   * @param popoverService Сервис всплывающих окон
   * @param router Маршрутизатор
   * @param store Сервис для хранения данных мониторинга
   * @param translator Injects {@link TranslateService}
   */
  constructor(
    private confirmationService: ConfirmationService,
    private helpService: HelpService,
    private hostConfigService: HostConfigService,
    private popoverService: BgPopoverService,
    private router: Router,
    private store: StoreService,
    private translator: TranslateService
  ) {
  }

  public fillNavigation(type: NavigationPanelType) {
    this.topNav = [];
    this.bottomNav = [];

    this.topNav.push({
      title: 'classes.nav-component.main',
      img: `/assets/images/labels/${this.hostConfigService.icon}`,
      type: PanelItemType.Link,
      link: '/'
    });

    switch (type) {
      case NavigationPanelType.Common:
        this.fillNav();
        break;
      case NavigationPanelType.Admin:
        this.fillAdminNav();
        break;
      case NavigationPanelType.CRM:
        this.fillCRMNav();
        break;
      case NavigationPanelType.Client:
        this.fillClientNav();
    }

    // Добавляем кнопки в нижнюю панель
    this.bottomNav.push({
      title: 'component.navigation.help',
      img: '/assets/images/navigation-panel/help.png',
      type: PanelItemType.Button,
      action: () => this.helpService.openHelpBlank(this.router.url)
    });

    // Добавляем логаут
    const logout: IPanelItem = !localStorage.getItem('observer')
      ? {
        title: 'classes.nav-component.exit',
        img: '/assets/images/navigation-panel/shutdown.png',
        type: PanelItemType.Button,
        action: this.logout
      } : {
        title: 'component.navigation.more.observe-end',
        img: '/assets/images/navigation-panel/eye-slash.png',
        type: PanelItemType.Button,
        action: this.observeEnd
      };

    // Если заблокирован пользователь
    if (!this.store.user || this.store.user.account.isBlocked) {
      this.bottomNav.push(logout);
      return;
    }

    // Если не заблокирован, добавляем кнопки
    this.bottomNav.push(
      {
        title: 'component.crm.navigation.monitoring',
        img: '/assets/images/navigation-panel/map.png',
        type: PanelItemType.SectionLink,
        link: '/monitoring'
      }, {
        title: 'component.navigation.details',
        img: '/assets/images/navigation-panel/more.png',
        type: PanelItemType.Button,
        action: this.more
      }, {
        title: 'component.navigation.admin',
        img: '/assets/images/navigation-panel/settings.png',
        type: PanelItemType.SectionLink,
        link: '/admin'
      })

    // Если диллер, добавляем дилерскую кнопку
    if (this.store.user && this.store.user.isDealer) {
      this.bottomNav.push({
        title: 'component.navigation.dealer',
        img: '/assets/images/navigation-panel/dealer.png',
        type: PanelItemType.SectionLink,
        link: '/crm'
      });
    }

    this.bottomNav.push(logout);
  }

  /**
   * Обработка клика по кнопке "Дополнительно"
   * @param e Аргумент события клика
   */
  private more = (e: MouseEvent) => {
    e.stopPropagation();
    const target = e.target as Element;
    const rect = target.getBoundingClientRect();
    const position = {x: rect.right, y: rect.top};
    this.popoverService.addPopover(NavigationMoreComponent, position);
  }

  /**
   * Выход из режима наблюдения
   */
  private logout = (event: MouseEvent) => {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translator.instant('ui.exit-confirmation'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.router.navigateByUrl('/logout').then()
    });
  };

  /**
   * Выход из режима наблюдения
   * @param e Событие мыши
   */
  private observeEnd = (e: MouseEvent) => {
    e.stopPropagation();
    localStorage.removeItem('observer');
    localStorage.removeItem('observable');
    this.router.navigate(['login'], {skipLocationChange: true, replaceUrl: true}).then();
  };

  /**
   * Заполнение панели
   */
  private fillNav() {
    this.topNav.push({
        title: 'component.navigation.tracking',
        img: '/assets/images/navigation-panel/tracking.png',
        type: PanelItemType.Section,
        link: '/monitoring/tracking'
      }, {
        title: 'component.navigation.race',
        img: '/assets/images/navigation-panel/race.png',
        type: PanelItemType.Section,
        link: '/monitoring/race'
      }, {
        title: 'component.navigation.geo',
        img: '/assets/images/navigation-panel/geozone.png',
        type: PanelItemType.Section,
        link: '/monitoring/geozones'
      }, {
        title: 'component.navigation.drivers',
        img: '/assets/images/navigation-panel/drivers.png',
        type: PanelItemType.Section,
        link: '/monitoring/drivers'
      }, {
        title: 'component.navigation.trails',
        img: '/assets/images/navigation-panel/trailers.png',
        type: PanelItemType.Section,
        link: '/monitoring/trailers'
      },
      // {
      //   title: 'component.navigation.routes',
      //   img: '/assets/images/navigation-panel/routes.png',
      //   type: PanelItemType.Section,
      //   link: '/monitoring/routes'
      // },
      {
        title: 'component.navigation.reports',
        img: '/assets/images/navigation-panel/report.png',
        type: PanelItemType.Section,
        link: '/monitoring/reports'
      }, {
        title: 'component.navigation.messages',
        img: '/assets/images/navigation-panel/messages.png',
        type: PanelItemType.Section,
        link: '/monitoring/messages'
      }, {
        title: 'component.navigation.analytics',
        img: '/assets/images/navigation-panel/flask.png',
        type: PanelItemType.Section,
        link: '/monitoring/analytics'
      }, {
        title: 'component.navigation.logistics',
        img: '/assets/images/navigation-panel/logistics.png',
        type: PanelItemType.Link,
        link: '/admin/logistic'
      });
  }

  /**
   * Заполнение панели админки
   */
  private fillAdminNav() {
    this.topNav.push({
      title: 'component.navigation.report-templates',
      img: '/assets/images/navigation-panel/report-template.png',
      type: PanelItemType.SectionLink,
      link: '/admin/report-templates'
    }, {
      title: 'component.navigation.notifications',
      img: '/assets/images/navigation-panel/notification.png',
      type: PanelItemType.SectionLink,
      link: '/admin/notifications'
    }, {
      title: 'component.navigation.tasks',
      img: '/assets/images/navigation-panel/task.png',
      type: PanelItemType.SectionLink,
      link: '/admin/tasks'
    }, {
      title: 'component.navigation.objects',
      img: '/assets/images/navigation-panel/car.png',
      type: PanelItemType.SectionLink,
      link: '/admin/units'
    }, {
      title: 'component.navigation.groups',
      img: '/assets/images/navigation-panel/unit-group.png',
      type: PanelItemType.SectionLink,
      link: '/admin/unit-groups'
    }, {
      title: 'component.navigation.users',
      img: '/assets/images/navigation-panel/users.png',
      type: PanelItemType.SectionLink,
      link: '/admin/users'
    }, {
      title: 'component.navigation.guest-access',
      img: '/assets/images/navigation-panel/refs.png',
      type: PanelItemType.SectionLink,
      link: '/admin/guest-access'
    }, {
      title: 'component.navigation.logistics',
      img: '/assets/images/navigation-panel/logistics.png',
      type: PanelItemType.SectionLink,
      link: '/admin/logistic'
    });
  }

  /**
   * Заполнение панели CRM
   */
  private fillCRMNav() {
    this.topNav.push({
      title: 'component.crm.navigation.accounts',
      img: '/assets/images/navigation-panel/acc.png',
      type: PanelItemType.SectionLink,
      link: '/crm/accounts'
    }, {
      title: 'component.crm.navigation.clients',
      img: '/assets/images/navigation-panel/client.png',
      type: PanelItemType.SectionLink,
      link: '/crm/clients'
    }, {
      title: 'component.crm.navigation.contracts',
      img: '/assets/images/navigation-panel/contract.png',
      type: PanelItemType.SectionLink,
      link: '/crm/contracts'
    }, {
      title: 'component.crm.navigation.tariffs',
      img: '/assets/images/navigation-panel/tariff.png',
      type: PanelItemType.SectionLink,
      link: '/crm/tariffs'
    }, {
      title: 'component.crm.navigation.trackers',
      img: '/assets/images/navigation-panel/tracker.png',
      type: PanelItemType.SectionLink,
      link: '/crm/terminals'
    }, {
      title: 'component.crm.navigation.equip',
      img: '/assets/images/navigation-panel/equipment.png',
      type: PanelItemType.SectionLink,
      link: '/crm/optionals'
    }, {
      title: 'component.crm.navigation.sim',
      img: '/assets/images/navigation-panel/sim.png',
      type: PanelItemType.SectionLink,
      link: '/crm/sim-cards'
    }, {
      title: 'component.crm.navigation.references',
      img: '/assets/images/navigation-panel/refs.png',
      type: PanelItemType.SectionLink,
      link: '/crm/refs'
    }, {
      title: 'component.crm.navigation.system-notification',
      img: '/assets/images/navigation-panel/system-notification.png',
      type: PanelItemType.SectionLink,
      link: '/crm/system-notifications'
    }, {
      title: 'component.navigation.repeaters',
      img: '/assets/images/navigation-panel/ret.png',
      type: PanelItemType.SectionLink,
      link: '/crm/repeaters'
    });
  }

  /**
   * Заполнение панели клиента
   */
  private fillClientNav() {
    this.topNav.push({
      title: 'component.client.nav.finances',
      img: '/assets/images/navigation-panel/client-finances.png',
      type: PanelItemType.SectionLink,
      link: '/client/finances'
    }, {
      title: 'component.client.nav.units-activity',
      img: '/assets/images/navigation-panel/units-activity.png',
      type: PanelItemType.SectionLink,
      link: '/client/units-activity'
    });

    if (!this.store.user || this.store.user.account.isBlocked) {
      return;
    }
  }
}

/**
 * Тип панели навигации
 */
export enum NavigationPanelType {
  /** Обычная */
  Common,
  /** Админская */
  Admin,
  /** CRM */
  CRM,
  /** Клиентская */
  Client
}

/**
 * Элемент панели
 */
export interface IPanelItem {
  /** Заголовок */
  title: string;
  /** Путь к изображению */
  img: string;
  /** Тип элемента панели */
  type: PanelItemType;
  /** Навигационная ссылка */
  link?: string;
  /** Назначенное действие */
  action?: (e: MouseEvent) => void;
}

/**
 * Тип элемента панели
 */
export enum PanelItemType {
  /** Ссылка */
  Link,
  /** Раздел */
  Section,
  /** Раздел - ссылка */
  SectionLink,
  /** Кнопка */
  Button
}
