import { OnDestroy, OnInit } from '@angular/core';

import { ActivityService } from '../services/activity.service';

/**
 * Базовый компонент для раздела системы
 * (мониторинг/администрирование/дилер)
 */
export class SectionComponent implements OnInit, OnDestroy {

  /**
   * Конструктор
   * @param activityService Сервис учета активности пользователей
   */
  constructor(protected activityService: ActivityService) { }

  /**
   * Обработки при инициализации компонента
   */
  public ngOnInit() {
    this.activityService.startActivityTrack();
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    this.activityService.stopActivityTrack();
  }
}
