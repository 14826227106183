import { Pipe, PipeTransform } from '@angular/core';

import { ISortInfo } from '../../shared/ISortInfo';
import { compare } from '../utils/sort';

/**
 * Сортировка по одному полю
 */
@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {
  public transform<T>(items: T[], sort: ISortInfo): T[] {
    if (!sort) return items;

    return items?.sort((a, b) => {
      const itemA = this.getField<T>(a, sort.field)
      const itemB = this.getField<T>(b, sort.field)
      return compare(itemA, itemB, sort.isDescending)
    });
  }

  /**
   * Получение поля объекта.
   * Если название поля содержит точки, то получается поле дочернего объекта
   * @param item Объект массива, поле которого необходимо получить
   * @param field Получаемое поле
   */
  private getField<T>(item: T, field: string): T {
    if (!item) { return undefined; }
    if (!field?.includes('.')) { return item[field]; }

    const parts = field?.split('.');
    const firstPart = parts?.shift();
    return this.getField(item[firstPart], parts?.join('.'));
  }
}
