/**
 * Тип периода для быстрого формирования рейса/отчета
 */
export enum FastPeriodType {
  /** Сегодня */
  TODAY = 1,
  /** Вчера */
  YESTERDAY,
  /** Неделя */
  WEEK,
  /** Текущая неделя */
  CUR_WEEK,
  /** Прошлая неделя */
  PREV_WEEK,
  /** Месяц */
  MONTH,
  /** Текущий месяц */
  CUR_MONTH,
  /** Предыдущий месяц */
  PREV_MONTH,
  /** Другой */
  OTHER
}

/**
 * Получение списка всех типов периодов для быстрого формирования рейса/отчета
 */
export function getAllFastPeriodTypes(): FastPeriodType[] {
  const result: FastPeriodType[] = [];
  for (let i = 1; i <= 9; i++) {
    result.push(i as FastPeriodType);
  }
  return result;
}

/**
 * Получение наименования типа периода для быстрого формирования рейса/отчета
 * @param type Тип периода для быстрого формирования рейса/отчета
 */
export function getFastPeriodTypeName(type: FastPeriodType): string {
  switch (type) {
    case FastPeriodType.TODAY: return 'enums.fast-period-type.today';
    case FastPeriodType.YESTERDAY: return 'enums.fast-period-type.yesterday';
    case FastPeriodType.WEEK: return 'enums.fast-period-type.week';
    case FastPeriodType.CUR_WEEK: return 'enums.fast-period-type.current-week';
    case FastPeriodType.PREV_WEEK: return 'enums.fast-period-type.past-week';
    case FastPeriodType.MONTH: return 'enums.fast-period-type.month';
    case FastPeriodType.CUR_MONTH: return 'enums.fast-period-type.current-month';
    case FastPeriodType.PREV_MONTH: return 'enums.fast-period-type.past-month';
    case FastPeriodType.OTHER: return 'enums.fast-period-type.other';
    default: return 'enums.fast-period-type.unknown';
  }
}

/**
 * Получение периода для быстрого рейса/отчета
 * @param type Тип периода
 * @param from Начало периода для типа "Другой"
 * @param to Конец периода для типа "Другой"
 */
export function getFastPeriod(type: FastPeriodType, from: number, to: number): { f: number, t: number } {
  const result = { f: 0, t: 0 };

  const now = new Date();
  const dayOfWeek = now.getDay() ? now.getDay() : 7;
  switch (type) {
    case FastPeriodType.TODAY:
      result.f = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.YESTERDAY:
      result.f = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.WEEK:
      result.f = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.CUR_WEEK:
      result.f = new Date(now.getFullYear(), now.getMonth(), now.getDate() - dayOfWeek + 1, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.PREV_WEEK:
      result.f = new Date(now.getFullYear(), now.getMonth(), now.getDate() - dayOfWeek - 6, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate() - dayOfWeek, 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.MONTH:
      result.f = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.CUR_MONTH:
      result.f = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0).getTime();
      result.t = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.PREV_MONTH:
      result.f = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0, 0).getTime();
      const daysInPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      result.t = new Date(now.getFullYear(), now.getMonth() - 1, daysInPrevMonth, 23, 59, 59, 999).getTime();
      break;
    case FastPeriodType.OTHER:
      result.f = from;
      result.t = to;
      break;
  }

  return result;
}
