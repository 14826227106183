import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';

import { PipesModule } from '../../../pipes.module';
import { UIDistricsModule } from '../districts/ui-districts.module';

import { UIMDistricsComponent } from './ui-mdistrics.components';

@NgModule({
  imports: [
    BootstrapModalModule.forRoot({ container: document.body }),
    TranslateModule,
    PipesModule,
    CommonModule,
    FormsModule,
    UIDistricsModule
  ],
  declarations: [
    UIMDistricsComponent
  ],
  entryComponents: [
  ],
  exports: [
    UIMDistricsComponent
  ]
})
export class UIMDistricsModule { }
