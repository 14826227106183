import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISimpleObject } from '../../shared/ISimpleObject';
import { AccountRightType, RightType } from '../../shared/rights/RightType';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';
import { IReportTemplateInfo } from './report-templates.service';

/**
 * Сервис для работы с геозонами
 */
@Injectable()
export class AccountsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/accounts`;

  /**
   * Конструктор
   * @param configService Сервис конфигурации
   * @param http HTTP клиент
   */
  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) {  }

  /**
   * Получение списка геозон для учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getAccountGeozones(accountId: string): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/geozones/${accountId}`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получение признака блокировки тс
   * @param accountId Идентификатор учетной записи
   */
  public getAccountBlock(accountId: string): Observable<boolean> {
    const url = `${this.baseUrl}/isBlocked/${accountId}`;
    return this.http.get<boolean>(url);
  }

  /**
   * Получение списка групп геозон для учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getAccountGeozoneGroups(accountId: string): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/geozone-groups/${accountId}`;
    return this.http.get<IListItem<string>[]>(url);
  }

  /**
   * Получение списка шаблонов отчетов для учетной записи
   * @param accountId Идентификатор учетной записи
   */
  public getAccountReportTemplates(accountId: string): Observable<ISimpleObject[]> {
    const url = `${this.baseUrl}/report-templates/${accountId}`;
    return this.http.get<IReportTemplateInfo[]>(url);
  }

  /**
   * Получение списка учетных записей, на которые у текущего пользователя есть указанное право доступа
   * @param right Право доступа
   */
  public getWithRight(right: RightType | AccountRightType): Observable<IListItem<string>[]> {
    const url = `${this.baseUrl}/right/${right}`;
    return this.http.get<IListItem<string>[]>(url);
  }
}
