import { Component, Input } from '@angular/core';

import { IGeneratedWAReport } from '../../../../../shared/crm/docs';

/**
 * Компонент для отображения сгенерированного акта выполненных работ
 */
@Component({
  selector: 'generated-wareport',
  templateUrl: './generated-wareport.component.html'
})
export class GeneratedWAReportComponent {
  /** Данные по акту */
  @Input() public report: IGeneratedWAReport;

  /** Признак того, что в акте есть ед.измерения, кол-во и цена */
  public get withMCC() {
    return this.report && this.report.services.some(
      (s) => (s.measure || s.cost || s.count) ? true : false);
  }
}
