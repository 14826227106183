import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPayment } from '../../shared/crm/IPayment';
import { IAccountBalance } from '../../shared/crm/operations/IAccountBalance';
import { IOperationsForPeriod } from '../../shared/crm/operations/IOperationsForPeriod';
import { IOperationsRequest } from '../../shared/crm/operations/IOperationsRequest';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с операциями по учетной записи
 */
@Injectable()
export class OperationsService {

  /**
   * Базовая часть адреса
   */
  private readonly baseUrl = `${this.configService.url}/crm/operations`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Добавление платежа
   * @param body Данные платежа
   */
  public addPayment(body: IPayment): Observable<string> {
    const url = `${this.baseUrl}/payment`;
    return this.http.post<string>(url, body);
  }

  /**
   * Получение данных об операциях за период
   * @param body Данные запроса
   */
  public getForPeriod(body: IOperationsRequest): Observable<IOperationsForPeriod> {
    return this.http.post<IOperationsForPeriod>(this.baseUrl, body);
  }

  /**
   * Получение информации о состоянии счета и последнему платежу по учетной записи
   * @param accountId Идентификатор учетной записи
   * (если не указан, то получаем по текущей учетной записи)
   */
  public getBalance(accountId?: string): Observable<IAccountBalance> {
    const url = `${this.baseUrl}/balance`;
    const options = accountId ? {params: {accountId}} : {};
    return this.http.get<IAccountBalance>(url, options);
  }
}
