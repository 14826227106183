import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISimpleObject } from '../../shared/ISimpleObject';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с районами
 */
@Injectable()
export class DistrictsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/districts`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Получение списка районов
   */
  public getList(): Observable<ISimpleObject[]> {
    return this.http.get<ISimpleObject[]>(this.baseUrl);
  }

  /**
   * Сохранение списка районов
   * @param districts Список районов
   */
  public saveList(districts: ISimpleObject[]): Observable<string[]> {
    return this.http.post<string[]>(this.baseUrl, districts);
  }
}
