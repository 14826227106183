import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Table } from 'primeng/table';
import { filter, flatMap } from 'rxjs/operators';

import { ISIMCard } from '../../../../shared/crm/sim/ISIMCardGeneric';
import { getSIMCardStateName } from '../../../../shared/crm/sim/SIMCardState';
import { IClientSIMCard } from '../../../classes/IClientSIMCard';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { AccountComponent } from '../../account/account.component';
import { ModalResult } from '../../modal/modal.component';
import { EquipmentsEditComponent } from '../equipments/edit/equipments.edit.component';

import { SIMCardsEditComponent } from './edit/sim-cards.edit.component';

/**
 * Компонент для отображения списка SIM-карт
 */
@Component({
  selector: 'sim-cards',
  templateUrl: './sim-cards.component.html'
})
export class SIMCardsComponent {
  /**
   * Таблица
   */
  @ViewChild('table', { static: false }) table: Table;
  /**
   * datasource
   */
  public items: IClientSIMCard[];
  /**
   * Выбранный элемент
   */
  public selected: IClientSIMCard;
  /**
   * поисковая фраза
   */
  public search: string;
  /**
   * список полей для поиска
   */
  public searchFields: Array<IListItem<string>>;
  /**
   * выбранное поле для поиска
   */
  public selectedField: string;
  /**
   * Текст ошибки, если есть
   */
  public error: string;

  /**
   * Конструктор
   * @param crudService Сервис для работы с CRUD
   * @param loadingService Сервис для отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   * @param modalService Сервис для работы с модальными окнами
   */
  constructor(
    private crudService: CRUDService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    private translator: TranslateService,
    private modalService: ModalService
  ) {
    this.loadData();

    this.searchFields = [
      { id: 'iccid', name: 'component.crm.sim-cards.iccid' },
      { id: 'phone', name: 'ui.phone' },
      { id: 'operator.name', name: 'component.crm.sim-cards.operator' },
      { id: 'tariff.name', name: 'component.crm.sim-cards.tariff' },
      { id: 'tracker.name', name: 'component.crm.sim-cards.tracker' },
      { id: 'client.name', name: 'ui.client' }
    ];

    this.selectedField = this.searchFields[0]?.id
  }

  /**
   * Прокрутка таблицы вверх при пагинации
   */
  public onPage() {
    const [body] = this.table?.containerViewChild?.nativeElement?.getElementsByClassName('p-datatable-wrapper');
    if (body) body.scrollTop = 0;
  }

  /**
   * Добавление SIM-карты
   */
  public addItem() {
    this.dialogService.addDialog(SIMCardsEditComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Изменение выбранной SIM-карты
   */
  public changeSelected(id: string) {
    if (!id) {
      return;
    }

    const data = { simCardId: id };
    this.dialogService.addDialog(SIMCardsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Копирование выбранной SIM-карты
   */
  public copySelected(id: string) {
    if (!id) {
      return;
    }

    const data = { simCardId: id, copy: true };
    this.dialogService.addDialog(SIMCardsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /** Удаление выбранной записи */
  public deleteSelected(id: string) {
    if (!id) {
      return;
    }

    const confirm = this.translator.instant('component.crm.sim-cards.confirm');
    this.modalService.showQuestion(confirm)
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService
          .wrap(this.crudService.del(id, CRUDEntityType.SIM_CARD), true)))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о клиенте, у которого установлена SIM-карта
   * @param clientId Идентификатор клиента
   */
  public showClient(clientId: string) {
    const data = { accountId: clientId };
    this.dialogService.addDialog(AccountComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Отображение информации о терминале, на который установлена SIM-карта
   * @param trackerId Идентификатор терминала
   */
  public showTracker(trackerId: string) {
    const data = { equipmentId: trackerId, isTerminal: true };
    this.dialogService.addDialog(EquipmentsEditComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(this.loadData);
  }

  /**
   * Загрузка данных компонента
   */
  protected loadData = () => {
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.SIM_CARD))
      .subscribe(
        (simCards: ISIMCard[]) => {
          this.items = simCards?.map((simCard) => ({
            ...simCard,
            stateName: simCard.state ? getSIMCardStateName(simCard.state) : null
          }));
        },
        (error) => this.error = error);
  };
}
