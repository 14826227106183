/**
 * Тип маски датчика табилцы отчета
 */
export enum ReportTableMaskType {

  /** Счетчик */
  COUNTER = 1,

  /** Моточасы */
  MH,

  /** Произвольный датчик */
  ARBITRARY,

  /** Датчик вращения двигателя */
  ENGINE_SPEED,

  /** Датчик полезной работы двигателя */
  ENGINE_WORK,

  /** Датчик температуры */
  TEMPERATURE,

  /** Датчик по топливу */
  FUEL,

  /** Датчик веса */
  WEIGHT,

  /** Цифровой датчик */
  DIGITAL,

  /** Событие */
  EVENT,

  /** Ошибка с CAN-шины */
  CAN_FAULT
}
