import { Component, Input, OnInit } from '@angular/core';

import { UnitRightType } from '../../../../shared/rights/RightType';
import { IFuel } from '../../../../shared/units/IFuel';

/**
 * Компонент с настройками расхода топлива по объекту мониторинга
 */
@Component({
  selector: 'unit-fuel',
  templateUrl: './unit.fuel.component.html',
  styleUrls: []
})
export class UnitFuelComponent implements OnInit {

  /** Данные по расходу топлива */
  @Input() public fuel: IFuel;

  /** Права доступа на объект мониторинга */
  @Input() public rights: number;

  /** Признак режима добавления */
  @Input() public isAddRegime: boolean;

  /** Признак только для чтения */
  public isReadOnly: boolean;

  /**
   * Конструктор
   */
  constructor() {
    this.isReadOnly = false;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    // tslint:disable-next-line:no-bitwise
    if (!this.isAddRegime && !(this.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)) {
      this.isReadOnly = true;
    }
  }
}
