import { Component } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter, flatMap, tap } from 'rxjs/operators';

import { IDocItem } from '../../../../shared/crm/docs';
import { IDogovor } from '../../../../shared/crm/dogovors/IDogovor';
import { IAccountBalance } from '../../../../shared/crm/operations/IAccountBalance';
import { IOperation } from '../../../../shared/crm/operations/IOperation';
import { OperationType } from '../../../../shared/crm/operations/OperationType';
import { IPage } from '../../../../shared/IPage';
import { ICurrentUser } from '../../../../shared/users';
import { IListItem } from '../../../classes/IListItem';
import { ClientService } from '../../../services/client.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { StoreService } from '../../../services/store.service';
import { prepareDocForDisplay } from '../../../utils/docs';
import { localeSort } from '../../../utils/sort';
import { DocsPrintPreviewComponent, IPreviewDoc } from '../../crm/docs/print-preview/docs.print-preview.component';
import { ModalResult } from '../../modal/modal.component';
import { ClientAddInvoiceComponent } from '../add-invoice/client.add-invoice.component';
import { ClientBalanceNotificationSettingsComponent } from '../client-balance-notification-settings/client-balance-notification-settings.component';

/**
 * Компонент с информацией по денежным средствам клиента
 */
@Component({
  selector: 'client-finances',
  templateUrl: './client.finances.component.html',
  styleUrls: ['./client.finances.component.scss']
})
export class ClientFinancesComponent {

  /** Данные по операциям */
  public operations: IPage<IOperation>;

  /** Баланс на текущую дату */
  public balance: IAccountBalance = {} as IAccountBalance;

  /** Действующий договор */
  public dogovor: IDogovor;

  /**
   * Идентификатор учетной записи,
   * информация по которой отображается в компоненте
   */
  public accountId: string;

  /**
   * Список учетных записей, по которым текущий
   * пользователь может видеть информацию
   */
  public accounts: IListItem<string>[] = [];

  /** Данные по счетам */
  public invoices: IPage<IDocItem>;

  /** Данные текущего пользователя */
  public user: ICurrentUser;

  /** Признак блокировки учетной записи текущего пользователя */
  public get isBlocked() {
    return this.user && this.user.account.isBlocked;
  }

  /** Максимальное кол-во записей на одной странице */
  private readonly pageLimit = 10;

  /**
   * Конструктор
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param clientService Сервис для работы с текущим клиентом
   * @param store Сервис для хранения данных
   */
  constructor(
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private clientService: ClientService,
    store: StoreService
  ) {
    this.user = store.user;

    this.loadingService.withLoading(
      this.clientService.getAccounts(),
      this.onAccountsLoaded);
  }

  /**
   * Получение стиля для суммы операции
   * (красный или зеленый цвет в зависимости от списания/поступления)
   * @param operation Операция
   */
  public getSumColor(operation: IOperation) {
    return operation.type === OperationType.DEBIT
      ? 'p-danger'
      : operation.sum >= 0
        ? 'p-success'
        : 'p-warning';
  }

  /**
   * Обработки при изменении выбранной учетной записи
   */
  public onAccountChange() {
    this.loadingService.withLoading(
      this.clientService.getBalance(this.accountId),
      (balance) => {
        if (balance.balance) {
          balance.balance /= 100;
        }

        if (balance.balanceLimit) {
          balance.balanceLimit /= 100;
        }

        this.balance = balance;
      }
    );

    this.loadingService.withLoading(
      this.clientService.getDogovor(this.accountId),
      (dogovor) => this.dogovor = dogovor
    );

    this.loadOperations(this.accountId, this.pageLimit, 0);
    this.loadInvoices(this.accountId, this.pageLimit, 0);
  }

  /**
   * Регистрация обещанного платежа
   */
  public promisedPayment() {
    this.modalService
      .showQuestion('component.client.finances.confirm')
      .pipe(
        filter((result) => result === ModalResult.YES),
        flatMap(() => this.loadingService.wrap(this.clientService.promisedPayment(this.accountId), true)))
      .subscribe(() => this.modalService.showInfo('component.client.finances.message'));
  }

  /**
   * Клик по кнопке настройки уведомлений
   */
  public balanceNotificationSettings() {
    this.dialogService
      .addDialog(ClientBalanceNotificationSettingsComponent, {})
      .pipe(filter((result) => !!result))
      .subscribe(() => this.onAccountChange());
  }

  /**
   * Переход на предыдущую страницу
   * @param curPage Текущая страница
   * @param load Метод загрузки страницы
   */
  public prevPage<T>(curPage: IPage<T>, load: LoadPageFunc) {
    if (!curPage || this.isFirstPage(curPage)) {
      return;
    }

    let offset = curPage.offset - this.pageLimit;
    if (offset < 0) {
      offset = 0;
    }

    load(this.accountId, this.pageLimit, offset);
  }

  /**
   * Переход на следующую страницу
   * @param curPage Текущая страница
   * @param load Метод загрузки страницы
   */
  public nextPage<T>(curPage: IPage<T>, load: LoadPageFunc) {
    if (!curPage || this.isLastPage(curPage)) {
      return;
    }

    const offset = curPage.offset + this.pageLimit;
    load(this.accountId, this.pageLimit, offset);
  }

  /**
   * Проверяет страницу на то, что она является первой
   * @param page Проверяемая страница
   */
  public isFirstPage<T>(page: IPage<T>): boolean {
    return !page.offset;
  }

  /**
   * Проверяет страницу на то, что она является последней
   * @param page Проверяемая страница
   */
  public isLastPage<T>(page: IPage<T>): boolean {
    return page.offset + this.pageLimit >= page.count;
  }

  /**
   * Получение счета для просмотра и печати
   * @param invoice Данные по счету
   */
  public getInvoice(invoice: IDocItem) {
    this.loadingService
      .wrap(this.clientService.getInvoice(invoice.id, this.accountId), true)
      .pipe(
        tap(prepareDocForDisplay),
        flatMap((doc) => {
          const data = { doc, type: 'invoice' } as IPreviewDoc;
          return this.dialogService.addDialog(DocsPrintPreviewComponent, data)
        }))
      .subscribe();
  }

  /**
   * Добавление нового счета
   */
  public addInvoice() {
    const data = { accountId: this.accountId };
    this.dialogService.addDialog(ClientAddInvoiceComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.loadInvoices(this.accountId, this.pageLimit, 0));
  }

  /**
   * Загрузка списка существующих счетов по учетной записи
   * @param accountId Идентификатор учетной записи
   * @param limit Максимальное кол-во получаемых записей
   * @param offset Смещение от начала списка
   */
  public loadInvoices = (accountId: string, limit: number, offset: number) => {
    this.loadingService.withLoading(
      this.clientService.getInvoices(limit, offset, accountId),
      (page) => {
        page.rows.forEach((row) => row.sum /= 100);

        this.invoices = page;
      }
    );
  }

  /**
   * Загрузка списка операций по учетной записи
   * @param accountId Идентификатор учетной записи
   * @param limit Максимальное кол-во получаемых записей
   * @param offset Смещение от начала списка
   */
  public loadOperations = (accountId: string, limit: number, offset: number) => {
    this.loadingService.withLoading(
      this.clientService.getOperations(limit, offset, accountId),
      (page) => {
        page.rows.forEach((row) => {
          row.sum /= 100;

          if (row.cost) {
            row.cost /= 100;
          }
        });

        this.operations = page;
      }
    );
  }

  /**
   * Обработка загрузки списка учетных записей
   * @param accounts Полученный список учетный записей
   */
  private onAccountsLoaded = (accounts: IListItem<string>[]) => {
    this.accounts = accounts?.sort(localeSort);

    if (this.user && this.accounts.some((a) => a.id === this.user.account.id)) {
      this.accountId = this.user.account.id;
    } else if (this.accounts.length) {
      this.accountId = this.accounts[0].id;
    }

    this.onAccountChange();
  }
}

/**
 * Метод загрузки страницы с записями
 */
type LoadPageFunc = (accountId: string, limit: number, offset: number) => void;
