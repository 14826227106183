/**
 * Тип источника карты
 */
export enum MapSourceType {

  /** Open Street Maps */
  OSM,

  /** Google Схема */
  GOOGLE_SCHEMA,

  /** Google Спутник */
  GOOGLE_SATELLITE,

  /** Google Гибрид */
  GOOGLE_HYBRID,

  /** Яндекс Схема */
  YANDEX_SCHEMA,

  /** Яндекс Спутник */
  YANDEX_SATELLITE,

  /** Яндекс Гибрид */
  YANDEX_HYBRID

}
