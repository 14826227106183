/**
 * Тип фона графика
 */
export enum ReportChartBgType {
  /** Моточасы */
  MH = 1,
  /** Остановки */
  STOP,
  /** Стоянки */
  PARKING,
  /** Поездки */
  MOVE
}

/**
 * Получение списка всех типов фона графика
 */
export function getAllReportChartBgTypes(): ReportChartBgType[] {
  const result: ReportChartBgType[] = [];
  for (let i = 1; i <= 4; i++) {
    result.push(i as ReportChartBgType);
  }
  return result;
}

/**
 * Получение наименования типа фона графика
 * @param type Тип фона графика
 */
export function getReportChartBgTypeName(type: ReportChartBgType): string {
  switch (type) {
    case ReportChartBgType.MH: return 'enums.reports.charts.bg-type.motor-hours';
    case ReportChartBgType.STOP: return 'enums.reports.charts.bg-type.stop';
    case ReportChartBgType.PARKING: return 'enums.reports.charts.bg-type.park';
    case ReportChartBgType.MOVE: return 'enums.reports.charts.bg-type.travel';
    default: return 'enums.reports.charts.bg-type.unknown';
  }
}
