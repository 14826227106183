import { Component } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { HistoryEntityType } from '../../../../../shared/crm/hist';
import { IRefItem, RefType } from '../../../../../shared/crm/IRefItem';
import {
  getAllLocationTypes,
  getLocationTypeName,
  LocationType
} from '../../../../../shared/crm/LocationType';
import { ISIMCard } from '../../../../../shared/crm/sim/ISIMCardGeneric';
import {
  getAllSIMCardStates,
  getSIMCardStateName,
  SIMCardState
} from '../../../../../shared/crm/sim/SIMCardState';
import { DetailComponent } from '../../../../classes/DetailComponent';
import { DetailTab } from '../../../../classes/DetailTab';
import { IClientHistory } from '../../../../classes/IClientHistory';
import { IListItem } from '../../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../../services/crud.service';
import { LoadingService } from '../../../../services/loading.service';
import { ModalService } from '../../../../services/modal.service';
import { AccountComponent } from '../../../account/account.component';
import { SelectionType, SelectItemsComponent } from '../../../select-items/select-items.component';
import { EquipmentsEditComponent } from '../../equipments/edit/equipments.edit.component';
import { RefsItemsModalComponent } from '../../refs/items-modal/refs.items-modal.component';

/**
 * Интерфейс компонента для редактирования SIM-карт
 */
interface ISIMCardsEditComponent {
  /** Идентификатор SIM-карты */
  simCardId?: string;
  /** Признак копирования */
  copy?: boolean;
}

/**
 * Компонент для редактирования SIM-карты
 */
@Component({
  selector: 'sim-cards-edit',
  templateUrl: './sim-cards.edit.component.html'
})
export class SIMCardsEditComponent
  extends DetailComponent<ISIMCardsEditComponent>
  implements ISIMCardsEditComponent {

  /** Идентификатор SIM-карты */
  public simCardId?: string;

  /** Признак копирования */
  public copy?: boolean;

  /** Данные SIM-карты */
  public simCard: ISIMCard;

  /** Список типов местоположения */
  public locationTypes: IListItem<LocationType>[] = [];

  /** Список состояний */
  public states: IListItem<SIMCardState>[] = [];

  /** История по SIM-карте */
  public history: IClientHistory[];

  /** Список терминалов для выбора привязки */
  public trackers: IListItem<string>[];

  /** Список учетных записей для выбора привязки */
  public accounts: IListItem<string>[];

  /** Тип истории */
  public hType = HistoryEntityType;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param modalService Сервис модальных окон
   * @param crudService Сервис работы с CRUD
   */
  constructor(
    dialogService: DialogService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private crudService: CRUDService
  ) {
    super(dialogService);

    this.tabs[Tabs.Main] = new DetailTab('component.crm.sim-cards.edit.main');
    this.tabs[Tabs.History] = new DetailTab('component.crm.sim-cards.edit.history');
    this.selectedTab = this.tabs[Tabs.Main];

    this.locationTypes = getAllLocationTypes()
      .map((id) => ({ id, name: getLocationTypeName(id) }));

    this.states = getAllSIMCardStates()
      .map((id) => ({ id, name: getSIMCardStateName(id) }));

    this.simCard = {} as ISIMCard;
    this.isAddRegime = true;
  }

  /**
   * Получение признака возможности сохранения записи
   */
  public get isCanSave() {
    return !!(this.simCard
      && this.simCard.iccid
      && this.simCard.iccid !== ''
      && this.simCard.operator
      && this.simCard.state);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: ISIMCardsEditComponent) {
    if (data.simCardId) {
      this.loadingService.wrap(this.crudService.get(data.simCardId, CRUDEntityType.SIM_CARD), true)
        .subscribe((simCard) => {
          this.simCard = simCard;
          if (data.copy) {
            delete this.simCard.id;
          }
        });
    }

    const action = data.simCardId ? data.copy ? 'copy' : 'edit' : 'add';
    this.title = `component.crm.sim-cards.edit.${action}-sim`;
    this.isAddRegime = action !== 'edit';

    this.tabs[Tabs.History].hidden = this.isAddRegime;

    return super.fillData(data);
  }

  /**
   * Клик по кнопке выбора оператора
   */
  public selectOperator() {
    this.selectFromRef(RefType.MOBILE_OPERATOR, this.simCard.operator as IRefItem,
      (operator) => this.simCard.operator = operator);
  }

  /**
   * Клик по кнопке выбора тарифа
   */
  public selectTariff() {
    this.selectFromRef(RefType.SIM_TARIFF, this.simCard.tariff as IRefItem,
      (tariff) => this.simCard.tariff = tariff);
  }

  /**
   * Клик по кнопке выбора трекера
   */
  public selectTracker() {
    // Выбираем из загруженного списка
    if (this.trackers) {
      this.selectFromTrackers();
      return;
    }

    // Загружаем список трекеров
    const query = { terminals: `${true}` };

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.EQUIPMENT, query), true)
      .subscribe((trackers) => {
        this.trackers = trackers;
        this.selectFromTrackers();
      });
  }

  /**
   * Клик по кнопке выбора учетной записи (клиента)
   */
  public selectClient() {
    // Выбираем из загруженного списка
    if (this.accounts) {
      this.selectFromAccounts();
      return;
    }

    // Загружаем список клиентов
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.ACCOUNT), true)
      .subscribe((accounts) => {
        this.accounts = accounts;
        this.selectFromAccounts();
      });
  }

  /**
   * Клик по кнопке отображения детальной информации по трекеру
   * @param tracker Трекер
   */
  public showTrackerDetail(tracker: IListItem<any>) {
    if (!tracker) {
      return;
    }

    const data = {
      equipmentId: tracker.id,
      isTerminal: true
    };

    this.dialogService.addDialog(EquipmentsEditComponent, data).subscribe();
  }

  /**
   * Клик по кнопке отображения детальной информации
   * по клиенту (учетной записи)
   * @param client Учетная запись (клиент)
   */
  public showClientDetail(client: IListItem<any>) {
    if (!client) {
      return;
    }

    const data = { accountId: client.id };
    this.dialogService.addDialog(AccountComponent, data).subscribe();
  }

  /** Не используется */
  public isCan(): boolean {
    return true;
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.loadingService.wrap(this.crudService.addUpdate(this.simCard, CRUDEntityType.SIM_CARD), true)
      .subscribe((id) => {
        this.result = id;
        this.close();
      });
  }

  /**
   * Выбор элемента из справочника
   * @param type Тип справочника
   * @param selected Выбранная в данный момент запись справочника
   * @param callback Функция, которая должна выполниться после выбора
   */
  private selectFromRef(type: RefType, selected: IRefItem, callback: (item: IRefItem) => void) {
    const data = { type, selected };
    this.dialogService.addDialog(RefsItemsModalComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => callback(result));
  }

  /**
   * Выбор из списка учетных записей (клиентов)
   */
  private selectFromAccounts() {
    const data = {
      items: this.accounts,
      selected: this.simCard.client ? [this.simCard.client] : null,
      title: 'component.crm.sim-cards.edit.select-account',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.simCard.client = result?.shift());
  }

  /**
   * Выбор из списка трекеров
   */
  private selectFromTrackers() {
    const data = {
      items: this.trackers,
      selected: this.simCard.tracker ? [this.simCard.tracker] : null,
      title: 'component.crm.sim-cards.edit.select-tracker',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.simCard.tracker = result?.shift());
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  History
}
