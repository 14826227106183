import { Pipe, PipeTransform } from '@angular/core';

/**
 * Фильтр по наименованию
 */
@Pipe({
  name: 'namefilter',
  pure: false
})
export class NameFilterPipe implements PipeTransform {
  public transform(items: {name: string}[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => item.name.toLowerCase().includes(lowerFilter)
    );
  }
}
