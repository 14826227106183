import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { round } from '@turf/helpers';
import { Feature, Feature as RenderFeature, Map, Overlay, View } from 'ol';
import * as Geocoder from 'ol-geocoder';
import { defaults } from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import { format as coordinateFormat } from 'ol/coordinate';
import { getCenter, isEmpty } from 'ol/extent';
import { Circle, Geometry, LineString, Point, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { circular } from 'ol/geom/Polygon';
import { Draw, Modify, Snap } from 'ol/interaction';
import { Tile, Vector } from 'ol/layer';
import VectorImageLayer from 'ol/layer/VectorImage';
import { unByKey } from 'ol/Observable';
import OverlayPositioning from 'ol/OverlayPositioning';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { getArea, getDistance, getLength } from 'ol/sphere';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Subscriber, Subscription } from 'rxjs';

import { GeozoneType } from '../../../shared/geozones/GeozoneType';
import { IGeozoneMap } from '../../../shared/geozones/IGeozoneMap';
import { ICoord } from '../../../shared/ICoord';
import { IHostConfig } from '../../../shared/IHostConfig';
import { IMessage } from '../../../shared/messages/IMessage';
import { GeocoderProvider } from '../../classes/GeocoderProvider';
import { IAddressChosenEvent } from '../../classes/IAddressChosenEvent';
import { IClientDriver } from '../../classes/IClientDriver';
import { IClientGeozone } from '../../classes/IClientGeozone';
import { IClientTrailer } from '../../classes/IClientTrailer';
import { IMapMessagePointInfo } from '../../classes/IMapMessagePointInfo';
import { IMapRoute } from '../../classes/IMapRoute';
import { IUnitMarkerInfo } from '../../classes/IUnitMarkerInfo';
import { MapButton } from '../../classes/MapButton';
import { MapSourceType } from '../../classes/MapSourceType';
import { TrackingUnit } from '../../classes/TrackingUnit';
import { MapDetailHostDirective } from '../../directives/map-detail-host.directive';
import { BgPopoverService } from '../../modules/bg-popover';
import { IPopoverPosition } from '../../modules/bg-popover/IPopoverPosition';
import { MapTypes } from '../../services/auth-guard.service';
import { GeozonesService } from '../../services/geozones.service';
import { HostConfigService } from '../../services/host-config.service';
import { MapService } from '../../services/map.service';
import { ModalService } from '../../services/modal.service';
import { MonitoringService } from '../../services/monitoring.service';
import { IClientRace, IRaceEventInfo, IRacePoint, RaceEventType } from '../../services/race.service';
import { IPointTable, IReportInterval, IReportRace, ReportsService } from '../../services/reports.service';
import { StoreService } from '../../services/store.service';
import { getScreenCordinates } from '../../utils/coords';
import { hexToRgb } from '../../utils/hex-to-rgb';
import { DriversDetailComponent } from '../drivers/detail/drivers.detail.component';
import { GeozonesDetailComponent } from '../geozones/detail/geozones.detail.component';
import { TrailersDetailComponent } from '../trailers/detail/trailers.detail.component';

import { AddressDetailComponent } from './address-detail/map.address-detail.component';
import { MapDetailLightComponent } from './light/map.light.component';
import { MapRaceDetailComponent } from './race-detail/map.race-detail.component';
import { IMapReportDetails, MapReportDetailComponent } from './report-detail/map.report-detail.component';
import { MapSettingsComponent } from './settings/map.settings.component';

/**
 * Компонент карты
 */
@Component({
  selector: 'bars-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  /** Хост элемент для детальной информации */
  @ViewChild(MapDetailHostDirective, { static: false })
  public mapDetailHost: MapDetailHostDirective;

  /** Подписка на обновление данных по объектам мониторинга */
  private showUnitsSubscription: Subscription;

  /** Подписка на удаление объектов из списка для слежения */
  private hideUnitsSubscription: Subscription;

  /** Подписка на отображение рейса */
  private showRaceSubscription: Subscription;

  /** Подписка на скрытие рейса */
  private hideRaceSubscription: Subscription;

  /** Пописка на перемещение центра карты */
  private moveToPointSubscription: Subscription;

  /** Подписка на анимацию рейса */
  private animateRaceSubscription: Subscription;

  /** Подписка на релоад карты с сервера */
  private loadOrReloadGeozonesSubscription: Subscription;

  /** Подписка на отображение списка геозон */
  private showGeozonesSubscription: Subscription;

  /** Подписка на скрытие списка геозон */
  private hideGeozonesSubscription: Subscription;

  /** Подписка на очистку слоя геозон */
  private clearGeozonesSubscription: Subscription;

  /** Подписка на центрирование на геозоне */
  private fitGeozoneSubscription: Subscription;

  /** Подписка на начало рисования геозоны */
  private startDrawGeozoneSubscription: Subscription;

  /** Подписка на отмену рисования геозоны */
  private cancelDrawGeozoneSubscription: Subscription;

  /** Подписка на скрытие водителя */
  private hideDriverSubscription: Subscription;

  /** Подписка на отображение водителя */
  private showDriverSubscription: Subscription;

  /** Подписка на скрытие прицепа */
  private hideTrailerSubscription: Subscription;

  /** Подписка на отображение прицепа */
  private showTrailerSubscription: Subscription;

  /** Подписка на отображение рейса по отчёту на карте */
  private reportRaceSubscription: Subscription;

  /** Подписка на отображение интервала по отчёту на карте */
  private reportShowIntervalSubscription: Subscription;

  /** Подписка на скрытие интервала по отчёту на карте */
  private reportHideIntervalSubscription: Subscription;

  /** Подписка на отображение точки по отчёту на карте */
  private reportPointSubscription: Subscription;

  /** Подписка на очистку информации по отчету */
  private reportClearSubscription: Subscription;

  /** Подписка на скрытие точки сообщения */
  private hideMessagePointSubscription: Subscription;

  /** Подписка на отображение точки сообщения */
  private showMessagePointSubscription: Subscription;

  /** Подписка на отображение маршрута */
  private showRouteSubscription: Subscription;

  /** Подписка на скрытие маршрута */
  private hideRouteSubscription: Subscription;

  /** Подписка на скрытия детальной информации по адресу */
  private removeAddressDetailSubscription: Subscription;

  /** Подписка на нажатие на ТС */
  private clickOnUnitSubscription: Subscription;

  /** Подписка на обновление видимости слоёв */
  private layersVisibilitySubscription: Subscription;

  /** Подписка на обновление размера карты */
  private resizeMapSubscription: Subscription;

  /** Подписка на печать карты */
  private printMapSubscription: Subscription;

  /** Подписка на обновление слоя карты (костыль для печати яндекс карт) */
  private refreshMapSubscription: Subscription;

  /** Слой OSM */
  private osmLayer: Tile;

  /** Вьюшка карты */
  private view: View;

  /** Карта */
  private map: Map;

  /** Карта Гугла */
  private gmap: google.maps.Map;

  /** Карта Яндекса */
  private ymap: ymaps.Map;

  /** Источник для слоя с рейсами */
  private racesSource: VectorSource;

  /** Слой с рейсами */
  private racesLayer: Vector;

  /** Источник для слоя с маркерами ТС рейса */
  private racesMarkersSource: VectorSource;

  /** Слой с маркерами ТС рейса */
  private racesMarkersLayer: Vector;

  /** Источник для слоя с маркерами активных ТС рейса */
  private racesEyeMarkersSource: VectorSource;

  /** Слой с маркерами аткивных ТС рейса */
  private racesEyeMarkersLayer: Vector;

  /** Источник для слоя с маркерами неактивных объектов */
  private markersSource: VectorSource;

  /** Слой с маркерами неактивных объектов */
  private markersLayer: VectorImageLayer;

  /** Источник для слоя с маркерами неактивных объектов */
  private eyeMarkersSource: VectorSource;

  /** Слой с маркерами активных объектов */
  private eyeMarkersLayer: VectorImageLayer;

  /** Источник для слоя с геозонами */
  private geozonesSource: VectorSource;

  /** Слой с геозонами */
  private geozonesLayer: VectorImageLayer;

  /** Источник для слоя с водителями */
  private driversSource: VectorSource;

  /** Слой с водителями */
  private driversLayer: Vector;

  /** Источник для слоя с прицепами */
  private trailersSource: VectorSource;

  /** Слой с прицепами */
  private trailersLayer: Vector;

  /** Источник слоя для рисования геозон */
  private drawGeozonesSource: VectorSource;

  /** Слой для рисования геозон */
  private drawGeozonesLayer: Vector;

  /** Источник слоя для отчетов */
  private reportSource: VectorSource;

  /** Слой для отчетов */
  private reportLayer: Vector;

  /** Источник слоя для сообщений */
  private messagesSource: VectorSource;

  /** Слой для сообщений */
  private messagesLayer: Vector;

  /** Источник для слоя с маршрутами */
  private routesSource: VectorSource;

  /** Слой с маршрутами */
  private routesLayer: Vector;

  /** Список маркеров объектов */
  private markers: { unitId: string, feature: Feature, source: VectorSource }[] = [];

  /** Детальная информация */
  private detail: { unit: TrackingUnit, overlay: Overlay, timer?: any };

  /** Детальная информация по рейсу */
  private raceDetail: { race: IClientRace, point: IRacePoint, overlay: Overlay };

  /** Детальная информация по геозоне */
  private geozoneDetail: { geozone: IClientGeozone, overlay: Overlay };

  /** Детальная информация по водителю */
  private driverDetail: { driver: IClientDriver, overlay: Overlay };

  /** Детальная информация по прицепу */
  private trailerDetail: { trailer: IClientTrailer, overlay: Overlay };

  /** Детальная информация по адресу */
  private addressDetail: { addressChosenEvent: IAddressChosenEvent, overlay: Overlay };

  /** Список рейсов */
  private races: { race: IClientRace, features: Feature[] }[] = [];

  /** Список маркеров объектов рейсов */
  private raceMarkers: IRaceMarker[] = [];

  /** Список геозон на карте */
  private mapGeozones: { geozone: IGeozoneMap, feature: Feature }[] = [];

  /** Рисуемая геозона */
  private geozoneDraw: IGeozoneDraw;

  /** Список водителей на карте */
  private mapDrivers: { driver: IClientDriver, feature: Feature }[] = [];

  /** Список прицепов на карте */
  private mapTrailers: { trailer: IClientTrailer, feature: Feature }[] = [];

  /** Детальная информация по отчету */
  private reportDetailOverlay: Overlay;

  /** Список рейсов по отчету */
  private reportRaceFeatures: Feature[] = [];

  /** Список интервалов по таблицам отчета */
  private reportIntervals: IMapReportInterval[] = [];

  /** Информация по отображаемой точке из данных отчета */
  private reportPointFeature: Feature;

  /** Информация по отображаемой точке из данных сообщения */
  private messagePointFeature: Feature;

  /** Список маршрутов */
  private routes: { id: string, pointIds: string[] }[] = [];

  /** Список геозон, используемых в маршрутах */
  private routeGeozones: IMapObject<IClientGeozone>[] = [];

  /** Ребра, соединяющие точки в маршрутах */
  private routeEdges: IMapObject<{ fromId: string, toId: string }>[] = [];

  /** Источник для слоя c линейкой */
  public measureSource: VectorSource;

  /** Слой с линейкой */
  private measureLayer: Vector;

  /** Рисуемая линейка */
  private measureDraw: Draw;

  /** Тултип для линейки */
  private measureTooltip: Overlay;

  /** Тултип для линейки */
  private measureTooltipElement: HTMLElement;

  /** Признак что линейкой еще пользуются */
  private measureSketch: Feature;

  /** Тип линейки (линия полигон) */
  public measureType: MeasureType = MeasureType.LINE;

  /** Возможные типы линеек */
  public measureTypes = MeasureType;

  /** Признак видимости линейки */
  public isMeasureEnable: boolean = false;

  /**
   * Источник карт (костыль для печати яндекс карт)
   */
  private mapSource: MapSourceType;

  /** Время автоматического обновления данных в миллисекундах */
  private readonly updateTimeInMilliseconds = 900000;

  /** Строковые литералы */
  private strings: Dict<string> = {
    'component.map.parking': '',
    'component.map.stop': '',
    'component.map.speeding': '',
    'component.map.filling': '',
    'component.map.theft': '',
    'component.map.event': '',
    'component.map.search-placeholder': '',
    'component.map.message-point': ''
  };

  /**
   * Конструктор
   * @param monitoringService Сервис мониторинга
   * @param componentFactoryResolver Хз что это
   * @param popoverService Сервис всплывающих окон
   * @param modalService Сервис модальных окон
   * @param store Сервис для хранения данных мониторинга
   * @param router Маршрутизатор
   * @param translator Сервис для перевода
   * @param reportsService Сервис для работы с отчетами
   * @param mapService Сервис для работы с картой
   * @param geozonesService
   * @param hostConfigService
   */
  constructor(
    private monitoringService: MonitoringService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private popoverService: BgPopoverService,
    private modalService: ModalService,
    private store: StoreService,
    private router: Router,
    private translator: TranslateService,
    private reportsService: ReportsService,
    private mapService: MapService,
    private geozonesService: GeozonesService,
    private hostConfigService: HostConfigService
  ) {
    this.showUnitsSubscription = monitoringService.showUnitsSubject.subscribe(this.onShowUnits);
    this.hideUnitsSubscription = monitoringService.hideUnitsSubject.subscribe(this.onHideUnits);

    this.showRaceSubscription = monitoringService.showRaceSubject.subscribe(this.onShowRace);
    this.hideRaceSubscription = monitoringService.hideRaceSubject.subscribe(this.onHideRace);

    this.moveToPointSubscription = monitoringService.moveToPointSubject.subscribe(this.moveToPoint);

    this.animateRaceSubscription = monitoringService.animateRaceSubject.subscribe(this.onAnimateRace);

    // релоад геозон
    this.loadOrReloadGeozonesSubscription = mapService.loadOrReloadGeozonesSubject.subscribe(this.loadOrReloadGeozones);
    this.showGeozonesSubscription = mapService.showGeozonesSubject.subscribe(this.onShowGeozonesToMap);
    this.hideGeozonesSubscription = mapService.hideGeozonesSubject.subscribe(this.onHideGeozonesToMap);
    this.clearGeozonesSubscription = mapService.clearGeozonesSubject.subscribe(this.onClearGeozonesToMap);

    this.fitGeozoneSubscription = monitoringService.fitGeozoneSubject.subscribe(this.onFitGeozone);
    this.startDrawGeozoneSubscription = monitoringService.startDrawGeozoneSubject.subscribe(this.onStartDrawGeozone);
    this.cancelDrawGeozoneSubscription = monitoringService.cancelDrawGeozoneSubject.subscribe(this.onCancelDrawGeozone);

    this.showDriverSubscription = monitoringService.showDriversSubject.subscribe(this.onShowDrivers);
    this.hideDriverSubscription = monitoringService.hideDriversSubject.subscribe(this.onHideDrivers);

    this.showTrailerSubscription = monitoringService.showTrailersSubject.subscribe(this.onShowTrailers);
    this.hideTrailerSubscription = monitoringService.hideTrailersSubject.subscribe(this.onHideTrailers);

    this.reportRaceSubscription = reportsService.showRaceOnMapSubject.subscribe(this.onShowReportRaces);
    this.reportShowIntervalSubscription =
      reportsService.showIntervalsOnMapSubject.subscribe(this.onShowReportIntervals);
    this.reportHideIntervalSubscription =
      reportsService.hideIntervalsFromMapSubject.subscribe(this.onHideReportIntervals);
    this.reportPointSubscription = reportsService.showPointOnMapSubject.subscribe(this.onShowReportPoint);
    this.reportClearSubscription = reportsService.clearReportInfoSubject.subscribe(this.onClearReportInfo);

    this.showMessagePointSubscription = monitoringService.showMessagePointSubject.subscribe(this.onShowMessagePoint);
    this.hideMessagePointSubscription = monitoringService.hideMessagePointSubject.subscribe(this.onHideMessagePoint);

    this.showRouteSubscription = monitoringService.showRouteSubject.subscribe(this.onShowRoute);
    this.hideRouteSubscription = monitoringService.hideRouteSubject.subscribe(this.onHideRoute);

    this.removeAddressDetailSubscription =
      monitoringService.removeAddressDetailSubject.subscribe(this.onRemoveAddressDetail);

    this.clickOnUnitSubscription = this.monitoringService.clickOnUnitSubject.subscribe(this.focusOnUnit);

    this.layersVisibilitySubscription = this.mapService.layersVisibilitySubject.subscribe(this.setLayersVisibility);
    this.resizeMapSubscription = this.mapService.resizeMapSubject.subscribe(this.resizeMap);

    this.printMapSubscription = this.mapService.printMapSubject.subscribe(this.onPrint);
    this.refreshMapSubscription = this.mapService.refreshMapSubject.subscribe(this.refreshMap);
  }

  /**
   * Апендим скрипты карт
   */
  public initMapsScripts(config: IHostConfig, type: MapTypes): IHostConfig {
    const script = document.createElement('script');
    switch (type) {
      case MapTypes.GOOGLE:
        if (!!document.getElementById('googleMap')) return;

        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.store.user?.mapKeys?.googleKey ? this.store.user?.mapKeys?.googleKey : config?.gMapApiKey}`;
        script.onload = () => this.mapService.initGoogleMap$.next(true);
        script.id = 'googleMap'
        break;
      case MapTypes.YANDEX:
        if (!!document.getElementById('yandexMap')) return;

        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${this.store.user?.mapKeys?.yandexKey ? this.store.user?.mapKeys?.yandexKey : config?.yaMapApiKey}&lang=ru_RU`;
        script.onload = () => this.mapService.initYandexMap$.next(true);
        script.id = 'yandexMap'
        break;
    }
    script.async = true;
    script.onerror = (e) => console.error(`Error load map: ${e}`);
    document.head.appendChild(script);
    return config
  }

  /**
   * Обновление видимости слоев карты
   */
  public setLayersVisibility = () => {
    if (!this.map) {
      // Если настройки видимости загрузились раньше,
      // чем инициализировалась карта
      return;
    }

    const lv = this.mapService.layersVisibility;
    if (!lv) {
      // Если настройки видимости по какой-то
      // странной причине еще не загрузились
      return;
    }

    this.geozonesLayer.setVisible(lv.geozones || false);
    this.driversLayer.setVisible(lv.drivers || false);
    this.trailersLayer.setVisible(lv.trailers || false);
    this.racesLayer.setVisible(lv.race || false);
    this.markersLayer.setVisible(lv.tracking || false);
    this.eyeMarkersLayer.setVisible(lv.tracking || false);
    this.racesMarkersLayer.setVisible(lv.race || false);
    this.racesEyeMarkersLayer.setVisible(lv.race || false);
    this.routesLayer.setVisible(lv.routes || false);
    this.reportLayer.setVisible(lv.reports || false);
    this.messagesLayer.setVisible(lv.messages || false);
  }

  /**
   * Обновление размера карты
   */
  public resizeMap = () => {
    if (this.map) {
      this.map.updateSize();
    }

    if (this.gmap) {
      const center = this.gmap.getCenter();
      google.maps.event.trigger(this.gmap, 'resize');
      this.gmap.setCenter(center);
    }

    if (this.ymap) {
      this.ymap.container.fitToViewport();
    }
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.initMapsScripts(this.store.hostConfig, MapTypes.GOOGLE)
    this.initMapsScripts(this.store.hostConfig, MapTypes.YANDEX)
    this.initMap();

    this.translator.get([
      'component.map.parking',
      'component.map.stop',
      'component.map.speeding',
      'component.map.filling',
      'component.map.theft',
      'component.map.event',
      'component.map.search-placeholder',
      'component.map.message-point',
      'component.map.layer-settings',
      'component.map.layer-measure',
      'component.map.layer-measure-square'
    ]).subscribe((x) => {
      this.strings = x;
      this.addGeocoderButton();
      this.addLayerSelectionButton();
      this.addMeasureButton();
    });
  }

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    if (this.ymap) {
      this.ymap.destroy();
      this.ymap = null;
    }

    this.showUnitsSubscription.unsubscribe();
    this.hideUnitsSubscription.unsubscribe();
    this.showRaceSubscription.unsubscribe();
    this.hideRaceSubscription.unsubscribe();
    this.moveToPointSubscription.unsubscribe();
    this.animateRaceSubscription.unsubscribe();
    this.loadOrReloadGeozonesSubscription.unsubscribe();
    this.showGeozonesSubscription.unsubscribe();
    this.hideGeozonesSubscription.unsubscribe();
    this.clearGeozonesSubscription.unsubscribe();
    this.fitGeozoneSubscription.unsubscribe();
    this.startDrawGeozoneSubscription.unsubscribe();
    this.cancelDrawGeozoneSubscription.unsubscribe();
    this.hideDriverSubscription.unsubscribe();
    this.showDriverSubscription.unsubscribe();
    this.hideTrailerSubscription.unsubscribe();
    this.showTrailerSubscription.unsubscribe();
    this.reportRaceSubscription.unsubscribe();
    this.reportShowIntervalSubscription.unsubscribe();
    this.reportHideIntervalSubscription.unsubscribe();
    this.reportPointSubscription.unsubscribe();
    this.reportClearSubscription.unsubscribe();
    this.showMessagePointSubscription.unsubscribe();
    this.hideMessagePointSubscription.unsubscribe();
    this.showRouteSubscription.unsubscribe();
    this.hideRouteSubscription.unsubscribe();
    this.removeAddressDetailSubscription.unsubscribe();
    this.clickOnUnitSubscription.unsubscribe();
    this.layersVisibilitySubscription.unsubscribe();
    this.resizeMapSubscription.unsubscribe();
    this.printMapSubscription.unsubscribe();
    this.refreshMapSubscription.unsubscribe();
  }

  /**
   * Обновление маркеров объектов
   * @param units Список объектов, по которым необходимо обновить маркеры
   */
  private onShowUnits = (units: TrackingUnit[]) => {
    for (const unit of units) {
      if (round(unit.position?.ln + unit.position?.lt, 1) === 0) continue;

      let marker = this.markers.find((item) => item.unitId === unit.id);
      if (marker) {
        marker.source.removeFeature(marker.feature);
      } else {
        marker = { unitId: unit.id, feature: null, source: null };
        this.markers.push(marker);
      }
      const markerInfo = {
        id: unit.id,
        name: unit.name,
        speed: unit.position.s,
        bearing: unit.position.b,
        lt: unit.position.lt,
        ln: unit.position.ln,
        icon: unit.icon,
        rotate: unit.rotate,
        color: unit.color,
        isOutdated: (Date.now() - unit.time) >= this.updateTimeInMilliseconds
      };
      marker.feature = this.getMarker(markerInfo);
      marker.feature.set('unit', unit);
      marker.source = (unit.eye || false)
        ? this.eyeMarkersSource
        : this.markersSource;
      marker.source.addFeature(marker.feature);
    }

    const eyeFeaturesCount = this.eyeMarkersSource.getFeatures().length;
    if (this.mapService.isTracking && eyeFeaturesCount && units.length) {
      this.map.getView().fit(
        this.eyeMarkersSource.getExtent(),
        this.getEyeFitOptions(eyeFeaturesCount));
    }
  }

  /**
   * Получение параметров размещения маркеров на карте
   * @param featuresCount Количество выбранных элементов
   */
  private getEyeFitOptions(featuresCount: number) {
    const currentZoom = this.map.getView().getZoom();
    if (currentZoom > 18) {
      return { maxZoom: 18 };
    } else if (featuresCount === 1) {
      return { maxZoom: currentZoom };
    } else {
      return null;
    }
  }

  /**
   * Удаление маркеров объектов
   * @param units Список объектов, по которым необходимо прекратить отображать маркеры
   */
  private onHideUnits = (units: TrackingUnit[]) => {
    for (const unit of units) {
      const marker = this.markers.find((item) => item.unitId === unit.id);
      if (marker) {
        marker.source.removeFeature(marker.feature);
        const index = this.markers.indexOf(marker);
        this.markers.splice(index, 1);
      }
    }
  }

  /**
   * Получение маркера объекта мониторинга
   * @param unit Данные по объекту мониторинга
   */
  private getMarker(unit: IUnitMarkerInfo) {
    const feature = new Feature({
      geometry: new Point(fromLonLat([unit.ln, unit.lt])),
      name: unit.name,
      population: 4000,
      rainfall: 500
    });
    const styles = [];
    const style = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        rotation: unit.rotate ? unit.bearing * 0.0174533 : 0,
        opacity: unit.isOutdated ? 0.5 : 1,
        src: `/assets/images/unit/${unit.icon || 'default.png'}`
      }),
      zIndex: 1
    });
    if (this.store.user && this.store.user.settings.showUnitNames) {
      const text = new Text({
        text: unit.name,
        offsetY: -25,
        // tslint:disable-next-line:max-line-length
        font: '14px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        fill: new Fill({ color: unit.color || '#000' }),
        stroke: new Stroke({ color: '#fff', width: 4 })
      });
      style.setText(text);
    }
    styles.push(style);
    if (!unit.rotate && unit.speed && !unit.isOutdated) {
      const arrowStyle = new Style({
        image: new Icon({
          anchor: [8, 37],
          anchorXUnits: IconAnchorUnits.PIXELS,
          anchorYUnits: IconAnchorUnits.PIXELS,
          rotation: unit.bearing * 0.0174533,
          opacity: 1,
          src: '/assets/images/arrow2.png'
        }),
        zIndex: 2
      });
      styles.push(arrowStyle);
    }
    feature.setStyle(styles);
    return feature;
  }

  /**
   * Завершение линейки
   */
  public onCancelDrawMeasure = () => {
    this.isMeasureEnable = false;
    if (this.measureDraw) {
      this.map.removeInteraction(this.measureDraw);
      this.measureDraw = null;
    }
    this.measureSource.clear(true);
    document.querySelectorAll('.ol-overlay-container.ol-selectable').forEach((e) => e.remove());
  }

  /**
   * Получение маркеров для точек начала и конца рейса отчёта
   * @param race Рейс
   * @param isFinish Признак конца рейса
   */
  private getReportRaceMarkers(race: IReportRace, isFinish: boolean): Feature {
    const point = isFinish ? race.p[race.p.length - 1] : race.p[0];

    const feature = this.getPointFeature(point, isFinish);
    const reportDetail: IMapReportDetails = {
      name: race.n,
      objectId: race.i,
      point
    };
    feature.set('reportDetail', reportDetail);
    feature.set('reportRacePoint', true);

    return feature;
  }

  /**
   * Получение маркеров для точек начала и конца интервала отчёта
   * @param interval Интервал
   * @param isFinish Признак конца рейса
   */
  private getReportIntervalMarkers(interval: IMapReportInterval, isFinish: boolean): Feature {
    const point = isFinish ? interval.p[interval.p.length - 1] : interval.p[0];

    const feature = this.getPointFeature(point, isFinish);
    const reportDetail: IMapReportDetails = {
      name: interval.n,
      objectId: interval.r.o,
      point,
      table: interval.table
    };
    feature.set('reportDetail', reportDetail);
    feature.set('reportIntervalPoint', true);

    return feature;
  }

  /**
   * Получение маркеров для точек начала и конца рейса
   * @param race Рейс
   * @param isFinish Признак конца рейса
   */
  private getRaceMarkers(race: IClientRace | IReportRace, isFinish?: boolean): Feature {
    const point = isFinish ? race.p[race.p.length - 1] : race.p[0];

    const feature = this.getPointFeature(point, isFinish);
    feature.set('race', race);
    feature.set('point', point);

    return feature;
  }

  /**
   * Получение Feature начального или конечного маркера
   * @param point Точка
   * @param isFinish Признак конечного маркера
   */
  private getPointFeature(point: ICoord, isFinish?: boolean) {
    const feature: Feature = new Feature({
      geometry: new Point(fromLonLat([point.ln, point.lt])),
      name: this.translator.instant(`component.map.track-${isFinish ? 'end' : 'begin'}`),
      population: 4000,
      rainfall: 500
    });
    const style = new Style({
      image: new Icon({
        anchor: [0, 1],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        src: `/assets/images/marker-${isFinish ? 'finish' : 'start'}.png`
      })
    });
    feature.setStyle(style);
    return feature;
  }

  /**
   * Удаление рейса
   * @param race Рейс
   */
  private onHideRace = (race: IClientRace) => {
    const existsRace = this.races.find((item) => item.race === race);
    if (existsRace) {
      for (const feature of existsRace.features) {
        this.racesSource.removeFeature(feature);
      }

      const index = this.races.indexOf(existsRace);
      this.races.splice(index, 1);
    }
    const existsRaceMarker = this.raceMarkers.find((item) => item.race === race);
    if (existsRaceMarker) {
      existsRaceMarker.source.removeFeature(existsRaceMarker.feature);
      const index = this.raceMarkers.indexOf(existsRaceMarker);
      this.raceMarkers.splice(index, 1);
    }
  }

  /**
   * Перемещение центра карты на указанную точку
   * @param point Точка
   */
  private moveToPoint = (point: ICoord) => {
    this.view.setCenter(fromLonLat([point.ln, point.lt]));
  }

  /**
   * Инициализация карты
   */
  private initMap() {
    this.osmLayer = new Tile({ source: new OSM(), visible: false });
    this.geozonesSource = new VectorSource({ features: [] });
    this.geozonesLayer = new VectorImageLayer({ source: this.geozonesSource });
    this.driversSource = new VectorSource({ features: [] });
    this.driversLayer = new Vector({ source: this.driversSource });
    this.trailersSource = new VectorSource({ features: [] });
    this.trailersLayer = new Vector({ source: this.trailersSource });
    this.drawGeozonesSource = new VectorSource({ features: [] });
    this.drawGeozonesLayer = new Vector({ source: this.drawGeozonesSource });
    this.racesSource = new VectorSource({ features: [] });
    this.racesLayer = new Vector({ source: this.racesSource });
    this.markersSource = new VectorSource({ features: [] });
    this.markersLayer = new VectorImageLayer({ source: this.markersSource });
    this.eyeMarkersSource = new VectorSource({ features: [] });
    this.eyeMarkersLayer = new VectorImageLayer({ source: this.eyeMarkersSource });

    this.racesMarkersSource = new VectorSource({ features: [] });
    this.racesMarkersLayer = new Vector({ source: this.racesMarkersSource });
    this.racesEyeMarkersSource = new VectorSource({ features: [] });
    this.racesEyeMarkersLayer = new Vector({ source: this.racesEyeMarkersSource });
    this.reportSource = new VectorSource({ features: [] });
    this.reportLayer = new Vector({ source: this.reportSource });
    this.messagesSource = new VectorSource({ features: [] });
    this.messagesLayer = new Vector({ source: this.messagesSource });
    this.routesSource = new VectorSource({ features: [] });
    this.routesLayer = new Vector({ source: this.routesSource });

    // Слои и сорцы для линейки
    this.measureSource = new VectorSource();
    this.measureLayer = new Vector({
      source: this.measureSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: '#01e066',
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#01e066'
          })
        })
      })
    })

    this.view = new View({
      zoom: 13,
      maxZoom: 19,
      constrainResolution: true
    });

    const mousePositionControl = new MousePosition({
      projection: 'EPSG:4326',
      undefinedHTML: ' ',
      coordinateFormat: (coord) => coordinateFormat(coord, '{y}, {x}', 4)
    });

    this.map = new Map({
      target: 'map',
      layers: [
        this.osmLayer,
        this.geozonesLayer,
        this.drawGeozonesLayer,
        this.racesLayer,
        this.markersLayer,
        this.eyeMarkersLayer,
        this.driversLayer,
        this.trailersLayer,
        this.racesMarkersLayer,
        this.racesEyeMarkersLayer,
        this.reportLayer,
        this.messagesLayer,
        this.routesLayer,
        this.measureLayer
      ],
      view: this.view,
      controls: defaults().extend([mousePositionControl])
    });
    this.map.on('pointermove', this.onPointerMove);
    this.map.on('singleclick', this.onSingleClick);
    this.drawGeozonesSource.on('addfeature', this.onFinishDrawGeozone);

    this.view.on('change:center', this.onViewCenterChange);
    this.view.on('change:resolution', this.onViewResolutionChange);

    const mapSource = +(localStorage.getItem('mapSource') || '0') as MapSourceType;
    this.selectMapSource(mapSource);
    this.setLayersVisibility();

    /** Загружаем геозоны при старте компонента */
    this.loadOrReloadGeozones()
  }

  /**
   * Добавление кнопки геокодирования на карту
   */
  private addGeocoderButton() {
    const geocoder = new Geocoder('nominatim', {
      provider: new GeocoderProvider(),
      lang: this.translator.currentLang,
      autoComplete: false,
      autoCompleteMinLength: 5,
      placeholder: this.strings['component.map.search-placeholder'],
      preventDefault: true
    });

    geocoder.on('addresschosen', this.onAddressChosen);

    this.map.addControl(geocoder);
  }

  /**
   * Добавление кнопки выбора слоя на карту
   */
  private addLayerSelectionButton() {
    const button = MapButton.createSettingsButton(this.showMapSettings, this.strings['component.map.layer-settings']);

    this.map.addControl(button);
  }

  /**
   * Добавление кнопки линейки
   */
  private addMeasureButton() {
    const button = MapButton.createMeasureButton(this.onDrawMeasure, this.strings['component.map.layer-measure']);

    this.map.addControl(button);
  }

  /**
   * Обработка при выборе адреса в местном геокодере
   * @param addressChosenEvent Данные по выбранному адресу
   */
  private onAddressChosen = (addressChosenEvent: IAddressChosenEvent) => {
    if (this.addressDetail) {
      this.map.removeOverlay(this.addressDetail.overlay);
      this.addressDetail = null;
    }

    const match = addressChosenEvent.address.original.query.match(/(\d+\.\d+)[, ]+(\d+\.\d+)/);

    if (match && match.length === 3) {
      addressChosenEvent.coordinate = fromLonLat([parseFloat(match[2]), parseFloat(match[1])]);
    }

    this.view.setCenter(addressChosenEvent.coordinate);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AddressDetailComponent);
    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.addressChosenEvent = addressChosenEvent;

    const element = document.getElementsByTagName('app-address-detail')[0] as HTMLElement;
    element.style.display = 'block';
    const detail = new Overlay({
      stopEvent: false,
      position: addressChosenEvent.coordinate,
      element,
      positioning: OverlayPositioning.BOTTOM_CENTER
    });
    this.addressDetail = { addressChosenEvent, overlay: detail };
    this.map.addOverlay(detail);
  }

  /**
   * Обработка изменения центра карты
   */
  private onViewCenterChange = () => {
    const [lon, lat] = transform(this.view.getCenter(), 'EPSG:3857', 'EPSG:4326');
    if (this.gmap) {
      this.gmap.setCenter(new google.maps.LatLng(lat, lon));
    }

    if (this.ymap) {
      this.ymap.setCenter([lat, lon]).then();
    }
  }

  /**
   * Обработка изменения масштаба карты
   */
  private onViewResolutionChange = () => {
    if (this.gmap) {
      this.gmap.setZoom(Math.round(this.view.getZoom()));
    }

    if (this.ymap) {
      this.ymap.setZoom(Math.round(this.view.getZoom())).then();
    }
  }

  /**
   * Отображение настроек карты
   * @param event Объект события
   */
  private showMapSettings = (event: MouseEvent) => {
    // Ориентировочно где то здесь будет слева от кнопки, на которую нажимают
    const target = event.target as HTMLElement;
    const position: IPopoverPosition = getScreenCordinates(target);

    const x = position.x - 20;
    const y = 8;
    const mapSource = +(localStorage.getItem('mapSource') || '0') as MapSourceType;

    setTimeout(() => {
      this.popoverService.addPopover(MapSettingsComponent, { x, y }, {
        mapSource,
        onMapSourceChanged: (s) => {
          localStorage.setItem('mapSource', `${s}`);
          this.selectMapSource(s);
        }
      });
    }, 50)
  }

  /**
   * Загрузка компонента с детальной информацией
   * @param unit Объект мониторинга
   */
  private loadDetail(unit: TrackingUnit) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MapDetailLightComponent);

    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);

    componentRef.instance.unitId = unit.id;
    componentRef.instance.geozones = unit.geozones;
  }

  /**
   * Загрука компонента с детальной информацией рейса
   * @param race Рейс
   * @param point Точка рейса
   * @param eventInfos События в точке
   */
  private loadRaceDetail(
    race: IClientRace,
    point: IRacePoint,
    eventInfos: IRaceEventInfo[]
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MapRaceDetailComponent);

    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.race = race;
    componentRef.instance.point = point;
    componentRef.instance.eventInfos = eventInfos;
  }

  /**
   * Загрузка компонента с детальной информацией о геозоне
   * @param geozone Геозона
   */
  private loadGeozoneDetail(geozone: IClientGeozone) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GeozonesDetailComponent);

    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.geozone = geozone;
  }

  /**
   * Загрузка компонента с детальной информацией о водителе
   * @param driver Водитель
   */
  private loadDriverDetail(driver: IClientDriver) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DriversDetailComponent);

    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.driver = driver;
  }

  /**
   * Загрузка компонента с детальной информацией о прицепе
   * @param trailer Прицеп
   */
  private loadTrailerDetail(trailer: IClientTrailer) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TrailersDetailComponent);

    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.trailer = trailer;
  }

  /**
   * Удаление всех всплывающих окошек с детальной информацией
   */
  private removeAllDetails() {
    if (this.raceDetail) {
      this.map.removeOverlay(this.raceDetail.overlay);
      this.raceDetail = null;
    }

    if (this.geozoneDetail) {
      this.map.removeOverlay(this.geozoneDetail.overlay);
      this.geozoneDetail = null;
    }

    if (this.reportDetailOverlay) {
      this.map.removeOverlay(this.reportDetailOverlay);
      this.reportDetailOverlay = null;
    }

    if (this.driverDetail) {
      this.map.removeOverlay(this.driverDetail.overlay);
      this.driverDetail = null;
    }

    if (this.trailerDetail) {
      this.map.removeOverlay(this.trailerDetail.overlay);
      this.trailerDetail = null;
    }

    if (this.detail) {
      this.map.removeOverlay(this.detail.overlay);
      this.detail = null;
    }
  }

  /**
   * Обработка при движении курсора мыши
   * @param e Объект события
   */
  private onPointerMove = (e) => {
    if (e.dragging) {
      return;
    }

    if (e.originalEvent.ctrlKey) {
      this.removeAllDetails();
      return;
    }

    const features: Array<Feature | RenderFeature> = [];
    e.map.forEachFeatureAtPixel(e.pixel, (feature) => {
      features.push(feature);
    });

    // Иконка ТС на карте
    const unitFeature = features.find((f) => f.get('unit'));

    // Элементы рейса на карте
    let pointFeatures = features.filter((f) => f.get('point'));
    let eventFeatures = features.filter((f) => f.get('event'));

    // Геозона на карте
    const geozoneFeatures = features.filter((f) => f.get('geozone') && !this.drawGeozonesSource.getFeatures().includes(f as Feature));

    // Водители на карте
    const driverFeatures = features.filter((f) => f.get('driver'));

    // Прицепы на карте
    const trailerFeatures = features.filter((f) => f.get('trailer'));

    // Элементы отчета на карте
    const reportPointFeatures = features.filter((f) => f.get('reportDetail'));

    if (this.raceDetail) {
      this.map.removeOverlay(this.raceDetail.overlay);
      this.raceDetail = null;
    }
    if (this.geozoneDetail) {
      this.map.removeOverlay(this.geozoneDetail.overlay);
      this.geozoneDetail = null;
    }
    if (this.reportDetailOverlay) {
      this.map.removeOverlay(this.reportDetailOverlay);
      this.reportDetailOverlay = null;
    }
    if (this.driverDetail) {
      this.map.removeOverlay(this.driverDetail.overlay);
      this.driverDetail = null;
    }
    if (this.trailerDetail) {
      this.map.removeOverlay(this.trailerDetail.overlay);
      this.trailerDetail = null;
    }

    let overlayAdded = false;
    if (unitFeature) {
      const unit = unitFeature.get('unit') as TrackingUnit;
      if (this.detail) {
        if (this.detail.unit.id !== unit.id) {
          this.detail.timer = setTimeout(() => {
            if (this.detail?.overlay) this.map.removeOverlay(this.detail.overlay);
            this.detail = null;
          }, 100);
        }
        return;
      }
      this.loadDetail(unit);
      const element = document.getElementsByTagName('map-detail-light')[0] as HTMLElement;
      element.onmousemove = (evt) => {
        clearTimeout(this.detail.timer);
      };
      element.style.display = 'block';
      const detail = new Overlay({
        stopEvent: true,
        position: e.coordinate,
        element,
        positioning: this.getDetailPositioning(e)
      });
      this.detail = { unit, overlay: detail };
      e.map.addOverlay(detail);
      overlayAdded = true;
    } else {
      if (this.detail) {
        this.detail.timer = setTimeout(() => {
          if (this.detail?.overlay) this.map.removeOverlay(this.detail.overlay);
          this.detail = null;
        }, 100);
      }

      if (pointFeatures.length) {
        const race: IClientRace = eventFeatures.length
          ? eventFeatures[0].get('race')
          : pointFeatures[0].get('race');
        pointFeatures = pointFeatures.filter((f) => f.get('race') === race);
        eventFeatures = eventFeatures.filter((f) => f.get('race') === race);
        const point: IRacePoint = pointFeatures[0].get('point') as IRacePoint;
        const eventInfos = eventFeatures
          ?.map((feature) => feature.get('event') as IRaceEventInfo)
          ?.sort((a, b) => a.tm - b.tm);

      this.loadRaceDetail(race, point, eventInfos);
      const element = document.getElementsByTagName('map-race-detail')[0] as HTMLElement;
      element.style.display = 'block';
      const detail = new Overlay({
        stopEvent: false,
        position: e.coordinate,
        element,
        positioning: this.getDetailPositioning(e)
      });
        this.raceDetail = { race, point, overlay: detail };
        e.map.addOverlay(detail);
        overlayAdded = true;
      } else if (reportPointFeatures.length) {
        this.addReportDetail(e, reportPointFeatures);
        overlayAdded = true;
      } else if (geozoneFeatures.length) {
        let withMinSquare = geozoneFeatures[0].get('geozone') as IClientGeozone;
        for (let i = 1; i < geozoneFeatures.length; i++) {
          const geozone = geozoneFeatures[i].get('geozone') as IClientGeozone;
          if (geozone.square < withMinSquare.square) {
            withMinSquare = geozone;
          }
        }

        withMinSquare.square = this.monitoringService.calcGeozoneSquare(withMinSquare)

        this.loadGeozoneDetail(withMinSquare);
        const element = document.getElementsByTagName('geozones-detail')[0] as HTMLElement;
        element.style.display = 'block';
        const detail = new Overlay({
          stopEvent: false,
          position: e.coordinate,
          element,
          positioning: this.getDetailPositioning(e)
        });
        this.geozoneDetail = { geozone: withMinSquare, overlay: detail };
        e.map.addOverlay(detail);
        overlayAdded = true;
      } else if (driverFeatures.length) {
        const driver = driverFeatures[0].get('driver') as IClientDriver;
        this.loadDriverDetail(driver);
        const element = document.getElementsByTagName('drivers-detail')[0] as HTMLElement;
        element.style.display = 'block';
        const detail = new Overlay({
          stopEvent: false,
          position: e.coordinate,
          element,
          positioning: this.getDetailPositioning(e)
        });
        this.driverDetail = { driver, overlay: detail };
        e.map.addOverlay(detail);
        overlayAdded = true;
      } else if (trailerFeatures.length) {
        const trailer = trailerFeatures[0].get('trailer') as IClientTrailer;
        this.loadTrailerDetail(trailer);
        const element = document.getElementsByTagName('trailers-detail')[0] as HTMLElement;
        element.style.display = 'block';
        const detail = new Overlay({
          stopEvent: false,
          position: e.coordinate,
          element,
          positioning: this.getDetailPositioning(e)
        });
        this.trailerDetail = { trailer, overlay: detail };
        e.map.addOverlay(detail);
        overlayAdded = true;
      }
    }

    // Если добавили новый оверлей, удаляем данные по адресу
    if (overlayAdded) {
      this.onRemoveAddressDetail();
    }
  }

  /**
   * Обработка одиночного клика по карте
   * @param e Объект события
   */
  private onSingleClick = (e) => {
    if (e.dragging) {
      return;
    }

    const features: Array<Feature | RenderFeature> = [];
    e.map.forEachFeatureAtPixel(e.pixel, (feature) => {
      features.push(feature);
    });

    // Очистка списка от подсветкки
    const cars = document.getElementsByClassName('car-row');
    for (const car of Array.from(cars)) {
      car.classList.remove('unit-highlight');
    }

    if (this.monitoringService.lastCheckedUnit) {
      this.monitoringService.lastCheckedUnit.detail = false;
      this.monitoringService.lastCheckedUnit = null;
    }

    // Клик по объекту мониторинга переключает признак слежения за ним
    const unitFeature = features.find((f) => f.get('unit'));
    if (unitFeature) {
      const unit = unitFeature.get('unit') as TrackingUnit;

      this.monitoringService.switchVisibilityOfUnitDetail(unit);

      return;
    }

    if (!e.originalEvent.ctrlKey) {
      return;
    }

    // Клик по геозоне с зажатым ctrl переводит ее в режим редактирования

    // Если уже редактируется другая геозона, то не переводим
    if (this.mapService.isEditGeozone) {
      return;
    }

    const geozoneFeatures = features.filter((f) => f.get('geozone'));
    if (!geozoneFeatures.length) {
      return;
    }

    let withMinSquare = geozoneFeatures[0].get('geozone') as IClientGeozone;
    for (let i = 1; i < geozoneFeatures.length; i++) {
      const geozone = geozoneFeatures[i].get('geozone') as IClientGeozone;
      if (geozone.square < withMinSquare.square) {
        withMinSquare = geozone;
      }
    }

    const editGeozone = { ...withMinSquare };
    this.monitoringService.startEditGeozone(editGeozone);
    this.router.navigate([`monitoring/geozones/${editGeozone.id}`]).then();
  }

  /**
   * Получение позиционирования для детальной информации
   * @param e Объект события движения мыши
   */
  private getDetailPositioning(e) {
    const center = this.map.getView().getCenter();
    if (e.coordinate[0] > center[0]) {
      if (e.coordinate[1] < center[1]) {
        return OverlayPositioning.BOTTOM_RIGHT;
      } else {
        return OverlayPositioning.TOP_RIGHT;
      }
    } else {
      if (e.coordinate[1] < center[1]) {
        return OverlayPositioning.BOTTOM_LEFT;
      } else {
        return OverlayPositioning.TOP_LEFT;
      }
    }
  }

  /**
   * Отображение рейса
   * @param race Рейс
   */
  private onShowRace = (race: IClientRace) => {
      if (!race.p.length || this.races.some((item) => item.race === race)) {
        return;
      }

      const newRace = { race, features: [] as Feature[] };
      this.races.push(newRace);

      const startFeature = this.getRaceMarkers(race);
      newRace.features.push(startFeature);
      this.racesSource.addFeature(startFeature);

      const finishFeature = this.getRaceMarkers(race, true);
      newRace.features.push(finishFeature);
      this.racesSource.addFeature(finishFeature);

      for (const segment of race?.s || []) {
        const rgb = hexToRgb(segment.c);
        const points = race.p.slice(segment.s, segment.e + 1).filter((p) => !p.h);

        for (const point of points) {
          const coordinate = fromLonLat([point.ln, point.lt]);

          const pointStyle = new Style({
            stroke: new Stroke({
              color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 1)`,
              width: (race.lineWidth || 4) * 1.5
            })
          });
          const pointFeature = new Feature({
            geometry: new Circle(coordinate),
            name: race.unitName
          });
          pointFeature.setStyle(pointStyle);
          pointFeature.set('race', race);
          pointFeature.set('point', point);

          newRace.features.push(pointFeature);
          this.racesSource.addFeature(pointFeature);

          for (const event of point?.e || []) {
            let name: string;
            let icon: string;
            switch (event.t) {
              case RaceEventType.PARKING:
                name = this.strings['component.map.parking'];
                icon = 'parking-active.png';
                break;
              case RaceEventType.STOP:
                name = this.strings['component.map.stop'];
                icon = 'stop-active.png';
                break;
              case RaceEventType.SPEEDING:
                name = this.strings['component.map.speeding'];
                icon = 'speeding-active.png';
                break;
              case RaceEventType.FILLING:
                name = this.strings['component.map.filling'];
                icon = 'filling-active.png';
                break;
              case RaceEventType.THEFT:
                name = this.strings['component.map.theft'];
                icon = 'theft-active.png';
                break;
              default:
                name = this.strings['component.map.event'];
                icon = 'event-active.png';
                break;
            }

            const eventFeatureStyle = new Style({
              image: new Icon({
                anchor: [0.5, 0.5],
                anchorXUnits: IconAnchorUnits.FRACTION,
                anchorYUnits: IconAnchorUnits.FRACTION,
                opacity: 1,
                src: `/assets/images/race-events/${icon}`
              })
            });
            const eventFeature = new Feature({
              geometry: new Point(coordinate),
              name,
              population: 4000,
              rainfall: 500
            });
            eventFeature.setStyle(eventFeatureStyle);
            eventFeature.set('race', race);
            eventFeature.set('point', point);
            eventFeature.set('event', event);

            newRace.features.push(eventFeature);
            this.racesSource.addFeature(eventFeature);
          }
        }

        if (race.lineWidth) {
          const lineStyle = new Style({
            stroke: new Stroke({
              color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.7)`,
              width: race.lineWidth
            })
          });
          const lineFeature = new Feature({
            geometry: new LineString(points.map((point) => fromLonLat([point.ln, point.lt]))),
            name: race.unitName
          });
          lineFeature.setStyle(lineStyle);
          lineFeature.set('race', race);
          lineFeature.set('segment', segment);

          newRace.features.push(lineFeature);
          this.racesSource.addFeature(lineFeature);
        }
      }

      const racesExtent = this.racesSource.getExtent();
      if (!isEmpty(racesExtent)) {
        this.map.getView().fit(racesExtent);
      }
  }

  /**
   * Загружаем геозоны с сервера и отображаем на карте
   */
  private loadOrReloadGeozones = () => {
    this.mapService.clearGeozonesSubject.next()
    this.geozonesService.getGeozonesForMap()
      .subscribe((data) => {
        this.mapService.showGeozonesSubject.next(data)
      })
  }

  /**
   * В цикле разбираем геозоны
   * @param geozones Объект геозоны
   */
  private onShowGeozonesToMap = (geozones: IGeozoneMap[]) => {
    for (const geozone of geozones) {
      this.showGeozoneToMap(geozone);
    }
  }

  /**
   * Удаление списка геозон с карты
   * @param geozoneIds
   */
  private onHideGeozonesToMap = (geozoneIds: string[]) => {
    for (const geozoneId of geozoneIds) {
      const mapGeozone = this.mapGeozones.find((g) => g.geozone.id === geozoneId);
      if (mapGeozone) {
        this.geozonesSource.removeFeature(mapGeozone.feature);
        const index = this.mapGeozones.indexOf(mapGeozone);
        this.mapGeozones.splice(index, 1);
      }
    }
  }

  /**
   * Очистка слоя с геозонами
   */
  private onClearGeozonesToMap = () => {
    this.geozonesSource.clear()
    this.mapGeozones = []
  }

  /**
   * Рисуем геозоны на карте в зависимости от типа геозоны
   * @param geozone Объект геозоны
   */
  private showGeozoneToMap(geozone: IGeozoneMap) {
    let mapGeozone = this.mapGeozones.find((g) => g.geozone?.id === geozone.id);

    if (mapGeozone) {
      this.geozonesSource.removeFeature(mapGeozone.feature);
      mapGeozone.feature = null;
    } else {
      mapGeozone = { geozone, feature: null };
      this.mapGeozones.push(mapGeozone);
    }

    let feature: Feature<Geometry>
    switch (geozone.type) {
      // Рисуем полигоны
      case GeozoneType.POLYGON:
        const pointsPolygon = geozone.points.map((point) => fromLonLat([point.ln, point.lt]));
        feature = new Feature({ geometry: new Polygon([pointsPolygon]), name: geozone.name });
        break;

      // Рисуем линии
      case GeozoneType.LINE:
        const pointsLine = geozone.points.map((point) => fromLonLat([point.ln, point.lt]));
        feature = new Feature({ geometry: new LineString(pointsLine), name: geozone.name });
        break;

      // Рисуем круги
      case GeozoneType.CIRCLE:
        const circle = circular([geozone.points[0]?.ln, geozone.points[0]?.lt], geozone.r, 64, 6378137)
          .transform('EPSG:4326', 'EPSG:3857');
        feature = new Feature({ geometry: circle, name: geozone.name });
        break;
    }

    if (feature) {
      mapGeozone.feature = feature;
      feature.set('geozone', geozone);
      feature.setStyle(this.getGeozoneStyleMap(geozone))
      this.geozonesSource.addFeature(feature);
    }
  }

  /**
   * Заполняем стили геозон
   * @param geozone Объект геозоны
   */
  private getGeozoneStyleMap(geozone: IGeozoneMap): Style {
    const rgb = hexToRgb(geozone.color || '#000');
    const stroke = new Stroke({
      color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.7)`,
      width: geozone.type === GeozoneType.LINE ? 8 : 4
    })
    const style = new Style({ stroke });

    if (geozone.type !== GeozoneType.LINE) {
      const fill = new Fill({ color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.4)` });
      style.setFill(fill);
    }

    if (this.store?.user?.settings?.showGeozoneNames) {
      const text = new Text({
        text: geozone.name,
        font: '14px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        fill: new Fill({ color: geozone.labelColor || '#000' }),
        stroke: new Stroke({ color: '#fff', width: 4 }),
        exceedLength: true
      } as any);
      style.setText(text);
    }

    return style;
  }

  /**
   * Установить центр карты на геозону
   * @param geozoneId идентификатор геозоны
   */
  private onFitGeozone = (geozoneId: string) => {
    const mapGeozone = this.mapGeozones.find((g) => g.geozone?.id === geozoneId);
    if (!mapGeozone) {
      return;
    }

    const geozoneExtent = mapGeozone.feature.getGeometry().getExtent();
    this.view.fit(geozoneExtent);
  }

  /**
   * Отображение точки маршрута на карте
   * @param geozone Точка маршрута (геозона)
   * @param index Порядковый номер точки в маршруте
   */
  private showRouteGeozone = (geozone: IClientGeozone, index: number) => {
    let routeGeozone = this.routeGeozones.find((p) => p.obj.id === geozone.id);
    if (routeGeozone) {
      routeGeozone.amount++;
      return;
    }
    const feature = this.getGeozoneFeature(geozone);
    routeGeozone = { obj: geozone, amount: 1, feature: null };
    if (feature) {
      const text = new Text({
        text: `${index + 1}`,
        // tslint:disable-next-line:max-line-length
        font: '18px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        stroke: new Stroke({ color: '#fff', width: 6 })
      });
      const style = feature.getStyle() as Style;
      style.setText(text);
      feature.setStyle(style);
      routeGeozone.feature = feature;
      this.routesSource.addFeature(feature);
    }
    this.routeGeozones.push(routeGeozone);
  }

  /**
   * Удаление точки маршрута с карты
   * @param id Идентификатор точки маршрута (геозона)
   */
  private hideRouteGeozone = (id: string) => {
    const index = this.routeGeozones.findIndex((p) => p.obj.id === id);
    if (this.routeGeozones[index].amount > 1) {
      this.routeGeozones[index].amount--;
      return;
    }
    this.routesSource.removeFeature(this.routeGeozones[index].feature);
    this.routeGeozones.splice(index, 1);
  }

  /**
   * Отображение ребра маршрута на карте
   * @param fromId Идентификатор первой точки маршрута (геозона)
   * @param toId Идентификатор второй точки маршрута (геозона)
   */
  private showPathBetweenRouteGeozones = (fromId: string, toId: string) => {
    let edge = this.routeEdges.find(
      (e) => e.obj.fromId === fromId && e.obj.toId === toId || e.obj.fromId === toId && e.obj.toId === fromId);
    if (edge) {
      edge.amount++;
      return;
    }
    edge = { obj: { fromId, toId }, amount: 1, feature: null };
    const firstPoint = this.routeGeozones.find((p) => p.obj.id === fromId);
    const secondPoint = this.routeGeozones.find((p) => p.obj.id === toId);
    const points = [
      fromLonLat(this.getGeozoneCenter(firstPoint)),
      fromLonLat(this.getGeozoneCenter(secondPoint))
    ];
    const lineFeature = new Feature({
      geometry: new LineString(points)
    });
    const lineStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${21},${26},${174}, 0.7)`,
        width: 4,
        lineDash: [.1, 5]
      })
    });
    lineFeature.setStyle(lineStyle);
    edge.feature = lineFeature;
    this.routeEdges.push(edge);
    this.routesSource.addFeature(lineFeature);
  }

  /**
   * Скрытие ребра маршрута на карте
   * @param fromId Идентификатор первой точки маршрута (геозона)
   * @param toId Идентификатор второй точки маршрута (геозона)
   */
  private hidePathBetweenRouteGeozones = (fromId: string, toId: string) => {
    const index = this.routeEdges.findIndex(
      (e) => e.obj.fromId === fromId && e.obj.toId === toId || e.obj.fromId === toId && e.obj.toId === fromId);
    if (this.routeEdges[index].amount > 1) {
      this.routeEdges[index].amount--;
      return;
    }
    this.routesSource.removeFeature(this.routeEdges[index].feature);
    this.routeEdges.splice(index, 1);
  }

  /**
   * Создание геозоны типа "Линия"
   * @param geozone Геозона
   */
  private createGeozoneLine(geozone: IClientGeozone): Feature {
    const points = geozone.points.map((point) => fromLonLat([point.ln, point.lt]));
    const feature = new Feature({
      geometry: new LineString(points),
      name: geozone.name
    });
    feature.set('geozone', geozone);

    feature.setStyle(this.getGeozoneStyle(geozone));
    return feature;
  }

  /**
   * Создание геозоны типа "Круг" (в виде полигона)
   * @param geozone Геозона
   */
  private createGeozoneCirclePolygon(geozone: IClientGeozone): Feature {
    const point = geozone.points[0];
    const circle = circular([point.ln, point.lt], geozone.r, 64, 6378137)
      .transform('EPSG:4326', 'EPSG:3857');
    const feature = new Feature({
      geometry: circle,
      name: geozone.name
    });
    feature.set('geozone', geozone);

    feature.setStyle(this.getGeozoneStyle(geozone));
    return feature;
  }

  /**
   * Создание геозоны типа "Круг" (в виде окружности, для редактирования геозоны)
   * @param geozone Геозона
   */
  private createGeozoneCircle(geozone: IClientGeozone): Feature {
    const point = geozone.points[0];

    const center = fromLonLat([point.ln, point.lt]);
    const resolutionAtEquator = this.view.getResolution();
    const pointResolution = resolutionAtEquator / Math.cosh(center[1] / 6378137);
    const resolutionFactor = resolutionAtEquator / pointResolution;
    const radius = geozone.r * resolutionFactor;

    const feature = new Feature({
      geometry: new Circle(center, radius),
      name: geozone.name
    });
    feature.set('geozone', geozone);

    feature.setStyle(this.getGeozoneStyle(geozone));
    return feature;
  }

  /**
   * Создание геозоны типа "Полигон"
   * @param geozone Геозона
   */
  private createGeozonePolygon(geozone: IClientGeozone): Feature {
    const points = geozone.points.map((point) => fromLonLat([point.ln, point.lt]));
    const feature = new Feature({
      geometry: new Polygon([points]),
      name: geozone.name
    });
    feature.set('geozone', geozone);

    feature.setStyle(this.getGeozoneStyle(geozone));
    return feature;
  }

  /**
   * Обработка начала рисования геозоны
   * @param geozone Геозона
   */
  private onStartDrawGeozone = (geozone: IClientGeozone) => {
    this.map.removeInteraction(this.measureDraw);
    this.isMeasureEnable = false;

    if (this.geozoneDraw) {
      this.map.removeInteraction(this.geozoneDraw.snap);
      this.map.removeInteraction(this.geozoneDraw.draw);
      this.map.removeInteraction(this.geozoneDraw.modify);
      this.geozoneDraw = null;
    }
    this.drawGeozonesSource.clear(true);
    if (geozone.points && geozone.points.length && (geozone.r || geozone.type !== GeozoneType.CIRCLE)) {
      let feature: Feature;
      switch (geozone.type) {
        case GeozoneType.CIRCLE:
          feature = this.createGeozoneCircle(geozone);
          break;
        case GeozoneType.LINE:
          feature = this.createGeozoneLine(geozone);
          break;
        case GeozoneType.POLYGON:
          feature = this.createGeozonePolygon(geozone);
          break;
      }
      this.drawGeozonesSource.addFeature(feature);
    }
    let geometryType: GeometryType;
    switch (geozone.type) {
      case GeozoneType.POLYGON:
        geometryType = GeometryType.POLYGON;
        break;
      case GeozoneType.CIRCLE:
        geometryType = GeometryType.CIRCLE;
        break;
      case GeozoneType.LINE:
        geometryType = GeometryType.LINE_STRING;
        break;
      default:
        return;
    }

    const draw = new Draw({
      source: this.drawGeozonesSource,
      type: geometryType
    });
    draw.on('drawstart', () => {
      // Удаляем то, что нарисовали раньше
      this.drawGeozonesSource.clear(true);

      // Удаляем старое изображение геозоны с карты
      if (geozone.id) {
        const mapGeozone = this.mapGeozones.find((g) => g.geozone.id === geozone.id);
        if (mapGeozone) {
          this.geozonesSource.removeFeature(mapGeozone.feature);
          const index = this.mapGeozones.indexOf(mapGeozone);
          this.mapGeozones.splice(index, 1);
        }
      }
    });
    const modify = new Modify({ source: this.drawGeozonesSource });
    modify.on('modifyend', this.onFinishModifyGeozone);
    const snap = new Snap({ source: this.drawGeozonesSource });
    this.map.addInteraction(modify);
    this.map.addInteraction(draw);
    this.map.addInteraction(snap);
    this.geozoneDraw = { draw, geozone, modify, snap };
  }

  /**
   * Обработка отмены рисования геозоны
   */
  private onCancelDrawGeozone = () => {
    if (this.geozoneDraw) {
      this.map.removeInteraction(this.geozoneDraw.snap);
      this.map.removeInteraction(this.geozoneDraw.draw);
      this.map.removeInteraction(this.geozoneDraw.modify);
      this.geozoneDraw = null;
    }
    this.drawGeozonesSource.clear(true);
  }

  /**
   * Запуск линейки
   */
  private onDrawMeasure = () => {
    this.isMeasureEnable = false;
    if (this.geozoneDraw) {
      return
    }

    if (this.measureDraw) {
      this.map.removeInteraction(this.measureDraw);
    }

    // Признак видимости линейки
    this.isMeasureEnable = true;

    // Выбор типа линейки
    let type: GeometryType;
    switch (this.measureType) {
      case MeasureType.POLYGON:
        type = GeometryType.POLYGON;
        break;
      default:
        type = GeometryType.LINE_STRING;
    }

    // Рисуем линейку
    let listener;
    const draw = new Draw({
      source: this.measureSource,
      type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)'
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          })
        })
      })
    });

    // Тултипы, подсказки
    this.createMeasureTooltip();
    this.createHelpTooltip();

    draw.on('drawstart', (evt) => {
      this.measureSketch = evt.feature;

      let tooltipCoord = evt.feature.get('coordinate');

      // tslint:disable-next-line:no-shadowed-variable
      listener = this.measureSketch.getGeometry().on('change', (evt) => {
        const geom = evt.target;

        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.measureDraw = draw;

    draw.on('drawend', () => {
      this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip.setOffset([0, -7]);
      // unset sketch
      this.measureSketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      unByKey(listener);
    });

    this.map.addInteraction(draw);
  }

  /**
   * Перезапускаем линейки при смене типа
   */
  public changeMeasure() {
    this.map.removeInteraction(this.measureDraw);
    this.onDrawMeasure();
  }

  /**
   * Анимация рейса
   * @param animationInfo Информация по анимации
   */
  private onAnimateRace = (animationInfo: { race: IClientRace, point: IRacePoint }) => {
    const { race, point } = animationInfo;
    let marker = this.raceMarkers.find((item) => item.race === race);
    if (marker) {
      marker.source.removeFeature(marker.feature);
      marker.point = point;
    } else {
      marker = { race, feature: null, source: null, point };
      this.raceMarkers.push(marker);
    }
    const markerInfo = {
      id: race.uid,
      name: race.unitName,
      lt: point.lt,
      ln: point.ln,
      bearing: point.b,
      speed: point.s,
      icon: race.icon,
      rotate: race.rotate,
      color: race.labelColor,
      isOutdated: false
    };
    marker.feature = this.getMarker(markerInfo);
    marker.feature.set('race', race);
    marker.feature.set('point', point);
    marker.source = race.eye
      ? this.racesEyeMarkersSource
      : this.racesMarkersSource;
    marker.source.addFeature(marker.feature);

    const eyeFeaturesCount = this.racesEyeMarkersSource.getFeatures().length;
    if (eyeFeaturesCount > 1) {
      this.map.getView().fit(
        this.racesEyeMarkersSource.getExtent(),
        this.getEyeFitOptions(eyeFeaturesCount)
      );
    } else if (eyeFeaturesCount) {
      const [x, y] = this.racesEyeMarkersSource.getExtent();
      this.map.getView().setCenter([x, y]);
    }
  }

  /**
   * Получам данные измерений
   * @param polygon
   */
  private formatArea = (polygon) => {
    const area = getArea(polygon);
    const length = this.formatLength(polygon)

    let result = this.translator.instant('component.map.measure.square-m', {
      square: Math.round(area * 100) / 100,
      width: length
    });

    if (area > 10000) {
      result = this.translator.instant('component.map.measure.square-km', {
        square: Math.round((area / 1000000) * 100) / 100,
        squareGa: Math.round((area / 10000) * 100) / 100,
        width: length
      });
    }

    return result;
  };

  /**
   * Получам данные измерений
   * @param line
   */
  private formatLength = (line) => {
    const length = getLength(line);

    let result;
    result = this.translator.instant('component.map.measure.width-m', { width: Math.round(length * 100) / 100 });

    if (length > 100) {
      result = this.translator.instant('component.map.measure.width-km', { width: Math.round(length / 10) / 100 });
    }

    return result;
  };

  /** Создаем тултип для отображения площади, периметра, расстояния */
  private createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER
    });
    this.map.addOverlay(this.measureTooltip);
  }

  /**
   * Создаем тултип хелпер
   */
  private createHelpTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip hidden';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT
    });
    this.map.addOverlay(this.measureTooltip);
  }

  /**
   * Обработка завершения операции над геозоной (рисование/изменение)
   * @param feature Объект геозоны на карте
   */
  private onFinishGeozone = (feature: Feature) => {
    const geometry = feature.getGeometry();
    const geozone = this.geozoneDraw.geozone;
    if (geozone.type === GeozoneType.CIRCLE) {
      const circle = geometry as Circle;
      const center = circle.getCenter();
      const [ln, lt] = toLonLat(center);
      const points = [{ lt, ln }];
      const radius = circle.getRadius();
      const edgeCordinate: [number, number] = [center[0] + radius, center[1]];
      const groundRadius = getDistance(toLonLat(center), toLonLat(edgeCordinate), 6378137);
      this.monitoringService.updateEditGeozonePointsAndR(points, groundRadius);
    } else {
      const coordinates = geozone.type === GeozoneType.POLYGON
        ? (geometry as Polygon).getCoordinates()[0]
        : (geometry as LineString).getCoordinates();
      const points = coordinates.map((coord) => {
        const [ln, lt] = toLonLat(coord);
        return { ln, lt };
      });
      this.monitoringService.updateEditGeozonePointsAndR(points);
    }
    feature.setStyle(this.getGeozoneStyle(geozone));
  }

  /**
   * Обработка завершения изменения геозоны на карте
   * @param event Объект события
   */
  private onFinishModifyGeozone = (event) => {
    const feature = event.features.getArray()[0];
    this.onFinishGeozone(feature);
  }

  /**
   * Обработка завершения рисования геозоны на карте
   * @param event Объект события
   */
  private onFinishDrawGeozone = (event) => {
    const feature = event.feature;
    if (feature.get('geozone')) {
      return;
    }
    this.onFinishGeozone(feature);
  }

  /**
   * Получение стиля геозоны
   * @param geozone Геозона
   */
  private getGeozoneStyle(geozone: IClientGeozone): Style {
    const rgb = hexToRgb(geozone.color || '#000');
    const style = new Style({
      stroke: new Stroke({
        color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.7)`,
        width: geozone.type === GeozoneType.LINE ? 8 : 4
      })
    });

    if (geozone.type !== GeozoneType.LINE) {
      const fill = new Fill({ color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.4)` });
      style.setFill(fill);
    }

    if (this.store.user && this.store.user.settings.showGeozoneNames) {
      const text = new Text({
        text: geozone.name,
        // tslint:disable-next-line:max-line-length
        font: '14px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        fill: new Fill({ color: geozone.labelColor || '#000' }),
        stroke: new Stroke({ color: '#fff', width: 4 }),
        exceedLength: true
      } as any);
      style.setText(text);
    }

    return style;
  }

  /**
   * Отображение списка водителей
   * @param drivers Список водителей, которых нужно отобразить
   */
  private onShowDrivers = (drivers: IClientDriver[]) => {
    for (const driver of drivers) {
      this.showDriver(driver);
    }
  }

  /**
   * Отображение водителя на карте
   * @param driver Водитель
   */
  private showDriver = (driver: IClientDriver) => {
    let mapDriver = this.mapDrivers.find((d) => d.driver === driver);
    if (mapDriver && mapDriver.feature) {
      this.driversSource.removeFeature(mapDriver.feature);
      mapDriver.feature = null;
    } else {
      mapDriver = { driver, feature: null };
      this.mapDrivers.push(mapDriver);
    }

    if (driver.assignment) {
      mapDriver.feature = this.getDriverMarker(driver.assignment, driver, 'driver');
      this.driversSource.addFeature(mapDriver.feature);
    }
  }

  /**
   * Удаление списка водителей с карты
   * @param drivers Список удаляемых водителей
   */
  private onHideDrivers = (drivers: IClientDriver[]) => {
    for (const driver of drivers) {
      const mapDriver = this.mapDrivers.find((d) => d.driver === driver);
      if (mapDriver) {
        if (mapDriver.feature) {
          this.driversSource.removeFeature(mapDriver.feature);
        }
        const index = this.mapDrivers.indexOf(mapDriver);
        this.mapDrivers.splice(index, 1);
      }
    }
  }

  /**
   * Отображение списка прицепов
   * @param trailers Список прицепов для отображения
   */
  private onShowTrailers = (trailers: IClientTrailer[]) => {
    for (const trailer of trailers) {
      this.showTrailer(trailer);
    }
  }

  /**
   * Отображение прицепа на карте
   * @param trailer Прицеп
   */
  private showTrailer = (trailer: IClientTrailer) => {
    let mapTrailer = this.mapTrailers.find((t) => t.trailer === trailer);
    if (mapTrailer && mapTrailer.feature) {
      this.trailersSource.removeFeature(mapTrailer.feature);
      mapTrailer.feature = null;
    } else {
      mapTrailer = { trailer, feature: null };
      this.mapTrailers.push(mapTrailer);
    }

    if (trailer.assignment) {
      mapTrailer.feature = this.getTrailerMarker(trailer.assignment, trailer, 'trailer');
      this.trailersSource.addFeature(mapTrailer.feature);
    }
  }

  /**
   * Удаление прицепов с карты
   * @param trailers Список удаляемых прицепов
   */
  private onHideTrailers = (trailers: IClientTrailer[]) => {
    for (const trailer of trailers) {
      const mapTrailer = this.mapTrailers.find((t) => t.trailer === trailer);
      if (mapTrailer) {
        if (mapTrailer.feature) {
          this.trailersSource.removeFeature(mapTrailer.feature);
        }
        const index = this.mapTrailers.indexOf(mapTrailer);
        this.mapTrailers.splice(index, 1);
      }
    }
  }

  /**
   * Добавление на карту детальной информации по элементу отчета
   * @param e Данные события движения мыши по карте
   * @param features Элементы карты, связанные с точками отчета
   */
  private addReportDetail(e, features: Feature[]) {
    if (!(features && features.length)) {
      return;
    }

    const details: IMapReportDetails = {
      name: null,
      objectId: null,
      point: null,
      table: null,
      charts: null
    };

    const intervalPoint = features.find((f) => f.get('reportIntervalPoint'));
    const interval = features.find((f) => f.get('reportInterval'));
    const point = features.find((f) => f.get('reportPoint'));
    const racePoint = features.find((f) => f.get('reportRacePoint'));
    const tFeature = intervalPoint ? intervalPoint : interval;
    const cFeature = point ? point : racePoint;

    if (tFeature) {
      const detail: IMapReportDetails = tFeature.get('reportDetail');
      details.name = detail.name;
      details.objectId = detail.objectId;
      details.point = detail.point;
      details.table = detail.table;
    }

    if (cFeature) {
      const detail: IMapReportDetails = cFeature.get('reportDetail');
      if (!details.objectId || details.objectId === detail.objectId) {
        details.name = detail.name;
        details.objectId = detail.objectId;
        details.point = details.point ? details.point : detail.point;
        details.charts = detail.charts;
      }
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MapReportDetailComponent);
    const viewContainerRef = this.mapDetailHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.details = details;

    const element = document.getElementsByTagName('map-report-detail')[0] as HTMLElement;
    element.style.display = 'block';
    const overlay = new Overlay({
      stopEvent: false,
      element,
      position: e.coordinate,
      positioning: this.getDetailPositioning(e)
    });

    this.reportDetailOverlay = overlay;
    this.map.addOverlay(overlay);
  }

  /**
   * Обработка события сокрытия точки сообщения
   */
  private onHideMessagePoint = () => {
    if (this.messagePointFeature) {
      this.messagesSource.removeFeature(this.messagePointFeature);
      this.messagePointFeature = null;
    }
  }

  /**
   * Обработка события отображения точки сообщения
   * @param data Информация по точке сообщения
   */
  private onShowMessagePoint = (data: IMapMessagePointInfo) => {
    this.onHideMessagePoint()

    const messageFeature = this.getMessageMarker(data.message, this.strings['component.map.message-point'], 'marker1');

    this.messagesSource.addFeature(messageFeature);
    this.messagePointFeature = messageFeature;
    this.moveToPoint(data.message);
  }

  /**
   * Отображение маршрута
   * @param route Маршрут
   */
  private onShowRoute = (route: IMapRoute) => {
    if (!route.points.length || this.routes.some((r) => r.id === route.id)) {
      return;
    }
    this.routes.push({ id: route.id, pointIds: route.points.map((p) => p.id) });
    this.showRouteGeozone(route.points[0], 0);
    for (let i = 1; i < route.points.length; i++) {
      this.showRouteGeozone(route.points[i], i);
      this.showPathBetweenRouteGeozones(route.points[i].id, route.points[i - 1].id);
    }
  }

  /**
   * Удаление маршрута
   * @param id Идентификатор маршрута
   */
  private onHideRoute = (id: string) => {
    const index = this.routes.findIndex((r) => r.id === id);
    if (index === -1) {
      return;
    }
    this.hideRouteGeozone(this.routes[index].pointIds[0]);
    for (let i = 1; i < this.routes[index].pointIds.length; i++) {
      this.hideRouteGeozone(this.routes[index].pointIds[i]);
      this.hidePathBetweenRouteGeozones(this.routes[index].pointIds[i], this.routes[index].pointIds[i - 1]);
    }
    this.routes.splice(index, 1);
  }

  /**
   * Обработка удаления детальной информации по адресу
   */
  private onRemoveAddressDetail = () => {
    if (this.addressDetail) {
      this.map.removeOverlay(this.addressDetail.overlay);
      this.addressDetail = null;
    }
  }

  /**
   * Центрирование карты на ТС
   * @param unit ТС
   */
  private focusOnUnit = (unit: TrackingUnit) => {
    if (round(unit.position?.ln + unit.position?.lt, 1) === 0) return;
    this.view.setCenter(fromLonLat([unit.position.ln, unit.position.lt]))
  };

  /**
   * Очистка данных отчета
   */
  private onClearReportInfo = () => {
    this.clearReportPoint();

    for (const interval of this.reportIntervals) {
      for (const feature of interval.features) {
        this.reportSource.removeFeature(feature);
      }
    }
    this.reportIntervals = [];

    this.reportRaceFeatures.forEach((f) => this.reportSource.removeFeature(f));
    this.reportRaceFeatures = [];
  }

  /**
   * Отображение рейсов отчета
   */
  private onShowReportRaces = () => {
    this.reportsService.reportRaces.forEach((race) => {
      if (!race?.p?.length) {
        return;
      }

      const startFeature = this.getReportRaceMarkers(race, false);
      this.reportRaceFeatures.push(startFeature);
      this.reportSource.addFeature(startFeature);

      const finishFeature = this.getReportRaceMarkers(race, true);
      this.reportRaceFeatures.push(finishFeature);
      this.reportSource.addFeature(finishFeature);

      for (const segment of race.s) {
        const rgb = hexToRgb(segment.c);
        const points = race.p?.slice(segment.s, segment.e + 1).filter((p) => !p.h);

        for (const point of points) {
          const pointStyle = new Style({
            stroke: new Stroke({
              color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 1)`,
              width: 6
            })
          });
          const reportDetail: IMapReportDetails = {
            name: race.n,
            objectId: race.i,
            point
          };
          const pointFeature = new Feature({
            geometry: new Circle(fromLonLat([point.ln, point.lt])),
            name: race.n
          });
          pointFeature.setStyle(pointStyle);
          pointFeature.set('reportDetail', reportDetail);
          pointFeature.set('reportRacePoint', true);

          this.reportRaceFeatures.push(pointFeature);
          this.reportSource.addFeature(pointFeature);
        }

        const lineStyle = new Style({
          stroke: new Stroke({
            color: `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.7)`,
            width: 4
          })
        });
        const lineFeature = new Feature({
          geometry: new LineString(points.map((p) => fromLonLat([p.ln, p.lt]))),
          name: race.n
        });
        lineFeature.setStyle(lineStyle);

        this.reportRaceFeatures.push(lineFeature);
        this.reportSource.addFeature(lineFeature);
      }

      const reportRacesExtent = this.reportSource.getExtent();
      if (!isEmpty(reportRacesExtent)) {
        this.map.getView().fit(reportRacesExtent);
      }
    });
  }

  /**
   * Отображение на карте интервалов отчета
   * @param intervals Список интервалов
   */
  private onShowReportIntervals = (intervals: IMapReportInterval[]) => {
    let extent: [number, number, number, number] = null;

    intervals.forEach((interval) => {
      if (!interval.p.length || this.reportIntervals.some((i) => i.r.i === interval.r.i)) {
        return;
      }
      this.reportIntervals.push(interval);

      const startFeature = this.getReportIntervalMarkers(interval, false);
      interval.features.push(startFeature);
      this.reportSource.addFeature(startFeature);

      const finishFeature = this.getReportIntervalMarkers(interval, true);
      interval.features.push(finishFeature);
      this.reportSource.addFeature(finishFeature);

      const points = interval.p
        .filter((p) => !p.h)
        .map((p) => fromLonLat([p.ln, p.lt]));

      const style = new Style({
        stroke: new Stroke({
          color: `rgba(255, 0, 0, 0.7)`,
          width: 6
        })
      });
      const reportDetail: IMapReportDetails = {
        name: interval.n,
        objectId: interval.r.o,
        table: interval.table
      };
      const lineFeature = new Feature({ geometry: new LineString(points) });
      lineFeature.setStyle(style);
      lineFeature.set('reportDetail', reportDetail);
      lineFeature.set('reportInterval', true);

      interval.features.push(lineFeature);
      this.reportSource.addFeature(lineFeature);

      const newExtent = lineFeature.getGeometry().getExtent();

      if (!extent) {
        extent = newExtent;
      } else {
        if (newExtent[0] < extent[0]) {
          extent[0] = newExtent[0];
        }
        if (newExtent[1] < extent[1]) {
          extent[1] = newExtent[1];
        }
        if (newExtent[2] > extent[2]) {
          extent[2] = newExtent[2];
        }
        if (newExtent[3] > extent[3]) {
          extent[3] = newExtent[3];
        }
      }
    });

    if (!isEmpty(extent)) {
      this.map.getView().fit(extent);
    }
  }

  /**
   * Удаление интервалов с карты
   * @param rows Список идентификаторов строк, интервалы по которым необходимо скрыть
   */
  private onHideReportIntervals = (rows: string[]) => {
    rows.forEach((id) => {
      this.reportIntervals = this.reportIntervals.filter((i) => {
        if (i.r.i !== id) {
          return true;
        }
        i.features.forEach((f) => this.reportSource.removeFeature(f));
        return false;
      });
    });
  }

  /**
   * Отображение точки из отчета на карте
   * @param details Информация по точке отчета
   */
  private onShowReportPoint = (details: IMapReportDetails) => {
    this.clearReportPoint();

    const feature = new Feature({
      geometry: new Point(fromLonLat([details.point.ln, details.point.lt])),
      name: this.translator.instant('component.map.race-point'),
      population: 4000,
      rainfall: 500
    });

    const style = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        src: `/assets/images/marker1.png`
      })
    });
    feature.setStyle(style);

    feature.set('reportDetail', details);
    feature.set('reportPoint', true);

    this.reportPointFeature = feature;
    this.reportSource.addFeature(feature);
    this.moveToPoint(details.point);
  }

  /**
   * Очистка маркера точки отчёта
   */
  private clearReportPoint() {
    if (this.reportPointFeature) {
      this.reportSource.removeFeature(this.reportPointFeature);
      this.reportPointFeature = null;
    }
  }

  /**
   * Получение маркера для отображения точки сообщения
   * @param point Точка сообщения
   * @param name Наименование точки
   * @param image Иконка маркера
   */
  private getMessageMarker(point: IMessage, name: string, image: string) {
    const feature = new Feature({
      geometry: new Point(fromLonLat([point.ln, point.lt])),
      name,
      population: 4000,
      rainfall: 500
    });
    const style = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        src: `/assets/images/${image}.png`
      })
    });
    feature.setStyle(style);
    return feature;
  }

  /**
   * Получение маркера водителя для отображения точки на карте
   * @param point Точка, в которой находился водитель в последний раз
   * @param driver Водитель
   * @param image Название иконки маркера
   */
  private getDriverMarker(point: ICoord, driver: IClientDriver, image: string) {
    const feature = new Feature({
      geometry: new Point(fromLonLat([point.ln, point.lt])),
      name,
      population: 4000,
      rainfall: 500
    });
    feature.set('driver', driver);
    const style = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        src: `/assets/images/${image}.png`
      })
    });
    if (this.store.user && this.store.user.settings.showDriverNames) {
      const text = new Text({
        text: driver.name,
        offsetY: -42,
        // tslint:disable-next-line:max-line-length
        font: '14px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        stroke: new Stroke({ color: '#fff', width: 4 })
      });
      style.setText(text);
    }

    feature.setStyle(style);

    return feature;
  }

  /**
   * Получение маркера прицепа для отображения точки на карте
   * @param point Точка, в которой находился прицеп в последний раз
   * @param trailer Прицеп
   * @param image Название иконки маркера
   */
  private getTrailerMarker(point: ICoord, trailer: IClientTrailer, image: string) {
    const feature = new Feature({
      geometry: new Point(fromLonLat([point.ln, point.lt])),
      name,
      population: 4000,
      rainfall: 500
    });

    feature.set('trailer', trailer);

    const style = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        opacity: 1,
        src: `/assets/images/${image}.png`
      })
    });

    if (this.store.user && this.store.user.settings.showTrailerNames) {
      const text = new Text({
        text: trailer.name,
        offsetY: -22,
        // tslint:disable-next-line:max-line-length
        font: '14px -apple-system,BlinkMacSystemFont,Segoe WPC,Segoe UI,HelveticaNeue-Light,Ubuntu,Droid Sans,sans-serif',
        stroke: new Stroke({ color: '#fff', width: 4 })
      });
      style.setText(text);
    }

    feature.setStyle(style);

    return feature;
  }

  /**
   * Установка источника карты
   * @param type Тип источника карты
   */
  private selectMapSource(type: MapSourceType) {
    this.mapSource = type;
    if (this.gmap) {
      this.gmap = null;
    }

    if (this.ymap) {
      this.ymap.destroy();
      this.ymap = null;
    }

    if (type === MapSourceType.OSM) {
      document.getElementById('customMap').innerHTML = '';
      this.osmLayer.setVisible(true);
      return;
    }

    const customMap = document.getElementById('customMap');
    customMap.innerHTML = '';

    const center = this.view.getCenter() || fromLonLat([53.224853, 56.852023]);
    const [lon, lat] = transform(center, 'EPSG:3857', 'EPSG:4326');

    this.osmLayer.setVisible(false);
    switch (type) {
      case MapSourceType.GOOGLE_SCHEMA:
      case MapSourceType.GOOGLE_SATELLITE:
      case MapSourceType.GOOGLE_HYBRID:
        this.initGoogleMap(type, customMap, lon, lat);
        break;
      case MapSourceType.YANDEX_SCHEMA:
      case MapSourceType.YANDEX_SATELLITE:
      case MapSourceType.YANDEX_HYBRID:
        this.initYandexMap(type, customMap, lon, lat);
        break;
    }
  }

  /**
   * Инициализация карт Yandex
   * @param type - Тип
   * @param customMap - Контейнер для карты в ДОМ
   * @param lon - lon
   * @param lat - lat
   */
  private initYandexMap(type, customMap, lon, lat) {
    this.mapService.initYandexMap$.subscribe((isLoad) => {
      if (!isLoad) return;

      let ymapType: string;
      switch (type) {
        case MapSourceType.YANDEX_SCHEMA:
          ymapType = 'yandex#map';
          break;
        case MapSourceType.YANDEX_SATELLITE:
          ymapType = 'yandex#satellite';
          break;
        case MapSourceType.YANDEX_HYBRID:
          ymapType = 'yandex#hybrid';
          break;
      }

      ymaps.ready(() => {
        this.ymap = new ymaps.Map(customMap, {
          center: [lat, lon],
          zoom: this.view.getZoom(),
          type: ymapType as any,
          controls: []
        });
      }).then();
    })
  }

  /**
   * Инициализация карт Google
   * @param type - Тип
   * @param customMap - Контейнер для карты в ДОМ
   * @param lon - lon
   * @param lat - lat
   */
  private initGoogleMap(type, customMap, lon, lat) {
    this.mapService.initGoogleMap$.subscribe((isLoad) => {
      if (!isLoad) return;

      let gmapType: google.maps.MapTypeId;
      switch (type) {
        case MapSourceType.GOOGLE_SCHEMA:
          gmapType = google.maps.MapTypeId.ROADMAP;
          break;
        case MapSourceType.GOOGLE_SATELLITE:
          gmapType = google.maps.MapTypeId.SATELLITE;
          break;
        case MapSourceType.GOOGLE_HYBRID:
          gmapType = google.maps.MapTypeId.HYBRID;
          break;
      }

      this.gmap = new google.maps.Map(customMap, {
        disableDefaultUI: true,
        keyboardShortcuts: false,
        draggable: false,
        disableDoubleClickZoom: true,
        scrollwheel: false,
        streetViewControl: false,
        center: new google.maps.LatLng(lat, lon),
        zoom: this.view.getZoom(),
        mapTypeId: gmapType
      } as google.maps.MapOptions);
    })
  }

  /**
   * Получение географического примитива геозоны
   * @param geozone Геозона
   */
  private getGeozoneFeature(geozone: IClientGeozone) {
    switch (geozone.type) {
      case GeozoneType.POLYGON:
        return this.createGeozonePolygon(geozone);
      case GeozoneType.LINE:
        return this.createGeozoneLine(geozone);
      case GeozoneType.CIRCLE:
        return this.createGeozoneCirclePolygon(geozone);
      default:
        return null;
    }
  }

  /**
   * Получение центра отображаемой геозоны
   * @param geozone Отображаемая геозона
   */
  private getGeozoneCenter(geozone: IMapObject<IClientGeozone>): number[] {
    if (geozone.obj.type === GeozoneType.CIRCLE) {
      return [geozone.obj.points[0].ln, geozone.obj.points[0].lt];
    } else {
      const extent = geozone.feature.getGeometry().getExtent();
      return toLonLat(getCenter(extent));
    }
  }

  /**
   * Обработка события печати карты
   * @param sub Подписчик (для возвращения результата)
   */
  private onPrint = (sub: Subscriber<string>) => {
    if (!this.map) {
      sub.complete();
      return;
    }

    const images: IImage[] = [];

    if (this.gmap) {
      this.extractGMap(images);
    }

    if (this.ymap) {
      this.extractYMap();
    }

    const viewport = this.map.getViewport() as HTMLElement;
    this.extractOl(images, viewport);

    const canvas = document.createElement('canvas');
    canvas.width = viewport.offsetWidth;
    canvas.height = viewport.offsetHeight;
    const context = canvas.getContext('2d');

    Promise.all(images.map((i) => this.getImage(i)))
      .then((imgs: HTMLImageElement[]) => {
        imgs.forEach(((img, i) => context.drawImage(img, images[i].x, images[i].y)));
        sub.next(canvas.toDataURL());
        sub.complete();
      }).catch((e) => sub.error(e));
  }

  /**
   * Извлечение карты OL
   * @param images Массив изображений
   * @param container Контейнер карты OL
   */
  private extractOl(images: IImage[], container: HTMLElement) {
    const elements = container.getElementsByTagName('canvas');

    if (!elements.length) {
      return;
    }

    const canvas = elements[0];
    images.push({ src: canvas.toDataURL(), x: canvas.offsetLeft, y: canvas.offsetTop });
  }

  /**
   * Выковыривание тайлов гуглокарт
   * @param images Массив изображений
   */
  private extractGMap(images: IImage[]) {
    const gmap = this.gmap.getDiv() as HTMLElement;
    const gmapRect = gmap.getBoundingClientRect();
    const imgs = gmap
      .firstElementChild
      .firstElementChild
      .firstElementChild
      .firstElementChild
      .lastElementChild
      .firstElementChild
      .getElementsByTagName('img');

    for (let i = 0, l = imgs.length; i < l; i++) {
      const img = imgs[i];
      const imgRect = img.getBoundingClientRect();
      const x = imgRect.left - gmapRect.left;
      const y = imgRect.top - gmapRect.top;
      images.push({ src: img.src, x, y });
    }
  }

  /**
   * Выковыривание всей яндекс карты на печать
   * Костыльвания, лучше вообще выпилить якарты или купить их
   */
  private extractYMap() {
    const div = document.createElement('div');
    div.style.position = 'relative';
    div.style.top = '0';
    div.style.left = '0';
    div.style.height = '0';
    div.style.height = '0';
    div.style.width = '0';
    const element = this.ymap.container.getElement();
    div.appendChild(element);
    document.getElementById('print').appendChild(div);
  }

  /**
   * Получение изображения для добавления в контекст холста
   * @param image Изображение
   */
  private getImage = (image: IImage) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = image.src;
    })

  /**
   * Обновление слоя карт (костыль для печати яндекс карт)
   */
  private refreshMap = () => {
    if (this.mapSource === MapSourceType.YANDEX_HYBRID
      || this.mapSource === MapSourceType.YANDEX_SATELLITE
      || this.mapSource === MapSourceType.YANDEX_SCHEMA) {
      this.selectMapSource(this.mapSource);
    }
  }
}

/**
 * Интерфейс обёртка для изображений
 */
interface IImage {

  /**
   * Источник изображения
   */
  src: string;

  /**
   * Отступ по оси x
   */
  x: number;

  /**
   * Отступ по оси y
   */
  y: number;
}

/**
 * Интерфейс отображаемого на карте объекта
 */
interface IMapObject<T> {
  /** Отображаемая сущность */
  obj: T;
  /** Географический примитив */
  feature: Feature;
  /** Количество упоминаний на карте */
  amount: number;
}

/**
 * Интервал отчета для использования в компоненте карты
 */
export interface IMapReportInterval extends IReportInterval {
  /** Информация о таблице в точке */
  table: IPointTable;
  /** Список элементов карты, соответствующих интервалу отчета */
  features: Feature[];
}

/**
 * Информация о геозоне на время операций над ней на карте
 */
interface IGeozoneDraw {
  /** Геозона */
  geozone: IClientGeozone;
  /** Фича рисования геозоны */
  draw: Draw;
  /** Фича изменения геозоны */
  modify: Modify;
  /** Фича прилипания курсора к точкам геозоны (вроде как) */
  snap: Snap;
}

/**
 * Маркер объекта рейса на карте
 */
interface IRaceMarker {
  /** Рейс */
  race: IClientRace;
  /** Объект на карте, представляющий маркер */
  feature: Feature;
  /** Источник слоя, в котором находится объект */
  source: VectorSource;
  /** Точка рейса */
  point: IRacePoint;
}

/**
 * Тип линеек
 */
export enum MeasureType {
  LINE,
  POLYGON
}
