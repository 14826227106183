import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IListItem } from '../classes/IListItem';
import { SelectionType } from '../components/select-items/select-items.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  public openSubject = new Subject<ISelectItemsComponent>();
}

/**
 * Интерфейс компонента выбора объектов мониторинга
 */
export interface ISelectItemsComponent {
  /** Список элементов */
  items: IListItem<string>[];
  /** Выбраные элементы */
  selected: IListItem<string>[];
  /** Заголовок */
  title: string;
  /** Тип ограничения выбора объектов */
  selection?: SelectionType;
  /** Объект с результатом от календаря */
  result: Subject<IListItem<string>[]>;
}
