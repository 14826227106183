import { IReportTableColumn } from './IReportTableColumn';
import { ReportTableColumnType } from './ReportTableColumnType';
import { ReportTableType } from './ReportTableType';

/**
 * Получение списка колонок, доступных для таблицы отчета
 * @param type Тип таблицы отчета
 * @param isScavenger Признак мусоровозов
 */
export function getReportTableColumns(type: ReportTableType, isScavenger: boolean): IReportTableColumn[] {
  switch (type) {
    case ReportTableType.GEOZONES:
      return getGeozonesTableColumns(isScavenger);
    case ReportTableType.COUNTERS:
      return getCountersTableColumns();
    case ReportTableType.FUEL:
      return getFuelTableColumns();
    case ReportTableType.FILLINGS:
      return getFillingsTableColumns();
    case ReportTableType.MHS:
      return getMhTableColumns();
    case ReportTableType.STOPS:
      return getStopsTableColumns();
    case ReportTableType.RACES:
      return getRacesTableColumns();
    case ReportTableType.SPEEDING:
      return getSpeedingTableColumns();
    case ReportTableType.SUMMARY:
      return getSummaryTableColumns();
    case ReportTableType.THEFTS:
      return getTheftsTableColumns();
    case ReportTableType.PARKINGS:
      return getParkingsTableColumns();
    case ReportTableType.DIGITAL_SENSORS:
      return getDigitalSensorsTableColumns();
    case ReportTableType.FUEL_BY_CHECK:
      return getFuelByCheckTableColumns();
    case ReportTableType.EVENTS:
      return getEventsTableColumns();
    case ReportTableType.VIOLATIONS:
      return getEventsTableColumns();
    case ReportTableType.DRIVING_QUALITY:
      return getDrivingQualityTableColumns();
    case ReportTableType.MAINTENANCE:
      return getMaintenanceTableColumns();
    case ReportTableType.LOADINGS:
      return getLoadingsTableColumns();
    case ReportTableType.CONNECTION_PROBLEMS:
      return getLostConnectionTableColumns();
    case ReportTableType.ASSIGNMENTS:
      return getAssignmentsTableColumns();
    case ReportTableType.USER_ACTIVITY:
      return getUserActivityTableColumns();
    default:
      return [];
  }
}

/**
 * Получение списка колонок, доступных для таблицы "Геозоны"
 */
function getGeozonesTableColumns(isScavenger: boolean): IReportTableColumn[] {
  // Это костыль для помоек
  const costil = [
    { name: 'enums.reports.tables.columns.geozones.geozone-name', type: ReportTableColumnType.GEOZONE_NAME },
    { name: 'enums.reports.tables.columns.geozones.geozone-type', type: ReportTableColumnType.GEOZONE_TYPE },
    { name: 'enums.reports.tables.columns.geozones.geozone-square', type: ReportTableColumnType.GEOZONE_SQUARE },
    { name: 'enums.reports.tables.columns.geozones.geozone-descr', type: ReportTableColumnType.GEOZONE_DESCR },
    { name: 'enums.reports.tables.columns.geozones.geozone-center', type: ReportTableColumnType.GEOZONE_CENTER },
    { name: 'enums.reports.tables.columns.geozones.geozone-address', type: ReportTableColumnType.GEOZONE_POS },
    { name: 'enums.reports.tables.columns.geozones.geozone-extcode', type: ReportTableColumnType.GEOZONE_EXTCODE },
    { name: 'enums.reports.tables.columns.geozones.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.geozones.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.geozones.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.geozones.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.geozones.time-parking', type: ReportTableColumnType.TIME_PARKING },
    { name: 'enums.reports.tables.columns.geozones.time-parking-prev', type: ReportTableColumnType.TIME_PARKING_PREV },
    { name: 'enums.reports.tables.columns.geozones.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.geozones.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.geozones.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.geozones.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.geozones.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.geozones.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.geozones.engine-speed-avg', type: ReportTableColumnType.ENGINE_SPEED_AVG },
    { name: 'enums.reports.tables.columns.geozones.engine-speed-max', type: ReportTableColumnType.ENGINE_SPEED_MAX },
    { name: 'enums.reports.tables.columns.geozones.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.geozones.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.geozones.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.geozones.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.geozones.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.geozones.mileage-prev', type: ReportTableColumnType.MILEAGE_PREV },
    { name: 'enums.reports.tables.columns.geozones.mileage-prev-corrected', type: ReportTableColumnType.MILEAGE_PREV_CORRECTED },
    { name: 'enums.reports.tables.columns.geozones.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.geozones.speed-avg-prev', type: ReportTableColumnType.SPEED_AVG_PREV },
    { name: 'enums.reports.tables.columns.geozones.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.geozones.speed-max-prev', type: ReportTableColumnType.SPEED_MAX_PREV },
    { name: 'enums.reports.tables.columns.geozones.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.geozones.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.geozones.geozone-visits', type: ReportTableColumnType.GEOZONE_VISITS },
    { name: 'enums.reports.tables.columns.geozones.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.geozones.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.geozones.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.geozones.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.geozones.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.geozones.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.geozones.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.geozones.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.geozones.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];

  if (isScavenger) {
    costil.push({ name: 'enums.reports.tables.columns.geozones.garbage-type', type: ReportTableColumnType.GARBAGE_VOL })
  }

  return costil;
}

/**
 * Получение списка колонок, доступных для таблицы "Счетчики"
 */
function getCountersTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.counters.sensor-name', type: ReportTableColumnType.SENSOR_NAME },
    { name: 'enums.reports.tables.columns.counters.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.counters.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.counters.move-start', type: ReportTableColumnType.MOVE_START },
    { name: 'enums.reports.tables.columns.counters.move-end', type: ReportTableColumnType.MOVE_END },
    { name: 'enums.reports.tables.columns.counters.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.counters.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.counters.pos', type: ReportTableColumnType.POS },
    { name: 'enums.reports.tables.columns.counters.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.counters.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.counters.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.counters.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.counters.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.counters.intersect', type: ReportTableColumnType.INTERSECT },
    { name: 'enums.reports.tables.columns.counters.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.counters.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.counters.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.counters.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.counters.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Движение топлива"
 */
function getFuelTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.fuel.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.fuel.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.fuel.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.fuel.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.fuel.fuel-event-type', type: ReportTableColumnType.FUEL_EVENT_TYPE },
    { name: 'enums.reports.tables.columns.fuel.fuel-vol', type: ReportTableColumnType.FUEL_VOL },
    { name: 'enums.reports.tables.columns.fuel.sensor-name', type: ReportTableColumnType.SENSOR_NAME },
    { name: 'enums.reports.tables.columns.fuel.intersect', type: ReportTableColumnType.INTERSECT },
    { name: 'enums.reports.tables.columns.fuel.intersect-fuel', type: ReportTableColumnType.INTERSECT_FUEL },
    { name: 'enums.reports.tables.columns.fuel.fuel-dif', type: ReportTableColumnType.FUEL_DIF },
    { name: 'enums.reports.tables.columns.fuel.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Заправки"
 */
function getFillingsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.fillings.time', type: ReportTableColumnType.TIME },
    { name: 'enums.reports.tables.columns.fillings.pos', type: ReportTableColumnType.POS },
    { name: 'enums.reports.tables.columns.fillings.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.fillings.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.fillings.filling', type: ReportTableColumnType.FILLING },
    { name: 'enums.reports.tables.columns.fillings.filling-reg', type: ReportTableColumnType.FILLING_REG },
    { name: 'enums.reports.tables.columns.fillings.fuel-dif', type: ReportTableColumnType.FUEL_DIF },
    { name: 'enums.reports.tables.columns.fillings.filling-reg-descr', type: ReportTableColumnType.FILLING_REG_DESCR },
    { name: 'enums.reports.tables.columns.fillings.sensor-name', type: ReportTableColumnType.SENSOR_NAME },
    { name: 'enums.reports.tables.columns.fillings.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.fillings.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.fillings.filling-count', type: ReportTableColumnType.FILLING_COUNT },
    { name: 'enums.reports.tables.columns.fillings.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.fillings.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.fillings.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Моточасы"
 */
function getMhTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.mhs.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.mhs.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.mhs.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.mhs.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.mhs.move-start', type: ReportTableColumnType.MOVE_START },
    { name: 'enums.reports.tables.columns.mhs.move-end', type: ReportTableColumnType.MOVE_END },
    { name: 'enums.reports.tables.columns.mhs.mh', type: ReportTableColumnType.MH },
    { name: 'enums.reports.tables.columns.mhs.mh-start', type: ReportTableColumnType.MH_START },
    { name: 'enums.reports.tables.columns.mhs.mh-end', type: ReportTableColumnType.MH_END },
    { name: 'enums.reports.tables.columns.mhs.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.mhs.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.mhs.time-move', type: ReportTableColumnType.TIME_MOVE },
    { name: 'enums.reports.tables.columns.mhs.time-idle', type: ReportTableColumnType.TIME_IDLE },
    { name: 'enums.reports.tables.columns.mhs.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.mhs.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.mhs.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.mhs.mileage-end', type: ReportTableColumnType.MILEAGE_END },
    { name: 'enums.reports.tables.columns.mhs.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.mhs.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.mhs.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.mhs.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.mhs.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.mhs.engine-speed-avg', type: ReportTableColumnType.ENGINE_SPEED_AVG },
    { name: 'enums.reports.tables.columns.mhs.engine-speed-max', type: ReportTableColumnType.ENGINE_SPEED_MAX },
    { name: 'enums.reports.tables.columns.mhs.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.mhs.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.mhs.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.mhs.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.mhs.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.mhs.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.mhs.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.mhs.mh-move-rpod', type: ReportTableColumnType.MH_MOVE_RPOD },
    { name: 'enums.reports.tables.columns.mhs.time-eng-prod', type: ReportTableColumnType.TIME_ENG_PROD },
    { name: 'enums.reports.tables.columns.mhs.time-eng-idle', type: ReportTableColumnType.TIME_ENG_IDLE },
    { name: 'enums.reports.tables.columns.mhs.mh-util', type: ReportTableColumnType.MH_UTIL },
    { name: 'enums.reports.tables.columns.mhs.mh-util-prod', type: ReportTableColumnType.MH_UTIL_PROD },
    { name: 'enums.reports.tables.columns.mhs.mh-prod', type: ReportTableColumnType.MH_PROD },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-dut-move', type: ReportTableColumnType.FUEL_SPENT_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-imp-move', type: ReportTableColumnType.FUEL_SPENT_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-abs-move', type: ReportTableColumnType.FUEL_SPENT_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-ins-move', type: ReportTableColumnType.FUEL_SPENT_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-move', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-dut-idle', type: ReportTableColumnType.FUEL_SPENT_BY_DUT_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-imp-idle', type: ReportTableColumnType.FUEL_SPENT_BY_IMP_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-abs-idle', type: ReportTableColumnType.FUEL_SPENT_BY_ABS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-ins-idle', type: ReportTableColumnType.FUEL_SPENT_BY_INS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-idle', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-dut-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-dut-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-imp-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-imp-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-abs-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-abs-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-ins-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-ins-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-dut-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-imp-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-abs-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-ins-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.mhs.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.mhs.fuel-min', type: ReportTableColumnType.FUEL_MIN },
    { name: 'enums.reports.tables.columns.mhs.fuel-max', type: ReportTableColumnType.FUEL_MAX },
    { name: 'enums.reports.tables.columns.mhs.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.mhs.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.mhs.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Цифровые датчики"
 */
function getDigitalSensorsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.digital-sensors.sensor-name', type: ReportTableColumnType.SENSOR_NAME },
    { name: 'enums.reports.tables.columns.digital-sensors.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.digital-sensors.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.digital-sensors.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.digital-sensors.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.digital-sensors.move-start', type: ReportTableColumnType.MOVE_START },
    { name: 'enums.reports.tables.columns.digital-sensors.move-end', type: ReportTableColumnType.MOVE_END },
    { name: 'enums.reports.tables.columns.digital-sensors.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.digital-sensors.mh', type: ReportTableColumnType.MH },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-start', type: ReportTableColumnType.MH_START },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-end', type: ReportTableColumnType.MH_END },
    { name: 'enums.reports.tables.columns.digital-sensors.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.digital-sensors.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.digital-sensors.time-move', type: ReportTableColumnType.TIME_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.time-idle', type: ReportTableColumnType.TIME_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.digital-sensors.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.digital-sensors.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.digital-sensors.mileage-end', type: ReportTableColumnType.MILEAGE_END },
    { name: 'enums.reports.tables.columns.digital-sensors.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.digital-sensors.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.digital-sensors.digital-sensor-on-count', type: ReportTableColumnType.DIGITAL_SENSOR_ON_COUNT },
    { name: 'enums.reports.tables.columns.digital-sensors.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.digital-sensors.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.digital-sensors.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.digital-sensors.engine-speed-avg', type: ReportTableColumnType.ENGINE_SPEED_AVG },
    { name: 'enums.reports.tables.columns.digital-sensors.engine-speed-max', type: ReportTableColumnType.ENGINE_SPEED_MAX },
    { name: 'enums.reports.tables.columns.digital-sensors.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.digital-sensors.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.digital-sensors.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.digital-sensors.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.digital-sensors.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.digital-sensors.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.digital-sensors.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-move-rpod', type: ReportTableColumnType.MH_MOVE_RPOD },
    { name: 'enums.reports.tables.columns.digital-sensors.time-eng-prod', type: ReportTableColumnType.TIME_ENG_PROD },
    { name: 'enums.reports.tables.columns.digital-sensors.time-eng-idle', type: ReportTableColumnType.TIME_ENG_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-util', type: ReportTableColumnType.MH_UTIL },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-util-prod', type: ReportTableColumnType.MH_UTIL_PROD },
    { name: 'enums.reports.tables.columns.digital-sensors.mh-prod', type: ReportTableColumnType.MH_PROD },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-dut-move', type: ReportTableColumnType.FUEL_SPENT_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-imp-move', type: ReportTableColumnType.FUEL_SPENT_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-abs-move', type: ReportTableColumnType.FUEL_SPENT_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-ins-move', type: ReportTableColumnType.FUEL_SPENT_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-move', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-dut-idle', type: ReportTableColumnType.FUEL_SPENT_BY_DUT_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-imp-idle', type: ReportTableColumnType.FUEL_SPENT_BY_IMP_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-abs-idle', type: ReportTableColumnType.FUEL_SPENT_BY_ABS_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-spent-by-ins-idle', type: ReportTableColumnType.FUEL_SPENT_BY_INS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-idle', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-dut-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-dut-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-imp-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-imp-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-abs-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-abs-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-ins-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-100km-by-ins-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm-move', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm-move', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS_MOVE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-dut-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-imp-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-abs-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-rate-avg-by-ins-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS_IDLE },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-min', type: ReportTableColumnType.FUEL_MIN },
    { name: 'enums.reports.tables.columns.digital-sensors.fuel-max', type: ReportTableColumnType.FUEL_MAX },
    { name: 'enums.reports.tables.columns.digital-sensors.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.digital-sensors.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.digital-sensors.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Остановки"
 */
function getStopsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.stops.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.stops.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.stops.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.stops.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.stops.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.stops.mileage-prev', type: ReportTableColumnType.MILEAGE_PREV },
    { name: 'enums.reports.tables.columns.stops.mileage-prev-corrected', type: ReportTableColumnType.MILEAGE_PREV_CORRECTED },
    { name: 'enums.reports.tables.columns.stops.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.stops.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.stops.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.stops.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.stops.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.stops.stop-count', type: ReportTableColumnType.STOP_COUNT },
    { name: 'enums.reports.tables.columns.stops.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.stops.weight-avg', type: ReportTableColumnType.WEIGHT_AVG },
    { name: 'enums.reports.tables.columns.stops.weight-min', type: ReportTableColumnType.WEIGHT_MIN },
    { name: 'enums.reports.tables.columns.stops.weight-max', type: ReportTableColumnType.WEIGHT_MAX },
    { name: 'enums.reports.tables.columns.stops.weight-start', type: ReportTableColumnType.WEIGHT_START },
    { name: 'enums.reports.tables.columns.stops.weight-end', type: ReportTableColumnType.WEIGHT_END },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Поездки"
 */
function getRacesTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.races.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.races.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.races.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.races.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.races.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.races.pos-end-coords', type: ReportTableColumnType.POS_END_COORDS },
    { name: 'enums.reports.tables.columns.races.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.races.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.races.passenger-count', type: ReportTableColumnType.PASSENGER_COUNT },
    { name: 'enums.reports.tables.columns.races.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.races.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.races.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.races.time-next', type: ReportTableColumnType.TIME_NEXT },
    { name: 'enums.reports.tables.columns.races.mh', type: ReportTableColumnType.MH },
    { name: 'enums.reports.tables.columns.races.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.races.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.races.mileage-city', type: ReportTableColumnType.MILEAGE_CITY },
    { name: 'enums.reports.tables.columns.races.mileage-highway', type: ReportTableColumnType.MILEAGE_HIGHWAY },
    { name: 'enums.reports.tables.columns.races.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.races.mileage-end', type: ReportTableColumnType.MILEAGE_END },
    { name: 'enums.reports.tables.columns.races.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.races.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.races.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.races.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.races.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.races.engine-speed-avg', type: ReportTableColumnType.ENGINE_SPEED_AVG },
    { name: 'enums.reports.tables.columns.races.engine-speed-max', type: ReportTableColumnType.ENGINE_SPEED_MAX },
    { name: 'enums.reports.tables.columns.races.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.races.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.races.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.races.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.races.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.races.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.races.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.races.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.races.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.races.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-dut-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT_IDLE },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-imp-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP_IDLE },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-abs-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS_IDLE },
    { name: 'enums.reports.tables.columns.races.fuel-rate-avg-by-ins-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS_IDLE },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm-idle', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS_IDLE },
    { name: 'enums.reports.tables.columns.races.fuel-mileage-avg-by-dut', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.races.fuel-mileage-avg-by-imp', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.races.fuel-mileage-avg-by-abs', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.races.fuel-mileage-avg-by-ins', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-mileage-avg-by-norm', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.races.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.races.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.races.fuel-min', type: ReportTableColumnType.FUEL_MIN },
    { name: 'enums.reports.tables.columns.races.fuel-max', type: ReportTableColumnType.FUEL_MAX },
    { name: 'enums.reports.tables.columns.races.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.races.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.races.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Превышения скорости"
 */
function getSpeedingTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.speeding.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.speeding.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.speeding.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.speeding.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.speeding.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.speeding.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.speeding.speed-limit', type: ReportTableColumnType.SPEED_LIMIT },
    { name: 'enums.reports.tables.columns.speeding.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.speeding.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.speeding.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.speeding.mileage-end', type: ReportTableColumnType.MILEAGE_END },
    { name: 'enums.reports.tables.columns.speeding.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.speeding.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.speeding.speeding-count', type: ReportTableColumnType.SPEEDING_COUNT },
    { name: 'enums.reports.tables.columns.speeding.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Сводка"
 */
function getSummaryTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.summary.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.summary.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.summary.move-start', type: ReportTableColumnType.MOVE_START },
    { name: 'enums.reports.tables.columns.summary.move-end', type: ReportTableColumnType.MOVE_END },
    { name: 'enums.reports.tables.columns.summary.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.summary.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.summary.mileage-move-detect', type: ReportTableColumnType.MILEAGE_MOVE_DETECT },
    { name: 'enums.reports.tables.columns.summary.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.summary.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.summary.time-move', type: ReportTableColumnType.TIME_MOVE },
    { name: 'enums.reports.tables.columns.summary.mh', type: ReportTableColumnType.MH },
    { name: 'enums.reports.tables.columns.summary.time-idle', type: ReportTableColumnType.TIME_IDLE },
    { name: 'enums.reports.tables.columns.summary.all-move-time', type: ReportTableColumnType.ALL_MOVE_TIME },
    { name: 'enums.reports.tables.columns.summary.stop-move-time', type: ReportTableColumnType.STOP_MOVE_TIME },
    { name: 'enums.reports.tables.columns.summary.move-pct', type: ReportTableColumnType.MOVE_PCT },
    { name: 'enums.reports.tables.columns.summary.stop-pct', type: ReportTableColumnType.STOP_PCT },
    { name: 'enums.reports.tables.columns.summary.time-eng-prod', type: ReportTableColumnType.TIME_ENG_PROD },
    { name: 'enums.reports.tables.columns.summary.time-parking', type: ReportTableColumnType.TIME_PARKING },
    { name: 'enums.reports.tables.columns.summary.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.summary.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.summary.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.summary.arb-sensor-start', type: ReportTableColumnType.ARB_SENSOR_START },
    { name: 'enums.reports.tables.columns.summary.arb-sensor-end', type: ReportTableColumnType.ARB_SENSOR_END },
    { name: 'enums.reports.tables.columns.summary.arb-sensor-dif', type: ReportTableColumnType.ARB_SENSOR_DIF },
    { name: 'enums.reports.tables.columns.summary.mh-util', type: ReportTableColumnType.MH_UTIL },
    { name: 'enums.reports.tables.columns.summary.mh-util-prod', type: ReportTableColumnType.MH_UTIL_PROD },
    { name: 'enums.reports.tables.columns.summary.mh-prod', type: ReportTableColumnType.MH_PROD },
    { name: 'enums.reports.tables.columns.summary.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.summary.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.summary.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.summary.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.summary.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.summary.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.summary.fuel-mileage-avg-by-dut', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.summary.fuel-mileage-avg-by-imp', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.summary.fuel-mileage-avg-by-abs', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.summary.fuel-mileage-avg-by-ins', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-mileage-avg-by-norm', type: ReportTableColumnType.FUEL_MILEAGE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.summary.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.summary.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.summary.filling-count', type: ReportTableColumnType.FILLING_COUNT },
    { name: 'enums.reports.tables.columns.summary.theft-count', type: ReportTableColumnType.THEFT_COUNT },
    { name: 'enums.reports.tables.columns.summary.filling', type: ReportTableColumnType.FILLING },
    { name: 'enums.reports.tables.columns.summary.theft', type: ReportTableColumnType.THEFT },
    { name: 'enums.reports.tables.columns.summary.fuel-norms-lp100km', type: ReportTableColumnType.FUEL_NORMS_LP100KM },
    { name: 'enums.reports.tables.columns.mhs.fuel-norm', type: ReportTableColumnType.FUEL_NORMS_LPH },
    { name: 'enums.reports.tables.columns.summary.fuel-norms-dif', type: ReportTableColumnType.FUEL_NORMS_DIF },
    { name: 'enums.reports.tables.columns.summary.fuel-norms-economy', type: ReportTableColumnType.FUEL_NORMS_ECONOMY },
    { name: 'enums.reports.tables.columns.summary.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.summary.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.mhs.fuel-price', type: ReportTableColumnType.FUEL_PRICE },
    { name: 'enums.reports.tables.columns.mhs.fuel-cost', type: ReportTableColumnType.FUEL_COST },
    { name: 'enums.reports.tables.columns.summary.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Сливы"
 */
function getTheftsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.thefts.time', type: ReportTableColumnType.TIME },
    { name: 'enums.reports.tables.columns.thefts.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.thefts.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.thefts.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.thefts.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.thefts.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.thefts.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.thefts.theft', type: ReportTableColumnType.THEFT },
    { name: 'enums.reports.tables.columns.thefts.speed-start', type: ReportTableColumnType.SPEED_START },
    { name: 'enums.reports.tables.columns.thefts.speed-end', type: ReportTableColumnType.SPEED_END },
    { name: 'enums.reports.tables.columns.thefts.sensor-name', type: ReportTableColumnType.SENSOR_NAME },
    { name: 'enums.reports.tables.columns.thefts.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.thefts.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.thefts.theft-count', type: ReportTableColumnType.THEFT_COUNT },
    { name: 'enums.reports.tables.columns.thefts.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.thefts.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.thefts.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Стоянки"
 */
function getParkingsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.parking.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.parking.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.parking.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.parking.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.parking.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.parking.mileage-prev', type: ReportTableColumnType.MILEAGE_PREV },
    { name: 'enums.reports.tables.columns.parking.mileage-prev-corrected', type: ReportTableColumnType.MILEAGE_PREV_CORRECTED },
    { name: 'enums.reports.tables.columns.parking.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.parking.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.parking.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.parking.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.parking.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.parking.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.parking.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.parking.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.parking.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.parking.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.parking.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.parking.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.parking.weight-avg', type: ReportTableColumnType.WEIGHT_AVG },
    { name: 'enums.reports.tables.columns.parking.weight-min', type: ReportTableColumnType.WEIGHT_MIN },
    { name: 'enums.reports.tables.columns.parking.weight-max', type: ReportTableColumnType.WEIGHT_MAX },
    { name: 'enums.reports.tables.columns.parking.weight-start', type: ReportTableColumnType.WEIGHT_START },
    { name: 'enums.reports.tables.columns.parking.weight-end', type: ReportTableColumnType.WEIGHT_END },
    { name: 'enums.reports.tables.columns.parking.parking-count', type: ReportTableColumnType.PARKING_COUNT },
    { name: 'enums.reports.tables.columns.parking.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Топливо по чекам"
 */
function getFuelByCheckTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.fuel-by-check.event-date', type: ReportTableColumnType.EVENT_DATE },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-mileage', type: ReportTableColumnType.CHECK_MILEAGE },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-value', type: ReportTableColumnType.CHECK_VALUE },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-mileage-prev', type: ReportTableColumnType.CHECK_MILEAGE_PREV },
    { name: 'enums.reports.tables.columns.fuel-by-check.mileage-prev', type: ReportTableColumnType.MILEAGE_PREV },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-dif', type: ReportTableColumnType.CHECK_DIF },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-fuel-rate-avg-100km', type: ReportTableColumnType.CHECK_FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.fuel-by-check.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.fuel-by-check.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.fuel-by-check.check-count', type: ReportTableColumnType.CHECK_COUNT },
    { name: 'enums.reports.tables.columns.fuel-by-check.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблиц "События" и "Нарушения"
 */
function getEventsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.events.event-date', type: ReportTableColumnType.EVENT_DATE },
    { name: 'enums.reports.tables.columns.events.event-description', type: ReportTableColumnType.EVENT_DESCRIPTION },
    { name: 'enums.reports.tables.columns.events.event-count', type: ReportTableColumnType.EVENT_COUNT },
    { name: 'enums.reports.tables.columns.events.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.events.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.events.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Качество вождения"
 */
function getDrivingQualityTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.driving-quality.dq-name', type: ReportTableColumnType.DQ_NAME },
    { name: 'enums.reports.tables.columns.driving-quality.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.driving-quality.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.driving-quality.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.driving-quality.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.driving-quality.dq-value', type: ReportTableColumnType.DQ_VALUE },
    { name: 'enums.reports.tables.columns.driving-quality.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.driving-quality.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.driving-quality.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.driving-quality.dq-score', type: ReportTableColumnType.DQ_SCORE },
    { name: 'enums.reports.tables.columns.driving-quality.rating', type: ReportTableColumnType.RATING },
    { name: 'enums.reports.tables.columns.driving-quality.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.driving-quality.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.driving-quality.mileage-start', type: ReportTableColumnType.MILEAGE_START },
    { name: 'enums.reports.tables.columns.driving-quality.mileage-end', type: ReportTableColumnType.MILEAGE_END },
    { name: 'enums.reports.tables.columns.driving-quality.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.driving-quality.dq-count', type: ReportTableColumnType.DQ_COUNT },
    { name: 'enums.reports.tables.columns.driving-quality.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.driving-quality.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.driving-quality.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Техобслуживание"
 */
function getMaintenanceTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.maintenance.event-date', type: ReportTableColumnType.EVENT_DATE },
    { name: 'enums.reports.tables.columns.maintenance.maintenance-work', type: ReportTableColumnType.MAINTENANCE_WORK },
    { name: 'enums.reports.tables.columns.maintenance.event-description', type: ReportTableColumnType.EVENT_DESCRIPTION },
    { name: 'enums.reports.tables.columns.maintenance.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.maintenance.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.maintenance.maintenance-duration', type: ReportTableColumnType.MAINTENANCE_DURATION },
    { name: 'enums.reports.tables.columns.maintenance.maintenance-cost', type: ReportTableColumnType.MAINTENANCE_COST },
    { name: 'enums.reports.tables.columns.maintenance.maintenance-mileage', type: ReportTableColumnType.MAINTENANCE_MILEAGE },
    { name: 'enums.reports.tables.columns.maintenance.maintenance-mh', type: ReportTableColumnType.MAINTENANCE_MH },
    { name: 'enums.reports.tables.columns.maintenance.event-count', type: ReportTableColumnType.EVENT_COUNT },
    { name: 'enums.reports.tables.columns.maintenance.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Назначения"
 */
function getAssignmentsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.assignments.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.assignments.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.assignments.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.assignments.pos-end', type: ReportTableColumnType.POS_END },
    { name: 'enums.reports.tables.columns.assignments.assignments-duration', type: ReportTableColumnType.ASSIGNMENTS_DURATION },
    { name: 'enums.reports.tables.columns.assignments.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.assignments.mh', type: ReportTableColumnType.MH },
    { name: 'enums.reports.tables.columns.assignments.time-move', type: ReportTableColumnType.TIME_MOVE },
    { name: 'enums.reports.tables.columns.assignments.time-idle', type: ReportTableColumnType.TIME_IDLE },
    { name: 'enums.reports.tables.columns.assignments.mileage', type: ReportTableColumnType.MILEAGE },
    { name: 'enums.reports.tables.columns.assignments.mileage-corrected', type: ReportTableColumnType.MILEAGE_CORRECTED },
    { name: 'enums.reports.tables.columns.assignments.mileage-city', type: ReportTableColumnType.MILEAGE_CITY },
    { name: 'enums.reports.tables.columns.assignments.mileage-highway', type: ReportTableColumnType.MILEAGE_HIGHWAY },
    { name: 'enums.reports.tables.columns.assignments.speed-avg', type: ReportTableColumnType.SPEED_AVG },
    { name: 'enums.reports.tables.columns.assignments.speed-max', type: ReportTableColumnType.SPEED_MAX },
    { name: 'enums.reports.tables.columns.assignments.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.assignments.assignments-count', type: ReportTableColumnType.ASSIGNMENTS_COUNT },
    { name: 'enums.reports.tables.columns.assignments.fuel-spent', type: ReportTableColumnType.FUEL_SPENT },
    { name: 'enums.reports.tables.columns.assignments.fuel-spent-by-dut', type: ReportTableColumnType.FUEL_SPENT_BY_DUT },
    { name: 'enums.reports.tables.columns.assignments.fuel-spent-by-imp', type: ReportTableColumnType.FUEL_SPENT_BY_IMP },
    { name: 'enums.reports.tables.columns.assignments.fuel-spent-by-abs', type: ReportTableColumnType.FUEL_SPENT_BY_ABS },
    { name: 'enums.reports.tables.columns.assignments.fuel-spent-by-ins', type: ReportTableColumnType.FUEL_SPENT_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-spent-by-norm-corr', type: ReportTableColumnType.FUEL_SPENT_BY_NORMS_CORRECTED },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg', type: ReportTableColumnType.FUEL_RATE_AVG },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-100km', type: ReportTableColumnType.FUEL_RATE_AVG_100KM },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_BY_DUT },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-100km-by-dut', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_DUT },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_BY_IMP },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-100km-by-imp', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_IMP },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_BY_ABS },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-100km-by-abs', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_ABS },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_BY_INS },
    { name: 'enums.reports.tables.columns.assignments.fuel-rate-avg-100km-by-ins', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_INS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_BY_NORMS },
    { name: 'enums.reports.tables.columns.mhs.fuel-rate-avg-100km-by-norm', type: ReportTableColumnType.FUEL_RATE_AVG_100KM_BY_NORMS },
    { name: 'enums.reports.tables.columns.assignments.fuel-start', type: ReportTableColumnType.FUEL_START },
    { name: 'enums.reports.tables.columns.assignments.fuel-end', type: ReportTableColumnType.FUEL_END },
    { name: 'enums.reports.tables.columns.assignments.fine', type: ReportTableColumnType.FINE },
    { name: 'enums.reports.tables.columns.assignments.dq-score', type: ReportTableColumnType.DQ_SCORE },
    { name: 'enums.reports.tables.columns.assignments.note', type: ReportTableColumnType.NOTE }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Загрузки"
 */
function getLoadingsTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.loadings.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.loadings.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.loadings.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.loadings.time-full', type: ReportTableColumnType.TIME_FULL },
    { name: 'enums.reports.tables.columns.loadings.time-prev', type: ReportTableColumnType.TIME_PREV },
    { name: 'enums.reports.tables.columns.loadings.pos-start', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.loadings.pos-start-coords', type: ReportTableColumnType.POS_START_COORDS },
    { name: 'enums.reports.tables.columns.loadings.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.loadings.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.loadings.counter', type: ReportTableColumnType.COUNTER },
    { name: 'enums.reports.tables.columns.loadings.counter-start', type: ReportTableColumnType.COUNTER_START },
    { name: 'enums.reports.tables.columns.loadings.counter-end', type: ReportTableColumnType.COUNTER_END },
    { name: 'enums.reports.tables.columns.loadings.temp-avg', type: ReportTableColumnType.TEMP_AVG },
    { name: 'enums.reports.tables.columns.loadings.temp-min', type: ReportTableColumnType.TEMP_MIN },
    { name: 'enums.reports.tables.columns.loadings.temp-max', type: ReportTableColumnType.TEMP_MAX },
    { name: 'enums.reports.tables.columns.loadings.temp-start', type: ReportTableColumnType.TEMP_START },
    { name: 'enums.reports.tables.columns.loadings.temp-end', type: ReportTableColumnType.TEMP_END },
    { name: 'enums.reports.tables.columns.loadings.weight-avg', type: ReportTableColumnType.WEIGHT_AVG },
    { name: 'enums.reports.tables.columns.loadings.weight-min', type: ReportTableColumnType.WEIGHT_MIN },
    { name: 'enums.reports.tables.columns.loadings.weight-max', type: ReportTableColumnType.WEIGHT_MAX },
    { name: 'enums.reports.tables.columns.loadings.weight-start', type: ReportTableColumnType.WEIGHT_START },
    { name: 'enums.reports.tables.columns.loadings.weight-end', type: ReportTableColumnType.WEIGHT_END },
    { name: 'enums.reports.tables.columns.loadings.loadings-count', type: ReportTableColumnType.LOADINGS_COUNT },
    { name: 'enums.reports.tables.columns.loadings.note', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Активность пользователя"
 */
function getUserActivityTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.user-activity.user-activity-value', type: ReportTableColumnType.USER_ACTIVITY_VALUE },
    { name: 'enums.reports.tables.columns.user-activity.note', type: ReportTableColumnType.NOTE }
  ];
}

/**
 * Получение списка колонок, доступных для таблицы "Потеря связи"
 */
function getLostConnectionTableColumns(): IReportTableColumn[] {
  return [
    { name: 'enums.reports.tables.columns.connection-problems.time-start', type: ReportTableColumnType.TIME_START },
    { name: 'enums.reports.tables.columns.connection-problems.time-end', type: ReportTableColumnType.TIME_END },
    { name: 'enums.reports.tables.columns.connection-problems.time-length', type: ReportTableColumnType.TIME_LENGTH },
    { name: 'enums.reports.tables.columns.connection-problems.pos', type: ReportTableColumnType.POS_START },
    { name: 'enums.reports.tables.columns.connection-problems.count', type: ReportTableColumnType.DC_COUNT },
    { name: 'enums.reports.tables.columns.connection-problems.driver', type: ReportTableColumnType.DRIVER },
    { name: 'enums.reports.tables.columns.connection-problems.trailer', type: ReportTableColumnType.TRAILER },
    { name: 'enums.reports.tables.columns.connection-problems.notes', type: ReportTableColumnType.NOTE },
    { name: 'enums.reports.tables.columns.geozones.unit-name', type: ReportTableColumnType.UNIT_NAME },
    { name: 'enums.reports.tables.columns.geozones.unit-model', type: ReportTableColumnType.UNIT_MODEL },
    { name: 'enums.reports.tables.columns.geozones.unit-brand', type: ReportTableColumnType.UNIT_BRAND },
    { name: 'enums.reports.tables.columns.geozones.unit-registration-plate', type: ReportTableColumnType.UNIT_REGISTRATION_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-garage-plate', type: ReportTableColumnType.UNIT_GARAGE_PLATE },
    { name: 'enums.reports.tables.columns.geozones.unit-description', type: ReportTableColumnType.UNIT_DESCRIPTION }
  ];
}
