import { Component, ElementRef, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dateFormat from 'dateformat';

import { tranny } from '../../app.component';
import { correctDisableDatepickerSingle, endOfDay, startOfDay } from '../../utils/intervals';
import { BgPopoverService } from '../bg-popover';

import { BgDatetimepickerPopoverComponent } from './bg-datetimepicker.popover.component';

const CUSTOM_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BgDatetimepickerComponent),
  multi: true
};

@Component({
  selector: 'bg-datetimepicker',
  templateUrl: './bg-datetimepicker.component.html',
  styleUrls: ['./bg-datetimepicker.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class BgDatetimepickerComponent implements ControlValueAccessor {

  public valueString: string;
  public isShowCalendar = false;
  public isDisabled = false;
  @Input() public canReset = false;

  /** Маска для отображения даты */
  @Input() public mask = 'formats.mask-date-short-time';

  /** Признак того, что необходимо отображать только календарь, без часов */
  @Input() public onlyDate = false;

  /** Ограничение прошлого периода */
  @Input() public disableUntil: Date;
  /** Ограничение будущего периода */
  @Input() public disableSince: Date;
  /** Признак что конец дня */
  @Input() public isEndDay: boolean

  /**
   * Признак отображения кнопки confirm
   */
  @Input() public isConfirmBtn = true;

  private value: Date = null;

  constructor(public elem: ElementRef, private popoverService: BgPopoverService) { }

  public toggleCalendar(e: MouseEvent) {
    e.stopPropagation();
    if (this.isDisabled) { return; }
    this.isShowCalendar = !this.isShowCalendar;
    if (this.isShowCalendar) {
      const rect = this.elem.nativeElement.getBoundingClientRect();
      this.popoverService.addPopover(
        BgDatetimepickerPopoverComponent,
        { x: rect.left, y: rect.bottom + 2 },
        {
          date: this.value,
          onlyDate: this.onlyDate,
          isConfirmBtn: this.isConfirmBtn,
          disableSince: this.disableSince,
          disableUntil: this.disableUntil,
          onClose: (date) => {
            this.isShowCalendar = false;
            if (!date) {
              this.resetValue();
              return;
            }
            this.value = this.onlyDate ? startOfDay(date) : date
            this.value = this.isEndDay ? endOfDay(date) : this.value
            this.valueString = dateFormat(this.value, tranny.instant(this.mask));
            this.onChangeCb(this.value);
            this.onTouchedCb();
          }
        }
      );
    } else {
      this.popoverService.removePopover();
    }
  }

  public resetValue() {
    this.value = null;
    this.valueString = '';
    this.onChangeCb(this.value);
  }

  public writeValue(value: any) {
    if (value) {
      this.value = new Date(value);
      this.value = correctDisableDatepickerSingle(this.value, this.disableUntil, this.disableSince);
      this.valueString = dateFormat(this.value, tranny.instant(this.mask));
    } else {
      this.valueString = '';
    }
  }

  public setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  public registerOnChange(fn: any) {
    this.onChangeCb = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCb = fn;
  }

  public get actionsClass() {
    return this.canReset ? 'actions two-button' : 'actions one-button';
  }

  // tslint:disable-next-line:no-empty
  private onChangeCb: (_: any) => void = () => { };
  // tslint:disable-next-line:no-empty
  private onTouchedCb: () => void = () => { };
}
