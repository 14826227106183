/**
 * Типы таблиц в отчетах
 */
import { ReportType } from '../../../app/services/report-templates.service';

export enum ReportTableType {
  // noinspection JSUnusedGlobalSymbols
  /** Геозоны */
  GEOZONES = 1,
  /** Счетчики */
  COUNTERS,
  /** Движение топлива */
  FUEL,
  /** Заправки */
  FILLINGS,
  /** Сливы */
  THEFTS,
  /** Моточасы */
  MHS,
  /** Поездки */
  RACES,
  /** Остановки */
  STOPS,
  /** Стоянки */
  PARKINGS,
  /** Превышения скорости */
  SPEEDING,
  /** Сводка */
  SUMMARY,
  /** Цифровые датчики */
  DIGITAL_SENSORS,
  /** Топливо по чекам */
  FUEL_BY_CHECK,
  /** Посещенные улицы */
  STREETS,
  /** События (исключая нарушения) */
  EVENTS,
  /** Нарушения */
  VIOLATIONS,
  /** Качество вождения */
  DRIVING_QUALITY,
  /** Техобслуживание */
  MAINTENANCE,
  /** Назначения */
  ASSIGNMENTS,
  /** Маршруты: план-факт */
  ROUTE_PLAN_FACT,
  /** Загрузки */
  LOADINGS,
  /** Активность пользователя */
  USER_ACTIVITY,
  /** Потеря связи */
  CONNECTION_PROBLEMS
}

/**
 * Получение списка типов доступных таблиц отчета
 * @param type Тип отчета
 */
export function getReportTableTypes(type: ReportType): ReportTableType[] {
  switch (type) {
    case ReportType.UNIT:
    case ReportType.UNIT_GROUP:
      return [
        ReportTableType.GEOZONES,
        ReportTableType.COUNTERS,
        ReportTableType.FUEL,
        ReportTableType.FILLINGS,
        ReportTableType.MHS,
        ReportTableType.STOPS,
        ReportTableType.RACES,
        ReportTableType.SPEEDING,
        ReportTableType.SUMMARY,
        ReportTableType.THEFTS,
        ReportTableType.PARKINGS,
        ReportTableType.DIGITAL_SENSORS,
        ReportTableType.FUEL_BY_CHECK,
        ReportTableType.EVENTS,
        ReportTableType.VIOLATIONS,
        ReportTableType.DRIVING_QUALITY,
        ReportTableType.MAINTENANCE,
        ReportTableType.LOADINGS,
        ReportTableType.CONNECTION_PROBLEMS
      ];
    case ReportType.DRIVER:
    case ReportType.DRIVER_GROUP:
    case ReportType.TRAILER:
    case ReportType.TRAILER_GROUP:
      return [ReportTableType.ASSIGNMENTS];
    case ReportType.USER:
      return [ReportTableType.USER_ACTIVITY];
    default: return [];
  }
}

/**
 * Получение наименования типа таблицы в отчетах
 * @param type Тип таблицы в отчетах
 */
export function getReportTableTypeName(type: ReportTableType): string {
  switch (type) {
    case ReportTableType.GEOZONES: return 'enums.reports.tables.type.geo';
    case ReportTableType.COUNTERS: return 'enums.reports.tables.type.counters';
    case ReportTableType.FUEL: return 'enums.reports.tables.type.fuel';
    case ReportTableType.FILLINGS: return 'enums.reports.tables.type.fueling';
    case ReportTableType.MHS: return 'enums.reports.tables.type.motor-hours';
    case ReportTableType.STOPS: return 'enums.reports.tables.type.stop';
    case ReportTableType.RACES: return 'enums.reports.tables.type.move';
    case ReportTableType.SPEEDING: return 'enums.reports.tables.type.speeding';
    case ReportTableType.SUMMARY: return 'enums.reports.tables.type.summary';
    case ReportTableType.THEFTS: return 'enums.reports.tables.type.theft';
    case ReportTableType.PARKINGS: return 'enums.reports.tables.type.parking';
    case ReportTableType.DIGITAL_SENSORS: return 'enums.reports.tables.type.digital';
    case ReportTableType.FUEL_BY_CHECK: return 'enums.reports.tables.type.fuel-by-check';
    case ReportTableType.EVENTS: return 'enums.reports.tables.type.events';
    case ReportTableType.VIOLATIONS: return 'enums.reports.tables.type.violations';
    case ReportTableType.DRIVING_QUALITY: return 'enums.reports.tables.type.quality';
    case ReportTableType.MAINTENANCE: return 'enums.reports.tables.type.service';
    case ReportTableType.ASSIGNMENTS: return 'enums.reports.tables.type.assignments';
    case ReportTableType.LOADINGS: return 'enums.reports.tables.type.loadings';
    case ReportTableType.USER_ACTIVITY: return 'enums.reports.tables.type.activity';
    case ReportTableType.CONNECTION_PROBLEMS: return 'enums.reports.tables.type.connection-problems';
    default: return 'enums.reports.tables.type.unknown';
  }
}
