import { Component } from '@angular/core';

import { SectionComponent } from '../../classes/SectionComponent';
import { ActivityService } from '../../services/activity.service';
import { NavigationPanelType, NavigationService } from '../../services/navigation.service';
import { Subscription } from 'rxjs';

/**
 * Компонент, представляющий админскую часть сайта
 */
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends SectionComponent {

  /**
   * Ссылка на типы панели навигации
   */
  public nType = NavigationPanelType;

  /**
   * Конструктор
   * @param activityService Сервис учета активности пользователей
   * @param navigationService
   */
  constructor(
    activityService: ActivityService,
    public navigationService: NavigationService
  ) {
    super(activityService);
  }
}
