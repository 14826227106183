import { Pipe, PipeTransform } from '@angular/core';

import { IObjectRight } from '../../shared/rights/IObjectRight';

/**
 * Фильтр для списка прав на объекты в администрировании
 */
@Pipe({
  name: 'orfilter',
  pure: false
})
export class ObjectRightsFilterPipe implements PipeTransform {
  public transform(items: IObjectRight[], filter: string): any {
    if (!items || !filter || !filter.length) {
      return items;
    }
    const lowerFilter = filter.toLowerCase();

    return items.filter(
      (item) => item.objectName.toLowerCase().includes(lowerFilter)
    );
  }

}
