import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IGeozone } from '../../../../shared/geozones/IGeozone';
import { ISortInfo } from '../../../../shared/ISortInfo';
import { IRoutePoint } from '../../../../shared/routes/IRoutePoint';
import { IClientRoute } from '../../../classes/IClientRoute';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { localeSort } from '../../../utils/sort';
import { SelectionType, SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для редактирования маршрута
 */
@Component({
  selector: 'app-routes-edit',
  templateUrl: './routes.edit.component.html',
  styleUrls: ['./routes.edit.component.scss']
})
export class RoutesEditComponent implements OnInit {

  /** Редактируемый маршрут */
  @Input() public route: IClientRoute;

  /** Строка поиска */
  public search: string;

  /** Признак отображения строки поиска */
  public showSearch: boolean = false;

  /** Сортировка */
  public sort: ISortInfo;

  /** Объекты мониторинга */
  public units: IListItem<string>[] = [];

  /** Водители */
  public drivers: IListItem<string>[] = [];

  /** Геозоны */
  public geozones: IListItem<string>[] = [];

  /** Выбранная точка маршрута в списке точек маршрута */
  public selected: IRoutePoint = null;

  /** Список всех доступных объектов мониторинга */
  private allUnits: IListItem<string>[];

  /**
   * Конструктор
   * @param crudService Сервис работы с CRUD
   * @param modalService Сервис работы с модальными окнами
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param monitoringService Сервис мониторинга
   * @param store Сервис для хранения данных мониторинга
   * @param translator Сервис для перевода
   */
  constructor(
    private crudService: CRUDService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private monitoringService: MonitoringService,
    private store: StoreService,
    private translator: TranslateService
  ) {
    this.sort = { field: 'name', isDescending: false };
  }

  /**
   * Получение признака возможности сохранения маршрута
   */
  public get isCanSave() {
    return this.route
      && this.route.name
      && this.route.unitId
      && this.route.start
      && this.route.end
      && this.route.points
      && this.route.points.length
      && this.route.points.every((p) => p.geozoneId && !!p.in && !!p.out && p.out > p.in);
  }

  /**
   * Добавление точки маршрута
   */
  public addPoint() {
    const point = { geozoneId: null } as IRoutePoint;
    this.route.points.push(point);
    this.selected = point;
  }

  /**
   * Удаление точки маршрута
   * @param point Точка маршрута
   */
  public deletePoint(point: IRoutePoint) {
    if (this.selected === point) {
      this.selected = null;
    }

    const index = this.route.points.indexOf(point);
    if (index !== -1) {
      this.route.points.splice(index, 1);
    }
  }

  /**
   * Выбор геозоны из списка геозон
   */
  public selectGeozone() {
    if (!this.selected) {
      return;
    }

    const data = {
      items: this.geozones,
      selected: this.geozones.filter((u) => u.id === this.selected.geozoneId),
      title: 'component.routes.edit.select-geo-title',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.selected.geozoneId = result?.shift()?.id);
  }

  /**
   * Возвращает имя геозоны точки маршрута
   * @param point Точка маршрута
   */
  public getGeozoneName(point: IRoutePoint) {
    const geozone = this.geozones.find((g) => g.id === point.geozoneId);
    return geozone?.name ?? '<' + this.translator.instant('component.routes.edit.geo-undefined') + '>';
  }

  /**
   * Сохранение маршрута
   */
  public save() {
    if (!this.isCanSave) {
      return;
    }

    this.route.points?.sort((a, b) => a.in - b.in);

    for (let i = 1; i < this.route.points.length; i++) {
      if (this.route.points[i].in < this.route.points[i - 1].out) {
        const err = this.translator.instant('component.routes.edit.error-1', {
          val1: this.getGeozoneName(this.route.points[i]),
          val2: this.getGeozoneName(this.route.points[i - 1])
        });
        this.modalService.showError(err);
        return;
      }
    }

    if (this.route.points[0].in < this.route.start
      || this.route.points[this.route.points.length - 1].out > this.route.end) {

      this.modalService.showError('component.routes.edit.error-2');
      return;
    }

    this.loadingService.wrap(this.monitoringService.saveRoute(this.route), true).subscribe();
  }

  /**
   * Обработка отмены добавления/редактирования маршрута
   */
  public cancel() {
    this.monitoringService.cancelEditRoute();
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadingService.wrap(this.crudService.getList(CRUDEntityType.GEOZONE), true)
      .subscribe((geozones: IGeozone[]) => {
        this.geozones = geozones
          ?.map((g) => ({ id: g.id, name: g.name }))
          ?.sort(localeSort);
      });

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.DRIVER), true)
      .subscribe((drivers: IListItem<string>[]) => this.drivers = drivers?.sort(localeSort));

    if (this.store?.units?.length) {
      this.units = this.store.units
        ?.map((u) => ({ id: u.id, name: u.name }))
        ?.sort(localeSort);

      this.loadUnit(this.route.unitId);
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units: IListItem<string>[]) => {
        this.units = units
          ?.map((u) => ({ id: u.id, name: u.name }))
          ?.sort(localeSort);

        this.allUnits = this.units;
        this.loadUnit(this.route.unitId);
      });
  }

  /**
   * Загрузка объекта в список (если его там нет)
   * @param unitId Идентификатор объекта
   */
  private loadUnit(unitId: string) {
    if (!unitId) {
      return;
    }

    const exists = this.units.some((u) => u.id === unitId);
    if (exists) {
      return;
    }

    this.loadingService.wrap(this.crudService.get(unitId, CRUDEntityType.UNIT), true)
      .subscribe((unit) => this.units.push({ id: unit.id, name: unit.name }));
  }

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.route.unitId
      ? this.allUnits.filter((u) => u.id === this.route.unitId)
      : [];

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.routes.edit.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        if (!result?.length) {
          this.route.unitId = null;
          return;
        }

        const unit = result?.shift();
        this.route.unitId = unit?.id;

        const exists = this.units.some((u) => u.id === this.route.unitId);
        if (!exists) {
          this.units.push(unit);
        }
      });
  }
}
