import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IUnitMoveOptions } from '../../../../shared/units/IUnitMoveOptions';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { UnitsService } from '../../../services/units.service';
import { localeSort } from '../../../utils/sort';

/**
 * Данные компонента
 */
interface IData {
  /** Список идентификаторов переносимых объектов мониторинга */
  unitIds: string[];
}

/**
 * Компонент диалогового окна переноса объектов мониторинга
 * из одной учетной записи в другую
 */
@Component({
  selector: 'app-units-move',
  templateUrl: './units.move.component.html'
})
export class UnitsMoveComponent
extends DialogComponent<IData, boolean>
implements IData, OnInit {

  /** Список идентификаторов переносимых объектов мониторинга */
  public unitIds: string[] = [];

  /** Список доступных для выбора учетных записей */
  public accounts: IListItem<string>[] = [];

  /** Идентификатор выбранной учетной записи */
  public selectedAccountId: string;

  /** Дополнительные параметры переноса */
  public options: IUnitMoveOptions;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param unitsService Сервис для работы с объектами мониторинга
   * @param crudService Сервис для CRUD
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private unitsService: UnitsService,
    private crudService: CRUDService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.options = {
      moveTracker: false,
      moveOptionals: false,
      moveSIMCards: false,
      endTrailerAssignment: false,
      endDriverAssignment: false
    };
  }

  /** Получение признака возможности переноса */
  public get isCanMove(): boolean {
    return !!this.selectedAccountId;
  }

  /**
   * Обработка при изменении признака переноса трекера
   * вместе с объектом
   */
  public onChangeMoveTrackerOption() {
    if (!this.options.moveTracker) {
      this.options.moveSIMCards = false;
    }
  }

  /**
   * Обработка клика по кнопке переноса
   */
  public move() {
    if (!this.isCanMove) {
      return;
    }

    this.loadingService.withLoading(
      this.unitsService.move(this.unitIds, this.selectedAccountId, this.options),
      () => {
        this.result = true;
        this.close();
      }
    );
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadingService.withLoading(
      this.crudService.getListLight(CRUDEntityType.ACCOUNT),
      (accounts: IListItem<string>[]) =>
        this.accounts = accounts?.sort(localeSort)
    );
  }
}
