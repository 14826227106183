import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AccountType } from '../../../../shared/accounts/IAccount';
import { GeozoneType, getAllGeozoneTypes, getGeozoneTypeName } from '../../../../shared/geozones/GeozoneType';
import { ISimpleObject } from '../../../../shared/ISimpleObject';
import { IClientGeozone } from '../../../classes/IClientGeozone';
import { IListItem } from '../../../classes/IListItem';
import { DistrictsService } from '../../../services/districts.service';
import { GeozonesService } from '../../../services/geozones.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { StoreService } from '../../../services/store.service';
import { ToastService } from '../../../services/toast.service';

/**
 * Компонент для редактирования геозон
 */
@Component({
  selector: 'geozones-edit',
  templateUrl: './geozones.edit.component.html',
  styleUrls: ['./geozones.edit.component.scss']
})
export class GeozonesEditComponent implements OnInit, OnDestroy {

  /** Типы геозон */
  public types: IListItem<GeozoneType>[] = [];

  /** Районы */
  public districts: ISimpleObject[] = [];

  /** Тип аккаунта */
  public accountType = AccountType;

  /** Типы геозон */
  public GeozoneType = GeozoneType

  /** Форма */
  public editForm: FormGroup = new FormGroup({});

  /** Редактируемая геозона */
  public geozone: IClientGeozone;

  /** Список типов накопления ТКО */
  public garbageZones: IListItem<number>[];

  /** Список типов выгрузки */
  public garbageTypes: IListItem<number>[];

  /**
   * Конструктор
   * @param monitoringService Сервис мониторинга
   * @param modalService Сервис модальных окон
   * @param geozonesService Сервис для работы с геозонами
   * @param loadingService Сервис для отображения процесса загрузки
   * @param districtsService Сервис для работы с районами
   * @param formBuilder FormBuilder
   * @param route ActivatedRoute
   * @param router
   * @param toastService
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    private monitoringService: MonitoringService,
    private modalService: ModalService,
    private geozonesService: GeozonesService,
    private loadingService: LoadingService,
    private districtsService: DistrictsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public store: StoreService
  ) { }

  ngOnInit(): void {
    // Загружаем геозону
    this.route.params.pipe(
      switchMap((params) => {
        if (this.store.editGeozone != null) {
          return of(this.store.editGeozone);
        }
        if (params.id == null) {
          return of({
            accountId: this.store?.trackingSettings?.geozonesAccountId,
            checked: true,
            type: GeozoneType.CIRCLE,
            color: '#ff0000',
            labelColor: '#0000ff',
            points: []
          } as IClientGeozone)
        } else {
          return this.geozonesService.getGeozone(params.id)
        }
      }),
      filter((result) => !!result)
    ).subscribe((result) => {
      this.geozone = result

      // Запускаем карту на редактирование геозоны
      this.monitoringService.startEditGeozone(result)

      // Форма с валидацией
      this.editForm = this.formBuilder.group({
        name: [this.geozone?.name, [ Validators.required ]],
        type: [this.geozone?.type, [Validators.required]],
        color: [this.geozone?.color, [Validators.required]],
        labelColor: [this.geozone?.labelColor, [Validators.required]],
        r: [this.geozone?.r, []],
        extCode: [this.geozone?.extCode, []],
        garbageZone: [this.geozone?.garbageZone, []],
        garbageType: [this.geozone?.garbageType, []],
        district: [this.geozone?.extCode, []],
        garbageVol: [this.geozone?.extCode, []],
        garbageDistrict: [this.geozone?.extCode, []],
        garbageSettlement: [this.geozone?.extCode, []],
        garbageLocality: [this.geozone?.extCode, []],
        street: [this.geozone?.extCode, []],
        homeNumber: [this.geozone?.extCode, []],
        regNumber: [this.geozone?.extCode, []],
        inn: [this.geozone?.extCode, []],
        legalPersonName: [this.geozone?.extCode, []],
        descr: [this.geozone?.extCode, []],
        track: [this.geozone?.track, []]
      }, { updateOn: 'change' })
    }, () => this.router.navigate(['../'], { relativeTo: this.route }))

    // Заполнение типов геозон
    this.types = getAllGeozoneTypes()
      .map((type) => ({ id: type, name: getGeozoneTypeName(type) }));

    // Заполняем районы
    if (this.store?.user?.account?.accountType === this.accountType.SCAVENGER) {
      this.loadingService.withLoading(this.districtsService.getList(), (districts) => this.districts = districts);
    }

    // Заполнение типов накопления ТКО
    this.garbageZones = [
      { id: undefined, name: 'component.geo.undefined' },
      { id: 1, name: 'component.geo.base' },
      { id: 2, name: 'component.geo.containers' },
      { id: 3, name: 'component.geo.landfill' }
    ];

    // Заполнение типов мест выгрузки
    this.garbageTypes = [
      { id: 0, name: 'component.geo.undefined' },
      { id: 1, name: 'component.geo.sorted' },
      { id: 2, name: 'component.geo.not-sorted' }
    ];
  }

  ngOnDestroy(): void {
    this.monitoringService.cancelEditGeozone(this.geozone);
  }

  /**
   * Обработка изменения типа геозоны
   */
  public onTypeChanged() {
    if (this.geozone.type === GeozoneType.POLYGON) {
      if (this.geozone.r) { delete this.geozone.r; }
    }
    this.geozone.points = [];
    this.monitoringService.startDrawGeozone(this.geozone);
  }

  /** При изменении районов */
  public onChangeDistricts(districts) {
    this.districts = districts;
  }

  /**
   * Получение названия Юр. Лица
   */
  public getLegalPersonName(inn: string) {
    this.loadingService.wrap(this.geozonesService.getLegalPersonName(inn), true)
        .subscribe((data) => this.geozone.legalPersonName = data?.suggestions?.shift()?.data?.name?.short_with_opf);
  }

  /**
   * Обработка изменения значения какого-либо поля
   */
  public onFieldChanged() {
    this.monitoringService.startDrawGeozone(this.geozone);
  }

  /**
   * Обработка изменения радиуса/ширины геозоны
   */
  public onRChanged(e) {
    this.geozone.r = e.value
    this.monitoringService.startDrawGeozone(this.geozone);
    if (this.geozone.points && this.geozone.points.length && this.geozone.type !== GeozoneType.POLYGON) {
      this.monitoringService.updateEditGeozonePointsAndR(this.geozone.points, this.geozone.r);
    }
  }

  /**
   * Получение признака возможности сохранения геозоны
   */
  get isCanSave() {
    return this.editForm.valid
  }

  /**
   * Сохранение геозоны
   */
  public onSubmit() {
    if (!this.editForm.valid) { return; }
    this.monitoringService.saveGeozone(this.geozone).subscribe(
      () => {
        this.toastService.success('component.geo.edit.success')
        this.router.navigate(['../'], { relativeTo: this.route }).finally();
      },
      (error) => this.modalService.showError(error)
    );
  }

  /**
   * Отмена изменений
   */
  public cancel() {
    this.router.navigate(['../'], { relativeTo: this.route }).then();
  }

  /**
   * Получение наименования поля r для геозоны
   * @param type Тип геозоны
   */
  public getRFieldName(type: GeozoneType) {
    switch (type) {
      case GeozoneType.CIRCLE: return 'component.geo.edit.radius';
      case GeozoneType.LINE: return 'component.geo.edit.width';
      default: return 'component.geo.unknown';
    }
  }
}
