import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';

import { BgPopoverModule } from '../bg-popover';

import { BgDatetimepickerComponent } from './bg-datetimepicker.component';
import { BgDatetimepickerPopoverComponent } from './bg-datetimepicker.popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MyDatePickerModule,
    BgPopoverModule
  ],
  declarations: [BgDatetimepickerComponent, BgDatetimepickerPopoverComponent],
  entryComponents: [BgDatetimepickerPopoverComponent],
  exports: [BgDatetimepickerComponent]
})
export class BgDatetimepickerModule { }
