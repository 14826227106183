import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Point, SeriesOptionsType, XAxisOptions } from 'highcharts';
import { AxisOptions, Chart, getOptions, Options, setOptions, stockChart } from 'highcharts/highstock';
import { Subscription, timer } from 'rxjs';

import { LoadingService } from '../../../services/loading.service';
import {
  IReportChartInfo,
  IReportElement,
  IReportPointInfo,
  ReportChartType,
  ReportLineType,
  ReportsService
} from '../../../services/reports.service';

/**
 * Компонент для отображения графика отчета
 */
@Component({
  selector: 'report-chart',
  templateUrl: './report.chart.component.html',
  styleUrls: ['./report.chart.component.scss']
})
export class ReportChartComponent implements OnChanges, OnDestroy {

  /** Идентификатор графика */
  @Input() public reportChart: IReportElement;

  /** Подписка на изменение размеров графика */
  private chartReflowSubscription: Subscription;

  /** Информация о графике */
  private chart: IReportChartInfo;

  /** Объект графика */
  private highchart: Chart;

  /** Параметры графика */
  private readonly options: Options;

  /**
   * Конструктор
   * @param loadingService Сервис для отображения процесса загрузки
   * @param reportsService Сервис для работы с отчетами
   * @param translator Сервис перевода
   */
  constructor(
    private loadingService: LoadingService,
    private reportsService: ReportsService,
    private translator: TranslateService
  ) {
    this.chartReflowSubscription = this.reportsService.chatReflowSubject.subscribe(this.onChartReflow);

    this.setGlobalOptions();

    this.options = {
      chart: {renderTo: 'chartContainer', type: 'line', zoomType: 'x'},
      title: {text: null},
      credits: {enabled: false},
      time: {useUTC: false},
      rangeSelector: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      mapNavigation: {
        enableMouseWheelZoom: true
      },
      tooltip: {shared: true}, // , style: {color: '#ccc', dashStyle: 'solid'}
      plotOptions: {series: {point: {events: {click: this.onPointClick}}}}
    };
  }

  /**
   * Обработки после изменения компонента
   */
  public ngOnChanges() {
    this.loadingService.withLoading(
      this.reportsService.getChart(this.reportChart.id),
      this.onChartLoaded
    );
  }

  /**
   * Обработки перед уничтожением компонента
   */
  public ngOnDestroy() {
    this.chartReflowSubscription.unsubscribe();
  }

  /**
   * Обработка клика на точку линии графика
   * @param event Данные события клика
   */
  public onPointClick = (event: Event) => {
    const p = (event as any).point as Point;
    this.loadingService.withLoading(
      this.reportsService.getPoint(this.reportsService.reportInfo.objectIds[0], p.x, this.chart.chartType),
      (point: IReportPointInfo) => this.reportsService.showPoint(point));
    return true;
  }

  /**
   * Обработка загрузки информации о графике
   * @param chart Информация о графике
   */
  private onChartLoaded = (chart: IReportChartInfo) => {
    this.chart = chart;
    this.setOptions();

    if (this.highchart) {
      this.highchart.destroy();
    }
    this.highchart = stockChart(this.options)
  };

  /**
   * Обработка изменения размера графика
   */
  private onChartReflow = () => {
    if (this.highchart) {
      timer(100).subscribe(() => {
        this.highchart.reflow();
      });
    }
  };

  /**
   * Установка глобальных параметров компонента с графиком
   */
  private setGlobalOptions() {
    this.translator.get([
      'classes.time.month-1', 'classes.time.month-2', 'classes.time.month-3', 'classes.time.month-4',
      'classes.time.month-5', 'classes.time.month-6', 'classes.time.month-7', 'classes.time.month-8',
      'classes.time.month-9', 'classes.time.month-10', 'classes.time.month-11', 'classes.time.month-12',
      'classes.time.short-month-1', 'classes.time.short-month-2', 'classes.time.short-month-3',
      'classes.time.short-month-4', 'classes.time.short-month-5', 'classes.time.short-month-6',
      'classes.time.short-month-7', 'classes.time.short-month-8', 'classes.time.short-month-9',
      'classes.time.short-month-10', 'classes.time.short-month-11', 'classes.time.short-month-12',
      'classes.time.day-1', 'classes.time.day-2', 'classes.time.day-3', 'classes.time.day-4',
      'classes.time.day-5', 'classes.time.day-6', 'classes.time.day-7',
      'classes.chart-manager.loading', 'classes.chart-manager.download', 'classes.chart-manager.print',
      'classes.chart-manager.reset', 'classes.chart-manager.reset-zoom'
    ]).subscribe((x) => {
      setOptions({
        time: { useUTC: false },
        lang: {
          months: [
            x['classes.time.month-1'], x['classes.time.month-2'], x['classes.time.month-3'],
            x['classes.time.month-4'], x['classes.time.month-5'], x['classes.time.month-6'],
            x['classes.time.month-7'], x['classes.time.month-8'], x['classes.time.month-9'],
            x['classes.time.month-10'], x['classes.time.month-11'], x['classes.time.month-12']
          ],
          weekdays: [
            x['classes.time.day-7'], x['classes.time.day-1'], x['classes.time.day-2'],
            x['classes.time.day-3'], x['classes.time.day-4'], x['classes.time.day-5'], x['classes.time.day-6']
          ],
          shortMonths: [
            x['classes.time.short-month-1'], x['classes.time.short-month-2'], x['classes.time.short-month-3'],
            x['classes.time.short-month-4'], x['classes.time.short-month-5'], x['classes.time.short-month-6'],
            x['classes.time.short-month-7'], x['classes.time.short-month-8'], x['classes.time.short-month-9'],
            x['classes.time.short-month-10'], x['classes.time.short-month-11'], x['classes.time.short-month-12']
          ],
          loading: x['classes.chart-manager.loading'],
          downloadPNG: x['classes.chart-manager.download'] + ' PNG',
          downloadJPEG: x['classes.chart-manager.download'] + ' JPEG',
          downloadPDF: x['classes.chart-manager.download'] + ' PDF',
          downloadSVG: x['classes.chart-manager.download'] + ' SVG',
          printChart: x['classes.chart-manager.print'],
          resetZoom: x['classes.chart-manager.reset'],
          resetZoomTitle: x['classes.chart-manager.reset-zoom']
        }
      });
    });
  }

  /**
   * Заполнение параметров графика
   */
  private setOptions() {
    const xAxis: XAxisOptions = {
      title: {text: this.chart.title},
      type: this.chart.chartType === ReportChartType.TIME ? 'datetime' : 'linear',
      crosshair: {color: '#ccc', dashStyle: 'Solid'},
      ordinal: false
    };
    if (this.chart.bgs) {
      xAxis.plotBands = this.chart.bgs.map((bg) => ({
        color: bg.c,
        from: this.chart.data[bg.s][0],
        to: this.chart.data[bg.e][0]
      }));
    }

    let yAxis: AxisOptions[] = [];
    if (this.chart.axes) {
      yAxis = this.chart.axes.map((axis, i) => ({
        opposite: axis.opposite,
        title: {
          text: axis.title,
          style: {color: getOptions().colors[i]}
        },
        labels: {
          format: `{value} ${(axis.measure ? axis.measure : '')}`,
          style: {color: getOptions().colors[i]}
        }
      }));
    }

    const series: SeriesOptionsType[] = [];
    if (this.chart.lines) {
      this.chart.lines.forEach((line) => {
        const seria: any = {
          type: 'line',
          name: line.name,
          color: line.color,
          step: line.step,
          yAxis: line.y,
          tooltip: { valueDecimals: 2 },
          dataGrouping: {
            enabled: false
          }
        };

        const measure = this.chart.axes[line.y].measure;
        if (measure) {
          seria.tooltip.valueSuffix = ` ${measure}`;
        }

        const state = (v) => this.translator.instant(v ? 'classes.chart-manager.on' : 'classes.chart-manager.off');

        if (line.step) {
          seria.tooltip.pointFormatter = function() {
            return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${state(this.y)}</b><br>`;
          };
        }

        if (line.type === ReportLineType.DASH) {
          seria.dashStyle = 'ShortDot';
          seria.data = [];

          line.intervals?.forEach((i) => {
            const start = this.chart.data[i.s];
            const end = this.chart.data[i.e];
            seria.data.push([start[0], start[line.value]]);
            seria.data.push([end[0], end[line.value]]);
            seria.data.push([end[0], null]);
          });

        } else {
          const begin = line.intervals[0]?.s;
          const end = line.intervals[line.intervals?.length - 1]?.e + 1;
          const plots = this.chart.data.slice(begin, end);

          seria.data = plots.map((plot, i) => {
            const inside = line.intervals?.some((interval) => (i + begin >= interval.s && i + begin <= interval.e));
            return [plot[0], (inside ? plot[line.value] : null)] as [number, number];
          });
        }

        series.push(seria);
      });
    }

    this.options.xAxis = xAxis;
    this.options.yAxis = yAxis;
    this.options.series = series;
  }
}
