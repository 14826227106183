import { deepClone } from '../CloneHelper';

import { ITimeInterval } from './ITimeInterval';

/** Ограничение времени */
export interface ITimeLimits {
  /** Ограничение внутри дня */
  day?: ITimeInterval;
  /** Ограничение внутри недели (дни недели) */
  week?: number[];
  /** Ограничение внутри месяца (дни месяца) */
  month?: number[];
}

/**
 * Обработка ограничений времени
 * @param timeLimits Ограничения времени
 */
export function processingTimeLimits(timeLimits: ITimeLimits): ITimeLimits {
  const result = deepClone(timeLimits) as ITimeLimits;

  // Приводим значения к нужному виду
  if (!isFinite(result.day.from.h)) { result.day.from.h = 0; }
  if (!isFinite(result.day.from.m)) { result.day.from.m = 0; }
  if (!isFinite(result.day.to.h)) { result.day.to.h = 23; }
  if (!isFinite(result.day.to.m)) { result.day.to.m = 59; }

  // tslint:disable:no-bitwise
  result.day.from.h = ~~result.day.from.h;
  result.day.from.m = ~~result.day.from.m;
  result.day.to.h = ~~result.day.to.h;
  result.day.to.m = ~~result.day.to.m;
  // tslint:enable:no-bitwise

  if (result.day.from.h > 23) { result.day.from.h = 23; }
  if (result.day.from.h < 0) { result.day.from.h = 0; }
  if (result.day.to.h > 23) { result.day.to.h = 23; }
  if (result.day.to.h < 0) { result.day.to.h = 0; }
  if (result.day.from.m > 59) { result.day.from.m = 59; }
  if (result.day.from.m < 0) { result.day.from.m = 0; }
  if (result.day.to.m > 59) { result.day.to.m = 59; }
  if (result.day.to.m < 0) { result.day.to.m = 0; }

  // Подчищаем неиспользуемые поля
  if (result.day.from.h === 0 && result.day.from.m === 0 &&
    result.day.to.h === 23 && result.day.to.m === 59) {
    delete result.day;
  }

  if (result.week && !result.week.length) {
    delete result.week;
  }

  if (result.month && !result.month.length) {
    delete result.month;
  }

  if (!result.day && !result.week && !result.month) {
    return null;
  }
  return result;
}
