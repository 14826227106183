import { Component, Input, OnInit } from '@angular/core';

import { UnitRightType } from '../../../../shared/rights/RightType';
import { IMoveDetection } from '../../../../shared/units/IMoveDetection';

/**
 * Компонент с настройками детектора поездок по объекту мониторинга
 */
@Component({
  selector: 'unit-move-detection',
  templateUrl: './unit.move-detection.component.html',
  styleUrls: []
})
export class UnitMoveDetectionComponent implements OnInit {

  /** Данные детектора поездок */
  @Input() public moveDetection: IMoveDetection;

  /** Права доступа на объект мониторинга */
  @Input() public rights: number;

  /** Признак режима добавления */
  @Input() public isAddRegime: boolean;

  /** Признак только для чтения */
  public isReadOnly = false;

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    // tslint:disable-next-line:no-bitwise
    if (!this.isAddRegime && !(this.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)) {
      this.isReadOnly = true;
    }
  }
}
