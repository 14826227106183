import { CommonModule } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Injector, ModuleWithProviders, NgModule } from '@angular/core';

import { BgPopoverHolderComponent } from './bg-popover-holder.component';
import { BgPopoverWrapperComponent } from './bg-popover-wrapper.component';
import { BgPopoverService } from './bg-popover.service';

export function bgPopoverServiceFactory(
  resolver: ComponentFactoryResolver,
  applicationRef: ApplicationRef,
  injector: Injector
) {
  return new BgPopoverService(resolver, applicationRef, injector);
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    BgPopoverHolderComponent,
    BgPopoverWrapperComponent
  ],
  providers: [
    BgPopoverService
  ],
  entryComponents: [
    BgPopoverHolderComponent,
    BgPopoverWrapperComponent
  ]
})
export class BgPopoverModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: BgPopoverModule,
      providers: [
        {
          provide: BgPopoverService,
          useFactory: bgPopoverServiceFactory,
          deps: [ComponentFactoryResolver, ApplicationRef, Injector]
        }
      ]
    };
  }
}
