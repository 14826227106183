import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import * as _ from 'underscore';

import { ISortInfo } from '../../../shared/ISortInfo';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { TrackingService } from '../../services/tracking.service';

/**
 * Интерфейс компонента выбора объектов мониторинга
 */
export interface ISelectUnitsComponent {

  /** Идентификатор сессии */
  sessionId: string;
}

/**
 * Компонент выбора объектов мониторинга
 */
@Component({
  selector: 'select-units',
  templateUrl: './select-units.component.html',
  styleUrls: ['./select-units.component.scss']
})
export class SelectUnitsComponent
extends DialogComponent<ISelectUnitsComponent, boolean>
implements ISelectUnitsComponent {

  /** Идентификатор сессии */
  public sessionId: string;

  /** Текст сообщения об ошибке */
  public error: string;

  /** Текст поиска */
  public search: string;

  /** Параметры сортировки */
  public sort: ISortInfo = { field: 'name', isDescending: false };

  /** Список объектов мониторинга */
  public units: ISelectUnitsItem[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для работы с ДИУП
   * @param trackingService Сервис для работы с режимом слежения
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private trackingService: TrackingService,
    private loadingService: LoadingService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: ISelectUnitsComponent) {
    this.loadingService.startLoading();
    this.crudService.getListLight(CRUDEntityType.UNIT).subscribe(
      (units) => {
        this.units = units.map(
          (unit) => ({
            id: unit.id,
            name: unit.name,
            checked: false
          })
        );

        this.trackingService.getTrackingUnits(
          data.sessionId
        ).subscribe(
          (trackingUnits) => {
            trackingUnits.forEach(
              (trackingUnit) => {
                const unit = _.find(this.units, (item) => item.id === trackingUnit.id);
                if (unit) {
                  unit.checked = true;
                }
              }
            );
            this.loadingService.stopLoading();
          },
          (error) => {
            this.loadingService.stopLoading();
            this.error = error;
          }
        );
      },
      (error) => {
        this.loadingService.stopLoading();
        this.error = error;
      }
    );

    return super.fillData(data);
  }

  /**
   * Изменение видимости объекта мониторинга в сессии
   * @param unit Объект мониторинга, видимость которого была изменена
   */
  public checkUnit(unit: ISelectUnitsItem) {
    if (unit.checked) {
      this.trackingService.addUnit(
        this.sessionId, unit.id
      ).subscribe(
        // tslint:disable-next-line:no-empty
        () => { },
        (error) => this.error = error
      );
    } else {
      this.trackingService.deleteUnit(
        this.sessionId, unit.id
      ).subscribe(
        // tslint:disable-next-line:no-empty
        () => { },
        (error) => this.error = error
      );
    }
  }
}

/**
 * Интерфейс, представляющий данные объекта мониторинга для выбора
 */
interface ISelectUnitsItem {
  /** Идентификатор записи */
  id: string;

  /** Наименование объекта мониторинга */
  name: string;

  /** Признак выбранного */
  checked: boolean;
}
