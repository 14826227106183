import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { IAutoBinding } from '../../../shared/auto-binding/IAutoBinding';
import { LinkObjectType } from '../../../shared/LinkObjectType';
import { AccountRightType } from '../../../shared/rights/RightType';
import { IUnitLight } from '../../../shared/units/IUnitLight';
import { IListItem } from '../../classes/IListItem';
import { AccountsService } from '../../services/accounts.service';
import { AutoBindingService } from '../../services/auto-binding.service';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { localeSort } from '../../utils/sort';
import { SelectItemsComponent } from '../select-items/select-items.component';

/**
 * Интерфейс, описывающий параметры,
 * передаваемые в компонент с подробной информацией
 * при создании на его основе модального окна
 */
export interface IAutoBindingComponent {
  /** Идентификатор учетной записи, выбранной в окне списка водителей/прицепов */
  selectedAccountId: string;
  /** Тип объектов, для которого задается список */
  objectType: LinkObjectType;
}

/**
 * Компонент для работы с автоматическими прикреплениями
 */
@Component({
  selector: 'auto-binding',
  templateUrl: './auto-binding.component.html',
  styleUrls: ['./auto-binding.component.scss']
})
export class AutoBindingComponent extends DialogComponent<IAutoBindingComponent, boolean>
  implements IAutoBindingComponent {
  /**
   * Список учетных записей, которые может видеть пользователь
   */
  public accounts: IListItem<string>[] = [];

  /** Выбранная учетная запись */
  public selectedAccountId: string = null;

  /** Тип объектов, для которого задается список */
  public objectType: LinkObjectType;

  /** Общий список ТС */
  public units: IUnitLight[];

  /** Строка поиска */
  public search: string;

  /** Список выбранных объектов мониторинга */
  public selectedUnits: IListItem<string>[] = [];

  /** Список всех доступных привязок */
  private binding: IAutoBinding;

  /**
   * Конструктор
   * @param accountsService Сервис работы с учетными записями
   * @param crudService Сервис работы с CRUD
   * @param autoBindingService Сервис работы со списком автоматического прикрепления
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   */
  constructor(
    private accountsService: AccountsService,
    private crudService: CRUDService,
    private autoBindingService: AutoBindingService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    protected dialogService: DialogService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные комопнента
   */
  public fillData(data: IAutoBindingComponent) {
    const changeRight = this.objectType === LinkObjectType.DRIVER
      ? AccountRightType.CHANGE_DRIVERS
      : AccountRightType.CHANGE_TRAILERS;

    this.loadingService.wrap(this.accountsService.getWithRight(changeRight), true)
      .pipe(filter((result) => !!result))
      .subscribe((accounts) => {

        this.accounts = [...accounts?.sort(localeSort)];

        const selected = this.accounts.some((account) => account.id === data.selectedAccountId);
        this.selectedAccountId = selected
          ? data.selectedAccountId
          : accounts?.shift()?.id;

        this.loadUnits();
      });

    return super.fillData(data);
  }

  /**
   * Выбор объектов в автоматическое прикрепление
   */
  public selectUnits() {
    const data = {
      items: this.units,
      selected: this.selectedUnits,
      title: 'component.auto-binding.title',
      withSearch: true
    };
    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        result?.sort(localeSort);
        this.selectedUnits = result;
      });
  }

  /**
   * Удаление объекта из автоматического прикрепления
   * @param unit Объект мониторинга
   */
  public deleteUnit(unit: IListItem<string>) {
    const index = this.selectedUnits.indexOf(unit);
    if (index !== -1) {
      this.selectedUnits.splice(index, 1);
    }
  }

  /**
   * Выполняет операции по нажатию кнопки ОК окна
   */
  public confirm() {
    this.binding.unitIds = this.selectedUnits.map((u) => u.id);
    this.loadingService.wrap(this.autoBindingService.addUpdate(this.binding))
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Обработка при изменении выбранной учетной записи
   */
  public onChangeAccount() {
    this.loadingService.wrap(this.autoBindingService.get(this.selectedAccountId, this.objectType))
      .subscribe((result) => {
        this.binding = result;
        this.selectedUnits = this.units.filter((unit) => this.binding.unitIds.includes(unit.id));
      });
  }

  /**
   * Загрузка списка объектов мониторинга
   */
  private loadUnits() {
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT))
      .subscribe((units) => {
        this.units = units?.sort(localeSort);
        if (this.selectedAccountId) {
          this.onChangeAccount();
        }
      });
  }
}
