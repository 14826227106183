/**
 * Тип сущности истории
 */
export enum HistoryEntityType {
  /** Оборудование */
  EQUIPMENT = 1,
  /** Учетная запись */
  ACCOUNT,
  /** Объект мониторинга */
  UNIT,
  /** Сим */
  SIM,
  /** Смена пароля */
  CHANGE_PASSWORD,
  /** Ретранслятор */
  RETRANSLATOR
}
