import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { HistoryEntityType, IHistory } from '../../../../shared/crm/hist';
import { IClientHistory } from '../../../classes/IClientHistory';
import { HistoryService } from '../../../services/history.service';
import { LoadingService } from '../../../services/loading.service';
import { AccountComponent } from '../../account/account.component';
import { UnitComponent } from '../../unit/unit.component';
import { UserComponent } from '../../user/user.component';
import { EquipmentsEditComponent } from '../equipments/edit/equipments.edit.component';
import { SIMCardsEditComponent } from '../sim-cards/edit/sim-cards.edit.component';
import { TariffEditComponent } from '../tariffs/edit/tariffs.edit.component';

/**
 * Компонент для отображения истории
 */
@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  /** Список записей истории */
  @Input() public history: IClientHistory[];

  /** Генератор событий об изменении списка записей истории */
  @Output() public historyChange = new EventEmitter<IHistory[]>();

  /** Тип сущности, для которой отображается история */
  @Input() public type: HistoryEntityType;

  /** Идентификатор записи, для которого отображается история */
  @Input() public id: string;

  /** Тип истории */
  public hType = HistoryEntityType;

  /** Поиск по истории */
  public search: string;

  /**
   * Конструктор
   * @param historyService Сервис работы с истосрией
   * @param loadingService Сервис отображения процесса загрузки
   * @param dialogService Сервис диалоговых окон
   */
  constructor(
    private historyService: HistoryService,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {
  }

  /**
   * Получение класса для иконки записи истории
   * @param hist Запись истории
   */
  public getIconClass(hist: IClientHistory) {
    switch (hist.itemType) {

      case 'state':
        return 'state';

      case 'block':
        return !hist.isRemove ? 'remove' : null;

      case 'accountBlock':
        return !hist.isRemove ? 'remove' : null;

      default:
        return hist.isRemove ? 'remove' : null;
    }
  }

  /**
   * Отображение детальной информации по элементу,
   * изменившемуся в записи истории
   * @param hist Запись истории
   */
  public showDetail(hist: IClientHistory) {
    if (!hist.item?.id || hist.itemType === 'state') {
      return;
    }

    let component;
    let data;
    switch (hist.itemType) {
      case 'unit':
        component = UnitComponent;
        data = { unitId: hist.unit.id };
        break;

      case 'account':
        component = AccountComponent;
        data = { accountId: hist.client.id };
        break;

      case 'equipment':
        component = EquipmentsEditComponent;
        data = { equipmentId: hist.equipment.id };
        break;

      case 'sim':
        component = SIMCardsEditComponent;
        data = { simCardId: hist.sim.id };
        break;

      case 'tariff':
        component = TariffEditComponent;
        data = { tariffId: hist.tariff.id };
        break;

      case 'changePassword':
        component = UserComponent;
        data = { userId: hist.changePasswordUser.id };
        break;

      case 'accountBlock':
        component = UserComponent;
        data = { userId: hist.changeBlockedClient.id };
        break;

      default:
        return;
    }

    this.dialogService.addDialog(component, data)
      .pipe(filter((result) => !!result))
      .subscribe(() => this.loadHistory());
  }

  /**
   * Обработки после загрузки компонента
   */
  public ngOnInit(): void {
    if (!this.history) {
      this.loadHistory();
    }
  }

  /**
   * Загрузка истории
   */
  private loadHistory() {
    this.loadingService
      .wrap(this.historyService.get(this.id, this.type), true)
      .subscribe((history) => this.history = this.historyService.processHistory(history, this.type));
  }
}
