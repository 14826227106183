import { CountryCode } from './CountryCodes';
import { ProtocolType } from './ProtocolType';

/** Элемент справочника */
export interface IRefItem extends IRefItemGeneric<string> {
  id: string;
}

/**
 * Обобщенный интерфейс элемента справочника
 */
export interface IRefItemGeneric<T> {
  /** Идентификатор учетной записи дилера */
  accountId: T;
  /** Тип справочника */
  type: RefType;
  /** Наименование */
  name: string;
  /**
   * Стоимость
   * (для справочников типов трекеров
   * и доп. оборудования, тарифов SIM-карт)
   */
  cost?: number;
  /** Тип протокола (для справочников типов трекеров) */
  protocol?: ProtocolType;
  /** Код страны */
  countryCode?: CountryCode;
  /** Валюта */
  countryCurrency?: string;
  /** Признак приостановки работы (для справочника состояний оборудования) */
  isSuspend?: boolean;
}

/** Типы справочников */
export enum RefType {
  /** Типы трекеров */
  TRACKER = 1,
  /** Типы доп. оборудования */
  OPTIONAL_EQUIPMENT,
  /** Производители */
  MANUFACTURER,
  /** Состояния оборудования */
  EQUIPMENT_STATE,
  /** Тарифы SIM-карт */
  SIM_TARIFF,
  /** Источники уч. записей */
  ACCOUNT_ORIGIN,
  /** Мобильные операторы */
  MOBILE_OPERATOR,
  /** Код страны */
  COUNTRY_CODES
}

/**
 * Получение списка всех типов справочников
 */
export function getAllRefTypes(): RefType[] {
  return [
    RefType.TRACKER, RefType.OPTIONAL_EQUIPMENT,
    RefType.MANUFACTURER, RefType.EQUIPMENT_STATE,
    RefType.SIM_TARIFF, RefType.ACCOUNT_ORIGIN, RefType.MOBILE_OPERATOR,
    RefType.COUNTRY_CODES
  ];
}

/**
 * Получение наименования типа справочника
 * @param type Тип справочника
 */
export function getRefTypeName(type: RefType): string {
  switch (type) {
    case RefType.TRACKER: return 'enums.crm.ref-type.tracker';
    case RefType.OPTIONAL_EQUIPMENT: return 'enums.crm.ref-type.equipment';
    case RefType.MANUFACTURER: return 'enums.crm.ref-type.manufacturers';
    case RefType.EQUIPMENT_STATE: return 'enums.crm.ref-type.state';
    case RefType.SIM_TARIFF: return 'enums.crm.ref-type.tariff';
    case RefType.ACCOUNT_ORIGIN: return 'enums.crm.ref-type.account';
    case RefType.MOBILE_OPERATOR: return 'enums.crm.ref-type.mobile-operator';
    case RefType.COUNTRY_CODES: return 'enums.crm.ref-type.country_codes';
    default: return 'enums.crm.ref-type.unknown';
  }
}
