import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Сервис для работы с справочниками
 */
@Injectable()
export class RefsService {
  /**
   * Поиск
   */
  public search: string;
  /**
   * Передаем создание нового итема
   */
  public addItem = new Subject<any>()
}