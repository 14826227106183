/**
 * Типы полей в таблицах отчетов
 */
export enum ReportTableColumnType {
  /** Время */
  TIME = 1,
  /** Время начала периода */
  TIME_START,
  /** Время окончания периода */
  TIME_END,
  /** Продолжительность */
  TIME_LENGTH,
  /** Полная продолжительность */
  TIME_FULL,
  /** Время остановки */
  TIME_STOP,
  /** Время стоянки */
  TIME_PARKING,
  /** Время движения */
  TIME_MOVE,
  /** Время от предыдущего до текущего */
  TIME_PREV,
  /** Время от текущего до следующего */
  TIME_NEXT,
  TIME_PROD,
  TIME_IDLE,
  TIME_ENG_PROD,
  TIME_ENG_IDLE,
  /** Заметки */
  NOTE,
  /** Водитель */
  DRIVER,
  /** Прицеп */
  TRAILER,
  /** Качество вождения: Штраф */
  FINE,
  /** Качество вождения: Рейтинг */
  RATING,
  /** Пробег */
  MILEAGE,
  MILEAGE_CORRECTED,
  MILEAGE_START,
  MILEAGE_END,
  MILEAGE_PREV,
  MILEAGE_PREV_CORRECTED,
  MILEAGE_CITY,
  MILEAGE_HIGHWAY,
  MILEAGE_MOVE_DETECT,
  /** Средние обороты двигателя */
  ENGINE_SPEED_AVG,
  /** Максимальные обороты двигателя */
  ENGINE_SPEED_MAX,
  /** Температура */
  TEMP,
  TEMP_AVG,
  TEMP_MIN,
  TEMP_MAX,
  TEMP_START,
  TEMP_END,
  /** Скорость */
  SPEED,
  SPEED_AVG,
  SPEED_MAX,
  SPEED_LIMIT,
  SPEED_START,
  SPEED_END,
  /** Наименование геозоны */
  GEOZONE_NAME,
  GEOZONE_DESCR,
  GEOZONE_TYPE,
  GEOZONE_SQUARE,
  GEOZONE_VISITS,
  /** Счетчик */
  COUNTER,
  COUNTER_START,
  COUNTER_END,
  /** Объем топлива */
  FUEL,
  /** Объем топлива на начало периода */
  FUEL_START,
  /** Объем топлива на конец периода */
  FUEL_END,
  /** Разница в объеме топлива */
  FUEL_DIF,
  /** Минимальный уровень топлива */
  FUEL_MIN,
  /** Максимальный уровень топлива */
  FUEL_MAX,
  /** Потрачено топлива */
  FUEL_SPENT,
  /** Потрачено топлива по ДУТ */
  FUEL_SPENT_BY_DUT,
  /** Потрачено топлива по ДУТ в движении */
  FUEL_SPENT_BY_DUT_MOVE,
  /** Потрачено топлива по ДУТ на холостом ходу */
  FUEL_SPENT_BY_DUT_IDLE,
  /** Потрачено топлива по ИДРТ */
  FUEL_SPENT_BY_IMP,
  /** Потрачено топлива по ИДРТ в движении */
  FUEL_SPENT_BY_IMP_MOVE,
  /** Потрачено топлива по ИДРТ на холостом ходу */
  FUEL_SPENT_BY_IMP_IDLE,
  /** Потрачено топлива по ДАРТ */
  FUEL_SPENT_BY_ABS,
  /** Потрачено топлива по ДАРТ в движении */
  FUEL_SPENT_BY_ABS_MOVE,
  /** Потрачено топлива по ДАРТ на холостом ходу */
  FUEL_SPENT_BY_ABS_IDLE,
  /** Потрачено топлива по ДМРТ */
  FUEL_SPENT_BY_INS,
  /** Потрачено топлива по ДМРТ в движении */
  FUEL_SPENT_BY_INS_MOVE,
  /** Потрачено топлива по ДМРТ на холостом ходу */
  FUEL_SPENT_BY_INS_IDLE,
  /** Расход топлива */
  FUEL_RATE,
  /** Средний расход топлива (л/ч) */
  FUEL_RATE_AVG,
  FUEL_RATE_AVG_BY_DUT,
  FUEL_RATE_AVG_BY_DUT_MOVE,
  FUEL_RATE_AVG_BY_DUT_IDLE,
  FUEL_RATE_AVG_BY_IMP,
  FUEL_RATE_AVG_BY_IMP_MOVE,
  FUEL_RATE_AVG_BY_IMP_IDLE,
  FUEL_RATE_AVG_BY_ABS,
  FUEL_RATE_AVG_BY_ABS_MOVE,
  FUEL_RATE_AVG_BY_ABS_IDLE,
  FUEL_RATE_AVG_BY_INS,
  FUEL_RATE_AVG_BY_INS_MOVE,
  FUEL_RATE_AVG_BY_INS_IDLE,
  FUEL_EVENT_TYPE,
  FUEL_MILEAGE_AVG_BY_DUT,
  FUEL_MILEAGE_AVG_BY_IMP,
  FUEL_MILEAGE_AVG_BY_ABS,
  FUEL_MILEAGE_AVG_BY_INS,
  /** Заправка */
  FILLING,
  /** Зарегистрированная заправка */
  FILLING_REG,
  /** Описание зарегистрированной заправки */
  FILLING_REG_DESCR,
  /** Количество заправок */
  FILLING_COUNT,
  /** Слив */
  THEFT,
  /** Количество сливов */
  THEFT_COUNT,
  /** Положение */
  POS,
  /** Начальное положение */
  POS_START,
  /** Конечное положение */
  POS_END,
  /** Координаты */
  POS_COORDS,
  /** Начальные координаты */
  POS_START_COORDS,
  /** Конечные координаты */
  POS_END_COORDS,
  INTERSECT,
  INTERSECT_FUEL,
  /** Имя датчика */
  SENSOR_NAME,
  /** Моточасы */
  MH,
  /** Моточасы на начало периода */
  MH_START,
  /** Моточасы на конец периода */
  MH_END,
  /** Продуктивные моточасы */
  MH_PROD,
  /** Продуктивные моточасы в движении */
  MH_MOVE_RPOD,
  /** Утилизация */
  MH_UTIL,
  /** Продуктивная утилизация */
  MH_UTIL_PROD,
  /** Количество остановок */
  STOP_COUNT,
  /** Количество стоянок */
  PARKING_COUNT,
  /** Вес */
  WEIGHT,
  /** Средний вес */
  WEIGHT_AVG,
  /** Минимальный вес */
  WEIGHT_MIN,
  /** Максимальный вес */
  WEIGHT_MAX,
  /** Вес на начало периода */
  WEIGHT_START,
  /** Вес на конец периода */
  WEIGHT_END,
  /** Количество пассажиров */
  PASSENGER_COUNT,
  /** Количество привышений скорости */
  SPEEDING_COUNT,
  /** Начальное значение произвольного датчика */
  ARB_SENSOR_START,
  /** Конечное значение произвольного датчика */
  ARB_SENSOR_END,
  /** Разница между начальным и конечным значением произвольного датчика */
  ARB_SENSOR_DIF,
  /** Средний расход топлива (л/100км) */
  FUEL_RATE_AVG_100KM,
  FUEL_RATE_AVG_100KM_BY_DUT,
  FUEL_RATE_AVG_100KM_BY_DUT_MOVE,
  FUEL_RATE_AVG_100KM_BY_IMP,
  FUEL_RATE_AVG_100KM_BY_IMP_MOVE,
  FUEL_RATE_AVG_100KM_BY_ABS,
  FUEL_RATE_AVG_100KM_BY_ABS_MOVE,
  FUEL_RATE_AVG_100KM_BY_INS,
  FUEL_RATE_AVG_100KM_BY_INS_MOVE,
  /** Объем топлива (для отчета по движению топлива) */
  FUEL_VOL,
  /** Количество включений цифрового датчика */
  DIGITAL_SENSOR_ON_COUNT,
  /** Дата события */
  EVENT_DATE,
  /** Пробег на момент заправки по чеку */
  CHECK_MILEAGE,
  /** Объем заправки по чеку */
  CHECK_VALUE,
  /** Пробег от предыдущей заправки по чеку до текущей */
  CHECK_MILEAGE_PREV,
  /** Разница в % в пробегах по системе мониторинга и по чеку */
  CHECK_DIF,
  /** Расход топлива по чеку, л/100км */
  CHECK_FUEL_RATE_AVG_100KM,
  /** Количество заправок по чеку */
  CHECK_COUNT,
  /** Наименование улицы */
  STREET_NAME,
  /** Количество улиц */
  STREET_COUNT,
  /** Количество событий/нарушений */
  EVENT_COUNT,
  /** Описание события/нарушения */
  EVENT_DESCRIPTION,
  /** Качество вождения: Нарушение */
  DQ_NAME,
  /** Качество вождения: Значение */
  DQ_VALUE,
  /** Качество вождения: Кол-во */
  DQ_COUNT,
  /** Качество вождения: Оценка */
  DQ_SCORE,
  /** Начало движения */
  MOVE_START,
  /** Окончание движения */
  MOVE_END,

  /** Техобслуживание: Вид работ */
  MAINTENANCE_WORK,
  /** Техобслуживание: Длительность */
  MAINTENANCE_DURATION,
  /** Техобслуживание: Стоимость */
  MAINTENANCE_COST,
  /** Техобслуживание: Пробег */
  MAINTENANCE_MILEAGE,
  /** Техобслуживание: Моточасы */
  MAINTENANCE_MH,

  /** Назначения: Количество */
  ASSIGNMENTS_COUNT,
  /** Назначения: Длительность */
  ASSIGNMENTS_DURATION,

  /** Норма расхода топлива (л/100км) */
  FUEL_NORMS_LP100KM,
  /** Разница между фактическим расходом топлива и расходом по нормам */
  FUEL_NORMS_DIF,
  /** Экономия денег на топливе относительно норм */
  FUEL_NORMS_ECONOMY,

  /** Маршруты: плановое время входа в точку маршрута */
  ROUTES_POINT_PLAN_START_TIME,
  /** Маршруты: плановое время выхода из точки маршрута */
  ROUTES_POINT_PLAN_END_TIME,
  /** Маршруты: фактическое время входа в точку маршрута */
  ROUTES_POINT_FACT_START_TIME,
  /** Маршруты: фактическое время выхода из точки маршрута */
  ROUTES_POINT_FACT_END_TIME,
  /** Маршруты: состояние посещенности точки маршрута */
  ROUTES_POINT_STATE,

  /** Сводка: процент движения */
  MOVE_PCT,
  /** Сводка: процент простоя */
  STOP_PCT,
  /** Сводка: время от начала первого до окончания последнего движения */
  ALL_MOVE_TIME,
  /** Сводка: суммарное время простоя за ALL_MOVE_TIME */
  STOP_MOVE_TIME,

  /** Маршруты: координаты центра точки маршрута */
  ROUTES_COORDS,

  /** Загрузки: количество */
  LOADINGS_COUNT,

  /** Геозоны: центр */
  GEOZONE_CENTER,

  /** Активность пользователя: тип активности пользователя */
  USER_ACTIVITY_TYPE,
  /** Активность пользователя: значение активности пользователя */
  USER_ACTIVITY_VALUE,

  /** Потрачено топлива по нормам */
  FUEL_SPENT_BY_NORMS,
  /** Потрачено топлива по нормам в движении */
  FUEL_SPENT_BY_NORMS_MOVE,
  /** Потрачено топлива по нормам на холостом ходу */
  FUEL_SPENT_BY_NORMS_IDLE,
  /** Ср. расход по нормам, л/ч */
  FUEL_RATE_AVG_BY_NORMS,
  /** Ср. расход по нормам в движении, л/ч */
  FUEL_RATE_AVG_BY_NORMS_MOVE,
  /** Ср. расход по нормам на холостом ходу */
  FUEL_RATE_AVG_BY_NORMS_IDLE,
  /** Ср. пробег по нормам на единицу топлива */
  FUEL_MILEAGE_AVG_BY_NORMS,
  /** Ср. расход по нормам, л/100км */
  FUEL_RATE_AVG_100KM_BY_NORMS,
  /** Ср. расход по нормам в движении, л/100км */
  FUEL_RATE_AVG_100KM_BY_NORMS_MOVE,
  /** Потрачено топлива по нормам (скорректировано) */
  FUEL_SPENT_BY_NORMS_CORRECTED,

  /** Геозоны: адрес */
  GEOZONE_POS,

  /** Потери связи: количество */
  DC_COUNT,

  /** Сводка: цена одного литра топлива */
  FUEL_PRICE,
  /** Сводка: стоимость израсходованного топлива */
  FUEL_COST,
  /** Норма расхода топлива (л/ч) */
  FUEL_NORMS_LPH,

  /** Код геозоны */
  GEOZONE_EXTCODE,
  /** Средняя скорость между */
  SPEED_AVG_PREV,
  /** Максимальная скорость между */
  SPEED_MAX_PREV,
  /** Длительность стоянок между */
  TIME_PARKING_PREV,
  /** Объем мусора (куб.м.) из геозоны */
  GARBAGE_VOL,
  /** Name of vehicle */
  UNIT_NAME,
  /** Brand of vehicle */
  UNIT_BRAND,
  /** Model of vehicle */
  UNIT_MODEL,
  /** Registration plate of vehicle */
  UNIT_REGISTRATION_PLATE,
  /** Garage plate of vehicle */
  UNIT_GARAGE_PLATE,
  /** Additional info about vehicle */
  UNIT_DESCRIPTION
}
