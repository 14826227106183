import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Компонент для перенаправлений
 */
@Component({
  selector: 'app-redirect',
  template: `<div>Redirecting...</div>`
})
export class RedirectComponent {

  /**
   * Конструктор
   * @param router Маршрутизатор
   */
  constructor(router: Router) {
    const url = router.parseUrl(router.url).queryParams.url;
    if (url) {
      router.navigateByUrl(url).then();
    } else {
      router.navigate(['']).then();
    }
  }

}
