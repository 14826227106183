import { Component, Input, OnInit } from '@angular/core';

import { IPayment } from '../../../../shared/crm/IPayment';
import { IAccountBalance } from '../../../../shared/crm/operations/IAccountBalance';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { OperationsService } from '../../../services/operations.service';

/**
 * Компонент для внесения оплаты по учетной записи
 */
@Component({
  selector: 'account-payment',
  templateUrl: './account.payment.component.html'
})
export class AccountPaymentComponent implements OnInit {

  /** Идентификатор учетной записи */
  @Input() public accountId: string;

  /** Данные платежа */
  public payment: IPayment;

  /** Информация по состоянию счета и последней оплате */
  public balance: IAccountBalance;

  /**
   * Конструктор
   * @param operationsService Сервис работы с операциями
   * @param modalService Сервис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private operationsService: OperationsService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) {
    this.payment = { accountId: null, sum: 0, date: new Date().getTime() };
    this.balance = { lastPayment: null };
  }

  /**
   * Добавление оплаты
   */
  public add() {
    if (!this.isCanAdd) {
      return;
    }

    const payment = {
      ...this.payment,
      accountId: this.accountId,
      sum: Math.round(this.payment.sum * 100)
    };

    this.loadingService
      .wrap(this.operationsService.addPayment(payment), true)
      .subscribe(() => {
        this.loadBalance();

        // Сбрасываем то, что ввел пользователь
        this.payment = {
          accountId: this.accountId,
          sum: 0,
          date: new Date().getTime()
        };

        this.modalService.showInfo('component.account.payment.added');
      });
  }

  /**
   * Получение признака возможности добавления оплаты
   */
  public get isCanAdd() {
    if (isNaN(+this.payment.sum)) return false;
    return !!this.accountId && !!this.payment?.sum && !!this.payment?.date;
  }

  /**
   * Обработки после инициализации компонента
   */
  public ngOnInit() {
    this.loadBalance();
  }

  /**
   * Загрузка информации о состоянии счета и последнему платежу
   */
  private loadBalance() {
    this.operationsService.getBalance(
      this.accountId
    ).subscribe(
      (balance) => {
        balance.balance = balance.balance && (balance.balance / 100);
        balance.balanceLimit = balance.balanceLimit && (balance.balanceLimit / 100);

        this.balance = balance;
      },
      (error) => this.modalService.showError(error)
    );
  }
}
