import { Component, OnDestroy } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subscription } from 'rxjs';

import { FastPeriodType, getAllFastPeriodTypes, getFastPeriodTypeName } from '../../../../shared/FastPeriodType';
import { ITrackingSettings } from '../../../../shared/tracking/ITrackingSettings';
import { IListItem } from '../../../classes/IListItem';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { IReportTemplateInfo, ReportTemplatesService, ReportType } from '../../../services/report-templates.service';
import { StoreService } from '../../../services/store.service';
import { TrackingService } from '../../../services/tracking.service';

/**
 * Компонент для отображения настроек слежения
 */
@Component({
  selector: 'tracking-settings',
  templateUrl: './tracking.settings.component.html',
  styleUrls: ['./tracking.settings.component.scss']
})
export class TrackingSettingsComponent implements OnDestroy {

  /** Visibilities of modal window */
  public visible: boolean;

  /** Настройки слежения */
  public settings: ITrackingSettings;

  /** Типы периодов для формирования быстрого рейса/отчета */
  public fastPeriodTypes: IListItem<FastPeriodType>[] = [];

  /** Признак выполнения загрузки */
  public isLoading = false;

  /** Список шаблонов отчетов для объектов мониторинга */
  public unitReports: IReportTemplateInfo[] = [];

  /** Список шаблонов отчетов для групп объектов мониторинга */
  public unitGroupReports: IReportTemplateInfo[] = [];

  /** Подписка на открытие / закрытие окна */
  private visibilitiesSubscription: Subscription;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param monitoringService
   * @param trackingService
   * @param reportTemplatesService Сервис работы с ДИУП
   * @param modalService Севрис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   * @param store
   */
  constructor(
    dialogService: DialogService,
    public monitoringService: MonitoringService,
    public trackingService: TrackingService,
    private reportTemplatesService: ReportTemplatesService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private store: StoreService
  ) {
    this.settings = {} as ITrackingSettings;

    this.visibilitiesSubscription = this.monitoringService.trackingSettingsVisibilities.subscribe(this.changeVisibility)

    this.fastPeriodTypes = getAllFastPeriodTypes()
      .map((type) => ({ id: type, name: getFastPeriodTypeName(type) }));
  }

  /**
   * Обработки после инициализации компонента
   */
  public onLoad() {
    this.reportTemplatesService.getList().subscribe((templates) => {
      this.unitReports = templates.filter((t) => t.type === ReportType.UNIT);
      this.unitGroupReports = templates.filter((t) => t.type === ReportType.UNIT_GROUP);
    });

    this.trackingService.getOnlyTrackingSettings().subscribe((ts) => this.settings = ts);
  }

  /**
   * Открытие / закрытие окна
   * @param v
   */
  private changeVisibility = (v) => {
    this.visible = v
    if (!v) return;
    this.onLoad()
  }

  /**
   * Получение признака проигрывания рейса
   */
  get isDisabled() {
    return !this.isCanSave;
  }

  /**
   * Признак возможности сохранения
   */
  get isCanSave() {
    if (this.isLoading) {
      return false;
    }

    if (!this.settings.moveThreshold || this.settings.moveThreshold < 0) {
      return false;
    }

    if (!this.settings.connectionThreshold || this.settings.connectionThreshold < 0) {
      return false;
    }

    if (this.settings.fastUnitReportTemplateId && !this.settings.fastUnitReportPeriod) {
      return false;
    }

    if (this.settings.fastGroupReportTemplateId && !this.settings.fastGroupReportPeriod) {
      return false;
    }

    return true;
  }

  /**
   * Accept changes
   */
  confirm() {
    if (!this.isCanSave) {
      return;
    }

    this.monitoringService.updateTrackingSettings(this.settings).subscribe(() => {
      this.close();
    })
  }

  /**
   * Close modal
   */
  public close() {
    this.monitoringService.trackingSettingsVisibilities.next(false);
  }

  ngOnDestroy(): void {
    this.visibilitiesSubscription.unsubscribe()
  }

}