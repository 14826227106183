import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGeozoneList } from '../../shared/geozones/IGeozoneList';
import { IGeozoneMap } from '../../shared/geozones/IGeozoneMap';
import { IGeozoneTrack } from '../../shared/geozones/IGeozoneTrack';
import { ICoord } from '../../shared/ICoord';
import { IClientGeozone } from '../classes/IClientGeozone';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { MonitoringService } from './monitoring.service';
import { StoreService } from './store.service';

/**
 * Сервис для работы с геозонами
 */
@Injectable()
export class GeozonesService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/geozones`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param store
   * @param monitoringService
   * @param configService
   * @param loadingService
   */
  constructor(
    private http: HttpClient,
    private store: StoreService,
    private monitoringService: MonitoringService,
    private configService: ConfigService,
    private loadingService: LoadingService
  ) {}

  public getGeozone(id: string): Observable<IClientGeozone> {
    return this.http.get<IClientGeozone>(`${this.baseUrl}/${id}`)
  }

  /**
   * Получение геозон по accountId для карты
   * @param accountId Идентификатор аккаунта
   */
  public getGeozonesForMap(accountId?: string): Observable<IGeozoneMap[]> {
    const options = { headers: this.loadingService.waiterHeader }
    const params = accountId ? `?accountId=${accountId}` : ''
    return this.http.get<IGeozoneMap[]>(`${this.baseUrl}/map${params}`, options)
  }

  /**
   * Получение геозон для левой панели
   * @param accountId Идентификатор аккаунта
   */
  public getAllForList(accountId?: string): Observable<IGeozoneList[]> {
    const options = { headers: this.loadingService.waiterHeader }
    const params = accountId ? `?accountId=${accountId}` : ''
    return this.http.get<IGeozoneList[]>(`${this.baseUrl}/small${params}`, options)
  }

  /**
   * Удаление геозоны
   * @param id Идентификатор геозоны
   */
  public deleteGeozone(id: string): Observable<string[]> {
    const options = { headers: this.loadingService.waiterHeader }
    const url: string = `${this.baseUrl}/${id}`;
    return this.http.delete<string[]>(url, options)
  }

  /**
   * Получение списка геозон с расстояниями
   * @param point Точка от которой будут рассчитываться расстояния до геозон
   */
  public getDistances(point: ICoord): Observable<IGeozoneTrack[]> {
    const url = `${this.baseUrl}/distance`;
    const body = {point};
    return this.http.post<IGeozoneTrack[]>(url, body);
  }

  /**
   * Отправка запроса на получение таблицы эксель
   * @param accountId Идентификатор аккаунта
   * @param geozoneIds
   */
  public getExcel(accountId: string, geozoneIds: string[]): Observable<Blob> {
    const url = `${this.baseUrl}/xlsx`;
    const body = {accountId, geozoneIds};
    const options: object = {responseType: 'blob'};
    return this.http.post<Blob>(url, body, options);
  }

  /**
   * Отправка запроса на получение названия Юр. Лица по ИНН
   */
  public getLegalPersonName(inn: string) {
    const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`;
    const body = {query: inn};
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Token ba2ffc94051dea55ace24e87bda2b8e13f0eeb86');
    const options: object = {headers};
    return this.http.post<any>(url, body, options);
  }

  /**
   * Удаление группы геозон
   * @param ids Идентификаторы геозон
   */
  public deleteSelectedGeozones(ids: string[]): Observable<string[]> {
    const url: string = `${this.baseUrl}/delete`;
    return this.http.post<string[]>(url, ids);
  }

  /**
   * Удаление связанных с геозонами элементов
   */
  public deleteRelatedGeozone(ids: string[]): boolean {
    let isNeedUpdateRoute: boolean = false;
    const geozones = this.store.geozones.filter(g => ids.some((i) => i === g.id));

    for (const geozone of geozones) {
      if (geozone.checked) { this.monitoringService.hideGeozonesSubject.next([geozone]); }
      let index = this.store.geozones.indexOf(geozone);
      // Удаление геозоны из стора
      if (index !== -1) { this.store.geozones.splice(index, 1); }
      // Удаляем из групп
      for (const group of this.store.geozoneGroups) {
        index = group.trackingGeozones.indexOf(geozone);
        if (index !== -1) {
          group.trackingGeozones.splice(index, 1);
          index = group.geozones.indexOf(geozone.id);
          if (index !== -1) { group.geozones.splice(index, 1); }
        }
      }
      if (this.store.routes.some((r) => r.points.some((p) => p.geozoneId === geozone.id))) {
        isNeedUpdateRoute = true;
      }
    }
    // Обновляем маршруты при необходимости
    if(isNeedUpdateRoute) this.monitoringService.getRoutes();
    return true
  }

}
