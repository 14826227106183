import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPointDetail } from '../../shared/points/IPointDetail';

import { ConfigService } from './config.service';

/**
 * Сервис для работы с точками
 */
@Injectable()
export class PointsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/points`;

  /**
   * Конструктор
   * @param http HTTP клиент
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {}

  /**
   * Получение точки
   * @param id Идентификатор точки
   */
  public get(id: string): Observable<IPointDetail> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<IPointDetail>(url);
  }

  /**
   * Обновление точки
   * @param body Точка
   */
  public update(body: IPointDetail): Observable<string> {
    return this.http.post<string>(this.baseUrl, body);
  }

  /**
   * Установка валидности сообщений
   * @param ids Идентификаторы сообщений
   * @param valid Валидность
   */
  public setValid(ids: string[], valid: boolean): Observable<string> {
    const url = `${this.baseUrl}/setValid`;
    const body = {ids, valid};
    return this.http.post<string>(url, body);
  }

  /**
   * Получение списка параметров точки
   * @param unitId Идентификатор ТС
   */
  public getParams(unitId: string): Observable<string[]> {
    const url = `${this.baseUrl}/params/${unitId}`;
    return this.http.get<string[]>(url);
  }
}
