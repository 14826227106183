import { ScrollingModule } from '@angular/cdk-experimental/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularResizedEventModule } from 'angular-resize-event';
import { MyDatePickerModule } from 'mydatepicker';
import { MetrikaModule } from 'ng-yandex-metrika';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { FileUploadModule } from 'ng2-file-upload';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, FilterService, MessageService, PrimeNGConfig } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService as DialogPrimeService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AccountComponent } from './components/account/account.component';
import { AccountOperationsComponent } from './components/account/operations/account.operations.component';
import { AccountPaymentComponent } from './components/account/payment/account.payment.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { AccountsLoadPaymentsComponent } from './components/accounts/load-payments/accounts.load-payments.component';
import { AdminComponent } from './components/admin/admin.component';
import { AnalyticsComponent } from './components/analytics';
import { ArbitraryFieldsComponent } from './components/arbitrary-fields/arbitrary-fields.component';
import { AssignComponent } from './components/assignments/assign/assignments.assign.component';
import { AssignmentsComponent } from './components/assignments/assignments.component';
import { AutoBindingComponent } from './components/auto-binding/auto-binding.component';
import { BgInfoComponent } from './components/bg-info/bg-info.component';
import { ButtonEditComponent } from './components/button-edit/button-edit.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { ClientAddInvoiceComponent } from './components/client/add-invoice/client.add-invoice.component';
import { BalanceNotificationSettingsComponent } from './components/client/balance-notification-settings/balance-notification-settings.component';
import { ClientBalanceNotificationSettingsComponent } from './components/client/client-balance-notification-settings/client-balance-notification-settings.component';
import { ClientComponent } from './components/client/client/client.component';
import { ClientFinancesComponent } from './components/client/finances/client.finances.component';
import { UnitsActivityComponent } from './components/client/units-activity/units-activity.component';
import { ClientsComponent } from './components/crm/clients/clients.component';
import { ClientsEditComponent } from './components/crm/clients/edit/clients.edit.component';
import { CrmComponent } from './components/crm/crm/crm.component';
import { DillerComponent } from './components/crm/diller/diller.component';
import { DillerDocsSettingsComponent } from './components/crm/diller/docs-settings/diller.docs-settings.component';
import { UnblockEntityComponent } from './components/crm/diller/unblock/diller.unblock.component';
import { DocAddComponent } from './components/crm/docs/doc/add/doc.add.component';
import { DocChangeComponent } from './components/crm/docs/doc/change/doc.change.component';
import { DocComponent } from './components/crm/docs/doc/doc.component';
import { DocsComponent } from './components/crm/docs/docs.component';
import { GeneratedInvoiceComponent } from './components/crm/docs/generated-invoice/generated-invoice.component';
import { GeneratedWAReportComponent } from './components/crm/docs/generated-wareport/generated-wareport.component';
import { DocsPacketsComponent } from './components/crm/docs/packets/docs.packets.component';
import { DocsPacketsRequestParamsComponent } from './components/crm/docs/packets/request-params/docs-packets-request-params.component';
import { DocsPrintDetailComponent } from './components/crm/docs/print-detail/docs.print-detail.component';
import { DocsPrintPreviewComponent } from './components/crm/docs/print-preview/docs.print-preview.component';
import { DocsSearchComponent } from './components/crm/docs/search/docs.search.component';
import { DogovorsComponent } from './components/crm/dogovors/dogovors.component';
import { DogovorsEditComponent } from './components/crm/dogovors/edit/dogovors.edit.component';
import { EquipmentsEditComponent } from './components/crm/equipments/edit/equipments.edit.component';
import { EquipmentsComponent } from './components/crm/equipments/equipments.component';
import { HistoryComponent } from './components/crm/history/history.component';
import { RefsEditComponent } from './components/crm/refs/edit/refs.edit.component';
import { RefsItemsModalComponent } from './components/crm/refs/items-modal/refs.items-modal.component';
import { RefsItemsComponent } from './components/crm/refs/items/refs.items.component';
import { RefsComponent } from './components/crm/refs/refs.component';
import { SIMCardsEditComponent } from './components/crm/sim-cards/edit/sim-cards.edit.component';
import { SIMCardsComponent } from './components/crm/sim-cards/sim-cards.component';
import { TariffEditComponent } from './components/crm/tariffs/edit/tariffs.edit.component';
import { TariffsComponent } from './components/crm/tariffs/tariffs.component';
import { DriversDetailComponent } from './components/drivers/detail/drivers.detail.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { DriversGroupEditComponent } from './components/drivers/edit-group/drivers.edit-group.component';
import { DriversEditComponent } from './components/drivers/edit/drivers.edit.component';
import { EventComponent } from './components/event/event.component';
import { ExportAccountsComponent } from './components/export/accounts/export.accounts.component';
import { ExportUnitsComponent } from './components/export/units/export.units.component';
import { GeozonesDetailComponent } from './components/geozones/detail/geozones.detail.component';
import { GeozonesEditGroupComponent } from './components/geozones/edit-group/geozones.edit-group.component';
import { GeozonesEditComponent } from './components/geozones/edit/geozones.edit.component';
import { GeozonesComponent } from './components/geozones/geozones.component';
import { GuestAccessAddComponent } from './components/guest-access/add/guest-access.add.component';
import { GuestAccessComponent } from './components/guest-access/guest-access.component';
import { ImportAccountsComponent } from './components/import/accounts/import.accounts.component';
import { ImportUnitsComponent } from './components/import/units/import.units.component';
import { ListEditComponent } from './components/list-edit/list-edit.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AddressDetailComponent } from './components/map/address-detail/map.address-detail.component';
import { MapDetailLightComponent } from './components/map/light/map.light.component';
import { MapComponent } from './components/map/map.component';
import { MapRaceDetailComponent } from './components/map/race-detail/map.race-detail.component';
import { MapReportDetailComponent } from './components/map/report-detail/map.report-detail.component';
import { MapSettingsComponent } from './components/map/settings/map.settings.component';
import { MessagesEventsComponent } from './components/messages/events/messages.events.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MessagesPointsComponent } from './components/messages/points/messages.points.component';
import { MessagesTableComponent } from './components/messages/table/messages.table.component';
import { ModalComponent } from './components/modal/modal.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { NavigationMoreComponent } from './components/navigation/more/navigation.more.component';
import { NavComponent } from './components/navigation/nav.component';
import { NotificationSettingComponent } from './components/notifications/notification-setting/notification-setting.component';
import { NotificationSettingsComponent } from './components/notifications/notification-settings/notification-settings.component';
import { OnlineNotificationsComponent } from './components/notifications/online-notifications/online-notifications.component';
import { SystemNotificationComponent } from './components/notifications/system-notification/system-notification.component';
import { SystemNotificationsComponent } from './components/notifications/system-notifications/system-notifications.component';
import { OverlayPanelComponent } from './components/overlay-panel/overlay-panel.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PointEditComponent } from './components/point/edit/point.edit.component';
import { CreateGeozoneComponent } from './components/race/create-geozone/create-geozone.component';
import { RacePlayerComponent } from './components/race/player/race.player.component';
import { RaceComponent } from './components/race/race.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { RegisterComponent } from './components/register/register.component';
import { ReportQueueComponent } from './components/report-queue/report-queue.component';
import { ReportTemplateChartComponent } from './components/report-template/chart/report-template.chart.component';
import { ReportTemplateComponent } from './components/report-template/report-template.component';
import { ReportTemplateTableFilterComponent } from './components/report-template/table-filter/report-template.table-filter.component';
import { ReportTemplateTableComponent } from './components/report-template/table/report-template.table.component';
import { ReportTemplatesComponent } from './components/report-templates/report-templates.component';
import { ReportChartComponent } from './components/report/chart/report.chart.component';
import { ReportPreviewComponent } from './components/report/preview/report.preview.component';
import { ReportComponent } from './components/report/report.component';
import { EmptyCellTooltipComponent } from './components/report/table/empty-cell-tooltip.component';
import { ReportTableComponent } from './components/report/table/report.table.component';
import { RightsGenComponent } from './components/rights-gen/rights-gen.component';
import { RightsComponent } from './components/rights/rights.component';
import { RoutesEditComponent } from './components/routes/edit/routes.edit.component';
import { RoutesRepeatComponent } from './components/routes/repeat/routes.repeat.component';
import { RoutesComponent } from './components/routes/routes.component';
import { ItemSelectorComponent } from './components/select-items-modal/item-selector.component';
import { SelectItemsModalComponent } from './components/select-items-modal/select-items-modal.component';
import { SelectItemsComponent } from './components/select-items/select-items.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';
import { SelectUnitsComponent } from './components/select-units/select-units.component';
import { SensorComponent } from './components/sensor/sensor.component';
import { TaskComponent } from './components/task/task.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TimeLimitsComponent } from './components/time-limits/time-limits.component';
import { BgTooltipComponent } from './components/tooltip/bg-tooltip.component';
import { SendCommandComponent } from './components/tracking/send-command/send-command.component';
import { TrackingSettingsComponent } from './components/tracking/settings/tracking.settings.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { TrailersDetailComponent } from './components/trailers/detail/trailers.detail.component';
import { TrailersGroupEditComponent } from './components/trailers/edit-group/trailers.edit-group.component';
import { TrailersEditComponent } from './components/trailers/edit/trailers.edit.component';
import { TrailersComponent } from './components/trailers/trailers.component';
import { UnitGroupComponent } from './components/unit-group/unit-group.component';
import { UnitGroupsComponent } from './components/unit-groups/unit-groups.component';
import { UnitCharacteristicsComponent } from './components/unit/characteristics/unit.characteristics.component';
import { CommandsComponent } from './components/unit/commands/commands.component';
import { UnitDrivingQualityCriteriaComponent } from './components/unit/driving-quality/unit.driving-quality-criteria.component';
import { UnitDrivingQualityComponent } from './components/unit/driving-quality/unit.driving-quality.component';
import { UnitExtrasComponent } from './components/unit/extras/unit.extras.component';
import { UnitFuelComponent } from './components/unit/fuel/unit.fuel.component';
import { UnitGeneralComponent } from './components/unit/general/unit.general.component';
import { GeozoneTrackerComponent } from './components/unit/geozone-tracker/geozone-tracker.component';
import { UnitIconComponent } from './components/unit/icon/unit.icon.component';
import { UnitInGroupsComponent } from './components/unit/in-groups/unit.in-groups.component';
import { UnitMoveDetectionComponent } from './components/unit/move-detection/unit.move-detection.component';
import { UnitSensorsComponent } from './components/unit/sensors/unit.sensors.component';
import { UnitTOIntervalComponent } from './components/unit/to/unit.to-interval.component';
import { UnitTOIntervalsComponent } from './components/unit/to/unit.to-intervals.component';
import { UnitComponent } from './components/unit/unit.component';
import { UnitValidationComponent } from './components/unit/validation/unit.validation.component';
import { UnitsMoveComponent } from './components/units/move/units.move.component';
import { UnitsComponent } from './components/units/units.component';
import { UserRightsComponent } from './components/user-rights/user-rights.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from './components/users/users.component';
import { YandexTransportRepeaterComponent } from './components/yandex-transport-repeater/yandex-transport-repeater.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CdkCustomAutoSizeVirtualScroll } from './directives/custom-autosize.directive';
import { MapDetailHostDirective } from './directives/map-detail-host.directive';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { BgAuthModule } from './modules/bg-auth/bg-auth.module';
import { BgAuthService } from './modules/bg-auth/services/bg-auth.service';
import { BgDatetimepickerModule } from './modules/bg-datetimepicker/bg-datetimepicker.module';
import { BgGuestAccessService } from './modules/bg-guest-access/services/bg-guest-access.service';
import { BgPopoverModule } from './modules/bg-popover';
import {BgRepeatersModule} from './modules/bg-repeaters/bg-repeaters.module';
import { SharedModule } from './modules/shared/shared.module';
import { UiDistrictsComponent } from './modules/ui/districts/ui-districts.component';
import { UIMDistricsModule } from './modules/ui/mdistrics/ui-mdistrics.module';
import { PipesModule } from './pipes.module';
import { RoutingModule } from './routing.module';
import { AccountsService } from './services/accounts.service';
import { ActivityService } from './services/activity.service';
import { AnalyticsService } from './services/analytics.service';
import { AssignmentsService } from './services/assignments.service';
import { AuthGuard } from './services/auth-guard.service';
import { AutoBindingService } from './services/auto-binding.service';
import { BGMessagesService } from './services/b-g-messages.service';
import { CleanerService } from './services/cleaner.service';
import { ClientGuard } from './services/client-guard.service';
import { ClientService } from './services/client.service';
import { CommandTypesService } from './services/command-types.service';
import { CommandsService } from './services/commands.service';
import { ConfigService } from './services/config.service';
import { ConfirmWrapService } from './services/confirm-wrap.service';
import { CRUDService } from './services/crud.service';
import { DillerGuard } from './services/diller-guard.service';
import { DillersService } from './services/dillers.service';
import { DistrictsService } from './services/districts.service';
import { DocsService } from './services/docs.service';
import { EventsService } from './services/events.service';
import { ExportImportService } from './services/export-import.service';
import { FileService } from './services/file.service';
import { GarbageService } from './services/garbage.service';
import { GeocoderService } from './services/geocoder.service';
import { GeozoneGroupService } from './services/geozone-group.service';
import { GeozonesService } from './services/geozones.service';
import { GuestAccessService } from './services/guest-access.service';
import { HelpService } from './services/help.service';
import { HistoryService } from './services/history.service';
import { HostConfigService } from './services/host-config.service';
import { ItemSelectorService } from './services/item-selector.service';
import { LoadingService } from './services/loading.service';
import { MapService } from './services/map.service';
import { ModalDialogService } from './services/modal-dialog.service';
import { ModalService } from './services/modal.service';
import { MonitoringService } from './services/monitoring.service';
import { NavigationService } from './services/navigation.service';
import { NotificationsService } from './services/notifications.service';
import { OnlineNotificationsService } from './services/online-notifications.service';
import { OperationsService } from './services/operations.service';
import { OverlayPanelService } from './services/overlay-panel.service';
import { PointsService } from './services/points.service';
import { RaceService } from './services/race.service';
import { RefsService } from './services/refs.service';
import { RegisterService } from './services/register.service';
import { ReportObjectsService } from './services/report-objects.service';
import { ReportTemplatesService } from './services/report-templates.service';
import { ReportsService } from './services/reports.service';
import { RightsService } from './services/rights.service';
import { RoutesService } from './services/routes.service';
import { StoreService } from './services/store.service';
import { SystemNotificationsService } from './services/system-notifications.service';
import { ToastService } from './services/toast.service';
import { TrackingService } from './services/tracking.service';
import { UnitGroupsService } from './services/unit-groups.service';
import { UnitsService } from './services/units.service';
import { UsersService } from './services/users.service';
import { YandexTransportRepeaterService } from './services/yandex-transport-repeater.service';

/**
 * Фабрика загрузчиков языковых файлов
 * @param http Клиент HTTP
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

/**
 * Получатель токена для JWT
 */
export function tokenGetter() {
  return localStorage.getItem('token');
}

registerLocaleData(localeRu, 'ru-RU');

/**
 * Модуль приложения
 */
@NgModule({
  declarations: [
    AccountComponent,
    AccountOperationsComponent,
    AccountPaymentComponent,
    AccountsComponent,
    AccountsLoadPaymentsComponent,
    AddressDetailComponent,
    AdminComponent,
    AnalyticsComponent,
    AppComponent,
    ArbitraryFieldsComponent,
    AssignComponent,
    AssignmentsComponent,
    AutoBindingComponent,
    BalanceNotificationSettingsComponent,
    BgInfoComponent,
    BgTooltipComponent,
    ButtonEditComponent,
    CalendarComponent,
    ChangelogComponent,
    ClickOutsideDirective,
    ClientAddInvoiceComponent,
    ClientBalanceNotificationSettingsComponent,
    ClientComponent,
    ClientFinancesComponent,
    ClientsComponent,
    ClientsEditComponent,
    CommandsComponent,
    CreateGeozoneComponent,
    CrmComponent,
    DillerComponent,
    DillerDocsSettingsComponent,
    DocAddComponent,
    DocChangeComponent,
    DocComponent,
    DocsComponent,
    DocsPacketsComponent,
    DocsPacketsRequestParamsComponent,
    DocsPrintDetailComponent,
    DocsPrintPreviewComponent,
    DocsSearchComponent,
    DogovorsComponent,
    DogovorsEditComponent,
    DriversComponent,
    DriversDetailComponent,
    DriversEditComponent,
    DriversGroupEditComponent,
    EmptyCellTooltipComponent,
    EquipmentsComponent,
    EquipmentsEditComponent,
    EventComponent,
    ExportAccountsComponent,
    ExportUnitsComponent,
    GeneratedInvoiceComponent,
    GeneratedWAReportComponent,
    GeozonesComponent,
    GeozonesDetailComponent,
    GeozonesEditComponent,
    GeozonesEditGroupComponent,
    GeozoneTrackerComponent,
    HistoryComponent,
    ImportAccountsComponent,
    ImportUnitsComponent,
    ItemSelectorComponent,
    ListEditComponent,
    LoginComponent,
    LogoutComponent,
    MapComponent,
    MapDetailHostDirective,
    CdkCustomAutoSizeVirtualScroll,
    MapDetailLightComponent,
    MapRaceDetailComponent,
    MapReportDetailComponent,
    MapSettingsComponent,
    MessagesComponent,
    MessagesEventsComponent,
    MessagesPointsComponent,
    MessagesTableComponent,
    ModalComponent,
    MonitoringComponent,
    NavComponent,
    NavigationMoreComponent,
    NotificationSettingComponent,
    NotificationSettingsComponent,
    OnlineNotificationsComponent,
    OverlayPanelComponent,
    PaginatorComponent,
    PointEditComponent,
    RaceComponent,
    RacePlayerComponent,
    RedirectComponent,
    RefsComponent,
    RefsEditComponent,
    RefsItemsComponent,
    RefsItemsModalComponent,
    RegisterComponent,
    ReportChartComponent,
    ReportComponent,
    ReportPreviewComponent,
    ReportQueueComponent,
    ReportTableComponent,
    ReportTemplateChartComponent,
    ReportTemplateComponent,
    ReportTemplatesComponent,
    ReportTemplateTableComponent,
    ReportTemplateTableFilterComponent,
    RightsComponent,
    RightsGenComponent,
    RoutesComponent,
    RoutesEditComponent,
    RoutesRepeatComponent,
    SelectItemsComponent,
    SelectItemsModalComponent,
    SelectPeriodComponent,
    SelectUnitsComponent,
    SendCommandComponent,
    SensorComponent,
    SIMCardsComponent,
    SIMCardsEditComponent,
    SystemNotificationComponent,
    SystemNotificationsComponent,
    TariffEditComponent,
    TariffsComponent,
    TaskComponent,
    TasksComponent,
    TimeLimitsComponent,
    TrackingComponent,
    TrackingSettingsComponent,
    TrailersComponent,
    TrailersDetailComponent,
    TrailersEditComponent,
    TrailersGroupEditComponent,
    UnblockEntityComponent,
    UnitCharacteristicsComponent,
    UnitComponent,
    UnitDrivingQualityComponent,
    UnitDrivingQualityCriteriaComponent,
    UnitExtrasComponent,
    UnitFuelComponent,
    UnitGeneralComponent,
    UnitGroupComponent,
    UnitGroupsComponent,
    UnitIconComponent,
    UnitInGroupsComponent,
    UnitMoveDetectionComponent,
    UnitsActivityComponent,
    UnitsComponent,
    UnitSensorsComponent,
    UnitsMoveComponent,
    UnitTOIntervalComponent,
    UnitTOIntervalsComponent,
    UnitValidationComponent,
    UserComponent,
    GuestAccessComponent,
    UserRightsComponent,
    UsersComponent,
    UserSettingsComponent,
    GuestAccessAddComponent,
    YandexTransportRepeaterComponent
  ],
  imports: [
    AccordionModule,
    AngularResizedEventModule,
    BadgeModule,
    BgAuthModule,
    BgDatetimepickerModule,
    BgPopoverModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ColorPickerModule,
    CommonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DividerModule,
    DragDropModule,
    DropdownModule,
    DynamicDialogModule,
    FieldsetModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        blacklistedRoutes: ['api/swagger', 'api/docs', 'api/register', 'api/auth/login', 'assets/']
      }
    }),
    KeyFilterModule,
    ListboxModule,
    MessageModule,
    MessagesModule,
    MetrikaModule.forRoot(
      { id: 86208465, webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true }
    ),
    MyDatePickerModule,
    NgxCurrencyModule.forRoot({
      align: 'right',
      allowNegative: true,
      allowZero: false,
      decimal: ',',
      inputMode: CurrencyMaskInputMode.FINANCIAL,
      nullable: false,
      precision: 2,
      prefix: '',
      suffix: '',
      thousands: ' '
    }),
    OverlayPanelModule,
    PickListModule,
    PipesModule,
    ProgressBarModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    RippleModule,
    RoutingModule,
    ScrollingModule,
    ScrollPanelModule,
    SharedModule,
    SidebarModule,
    TableModule,
    TabViewModule,
    TagModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    UIMDistricsModule,
    VirtualScrollerModule,
    ConfirmPopupModule,
    SkeletonModule,
    BgRepeatersModule,
    ScrollingModule
  ],
  entryComponents: [
    AccountComponent,
    AccountsLoadPaymentsComponent,
    AddressDetailComponent,
    AssignmentsComponent,
    AutoBindingComponent,
    BgInfoComponent,
    BgTooltipComponent,
    CalendarComponent,
    ChangelogComponent,
    ClientAddInvoiceComponent,
    ClientBalanceNotificationSettingsComponent,
    ClientsEditComponent,
    CreateGeozoneComponent,
    DillerDocsSettingsComponent,
    DocAddComponent,
    DocChangeComponent,
    DocsPacketsRequestParamsComponent,
    DocsPrintDetailComponent,
    DocsPrintPreviewComponent,
    DogovorsEditComponent,
    DriversComponent,
    DriversDetailComponent,
    DriversEditComponent,
    EmptyCellTooltipComponent,
    EquipmentsEditComponent,
    ExportAccountsComponent,
    ExportUnitsComponent,
    GeozonesDetailComponent,
    GeozoneTrackerComponent,
    ImportAccountsComponent,
    ImportUnitsComponent,
    MapDetailLightComponent,
    MapRaceDetailComponent,
    MapReportDetailComponent,
    MapSettingsComponent,
    ModalComponent,
    NavigationMoreComponent,
    NotificationSettingComponent,
    OverlayPanelComponent,
    PaginatorComponent,
    PointEditComponent,
    RefsEditComponent,
    RefsItemsModalComponent,
    ReportPreviewComponent,
    ReportQueueComponent,
    ReportTemplateChartComponent,
    ReportTemplateComponent,
    ReportTemplateTableComponent,
    ReportTemplateTableFilterComponent,
    RoutesComponent,
    RoutesRepeatComponent,
    SelectItemsComponent,
    SelectPeriodComponent,
    SelectUnitsComponent,
    SendCommandComponent,
    SensorComponent,
    SIMCardsEditComponent,
    SystemNotificationComponent,
    SystemNotificationsComponent,
    TariffEditComponent,
    TaskComponent,
    TrackingSettingsComponent,
    TrailersComponent,
    TrailersDetailComponent,
    TrailersEditComponent,
    UiDistrictsComponent,
    UnblockEntityComponent,
    UnitComponent,
    UnitDrivingQualityCriteriaComponent,
    UnitGroupComponent,
    UnitsMoveComponent,
    UnitTOIntervalComponent,
    UnitValidationComponent,
    UserComponent,
    GuestAccessComponent,
    GuestAccessAddComponent,
    UserSettingsComponent
  ],
  providers: [
    AccountsService,
    ActivityService,
    AnalyticsService,
    AssignmentsService,
    AuthGuard,
    AutoBindingService,
    BgAuthService,
    BgGuestAccessService,
    BGMessagesService,
    CleanerService,
    ClientGuard,
    ClientService,
    CommandsService,
    CommandTypesService,
    ConfigService,
    ConfirmationService,
    ConfirmWrapService,
    CRUDService,
    DialogPrimeService,
    DillerGuard,
    DillersService,
    DistrictsService,
    DocsService,
    EventsService,
    ExportImportService,
    FileService,
    FilterService,
    GarbageService,
    GeocoderService,
    GeozoneGroupService,
    GeozonesService,
    GuestAccessService,
    HelpService,
    HistoryService,
    HostConfigService,
    ItemSelectorService,
    LoadingService,
    MapService,
    MessageService,
    ModalDialogService,
    ModalService,
    MonitoringService,
    NavigationService,
    NotificationsService,
    OnlineNotificationsService,
    OperationsService,
    OverlayPanelService,
    PointsService,
    PrimeNGConfig,
    RaceService,
    RefsService,
    RegisterService,
    ReportObjectsService,
    ReportsService,
    ReportTemplatesService,
    RightsService,
    RoutesService,
    StoreService,
    SystemNotificationsService,
    Title,
    ToastService,
    TrackingService,
    UnitGroupsService,
    UnitsService,
    UsersService,
    YandexTransportRepeaterService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'ru-RU' }
  ],
  exports: [
    NavComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
