import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import {
  DrivingQualityAvgType,
  getAllDrivingQualityAvgTypes,
  getDrivingQualityAvgTypeName
} from '../../../../shared/driving-quality/DrivingQualityAvgType';
import {
  DrivingQualityType,
  getAllDrivingQualityTypes,
  getDrivingQualityTypeName
} from '../../../../shared/driving-quality/DrivingQualityType';
import { IDrivingQuality } from '../../../../shared/driving-quality/IDrivingQuality';
import { UnitRightType } from '../../../../shared/rights/RightType';
import { IUnit } from '../../../../shared/units/IUnit';
import { IListItem } from '../../../classes/IListItem';

/** Интерфейс компонента для редактирования критерия качества вождения */
interface IUnitDrivingQualityCriteriaComponent {
  /** Оригинальный критерий */
  originalCriteria: IDrivingQuality;
  /** Объект мониторинга */
  unit: IUnit;
  /** Признак режима добавления */
  isAddRegime: boolean;
}

/** Компонент для редактирования критерия качества вождения */
@Component({
  selector: 'unit-driving-quality-criteria',
  templateUrl: './unit.driving-quality-criteria.component.html'
})
export class UnitDrivingQualityCriteriaComponent
extends DialogComponent<IUnitDrivingQualityCriteriaComponent, IDrivingQuality>
implements IUnitDrivingQualityCriteriaComponent {

  /** Оригинальный критерий */
  public originalCriteria: IDrivingQuality;
  /** Объект мониторинга */
  public unit: IUnit;
  /** Признак режима добавления */
  public isAddRegime: boolean;

  /** Заголовок окна */
  public title: string;
  /** Редактируемый критерий */
  public criteria: IDrivingQuality;

  /** Список типов критериев */
  public types: IListItem<DrivingQualityType>[] = [];
  /** Список типов усреднения */
  public avgTypes: IListItem<DrivingQualityAvgType>[] = [];

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   */
  constructor(dialogService: DialogService) {
    super(dialogService);

    this.types = getAllDrivingQualityTypes().map((id) => ({ id, name: getDrivingQualityTypeName(id) }));

    this.avgTypes = getAllDrivingQualityAvgTypes()
      .map((id) => ({ id, name: getDrivingQualityAvgTypeName(id) }));
  }

  /** Обработка при изменении типа критерия */
  public onTypeChange() {
    if (this.criteria.sensorId && this.criteria.type !== DrivingQualityType.ARBITRARY) {
      this.criteria.sensorId = null;
    }
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IUnitDrivingQualityCriteriaComponent) {
    if (!data.originalCriteria) {
      this.title = 'component.unit.driving-quality.criteria.title-add-criteria';
      // tslint:disable-next-line:no-bitwise
    } else if (data.isAddRegime || (data.unit.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING)) {
      this.title = 'component.unit.driving-quality.criteria.title-edit-criteria';
    } else {
      this.title = 'component.unit.driving-quality.criteria.title-show-criteria';
    }

    if (!data.originalCriteria) {
      this.criteria = {
        fine: 1000,
        type: DrivingQualityType.SPEEDING,
        avgType: null,
        validatorId: null,
        sensorId: null
      } as IDrivingQuality;
    } else {
      this.criteria = {
        ...data.originalCriteria,
        avgType: data.originalCriteria.avgType || null,
        sensorId: data.originalCriteria.sensorId || null,
        validatorId: data.originalCriteria.validatorId || null
      };
    }

    return super.fillData(data);
  }

  /** Получение признака возможности изменения критерия */
  public get isCanChange() {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || (this.unit.rights & UnitRightType.CHANGE_MOVE_AND_FUEL_SETTING);
  }

  /** Получение признака возможности сохранения */
  public get isCanSave() {
    if (!this.criteria.name || this.criteria.name === '') { return false; }

    if (this.criteria.fine <= 0) { return false; }

    if (this.criteria.max != null && this.criteria.max <= 0) { return false; }
    if (this.criteria.min != null && this.criteria.min <= 0) { return false; }
    if (this.criteria.maxSpeed != null && this.criteria.maxSpeed <= 0) { return false; }
    if (this.criteria.maxTime != null && this.criteria.maxTime <= 0) { return false; }
    if (this.criteria.minSpeed != null && this.criteria.minSpeed <= 0) { return false; }
    if (this.criteria.minTime != null && this.criteria.minTime <= 0) { return false; }
    if (
      this.criteria.max != null &&
      this.criteria.min != null &&
      this.criteria.max <= this.criteria.min
    ) { return false; }

    if (
      this.criteria.maxSpeed != null &&
      this.criteria.minSpeed != null &&
      this.criteria.maxSpeed <= this.criteria.minSpeed
    ) { return false; }

    if (
      this.criteria.maxTime != null &&
      this.criteria.minTime != null &&
      this.criteria.maxTime <= this.criteria.minTime
    ) { return false; }

    if (this.criteria.type === DrivingQualityType.ARBITRARY && !this.criteria.sensorId) {
      return false;
    }

    return true;
  }

  /** Подтверждение изменений */
  public confirm() {
    if (!this.isCanChange || !this.isCanSave) { return; }

    this.result = this.criteria;
    this.close();
  }
}
