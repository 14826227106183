import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  CommandsService,
  CommandState,
  getState,
  IListCommand,
  IListCommandParam
} from '../../../services/commands.service';
import { ToastService } from '../../../services/toast.service';

/**
 * Компонент для просмотра отправленных команд
 */
@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html'
})
export class CommandsComponent implements OnInit, OnDestroy {

  /**
   * Список команд
   */
  public commands: IListCommand[];

  /**
   * Состояние команды
   */
  public cState = CommandState;

  /**
   * Идентификатор объекта мониторинга
   */
  @Input()
  private oid: string;

  /**
   * Подписка на сабж для уведомления об отправке команды
   */
  private readonly commandSentSubscription: Subscription;

  /**
   * Конструктор
   * @param commandsService Сервис работы с сообщениями
   * @param toastService Сервис всплывающих сообщений
   */
  constructor(
    private commandsService: CommandsService,
    private toastService: ToastService
  ) {
    this.commandSentSubscription = this.commandsService.commandSentSubject.subscribe(() => this.loadList());
  }

  /**
   * Обработки при инициализации компонента
   */
  public ngOnInit() {
    this.loadList();
  }

  /**
   * Обработки при закрытии компонента
   */
  public ngOnDestroy(): void {
    if (this.commandSentSubscription) this.commandSentSubscription.unsubscribe();
  }

  /**
   * Получение названия состояния команды
   * @param state Состояние команды
   */
  public state(state: CommandState) {
    return getState(state);
  }

  /**
   * Получение строки параметров команды
   * @param params Параметры команды
   */
  public params(params: IListCommandParam[]) {
    return params
      .filter((x) => x.value != null)
      .map((x) => `${x.name}=${x.value}`).join(', ');
  }

  /**
   * Загрузка списка команд
   */
  public loadList() {
    this.commandsService.list(this.oid)
      .subscribe(commands => this.commands = commands
        ?.sort((a, b) => b.date - a.date));
  }

  /**
   * Добавление команды
   */
  public add() {
    this.commandsService.oidSubject.next(this.oid);
  }

  /**
   * Отмена команды
   * @param id Идентификатор команды
   */
  public cancel(id: string) {
    this.commandsService.remove(id)
      .pipe(tap(() => this.loadList()))
      .subscribe(() => this.toastService.success('component.command.cancel-success'),
        () => this.toastService.error('component.command.error-cancel-command'));
  }
}
