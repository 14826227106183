/** Состояния SIM-карты */
export enum SIMCardState {
  /** Готова к активации */
  PREPARED = 1,
  /** В работе */
  IN_WORK,
  /** Заблокирована */
  BLOCKED,
  /** Списана */
  WRITE_OFF
}

/** Получение списка всех состояний SIM-карты */
export function getAllSIMCardStates(): SIMCardState[] {
  return [
    SIMCardState.PREPARED, SIMCardState.IN_WORK,
    SIMCardState.BLOCKED, SIMCardState.WRITE_OFF
  ];
}

/**
 * Получение наименования состояния SIM-карты
 * @param state Состояние
 */
export function getSIMCardStateName(state: SIMCardState): string {
  switch (state) {
    case SIMCardState.PREPARED: return 'enums.crm.sim.state.prepared';
    case SIMCardState.IN_WORK: return 'enums.crm.sim.state.operable';
    case SIMCardState.BLOCKED: return 'enums.crm.sim.state.blocked';
    case SIMCardState.WRITE_OFF: return 'enums.crm.sim.state.closed';
    default: return 'enums.crm.sim.state.unknown';
  }
}
