import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { getRefTypeName, IRefItem, RefType } from '../../../../../shared/crm/IRefItem';

/**
 * Интрефейс компонента диалогового окна
 * для отображения списка записей справочника
 */
interface IRefsItemsModalComponent {
  /** Тип справочника */
  type: RefType;
  /** Выбранная запись справочника */
  selected: IRefItem;
}

/**
 * Компонент диалогового окна
 * для отображения списка записей справочника
 */
@Component({
  selector: 'refs-items-modal',
  templateUrl: './refs.items-modal.component.html',
  styleUrls: ['./refs.items-modal.component.scss']
})
export class RefsItemsModalComponent
extends DialogComponent<IRefsItemsModalComponent, IRefItem>
implements IRefsItemsModalComponent {

  /** Тип справочника */
  public type: RefType;

  /** Выбранная запись справочника */
  public selected: IRefItem;

  /** Заголовок окна */
  public title: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param translator Сервис для перевода
   */
  constructor(
    dialogService: DialogService,
    private translator: TranslateService
  ) {
    super(dialogService);
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IRefsItemsModalComponent) {
    this.translator.get(getRefTypeName(data.type))
      .subscribe((x) => this.title = x);

    return super.fillData(data);
  }

  /**
   * Подтверждение выбора записи из справочника
   */
  public confirm() {
    this.result = this.selected;
    this.close();
  }
}
