import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IRegInfo } from '../../shared/register/IRegInfo';

import { ConfigService } from './config.service';

/**
 * Сервис для регистрации в системе
 */
@Injectable()
export class RegisterService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/register`;

  /**
   * Конструктор
   * @param http Сервис работы с HTTP
   * @param translator Сервис для перевода
   * @param configService
   */
  constructor(
    private http: HttpClient,
    private translator: TranslateService,
    private configService: ConfigService
  ) {}

  /**
   * Отправка запроса на регистрацию
   * @param body Регистрационная информация
   */
  public register(body: IRegInfo): Observable<string> {
    return this.http.post<string>(this.baseUrl, body);
  }
}
