import { ITrailerGroup } from '../../shared/trailers/ITrailerGroup';

import { IClientTrailer } from './IClientTrailer';

/**
 * Группа прицепов для отображения в режиме слежения
 */
export class TrackingTrailerGroup implements ITrailerGroup {
  /** Идентификатор записи */
  public id: string;

  /** Наименование группы */
  public name: string;

  /** Идентификатор учетной записи, к которой относится группа */
  public accountId: string;

  /** Список идентификаторов прицепов группы */
  public trailers: string[];

  /** Описание группы */
  public descr?: string;

  /** Список отслеживаемых прицепов группы */
  public trackingTrailers: IClientTrailer[];

  /** Признак необходимости отображения прицепов группы */
  public showTrailers?: boolean;

  /**
   * Конструктор
   * @param group Информация о группе прицепов
   */
  constructor(group: ITrailerGroup) {
    this.id = group.id;
    this.name = group.name;
    this.accountId = group.accountId;
    this.trailers = group.trailers;
    this.descr = group.descr;
    this.trackingTrailers = [];
    this.showTrailers = true;
  }

  /**
   * Получение признака выбранности группы
   */
  public get checked() {
    return this.trackingTrailers.length && this.trackingTrailers.every((u) => u.checked);
  }
}
