/** Типы критериев, по которым ведется оценка качества вождения */
export enum DrivingQualityType {
  /** Превышение скорости */
  SPEEDING = 1,
  /** Ускорение */
  ACCELERATION,
  /** Торможение */
  DECELERATION,
  /** Поворот */
  TURN,
  /** Резкое вождение */
  DANGEROUS_DRIVING,
  /** Произвольный */
  ARBITRARY
}

/** Получение списка всех типов критериев */
export function getAllDrivingQualityTypes(): DrivingQualityType[] {
  const result: DrivingQualityType[] = [];
  for (let i = 1; i <= 6; i++) {
    result.push(i as DrivingQualityType);
  }
  return result;
}

/**
 * Получение наименования типа критерия
 * @param type Тип критерия
 */
export function getDrivingQualityTypeName(type: DrivingQualityType): string {
  switch (type) {
    case DrivingQualityType.SPEEDING: return 'enums.driving-quality.type.speeding';
    case DrivingQualityType.ACCELERATION: return 'enums.driving-quality.type.acceleration';
    case DrivingQualityType.DECELERATION: return 'enums.driving-quality.type.deceleration';
    case DrivingQualityType.TURN: return 'enums.driving-quality.type.turn';
    case DrivingQualityType.DANGEROUS_DRIVING: return 'enums.driving-quality.type.dangerous';
    case DrivingQualityType.ARBITRARY: return 'enums.driving-quality.type.arbitrary';
    default: return 'enums.driving-quality.type.unknown';
  }
}
