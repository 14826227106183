import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

/**
 * Сервис всплывающих подтверждающих запросов
 */
@Injectable()
export class ConfirmWrapService {

  /**
   * Конструктор
   * @param confirmationService
   */
  constructor(
    private confirmationService: ConfirmationService
  ) {

  }

  /**
   * Обёртка для подтверждения
   */
  public confirm(message: string, header: string, acceptCallback: () => void, rejectCallback: () => void) {
    this.confirmationService.confirm({
      key: 'confirm-dialog',
      message,
      header,
      icon: 'pi pi-exclamation-triangle',
      accept: acceptCallback,
      reject: rejectCallback
    })
  }
}
