/**
 * Тип валидации датчика
 */
export enum ValidationType {
  LOGICAL_OR = 1,
  LOGICAL_AND,
  NOT_ZERO,
  MATH_OR,
  MATH_AND,
  SUM,
  SUB_SENSOR_FROM_VALIDATOR,
  SUB_VALIDATOR_FROM_SENSOR,
  MULTIPLY,
  DIV_SENSOR_ON_VALIDATOR,
  DIV_VALIDATOR_ON_SENSOR,
  REPLACE_ON_ERROR
}

/**
 * Получение списка всех типов валидации датчика
 */
export function getAllValidationTypes() {
  const result: ValidationType[] = [];
  for (let i = 1; i <= 12; i++) {
    result.push(i as ValidationType);
  }

  return result;
}

/**
 * Получение наименования типа валидации датчика
 * @param validationType Тип валидации
 */
export function getValidationTypeName(validationType: ValidationType) {
  switch (validationType) {
    case ValidationType.LOGICAL_OR: return 'enums.sensors.validation-type.or';
    case ValidationType.LOGICAL_AND: return 'enums.sensors.validation-type.and';
    case ValidationType.NOT_ZERO: return 'enums.sensors.validation-type.zero';
    case ValidationType.MATH_OR: return 'enums.sensors.validation-type.math-or';
    case ValidationType.MATH_AND: return 'enums.sensors.validation-type.math-and';
    case ValidationType.SUM: return 'enums.sensors.validation-type.sum';
    case ValidationType.SUB_SENSOR_FROM_VALIDATOR: return 'enums.sensors.validation-type.sub-sensor';
    case ValidationType.SUB_VALIDATOR_FROM_SENSOR: return 'enums.sensors.validation-type.sub-validator';
    case ValidationType.MULTIPLY: return 'enums.sensors.validation-type.mul';
    case ValidationType.DIV_SENSOR_ON_VALIDATOR: return 'enums.sensors.validation-type.div-sensor';
    case ValidationType.DIV_VALIDATOR_ON_SENSOR: return 'enums.sensors.validation-type.div-validator';
    case ValidationType.REPLACE_ON_ERROR: return 'enums.sensors.validation-type.replace';
    default: return 'enums.sensors.validation-type.unknown';
  }
}
