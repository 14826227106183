import { GeneratedDoc, IDocService, IGeneratedInvoice } from '../../shared/crm/docs';

/**
 * Подготовка документа для отображения пользователю
 * @param doc Сгенерированный документ
 */
export function prepareDocForDisplay(doc: GeneratedDoc) {
  if ((doc as IGeneratedInvoice).balance) {
    (doc as IGeneratedInvoice).balance /= 100;
  }

  doc.sum /= 100;

  doc.services.forEach(prepareDocServiceForDisplay);
}

/**
 * Подготовка услуги для отображения пользователю
 * @param service Услуга по документу
 */
export function prepareDocServiceForDisplay(service: IDocService) {
  service.sum /= 100;

  if (service.cost) {
    service.cost /= 100;
  }
}
