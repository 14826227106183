import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ISimpleObject } from '../../shared/ISimpleObject';
import { IUnitInGroup } from '../../shared/unit-groups/IUnitInGroup';
import { IUnitInGroupUpdate } from '../../shared/unit-groups/IUnitInGroupUpdate';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';

/**
 * Сервис для работы с группами объектов мониторинга
 */
@Injectable()
export class UnitGroupsService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/unit-groups`;

  /**
   * Instantiates {@link UnitGroupsService}
   * @param configService Injects {@link ConfigService}
   * @param http Injects {@link HttpClient}
   * @param loadingService Injects {@link LoadingService}
   */
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  /**
   * Получение вхождения объекта мониторинга в группы
   * @param unitId Идентификатор объекта мониторинга
   */
  public getUnitInGroups(unitId: string): Observable<IUnitInGroup[]> {
    const url = `${this.baseUrl}/unit/${unitId}`;
    return this.http.get<IUnitInGroup[]>(url);
  }

  /**
   * Обновление вхождения объекта в группы
   * @param unitId Идентификатор объекта
   * @param updates Обновления по вхождению объекта в группы
   */
  public updateUnitInGroups(unitId: string, updates: IUnitInGroupUpdate[]): Observable<string[]> {
    const url = `${this.baseUrl}/unit/${unitId}`;
    return this.http.post<string[]>(url, updates);
  }

  /**
   * Получение список груп ТС
   */
  public getList(): Observable<IListItem<string>[]> {
    return this.http.get<IListItem<string>[]>(this.baseUrl);
  }

  /**
   * Get routes (group with route flag) found for yandex transport repeater
   */
  public getYTRRoutes(): Observable<Array<ISimpleObject>> {
    const headers = this.loadingService.waiterHeader;
    const url = `${this.baseUrl}/yt-repeater`;
    return this.http.get<Array<ISimpleObject>>(url, { headers });
  }
}
