import { Component, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';

import { IGuestAccessItem } from '../../../../shared/guest-access/IGuestAccessItem';
import { DetailComponent } from '../../../classes/DetailComponent';
import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { GuestAccessService } from '../../../services/guest-access.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { ToastService } from '../../../services/toast.service';

/**
 * Компонент для отображения подробной информации по пользователю
 */
@Component({
  selector: 'guest-access-add',
  templateUrl: './guest-access.add.component.html',
  styleUrls: ['./guest-access.add.component.scss']
})
export class GuestAccessAddComponent extends DetailComponent<any> implements OnInit {

  public name: string;

  public selectedUnit: IListItem<string>;

  public units: IListItem<string>[];

  public startOfTermAt: Date;

  public expirationAt: Date;

  /**
   * Конструктор
   */
  constructor(
    dialogService: DialogService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private crudService: CRUDService,
    private guestAccessService: GuestAccessService,
    private toastService: ToastService
  ) {
    super(dialogService);
  }

  get isCanSave(): boolean {
    return !!this.name && !!this.selectedUnit && !!this.startOfTermAt && !!this.expirationAt
  }

  ngOnInit(): void {
    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((result) => this.units = result);

    const date = new Date();

    this.startOfTermAt = new Date();
    this.expirationAt = new Date(date.setMonth(date.getMonth() + 3));
  }

  isCan(): boolean {
    return false;
  }

  confirm(): void {
    if (!this.isCanSave) {
      return;
    }

    if (this.startOfTermAt >= this.expirationAt) {
      this.toastService.warn('errors.begin-after-end')
      return;
    }

    const data: IGuestAccessItem = {
      name: this.name,
      unitId: this.selectedUnit.id,
      startOfTermAt: this.startOfTermAt?.getTime(),
      expirationAt: this.expirationAt?.getTime()
    }

    this.guestAccessService.createGuestAccess(data)
      .subscribe((res) => {
        this.result = res;
        this.close()
      })
  }
}
