/**
 * Параметры провайдера
 */
interface IProviderSettings {
  /** Адрес сервиса геокодирования */
  url: string;
  /** Параметры запроса */
  params: {
    /** Строка запроса */
    q: string;
    /** Формат ответа */
    format: string;
    /** Подробный адрес (0/1) */
    addressdetails: number;
    /** Максимальное количество записей в результате */
    limit: number;
    /** Код страны по ISO 3166-1alpha2 */
    countrycodes: string;
    /** Предпочтительный язык ответа */
    'accept-language': string;
  };
}

/**
 * Опции геокодера
 */
interface IGeocoderOptions {
  /** Строка запроса */
  query: string;
  /** Максимальное количество записей в результате */
  limit: number;
  /** Код страны по ISO 3166-1alpha2 */
  countrycodes: string;
  /** Предпочтительный язык ответа */
  lang: string;
}

/**
 * Ответ от сервиса геокодирования
 */
interface IGeocoderResult {
  /** Широта */
  lon: number;
  /** Долгота */
  lat: number;
  /** Адрес */
  display_name: string;
  /** Подробный адрес */
  address: {[key: string]: string};
}

/**
 * Обработанный ответ от сервиса геокодирования
 */
interface IGeocoderResponse {
  /** Широта */
  lon: number;
  /** Долгота */
  lat: number;
  /** Детали адреса */
  address: {
    /** Адрес */
    name: string;
    /** Индекс */
    postcode: string;
    /** Страна */
    country: string;
    /** Область */
    state: string;
    /** Город */
    city: string;
    /** Улица */
    road: string;
    /** Дом */
    houseNumber: string;
  };
  /** Необработанная информация из ответа */
  original: {
    /** Строка запроса */
    query: string;
    /** Адрес */
    formatted: string;
    /** Подробный адрес */
    details: {[key: string]: string};
  };
}

/**
 * Провайдер геокодера (плагина для openstreetmaps)
 */
export class GeocoderProvider {
  /** Строка запроса */
  private query: string;

  /**
   * Получение параметров провайдера
   * @param options Опции геокодера
   */
  public getParameters(options: IGeocoderOptions): IProviderSettings {
    this.query = options.query;
    return {
      url: 'https://nominatim.openstreetmap.org/search/',
      params: {
        'q': options.query,
        'format': 'json',
        'addressdetails': 1,
        'limit': options.limit || 10,
        'countrycodes': options.countrycodes || '',
        'accept-language': options.lang || 'en-US'
      }
    };
  }

  /**
   * Обработка ответа от сервиса геокодиования
   * @param results Ответ
   */
  public handleResponse(results: IGeocoderResult[]): IGeocoderResponse[] {
    return results.map((result) => ({
      lon: result.lon,
      lat: result.lat,
      address: {
        name: result.display_name,
        road: result.address.road || '',
        houseNumber: result.address.house_number || '',
        postcode: result.address.postcode,
        city: result.address.city || result.address.town,
        state: result.address.state,
        country: result.address.country
      },
      original: {
        query: this.query,
        formatted: result.display_name,
        details: result.address
      }
    }));
  }
}
