import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ISystemNotification, SystemNotificationsService } from '../../../services/system-notifications.service';
import { ToastService } from '../../../services/toast.service';
import { localeSort } from '../../../utils/sort';

/**
 * Интерфейс с параметрами для создания модального окна
 * добавления/изменения системного уведомления
 */
export interface IData {
  /** Идентификатор системного уведомления */
  id?: string;
}

/**
 * Компонент, представляющий подробную информацию о прицепе
 */
@Component({
  selector: 'system-notification',
  templateUrl: './system-notification.component.html'
})
export class SystemNotificationComponent
  extends DialogComponent<IData, boolean>
  implements IData {

  /** Идентификатор системного уведомления */
  public id: string;

  /** Системное уведомление */
  public notification: ISystemNotification;

  /** Список учетных записей */
  public accounts: IListItem<string>[];

  /** Список пользователей */
  public users: IListItem<string>[];

  /** Тип предназначения системного уведомления */
  public destinationType: DestinationType;

  /** Заголовок окна */
  public title: string;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param crudService Сервис для работы с ДИУП
   * @param systemNotificationsService Сервис для работы с системными оповещениями
   * @param toastService Сервис модальных окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private crudService: CRUDService,
    private systemNotificationsService: SystemNotificationsService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.title = 'component.notifications.system-notification.notification-add-title';

    this.destinationType = DestinationType.DILLER;
    this.notification = {} as ISystemNotification;
  }

  /**
   * Получение признака на возможность сохранения
   */
  public get isCanSave() {
    return this.notification && this.notification.name && this.notification.name !== ''
      && (!this.notification.pinned || (this.notification.pinned && this.notification.autoClose)) &&
      ((this.destinationType === DestinationType.ACCOUNT && this.notification.accountId) ||
        (this.destinationType === DestinationType.USER && this.notification.userId) ||
        this.destinationType === DestinationType.DILLER);
  }

  public get isNew() {
    return !this.notification.id
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IData) {
    if (!!data.id) {
      this.title = 'component.notifications.system-notification.notification-edit-title';

      this.systemNotificationsService.get(data.id).subscribe(
        (notification) => {
          this.notification = notification;

          if (notification.dillerId) {
            this.destinationType = DestinationType.DILLER;
          } else if (notification.accountId) {
            this.destinationType = DestinationType.ACCOUNT;
          } else if (notification.userId) {
            this.destinationType = DestinationType.USER;
          }

          this.loadLists();
        },
        () => this.toastService.error('component.notifications.notification-settings.get-error'));
    }

    return super.fillData(data);
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) {
      return;
    }

    switch (this.destinationType) {

      case DestinationType.DILLER:
        delete this.notification.accountId;
        delete this.notification.userId;
        break;

      case DestinationType.ACCOUNT:
        delete this.notification.userId;
        break;

      case DestinationType.USER:
        delete this.notification.accountId;
        break;

    }

    const observable = this.isNew
      ? this.systemNotificationsService.create(this.notification)
      : this.systemNotificationsService.update(this.notification);

    observable.subscribe(
      () => {
        this.result = true;
        this.close();
      },
      () => this.toastService.error('component.notifications.notification-settings.save-error'));
  }

  /**
   * Загрузка списков учетных записей/пользователей для выбора
   */
  public loadLists() {
    switch (this.destinationType) {

      case DestinationType.ACCOUNT:
        if (!this.accounts) {
          this.loadingService.withLoading(
            this.crudService.getListLight(CRUDEntityType.ACCOUNT),
            (accounts) => this.accounts = accounts?.sort(localeSort)
          );
        }
        break;

      case DestinationType.USER:
        if (!this.users) {
          this.loadingService.withLoading(
            this.crudService.getListLight(CRUDEntityType.USER),
            (users) => this.users = users?.sort(localeSort)
          );
        }
        break;

    }
  }
}

/**
 * Тип-перечисление предназначения системного уведомления
 */
enum DestinationType {
  DILLER = 1,
  ACCOUNT,
  USER
}
