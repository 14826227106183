import { Component, Input } from '@angular/core';

import { IMessagesRequest } from '../../../../shared/messages/IMessagesRequest';

@Component({
  selector: 'messages-table',
  templateUrl: './messages.table.component.html'
})
export class MessagesTableComponent {
  @Input() public request: IMessagesRequest;
}
