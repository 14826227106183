import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Директива для размещения детальной информации на карте
 */
@Directive({
  selector: '[map-detail-host]'
})
export class MapDetailHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
