import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { of, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { HistoryEntityType } from '../../../shared/crm/hist';
import { IRight } from '../../../shared/rights/IRight';
import { RightType, UserRightType } from '../../../shared/rights/RightType';
import { getTimezoneName, Timezone } from '../../../shared/time/timezones';
import { getDefaultUserSettings, getLocaleName, IActUser, IUser, Locale } from '../../../shared/users';
import { IUserExtras } from '../../../shared/users/IUserGeneric';
import { DetailComponent } from '../../classes/DetailComponent';
import { DetailTab } from '../../classes/DetailTab';
import { IClientHistory } from '../../classes/IClientHistory';
import { IListItem } from '../../classes/IListItem';
import { IUserRights } from '../../classes/IUserRights';
import { CRUDEntityType, CRUDService } from '../../services/crud.service';
import { HistoryService } from '../../services/history.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from '../../services/modal.service';
import { RightsService } from '../../services/rights.service';
import { StoreService } from '../../services/store.service';
import { UsersService } from '../../services/users.service';
import { getAllEnumValues } from '../../utils/enums';

/**
 * Интерфейс компонента для отображения подробной информации по пользователю
 */
interface IUserComponent {
  /** Идентификатор пользователя */
  userId?: string;
}

/**
 * Компонент для отображения подробной информации по пользователю
 */
@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends DetailComponent<IUserComponent> implements IUserComponent {

  /** Идентификатор пользователя */
  public userId?: string;

  /** Данные пользователя */
  public user: IUser;

  /** Список доступных для выбора создателей */
  public creators: IActUser[] = [];

  /** Пароль подтверждения */
  public confirmPassword: string;

  /** Права доступа пользователя */
  public userRights: IUserRights;

  /** Список доступных языков */
  public locales: IListItem<Locale>[];

  /** Список доступных часовых поясов */
  public timezones: IListItem<Timezone>[];

  /** Признак отображения поля смены пароля */
  public isResetPasswordVisible: boolean = false;

  /** Список записей истории */
  public history: IClientHistory[];

  /** Тип истории */
  public hType = HistoryEntityType;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param store Сервис для работы с хранилищем
   * @param usersService Сервис работы с пользователями
   * @param crudService Сервис для работы с ДИУП
   * @param rightsService Сервис работы с правами
   * @param translator Сервис для перевода
   * @param loadingService Сервис для отображения процесса загрузки
   * @param historyService Сервис истории
   * @param modalService Сервис модального окна
   */
  constructor(
    dialogService: DialogService,
    public store: StoreService,
    private usersService: UsersService,
    private crudService: CRUDService,
    private rightsService: RightsService,
    private translator: TranslateService,
    private loadingService: LoadingService,
    private historyService: HistoryService,
    private modalService: ModalService
  ) {
    super(dialogService);

    this.isAddRegime = true;
    this.user = { extras: {}, settings: getDefaultUserSettings() } as IUser;
    this.userRights = { users: [], units: [], groups: [], accounts: [] };

    this.title = 'component.user.add-user-title';

    this.tabs[Tabs.Main] = new DetailTab('component.user.main');
    this.tabs[Tabs.Access] = new DetailTab('component.user.access');
    this.tabs[Tabs.More] = new DetailTab('component.user.more');
    this.tabs[Tabs.Arbitrary] = new DetailTab('component.user.arbitrary');
    this.tabs[Tabs.History] = new DetailTab('component.user.history', true);

    this.selectedTab = this.tabs[Tabs.Main];

    this.locales = getAllEnumValues<Locale>(Locale)
      .map((locale) => ({ id: locale, name: getLocaleName(locale) }));

    this.timezones = getAllEnumValues<Timezone>(Timezone)
      .map((timezone) => ({ id: timezone, name: getTimezoneName(timezone) }));
  }

  /**
   * Обработка изменения выбранного создателя пользователя
   */
  public onCreatorChange() {
    const creator = this.creators.find((user) => user.id === this.user.creatorId);
    this.user.creator = creator.name;
    this.user.accountId = creator.accountId;
    this.user.account = creator.account;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IUserComponent) {
    if (data.userId) {
      this.title = 'component.user.edit-user-title';
      this.isAddRegime = false;

      this.loadingService.wrap(this.crudService.get(data.userId, CRUDEntityType.USER))
        .subscribe(
          (user) => this.onUserDataLoaded(user),
          (err) => {
            this.modalService.showError(err);
            this.close();
          });

    } else {
      this.tabs[Tabs.Access].hidden = true;

      this.loadingService.withLoading(
        this.usersService.getActUsers(),
        (users) => {
          this.creators = users;
          const currentUser = this.creators.find((user) => user.current);
          this.user.creatorId = currentUser.id;
          this.user.creator = currentUser.name;
          this.user.accountId = currentUser.accountId;
          this.user.account = currentUser.account;
        });
    }
    return super.fillData(data);
  }

  /**
   * Получение признака наличия прав на управление правами доступа пользователя
   */
  get isCanChangeAccess() {
    return this.isCan(UserRightType.CHANGE_USER_RIGHTS);
  }

  /**
   * Получение признака наличия прав на переименование пользователя
   */
  get isCanRename() {
    return this.isCan(RightType.RENAME);
  }

  /**
   * Получение признака наличия прав на просмотр произвольных свойств пользователя
   */
  get isCanViewArbitraryFields() {
    return this.isCan(RightType.VIEW_ARBITRARY_FIELDS);
  }

  /**
   * Проверка на наличие права
   * @param rightType Право, на наличие которого необходимо произвести проверку
   */
  public isCan(rightType: RightType | UserRightType): boolean {
    // tslint:disable-next-line:no-bitwise
    return this.isAddRegime || !!(this.user.rights & rightType);
  }

  /**
   * Получение признака возможности сохранения пользователя
   */
  get isCanSave() {
    return this.user.name && this.user.name !== ''
      && this.user.creatorId
      && this.user.settings.timezone
      && this.user.settings.locale != null
      && (!this.isAddRegime
        || (this.user.password
          && this.user.password !== ''
          && this.confirmPassword === this.user.password));
  }

  /**
   * Подтверждение изменений
   */
  public confirm() {
    if (!this.isCanSave) { return; }

    const objectRights = this.userRights.units.filter((right) => right.value !== right.newValue);
    objectRights.push(...this.userRights.users.filter((right) => right.value !== right.newValue));
    objectRights.push(...this.userRights.groups.filter((right) => right.value !== right.newValue));
    objectRights.push(...this.userRights.accounts.filter((right) => right.value !== right.newValue));
    const rights: IRight[] = objectRights.map(
      (right) => ({
        id: right.id,
        subjectId: right.subjectId,
        objectId: right.objectId,
        objectType: right.objectType,
        value: right.newValue,
        // tslint:disable-next-line:no-bitwise
        combined: (right.combined | right.newValue)
      })
    );

    const observable = this.crudService
      .addUpdate(this.user, CRUDEntityType.USER)
      .pipe(flatMap((id) => rights.length
        ? this.rightsService.updateRights(rights)
          .pipe(
            map(() => id),
            catchError((error) => this.translator.get('component.user.error', {val: error})
              .pipe(map((x) => throwError(x)))))
        : of(id)
      ));

    this.loadingService.withLoading(
      observable,
      () => {
        this.result = true;
        this.close();
      });
  }

  /**
   * Обработка загрузки данных пользователя с сервера
   * @param user Пользователь
   */
  private onUserDataLoaded(user: IUser) {
    if (!user.extras) {
      user.extras = { } as IUserExtras;
    }
    this.user = user;

    this.creators.push({
      id: this.user.creatorId,
      name: this.user.creator,
      account: this.user.account,
      accountId: this.user.accountId
    });

    // Получаем историю пользователя
    this.loadingService
      .wrap(this.historyService.get(user.id, this.hType.CHANGE_PASSWORD), true)
      .subscribe((history) => {
        this.history = this.historyService.processHistory(history, this.hType.CHANGE_PASSWORD);
        if (history.length > 0) this.tabs[Tabs.History].hidden = false;
      });


    this.tabs[Tabs.Access].hidden = !this.isCanChangeAccess;
    this.tabs[Tabs.Arbitrary].hidden = !this.isCanViewArbitraryFields;
  }
}

/**
 * Список вкладок
 */
enum Tabs {
  Main,
  Access,
  More,
  Arbitrary,
  History
}
