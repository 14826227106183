import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { filter } from 'rxjs/operators';

import { RightType } from '../../../../shared/rights/RightType';
import { IClientUnitInGroup } from '../../../classes/IClientUnitInGroup';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { UnitGroupsService } from '../../../services/unit-groups.service';
import { localeSort } from '../../../utils/sort';
import { SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для управления вхождением объекта мониторинга в группы
 */
@Component({
  selector: 'unit-in-groups',
  templateUrl: './unit.in-groups.component.html',
  styleUrls: ['./unit.in-groups.component.scss']
})
export class UnitInGroupsComponent implements OnInit {

  /** Информация по вхождению объекта в группы */
  @Input() public groups: IClientUnitInGroup[];
  @Output() public groupsChange = new EventEmitter<IClientUnitInGroup[]>();

  /** Идентификатор объекта мониторинга */
  @Input() public unitId: string;

  /** Права пользователя на объект мониторинга */
  @Input() public unitRights: number;

  /** Список групп, в которые входит объект мониторинга */
  public groupsWithUnit: IClientUnitInGroup[] = [];

  /** Строка поиска */
  public search: string;

  /**
   * Конструктор
   * @param groupsService Сервис работы с группами объектов
   * @param modalService Сервис работы с модальными окнами
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    private groupsService: UnitGroupsService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService
  ) {
  }

  /**
   * Удаление объекта из группы
   * @param item Объект с информацией по вхождению объекта в группу
   */
  public deleteUnitFromGroup(item: IClientUnitInGroup) {
    if (!this.isCanDeleteFromGroup(item)) {
      return;
    }

    item.checked = false;
    const index = this.groupsWithUnit.indexOf(item);
    if (index !== -1) {
      this.groupsWithUnit.splice(index, 1);
    }
  }

  /**
   * Получение признака возможности удаления объекта из группы
   * @param item Информация по группе
   */
  public isCanDeleteFromGroup(item: IClientUnitInGroup) {
    // tslint:disable-next-line:no-bitwise
    return item.rights & RightType.CHANGE_GROUP_UNITS;
  }

  /**
   * Выбор групп из списка доступных
   */
  public selectGroups() {
    // tslint:disable-next-line:no-bitwise
    const canChangeGroups = this.groups.filter((g) => g.rights & RightType.CHANGE_GROUP_UNITS);
    const data = {
      items: canChangeGroups,
      selected: this.groupsWithUnit,
      title: 'component.unit.in-groups.select-group-title',
      withSearch: true
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        for (const group of canChangeGroups) {
          group.checked = result.some((i) => i.id === group.id);
        }

        this.groupsWithUnit = this.groups.filter((g) => g.checked);
        this.groupsChange.emit(this.groups);
      });
  }

  /**
   * Обработка после инициализации компонента
   */
  public ngOnInit() {
    if (this.groups) {
      this.groupsWithUnit = this.groups.filter((g) => g.checked);
      return;
    }

    this.loadingService.wrap(this.groupsService.getUnitInGroups(this.unitId), true)
      .subscribe((groups) => {
        this.groups = [];

        for (const group of groups) {
          // tslint:disable-next-line:no-bitwise
          if (group.hasUnit || ((this.unitRights & group.rights) | RightType.CHANGE_GROUP_UNITS) === group.rights) {
            this.groups.push({ ...group, checked: group.hasUnit });
          }
        }

        this.groups?.sort(localeSort);
        this.groupsWithUnit = this.groups?.filter((g) => g.checked);
        this.groupsChange.emit(this.groups);
      });
  }
}
