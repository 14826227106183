import { Component } from '@angular/core';

/**
 * Компонент для входа на сайт
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
}
