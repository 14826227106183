/**
 * Report Templates Service
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FastPeriodType } from '../../shared/FastPeriodType';
import { IItemOrGroupGeneric } from '../../shared/IItemOrGroup';
import { IReportChart } from '../../shared/reports/charts/IReportChart';
import { IReportTable } from '../../shared/reports/tables/IReportTable';
import { IListItem } from '../classes/IListItem';

import { ConfigService } from './config.service';
import { LoadingService } from './loading.service';
import { sortLex } from './reports.service';
import { StoreService } from './store.service';

@Injectable()
export class ReportTemplatesService {

  /**
   * Базовая часть адреса сервиса
   */
  private readonly baseUrl = `${this.configService.url}/report-templates`;

  /**
   * Конструктор
   * @param configService Сервис конфигурации
   * @param http HTTP клиент
   * @param loadingService Сервис для отображения процесса загрузки
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    public configService: ConfigService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private store: StoreService
  ) {
  }

  /**
   * Get report templates list
   */
  public getList(): Observable<IReportTemplateInfo[]> {
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IReportTemplateInfo[]>(this.baseUrl, { headers });
  }

  /**
   * Get report template
   * @param id Report template id
   */
  public get(id: string): Observable<IReportTemplate> {
    const url = `${this.baseUrl}/${id}`;
    const headers = this.loadingService.waiterHeader;
    return this.http.get<IReportTemplate>(url, { headers });
  }

  /**
   * Create report template
   * @param body Report template
   */
  public create(body: IReportTemplate): Observable<string> {
    const headers = this.loadingService.waiterHeader;
    return this.http.post<string>(this.baseUrl, body, { headers });
  }

  /**
   * Update report template
   * @param body Report template
   */
  public update(body: IReportTemplate): Observable<string> {
    const headers = this.loadingService.waiterHeader;
    return this.http.put<string>(this.baseUrl, body, { headers });
  }

  /**
   * Remove report template
   * @param id Report template id
   */
  public remove(id: string): Observable<string> {
    const url = `${this.baseUrl}/${id}`;
    const headers = this.loadingService.waiterHeader;
    return this.http.delete<string>(url, { headers });
  }

  /**
   * Get reference report templates list
   */
  public getReferences(): Observable<IListItem<string>[]> {
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams().append('refs', '1');
    return this.http.get<IReportTemplateInfo[]>(this.baseUrl, { headers, params })
      .pipe(map((items) => items.map((item) => ({
        id: item.id,
        name: item.name
      }))));
  }

  /**
   * Получение списка аналитических отчетов
   */
  public getAnalytics(): Observable<IReportTemplateInfo[]> {
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams().append('analytics', '1');
    return this.http.get<IReportTemplateInfo[]>(this.baseUrl, { headers, params });
  }

  /**
   * Получение списка шаблонов отчетов
   */
  public getTemplates(accountId: string): Observable<IReportTemplateInfoWithObjects[]> {
    const url = `${this.baseUrl}/full`;
    const headers = this.loadingService.waiterHeader;
    const params = new HttpParams().append('accountId', accountId);
    return this.http.get<IReportTemplateInfoWithObjects[]>(url, { headers, params })
      .pipe(tap((x) => sortLex(x)));
  }
}

/**
 * Типы отчетов
 */
export enum ReportType {
  /** Объект */
  UNIT = 1,
  /** Группа объектов */
  UNIT_GROUP,
  /** Водитель */
  DRIVER,
  /** Группа водителей */
  DRIVER_GROUP,
  /** Прицеп */
  TRAILER,
  /** Группа прицепов */
  TRAILER_GROUP,
  /** Пользователь */
  USER = 8
}

/**
 * Получение списка всех типов отчетов
 */
export function getAllReportTypes(): ReportType[] {
  return [
    ReportType.UNIT,
    ReportType.UNIT_GROUP,
    ReportType.DRIVER,
    ReportType.DRIVER_GROUP,
    ReportType.TRAILER,
    ReportType.TRAILER_GROUP,
    ReportType.USER
  ];
}

/**
 * Получение наименования типа отчета
 * @param type Тип отчета
 */
export function getReportTypeName(type: ReportType): string {
  switch (type) {
    case ReportType.UNIT:
      return 'enums.reports.type.object';
    case ReportType.UNIT_GROUP:
      return 'enums.reports.type.objects-group';
    case ReportType.DRIVER:
      return 'enums.reports.type.driver';
    case ReportType.DRIVER_GROUP:
      return 'enums.reports.type.drivers-group';
    case ReportType.USER:
      return 'enums.reports.type.user';
    case ReportType.TRAILER:
      return 'enums.reports.type.trail';
    case ReportType.TRAILER_GROUP:
      return 'enums.reports.type.trails-group';
    default:
      return 'enums.reports.type.unknown';
  }
}

/**
 * Шаблон отчета (обобщенный)
 */
export interface IReportTemplate {
  /** Идентификатор записи */
  id: string;
  /** Наименование учетной записи */
  account: string;
  /** Признак эталонности отчета */
  isReference?: boolean;
  /** Идентификатор учетной записи */
  accountId: string;
  /** Наименование отчета */
  name: string;
  /** Тип отчета */
  type: ReportType;
  /** Список объектов отчета */
  objects: IItemOrGroupGeneric<string>[];
  /** Список элементов отчета */
  items: ReportItem[];
  /** Признак замены адреса в отчете на названия геозон */
  replaceAddressWithGeozone?: boolean;
  /** Признак отображения рейсов на карте */
  showRacesOnMap?: boolean;
  /** Признак отображения интервалов таблиц отчета на карте */
  showIntervalsOnMap?: boolean;
  /** Признак использования невалидных точек в отчёте */
  allowInvalidPoints?: boolean;
  /** Признак аналитического отчета */
  analytics?: boolean;
  /** Интервал для формирования аналитического отчета */
  analyticsPeriod?: FastPeriodType;
  /** Признак путевого листа */
  waybill?: boolean;
  /** Дата создания шаблона */
  createdAt: Date;
  /** Дата изменения шаблона */
  updatedAt: Date;
}

/**
 * Информация по шаблону отчета
 */
export interface IReportTemplateInfo {
  /** Идентификатор записи */
  id: string;
  /** Наименование */
  name: string;
  /** Учетная запись */
  account: string;
  /** Тип */
  type: ReportType;
  /** Период аналитического отчета */
  analyticsPeriod?: FastPeriodType;
  /** Признак эталонности шаблона */
  isReference?: boolean;
  /** Дата создания */
  createdAt: Date;
}

/**
 * Шаблон отчета со списком объектов
 */
export interface IReportTemplateInfoWithObjects {
  /** Идентификатор записи */
  id: string;
  /** Наименование */
  name: string;
  /** Тип */
  type: ReportType;
  /** Объекты */
  objects: IItemOrGroupGeneric<string>[];
}

/**
 * Элемент отчета
 */
export type ReportItem = IReportTable<string> | IReportChart;
