import { Component, OnDestroy } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IListItem } from '../../../classes/IListItem';
import { CRUDEntityType, CRUDService } from '../../../services/crud.service';
import { LoadingService } from '../../../services/loading.service';
import { ModalService } from '../../../services/modal.service';
import { MonitoringService } from '../../../services/monitoring.service';
import { IClientOnlineNotification, OnlineNotificationsService } from '../../../services/online-notifications.service';
import { StoreService } from '../../../services/store.service';
import { ISystemNotificationShort, SystemNotificationsService } from '../../../services/system-notifications.service';
import { SelectionType, SelectItemsComponent } from '../../select-items/select-items.component';

/**
 * Компонент для отображения списка онлайн-уведомлений
 */
@Component({
  selector: 'online-notifications',
  templateUrl: './online-notifications.component.html',
  styleUrls: ['./online-notifications.component.scss']
})
export class OnlineNotificationsComponent implements OnDestroy {

  /** Список уведомлений */
  public notifications: IClientOnlineNotification[] = [];

  /** Список выбираемых ТС */
  public units: IListItem<string>[];

  /** Список всех доступных ТС */
  private allUnits: IListItem<string>[];

  /** Подписка на смену выбранного объекта мониторинга в панели онлайн-уведомлений  */
  private onlineNotificationsUnitChangedSubscription: Subscription;

  /**
   * Конструктор
   * @param onlineNotificationsService Сервис работы с уведомлениями
   * @param systemNotificationsService Сервис работы с уведомлениями
   * @param monitoringService Сервис мониторинга
   * @param crudService Сервис работы с CRUD
   * @param modalService Сервис модальных окон
   * @param dialogService Сервис диалоговых окон
   * @param loadingService Сервис отображения процесса загрузки
   * @param store Сервис для хранения данных мониторинга
   */
  constructor(
    public onlineNotificationsService: OnlineNotificationsService,
    public systemNotificationsService: SystemNotificationsService,
    private monitoringService: MonitoringService,
    private crudService: CRUDService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private store: StoreService
  ) {
    this.notifications = this.onlineNotificationsService.notifications;
    this.onlineNotificationsUnitChangedSubscription =
      this.monitoringService.activateNotificationsPanelSubject.subscribe(this.onSelectedUnitChange);
    this.units = this.store.units.map((u) => ({ id: u.id, name: u.name }));
  }

  /**
   * Получение стиля уведомления
   * @param notification Уведомление
   */
  public getNotificationStyle(notification: IClientOnlineNotification) {
    return {
      'background-color': notification.bgColor,
      'border-left-color': notification.readed ? 'transparent' : notification.color
    };
  }

  /**
   * Переключение видимости детальной информации по уведомлению
   * @param notification Уведомление
   */
  public toggleDetail(notification: IClientOnlineNotification) {
    if (!notification.readed) {
      notification.readed = true;
    }
    notification.detail = !notification.detail;
    this.onlineNotificationsService.changeUnitTrafficLight(notification);
  }

  /**
   * Удаление уведомления
   * @param notification Уведомление
   */
  public deleteNotification(notification: IClientOnlineNotification) {
    this.onlineNotificationsService.deleteNotification(notification);
    this.onSelectedUnitChange();
  }

  /**
   * Удаление всех уведомлений
   */
  public deleteAll() {
    this.onlineNotificationsService.deleteAllNotifications();
    this.onSelectedUnitChange();
  }

  /**
   * Удаление прочитанных уведомлений
   */
  public deleteReaded() {
    this.onlineNotificationsService.deleteReadNotifications();
    this.onSelectedUnitChange();
  }

  /**
   * Удаление системного уведомления из списка
   * @param notification Уведомление
   */
  public deleteSystemNotification(notification: ISystemNotificationShort) {
    this.systemNotificationsService.markAsRead(notification.id);
  }

  /**
   * Выбор объекта из полного списка объектов
   */
  public selectUnitFromAll() {
    if (this.allUnits) {
      this.showSelectUnitFromAllDialog();
      return;
    }

    this.loadingService.wrap(this.crudService.getListLight(CRUDEntityType.UNIT), true)
      .subscribe((units) => {
        this.allUnits = units;
        this.showSelectUnitFromAllDialog();
      });
  }

  /**
   * Обработка изменения выбранного ТС
   */
  public onSelectedUnitChange = () => {
    this.notifications = this.onlineNotificationsService.selectedUnit
      ? this.onlineNotificationsService.notifications.filter((n) => n.unitId === this.onlineNotificationsService.selectedUnit)
      : this.onlineNotificationsService.notifications;
  };

  /**
   * Обработки при уничтожении компонента
   */
  public ngOnDestroy() {
    this.onlineNotificationsUnitChangedSubscription.unsubscribe();
  }

  /**
   * Отображение диалога для выбора объектов из полного списка
   */
  private showSelectUnitFromAllDialog() {
    const selected = this.onlineNotificationsService.selectedUnit
      ? this.allUnits.filter((u) => u.id === this.onlineNotificationsService.selectedUnit)
      : [];

    const data = {
      items: this.allUnits,
      selected,
      title: 'component.notifications.online-notifications.select-object',
      withSearch: true,
      selection: SelectionType.OnlyOne
    };

    this.dialogService.addDialog(SelectItemsComponent, data)
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        const unit = result.shift();
        this.onlineNotificationsService.selectedUnit = unit?.id;

        if (!this.units.some((u) => u.id === unit.id)) {
          this.units.push(unit);
        }

        this.onSelectedUnitChange();
      });
  }
}
