/** Тип усреднения значений штрафов по качеству вождения */
export enum DrivingQualityAvgType {
  /** По пробегу */
  BY_MILEAGE = 1,
  /** По времени */
  BY_TIME
}

/** Получение списка всех типов усреднения */
export function getAllDrivingQualityAvgTypes(): DrivingQualityAvgType[] {
  return [DrivingQualityAvgType.BY_MILEAGE, DrivingQualityAvgType.BY_TIME];
}

/**
 * Получение наименования типа усреднения
 * @param type Тип усреднения
 */
export function getDrivingQualityAvgTypeName(type: DrivingQualityAvgType): string {
  switch (type) {
    case DrivingQualityAvgType.BY_MILEAGE: return 'enums.driving-quality.average-type.mileage';
    case DrivingQualityAvgType.BY_TIME: return 'enums.driving-quality.average-type.time';
    default: return 'enums.driving-quality.average-type.unknown';
  }
}
