import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Observable } from 'rxjs';

import {
  DocRequest,
  DocRequestType,
  DocResponse,
  DocType,
  isInvoiceRequest
} from '../../../../../../shared/crm/docs';
import { DocsService } from '../../../../../services/docs.service';
import { LoadingService } from '../../../../../services/loading.service';

/** Интерфейс компонента для добавления документа */
interface IDocAddComponent {
  /** Тип документа */
  type: DocType;
  /** Идентификатор учетной записи */
  accountId: string;
}

/**
 * Компонент для добавления документа
 */
@Component({
  selector: 'doc-add',
  templateUrl: './doc.add.component.html'
})
export class DocAddComponent extends DialogComponent<IDocAddComponent, boolean> {

  /** Тип документа */
  public type: DocType;

  /** Идентификатор учетной записи */
  public accountId: string;

  /** Дата документа */
  public date: Date;

  /** Начало периода */
  public from: Date;

  /** Окончание периода */
  public to: Date;

  /** Сумма (для счетов) */
  public sum: number;

  /** Признак включения баланса в сумму счета */
  public withBalance: boolean;

  /** Признак формирования акта помесячно */
  public monthly: boolean;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param docsService Сервис для работы с документами
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private docsService: DocsService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.withBalance = false;
    this.monthly = false;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IDocAddComponent) {
    if (data.type === 'invoice') {
      this.date = new Date();
    }

    return super.fillData(data);
  }

  /** Признак счета */
  public get isInvoice() {
    return this.type === 'invoice';
  }

  /** Признак возможности сохранения */
  public get isCanSave() {
    if (!this.isInvoice) {
      return true;
    }

    // Нельзя указать сумму и период одновременно
    if (this.sum && (this.from || this.to)) {
      return false;
    }

    return !this.sum || this.sum > 0;
  }

  /** Добавление */
  public ok() {
    if (!this.isCanSave) {
      return;
    }

    const request: DocRequest = this.isInvoice ? {
      type: DocRequestType.GENERATE,
      accountId: this.accountId,
      date: this.date ? this.date.getTime() : null,
      from: this.from ? this.from.getTime() : null,
      to: this.to ? this.to.getTime() : null,
      sum: this.sum ? Math.round(this.sum * 100) : null,
      withBalance: this.withBalance
    } : {
      type: DocRequestType.GENERATE,
      accountId: this.accountId,
      date: this.date ? this.date.getTime() : null,
      from: this.from ? this.from.getTime() : null,
      monthly: this.monthly
    };

    const observable: Observable<DocResponse> = isInvoiceRequest(request, this.type)
      ? this.docsService.invoice(request)
      : this.docsService.waReport(request);

    this.loadingService
      .wrap(observable, true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /** Отмена */
  public cancel() {
    this.close();
  }
}
