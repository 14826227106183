import { NgModule } from '@angular/core';

import { AccountsFilterPipe } from './pipes/accounts-filter.pipe';
import { BgdatePipe } from './pipes/bgdate.pipe';
import { BgdurationPipe } from './pipes/bgduration.pipe';
import { BgnumberPipe } from './pipes/bgnumber.pipe';
import { ClientFilterPipe } from './pipes/client-filter.pipe';
import { DillerAccountsFilterPipe } from './pipes/diller-accounts-filter.pipe';
import { DogovorsFilterPipe } from './pipes/dogovor-filter.pipe';
import { EquipmentsFilterPipe } from './pipes/equipments-filter.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GeozonesFilterPipe } from './pipes/geozones-filter.pipe';
import { NameFilterPipe } from './pipes/name-filter.pipe';
import { NotificationSettingFilterPipe } from './pipes/notification-settings-fitler.pipe';
import { ObjectRightsFilterPipe } from './pipes/object-rights-filter.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { RetranslatorsHistoryFilterPipe } from './pipes/retranslator-history-filter.pipe';
import { RightsOrderByPipe } from './pipes/rights-order.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SIMCardsFilterPipe } from './pipes/sim-cards-filter.pipe';
import { SubjectRightsFilterPipe } from './pipes/subject-rights-filter.pipe';
import { TemplatesFilterPipe } from './pipes/templates-filter.pipe';
import { UnitGroupsFilterPipe } from './pipes/unit-groups-filter.pipe';
import { UnitsFilterPipe } from './pipes/units-filter.pipe';
import { UsersFilterPipe } from './pipes/users-filter.pipe';

/**
 * Модуль для всяких фильтров и т.п.
 */
@NgModule({
  declarations: [
    AccountsFilterPipe,
    BgdatePipe,
    BgdurationPipe,
    BgnumberPipe,
    ClientFilterPipe,
    DillerAccountsFilterPipe,
    DogovorsFilterPipe,
    EquipmentsFilterPipe,
    FilterPipe,
    GeozonesFilterPipe,
    NameFilterPipe,
    NotificationSettingFilterPipe,
    ObjectRightsFilterPipe,
    OrderByPipe,
    RetranslatorsHistoryFilterPipe,
    RightsOrderByPipe,
    SearchPipe,
    SIMCardsFilterPipe,
    SubjectRightsFilterPipe,
    TemplatesFilterPipe,
    UnitGroupsFilterPipe,
    UnitsFilterPipe,
    UsersFilterPipe
  ],
  imports: [],
  exports: [
    AccountsFilterPipe,
    BgdatePipe,
    BgdurationPipe,
    BgnumberPipe,
    ClientFilterPipe,
    DillerAccountsFilterPipe,
    DogovorsFilterPipe,
    EquipmentsFilterPipe,
    FilterPipe,
    GeozonesFilterPipe,
    NameFilterPipe,
    NotificationSettingFilterPipe,
    ObjectRightsFilterPipe,
    OrderByPipe,
    RetranslatorsHistoryFilterPipe,
    RightsOrderByPipe,
    SearchPipe,
    SIMCardsFilterPipe,
    SubjectRightsFilterPipe,
    TemplatesFilterPipe,
    UnitGroupsFilterPipe,
    UnitsFilterPipe,
    UsersFilterPipe
  ]
})
export class PipesModule { }
