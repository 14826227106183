import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

import {
  IClientGenerateInvoiceRequest,
  IDocTariffPeriod,
  IDocTariffsInfo
} from '../../../../shared/crm/docs';
import { TariffPeriodType } from '../../../../shared/crm/tariffs';
import { ClientService } from '../../../services/client.service';
import { LoadingService } from '../../../services/loading.service';

/** Интерфейс компонента для добавления счета клиентом */
interface IData {
  /** Идентификатор учетной записи */
  accountId: string;
}

/**
 * Компонент для добавления счета клиентом
 */
@Component({
  selector: 'client-add-invoice',
  templateUrl: './client.add-invoice.component.html'
})
export class ClientAddInvoiceComponent extends DialogComponent<IData, boolean> {

  /** Идентификатор учетной записи */
  public accountId: string;

  /** Дата документа */
  public date: Date;

  /** Начало периода */
  public from: Date;

  /** Окончание периода */
  public to: Date;

  /** Сумма */
  public sum: number;

  /** Признак включения баланса в сумму счета */
  public withBalance: boolean;

  /** Тариф, за который формируем */
  public tariff: IDocTariffPeriod = null;

  /** Информация по тарифам */
  public tariffsInfo: IDocTariffsInfo;

  /**
   * Конструктор
   * @param dialogService Сервис диалоговых окон
   * @param clientService Сервис для работы с текущим клиентом
   * @param loadingService Сервис для отображения процесса загрузки
   */
  constructor(
    dialogService: DialogService,
    private clientService: ClientService,
    private loadingService: LoadingService
  ) {
    super(dialogService);

    this.withBalance = false;
  }

  /**
   * Заполнение компонента данными
   * @param data Данные компонента
   */
  public fillData(data: IData) {
    this.loadTariffsInfo(data.accountId);
    return super.fillData(data);
  }

  /** Признак отображения начала периода */
  public get isShowFrom() {
    return this.tariff && this.tariff.debitPeriod === TariffPeriodType.MONTH;
  }

  /** Признак отображения окончания периода */
  public get isShowTo() {
    return this.tariff && this.tariff.debitPeriod === TariffPeriodType.MONTH;
  }

  /** Признак отображения суммы счета */
  public get isShowSum() {
    return this.tariff && this.tariff.debitPeriod !== TariffPeriodType.MONTH;
  }

  /** Признак отображения признака "С учетом баланса" */
  public get isShowWithBalance() {
    return this.tariff && this.tariff.debitPeriod === TariffPeriodType.MONTH;
  }

  /** Признак возможности сохранения */
  public get isCanSave() {
    if (!this.tariff || this.tariff.debitPeriod === TariffPeriodType.MONTH) {
      return true;
    }

    // Если новый тип тарифа, то необходимо указать суммму
    return this.sum && this.sum > 0;
  }

  /** Добавление */
  public ok() {
    if (!this.isCanSave) {
      return;
    }

    const request: IClientGenerateInvoiceRequest = {
      date: this.date ? this.date.getTime() : null,
      from: this.isShowFrom && this.from ? this.from.getTime() : null,
      to: this.isShowTo && this.to ? this.to.getTime() : null,
      sum: this.isShowSum && Math.round(this.sum * 100),
      withBalance: this.isShowWithBalance && this.withBalance
    };

    this.loadingService
      .wrap(this.clientService.generateInvoice(request, this.accountId), true)
      .subscribe(() => {
        this.result = true;
        this.close();
      });
  }

  /** Отмена */
  public cancel() {
    this.close();
  }

  /**
   * Загрузка информации по тарифу
   * @param accountId Идентификатор учетной записи
   */
  private loadTariffsInfo(accountId: string) {
    this.loadingService
      .wrap(this.clientService.getTariffsInfo(accountId), true)
      .subscribe((tariffsInfo) => {
        this.tariffsInfo = tariffsInfo;
        this.tariff = this.getMostSuitableTariff(tariffsInfo.tariffs);

        this.sum = ((tariffsInfo.regularPayment || 0) + (tariffsInfo.debt || 0)) / 100;
        this.date = new Date();
      });
  }

  /**
   * Получение наиболее подходящего тарифа (в идеале - текущий)
   * @param tariffs Список всех тарифов
   */
  private getMostSuitableTariff(tariffs: IDocTariffPeriod[]): IDocTariffPeriod {
    if (!tariffs.length) {
      return null;
    }

    const now = Date.now();
    const pastAndPresentTariffs = tariffs.filter((t) => t.from <= now);

    return pastAndPresentTariffs.length
      ? pastAndPresentTariffs[pastAndPresentTariffs.length - 1]
      : tariffs[0];
  }
}
