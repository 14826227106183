import { Component, ComponentFactoryResolver, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { BgPopoverWrapperComponent } from './bg-popover-wrapper.component';
import { BgPopoverComponent } from './bg-popover.component';
import { IPopoverPosition } from './IPopoverPosition';

@Component({
  selector: 'bg-popover-holder',
  template: `<ng-template #element></ng-template>`
})
export class BgPopoverHolderComponent {

  @ViewChild('element', {read: ViewContainerRef, static: true})
  public element: ViewContainerRef;

  public wrapper: BgPopoverWrapperComponent;

  constructor(private resolver: ComponentFactoryResolver) { }

  public addPopover<T>(component: Type<BgPopoverComponent<T>>, position: IPopoverPosition, data: T) {
    const factory = this.resolver.resolveComponentFactory(BgPopoverWrapperComponent);
    const componentRef = this.element.createComponent(factory);
    this.wrapper = componentRef.instance as BgPopoverWrapperComponent;
    const c: BgPopoverComponent<T> = this.wrapper.addComponent(component);

    c.fillData(data);

    const width = window.innerWidth;
    const height = window.innerHeight;
    if (!position.noRecalcX && position.x > width / 2) {
      this.wrapper.container.nativeElement.style.right = `${width - position.x}px`;
    } else {
      this.wrapper.container.nativeElement.style.left = `${position.x}px`;
    }

    if (!position.noRecalcY && position.y > height / 2) {
      this.wrapper.container.nativeElement.style.bottom = `${height - position.y}px`;
    } else {
      this.wrapper.container.nativeElement.style.top = `${position.y}px`;
    }
  }

  public clear() {
    this.element.clear();
  }
}
