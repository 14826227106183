import { Component } from '@angular/core';

import { BgAuthService } from '../../modules/bg-auth/services/bg-auth.service';
import { BGMessagesService } from '../../services/b-g-messages.service';
import { DocsService } from '../../services/docs.service';
import { OnlineNotificationsService } from '../../services/online-notifications.service';
import { RaceService } from '../../services/race.service';
import { ReportsService } from '../../services/reports.service';
import { StoreService } from '../../services/store.service';
import { SystemNotificationsService } from '../../services/system-notifications.service';

/**
 * Компонент, предназначенный для выхода с сайта
 */
@Component({
  template: ''
})
export class LogoutComponent {
  /**
   * Конструктор
   * @param auth Сервис аутентификации
   * @param raceService Сервис работы с рейсами
   * @param onlineNotificationsService Сервис работы с уведомлениями
   * @param systemNotificationsService Сервис работы с уведомлениями
   * @param reportsService Сервис для работы с отчетами
   * @param store Сервис для хранения данных мониторинга
   * @param messagesService Сервис для работы с сообщениями
   * @param docsService
   */
  constructor(
    auth: BgAuthService,
    raceService: RaceService,
    onlineNotificationsService: OnlineNotificationsService,
    systemNotificationsService: SystemNotificationsService,
    reportsService: ReportsService,
    store: StoreService,
    messagesService: BGMessagesService,
    docsService: DocsService
  ) {
    localStorage.removeItem('observer');
    localStorage.removeItem('observable');
    store.clean();
    reportsService.fullClearReport();
    messagesService.clean();
    raceService.clean();
    onlineNotificationsService.clean();
    systemNotificationsService.clean();
    auth.logout();
    docsService.clean()
  }
}
